import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { boundMethod } from 'autobind-decorator';
import { Feature } from 'geojson';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE } from '../../../../constant/poi.constant';
import { AddressesService, IFindAddressParams } from '../../../../services/common/addresses.service';
import { UtilService } from '../../../../services/common/util.service';
import { FormService } from '../../../../utils/form-service';
import { isAddressPOI } from '../../functions/is-address-poi.function';
import { AddressCollectionGoogle } from '../../interfaces/address-collection-google.interface';
import { UIAddress } from '../../interfaces/address-dto.interface';

@Component({
  selector: 'azz-address-poi-autocomplete',
  templateUrl: './address-poi-autocomplete.component.html',
  styleUrls: ['./address-poi-autocomplete.component.less'],
  providers: [NgOnDestroyService],
})
export class AddressPoiAutocompleteComponent implements OnInit {
  @Input() isDepartureAddressOutsideOfBusinessZone: boolean;
  @Input() ngId: string;
  @Input() customFocusTriggerCounter: number;
  @Input() withPoi: boolean;
  @Input() orderServiceType: string;
  @Input() ngDisabled: boolean;
  @Input() correspondingAddress: AddressCollectionGoogle;
  @Input() addressEqualityError: string;
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @Output() typing = new EventEmitter<void>();
  @Output() selectAddress = new EventEmitter<AddressCollectionGoogle>();
  public form: FormGroup;
  public loadingIndicator: boolean;
  public address: AddressCollectionGoogle;
  public isPOI = isAddressPOI;

  private readonly REQUEST_DELAY_MS = 300;

  constructor(
    private readonly addressesService: AddressesService,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly formService: FormService,
    private readonly utilService: UtilService,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  @boundMethod
  public search(text$: Observable<string>) {
    return text$.pipe(
      tap(() => {
        this.resetAddress();
        this.typing.emit();
      }),
      debounceTime(this.REQUEST_DELAY_MS),
      distinctUntilChanged(),
      tap(() => (this.loadingIndicator = true)),
      switchMap((searchValue: string) => this.findAddress(this.generateParams(searchValue))),
      map(list => (!list.length ? [null] : list)), // in order to display "not found" message
      tap(() => (this.loadingIndicator = false)),
      takeUntil(this.destroyed$)
    );
  }

  @boundMethod
  public formatter(address: any | string) {
    if (typeof address === 'string') {
      return address;
    }

    let addressSearch;
    if (address.housenumber) {
      addressSearch = `${address.housenumber ? address.housenumber : ''} ${address.street}, ${address.city}`;
    } else if (!address.housenumber) {
      addressSearch = `${address.address}, ${address.city}`;
    }

    const uiName = address.type !== 'poi' ? addressSearch : this.formatPOILabel(address);
    return uiName;
  }

  @boundMethod
  private noSelectedAddressValidator(): ValidationErrors | null {
    return this.address ? null : { noSelectedAddress: true };
  }

  @boundMethod
  private addressesEqualityValidator(): ValidationErrors | null {
    return this.utilService.isEqualAddress(this.address, this.correspondingAddress)
      ? { addressesEquality: true }
      : null;
  }

  public onAddressSelect(event: { item: AddressCollectionGoogle; preventDefault: () => void }): void {
    this.address = event.item;
    this.selectAddress.emit(this.address);
  }

  public setAutocompleteFieldValue(searchField: AddressCollectionGoogle): void {
    this.form.patchValue({ searchField });
    this.address = searchField;
    this.detectAndUpdate();
  }

  public markFieldsAsTouched(): void {
    this.formService.markFieldsAsTouched(this.form);
    this.customDetectChanges();
  }

  public detectAndUpdate(): void {
    this.form.controls.searchField.updateValueAndValidity();
    this.customDetectChanges();
  }

  public reset(): void {
    if (this.address) {
      this.address = null;
    }
    this.form.reset();
  }

  ngOnInit() {
    this.initForm();
  }

  public formatPOILabel(address: UIAddress) {
    console.warn(address);
    // const hasCity = !!address.city;
    // const city = hasCity ? `, ${address.city}` : '';
    return address.poi?.label ? address.poi.label : address.label;
    return address.label ? address.label : address.poi.label;
    //return address.type === 'poi' ? address.poi.label : address.label;
  }

  private initForm(): void {
    this.form = this.fb.group({
      searchField: [
        { value: '', disabled: this.ngDisabled },
        [this.noSelectedAddressValidator, this.addressesEqualityValidator],
      ],
    });
  }

  private findAddress(params: IFindAddressParams): Observable<Feature[]> {
    return params.q
      ? this.addressesService.findGoogleAddress(params).pipe(
          // filter((response: EtalabFeatureCollection) => !!response && !!response.features),
          // map((response: EtalabFeatureCollection) => (response.features.length ? response.features : [null])),
          catchError(() => {
            this.loadingIndicator = false;
            return EMPTY;
          }),
          takeUntil(this.destroyed$)
        )
      : of([]);
  }

  private resetAddress(): void {
    if (this.address) {
      this.address = null;
      this.form.controls.searchField.updateValueAndValidity();
    }
  }

  private generateParams(searchValue: string): IFindAddressParams {
    return {
      limit: 10,
      q: searchValue,
      withPoi: this.withPoi,
      serviceLevel: this.orderServiceType ? this.convertOrderServiceTypeToPoiServiceLevelArray() : null,
    };
  }

  private convertOrderServiceTypeToPoiServiceLevelArray(): string[] {
    return POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE[this.orderServiceType] &&
      typeof POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE[this.orderServiceType] === 'string'
      ? POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE[this.orderServiceType].split(',')
      : [''];
  }

  private customDetectChanges(): void {
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }
}
