import { Injectable } from '@angular/core';
import { Feature, FeatureCollection } from 'geojson';
import { geoJSON, Map } from 'leaflet';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export enum ZONE_TYPE {
  BUSINESS_ZONE = 'BUSINESS_ZONE',
  DISPATCH_ZONE = 'DISPATCH_ZONE',
  TAXI_STATION = 'TAXI_STATION',
  TAXIMETER_ZONE = 'TAXIMETER_ZONE',
}

export enum DRAWN_ZONE_TYPE_PROP {
  BUSINESS_ZONE = 'isBusinessZoneDrawn',
  DISPATCH_ZONE = 'isAtLeastOneDispatchZoneDrawn',
}

@Injectable({ providedIn: 'root' })
export class CreateZoneService {
  private readonly zoneDrawn$ = new Subject<{ zoneTypeDrawn: string; value: boolean }>();
  private readonly onHoverLayer$ = new Subject<{ zoneTypeGeoJSON: string; layerId: number; hover: boolean }>();
  private readonly layersFullOptions = { color: 'red', fillOpacity: 0.4, opacity: 1, weight: 3 };
  private readonly layersOnlyBorderOptions = { color: 'red', fillOpacity: 0, opacity: 1, weight: 3 };
  private readonly layersTransparentOptions = { opacity: 0, fillOpacity: 0 };
  private readonly businessZoneCollection$ = new BehaviorSubject<FeatureCollection>(undefined);
  private readonly dispatchZoneCollection$ = new BehaviorSubject<FeatureCollection>(undefined);
  private readonly taxiStationCollection$ = new BehaviorSubject<Feature[]>(undefined);
  private readonly taxiStationsFeatureCollections$ = new BehaviorSubject<FeatureCollection[]>(undefined);

  public getTaxiStationsFeatureCollections(): Observable<FeatureCollection[]> {
    return this.taxiStationsFeatureCollections$.asObservable();
  }

  public sendTaxiStationsFeatureCollections(collections: FeatureCollection[]): void {
    this.taxiStationsFeatureCollections$.next(collections);
  }

  public getTaxiStationCollection(): Observable<Feature[]> {
    return this.taxiStationCollection$.asObservable();
  }

  public sendTaxiStationCollection(features: Feature[]): void {
    this.taxiStationCollection$.next(features);
  }

  public getDispatchZoneCollection(): Observable<FeatureCollection> {
    return this.dispatchZoneCollection$.asObservable();
  }

  public sendDispatchZoneCollection(collection: FeatureCollection): void {
    this.dispatchZoneCollection$.next(collection);
  }

  public getBusinessZoneCollection(): Observable<FeatureCollection> {
    return this.businessZoneCollection$.asObservable();
  }

  public sendBusinessZoneCollection(collection: FeatureCollection): void {
    this.businessZoneCollection$.next(collection);
  }

  public getLayersFullOptions(): any {
    return this.layersFullOptions;
  }

  public getLayersOnlyBorderOptions(): any {
    return this.layersOnlyBorderOptions;
  }

  public getLayersTransparentOptions(): any {
    return this.layersTransparentOptions;
  }

  public setLayerHover(zoneTypeGeoJSON: string, layerId: number, hover: boolean) {
    this.onHoverLayer$.next({ zoneTypeGeoJSON, layerId, hover });
  }

  public getLayerHover(): Observable<{ zoneTypeGeoJSON: string; layerId: number; hover: boolean }> {
    return this.onHoverLayer$.asObservable();
  }

  public sendZoneDrawnInfo(data: { zoneTypeDrawn: string; value: boolean }): void {
    this.zoneDrawn$.next(data);
  }

  public getZoneDrawnInfo(): Observable<{ zoneTypeDrawn: string; value: boolean }> {
    return this.zoneDrawn$.asObservable();
  }

  public convertFeatureCollectionToFeaturesArray(collection: FeatureCollection): Feature[] {
    return cloneDeep(collection.features);
  }

  public convertFeaturesToCollection(features: Feature[]): FeatureCollection {
    return { type: 'FeatureCollection', features };
  }

  public convertToGeoJSON(json): any {
    return geoJSON(json);
  }

  public removeLayersFromMap(map: Map, layers: any[]) {
    layers.forEach(layer => map.removeLayer(layer));
  }

  public removeLayers(featureGroup: any[]) {
    featureGroup.forEach(layer => layer.clearLayers());
  }
}
