/* eslint-disable */
import { Location } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getAllFieldsEditableTimeLimit } from '@common/models/dispatch-order';
import { LoadWrapperService, NgOnDestroyService, UiOrderRulesService } from '@common/services';
import { IdOption, NamedOption } from '@common/types';
import { normalizeNumber } from '@controls/phone/normalizeNumber';
import { PhoneComponent } from '@controls/phone/phone.component';
import {
  ORDER_SERVICE_TYPE_POI_SERVICE_LEVEL_ARRAY,
  ORDER_TAG_TYPE,
  OrderStatusType,
  OrderType,
  PaymentTypeTitle,
  PHONE_TYPE,
  PhoneTypeFieldTitle,
  ServiceTypeTitle,
  VehicleTypeTitle,
} from '@core/constant';
import { CurrentOrderStatusType } from '@core/constant/current-orders-status.constant';
import { FrenchPhoneConstraints } from '@core/constant/french-phone-constraints.constant';
import { UserRole } from '@core/constant/role.constant';
import { RouteUrls } from '@core/constant/route-urls';
import { TagTypeName } from '@core/constant/tag.constant';
import {
  ICustomer,
  ICustomerPhone,
  IFleetParametersForOtherUser,
  IGetPaOrdersParams,
  INewCustomer,
  IPhoneAdvisorOrderData,
  ITag,
  PhoneAdvisorOrder,
} from '@core/models';
import { CustomConfirmDialogComponent } from '@core/modules/custom-dialog/custom-confirm-dialog';
import { helpers } from '@core/modules/nouvelle-commande/components/date-time/date/helpers';
import { DriverAssignmentInfo } from '@core/modules/nouvelle-commande/components/driver-assignment/driver-assignment-info';
import { DriverAssignmentService } from '@core/modules/nouvelle-commande/components/driver-assignment/driver-assignment.service';
import { TDistanceShowType } from '@core/modules/nouvelle-commande/components/order-pre-save-dialog/order-pre-save.dialog.component';
import { DateValidators } from '@core/modules/nouvelle-commande/functions/DateValidator';
import { HereApi } from '@core/modules/nouvelle-commande/interfaces';
import {
  AddressesService,
  GeoAreaService,
  LocalStorageService,
  OSRMService,
  TagService,
  UtilService,
} from '@core/services/common';
import { PAOrderService } from '@core/services/orders/phone-advisor-order.service';
import { SessionService } from '@core/services/sessions/session.service';
import { CustomerService } from '@core/services/users/customer.service';
import { FleetService } from '@core/services/users/fleet.service';
import { UserService } from '@core/services/users/user.service';
import { AppState } from '@core/store/reducers';
import { AllowedFieldType } from '@core/store/reducers/order-form.reducer';
import { normalizeWhitespacesIn } from '@core/utils';
import {
  extractTagIds,
  getPaymentTypeById,
  getServiceTypeById,
  getVehicleTypeById,
} from '@core/utils/extract-tag-ids.function';
import { FormService } from '@core/utils/form-service';
import { azzFrLettersAndNumsValidator } from '@core/utils/validators';
import { FRENCH_PHONE_CODE } from '@dash/modules/phone-advisor/common/constants';
import { defineOrderType } from '@dash/modules/phone-advisor/common/utils';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from '@services/root.service';
import { orderFormActions } from '@store/actions';
import { boundMethod } from 'autobind-decorator';
import dayjs from 'dayjs';
import { cloneDeep, isArray, isEqual, isNull } from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, combineLatest, first, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';

import { GoogleCoordinates } from '@core/services/common/addresses.service';
import * as _ from 'lodash-es';
import { Message } from 'primeng/api';
import { frenchTelephoneValidator } from '../customers/utils/french-telephone.validator';
import { baseFormFieldTitles } from './base-form-field-titles.array';
import { canBeDisabledOrderFormFields } from './can-be-disabled-order-form-fields.list';
import { AddressPoiAutocompleteComponent } from './components/address-poi-autocomplete';
import { AttributeTagsComponent } from './components/attribute-tags';
import { CustomerTypeaheadComponent } from './components/customer-typeahead/customer-typeahead.component';
import { OrderPreSaveDialogComponent } from './components/order-pre-save-dialog';
import { CustomerTypeTitle } from './customer-type.constant';
import { CustomerType } from './customer-type.enum';
import { buildCreateOrderAddressDTO } from './functions/build-address.function';
import { getPassengersOrderDTO } from './functions/get-passengers-order-dto.function';
import { getServiceVehiclePaymentTypeTagsPayload } from './functions/get-service-vehicle-payment-type-tags-payload.function';
import { isAddressPOI } from './functions/is-address-poi.function';
import { UIAddress } from './interfaces/address-dto.interface';
import { BusinessZoneParams } from './interfaces/business-zone.interface';
import { ETAResponseParams } from './interfaces/eta.interface';
import { GeoMeasurment } from './interfaces/geo-measurements.interface';
import { CUSTOMERS_SUB_ROUTE_TOKEN, FORM_WITH_CTI_TOKEN, IS_EDIT_MODE_TOKEN, ORDERS_SUB_ROUTE_TOKEN } from './tokens';
import { OrderForm, OrderFormField, OrderSubGroup } from './types';
import { AddressCollectionGoogle } from '@core/modules/base-create-order/interfaces';

@Component({
  selector: 'azz-nouvelle-commande',
  templateUrl: './nouvelle-commande.component.html',
  styleUrls: ['./nouvelle-commande.component.less'],
  providers: [NgOnDestroyService, LoadWrapperService, UiOrderRulesService],
})
export class NouvelleCommandeComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  private readonly driverAssignmentService = inject(DriverAssignmentService);
  private readonly rootService = inject(RootService);
  private readonly addressService = inject(AddressesService);
  private readonly customerService = inject(CustomerService);
  private readonly fleetService = inject(FleetService);
  private readonly fb = inject(FormBuilder);
  private readonly formService = inject(FormService);
  private readonly geoAreaService = inject(GeoAreaService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly location = inject(Location);
  private readonly osrmService = inject(OSRMService);
  private readonly paOrderService = inject(PAOrderService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly sessionService = inject(SessionService);
  private readonly store = inject<Store<AppState>>(Store);
  private readonly tagService = inject(TagService);
  private readonly translate = inject(TranslateService);
  private readonly uiOrderRules = inject(UiOrderRulesService);
  private readonly userService = inject(UserService);
  private readonly utilService = inject(UtilService);

  @ViewChild(CustomerTypeaheadComponent) customerAutoComplete: CustomerTypeaheadComponent;
  @ViewChild(OrderPreSaveDialogComponent) orderPreSaveDialog: OrderPreSaveDialogComponent;
  @ViewChild(AttributeTagsComponent) attributesAndTags: AttributeTagsComponent;
  @ViewChild(PhoneComponent) azzPhoneInput: PhoneComponent;
  @ViewChild('departureAddressRef') departureAddressRef: AddressPoiAutocompleteComponent;
  @ViewChild('arrivalAddressRef') arrivalAddressRef: AddressPoiAutocompleteComponent;
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ViewChild(CustomConfirmDialogComponent) confirmDialog: CustomConfirmDialogComponent;

  @Input() minValue: number;

  public readonly isEditingMode = inject(IS_EDIT_MODE_TOKEN);
  public readonly withCTI = inject(FORM_WITH_CTI_TOKEN);

  public allowedFields$: Observable<AllowedFieldType[]>;
  public readonlyFields$: Observable<{
    isFieldReadonlyFor: {
      [K in keyof OrderFormField]: boolean;
    };
  }>;
  public readonly arrivalAddress$: Observable<UIAddress | null>;
  public readonly submitButtonTitle$ = of(this.isEditingMode).pipe(
    map(isEdit => (isEdit ? 'UPDATE' : 'FD_VALIDATE_BUTTON')),
    map(token => this.translate.instant(token))
  );

  public departureAddress: UIAddress;
  public arrivalAddress: UIAddress;
  public duration: number;
  public form: FormGroup<OrderForm>;
  public listOfTags: ITag[];
  public uiTypes = {
    payment: ORDER_TAG_TYPE.payment,
  };
  public geoMeasurmentCourseEstimation: GeoMeasurment = { duration: 0, distance: 0 };
  public geoMeasurmentETAForImmediateOrder: GeoMeasurment = { duration: 0, distance: 0 };

  public geoMeasurmentETA: GeoMeasurment = { duration: 0, distance: 0 };
  public selectedCustomer: ICustomer;
  public customerCurrentOrders: IPhoneAdvisorOrderData;
  public customerCurrentOrdersData: PhoneAdvisorOrder[] = [];

  public currentOrdersStatus: CurrentOrderStatusType[] = [
    'CREATE',
    'DISPATCHING',
    'NO_VEHICLE_FOUND',
    'CONFIRMED',
    'AT_DEPARTURE_ADDRESS',
    'TOWARDS_DESTINATION',
  ];
  public customerMostFrequentOrders: PhoneAdvisorOrder[];
  public isButtonBlocked: boolean;
  public isDepartureAddressOutsideOfBusinessZone = false;
  public geoAreaId: number = null;
  public dateRange: { min: moment.Moment; max: moment.Moment } = {
    min: moment().startOf('day'),
    max: null,
  };

  public phoneTypes: NamedOption<PhoneTypeFieldTitle>[];
  public numbersRegexp: RegExp;
  public notNumbersRegexp: RegExp;
  public minPhoneLength: number;
  public maxPhoneLength: number;

  public order: PhoneAdvisorOrder;
  public orderId: string;
  public editableOrderTime: dayjs.Dayjs;

  public isManualDispatch = false;
  public isDispatching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private formSavingSubscription: Subscription;

  public customer: ICustomer;
  public isCustomerBlocked: boolean;
  public minModifyAndDeleteDate: any;
  public orderUpdateTimeLimitMinutes = 0;
  public dispatchBeforeMinutesRestrictions$ = new BehaviorSubject({ min: 0, max: 0 });
  public addresses: { appointmentAddress?: any; destinationAddress?: any };
  public phoneNumberWatcher = new Subject<any>();
  public newCustomer = {} as INewCustomer;
  public isTagsDisabled = false;
  public isPaymentDropdownCollapsed: boolean;
  public isDropdownTouched: boolean;

  public isPhoneNumberDropdownCollapsed: boolean;
  public estimatedPrice: number;

  public estPriceUnsubscribe$: Subject<boolean>;
  public durationDistanceUnsubscribe$: Subject<boolean>;

  public orderType$: Observable<OrderType>;
  protected isDispatchDelayEnabled$: Observable<boolean>;

  public isEtaLoading = false;

  public estimatedPriceLoading = false;
  public isEstimatedPriceShown = false;
  public createOrderFailedStartString = 'CreateOrder Failed :';
  public readonly focusTriggerCounters = {
    customerPhones: 0,
    destinationAddress: 0,
    submitButton: 0,
  };

  public destinationAddressFocusTriggerCounter = 0;
  public customerType = CustomerType;
  public addNewCustomerPhoneNumber = false;
  public noRoute: boolean;
  public phoneAlreadyExists: boolean;
  public phoneExistenceChecked: boolean;
  public selectedCustomerName: ICustomer;
  public readonly showNowLabelForOrderTime$: Observable<boolean>;
  public recalculatedDispatchTime: moment.Moment;

  public isPhoneNumberFromRouteParams: boolean;
  public phoneNumberParams: string;
  public distanceShowType$ = new BehaviorSubject<TDistanceShowType | null>(null);

  public fleetParams: IFleetParametersForOtherUser = {
    changeDispatchTime: false,
    inAdvanceMaxDays: 1,
    geoArea: {
      id: '',
      immutableId: '',
      name: '',
    },
    dispatchBeforeMinutes: 0,
  };

  private readonly departureAddressSubject = new BehaviorSubject<UIAddress | null>(null);
  public readonly departureAddress$ = this.departureAddressSubject.asObservable();

  private readonly arrivalAddressSubject = new BehaviorSubject<UIAddress | null>(null);
  private isRedirectedFromSearchToCreate: boolean;
  private readonly maxMinDiffForNow = 5;
  private orderSelectedTags: ITag[] = [];
  private readonly REQUEST_DELAY_MS = 700;
  private readonly dateChanged$ = new Subject<void>();
  private fleetId: number;
  private readonly subRoutes = {
    orders: inject(ORDERS_SUB_ROUTE_TOKEN),
    customers: inject(CUSTOMERS_SUB_ROUTE_TOKEN),
  } as const;
  private readonly destroyRef = inject(DestroyRef);
  private readonly fleetParams$ = this.fleetService
    .getFleetParametersForOtherUser(this.userService.getCurrentUserInfo()?.fleet?.id)
    .pipe(shareReplay(1), takeUntilDestroyed());

  protected readonly messages = signal<Message[]>([]);

  constructor() {
    this.initData();
    this.arrivalAddress$ = this.arrivalAddressSubject.asObservable();
    this.showNowLabelForOrderTime$ = this.dateChanged$.pipe(
      map(() => this.getCombinedPickerDate().diff(moment(), 'minutes') < this.maxMinDiffForNow),
      startWith(true),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public get orderSubGroup() {
    return this.form.get('order') as FormGroup<OrderSubGroup>;
  }

  public get customerFormGroup() {
    return this.form.get('customer') as FormGroup;
  }

  public get customerPhoneControl() {
    return this.form.get('customer.phoneNumber');
  }

  public get customerAutoCompleteFormFieldErrors(): boolean {
    if (!this.customerAutoComplete) {
      return;
    }

    if (this.customerAutoComplete.form.controls.searchField.errors) {
      return true;
    }
  }

  public get customerPhoneControlormFieldErrors(): boolean {
    if (!this.customerPhoneControl) {
      return;
    }

    if (this.customerPhoneControl.errors) {
      return true;
    }
  }

  public get customerFieldControlsErrors(): boolean {
    if (
      this.form.controls.customer.controls.lastName.hasError('required') ||
      this.form.controls.customer.controls.lastName.hasError('minlength') ||
      this.form.controls.customer.controls.lastName.hasError('maxlength')
    ) {
      return true;
    } else {
      return false;
    }
  }

  public get paymentFieldControlsErrors(): boolean {
    return this.form.controls.paymentType.value.id === 'DEFAULT';
  }

  public get isValidateButtonDisabled(): boolean {
    return (
      this.form.invalid ||
      this.paymentFieldControlsErrors ||
      this.customerFieldControlsErrors ||
      this.customerPhoneControlormFieldErrors ||
      this.customerAutoCompleteFormFieldErrors ||
      this.isCustomerBlocked ||
      this.isButtonBlocked ||
      this.lacksCoordinates ||
      (this.form.controls.customer.controls.type.value === 'new' && !this.phoneExistenceChecked)
    );
  }

  private get firstNameControl() {
    return this.form.get('customer.name');
  }

  private get lastNameControl() {
    return this.form.get('customer.lastName');
  }

  private get lacksCoordinates(): boolean {
    if (this.departureAddress?.type === 'poi' || this.arrivalAddress?.type === 'poi') {
      return false;
    }

    if (!this.departureAddress?.coordinates || !this.arrivalAddress?.coordinates) {
      return true;
    }

    return this.departureAddress?.coordinates == null || this.arrivalAddress?.coordinates == null;
  }

  private get departureHasNoCoords(): boolean {
    return !this.departureAddress?.coordinates && !this.departureAddress?.poi?.coordinates;
  }

  private get arrivalHasNoCoords(): boolean {
    return !this.arrivalAddress?.coordinates && !this.arrivalAddress?.poi?.coordinates;
  }

  @boundMethod
  private handleOrderError(err: HttpErrorResponse): void {
    if (err.status === 500 && err.message) {
      const message = this.generateCreateOrderFailedError(err.message);
      this.rootService.alerts.error(message);
    } else if (err.status === 400 && ['driver.is.unreachable', 'driver.is.unavailable'].includes(err.error.reason)) {
      this.openErrorCreateOrderDialog(err.error.message);
    } else {
      throw err;
    }

    this.refresh();
  }

  private checkFleetIsNotBlocked(): void {
    const isFleetBlocked = this.userService.getCurrentUserInfo().fleet.blocked;
    if (!isFleetBlocked) {
      return;
    }
    setTimeout(() => {
      this.form.disable();
      this.departureAddressRef.form.disable();
      this.arrivalAddressRef.form.disable();
      this.customerAutoComplete.form.disable();
      this.attributesAndTags.form.disable();
      this.rootService.alerts.warn('NOTIFICATION_CANNOT_CREATE_BECAUSE_BLOCKED_FLEET');
    });
  }

  public ngOnInit(): void {
    this.listenForCustomerDistinctName(this.form);
    this.fillFormWithValuesFromStoreOnInit()
      .pipe(first())
      .subscribe(() => {
        if (this.withCTI) {
          this.getCrcTags();
        } else {
          if (this.route.snapshot.params.orderId) {
            this.orderId = this.route.snapshot.params.orderId;
            this.setFormToDefaultState();
            this.resetCustomer();
            this.resetTags();
            this.resetServiceVehiclePaymentTypes();
            this.setDefaultCustomerType();
            this.geoMeasurmentETA = { duration: 0, distance: 0 };
            this.addresses = { appointmentAddress: null, destinationAddress: null };

            setTimeout(() => {
              this.loadOrderData();
            }, 100);
          } else {
            this.getCrcTags();
          }
          if (this.route.snapshot.queryParamMap.get('createOrderState') && this.paOrderService.getCreateOrderState()) {
            this.loadCreateOrderState();
          }
        }
      });
    this.listenForReadonlyFieldsOnInit();
  }

  public ngAfterViewInit(): void {
    if (this.route.snapshot.queryParamMap.get('createOrderState') && this.paOrderService.getCreateOrderState()) {
      setTimeout(() => {
        this.setDepartureAutocomplete();
        this.setArrivalAutocomplete();
      });
    }

    const orangeLink = this.localStorageService.getLocalStorageItem('ORANGE_LINK');
    if (orangeLink) {
      window.location.replace('/#' + orangeLink);
      this.removeOrangeParamsFromLocalStorage();
    }

    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.phoneNumberParams = params.phoneNumber;
      if (!this.phoneNumberParams) {
        return;
      }
      if (this.phoneNumberParams) {
        const url = '/#' + this.router.url;
        window.location.replace(url);
      }
      this.isPhoneNumberFromRouteParams = true;
      const hasPlusInPhoneNumberParam = this.phoneNumberParams.startsWith('+');
      this.customerAutoComplete.setDefaultPhoneNumber(
        decodeURI(hasPlusInPhoneNumberParam ? `+${this.phoneNumberParams.slice(1)}` : `+${this.phoneNumberParams}`)
      );
    });

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.switchCustomerType(this.customerType.old);
        this.isPhoneNumberFromRouteParams = true;
        setTimeout(() => {
          this.customerAutoComplete.setDefaultPhoneNumber(`${this.phoneNumberParams}`);
        });
      }
    });

    this.startSubscription();
    this.checkFleetIsNotBlocked();
  }

  public ngAfterContentInit(): void {
    this.allowedFields$
      .pipe(
        filter(list => list.length > 0),
        tap(allowed => this.updateReadonlyFields(allowed)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public startSubscription() {
    if (this.formSavingSubscription) {
      this.formSavingSubscription.unsubscribe();
    }

    // save values into the store on form values change
    this.formSavingSubscription = combineLatest([this.form.valueChanges, this.arrivalAddress$, this.departureAddress$])
      .pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        takeUntilDestroyed(this.destroyRef),
        takeUntil(this.isDispatching$.pipe(filter(val => val === false)))
      )
      .subscribe(([_, arrivalAddress, departureAddress]) => {
        if (this.isEditingMode) {
          this.store.dispatch(orderFormActions.CLEAR_ALL_FIELDS());
          return;
        }

        const formValues = this.form.getRawValue();
        this.store.dispatch(
          orderFormActions.SAVE_ALL_FIELDS({
            saved: {
              ...formValues,
              address: {
                arrival: arrivalAddress,
                departure: departureAddress,
              },
            },
          })
        );
        this.arrivalAddressRef.address = arrivalAddress;
        this.departureAddressRef.address = departureAddress;
        this.setAddresses(arrivalAddress, departureAddress);
      });
  }

  public stopSubscription() {
    this.isDispatching$.next(false);
  }

  public restartSubscription() {
    this.isDispatching$.next(true);
    this.startSubscription();
  }

  public fillFormWithValuesFromStoreOnInit(): Observable<any> {
    // fill form with saved values from store
    return this.store
      .select(state => state['order-form'].formFields)
      .pipe(
        take(1),
        delay(500),
        takeUntilDestroyed(this.destroyRef),
        tap(formFields => {
          if (isNull(formFields) || this.isEditingMode) {
            return;
          }
          this.form.patchValue(formFields);

          if (formFields.customer.type === 'new') {
            this.setNewCustomerValidators();
          }

          if (formFields.address.arrival) {
            this.arrivalAddress = formFields.address.arrival;
            this.arrivalAddressSubject.next(this.arrivalAddress);
            this.setAddresses(this.arrivalAddress, this.departureAddress);
            this.setArrivalAutocomplete();
          }

          if (formFields.address.departure) {
            this.departureAddress = formFields.address.departure;
            this.departureAddressSubject.next(this.departureAddress);
            this.setAddresses(this.arrivalAddress, this.departureAddress);
            this.setDepartureAutocomplete();
          }

          if (!this.utilService.isEqualAddress(this.departureAddress, this.arrivalAddress)) {
            this.calculateEstimatedPrice();
            this.calculateDurationAndDistance();
          }

          if (!!formFields.tags && isArray(formFields.tags) && formFields.tags.length > 0) {
            this.listOfTags = cloneDeep(formFields.tags);
          }
          this.onTagsChanged();

          if (formFields.customer) {
            this.form.get('customer').patchValue(formFields.customer);
            if (!this.isPhoneNumberFromRouteParams) {
              this.customerAutoComplete.form.patchValue({
                phoneNumber: formFields.selectedCustomerPhoneNumber,
              });
            }
            this.customerAutoComplete.form.controls.searchField.updateValueAndValidity();
            this.customerAutoComplete.searchValues$
              .pipe(
                take(1),
                withLatestFrom(this.store.select(state => state['order-form'])),
                takeUntilDestroyed(this.destroyRef)
              )
              .subscribe(([found, saved]) => {
                if (!!saved.formFields.customer && !!saved.formFields.selectedCustomerPhoneNumber) {
                  this.customerAutoComplete.removeFocusOnSearchField();
                }
              });
          }
        })
      );
  }

  public listenForReadonlyFieldsOnInit(): void {
    this.allowedFields$ = this.store.pipe(map(appState => appState['order-form'].allowedFields));

    this.readonlyFields$ = this.allowedFields$.pipe(
      distinctUntilChanged((previous, current) => isEqual(previous, current)),
      map(fields => {
        const noFieldsProvided = !isArray(fields) || fields.length === 0;
        const readonlyFields = noFieldsProvided ? [] : baseFormFieldTitles.filter(title => !fields.includes(title));
        return baseFormFieldTitles.reduce(
          (accumulator, title) => {
            accumulator[title] = noFieldsProvided ? false : readonlyFields.includes(title);
            return accumulator;
          },
          {} as { [K in keyof OrderFormField | 'searchField']: boolean }
        );
      }),
      tap(shouldBeDisabled => {
        canBeDisabledOrderFormFields.forEach(field => {
          if (!shouldBeDisabled[field]) {
            return;
          }

          const control = this.form.get(field);
          const hasNoControl = !control && !(control instanceof AbstractControl);
          if (hasNoControl) {
            return;
          }

          control.disable();
        });
      }),
      map(fieldsBooleanMap => ({ isFieldReadonlyFor: fieldsBooleanMap }))
    );
  }

  public checkCustomerAutocompleteRules(searchResults: ICustomer[]): void {
    const firstCustomer = searchResults[0];

    if (!this.isPhoneNumberFromRouteParams) {
      return;
    }

    if (firstCustomer === null) {
      return this.switchToCreateCustomer(this.phoneNumberParams);
    } else if (searchResults.length === 1) {
      return this.switchToExistingCustomer(firstCustomer);
    }
  }

  public switchToExistingCustomer(customer: ICustomer): void {
    this.customerAutoComplete.form.controls.searchField.updateValueAndValidity();
    this.customerAutoComplete.onCustomerSelect(customer);
    this.customerAutoComplete.form.patchValue({ searchField: customer });
    this.customerAutoComplete.removeFocusOnSearchField();
    this.isPhoneNumberFromRouteParams = true;
  }

  public onDepartureAddressSelect(a: AddressCollectionGoogle): void {
    const address = a as UIAddress;
    this.isDepartureAddressOutsideOfBusinessZone = address.dispatchOutsideOfBusinessZone;
    this.departureAddress = address;
    this.form.markAsTouched();
    if (!this.departureAddress.type) {
      this.departureAddress.type = isAddressPOI(address) ? 'poi' : 'default';
    }
    this.recalculateEstimations();
    this.setFocusOnDestinationField();

    this.setAddresses(this.arrivalAddress, this.departureAddress);
    if (this.departureAddress.type === 'poi') {
      this.departureAddressSubject.next(this.departureAddress);
      this.departureAddressRef.detectAndUpdate();
      this.recalculateETA();

      return;
    }

    if (!address?.label) {
      const { address: addr, housenumber, city } = address;
      const newLabel = [addr, housenumber, city].filter(Boolean).join(' ');
      // @ts-ignore
      address = { ...address, label: newLabel };
    }
    this.isButtonBlocked = true;

    // not good because it is not truely reactive TODO: refactor
    this.googleCoordinates$
      .pipe(
        first(),
        switchMap(({ coordinates, postalCode, street, number: houseNumber }) => {
          this.departureAddress = {
            ...address,
            coordinates,
            postalCode,
            street,
            housenumber: houseNumber,
          };

          this.departureAddressSubject.next(this.departureAddress);
          const geoAreaIdFoundByPoint$ = this.findGeoAreaByPoint(coordinates).pipe(
            shareReplay({ refCount: true, bufferSize: 1 })
          );
          this.setAddresses(this.arrivalAddress, this.departureAddress);
          return geoAreaIdFoundByPoint$;
        }),
        catchError(() => of(null)),
        finalize(() => {
          this.isButtonBlocked = false;
        })
      )
      .subscribe((response: BusinessZoneParams[]) => {
        if (!response) {
          this.rootService.alerts.warn('COORDINATES_NOT_FOUND');
          return;
        }
        this.recalculateEstimations();
        this.departureAddressRef.detectAndUpdate();
        this.recalculateETA();
      });
  }

  private readonly googleCoordinatesSearch$ = new ReplaySubject<string>(1);
  private readonly googleCoordinates$ = this.googleCoordinatesSearch$.pipe(
    debounceTime(0),
    filter(Boolean),
    distinctUntilChanged(_.isEqual),
    switchMap(search =>
      this.addressService.getGoogleCoordinates({
        q: encodeURIComponent(search),
      })
    ),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  private readonly departureAddressCoordinates$: Observable<GoogleCoordinates> = this.departureAddress$.pipe(
    debounceTime(0),
    filter(Boolean),
    distinctUntilChanged(_.isEqual),
    switchMap(departureAddress =>
      departureAddress.type === 'poi'
        ? of({ coordinates: departureAddress.poi.coordinates, postalCode: '', street: '', number: '' })
        : this.googleCoordinates$.pipe(first())
    ),
    filter(Boolean),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  protected readonly dispatchBeforeMinutesLoading$ = new BehaviorSubject<boolean>(false);

  protected readonly dispatchBeforeMinutes$ = of(null).pipe(
    tap(() => this.dispatchBeforeMinutesLoading$.next(true)),
    switchMap(() => this.departureAddressCoordinates$),
    switchMap(({ coordinates }) =>
      this.findGeoAreaByPoint(coordinates).pipe(
        map(points => points?.[0]),
        switchMap(point =>
          this.fleetId === point.id
            ? this.geoAreaService
                .findByGeozone({
                  geoAreaId: this.fleetParams.geoArea.id,
                  latitude: coordinates.latitude,
                  longitude: coordinates.longitude,
                })
                .pipe(
                  map(geoZone => geoZone.dispatchBeforeMinutes),
                  catchError(() => of(point.dispatchBeforeMinutes))
                )
            : of(this.fleetParams.dispatchBeforeMinutes)
        ),
        catchError(() => of(this.fleetParams.dispatchBeforeMinutes)),
        tap(() => this.dispatchBeforeMinutesLoading$.next(false))
      )
    ),
    takeUntilDestroyed(),
    shareReplay(1)
  );

  public onDepartureAddressTyping() {
    if (!this.departureAddress) {
      return;
    }

    this.departureAddress = null;
    this.store.dispatch(orderFormActions.SAVE_ALL_FIELDS({ saved: { address: { departure: null } } }));
    this.departureAddressSubject.next(null);
    this.geoMeasurmentETAForImmediateOrder = { duration: 0, distance: 0 };
    setTimeout(() => this.arrivalAddressRef.detectAndUpdate()); // use for waiting until corresponding address
    // value updates and after that it's possible to remove address equality error
  }

  public onArrivalAddressSelect(address: AddressCollectionGoogle): void {
    this.arrivalAddress = address as UIAddress;
    this.form.markAsTouched();
    this.arrivalAddress.type = isAddressPOI(address) ? 'poi' : 'default';
    this.recalculateEstimations();
    this.setFocusOnFirstSubmitButton();

    if (this.arrivalAddress.type !== 'poi') {
      this.isButtonBlocked = true;
      // not good because it is not truely reactive TODO: refactor
      this.addressService
        .getGoogleCoordinates({ q: encodeURIComponent(this.arrivalAddress.label) })
        .pipe(finalize(() => (this.isButtonBlocked = false)))
        .subscribe(({ coordinates, postalCode, street, number: houseNumber }) => {
          this.arrivalAddress.coordinates = coordinates;
          this.arrivalAddress.postalCode = postalCode;
          this.arrivalAddress.street = street;
          this.arrivalAddress.housenumber = houseNumber;
          this.arrivalAddressSubject.next(this.arrivalAddress);
          this.recalculateEstimations();
          this.setAddresses(this.arrivalAddress, this.departureAddress);
        });
    } else {
      this.setAddresses(this.arrivalAddress, this.departureAddress);
      this.arrivalAddress.coordinates = address?.poi?.coordinates;
      this.arrivalAddressSubject.next(this.arrivalAddress);
    }
    this.arrivalAddressRef.detectAndUpdate();
  }

  public onArrivalAddressTyping() {
    if (this.arrivalAddress) {
      this.addresses = {
        appointmentAddress: buildCreateOrderAddressDTO(this.departureAddress),
        destinationAddress: null,
      };
      setTimeout(() => this.departureAddressRef.detectAndUpdate()); // use for waiting until corresponding address
      // value updates and after that it's possible to remove address equality error
    }
  }

  public setAddresses(arrivalAddress: UIAddress, destinationAdress: UIAddress) {
    if (!destinationAdress) {
      this.googleCoordinatesSearch$.next(null);
    } else if (destinationAdress.type !== 'poi') {
      this.googleCoordinatesSearch$.next(
        destinationAdress.label ||
          [destinationAdress.address, destinationAdress.housenumber, destinationAdress.city].filter(Boolean).join(' ')
      );
    }

    if (arrivalAddress && destinationAdress) {
      return (this.addresses = {
        appointmentAddress: this.departureAddress ? buildCreateOrderAddressDTO(this.departureAddress) : null,
        destinationAddress: this.arrivalAddress ? buildCreateOrderAddressDTO(this.arrivalAddress) : null,
      });
    }
    if (!arrivalAddress && !destinationAdress) {
      return (this.addresses = {
        appointmentAddress: null,
        destinationAddress: null,
      });
    }
    if (arrivalAddress && !destinationAdress) {
      return (this.addresses = {
        appointmentAddress: null,
        destinationAddress: buildCreateOrderAddressDTO(this.arrivalAddress),
      });
    }
    if (destinationAdress && !arrivalAddress) {
      this.addresses = {
        appointmentAddress: buildCreateOrderAddressDTO(this.departureAddress),
        destinationAddress: null,
      };
    }
  }

  public clearDepartureAdress(): void {
    this.departureAddress = null;
    this.departureAddressRef.address = null;
    this.departureAddressSubject.next(null);
    this.setAddresses(this.arrivalAddress, this.departureAddress);
    this.store.dispatch(orderFormActions.CLEAR_ALL_FIELDS());
    const formValues = this.form.getRawValue();
    this.store.dispatch(
      orderFormActions.SAVE_ALL_FIELDS({
        saved: {
          ...formValues,
          tags: cloneDeep(this.listOfTags),
          address: {
            arrival: this.arrivalAddress,
            departure: null,
          },
        },
      })
    );

    this.recalculateETA();
  }

  public clearArrivalAddress(): void {
    this.arrivalAddress = null;
    this.arrivalAddressRef.address = null;
    this.setAddresses(this.arrivalAddress, this.departureAddress);
    this.store.dispatch(orderFormActions.CLEAR_ALL_FIELDS());
    const formValues = this.form.getRawValue();
    this.store.dispatch(
      orderFormActions.SAVE_ALL_FIELDS({
        saved: {
          ...formValues,
          tags: cloneDeep(this.listOfTags),
          address: {
            arrival: null,
            departure: this.departureAddress,
          },
        },
      })
    );
  }

  public invertAddresses(): void {
    [this.departureAddress, this.arrivalAddress] = [this.arrivalAddress, this.departureAddress];
    this.departureAddressRef.address = this.departureAddress;
    this.arrivalAddressRef.address = this.arrivalAddress;
    this.isDepartureAddressOutsideOfBusinessZone = false;
    this.arrivalAddressSubject.next(this.arrivalAddress);
    this.departureAddressSubject.next(this.departureAddress);
    this.setDepartureAutocomplete();
    this.setArrivalAutocomplete();
    this.resetDurationAndDistance();
    this.resetEstimatedPrice();
    this.calculateDurationAndDistance();
    this.calculateEstimatedPrice();
    this.setAddresses(this.arrivalAddress, this.departureAddress);
    const coordinates = this.departureAddress ? this.departureAddress.coordinates : null;
    if (coordinates) {
      this.findGeoAreaByPoint(coordinates).subscribe();
    }

    if (this.departureAddress) {
      this.onDepartureAddressSelect(this.departureAddress);
    }
  }

  public initData() {
    this.fleetId = this.userService.getCurrentUserInfo()?.fleet?.id;
    this.notNumbersRegexp = this.utilService.notNumbersRegexp;
    this.numbersRegexp = this.utilService.numbersRegexp;
    this.dateRange.max = this.getMaxDateRange(this.fleetParams.inAdvanceMaxDays);

    this.initForm();
    this.orderType$ = combineLatest([
      this.form.controls.order.controls.date.valueChanges.pipe(filter(Boolean), distinctUntilChanged()),
      this.form.controls.order.controls.time.valueChanges.pipe(filter(Boolean), distinctUntilChanged()),
    ]).pipe(
      debounceTime(0),
      map(([date, time]) => {
        return defineOrderType({
          time,
          date: helpers.convertDateStrToMoment(date),
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isDispatchDelayEnabled$ = this.fleetParams$.pipe(
      mergeMap(params =>
        params.changeDispatchTime
          ? combineLatest([this.departureAddress$, this.orderType$]).pipe(
              debounceTime(0),
              distinctUntilChanged(_.isEqual),
              map(([departAddress, orderType]) => !!departAddress && orderType !== 'ORDER'),
              shareReplay({ bufferSize: 1, refCount: true })
            )
          : of(false)
      )
    );

    this.orderType$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(orderType => {
      if (orderType === 'ORDER') {
        this.form.controls.driver.enable();
      } else {
        this.form.controls.driver.setValue(null);
        this.form.controls.driver.disable();
      }
    });

    this.listenForPickerDateChanges();
    this.cancelSelectedTags();
    this.phoneTypes = this.initPhoneTypes();
    this.minPhoneLength = this.utilService.getMinPhoneNumberLength() + 3;
    this.maxPhoneLength = this.utilService.getMaxPhoneNumberLength() + 3;
    this.initPhoneNumberWatcher();
    this.handlePhoneNumberChanging();
    this.loadFleetParams();
    this.isManualDispatch = this.userService.getCurrentUserInfo()?.fleet?.dispatchManually;
    this.isPhoneNumberFromRouteParams = false;
  }

  public initForm() {
    const mainForm: FormGroup<OrderForm> = this.fb.group({
      anonymousPassenger: this.fb.control(false),
      comment: this.fb.control(''),
      appointmentComment: this.fb.control(''),
      destinationComment: this.fb.control(''),
      passengerName: this.fb.control(''),
      selectedCustomerPhoneNumber: this.fb.control(''),
      driver: this.fb.control(null),
      serviceType: this.fb.control(ORDER_TAG_TYPE.service[0], [Validators.required]),
      vehicleType: this.fb.control(ORDER_TAG_TYPE.vehicle[0], [Validators.required]),
      paymentType: this.fb.control(ORDER_TAG_TYPE.payment[0], [Validators.required]),
      order: this.fb.group({
        type: this.fb.control<OrderStatusType>('CREATE'),
        date: this.fb.control(null, [Validators.required]),
        time: this.fb.control(null, [Validators.required, DateValidators.validTime]),
        dispatchDelay: this.fb.control({ value: null, disabled: true }),
      }),
      customer: this.fb.group({
        id: this.fb.control(''),
        type: this.fb.control<CustomerTypeTitle>('old', [Validators.required]),
        name: this.fb.control(''),
        lastName: this.fb.control(''),
        phoneNumber: this.fb.control(FRENCH_PHONE_CODE),
        phoneCode: this.fb.control(FRENCH_PHONE_CODE),
        phoneType: this.fb.control(''),
      }),
    });
    this.form = mainForm;

    const customerPhoneNumberControl = this.form.get('customer.phoneNumber');

    // Phone Number Auto Update Logic
    customerPhoneNumberControl.valueChanges
      .pipe(
        filter(value => Boolean(value) || value === ''),
        skip(2),
        distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
        debounceTime(600),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((phoneNumber: string) => {
        this.addPhonePlusSignIfPlusIsAbsent(phoneNumber);
        this.phoneNumberWatcher.next(null);
        this.updatePhoneCodeControlValue();
      });
  }

  public generateCreateOrderFailedError(originError: string): string {
    let resultStr = '';
    if (originError.search(new RegExp(this.createOrderFailedStartString)) !== -1) {
      resultStr = originError.replace(this.createOrderFailedStartString, '').trim();
      resultStr = resultStr[0].toUpperCase() + resultStr.slice(1);
    } else {
      resultStr = originError;
    }
    return resultStr;
  }

  public loadCreateOrderState(): void {
    this.onDepartureAddressSelect(this.paOrderService.getCreateOrderState().departureAddress);
    this.onArrivalAddressSelect(this.paOrderService.getCreateOrderState().arrivalAddress);
    this.fillCreateOrderForm();
    this.customerService
      .getCustomerById(this.paOrderService.getCreateOrderState().customerId)
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(2000))
      .subscribe((customer: ICustomer) => this.setCustomerInfo(customer));
  }

  public setCustomerInfo(customer: ICustomer): void {
    this.selectedCustomer = customer;
    this.setEditOrderCustomer();
  }

  public setEditOrderCustomer() {
    const { phoneCode, phoneNumber } = this.selectedCustomer.phones[0];
    const customerPhone = phoneCode + phoneNumber;
    this.form.patchValue({
      customer: {
        id: this.selectedCustomer.id.toString(),
        name: this.selectedCustomer.name,
        lastName: this.selectedCustomer.lastName,
      },
      selectedCustomerPhoneNumber: customerPhone,
      passengerName: this.order.passengers[0],
    });
    this.getCustomerOrders();
    this.getCustomerMostFrequentOrders();

    if (this.customerAutoComplete) {
      this.customerAutoComplete.setAutocompleteFieldValueName(this.selectedCustomer);
    }
  }

  public setCreateOrderState() {
    const {
      anonymousPassenger,
      appointmentComment,
      comment,
      customer,
      destinationComment,
      order,
      driver,
      passengerName,
      paymentType,
      serviceType,
      vehicleType,
    } = this.form.getRawValue();

    const state = {
      anonymousPassenger,
      appointmentComment,
      arrivalAddress: this.arrivalAddress,
      comment,
      customerId: customer.id,
      departureAddress: this.departureAddress,
      destinationComment,
      orderDate: order.date,
      orderTime: order.time,
      orderType: order.type,
      driver,
      passengerName,
      paymentType,
      serviceType,
      tags: this.generateTagsPayload(),
      vehicleType,
    };
    this.paOrderService.setCreateOrderState(state);
  }

  public fillCreateOrderForm(): void {
    const orderState = this.paOrderService.getCreateOrderState();
    this.form.patchValue({
      appointmentComment: orderState.appointmentComment,
      destinationComment: orderState.destinationComment,
      order: {
        type: orderState.orderType,
        time: orderState.orderTime,
        date: orderState.orderDate,
      },
      anonymousPassenger: orderState.anonymousPassenger,
      passengerName: orderState.passengerName,
      comment: orderState.comment,
    });
    this.setEditOrderPassangerInfo(orderState);
  }

  // as I understood, the author wanted to add delay before check request
  public initPhoneNumberWatcher() {
    this.phoneNumberWatcher
      .pipe(throttleTime(this.REQUEST_DELAY_MS), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.handlePhoneNumberChanging());
  }

  public onCancel(): void {
    this.setFormToDefaultState();
    this.resetCustomer();
    this.resetFalgs();
    this.attributesAndTags.uncheckAllTags();
    this.geoMeasurmentETA = { duration: 0, distance: 0 };
    this.addresses = { appointmentAddress: null, destinationAddress: null };
    this.resetUrlStateAndReloadPage();
    this.driverAssignmentService.reload();
  }

  public onCancelBtnClick(): void {
    this.refresh();

    if (!this.isEditingMode) {
      this.onCancel();

      return;
    }

    this.router.navigate([this.subRoutes.orders, this.orderId]);
  }

  public setNewCustomerValidators() {
    this.formService.setValidators(this.customerFormGroup, 'name', [azzFrLettersAndNumsValidator]);
    this.formService.setValidators(this.customerFormGroup, 'lastName', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      azzFrLettersAndNumsValidator,
    ]);
    this.formService.setValidators(this.customerFormGroup, 'phoneNumber', [
      Validators.required,
      Validators.pattern(this.numbersRegexp),
      frenchTelephoneValidator,
    ]);
    this.formService.setValidators(this.customerFormGroup, 'phoneCode', [Validators.required]);
    this.formService.setValidators(this.customerFormGroup, 'phoneType', [Validators.required]);
  }

  public isPhoneTypeAvailable(): boolean {
    const {
      customer: { phoneNumber, phoneCode },
    } = this.form.getRawValue();

    if (!phoneNumber || !phoneNumber.length || phoneCode !== FRENCH_PHONE_CODE) {
      return false;
    }

    const { maxLength, minLength, codeLength } = FrenchPhoneConstraints;
    const phoneNoCode = phoneNumber.slice(codeLength);

    return phoneNoCode.length >= minLength && phoneNoCode.length <= maxLength;
  }

  public isMobileNumber(phoneNumber: string): boolean {
    return this.utilService.isMobileNumber(phoneNumber, this.form.get('customer').value?.phoneCode?.length);
  }

  public removeNewCustomerValidators() {
    const controlsArray = ['name', 'lastName', 'phoneNumber', 'phoneCode', 'phoneType'];
    for (const controlName of controlsArray) {
      this.formService.removeValidators(this.customerFormGroup, controlName);
    }
  }

  public setFormToDefaultState(): void {
    const controlsArrayForReset = [
      'appointmentComment',
      'destinationComment',
      'passengerName',
      'anonymousPassenger',
      'customer.id',
      'customer.lastName',
      'customer.name',
      'customer.phoneCode',
      'customer.phoneType',
      'comment',
      'driver',
    ];

    for (const controlName of controlsArrayForReset) {
      const control = this.form.get(controlName);
      control.reset();
    }
    if (!this.isNewCustomer() && this.customerAutoComplete) {
      this.customerAutoComplete.reset();
    }
    this.resetServiceVehiclePaymentTypes();
    this.resetDurationAndDistance();
    this.resetEstimatedPrice();
    this.setAddressesToDefaultState();
    this.cancelSelectedTags();
    this.form.get('customer').get('phoneNumber').setValue(FRENCH_PHONE_CODE);
    this.form.get('customer').get('phoneCode').setValue('');
    this.form.controls.order.controls.date.reset();
    this.form.controls.order.controls.time.reset();
    this.form.controls.order.controls.dispatchDelay.reset();
    this.enteredDispatchDelay = null;
  }

  public isNewCustomer(): boolean {
    return this.form?.getRawValue()?.customer?.type === 'new';
  }

  private listOfTagsCache: ITag[];

  public getSelectedFilters() {
    const tags = this.listOfTags ? this.listOfTags.filter(tag => tag.selected) : [];

    if (!isEqual(this.listOfTagsCache, tags)) {
      this.listOfTagsCache = tags;
    }

    return this.listOfTagsCache;
  }

  public setFocusToCustomerPhonesField(): void {
    this.focusTriggerCounters.customerPhones++;
  }

  public sortCustomerPhonesByOrder(): void {
    if (!this.selectedCustomer || !this.selectedCustomer?.phones || this.selectedCustomer.phones.length === 0) {
      return;
    }

    this.selectedCustomer.phones.sort(
      (first: ICustomerPhone, second: ICustomerPhone) => first.orderNumber - second.orderNumber
    );
  }

  public setAddressesToDefaultState(): void {
    this.departureAddressSubject.next(null);
    this.arrivalAddressSubject.next(null);
    this.departureAddressRef.reset();
    this.arrivalAddressRef.reset();
    this.departureAddress = null;
    this.arrivalAddress = null;
  }

  public resetCustomer(): void {
    if (!this.selectedCustomer) {
      return;
    }

    this.selectedCustomer = null;
    this.customerMostFrequentOrders = null;
    this.customerCurrentOrders = null;
    this.customerCurrentOrdersData = [];
    this.form.patchValue({
      customer: {
        id: null,
        name: null,
        lastName: null,
        phoneNumber: FRENCH_PHONE_CODE,
      },
      passengerName: null,
      selectedCustomerPhoneNumber: null,
    });
    this.resetPaymentType();
  }

  public redirectToExistingClient(): void {
    this.isRedirectedFromSearchToCreate = false;

    this.form.patchValue({
      customer: {
        id: null,
        name: null,
        lastName: null,
      },
      passengerName: null,
      selectedCustomerPhoneNumber: null,
    });

    this.switchCustomerType('old');
    setTimeout(() => {
      if (this.customerAutoComplete) {
        this.removeNewCustomerValidators();
        this.customerAutoComplete.focusOnSearchField();
        this.customerAutoComplete.setDefaultPhoneNumber(this.form.get('customer.phoneNumber').value);
      }
      this.resetPhoneNumberFieldForNewCustomer();
    });
  }

  public resetPhoneNumberFieldForNewCustomer(): void {
    this.form.patchValue({
      customer: {
        phoneNumber: FRENCH_PHONE_CODE,
      },
    });
    this.form.get('customer.phoneNumber').markAsPristine();
    this.form.get('customer.phoneNumber').markAsUntouched();
  }

  public switchToCreateCustomer(searchValue: string): void {
    this.form.patchValue({ customer: { type: 'new' } });
    this.changeCustomer();

    if (!Number.isNaN(Number(searchValue))) {
      this.form.patchValue({ customer: { phoneNumber: normalizeNumber(searchValue) } });
    } else {
      this.form.patchValue({ customer: { lastName: searchValue } });
      this.form.markAllAsTouched();
    }
    this.isPhoneNumberFromRouteParams = false;
  }

  public generateCustomerData(): INewCustomer {
    const { customer } = this.form.getRawValue();
    return {
      name: customer.name || null,
      lastName: customer.lastName,
      phones: [
        {
          phoneType: customer.phoneType,
          phoneNumber: this.removeZeroFromPhoneNumber(),
          phoneCode: customer.phoneCode,
          orderNumber: 1,
        },
      ],
      smsNotificationEnabled: this.localStorageService.checkSmsEnabled(),
    };
  }

  public updateCustomerData(customer: any) {
    this.form.patchValue({
      customer: { id: customer?.id },
      passengerName: customer?.name ? `${customer?.name} ${customer?.lastName}` : `${customer?.lastName}`,
    });
  }

  public syncPassengerName(): void {
    const { customer } = this.form.getRawValue();
    const passengerName = this.utilService.tagRemoveFalsyValueFunc`${customer?.name} ${customer?.lastName}`;
    this.form.patchValue({ passengerName });
  }

  public syncTagsField(): void {
    const formValues = this.form.getRawValue();
    this.store.dispatch(
      orderFormActions.SAVE_ALL_FIELDS({
        saved: {
          ...formValues,
          tags: cloneDeep(this.listOfTags),
        },
      })
    );
  }

  public onChangeTag(): void {
    this.listOfTags = this.attributesAndTags.listOfTags;
    this.form.get('order').markAsTouched();
    this.syncTagsField();
    const hotelTag = this.listOfTags.filter(tag => tag.selected).find(tag => tag.name === 'Hotel');

    if (hotelTag) {
      this.form.get('anonymousPassenger').disable();
      this.form.get('anonymousPassenger').setValue(false);
    } else {
      this.form.get('anonymousPassenger').enable();
    }

    this.onTagsChanged();
  }

  public goBack() {
    this.router.navigate([this.subRoutes.orders, this.isEditingMode ? this.orderId : '']);
  }

  public navigateToOrdersPage(): void {
    this.router.navigate([this.subRoutes.orders]);
  }

  public fillEditOrderForm() {
    if (this.isEditingMode) {
      this.setEditOrderDate();
    }
    this.handleAllTags(this.order.tags);
    this.setOrderAddress(this.order, 'appointmentAddress');
    this.setOrderAddress(this.order, 'destinationAddress');
    this.setDepartureAutocomplete();
    this.setArrivalAutocomplete();
    this.calculateDurationAndDistance();
    this.onTagsChanged();
    this.calculateEstimatedPrice();
    this.setEditOrderAllComments(this.order);
    this.setEditOrderPassangerInfo(this.order);
    this.orderSelectedTags = this.order.tags;
    this.handleAllTags(this.order.tags);
    if (this.order.tags.length) {
      this.setEditOrderSelectedTags(this.order.tags);
    }
  }

  public setPreviousOrderData(order: PhoneAdvisorOrder): void {
    this.resetTags();
    this.handleAllTags(order.tags);
    this.setDepartureAddressFromOrder(order);
    this.setArrivalAddressFromOrder(order);
    this.resetDurationAndDistance();
    this.resetEstimatedPrice();
    this.setAddresses(this.arrivalAddress, this.departureAddress);
    if (!this.utilService.isEqualAddress(this.departureAddress, this.arrivalAddress)) {
      this.calculateEstimatedPrice();
      this.calculateDurationAndDistance();
      this.onTagsChanged();
    }

    this.setPreviousOrderPhoneNumber(order);
    this.findGeoAreaByPoint(this.departureAddress.coordinates).pipe(first()).subscribe();
    this.departureAddressRef.address = this.departureAddress;
    this.arrivalAddressRef.address = this.arrivalAddress;

    const formType = this.form.getRawValue().order.type as OrderStatusType;
    const status = this.order?.status ? this.order.status : formType;
    const isAllowed = this.uiOrderRules.dispatchOrder.isAllowedToEditAllFields(status);
    if (isAllowed) {
      this.handleAllTags(order.tags);
    }
    this.setEditOrderSelectedTags(this.orderSelectedTags);

    this.syncTagsField();
    this.onDepartureAddressSelect(this.departureAddress);
  }

  public getHeaderText() {
    return this.withCTI
      ? this.translate.instant('CREATE_NEW_ORDER_WITH_CTI')
      : this.isEditingMode
        ? this.translate.instant('PA_MODIFY_ORDER_BACK_TITLE')
        : this.translate.instant('PA_CREATE_NEW_ORDER_BACK_TITLE');
  }

  public onSubmit() {
    this.addresses = {
      appointmentAddress: buildCreateOrderAddressDTO(this.departureAddress),
      destinationAddress: buildCreateOrderAddressDTO(this.arrivalAddress),
    };

    this.newCustomer = this.generateCustomerData();
    this.markFormFieldsAsTouched();

    if (!this.isFormValid()) {
      return this.scrollToInvalidFields();
    }

    if (this.noRoute) {
      this.rootService.alerts.warn('NOT_CREATE_ORDER_WITH_SUCH_ROUTE');
    } else {
      this.orderPreSaveDialog.showDialog(this.translate.instant('PA_CREATE_CONFIRMATION_HEADER'), '');
    }
  }

  public resetUrlStateAndReloadPage() {
    this.location.replaceState(RouteUrls.dash.phoneAdvisor, 'create-order');
    const orangeLink = this.localStorageService.getLocalStorageItem('ORANGE_LINK');
    if (orangeLink) {
      this.removeOrangeParamsFromLocalStorage();
      window.location.reload();
    }
  }

  protected refreshing$ = new BehaviorSubject(false);

  private refresh(): void {
    this.refreshing$.next(true);

    setTimeout(() => {
      this.refreshing$.next(false);
      this.driverAssignmentService.reload();
    });
  }

  public submit(): void {
    let geoAreaIdFoundByPoint;
    this.isButtonBlocked = true;

    if (this.isDepartureAddressOutsideOfBusinessZone) {
      const payload = this.generateOrderData();
      payload.appointmentAddress.geoAreaId = null;

      this.paOrderService
        .create(payload)
        .pipe(
          finalize(() => this.resetFalgs()),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((res: any) => {
          this.showCreateOrderDialog(res);
          this.onCancel();
          this.resetServiceVehiclePaymentTypes();
          this.setDefaultCustomerType();
          this.refresh();
        }, this.handleOrderError);
      return;
    }

    const createCustomer$ = this.isNewCustomer()
      ? this.customerService.createCustomer(this.generateCustomerData())
      : of(null);

    createCustomer$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(customer => {
          if (customer) {
            this.updateCustomerData(customer);
          }
          const payload = this.generateOrderData();
          payload.appointmentAddress.geoAreaId = geoAreaIdFoundByPoint;
          return this.paOrderService.create(payload).pipe(takeUntilDestroyed(this.destroyRef));
        }),
        finalize(() => this.resetFalgs()),
        takeUntilDestroyed(this.destroyRef),
        catchError(err => {
          const phoneNumber = this.form.get('customer.phoneNumber').value;
          this.checkPhoneNumberExist(phoneNumber);

          throw err;
        })
      )
      .subscribe((res: any) => {
        this.showCreateOrderDialog(res);
        this.onCancel();
        this.resetServiceVehiclePaymentTypes();
        this.setDefaultCustomerType();
        this.switchCustomerType(this.customerType.old);
        this.refresh();
      }, this.handleOrderError);
  }

  public setDefaultCustomerType(): void {
    this.form.get('customer').get('type').setValue('old');
    this.formService.setValidators(this.form, 'selectedCustomerPhoneNumber', [Validators.required]);
    setTimeout(() => {
      if (this.customerAutoComplete) {
        this.removeNewCustomerValidators();
        this.customerAutoComplete.focusOnSearchField();
      }
    });
  }

  public removeZeroFromPhoneNumber() {
    const { customer } = this.form.getRawValue();

    let phoneNumber = customer.phoneNumber?.slice(customer.phoneCode.length);
    if (phoneNumber.length === 10 && phoneNumber.slice(0, 1) === '0' && customer.phoneCode === FRENCH_PHONE_CODE) {
      phoneNumber = phoneNumber.slice(1, Number(customer.phoneNumber?.slice(customer.phoneCode.length)));
    }
    return phoneNumber;
  }

  public generateOrderData() {
    const values = this.form.getRawValue();

    return {
      id: this.isEditingMode ? this.orderId : null,
      aggregatorOrderId: this.isEditingMode ? this.order.aggregatorOrderId : null,
      date: this.generateOrderDate(),
      appointmentAddress: buildCreateOrderAddressDTO(this.departureAddress),
      destinationAddress: buildCreateOrderAddressDTO(this.arrivalAddress),
      destinationAddressComment: values.destinationComment,
      orderDispatchBeforeMinutes: values.order.dispatchDelay,
      appointmentAddressComment: values.appointmentComment,
      privateOrder: false,
      customer: {
        name: values.customer.name
          ? `${values.customer.name} ${values.customer.lastName}`
          : `${values.customer.lastName}`,
        phoneNumber: values.selectedCustomerPhoneNumber
          ? values.selectedCustomerPhoneNumber
          : values.customer.phoneCode + this.removeZeroFromPhoneNumber(),
        id: values.customer.id,
      },
      customerId: values.customer.id,
      anonymousPassenger: values.anonymousPassenger,
      passengers: [
        getPassengersOrderDTO({
          customerName: values.customer.name,
          customerLastName: values.customer.lastName,
          passengerName: values.passengerName,
        }),
      ],
      tags: this.generateTagsPayload(),
      comment: values.comment,
      driverId: values.driver,
    };
  }

  public isFormValid(): boolean {
    if (this.customerAutoComplete && this.customerAutoComplete.form?.enabled) {
      return (
        // this.form.valid &&
        this.departureAddressRef.form.valid &&
        this.arrivalAddressRef.form.valid &&
        this.customerAutoComplete.form.valid &&
        !!this.selectedCustomer
      );
    } else {
      return this.form.valid && this.departureAddressRef.form.valid && this.arrivalAddressRef.form.valid;
    }
  }

  public markFormFieldsAsTouched() {
    if (this.customerAutoComplete) {
      this.formService.markFieldsAsTouched(this.form);
      this.departureAddressRef.markFieldsAsTouched();
      this.arrivalAddressRef.markFieldsAsTouched();
      this.formService.markFieldsAsTouched(this.customerAutoComplete.form);
    } else {
      this.formService.markFieldsAsTouched(this.form);
      this.departureAddressRef.markFieldsAsTouched();
      this.arrivalAddressRef.markFieldsAsTouched();
    }
  }

  public scrollToInvalidFields() {
    if (this.customerAutoComplete) {
      this.formService.scrollToFirstInvalidControl([
        this.formElemRef,
        this.customerAutoComplete.formElemRef,
        this.departureAddressRef.formElemRef,
        this.arrivalAddressRef.formElemRef,
      ]);
    } else {
      this.formService.scrollToFirstInvalidControl([
        this.formElemRef,
        this.departureAddressRef.formElemRef,
        this.arrivalAddressRef.formElemRef,
      ]);
    }
  }

  public isAddressValid(address: string) {
    return !!this[address].value;
  }

  public setFocusOnDestinationField() {
    this.focusTriggerCounters.destinationAddress++;
  }

  public setFocusOnFirstSubmitButton(): void {
    this.focusTriggerCounters.submitButton++;
  }

  public calculateEstimatedPrice(): void {
    if (!this.departureAddress || !this.arrivalAddress || this.departureHasNoCoords || this.arrivalHasNoCoords) {
      return;
    }

    this.estPriceUnsubscribe$ = new Subject<boolean>();
    const pickupCoords = this.departureAddress.poi
      ? this.departureAddress.poi.coordinates
      : this.departureAddress.coordinates;
    const destinationCoords = this.arrivalAddress.poi
      ? this.arrivalAddress.poi.coordinates
      : this.arrivalAddress.coordinates;

    const { longitude: pickupLon, latitude: pickupLat } = pickupCoords;
    const { longitude: dropoffLon, latitude: dropoffLat } = destinationCoords;

    this.isEstimatedPriceShown = true;
    this.estimatedPriceLoading = true;
    this.sessionService
      .getEstimatedPrice({ pickupLat, pickupLon, dropoffLat, dropoffLon })
      .pipe(
        takeUntil(this.estPriceUnsubscribe$),
        finalize(() => (this.estimatedPriceLoading = false))
      )
      .subscribe((data: { price: number }) => {
        if (this.departureAddress && this.arrivalAddress) {
          this.estimatedPrice = data.price;
        }
      });
  }

  public setEditOrderAllComments(order: PhoneAdvisorOrder): void {
    this.form.patchValue({
      appointmentComment: order.appointmentAddressComment,
      destinationComment: order.destinationAddressComment,
      comment: order.comment,
    });
  }

  public setEditOrderPassangerInfo(order) {
    this.form.get('anonymousPassenger').setValue(order.anonymousPassenger);
  }

  public setEditOrderDate() {
    const dateMoment = helpers.convertDateStrToMoment(this.order.date);
    this.form.get('order').get('date').setValue(dateMoment.format('L'));
    this.form.get('order').get('time').setValue(dateMoment.format('HH:mm'));
  }

  public onUpdateOrder(): void {
    if (!this.canEditOrCancelOrder()) {
      this.rootService.alerts.warn('CANNOT_MODIFY_ORDER');
    }
    if (!this.isFormValid()) {
      return this.scrollToInvalidFields();
    }

    if (this.noRoute) {
      this.rootService.alerts.warn('NOT_CREATE_ORDER_WITH_SUCH_ROUTE');
    } else {
      this.updateOder();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromEstimatedPrice();
    this.unsubscribeFromDurationDistance();
    this.removeOrangeParamsFromLocalStorage();
  }

  public removeOrangeParamsFromLocalStorage(): void {
    this.localStorageService.setLocalStorageItem('ORANGE_LINK', null);
  }

  public changeCustomer(): void {
    this.isCustomerBlocked = null;
    if (this.isRedirectedFromSearchToCreate && this.isNewCustomer()) {
      this.form.patchValue({
        customer: {
          name: null,
          lastName: null,
          phoneNumber: FRENCH_PHONE_CODE,
        },
      });
      this.selectedCustomer = null;
      return;
    }

    if (this.isRedirectedFromSearchToCreate && !this.isNewCustomer()) {
      setTimeout(() => {
        this.customerAutoComplete.setAutocompleteFieldValueName(this.selectedCustomerName);
        this.selectedCustomer = this.selectedCustomerName;
      });
      return;
    }

    this.resetFeildsForNewCustomer();
    if (this.isNewCustomer()) {
      this.setNewCustomerValidators();
      this.formService.removeValidators(this.form, 'selectedCustomerPhoneNumber');
      this.form.get('selectedCustomerPhoneNumber').reset();
    } else {
      this.formService.setValidators(this.form, 'selectedCustomerPhoneNumber', [Validators.required]);
      setTimeout(() => {
        if (this.customerAutoComplete) {
          this.removeNewCustomerValidators();
          this.customerAutoComplete.focusOnSearchField();
        }
        this.resetPhoneNumberFieldForNewCustomer();
      });
    }
  }

  public cancelSelectedTags(): void {
    if (!this.listOfTags) {
      return;
    }
    if (this.listOfTags) {
      this.listOfTags.forEach((tag: ITag) => (tag.selected = false));
    }
  }

  public resetTags(): void {
    if (!this.listOfTags || !isArray(this.listOfTags)) {
      return;
    }

    if (this.listOfTags) {
      this.listOfTags.forEach((tag: ITag) => (tag.selected = false));
    }
  }

  public resetFeildsForNewCustomer() {
    this.form.get('passengerName').reset();
    this.form.controls.customer.controls.lastName.reset();
    this.form.controls.customer.controls.name.reset();
    this.resetCustomer();
    this.resetFalgs();
  }

  public getCrcTags(setSelectedTags?: boolean) {
    if (!!this.listOfTags && isArray(this.listOfTags)) {
      return;
    }

    this.tagService
      .getCrcTags()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ITag[]) => {
        const listOfTags = res
          .map(tag => ({
            ...tag,
            name: tag.name.charAt(0).toUpperCase() + tag.name.slice(1).toLowerCase(),
          }))
          .sort((firstTag, secondTag) => firstTag.crcPriority - secondTag.crcPriority);

        if (!isArray(this.listOfTags) || this.listOfTags.length === 0) {
          this.listOfTags = cloneDeep(listOfTags);
        }

        if (setSelectedTags) {
          this.setEditOrderSelectedTags(this.orderSelectedTags);
        }
      });
  }

  public setPreviousOrderPhoneNumber(order: PhoneAdvisorOrder): void {
    if (!order.customer || !order.customer.phoneNumber) {
      return;
    }

    for (const item of this.selectedCustomer.phones) {
      if (`${item.phoneCode}${item.phoneNumber}` === order.customer.phoneNumber) {
        this.form.get('selectedCustomerPhoneNumber').setValue(order.customer.phoneNumber);
        break;
      }
    }
  }

  public requestOrderTimeConstraints(order: PhoneAdvisorOrder): void {
    this.fleetService
      .getFleetConfigForAll()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ orderUpdateTimeLimitSeconds, defaultDispatchBeforeMinutes }) => {
        this.orderUpdateTimeLimitMinutes = orderUpdateTimeLimitSeconds / 60;
        this.editableOrderTime = getAllFieldsEditableTimeLimit({
          dispatchBeforeMinutes: defaultDispatchBeforeMinutes,
          orderDateRaw: order.date,
        });

        this.uiOrderRules.setTimeConstaints({
          updateTimeLimitMinutes: this.orderUpdateTimeLimitMinutes,
          dispatchBeforeMinutes: defaultDispatchBeforeMinutes,
          orderDateRaw: this.order.date,
        });
      });
  }

  public changeDropdownState() {
    this.isPaymentDropdownCollapsed = !this.isPaymentDropdownCollapsed;
    this.isDropdownTouched = true;

    this.driverAssignmentService.setTags(this.getDriversTags());

    if (!this.isPaymentDropdownCollapsed) {
      this.recalculateETA();
    }
  }

  public canEditOrCancelOrder(): boolean {
    if (this.isManualDispatch) {
      return true;
    }

    if (this.editableOrderTime) {
      const now = dayjs();
      const isEditableTime = now.isBefore(this.editableOrderTime);
      return isEditableTime;
    }

    return false;
  }

  public setEditOrderSelectedTags(selectedTags: ITag[]): void {
    const selectedTagIds = selectedTags.map(tag => tag.id);
    this.listOfTags = this.listOfTags.map(tag => {
      tag.selected = selectedTagIds.includes(tag?.id);
      return tag;
    });

    this.onTagsChanged();
  }

  public setOrderAddress(order: PhoneAdvisorOrder, addressField: 'appointmentAddress' | 'destinationAddress'): void {
    const address = order[addressField];
    let properties;
    if (address.poi && address.poi.label) {
      properties = address.poi.deleted ? null : { ...address.poi, type: 'poi' };
    } else {
      properties = {
        type: 'default',
        city: address.city,
        postcode: address.postalCode,
        street: address.street,
        housenumber: address.number,
      };
    }
    const componentAddress = addressField === 'appointmentAddress' ? 'departureAddress' : 'arrivalAddress';
    this[componentAddress] = properties
      ? {
          type: properties.type,
          coordinates: {
            latitude: address.position.latitude,
            longitude: address.position.longitude,
          },
          poi: {
            ...address.poi,
            coordinates: {
              ...address.position,
            },
          },
          address: address.street,
          label: address.description,
          city: address.city,
          postalCode: address.postalCode,
          street: address.street,
          housenumber: address.number,
          dispatchOutsideOfBusinessZone: properties.dispatchOutsideOfBusinessZone,
        }
      : null;
    if (componentAddress === 'arrivalAddress') {
      this.arrivalAddressSubject.next(this.arrivalAddress);
    } else if (componentAddress === 'departureAddress') {
      this.departureAddressSubject.next(this.departureAddress);
    }
  }

  public onCustomerSelect(customer: ICustomer): void {
    this.restartSubscription();
    this.customerCurrentOrdersData = [];
    this.customerCurrentOrders = null;
    if (customer?.status === 'BLOCKED') {
      this.isCustomerBlocked = true;
    }
    if (customer?.status === 'ACTIVE') {
      this.isCustomerBlocked = false;
    }
    this.form.markAsTouched();
    this.selectedCustomer = customer;
    this.sortCustomerPhonesByOrder();
    const phones = this.selectedCustomer.phones;
    const selectedCustomerPhoneNumber = phones[0]?.phoneCode + phones[0]?.phoneNumber;
    this.selectedCustomerName = customer;
    const { id: customerId, name: customerName, lastName: customerLastName } = customer;
    this.form.patchValue({
      customer: {
        id: customerId.toString(),
        name: customerName,
        lastName: customerLastName,
      },
      selectedCustomerPhoneNumber,
      passengerName: this.utilService.tagRemoveFalsyValueFunc`${customerName} ${customerLastName}`,
    });
    this.getCustomerOrders();
    this.getCustomerMostFrequentOrders();
    this.setFocusToCustomerPhonesField();
  }

  private updateReadonlyFields(allowed: string[]): void {
    if (!isArray(allowed) || allowed.length === 0) {
      console.warn("[BaseCreateOrder.updateReadonlyFields] not array or 0 length, be sure it's intentional");
      return;
    }

    if (allowed.includes('appointmentComment') && allowed.includes('destinationComment')) {
      this.form.get('appointmentComment')?.enable();
      this.form.get('destinationComment')?.enable();
      this.attributesAndTags.form.enable();
      this.isTagsDisabled = true;
    }

    const shouldDisableSearchForm = !allowed.includes('searchField');
    if (shouldDisableSearchForm && !!this.customerAutoComplete && this.isEditingMode) {
      this.customerAutoComplete.form.disable();
      this.form.get('selectedCustomerPhoneNumber').disable();
    }
  }

  private getMaxDateRange(maxAllowedDaysInAdvance: number): moment.Moment {
    return moment(this.dateRange.min).add(maxAllowedDaysInAdvance, 'days').endOf('day');
  }

  private resetPaymentType(): void {
    const [paymentType] = [ORDER_TAG_TYPE.payment[0]];
    this.form.patchValue({ paymentType }, { emitEvent: false });
    this.isDropdownTouched = false;
  }

  private listenForCustomerDistinctName(form: UntypedFormGroup): void {
    form.valueChanges
      .pipe(
        filter(values => values.hasOwnProperty('customer')),
        map(values => ({
          firstName: values.customer.name,
          lastName: values.customer.lastName,
          phoneNumber: values.customer.phoneNumber,
          appointmentComment: values.appointmentComment,
          destinationComment: values.destinationComment,
          comment: values.comment,
          payment: values.paymentType.value,
          serviceType: values.serviceType.value,
          vehicleType: values.vehicleType.value,
        })),
        distinctUntilChanged(
          ({ firstName: previousFirstName, lastName: previousLastName }, { firstName, lastName }) => {
            const firstNameNotChanged = previousFirstName === firstName;
            const lastNameNotChanged = previousLastName === lastName;
            return firstNameNotChanged && lastNameNotChanged;
          }
        ),
        debounceTime(500)
      )
      .subscribe(customer => {
        if (
          customer.firstName ||
          customer.lastName ||
          customer.phoneNumber.length > 4 ||
          customer.appointmentComment ||
          customer.destinationComment ||
          customer.comment ||
          customer.payment ||
          customer.serviceType ||
          customer.vehicleType
        ) {
          this.form.markAsTouched();
        }
        customer.firstName = customer.firstName === null ? '' : customer.firstName;
        customer.lastName = customer.lastName === null ? '' : customer.lastName;
      });
  }

  public onBlurFormaFirstNameText(): void {
    this.firstNameControl.setValue(normalizeWhitespacesIn(this.firstNameControl.value));
    this.syncPassengerName();
  }

  public onBlurFormatLastNameText(): void {
    this.lastNameControl.setValue(normalizeWhitespacesIn(this.lastNameControl.value));
    this.syncPassengerName();
  }

  private resetFalgs(): void {
    this.isDropdownTouched = false;
    this.isButtonBlocked = false;
    this.isDepartureAddressOutsideOfBusinessZone = false;
  }

  private findGeoAreaByPoint(coordinates: { longitude: number; latitude: number }): Observable<BusinessZoneParams[]> {
    return this.geoAreaService.findByPoint(coordinates).pipe(
      tap(fleet => {
        this.fleetParams.geoArea.id = fleet?.id;
      }),
      take(1),
      switchMap(foundArea => {
        return this.fleetService.getFleetsByAreaId([foundArea.id]).pipe(
          tap(result => {
            result.forEach(item => (item.dispatchBeforeMinutes = foundArea.dispatchBeforeMinutes));
          })
        );
      }),
      tap(foundFleets => {
        if (foundFleets.length === 0) {
          this.isDepartureAddressOutsideOfBusinessZone = true;
          return;
        }

        const currentUserFleetId = this.userService.getCurrentUserInfo().fleet.id;

        this.isDepartureAddressOutsideOfBusinessZone = foundFleets.some(found => found.id !== currentUserFleetId);
      }),
      catchError(error => {
        this.isDepartureAddressOutsideOfBusinessZone = error.status === 404;
        return of([]);
      })
    );
  }

  private recalculateEstimations(): void {
    this.noRoute = false;
    this.resetDurationAndDistance();
    this.resetEstimatedPrice();
    if (!this.utilService.isEqualAddress(this.departureAddress, this.arrivalAddress)) {
      this.calculateEstimatedPrice();
      this.calculateDurationAndDistance();
    }
  }

  /** most likely default is France, which is +33 */
  private updatePhoneCodeControlValue(): void {
    if (!this.azzPhoneInput) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
    const code = this.azzPhoneInput.phoneConfig.getSelectedCountryData()?.dialCode;
    if (code) {
      this.form.get('customer.phoneCode').patchValue(`+${code}`);
    } else {
      this.form.get('customer.phoneCode').patchValue('+');
    }
  }

  /** checks if plus sign ('+') is absent and adds it */
  private addPhonePlusSignIfPlusIsAbsent(phoneNumber: string): void {
    phoneNumber = phoneNumber === undefined ? '' : phoneNumber;
    if (phoneNumber.includes('+')) {
      return;
    }

    this.form.get('customer.phoneCode').patchValue('+');
    this.form.get('customer.phoneNumber').patchValue(`+${phoneNumber}`);
  }

  private loadFleetParams(): void {
    this.fleetParams$.subscribe((res: IFleetParametersForOtherUser) => {
      this.fleetParams.changeDispatchTime = res.changeDispatchTime;
      this.fleetParams.inAdvanceMaxDays = res.inAdvanceMaxDays;
      this.fleetParams.dispatchBeforeMinutes = res.dispatchBeforeMinutes;
      this.dateRange.max = this.getMaxDateRange(this.fleetParams.inAdvanceMaxDays);

      this.form.controls.order.controls.date.setValidators([
        Validators.required,
        DateValidators.minDate(this.dateRange.min),
        DateValidators.maxDate(this.dateRange.max),
        DateValidators.dateFormatType,
      ]);
    });
  }

  private loadOrderData(): void {
    this.paOrderService
      .getOrderById(this.orderId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap((order: PhoneAdvisorOrder) => {
          this.order = order;
          this.fillEditOrderForm();
          this.getCrcTags(true);
          this.requestOrderTimeConstraints(this.order);
          this.isDepartureAddressOutsideOfBusinessZone = this.order.addressOutsideBusinessZone;
          return this.customerService.getCustomerById(order.customerId).pipe(takeUntilDestroyed(this.destroyRef));
        })
      )
      .subscribe((customer: ICustomer) => this.setCustomerInfo(customer));
  }

  private handleAllTags(tags: ITag[]): void {
    const { serviceTypeId, paymentTypeId, vehicleTypeId, orderSelectedTags } = extractTagIds(tags);
    this.orderSelectedTags = [];
    this.orderSelectedTags = orderSelectedTags;
    const serviceType = getServiceTypeById(serviceTypeId);
    const vehicleType = getVehicleTypeById(vehicleTypeId);
    const paymentType = getPaymentTypeById(paymentTypeId);

    this.form.patchValue({ serviceType, vehicleType, paymentType });
  }

  private handlePhoneNumberChanging(): void {
    this.phoneAlreadyExists = false; // Reset if typing
    this.phoneExistenceChecked = false; // Reset if typing
    const phoneNumber = this.form.get('customer.phoneNumber').value;
    const phoneTypeControl = this.form.get('customer.phoneType');
    phoneTypeControl.setValue(this.isMobileNumber(phoneNumber) ? PHONE_TYPE.MOBILE : PHONE_TYPE.LANDLINE);
    this.checkPhoneNumberExist(phoneNumber);
  }

  private checkPhoneNumberExist(phoneNumber: string): void {
    const code = this.form.getRawValue().customer.phoneCode;
    const isValidated = this.form.get('customer.phoneNumber')?.valid;
    const isFrench = code === FRENCH_PHONE_CODE;
    const isMinLength = phoneNumber.length > 11 ? true : false;
    const checkedFromCheerToCree = this.form.get('customer.phoneNumber')?.value !== '+33';
    if (isMinLength && isFrench && isValidated && checkedFromCheerToCree && phoneNumber.includes(FRENCH_PHONE_CODE)) {
      this.customerService
        .checkPhoneNumberExist(phoneNumber, code)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          () => (this.phoneExistenceChecked = true),
          errorResponse => this.handleCheckPhoneNumberError(errorResponse)
        );
    } else {
      this.phoneExistenceChecked = true;
    }
  }

  private handleCheckPhoneNumberError(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status === 400 && errorResponse.error.reason === 'customer.phone.number.exists') {
      this.phoneAlreadyExists = true;
    } else {
      throw errorResponse;
    }
  }

  private listenForPickerDateChanges(): void {
    this.form.controls.order.controls.date.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedDate => {
        if (selectedDate) {
          this.form.controls.order.controls.time.setValidators([
            Validators.required,
            DateValidators.validTime,
            DateValidators.minTime(moment(), selectedDate),
          ]);
          this.form.controls.order.controls.time.setValue(this.form.controls.order.controls.time.value);
        } else {
          this.form.controls.order.controls.time.setValidators([Validators.required, DateValidators.validTime]);
        }
      });

    this.form.controls.order.controls.time.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.departureAddress) {
          this.onDepartureAddressSelect(this.departureAddress);
        }
      });

    this.isDispatchDelayEnabled$
      .pipe(
        switchMap(isEnabled =>
          isEnabled
            ? this.dispatchBeforeMinutes$.pipe(map(dispatchBeforeMinutes => ({ isEnabled, dispatchBeforeMinutes })))
            : of({ isEnabled, dispatchBeforeMinutes: 0 })
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ isEnabled, dispatchBeforeMinutes }) => {
        const { dispatchDelay, date, time } = this.form.controls.order.controls;

        dispatchDelay.clearValidators();

        if (isEnabled) {
          const minutesDiff = helpers.convertDateTimeStrToMoment(date.value, time.value).diff(moment(), 'minutes');
          this.dispatchBeforeMinutesRestrictions$.next({
            min: dispatchBeforeMinutes,
            max: minutesDiff > 1440 ? 1440 : minutesDiff,
          });

          if (!dispatchDelay.enabled) {
            dispatchDelay.enable();
          }
          dispatchDelay.setValidators([
            Validators.min(this.dispatchBeforeMinutesRestrictions$.value.min),
            Validators.max(this.dispatchBeforeMinutesRestrictions$.value.max),
          ]);

          if (!this.enteredDispatchDelay || this.enteredDispatchDelay === dispatchBeforeMinutes) {
            dispatchDelay.setValue(dispatchBeforeMinutes, { emitEvent: false });
            this.recalculatedDispatchTime = helpers
              .convertDateTimeStrToMoment(
                this.form.controls.order.controls.date.value,
                this.form.controls.order.controls.time.value
              )
              .subtract(dispatchBeforeMinutes, 'minutes');
            this.enteredDispatchDelay = null;
          } else if (this.enteredDispatchDelay) {
            dispatchDelay.setValue(this.enteredDispatchDelay);
          }
        } else {
          if (!dispatchDelay.disabled || dispatchDelay.value) {
            dispatchDelay.reset({ disabled: true, value: null });
          }
        }
      });

    this.form.controls.order.controls.dispatchDelay.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(dispatchDelay => {
        this.recalculatedDispatchTime = helpers
          .convertDateTimeStrToMoment(
            this.form.controls.order.controls.date.value,
            this.form.controls.order.controls.time.value
          )
          .subtract(dispatchDelay, 'minutes');

        if (dispatchDelay) {
          this.enteredDispatchDelay = dispatchDelay;
        }
      });
  }

  private enteredDispatchDelay: number;

  private getCombinedPickerDate(): moment.Moment {
    const {
      order: { date, time },
    } = this.form.getRawValue();
    if (!date || !time) {
      return moment().add(1, 'minutes');
    }

    return helpers.convertDateTimeStrToMoment(date, time);
  }

  private isPastDate(date: moment.Moment): boolean {
    return date.isBefore(moment().startOf('minute'));
  }

  private initPhoneTypes(): any {
    const mobile: NamedOption<PhoneTypeFieldTitle> = {
      name: this.translate.instant('PA_CUSTOMERS_MOBILE_PHONE') as string,
      value: 'MOBILE',
    };
    const landline: NamedOption<PhoneTypeFieldTitle> = {
      name: this.translate.instant('PA_CUSTOMERS_LANDLINE_PHONE') as string,
      value: 'LANDLINE',
    };
    return [mobile, landline];
  }

  private showCreateOrderDialog(res: any): void {
    const label = this.translate.instant('REFERENCE_LABEL');

    this.rootService.alerts.success('PA_CREATE_ORDER_SUCCESSFULLY_CREATED', {
      subText: `${label}: ${res.order.reference}`,
      route: {
        text: 'DETAILS_ABOUT_CREATED_ORDER',
        link: [RouteUrls.dash[UserRole.phoneAdvisor], 'orders', res.order.id],
      },
    });
  }

  private showUpdateOrderDialog(): void {
    this.rootService.alerts.success('PA_CREATE_ORDER_SUCCESSFULLY_CREATED');
  }

  private generateOrderDate(): number | null {
    const pickerCombinedDate = this.getCombinedPickerDate();

    return pickerCombinedDate.diff(moment(), 'minutes') < this.maxMinDiffForNow
      ? this.isEditingMode
        ? 0 // todo: fix this
        : null
      : pickerCombinedDate.valueOf();
  }

  private resetDurationAndDistance(): void {
    this.duration = null;
    this.geoMeasurmentCourseEstimation = { duration: 0, distance: 0 };
    this.unsubscribeFromDurationDistance();
  }

  private unsubscribeFromDurationDistance(): void {
    if (this.durationDistanceUnsubscribe$) {
      this.durationDistanceUnsubscribe$.next(true);
      this.durationDistanceUnsubscribe$.complete();
    }
  }

  private resetEstimatedPrice(): void {
    this.estimatedPrice = null;
    this.isEstimatedPriceShown = false;
    this.unsubscribeFromEstimatedPrice();
  }

  private unsubscribeFromEstimatedPrice(): void {
    if (this.estPriceUnsubscribe$) {
      this.estPriceUnsubscribe$.next(true);
      this.estPriceUnsubscribe$.complete();
    }
  }

  private getDriversTags(): TagTypeName[][] {
    const tagsSelected: TagTypeName[][] = [];

    if (this.form.controls.paymentType.value.value && this.form.controls.paymentType.value.id !== 'DEFAULT') {
      tagsSelected.push([this.form.controls.paymentType.value.value]);
    }

    if (this.form.controls.vehicleType.value.value) {
      tagsSelected.push([this.form.controls.vehicleType.value.value]);
    }

    if (this.form.controls.serviceType.value.value) {
      tagsSelected.push([this.form.controls.serviceType.value.value]);
    }

    const selectedFilter = this.getSelectedFilters();
    if (selectedFilter && selectedFilter.length) {
      selectedFilter.forEach(s => tagsSelected.push([s.id]));
    }

    return tagsSelected.length ? tagsSelected : [];
  }

  private generateETAparams(includeTags: boolean = true): HttpParams {
    const { latitude, longitude } =
      this.departureAddress.poi?.coordinates ||
      this.departureAddress.coordinates ||
      this.departureAddressSubject.value.poi.coordinates ||
      this.departureAddressSubject.value.coordinates;
    let coordsETA = new HttpParams().set('pickupLat', latitude).set('pickupLon', longitude);

    if (includeTags) {
      const tags = this.getDriversTags();
      tags?.forEach(tag => Object.values(tag).forEach(value => (coordsETA = coordsETA.append('tags', value))));
    }

    this.isEtaLoading = true;
    return coordsETA;
  }

  private recalculateETA(): void {
    if (!this.departureAddress) {
      this.geoMeasurmentETA = { distance: null, duration: null };
      this.distanceShowType$.next(null);

      return;
    }

    this.osrmService.getApproximateETA(this.generateETAparams(true)).subscribe(
      (res: ETAResponseParams[] | null) => {
        this.isEtaLoading = false;

        const tags = this.getDriversTags();
        if (tags.length) {
          if (res?.length) {
            this.distanceShowType$.next('show');
          } else {
            this.osrmService.getApproximateETA(this.generateETAparams(false)).subscribe(noTagsResponse => {
              this.distanceShowType$.next(noTagsResponse?.length ? 'hide-something-exists' : 'hide-nothing-exists');
            });
          }
        } else {
          this.distanceShowType$.next('show');
        }

        this.geoMeasurmentETA = res?.length
          ? {
              distance: res[0]?.meters || null,
              duration: res[0]?.seconds || null,
            }
          : { distance: null, duration: null };
      },
      (errorResponse: HttpErrorResponse) => this.handleETAError(errorResponse)
    );
  }

  private handleETAError(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status === 400) {
      throw errorResponse;
    }
  }

  private calculateDurationAndDistance() {
    if (!this.departureAddress || !this.arrivalAddress || this.arrivalHasNoCoords || this.departureHasNoCoords) {
      return;
    }

    this.durationDistanceUnsubscribe$ = new Subject<boolean>();
    const coordsX = this.departureAddress.poi
      ? this.departureAddress.poi.coordinates
      : this.departureAddress.coordinates;
    const coordsY = this.arrivalAddress.poi ? this.arrivalAddress.poi.coordinates : this.arrivalAddress.coordinates;

    const coordsString = new HttpParams()
      .set('pickupLat', coordsX.latitude)
      .set('pickupLon', coordsX.longitude)
      .set('dropoffLat', coordsY.latitude)
      .set('dropoffLon', coordsY.longitude);

    this.osrmService
      .getFastestRoute(coordsString)
      .pipe(takeUntil(this.durationDistanceUnsubscribe$))
      .subscribe(
        (res: HereApi) => {
          this.duration = Math.floor(res.duration / 60);
          this.geoMeasurmentCourseEstimation = { distance: res.meters, duration: res.seconds };
        },
        (errorResponse: HttpErrorResponse) => this.handleCalculateDistanceError(errorResponse)
      );
  }

  private handleCalculateDistanceError(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status === 400 && errorResponse.url.indexOf('proxy/distanceApi/driving') !== -1) {
      this.noRoute = true;
    } else {
      throw errorResponse;
    }
  }

  private resetServiceVehiclePaymentTypes(): void {
    const [serviceType, vehicleType, paymentType] = [
      ORDER_TAG_TYPE.service[0],
      ORDER_TAG_TYPE.vehicle[0],
      ORDER_TAG_TYPE.payment[0],
    ];
    this.form.patchValue({ serviceType, vehicleType, paymentType });
  }

  private generateTagsPayload(): ITag[] {
    const { serviceType, paymentType, vehicleType } = this.form.getRawValue();
    return [
      ...getServiceVehiclePaymentTypeTagsPayload({
        serviceType: serviceType as IdOption<ServiceTypeTitle, ServiceTypeTitle>,
        paymentType: paymentType as IdOption<PaymentTypeTitle, PaymentTypeTitle>,
        vehicleType: vehicleType as IdOption<VehicleTypeTitle, VehicleTypeTitle>,
      }),
      ...this.getSelectedFilters(),
    ];
  }

  public getCustomerOrders(): void {
    if (this.customerCurrentOrders?.last && !this.customerCurrentOrders?.first) {
      return;
    }
    this.paOrderService
      .getPACurrentOrders(this.generateCustomeCurrentrOrdersParams())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IPhoneAdvisorOrderData) => {
        if (res.first && !res.content.length) {
          this.customerCurrentOrders = null;
          this.customerCurrentOrdersData = [];
          return;
        }
        if (res.first && this.customerCurrentOrdersData.length) {
          this.customerCurrentOrders = null;
          this.customerCurrentOrdersData = [];
        }
        this.customerCurrentOrders = res;
        res.content.forEach(item => this.customerCurrentOrdersData.push(item));
        this.customerCurrentOrdersData = [...this.customerCurrentOrdersData];
      });
  }

  public getCurrentPage(): number {
    return this.customerCurrentOrders ? this.customerCurrentOrders?.number + 1 : 0;
  }

  private generateCustomeCurrentrOrdersParams(): Partial<IGetPaOrdersParams> {
    return {
      page: this.getCurrentPage(),
      customerId: this.selectedCustomer.id,
      statuses: this.currentOrdersStatus,
    };
  }

  private getCustomerMostFrequentOrders(): void {
    this.paOrderService
      .getPAFrequentOrders(this.generateCustomerFreqOrdersParams())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IPhoneAdvisorOrderData) => {
        this.customerMostFrequentOrders = res?.content;
      });
  }

  private generateCustomerFreqOrdersParams(): Partial<IGetPaOrdersParams> {
    return { customerId: this.selectedCustomer.id, quantity: 100, size: 5 };
  }

  private switchCustomerType(newCustomerType: CustomerTypeTitle): void {
    if (!newCustomerType) {
      const type = this.form.get('customerType')?.value;

      this.form.patchValue({ customer: { type: type === 'old' ? 'new' : 'old' } });
    } else {
      this.form.patchValue({ customer: { type: newCustomerType } });
    }
  }

  private setDepartureAddressFromOrder(order: PhoneAdvisorOrder): void {
    if (!order.appointmentAddress.poi || !order.appointmentAddress.poi.serviceLevel) {
      this.setOrderAddress(order, 'appointmentAddress');
      this.setDepartureAutocomplete();
      return;
    }

    if (
      ORDER_SERVICE_TYPE_POI_SERVICE_LEVEL_ARRAY[this.form.value.serviceType.id].includes(
        order.appointmentAddress.poi.serviceLevel
      )
    ) {
      this.setOrderAddress(order, 'appointmentAddress');
      this.setDepartureAutocomplete();
    } else {
      this.rootService.alerts.warn('POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE_INCOMPATIBILITY');
      setTimeout(() => this.departureAddressRef.detectAndUpdate());
    }
  }

  private setArrivalAddressFromOrder(order: PhoneAdvisorOrder): void {
    if (!order.destinationAddress.poi || !order.destinationAddress.poi.serviceLevel) {
      this.setOrderAddress(order, 'destinationAddress');

      this.setArrivalAutocomplete();
      return;
    }

    const serviceTypeId = this.form.getRawValue().serviceType.id;
    if (
      !!serviceTypeId &&
      ORDER_SERVICE_TYPE_POI_SERVICE_LEVEL_ARRAY[serviceTypeId].includes(order.destinationAddress.poi.serviceLevel)
    ) {
      this.setOrderAddress(order, 'destinationAddress');
      this.setArrivalAutocomplete();
    } else {
      this.rootService.alerts.error('POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE_INCOMPATIBILITY');
      setTimeout(() => this.arrivalAddressRef.detectAndUpdate());
    }
  }

  private setDepartureAutocomplete(): void {
    setTimeout(() => this.departureAddressRef.setAutocompleteFieldValue(this.departureAddress));
  }

  private setArrivalAutocomplete(): void {
    setTimeout(() => this.arrivalAddressRef.setAutocompleteFieldValue(this.arrivalAddress));
  }

  private updateOder(): void {
    this.isButtonBlocked = true;
    this.geoAreaService
      .findByPoint({ ...this.departureAddress.coordinates })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(() => of(null)),
        mergeMap(geoArea => {
          const payload = this.generateOrderData();
          payload.appointmentAddress.geoAreaId = geoArea?.id ?? null;
          return this.paOrderService.updateOrder(payload).pipe(takeUntilDestroyed(this.destroyRef));
        }),
        finalize(() => (this.isButtonBlocked = false))
      )
      .subscribe(() => {
        this.showUpdateOrderDialog();
        this.navigateToOrdersPage();
      }, this.handleOrderError);
  }

  private openErrorCreateOrderDialog(message: string): void {
    setTimeout(() => {
      this.confirmDialog.showDialog(message, this.translate.instant('DRIVER_ERROR_CREATE_DIALOG_MESSAGE'), {
        okValue: this.translate.instant('YES'),
        cancelValue: this.translate.instant('NO'),
        hasCancelButton: true,
        isSubmitted: true,
      });
      this.isWithoutDriverOpened = true;
    });
  }

  private isWithoutDriverOpened = true;

  public createWithoutDriverSuccess(): void {
    this.form.controls.driver.setValue(null);
    this.submit();
    this.confirmDialog.closeDialog();
  }

  public createWithoutDriverCancel(): void {
    if (!this.isWithoutDriverOpened) {
      return;
    }

    this.isWithoutDriverOpened = false;
  }

  public driverBecomeDisabled(driver: DriverAssignmentInfo): void {
    const message = this.translate.instant('NOTIFICATION_DRIVER_BECOME_DISABLED', {
      firstName: driver.firstName,
      lastName: driver.lastName,
    });

    this.messages.set([
      {
        severity: 'warn',
        detail: message,
        closable: true,
      },
    ]);
  }

  private onTagsChanged(): void {
    this.driverAssignmentService.setTags(this.getDriversTags());

    if (this.departureAddress) {
      this.recalculateETA();
    }
  }
}
