import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TableNoMatchesComponent } from './table-no-matches.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [TableNoMatchesComponent],
  exports: [TableNoMatchesComponent],
})
export class TableNoMatchesModule {}
