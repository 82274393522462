import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ExceptionsEffects } from './effects';
import * as ExceptionsReducer from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(ExceptionsReducer.exceptionsFeatureKey, ExceptionsReducer.reducer, {
      metaReducers: ExceptionsReducer.metaReducers,
    }),
    EffectsModule.forFeature([ExceptionsEffects]),
  ],
})
export class ExceptionsStoreModule {}
