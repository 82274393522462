import {AzzFirebaseConfig} from '@core/models/azz-config.interface';
import * as Sentry from '@sentry/angular-ivy';
import {environment} from './environment';
import {getAPIOriginByHost} from './get-api-origin-by-host.function';

export const getFirebaseConfig = (): AzzFirebaseConfig => {
  let config;
  const request = new XMLHttpRequest();
  const origin = getAPIOriginByHost();
  console.log('origin: ', origin.origin);

  try {
    const isAsynchronous = false;
    request.open('GET', `${origin.getHost()}/backend/api/v1/config/front`, isAsynchronous);
    request.send(null);

    if (request.status === 401) {
      location.reload();
    }

    if (request.status === 200) {
      config = JSON.parse(request.responseText);
    }

    return config.firebase;
  } catch (e) {
    const isProduction = environment?.production || environment?.type === 'production';

    if (isProduction) {
      Sentry.captureException(e);
    }
    console.error('Environment Error:getConfig: unable to get api key: ', e);
  }

  return config.firebase;
};
