/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * object returning when new order is created
 */
export interface CreateOrderResponseWebApiModel {
  /**
   * created order ID
   */
  id: string;
  /**
   * created order reference
   */
  reference: string;
}
