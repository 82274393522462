/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Response item returning suggested address. Optionally returns poiId or address.
 */
export interface AddressSuggestItemResponseWebApiModel {
  /**
   * address city
   */
  city: string;
  /**
   * label to be displayed
   */
  label: string;
  /**
   * address string
   */
  address?: string;
  /**
   * poiId if suggested option is POI
   */
  poiId?: string;
}
