import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AddressAutocompleteComponent } from './address-autocomplete.component';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbTypeaheadModule, TranslateModule, AzzRotateIndicatorModule],
  declarations: [AddressAutocompleteComponent],
  exports: [AddressAutocompleteComponent],
})
export class AddressAutocompleteModule {}
