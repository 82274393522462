import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { URLBuilderService } from './url-builder.service';

export abstract class ApiService {
  protected readonly urlBuilder = inject(URLBuilderService);

  protected constructor(protected http: HttpClient) {}

  public get<TResult>(url: string, queryParams?: { [key: string]: any }, headers?: HttpHeaders | any): Observable<any> {
    return this.http
      .get<TResult>(this.urlBuilder.build(url) + this.createQueryParams(queryParams), {
        observe: 'response',
        headers,
        withCredentials: true,
      })
      .pipe(map(this.extractBody));
  }

  public post(
    url: string,
    body: any,
    queryParams?: { [key: string]: any },
    headers?: HttpHeaders | any
  ): Observable<any> {
    return this.http
      .post(this.urlBuilder.build(url) + this.createQueryParams(queryParams), body, {
        observe: 'response',
        withCredentials: true,
        headers,
      })
      .pipe(map(this.extractBody));
  }

  public put(
    url: string,
    body: any,
    queryParams?: { [key: string]: any },
    headers?: HttpHeaders | any
  ): Observable<any> {
    return this.http
      .put(this.urlBuilder.build(url) + this.createQueryParams(queryParams), body, {
        observe: 'response',
        headers,
        withCredentials: true,
      })
      .pipe(map(this.extractBody));
  }

  public delete(url: string, queryParams?: { [key: string]: any }, headers?: HttpHeaders | any): Observable<any> {
    return this.http
      .delete(this.urlBuilder.build(url) + this.createQueryParams(queryParams), {
        observe: 'response',
        headers,
        withCredentials: true,
      })
      .pipe(map(this.extractBody));
  }

  public createQueryParams(params: { [key: string]: any } = {}): string {
    if (!Object.keys(params).length) {
      return '';
    }

    let str = '?';

    Object.keys(params).map((key, index) => {
      if (params[key] === '' || params[key] === null || params[key] === undefined) {
        return '';
      }

      const ampersand = index === Object.keys(params).length - 1 ? '' : '&';
      if (!Array.isArray(params[key])) {
        return (str += `${key}=${params[key]}${ampersand}`);
      }

      params[key].forEach((value, i) => {
        const innerAmpersand = i === params[key].length - 1 ? '' : '&';
        str += `${key}=${value}${innerAmpersand}`;
      });
      str += `${ampersand}`;
    });
    return str;
  }

  private extractBody(response: HttpResponse<any>): any {
    return response.body;
  }
}
