import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { getApp } from '@angular/fire/app';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { child, Database, ref as dbRef, push } from '@angular/fire/database';
import { getStorage, ref, Storage, uploadBytesResumable, UploadTaskSnapshot } from '@angular/fire/storage';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class FirebaseService extends ApiService {
  private readonly FIREBASE_URL = 'api/v1/firebase/';

  constructor(
    private readonly httpClient: HttpClient,
    @Optional() private readonly auth: Auth,
    @Optional() private readonly storage: Storage,
    @Optional() private readonly database: Database
  ) {
    super(httpClient);
  }

  public signIn() {
    return this.post(`${this.FIREBASE_URL}token`, {}).pipe(
      mergeMap(data => signInWithCustomToken(this.auth, data.token))
    );
  }

  public signOut(): Observable<void> {
    return from(this.auth.signOut());
  }

  public uploadFile(file: File, path: string, metadata?: any, fileName?: string): Observable<UploadTaskSnapshot> {
    // eslint-disable-next-line no-underscore-dangle
    const filename = fileName ? `${this._makeFilename(file, path, true)}_${fileName}` : this._makeFilename(file, path);

    if (filename == null) {
      return throwError(() => {
        new Error('Invalid file type');
      });
    }

    // debugger;
    // provideStorage(() => getStorage(getApp())),
    // console.log('getApp(): ', getApp());
    const storage = getStorage(getApp());
    // console.log('STORAGE: ', storage);
    const storageRef = ref(storage, filename);
    // console.log('storageRef: ', storageRef);
    const subject = new Subject<UploadTaskSnapshot>();
    // console.log('FILE: ', file, metadata);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    // console.log('upload task: ', uploadTask);
    uploadTask.resume();

    uploadTask.on(
      'state_changed',
      x => {},
      error => {
        subject.error(error);
      },
      () => {
        subject.next(uploadTask.snapshot);
        subject.complete();
      }
    );
    return subject.asObservable();
  }

  public uploadFileSkipNull(file: File, path, metadata?) {
    if (file == null) {
      return of(null);
    }
    return this.uploadFile(file, path, metadata);
  }

  public getFile(file) {
    return ref(this.storage, file);
  }

  private _makeFilename(file: File, prefix: string, isPrefixRequired?: boolean): string {
    const databaseRef = dbRef(this.database).ref;
    const rndFilename = push(child(databaseRef, 'filesKeys')).key;
    // const rndFilename = databaseRef.child('filesKeys').push().key;
    const ind = file.name.lastIndexOf('.');

    if (ind === -1) {
      return null;
    }

    const originalExtension = file.name.substring(ind + 1);
    if (prefix[prefix.length - 1] !== '/') {
      prefix = prefix + '/';
    }
    const filePath = prefix + rndFilename;
    const filePathWithExtension = filePath + '.' + originalExtension;

    return !isPrefixRequired ? filePathWithExtension : filePath;
  }
}
