<form [formGroup]="attachPreviewForm" #formElemRef>
  <div class="azz-attach-preview">
    <div class="form-group azz-last">
      <div class="azz-attach-block">
        <label [for]="azzId" class="azz-attach-btn" [ngClass]="{ 'btn-disabled': ngDisabled }"></label>
        <input
          [id]="azzId"
          type="file"
          [disabled]="ngDisabled"
          [attr.name]="azzId + '-file'"
          class="form-control"
          [accept]="azzAccept"
          (change)="onFileChange($event)"
        />
        <div class="azz-attach-popup-wrapper" *ngIf="imagePath || azzCurrentData">
          <div class="azz-attach-popup">
            <div class="azz-close-btn" *ngIf="!ngDisabled" (click)="deleteAttachedFile()"></div>
            <img class="azz-preview" [src]="imagePath || azzCurrentData" alt="image preview" />
          </div>
        </div>
      </div>
      <div class="form-control__error">
        <small
          *ngIf="
            attachPreviewForm.controls.attachPreview.errors?.required &&
            attachPreviewForm.controls.attachPreview.touched
          "
        >
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="sizeError">{{ 'CORE_FILE_SIZE_INVALID' | translate }}</small>
        <small *ngIf="typeOrExtensionError">{{ typeOrExtensionErrorStr }}</small>
      </div>
    </div>
  </div>
</form>
