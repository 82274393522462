/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentTypeEnumWebApiModel } from './payment-type-enum-web.api-model';

/**
 * customer info specified for creating order. Includes customer id, and optionally phone number, if customer has more than one phone, and we need to specify primary phone as contact phone
 */
export interface CustomerOptionsRequestWebApiModel {
  /**
   * customer ID
   */
  customerId: string;
  /**
   * primary customer phone used for order
   */
  phone?: string;
  paymentType?: PaymentTypeEnumWebApiModel;
}
export namespace CustomerOptionsRequestWebApiModel {}
