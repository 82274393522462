import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UtilService } from '../../services/common/util.service';

@Component({
  selector: 'azz-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.less'],
})
export class ProfileAvatarComponent {
  @Output() selectAvatar = new EventEmitter<any>();
  @Input() firstName: any;
  @Input() lastName: any;
  @Input() azzCurrent: any;
  @Input() ngDisabled: any;
  @Input() ngRequired: any;
  @Input() ngData: any;
  public attachedFile: File;
  public imagePath: string;
  public sizeError: boolean;
  public typeOrExtensionError: boolean;
  public azzAccept = 'image/png, image/jpeg';
  public acceptedExtensionsArray = ['jpg', 'jpeg', 'png'];

  private readonly MAX_FILE_SIZE = 10485760; // bytes

  constructor(private readonly utilService: UtilService) {}

  public onAvatarSelect(event) {
    this.resetErrors();
    const files = (event.target as HTMLInputElement).files;
    const reader = new FileReader();

    if (files && files.length) {
      this.attachedFile = files[0];

      if (!this.isFileTypeAccepted(this.attachedFile)) {
        this.typeOrExtensionError = true;
        return;
      }
      if (!this.isFileNameAccepted()) {
        this.typeOrExtensionError = true;
        return;
      }
      if (this.attachedFile.size > this.MAX_FILE_SIZE) {
        this.sizeError = true;
        return;
      }

      reader.readAsDataURL(this.attachedFile);
      reader.onload = (evt: any) => {
        this.imagePath = evt.target.result;
        this.selectAvatar.emit(this.attachedFile);
      };
    }
    // Make it possible to trigger change event when adding the same file
    (event.target as HTMLInputElement).value = '';
  }

  private resetErrors(): void {
    this.sizeError = false;
    this.typeOrExtensionError = false;
  }

  private isFileTypeAccepted(file: File): boolean {
    const typeProp = 'type';
    const acceptedImageTypes = this.utilService.getAcceptedImageTypesArrFromStr(this.azzAccept);

    return acceptedImageTypes.includes(file[typeProp]);
  }

  private isFileNameAccepted(): boolean {
    const fileName = this.getFileExtension(this.attachedFile.name);
    return this.acceptedExtensionsArray.includes(fileName);
  }

  private getFileExtension(fileName: string): string {
    return fileName.split('.').pop();
  }
}
