import { Action, createReducer, on } from '@ngrx/store';

import * as DeactivatedActions from '../actions/deactivated.actions';

export const deactivatedFeatureKey = 'deactivated';

export type State = boolean;

const initialState: State = false;

const deactivatedReducer = createReducer(
  initialState,
  on(DeactivatedActions.deactivatedPhoneAdvisor, (state, { deactivated }) => deactivated)
);

export const reducer = (state: State | undefined, action: Action) => deactivatedReducer(state, action);
