import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DialogDirective } from '@common/components/dialog/dialog.component';
import { NgOnDestroyService } from '@common/services/ng-on-destroy.service';
import { IDriverFullSession } from '@core/models/driver-session.model';
import { SessionService } from '@core/services/sessions/session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RootService } from '@services/root.service';
import moment from 'moment';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'azz-sessions-popup',
  templateUrl: './sessions-popup.component.html',
  styleUrls: ['./sessions-popup.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionsPopupComponent extends DialogDirective implements OnInit {
  protected readonly rootService = inject(RootService);

  @Input() public data: IDriverFullSession;
  @Input() public driversRoute: string;
  @Input() public ordersRoute: string;
  @Output() public сloseSession$ = new EventEmitter();
  @ViewChild('content') public declare dialogContent: any;
  public lastTimeUpdated$;

  constructor(
    dialogService: NgbModal,
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private readonly destroyed$: NgOnDestroyService
  ) {
    super(dialogService);
    this.modalClass = 'myCustomModalClass';
  }

  ngOnInit() {
    this.setContent(this.dialogContent);
    this.getCurrentSession();
    this.lastTimeUpdated$ = this.sessionService.lastTimeUpdatedCoords$.pipe(map(() => moment()));
  }

  public onCloseSession(): void {
    this.sessionService.deleteSession(this.data?.driverId).subscribe(
      () => {},
      response => {
        this.rootService.alerts.error(response.error.message);
      }
    );
  }

  public closeDialog(): void {
    if (this.hasOpenModals()) {
      this.dialogService.dismissAll();
    }
  }

  public hasOpenModals(): boolean {
    return this.dialogService.hasOpenModals();
  }

  public getCurrentSession() {
    this.sessionService.selectedSession$.pipe(takeUntil(this.destroyed$)).subscribe(res => (this.data = res));
  }

  public navigateToDriverDetailsPage(driverId: number): void {
    if (driverId) {
      this.router.navigate([this.driversRoute, driverId]);
      this.dialogService.dismissAll();
    }
  }

  public navigateToOrderDetailsPage(orderId: string): void {
    if (orderId) {
      this.router.navigate([this.ordersRoute, orderId]);
      this.dialogService.dismissAll();
    }
  }
}
