import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IMultiValidationG7Order, IMultiValidationPagination } from '../../../../models/g7-order.model';
import { G7OrdersStore } from '../../store';

@Component({
  selector: 'azz-multi-validation-popup',
  templateUrl: './multi-validation-popup.component.html',
  styleUrls: ['./multi-validation-popup.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class MultiValidationPopupComponent implements OnInit, OnDestroy {
  public tableLoading$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public orders: IMultiValidationG7Order[];
  public pagination: IMultiValidationPagination;
  public selectedOrdersLength: number;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly store: Store,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  public get lastPage() {
    return Math.ceil(this.selectedOrdersLength / this.pagination.size) - 1;
  }

  ngOnInit() {
    combineLatest([
      this.store.pipe(select(G7OrdersStore.selectG7OrdersDataSelectedOrdersCount)),
      this.store.pipe(select(G7OrdersStore.multiValidationPagination)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([length, pagination]) => {
        this.selectedOrdersLength = length;
        this.pagination = pagination;
      });
    this.store
      .pipe(select(G7OrdersStore.multiValidationOrders), takeUntil(this.destroyed$))
      .subscribe(orders => (this.orders = orders));
    this.tableLoading$ = this.store.pipe(select(G7OrdersStore.multiValidationTableLoading));
    this.loading$ = this.store.pipe(select(G7OrdersStore.multiValidationLoading));
  }

  public prevPage() {
    const page = this.pagination.page - 1;
    this.store.dispatch(G7OrdersStore.updateMultiValidationPagination({ pagination: { page } }));
  }

  public nextPage() {
    const page = this.pagination.page + 1;
    this.store.dispatch(G7OrdersStore.updateMultiValidationPagination({ pagination: { page } }));
  }

  public isPrevDisabled(): boolean {
    return !this.orders || this.pagination?.page === 0;
  }

  public isNextDisabled(): boolean {
    return !this.orders || this.pagination?.page === this.lastPage;
  }

  public close(): void {
    this.modal.close();
  }

  public submit(): void {
    this.store.dispatch(G7OrdersStore.multiValidateG7Orders({ popup: this.modal }));
  }

  ngOnDestroy() {
    this.store.dispatch(G7OrdersStore.closeMultiValidationPopup());
  }
}
