<span [ngbTooltip]="tooltipContentDelay" placement="right" tooltipClass="status-tooltip">
  <img src="assets/images/redesign/info.svg" alt="" />
</span>
<ng-template #tooltipContentDelay>
  <small>
    {{
      'DISPATCH_DELAY_NOT_POSSIBLE_WITH_THIS_VALUE'
        | translate
          : {
              min: dispatchBeforeMinutesRestrictions.min,
              max: dispatchBeforeMinutesRestrictions.max
            }
    }}</small
  >
</ng-template>
