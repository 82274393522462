import { IUserNotificationsData } from '@core/models/user-notification.model';
import { Action, createReducer, on } from '@ngrx/store';

import * as UserMessagesActions from '../actions/user-messages.actions';

export const userMessagesFeatureKey = 'user-messages';

export interface State {
  messagesData: IUserNotificationsData;
}

export const initialState: State = null;

const index = createReducer(
  initialState,
  on(
    UserMessagesActions.getUserMessagesSuccess,
    (state: State, { messagesData }: { messagesData: IUserNotificationsData }) => ({
      ...state,
      messagesData,
    })
  )
);

export const reducer = (state: State | undefined, action: Action) => index(state, action);
