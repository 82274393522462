import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ITransportLicense } from '../../../models/transport-license.model';

@Component({
  selector: 'app-driver-details-new-transport-license',
  templateUrl: './driver-details-new-transport-license.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsNewTransportLicenseComponent {
  @Input() newTransportLicense: ITransportLicense;
  @Input() ngDisabled: boolean;
  @Input() confirmBtnLoading: boolean;
  @Input() rejectBtnLoading: boolean;
  @Output() openApproveDriverLicensePopup$ = new EventEmitter<void>();
  @Output() openRejectDriverLicensePopup$ = new EventEmitter<void>();

  public openTransportLicense(newTransportLicense: ITransportLicense): void {
    window.open(newTransportLicense.fileUrl, '_blank');
  }

  public openApproveDriverLicensePopup(): void {
    this.openApproveDriverLicensePopup$.emit();
  }

  public openRejectDriverLicensePopup(): void {
    this.openRejectDriverLicensePopup$.emit();
  }
}
