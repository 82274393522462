import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DriverDetailsEffects } from './effects';
import * as DriverDetailsReducer from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(DriverDetailsReducer.driverDetailsFeatureKey, DriverDetailsReducer.reducer, {
      metaReducers: DriverDetailsReducer.metaReducers,
    }),
    EffectsModule.forFeature([DriverDetailsEffects]),
  ],
})
export class DriverDetailsStoreModule {}
