<form [formGroup]="form" class="filters">
  <div class="row">
    <div class="row">
      <div class="form-group col-sm-4 col-xl-2">
        <label for="orders-date-start" class="azz-transparent-input-label">{{ 'START_DATE' | translate }}</label>
        <div class="filters__date-group">
          <input
            id="orders-date-start"
            type="text"
            name="orders_date_start"
            class="form-control azz-transparent-input azz-validate"
            autocomplete="off"
            readonly
            formControlName="dateGt"
            [azzDisableControl]="!!orderId"
            [owlDateTimeTrigger]="dt2Start"
            [owlDateTime]="dt2Start"
            [owlDateTimeFilter]="filterDateStart"
            [max]="maxDate"
          />
          <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1" #dt2Start />
          <button *ngIf="startDate" type="button" class="remove-btn" (click)="resetDate('dateGt')"></button>
        </div>
      </div>
      <div class="form-group col-sm-4 col-xl-2">
        <label for="orders-date-end" class="azz-transparent-input-label">{{ 'END_DATE' | translate }}</label>
        <div class="filters__date-group">
          <input
            id="orders-date-end"
            type="text"
            name="orders_date_end"
            class="form-control azz-transparent-input azz-validate"
            autocomplete="off"
            readonly
            formControlName="dateLt"
            [azzDisableControl]="!!orderId"
            [owlDateTimeTrigger]="dt2End"
            [owlDateTime]="dt2End"
            [max]="maxDate"
            [owlDateTimeFilter]="filterDateEnd"
          />
          <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1" #dt2End />
          <button *ngIf="endDate" type="button" class="remove-btn" (click)="resetDate('dateLt')"></button>
        </div>
      </div>
      <div class="form-group col-sm-4 col-xl-2">
        <label for="orders-category" class="azz-transparent-input-label">{{
          'GO_ORDERS_CATEGORY_HEADER' | translate
        }}</label>
        <select
          id="orders-category"
          name="orders_category"
          class="form-control azz-transparent-input azz-expandable"
          formControlName="tripCategory"
          [azzDisableControl]="!!orderId"
        >
          <option [ngValue]="null">{{ 'CORE_SHOW_ALL_OPT' | translate }}</option>
          <option *ngFor="let category of categories" [value]="category">{{ category }}</option>
        </select>
      </div>
      <div class="form-group col-sm-4 col-xl-2">
        <label for="orders-processing-status" class="azz-transparent-input-label">
          {{ 'GO_ORDERS_RN7_STATUS_HEADER' | translate }}
        </label>
        <select
          id="orders-processing-status"
          name="orders_processing_status"
          class="form-control azz-transparent-input azz-expandable"
          formControlName="processingStatus"
          [azzDisableControl]="!!orderId"
        >
          <option [ngValue]="null">{{ 'CORE_SHOW_ALL_OPT' | translate }}</option>
          <option *ngFor="let status of processingStatuses" [value]="status">
            {{ 'GO_ORDER_STATUS_' + status | translate }}
          </option>
        </select>
      </div>
      <div class="form-group col-sm-4 col-xl-2" *ngIf="isG7User">
        <azz-city-dropdown
          azzId="select-fleet-by-city"
          defaultInputValue="CORE_SHOW_ALL_OPT"
          [azzLabel]="'CITY' | translate"
          [onlyG7FleetId]="onlyG7FleetId"
          [azzPlaceholder]="'CITY' | translate"
          [inputCurrentValue]="fleetCityName"
          [azzDataRequest]="loadCitiesCallBack()"
          [azzNullAsAll]="true"
          [azzAllOptionsLabel]="'CORE_SHOW_ALL_OPT' | translate"
          [ngDisabled]="!!orderId"
          [isPartnerRemovingIsPossible]="true"
          (azzSelect)="onFleetCitySelect($event)"
        >
        </azz-city-dropdown>
      </div>
      <div class="form-group col-sm-4 col-xl-2" *ngIf="isG7User">
        <label for="orders-search" class="azz-transparent-input-label">{{ 'REFERENCE_LABEL' | translate }}</label>
        <div class="azz-has-addon">
          <div class="azz-search-addon"></div>
          <input
            id="orders-search"
            name="orders_search"
            type="text"
            formControlName="id"
            class="form-control azz-transparent-input azz-search-field"
          />
        </div>
      </div>
      <div class="form-group col-sm-4 col-xl-2">
        <label for="partnerId" class="azz-transparent-input-label">{{ 'AUTHORIZATION_NUMBER' | translate }}</label>
        <input
          id="partnerId"
          name="orders_partnerId"
          type="text"
          class="form-control azz-transparent-input azz-search-field azz-validate"
          [azzDisableControl]="!!orderId"
          formControlName="partnerId"
        />
      </div>
      <div class="form-group col-sm-4 col-xl-2">
        <label for="zero-price" class="azz-transparent-input-label">{{ 'TOTAL_AMOUNT' | translate }}</label>
        <select
          name="zero_price"
          class="form-control azz-transparent-input azz-expandable"
          id="zero-price"
          formControlName="zeroPrice"
          [azzDisableControl]="!!orderId"
        >
          <option [ngValue]="null">{{ 'ALL' | translate }}</option>
          <option [value]="true">{{ 'ZERO_PRICE' | translate }}</option>
          <option [value]="false">{{ 'NOT_ZERO_PRICE' | translate }}</option>
        </select>
      </div>
      <div class="form-group col-lg-2" *ngIf="isG7User">
        <azz-dropdown
          azzId="select-fleet-by-id-g7"
          azzOptionFormat="idG7"
          defaultInputValue="CORE_SHOW_ALL_OPT"
          [azzLabel]="'GO_ORDERS_G7_CODE_HEADER' | translate"
          [azzSearchPlaceholder]="'G7BILLING_SEARCH_BY_CODE' | translate"
          [searchResultHeader]="'GO_ORDERS_G7_CODE_HEADER' | translate"
          [isFleetIdG7Allowed]="true"
          [onlyG7FleetId]="true"
          [azzPlaceholder]="'SUPER_TAXI_SELECT_FLEET_PLACEHOLDER' | translate"
          [azzDataRequest]="getLoadFleetsWithContextCallback()"
          [azzNullAsAll]="true"
          [azzAllOptionsLabel]="'CORE_SHOW_ALL_OPT' | translate"
          [isPartnerRemovingIsPossible]="true"
          (azzSelect)="onFleetG7IdSelect($event)"
        >
        </azz-dropdown>
      </div>
    </div>
    <div #footer class="flex flex-row align-center space-between">
      <azz-checkbox
        class="form-group col-lg-6 max-w-40"
        formControlName="paymentOnBoardOnly"
        label="G7_ORDERS_FILTERS_PAYMENT_ON_BOARD"
      ></azz-checkbox>
      <div
        class="form-group d-flex flex-wrap align-items-end justify-content-start justify-content-xl-end flex__gap-20px"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</form>
