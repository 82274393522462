export class ApiOriginData {
  public origin: string;
  public proto: string;

  constructor(origin: string, proto: string) {
    this.origin = origin;
    this.proto = proto;
  }

  getHost() {
    return this.proto + '://' + this.origin;
  }
}

export const API_ORIGINS = {
  prod: new ApiOriginData('prod-proxy-rn7.g7.fr', 'https'),
  pretest: new ApiOriginData('pretest-proxy-rn7.g7.fr', 'https'),
  stage: new ApiOriginData('stage-proxy-rn7.g7.fr', 'https'),
  preprod: new ApiOriginData('test-proxy-rn7.g7.fr', 'https'),
  develop: new ApiOriginData('develop-proxy-rn7.g7.fr', 'https'),
  docker: new ApiOriginData('localhost:8085', 'http'),
};
