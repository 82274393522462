import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IContact, IContactsData, IGetContactsDataParams } from '../../models/cti.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class ContactService extends ApiService {
  private readonly API_URL = 'user/api/v1/contact/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getContacts(params: Partial<IGetContactsDataParams>): Observable<IContactsData> {
    return this.get(`${this.API_URL}search`, params);
  }

  public getContactByPhone(phoneNumber: string): Observable<IContact> {
    return this.get(`${this.API_URL}phone/number/${phoneNumber}`);
  }
}
