import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule, LoadingBlockModule, SpinnerButtonModule } from '@common/components';
import { BlurOnClickTabModule } from '@common/directives';
import { TranslateModule } from '@ngx-translate/core';

import { AzzTripDetailsComponent } from './azz-trip-details.component';
import { AttachFileModule } from '../attach-file/attach-file.module';
import { AzzCustomAutocompleteModule } from '../azz-custom-autocomplete/azz-custom-autocomplete.module';
import { AzzDriverCircleModule } from '../azz-driver-cicrle/azz-driver-circle.module';
import { CustomDialogModule } from '../custom-dialog/custom-dialog.module';
import { G7CommonNotificationsModule } from '../g7-notifications/g7-notifications.module';
import { EditablePriceComponent } from './editable-price/editable-price.component';
import { EditableTollComponent } from './editable-toll/editable-toll.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AzzCustomAutocompleteModule,
    FormsModule,
    DialogModule,
    SpinnerButtonModule,
    AzzDriverCircleModule,
    G7CommonNotificationsModule,
    AttachFileModule,
    LoadingBlockModule,
    BlurOnClickTabModule,
    CustomDialogModule,
  ],
  declarations: [AzzTripDetailsComponent, EditablePriceComponent, EditableTollComponent],
  exports: [AzzTripDetailsComponent],
})
export class AzzTripDetailsModule {}
