<!--Can't use "exceptionClass" as host binding class cause change detection doesn't work, doesn't update row color,
only using it on table-row tags works correctly-->
<td class="exception" [class]="exceptionClass">
  {{ exception?.date | date: 'dd.MM.yyyy' }},<br />
  {{ exception?.date | date: 'HH:mm' }}
</td>
<td class="exception" [class]="exceptionClass">
  {{ 'PA_EXCEPTION_TYPE_' + exception.type | translate }}
</td>
<td class="exception exception__link" [class]="exceptionClass">
  <span (click)="navigateToDriver($event, exception?.driverId)">
    <span *ngIf="exception?.driverName">{{ exception?.driverName }}<br /></span>
    <span *ngIf="exception?.driverTaxiNumber">{{ exception?.driverTaxiNumber }}<br /></span>
    <span>{{ exception?.driverPhoneNumber }}</span>
  </span>
</td>
<td class="exception exception__link" [class]="exceptionClass">
  <span (click)="navigateToCustomer($event, exception?.customerId)">
    {{ exception?.customerName }}<br />
    {{ exception?.customerPhoneNumber }}
  </span>
</td>
<td class="exception" [class]="exceptionClass">{{ displayTime }}</td>
