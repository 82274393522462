/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderCustomerResponseWebApiModel } from './order-customer-response-web.api-model';
import { OrderAddressResponseWebApiModel } from './order-address-response-web.api-model';
import { OrderPassengersResponseWebApiModel } from './order-passengers-response-web.api-model';

/**
 * object with order data on order details page [TBD]
 */
export interface OrderResponseWebApiModel {
  /**
   * order ID
   */
  id: string;
  /**
   * order reference
   */
  reference: string;
  /**
   * order type. IMMEDIATE or IN_ADVANCE or RESERVATION
   */
  type: string;
  /**
   * order date and time in ISO 8601 format (YYYY-MM-DDThh:mm:ss±hh). If not specified, it\'s a creation date
   */
  date?: string;
  pickup: OrderAddressResponseWebApiModel;
  dropoff?: OrderAddressResponseWebApiModel;
  customerOptions?: OrderCustomerResponseWebApiModel;
  passengerOptions?: OrderPassengersResponseWebApiModel;
  /**
   * order tags
   */
  tags?: Array<string>;
  /**
   * common comment for all order
   */
  comment?: string;
}
