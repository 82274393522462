<section>
  <div class="azz-header">{{ 'PA_DETAILS_DRIVER_INFO' | translate }}</div>
  <div class="row">
    <div class="col-12">
      <label class="azz-transparent-input-label">{{ 'FR_SELECT_STATUS_LABEL' | translate }}</label>
      <div>{{ 'CORE_DRIVER_STATUS_' + driver?.status | translate }}</div>
    </div>
    <div class="col-12">
      <label class="azz-transparent-input-label">{{ 'FR_SELECT_DRIVER_LABEL' | translate }}</label>
      <azz-driver-circle7
        [driver]="{
          lastName: driver?.lastName,
          firstName: driver?.firstName,
          id: driver?.id,
          avatar: driver?.avatar
        }"
        [navigateUrl]="urlToDriverDetailsPage"
      >
      </azz-driver-circle7>
    </div>
    <div class="col-12">
      <label class="azz-transparent-input-label">{{ 'PHONE_NUMBER' | translate }}</label>
      <div class="row-phone">
        <div>{{ driver?.phone?.phoneCode }}{{ driver?.phone?.phoneNumber }}</div>
        <div class="phone-icon">
          <azz-phone-call-button
            *ngIf="phoneCallButtonAvailable"
            [phoneNumber]="driver?.phone?.phoneCode + driver?.phone?.phoneNumber"
            [link]="customerPhoneCallLink"
            class="phone-call-button"
          ></azz-phone-call-button>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="driver?.email">
      <label class="azz-transparent-input-label">{{ 'EMAIL' | translate }}</label>
      <div>{{ driver?.email }}</div>
    </div>
  </div>
</section>
