import { Directive, ElementRef, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[azzFocusOnInputChange]',
})
export class FocusOnInputChangeDirective implements OnChanges {
  @Input() focusTriggerCounter: number;

  constructor(private readonly el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focusTriggerCounter && changes.focusTriggerCounter.currentValue) {
      setTimeout(() => this.el.nativeElement.focus());
    }
  }
}

@NgModule({
  declarations: [FocusOnInputChangeDirective],
  exports: [FocusOnInputChangeDirective],
})
export class FocusOnInputChangeModule {}
