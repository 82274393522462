import { Directive, Inject, InjectionToken, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OrderStatusType } from '@core/constant/order-status.constant';

export const SHOW_BY_ORDER_STATUS_RULES = new InjectionToken<HideByOrderStatusRulesParams>(
  'show by order status rules'
);

/**Encapsulates display logic by order status
 *
 * @param HideByOrderStatusParam;
 */
@Directive({
  selector: '[azzShowByOrderStatus]',
})
export class AzzShowByOrderStatusDirective {
  private readonly allowed: OrderStatusType[];
  private readonly forbidden: OrderStatusType[];

  constructor(
    private readonly template: TemplateRef<any>,
    private readonly container: ViewContainerRef,
    @Inject(SHOW_BY_ORDER_STATUS_RULES) { allowed, forbidden }: HideByOrderStatusRulesParams
  ) {
    if (allowed?.length === 0 && forbidden?.length === 0) {
      throw new Error('[Error: AzzShowByOrderStatusDirective, no rules(allowed, forbidden) provided in the token');
    }

    this.allowed = allowed?.length ? allowed : [];
    this.forbidden = forbidden?.length ? forbidden : [];
  }

  @Input() set azzShowByOrderStatus(status: OrderStatusType) {
    if (this.allowed.includes(status)) {
      this.show();
      return;
    }

    if (this.forbidden.includes(status)) {
      this.hide();
      return;
    }

    this.show();
  }

  private show(): void {
    this.container.createEmbeddedView(this.template);
  }
  private hide(): void {
    this.container.clear();
  }
}

interface HideByOrderStatusRulesParams {
  allowed?: OrderStatusType[];
  forbidden?: OrderStatusType[];
}

@NgModule({
  declarations: [AzzShowByOrderStatusDirective],
  exports: [AzzShowByOrderStatusDirective],
})
export class ShowByOrderStatusDirectiveModule {}
