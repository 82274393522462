import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AzzRotateIndicatorModule } from '@common/components';
import { EmitOnEventModule, FocusOnInputChangeModule } from '@common/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CustomAutocompleteComponent } from './azz-custom-autocomplete.component';
import { SearchAddressPoiAutocompleteComponent } from './search-address-poi-autocomplete/search-address-poi-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    AzzRotateIndicatorModule,
    TranslateModule,
    FocusOnInputChangeModule,
    EmitOnEventModule,
  ],
  declarations: [CustomAutocompleteComponent, SearchAddressPoiAutocompleteComponent],
  exports: [CustomAutocompleteComponent, SearchAddressPoiAutocompleteComponent],
})
export class AzzCustomAutocompleteModule {}
