/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { CustomerBlockRequestWebApiModel } from '../model/customer-block-request-web.api-model';
// @ts-ignore
import { CustomerCreateRequestWebApiModel } from '../model/customer-create-request-web.api-model';
// @ts-ignore
import { CustomerItemResponseWebApiModel } from '../model/customer-item-response-web.api-model';
// @ts-ignore
import { CustomerSortFieldEnumWebApiModel } from '../model/customer-sort-field-enum-web.api-model';
// @ts-ignore
import { CustomerUpdateRequestWebApiModel } from '../model/customer-update-request-web.api-model';
// @ts-ignore
import { CustomersSearchResponseWebApiModel } from '../model/customers-search-response-web.api-model';
// @ts-ignore
import { ErrorResponseWebApiModel } from '../model/error-response-web.api-model';
// @ts-ignore
import { FrequentOrdersResponseWebApiModel } from '../model/frequent-orders-response-web.api-model';
// @ts-ignore
import { SortDirectionEnumWebApiModel } from '../model/sort-direction-enum-web.api-model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { PragmaticConfiguration } from '../configuration';

export interface WebCustomerBlockRequestParams {
  /** RN7 customer ID */
  customerId: string;
  /** Customer block request body object */
  customerBlockRequestWebApiModel?: CustomerBlockRequestWebApiModel;
}

export interface WebCustomerCreateRequestParams {
  /** Customer create */
  customerCreateRequestWebApiModel?: CustomerCreateRequestWebApiModel;
}

export interface WebCustomerFindFrequentOrdersRequestParams {
  /** RN7 customer ID */
  customerId: string;
  /** limit of previous orders used to find frequent addresses */
  quantity?: number;
  /** page number if specified */
  pageIndex?: number;
  /** max number of returned objects */
  pageSize?: number;
}

export interface WebCustomerGetRequestParams {
  /** RN7 customer ID */
  customerId: string;
}

export interface WebCustomerSearchRequestParams {
  /** query string to search by customers. Search will be executed in fields name, lastName, phone, refAbonne */
  q?: string;
  /** phone number to search if user with specified phone exists. Only for fulltext match */
  phoneNumber?: string;
  /** should we exclude blocked customers from response or not. By default is false */
  excludeBlocked?: boolean;
  /** sort field */
  sort?: CustomerSortFieldEnumWebApiModel;
  /** sort direction */
  direction?: SortDirectionEnumWebApiModel;
  /** page number if specified */
  pageIndex?: number;
  /** max number of returned objects */
  pageSize?: number;
}

export interface WebCustomerUnblockRequestParams {
  /** RN7 customer ID */
  customerId: string;
}

export interface WebCustomerUpdateRequestParams {
  /** RN7 customer ID */
  customerId: string;
  /** Customer update */
  customerUpdateRequestWebApiModel?: CustomerUpdateRequestWebApiModel;
}

@Injectable()
export class CustomerApiClient {
  protected basePath = 'https://develop-proxy-rn7.g7.fr/backend/web2';
  public defaultHeaders = new HttpHeaders();
  public configuration = new PragmaticConfiguration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: PragmaticConfiguration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * block customer by ID (stub)
   * A method for WEB to block customer by ID
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webCustomerBlock(
    requestParameters: WebCustomerBlockRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public webCustomerBlock(
    requestParameters: WebCustomerBlockRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public webCustomerBlock(
    requestParameters: WebCustomerBlockRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public webCustomerBlock(
    requestParameters: WebCustomerBlockRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const customerId = requestParameters?.customerId;
    if (customerId === null || customerId === undefined) {
      throw new Error('Required parameter customerId was null or undefined when calling webCustomerBlock.');
    }
    const customerBlockRequestWebApiModel = requestParameters?.customerBlockRequestWebApiModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/customers/${this.configuration.encodeParam({
      name: 'customerId',
      value: customerId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/block`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: customerBlockRequestWebApiModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Create customer (stub)
   * A method for WEB to create customer
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webCustomerCreate(
    requestParameters?: WebCustomerCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<CustomerItemResponseWebApiModel>;
  public webCustomerCreate(
    requestParameters?: WebCustomerCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<CustomerItemResponseWebApiModel>>;
  public webCustomerCreate(
    requestParameters?: WebCustomerCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<CustomerItemResponseWebApiModel>>;
  public webCustomerCreate(
    requestParameters?: WebCustomerCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const customerCreateRequestWebApiModel = requestParameters?.customerCreateRequestWebApiModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/customers`;
    return this.httpClient.request<CustomerItemResponseWebApiModel>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: customerCreateRequestWebApiModel,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * find customer frequent orders (stub)
   * A method for WEB to find customer frequent orders
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webCustomerFindFrequentOrders(
    requestParameters: WebCustomerFindFrequentOrdersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<FrequentOrdersResponseWebApiModel>;
  public webCustomerFindFrequentOrders(
    requestParameters: WebCustomerFindFrequentOrdersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<FrequentOrdersResponseWebApiModel>>;
  public webCustomerFindFrequentOrders(
    requestParameters: WebCustomerFindFrequentOrdersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<FrequentOrdersResponseWebApiModel>>;
  public webCustomerFindFrequentOrders(
    requestParameters: WebCustomerFindFrequentOrdersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const customerId = requestParameters?.customerId;
    if (customerId === null || customerId === undefined) {
      throw new Error(
        'Required parameter customerId was null or undefined when calling webCustomerFindFrequentOrders.'
      );
    }
    const quantity = requestParameters?.quantity;
    const pageIndex = requestParameters?.pageIndex;
    const pageSize = requestParameters?.pageSize;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (quantity !== undefined && quantity !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>quantity, 'quantity');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageIndex, 'pageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'pageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/customers/${this.configuration.encodeParam({
      name: 'customerId',
      value: customerId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/frequent-orders`;
    return this.httpClient.request<FrequentOrdersResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * find customer by ID (stub)
   * A method for WEB to find customer by ID
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webCustomerGet(
    requestParameters: WebCustomerGetRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<CustomerItemResponseWebApiModel>;
  public webCustomerGet(
    requestParameters: WebCustomerGetRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<CustomerItemResponseWebApiModel>>;
  public webCustomerGet(
    requestParameters: WebCustomerGetRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<CustomerItemResponseWebApiModel>>;
  public webCustomerGet(
    requestParameters: WebCustomerGetRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const customerId = requestParameters?.customerId;
    if (customerId === null || customerId === undefined) {
      throw new Error('Required parameter customerId was null or undefined when calling webCustomerGet.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/customers/${this.configuration.encodeParam({
      name: 'customerId',
      value: customerId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<CustomerItemResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Search customer by substring (stub)
   * A method for WEB to search customer. Optionally you can use q to search over parameters, or you can specify phoneNumber to search existing customers by phone
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webCustomerSearch(
    requestParameters?: WebCustomerSearchRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<CustomersSearchResponseWebApiModel>;
  public webCustomerSearch(
    requestParameters?: WebCustomerSearchRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<CustomersSearchResponseWebApiModel>>;
  public webCustomerSearch(
    requestParameters?: WebCustomerSearchRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<CustomersSearchResponseWebApiModel>>;
  public webCustomerSearch(
    requestParameters?: WebCustomerSearchRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const q = requestParameters?.q;
    const phoneNumber = requestParameters?.phoneNumber;
    const excludeBlocked = requestParameters?.excludeBlocked;
    const sort = requestParameters?.sort;
    const direction = requestParameters?.direction;
    const pageIndex = requestParameters?.pageIndex;
    const pageSize = requestParameters?.pageSize;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (q !== undefined && q !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>q, 'q');
    }
    if (phoneNumber !== undefined && phoneNumber !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>phoneNumber, 'phoneNumber');
    }
    if (excludeBlocked !== undefined && excludeBlocked !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>excludeBlocked, 'excludeBlocked');
    }
    if (sort !== undefined && sort !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sort, 'sort');
    }
    if (direction !== undefined && direction !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>direction, 'direction');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageIndex, 'pageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'pageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/customers`;
    return this.httpClient.request<CustomersSearchResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * unblock customer by ID (stub)
   * A method for WEB to unblock previously blocked customer by ID
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webCustomerUnblock(
    requestParameters: WebCustomerUnblockRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public webCustomerUnblock(
    requestParameters: WebCustomerUnblockRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public webCustomerUnblock(
    requestParameters: WebCustomerUnblockRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public webCustomerUnblock(
    requestParameters: WebCustomerUnblockRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const customerId = requestParameters?.customerId;
    if (customerId === null || customerId === undefined) {
      throw new Error('Required parameter customerId was null or undefined when calling webCustomerUnblock.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/customers/${this.configuration.encodeParam({
      name: 'customerId',
      value: customerId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/unblock`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * update customer (stub)
   * A method for WEB to update customer
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webCustomerUpdate(
    requestParameters: WebCustomerUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<CustomerItemResponseWebApiModel>;
  public webCustomerUpdate(
    requestParameters: WebCustomerUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<CustomerItemResponseWebApiModel>>;
  public webCustomerUpdate(
    requestParameters: WebCustomerUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<CustomerItemResponseWebApiModel>>;
  public webCustomerUpdate(
    requestParameters: WebCustomerUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const customerId = requestParameters?.customerId;
    if (customerId === null || customerId === undefined) {
      throw new Error('Required parameter customerId was null or undefined when calling webCustomerUpdate.');
    }
    const customerUpdateRequestWebApiModel = requestParameters?.customerUpdateRequestWebApiModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/customers/${this.configuration.encodeParam({
      name: 'customerId',
      value: customerId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<CustomerItemResponseWebApiModel>(
      'put',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: customerUpdateRequestWebApiModel,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }
}
