import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DialogDirective } from '../dialog.component';

@Component({
  selector: 'azzapp-confirm-dialog',
  templateUrl: './confirm.dialog.component.html',
})
export class ConfirmDialogComponent extends DialogDirective implements OnInit {
  @ViewChild('content') public declare dialogContent: any;
  constructor(dialogService: NgbModal) {
    super(dialogService);
  }
  ngOnInit() {
    this.setContent(this.dialogContent);
  }
}
