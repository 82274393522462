<form [formGroup]="form">
  <div class="form-group">
    <label for="taxi-number">{{ 'TAXI_NUMBER' | translate }}</label>
    <input
      id="taxi-number"
      type="text"
      name="taxi_number"
      formControlName="taxiNumber"
      maxlength="5"
      class="form-control azz-transparent-input azz-validate"
    />
    <div
      class="form-control__error"
      *ngIf="form.controls.taxiNumber.errors?.azzOnlyNumbers && form.controls.taxiNumber.touched"
    >
      <small>{{ 'CORE_ONLY_NUMBERS_FIELD_VALIDATION_ERROR' | translate }}</small>
    </div>
  </div>
</form>
