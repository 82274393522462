import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEstimation } from '@core/models/estimation.model';

@Component({
  selector: 'azz-custom-order-full-approaching-data',
  templateUrl: './full-approaching-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullApproachingDataComponent {
  @Input() public approachingData: IEstimation;
}
