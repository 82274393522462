import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { helpers } from '@core/modules/nouvelle-commande/components/date-time/date/helpers';
import moment from 'moment/moment';

export class DateValidators {
  public static minDate(from: moment.Moment): ValidatorFn {
    console.log('from', from.toDate());
    return (control: AbstractControl): ValidationErrors | null => {
      const controlMoment = helpers.convertDateStrToMoment(control.value);
      if (controlMoment.isBefore(from)) return { minDate: true };

      return null;
    };
  }

  public static maxDate(to: moment.Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlMoment = helpers.convertDateStrToMoment(control.value);
      if (controlMoment.isAfter(to)) return { maxDate: true };

      return null;
    };
  }

  public static minTime(minDate: moment.Moment, selectedDate: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const selectedDateTime = helpers.convertDateTimeStrToMoment(selectedDate, control.value);
      selectedDateTime.set('second', 59);

      if (minDate > selectedDateTime) {
        return { minTime: true };
      }

      return null;
    };
  }

  public static validTime(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const time = control.value.split(':');
    if (
      time.length != 2 ||
      time[0].length != 2 ||
      time[1].length != 2 ||
      !Number.isInteger(+time[0]) ||
      !Number.isInteger(+time[1])
    ) {
      return { invalid: true };
    }

    const [hh, mm] = time;

    if (hh > 23 || mm > 59) {
      return { invalid: true };
    }

    return null;
  }

  public static dateFormatType(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateRegEx = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;
      const value = control.value;
      if (!value) {
        return null;
      }

      if (!dateRegEx.test(value)) {
        return { invalidDateFormat: true };
      }

      return null;
    };
  }
}
