import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { PoiService, UtilService } from '@core/services/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, takeUntil } from 'rxjs/operators';

import { DialogDirective } from '../../../../common/components/dialog/dialog.component';

@Component({
  selector: 'azz-import-points-of-interest-dialog',
  templateUrl: './import-points-of-interest-dialog.component.html',
  styleUrls: ['./import-points-of-interest-dialog.component.less'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportPointsOfInterestDialogComponent extends DialogDirective implements OnInit {
  @Input() downloadUrl: string;
  @ViewChild('content') public declare dialogContent: any;
  @Output() poiSuccessImport = new EventEmitter<void>();
  @Output() poiErrorImport = new EventEmitter<string[]>();
  public attachedFile: File;
  public loadingIndicator = false;
  public modalReference: any;
  public csvMimeType: string;

  constructor(
    dialogService: NgbModal,
    private readonly poiService: PoiService,
    private readonly utilService: UtilService,
    private readonly destroyed$: NgOnDestroyService
  ) {
    super(dialogService);
  }

  ngOnInit() {
    this.setContent(this.dialogContent);
    this.csvMimeType = this.utilService.getCsvMimeType();
  }

  public override showDialog(header: string, message: string) {
    this.header = header;
    this.message = message;
    this.modalReference = this.dialogService.open(this.dialogContent, {
      windowClass: this.modalClass,
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  public onFileUpdate(file: File): void {
    this.attachedFile = file;
  }

  public importPoi(): void {
    this.enableLoadingIndicator(true);
    this.poiService
      .importPoi(this.attachedFile)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.enableLoadingIndicator(false))
      )
      .subscribe(
        () => {
          this.closeAfterSuccess();
          this.poiSuccessImport.emit();
        },
        (err: HttpErrorResponse) => this.handleImportPoiError(err)
      );
  }

  public enableLoadingIndicator(value: boolean): void {
    this.loadingIndicator = value;
  }

  public closeAfterSuccess(): void {
    this.loadingIndicator = false;
    this.modalReference.close();
  }

  public closeAfterFailure(): void {
    this.loadingIndicator = false;
    this.modalReference.close();
  }

  private handleImportPoiError(err: HttpErrorResponse): void {
    if (err.status === 400 && err.error.reason === 'poi.import.cant.determine.city') {
      this.poiErrorImport.emit(err.error.pois);
    } else {
      throw err;
    }
  }
}
