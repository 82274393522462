/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerItemResponseWebApiModel } from './customer-item-response-web.api-model';

/**
 * object with list of customers data in customers search response
 */
export interface CustomersSearchResponseWebApiModel {
  /**
   * result page number. 0 <= pageIndex < totalPages
   */
  pageIndex: number;
  /**
   * result page size. 1 <= pageSize < 100
   */
  pageSize: number;
  /**
   * total number of pages. 0 < totalPages
   */
  totalPages: number;
  /**
   * total number of items in query. 0 <= totalItems
   */
  totalItems: number;
  /**
   * List of customers found
   */
  items: Array<CustomerItemResponseWebApiModel>;
}
