<section class="auth-form-wrapper">
  <div class="azz-registration-type-form">
    <div class="azz-header-group">
      <div class="azz-header">{{ 'AUTH_REG_HEADER' | translate }}</div>
    </div>
    <div class="azz-footer-group">
      <div class="azz-form-group">
        <input
          type="button"
          name="register_email"
          value="{{ 'AUTH_REG_REGISTER_WITH_EMAIL' | translate }}"
          class="btn btn-block azz-primary-btn"
          [routerLink]="createUiSref"
          [queryParams]="queryParams"
        />
      </div>
      <form *ngIf="facebookUrl" method="POST" enctype="application/x-www-form-urlencoded" #f [action]="facebookUrl">
        <input type="hidden" [name]="paramName" [value]="paramValue" *ngIf="paramName" />

        <input
          type="submit"
          (click)="f.submit()"
          name="register_facebook"
          [value]="'AUTH_REG_REGISTER_WITH_FACEBOOK' | translate"
          class="btn btn-block azz-facebook-btn"
        />
      </form>
    </div>
  </div>
</section>
