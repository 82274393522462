import { IInvitationItem } from '@core/models/invitation.model';
import { IPhoneAdvisorRegPayload } from '@core/models/phone-advisor.model';
import { IPhoneAdvisorUserInfo } from '@core/models/user-info.model';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Phone Advisor Registration] Init');
export const destroy = createAction('[Phone Advisor Registration] Destroy');
export const getInvitation = createAction('[Phone Advisor Registration] Get Invitation', props<{ uuid: string }>());
export const getInvitationSuccess = createAction(
  '[Phone Advisor Registration] Get Invitation Success',
  props<{ invitation: IInvitationItem }>()
);
export const getInvitationError = createAction('[Phone Advisor Registration] Get Invitation Error');
export const register = createAction(
  '[Phone Advisor Registration] Register',
  props<{ body: IPhoneAdvisorRegPayload }>()
);
export const registerSuccess = createAction('[Phone Advisor Registration] Register Success');
export const registerError = createAction('[Phone Advisor Registration] Register Error');
export const updateRegistration = createAction(
  '[Phone Advisor Registration] Update Registration',
  props<{ body: IPhoneAdvisorUserInfo }>()
);
export const updateRegistrationSuccess = createAction(
  '[Phone Advisor Registration] Update Registration Success',
  props<{ user: IPhoneAdvisorUserInfo }>()
);
export const updateRegistrationError = createAction('[Phone Advisor Registration] Update Registration Error');
