<section class="azz-attach-filename">
  <form [formGroup]="attachFileForm" #formElemRef>
    <div class="form-group azz-last">
      <div class="azz-attach-block">
        <label [for]="azzId" class="azz-attach-btn" *ngIf="!hideAttacher">
          <input
            [id]="azzId"
            type="file"
            [name]="azzId + '-file'"
            class="form-control"
            [required]="ngRequired"
            (change)="onFileChange($event)"
            [accept]="azzAccept"
            [disabled]="isDisabled"
            #fileInput
          />
        </label>
        <div class="azz-attach-popup-wrapper">
          <div class="azz-attach-popup" *ngIf="value || azzUploadedData">
            <a class="azz-download-btn" *ngIf="fileName" (click)="download()"></a>
            <div class="azz-close-btn" *ngIf="!hideCloseButton" (click)="deleteAttachedFile()"></div>
            <a [attr.href]="href" target="_blank" class="azz-filename azz-word-break azz-drop-max-height" *ngIf="value">
              {{ value.name || value }}
            </a>
            <div class="azz-filename" *ngIf="!value && azzUploadedData">
              {{ 'CORE_FILE_UPLOADED_INDICATOR_TEXT' | translate }}
            </div>
            <img class="azz-type-icon" *ngIf="getAttachedFileIcon()" [src]="'assets/images/' + getAttachedFileIcon()" />
          </div>
        </div>
      </div>
      <div class="form-control__error">
        <small
          *ngIf="attachFileForm.controls.attachFile?.errors?.required && attachFileForm.controls.attachFile.touched"
        >
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="sizeError">{{ 'CORE_FILE_SIZE_INVALID' | translate }}</small>
        <small *ngIf="typeOrExtensionError">{{ typeOrExtensionErrorStr }}</small>
      </div>
    </div>
  </form>
</section>
