import { Component, HostBinding, Input } from '@angular/core';
import { IconCloseComponent } from '@icons/icon-close.component';

@Component({
  selector: 'azz-cross-button',
  standalone: true,
  templateUrl: './cross-button.component.html',
  imports: [IconCloseComponent],
  styleUrls: ['./cross-button.component.scss'],
})
export class CrossButtonComponent {
  @HostBinding('class') public class = 'azz-cross-button';
  @Input() public ariaLabel: string;
  @Input() public onClick: any;
  @Input() public styleClass: string;
}
