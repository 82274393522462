import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IException } from '../../../models/exception.model';

@Component({
  selector: 'app-exception-row',
  templateUrl: './exception-row.component.html',
  styleUrls: ['./exception-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExceptionRowComponent implements OnInit {
  @Input() exception: IException;
  @Output() navigateToCustomer$ = new EventEmitter<number>();
  @Output() navigateToDriver$ = new EventEmitter<number>();
  @HostBinding('class') exceptionClass: string;
  public unsubscribe = new Subject<void>();
  public secondsSinceCreated: number;

  constructor(
    protected cd: ChangeDetectorRef,
    private readonly translate: TranslateService
  ) {}

  public get displayTime() {
    const duration = moment.duration(this.secondsSinceCreated, 'seconds');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return this.showCountDownView(seconds, minutes, hours, days);
  }

  ngOnInit() {
    this.secondsSinceCreated = this.exception.secondsSinceCreated;
    this.startTimer();
  }

  public showCountDownView(seconds, minutes, hours, days?): string {
    const dayLabel = this.translate.instant('DAY').toLowerCase();
    const daysLabel = this.translate.instant('DAYS').toLowerCase();
    const finalDays = days === 0 ? '' : days === 1 ? `${days} ${dayLabel},` : `${days} ${daysLabel},`;
    const finalHours = hours.toString().length > 1 ? hours : `0${hours}`;
    const finalMinutes = minutes.toString().length > 1 ? minutes : `0${minutes}`;
    const finalSeconds = seconds.toString().length > 1 ? seconds : `0${seconds}`;
    return `${finalDays} ${finalHours}:${finalMinutes}:${finalSeconds}`;
  }

  public navigateToCustomer(evt: Event, id: number): void {
    evt.stopPropagation();
    this.navigateToCustomer$.emit(id);
  }

  public navigateToDriver(evt: Event, id: number): void {
    evt.stopPropagation();
    this.navigateToDriver$.emit(id);
  }

  private startTimer(): void {
    timer(0, 1000)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.increaseCounterByOne();
        this.paintExceptionRow();
        this.cd.detectChanges();
      });
  }

  private increaseCounterByOne(): void {
    this.secondsSinceCreated++;
  }

  private paintExceptionRow(): void {
    const minutesDelta = this.secondsSinceCreated / 60;
    if (minutesDelta < 5) {
      this.exceptionClass = 'exception--green';
    } else if (minutesDelta < 10) {
      this.exceptionClass = 'exception--orange';
    } else {
      this.exceptionClass = 'exception--red';
    }
  }
}
