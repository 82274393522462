import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AzzSpinnerButtonComponent } from './spinner-button.component';
import { FocusOnInputChangeModule } from '../../directives/focus-on-input-change';
import { AzzRotateIndicatorModule } from '../rotate-indicator/rotate-indicator.module';

@NgModule({
  imports: [CommonModule, FocusOnInputChangeModule, AzzRotateIndicatorModule],
  declarations: [AzzSpinnerButtonComponent],
  exports: [AzzSpinnerButtonComponent],
})
export class SpinnerButtonModule {}
