import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ICustomer } from '../../../../models/customer.model';
import { IOrder } from '../../../../models/order.model';
import { PhoneAdvisorOrder } from '../../../../models/phone-advisor-order';

@Component({
  selector: 'azz-custom-order-customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['../customer-driver.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDataComponent {
  @Input() customer: ICustomer;
  @Input() order: IOrder | PhoneAdvisorOrder;
  @Input() urlToCustomerDetailsPage: string;
  @Input() phoneCallButtonAvailable: boolean;
  public customerPhoneCallLink =
    'https://api-mediation.flexiblecontactcenter.orange-business.com/clickToCall?phoneNumber=%2B';
}
