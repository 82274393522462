import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import {
  IColorManagementFull,
  IColorManagementParams,
  IColorManagementShort,
} from '../../models/color-management.model';

@Injectable({ providedIn: 'root' })
export class ColorManagementService extends ApiService {
  private readonly API_URL = 'user/api/v1/fleet/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getColorManagement(fleetId: number, params: IColorManagementParams): Observable<IColorManagementFull> {
    return this.get(`${this.API_URL}${fleetId}/colorManagement`, params);
  }

  public activateColorManagement(fleetId: number, enabled: boolean): Observable<any> {
    return this.put(`${this.API_URL}${fleetId}/colorManagement/activate`, { activate: enabled });
  }

  public updateColorManagement(fleetId: number, params: IColorManagementShort): Observable<any> {
    return this.put(`${this.API_URL}${fleetId}/colorManagement`, params);
  }
}
