import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_LOGOUT_ROUTE, LOGIN_ERROR_REASON } from '@core/constant/auth.constant';
import { PA_ROUTE } from '@core/constant/phone-advisor.constant';
import { ILogoutData } from '@core/models/auth.model';
import { ICurrentUserInfo } from '@core/models/user-info.model';
import { FirebaseService, LocalStorageService, LoginService } from '@core/services/common';
import { CtiService } from '@core/services/cti/cti.service';
import { UserInfoService } from '@core/services/users/user-info.service';
import { UserService } from '@core/services/users/user.service';
import { AppStore } from '@core/store';
import { createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EffectsBase } from '@store/effects/base.effects';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, throwError } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as AuthActions from '../actions';
import { AuthStore } from '../index';

@Injectable()
export class AuthInfoEffects extends EffectsBase {
  private readonly loginService = inject(LoginService);
  private readonly userService = inject(UserService);
  private readonly firebaseService = inject(FirebaseService);
  private readonly router = inject(Router);
  private readonly cookieService = inject(CookieService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly userInfoService = inject(UserInfoService);
  private readonly ctiService = inject(CtiService);

  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap(({ authData, fleetId }) =>
        this.loginService.login(authData).pipe(
          map(() => AuthActions.loginSuccess({ fleetId })),
          catchError(error => {
            if (error.status === 401 && error.error.reason === LOGIN_ERROR_REASON.SESSION_EXISTS) {
              return throwError(() => new Error('AUTH_LOG_IN_ERROR_DIALOG_MESSAGE'));
            }

            return this.catchErrorHandler(error, AuthActions.loginError({ error: error.error }));
          })
        )
      )
    )
  );

  public loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      mergeMap(({ fleetId }) =>
        this.userInfoService.getInfo().pipe(
          map((userInfo: ICurrentUserInfo) => AuthActions.handleUserInfo({ userInfo, fleetId })),
          this.catchError(AuthActions.userInfoError())
        )
      )
    )
  );

  public handleUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.handleUserInfo),
      switchMap(({ userInfo, fleetId }) => this.handleUserInfo(userInfo, fleetId))
    )
  );

  public setUserInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.setUserInfo),
        tap(({ userInfo }) => this.userService.setCurrentUserInfo(userInfo))
      ),
    { dispatch: false }
  );

  public userInfoError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userInfoError),
      map(() => AuthActions.logout({ route: '/login' }))
    )
  );

  public firebaseSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.firebaseSignIn),
      exhaustMap(() =>
        this.firebaseService.signIn().pipe(
          map(() => AuthActions.firebaseSignInSuccess()),
          this.catchError(AuthActions.firebaseSignInError())
        )
      )
    )
  );

  public firebaseSignInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.firebaseSignInSuccess),
        tap(() => this.router.navigate([this.userService.getUserDefaultRoute()]))
      ),
    { dispatch: false }
  );

  public firebaseSignInError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.firebaseSignInError),
      tap(() => {
        this.localStorageService.clear();
        this.cookieService.deleteAll();
        // this.cookieService.removeAll();
      }),
      map(() => AuthActions.logout({ route: '/login' }))
    )
  );

  public tryLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.tryLogout),
      withLatestFrom(
        this.selectFromStore<string>(AppStore.selectRouterUrl),
        this.selectFromStore(AppStore.selectRouterParam('orderId'))
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_, url, orderIdParam]) => {
        const action: Action = AuthStore.logout({ showPopup: true });
        const roles = this.userService.getCurrentUserInfo()?.user?.roles || [];
        const isPhoneAdvisor = roles.indexOf('ROLE_PHONE_ADVISOR') !== -1;
        const isDispatcher = roles.indexOf('ROLE_DISPATCHER') !== -1;
        // REDESIGN - temporarily solution
        if (isPhoneAdvisor && this.isRouteProhibitedForNouvelleCommander(url)) {
          this.alertWarn('redesign_logout');
        }

        if ((isPhoneAdvisor || isDispatcher) && this.isRouteProhibitedForCoffeeBreak(url, orderIdParam)) {
          this.alertWarn('PROHIBIT_TO_LOGOUT_ROUTE');
          return { type: '' };
        } else if (this.hasActiveOrPendingCall()) {
          // ToDo Refactor after CTI NGRX REFACTORING
          this.alertWarn('PROHIBIT_COFFEE_BREAK_CAUSE_OF_CALL');
          return { type: '' };
        }
        return action;
      })
    )
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap((payload?: Partial<ILogoutData>) => {
        if (payload?.showPopup) {
          this.dispatch(AuthActions.toggleLogoutPopup({ isLogoutPopupShown: true }));
        }
      }),
      mergeMap((payload?: Partial<ILogoutData>) =>
        this.loginService.logout().pipe(
          mergeMap(() => this.firebaseService.signOut().pipe(catchError(() => EMPTY))),
          map(() => AuthActions.logoutSuccess({ route: payload.route || DEFAULT_LOGOUT_ROUTE })),
          this.catchError(AuthActions.logoutError())
        )
      )
    )
  );

  public logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutSuccess),
      tap(({ route }) => {
        this.localStorageService.clear();
        // this.cookieService.removeAll();
        this.cookieService.deleteAll();
        void this.router.navigate([route || DEFAULT_LOGOUT_ROUTE]);
      }),
      map(() => AuthActions.toggleLogoutPopup({ isLogoutPopupShown: false }))
    )
  );

  public logoutError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutError),
      map(() => AuthActions.toggleLogoutPopup({ isLogoutPopupShown: false }))
    )
  );

  private handleUserInfo(userInfo: ICurrentUserInfo, fleetId?: number): Action[] {
    let actions = [];
    if (this.isPhoneAdvisorRole(userInfo) && !userInfo.fleet.crcServiceEnabled) {
      this.alertWarn('AUTH_PHONE_ADVISOR_CRC_DISABLED');
      actions.push(AuthActions.logout({ route: '/login' }));
    } else if (this.isDispatcherRole(userInfo) && !userInfo.fleet.dispatchManually) {
      actions.push(AuthActions.logout({ route: '/login' }));
      this.alertWarn('AUTH_DISPATCHER_DISPATCH_MANUALLY_DISABLED');
    } else if (this.isPhoneAdvisorDeactivated(userInfo)) {
      actions.push(AuthActions.logout({ route: '/login' }));
      this.alertWarn('AUTH_PHONE_ADVISOR_DEACTIVATED');
    } else if (this.isPhoneAdvisorInactive(userInfo)) {
      actions.push(AuthActions.logout({ route: '/login' }));
      this.alertWarn('AUTH_PHONE_ADVISOR_DEACTIVATED');
    } else if (this.isFleetAdminDeactivated(userInfo)) {
      actions.push(AuthActions.logout({ route: '/login' }));
      this.alertWarn('AUTH_FlEET_ADMIN_DEACTIVATED');
    } else if (this.isDispatcherDeactivated(userInfo)) {
      actions.push(AuthActions.logout({ route: '/login' }));
      this.alertWarn('AUTH_FlEET_ADMIN_DEACTIVATED');
    } else if (fleetId && this.isPhoneAdvisorRole(userInfo) && fleetId !== userInfo.fleet.id) {
      actions.push(AuthActions.logout({ route: '/login', showPopup: true }));
    } else if (this.isPhoneAdvisorRole(userInfo) && this.isCtiEnabled(userInfo)) {
      actions = this.handleCti(userInfo);
    } else if (this.isSuperAdminDeactivated(userInfo)) {
      actions.push(AuthActions.logout({ route: '/login' }));
      this.alertWarn('AUTH_SUPER_ADMIN_DEACTIVATED');
    } else {
      actions.push(AuthActions.setUserInfo({ userInfo }));
      actions.push(AuthActions.firebaseSignIn());
    }
    return actions;
  }

  private isPhoneAdvisorRole(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_PHONE_ADVISOR') !== -1;
  }

  private isDispatcherRole(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_DISPATCHER') !== -1;
  }

  private isPhoneAdvisorDeactivated(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_PHONE_ADVISOR_DEACTIVATED') !== -1;
  }

  private isPhoneAdvisorInactive(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_PHONE_ADVISOR_INACTIVE') !== -1;
  }

  private isSuperAdminDeactivated(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_ADMIN_DEACTIVATED') !== -1;
  }

  private isFleetAdminDeactivated(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_FLEET_ADMIN_DEACTIVATED') !== -1;
  }

  private isDispatcherDeactivated(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_DISPATCHER_DEACTIVATED') !== -1;
  }

  private isCtiEnabled(res: ICurrentUserInfo): boolean {
    return res?.fleet?.ctiEnabled;
  }

  private handleCti(userInfo: ICurrentUserInfo): Action[] {
    const actions: Action[] = [];
    if (!this.isWebRtcSupported()) {
      this.alertWarn('WEB_RTC_NOT_SUPPORTED');
      actions.push(AuthActions.logout({ route: '/login' }));
    } else {
      if (!this.hasAssignedAgent(userInfo)) {
        this.alertWarn('NOT_ASSIGNED_AGENT');
        actions.push(AuthActions.logout({ route: '/login' }));
      } else {
        actions.push(AuthActions.setUserInfo({ userInfo }));
        actions.push(AuthActions.firebaseSignIn());
      }
    }
    return actions;
  }

  private isWebRtcSupported(): boolean {
    return (
      (navigator as any).getUserMedia ||
      (navigator as any).webkitGetUserMedia ||
      (navigator as any).mozGetUserMedia ||
      (navigator as any).msGetUserMedia ||
      (window as any).RTCPeerConnection
    );
  }

  private hasAssignedAgent(res: ICurrentUserInfo): boolean {
    return 'agentUuid' in res.user ? !!res.user?.agentUuid : false;
  }

  private isRouteProhibitedForCoffeeBreak(url: string, orderIdParam: string): boolean {
    const FULL_PROHIBIT = [PA_ROUTE.CREATE_ORDER, PA_ROUTE.CREATE_ORDER_WITH_CTI];
    const PARTIAL_PROHIBIT = [PA_ROUTE.ORDERS, PA_ROUTE.EXCEPTIONS];

    return (
      FULL_PROHIBIT.some(route => url.includes(route)) ||
      (PARTIAL_PROHIBIT.some(route => url.includes(route)) && !!orderIdParam)
    );
  }

  // REDESIGN - temporarily solution
  private isRouteProhibitedForNouvelleCommander(url: string): boolean {
    const FULL_PROHIBIT = [PA_ROUTE.CREATE_ORDER_WITH_CTI];

    return FULL_PROHIBIT.some(route => url.includes(route));
  }

  private hasActiveOrPendingCall(): boolean {
    return !!this.ctiService.session || !!this.ctiService?.activeSessions?.length;
  }
}
