import { AuthStore } from '@auth/store';
import { DRIVER_SERVICE_TYPE_TAG, DRIVER_VEHICLE_TYPE_TAG } from '@core/constant/tag.constant';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { IColor, IEnergy, IMake, IModel } from '../../../../models/vehicle.model';
import * as VehicleInfoActions from '../actions';

export const vehicleInfoFeatureKey = 'vehicle-info';

const getProductionYears = (): number[] => {
  const arr = [];
  const MIN_PRODUCTION_YEAR_DIFFERENCE = 10;
  let currentYear = new Date().getFullYear();
  const minYear = currentYear - MIN_PRODUCTION_YEAR_DIFFERENCE;
  for (currentYear; currentYear >= minYear; currentYear--) {
    arr.push(currentYear);
  }
  return arr;
};

export interface State {
  makes: IMake[];
  models: IModel[];
  energies: IEnergy[];
  colors: IColor[];
  productionYears: number[];
  serviceTypes: { id: string; value: string[] }[];
  vehicleTypes: { id: string; value: string[] }[];
}

const initialState: State = {
  makes: [],
  models: [],
  energies: [],
  colors: [],
  productionYears: getProductionYears(),
  serviceTypes: DRIVER_SERVICE_TYPE_TAG,
  vehicleTypes: DRIVER_VEHICLE_TYPE_TAG,
};

const vehicleInfoReducer = createReducer(
  initialState,
  on(VehicleInfoActions.getMakesSuccess, (state, { makes }) => ({ ...state, makes })),
  on(VehicleInfoActions.getMakesError, state => ({ ...state, makes: [] })),
  on(VehicleInfoActions.getModels, state => ({ ...state, models: [] })),
  on(VehicleInfoActions.getModelsSuccess, (state, { models }) => ({ ...state, models })),
  on(VehicleInfoActions.getModelsError, state => ({ ...state, models: [] })),
  on(VehicleInfoActions.getEnergiesSuccess, (state, { energies }) => ({ ...state, energies })),
  on(VehicleInfoActions.getEnergiesError, state => ({ ...state, energies: [] })),
  on(VehicleInfoActions.getColorsSuccess, (state, { colors }) => ({ ...state, colors })),
  on(VehicleInfoActions.getColorsError, state => ({ ...state, colors: [] })),
  on(VehicleInfoActions.destroy, state => ({ ...state, models: [] }))
);

export const reducer = (state: State | undefined, action: Action) => vehicleInfoReducer(state, action);

export const clearState =
  (r: ActionReducer<State>): ActionReducer<State> =>
  (state, action) => {
    if (action?.type === AuthStore.logoutSuccess.type) {
      return r(undefined, action);
    }

    return r(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
