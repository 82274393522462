import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BillingStatusType } from './billing-sstatus.constant';
import { OrderStatusType } from './order-status.constant';
import { IDropdownBillingOrderStatus, IDropdownOrderStatus } from '../models/order.model';

interface StatusName {
  name: OrderStatusType;
}

interface BillingStatusName {
  name: BillingStatusType;
}

type StatusNames = StatusName[];
type BillingStatusNames = BillingStatusName[];

@Injectable({ providedIn: 'root' })
export class OrderStatusesFactory {
  private readonly translate = inject(TranslateService);

  private readonly default = { value: null, title: this.translate.instant('CORE_SHOW_ALL_OPT') };
  private readonly statusNameArr: StatusNames = [
    { name: 'CREATE' },
    { name: 'CONFIRMED' },
    { name: 'FINISHED' },
    { name: 'FINISHED_AUTO' },
    { name: 'NO_VEHICLE_FOUND' },
    { name: 'CANCELLED_BY_DRIVER' },
    { name: 'CANCELLED_BY_CUSTOMER' },
    { name: 'CANCELLED_BY_PHONE_ADVISOR' },
    { name: 'INVALID' },
    { name: 'DISPATCHING' },
    { name: 'IN_QUEUE' },
    { name: 'APPROACH' },
    { name: 'AT_DEPARTURE_ADDRESS' },
    { name: 'TOWARDS_DESTINATION' },
    { name: 'CUSTOMER_NO_SHOW' },
  ];

  private readonly billingStatusNameArr: BillingStatusNames = [
    { name: 'VALIDATED' },
    { name: 'NOT_VALIDATED' },
    { name: 'TRANSMITTED' },
    { name: 'TRANSMIT_ERROR' },
  ];

  get statuses(): IDropdownOrderStatus[] {
    const statuses: IDropdownOrderStatus[] = this.statusNameArr.map((item: StatusName) => ({
      value: item.name,
      title: this.translate.instant('CORE_ORDER_STATUS_' + item.name),
    }));

    return [this.default, ...statuses];
  }

  get billingStatus(): IDropdownBillingOrderStatus[] {
    const billingStatuses: IDropdownBillingOrderStatus[] = this.billingStatusNameArr.map((item: BillingStatusName) => ({
      value: item.name,
      title: this.translate.instant('CORE_BILLING_ORDER_STATUS_' + item.name),
    }));

    return [this.default, ...billingStatuses];
  }

  get defaultStatus(): IDropdownOrderStatus {
    return this.default;
  }

  get allStatuses() {
    return [
      'CREATE',
      'CONFIRMED',
      'FINISHED',
      'FINISHED_AUTO',
      'NO_VEHICLE_FOUND',
      'CANCELLED_BY_DRIVER',
      'CANCELLED_BY_CUSTOMER',
      'CANCELLED_BY_PHONE_ADVISOR',
      'INVALID',
      'DISPATCHING',
      'APPROACH',
      'AT_DEPARTURE_ADDRESS',
      'TOWARDS_DESTINATION',
      'CUSTOMER_NO_SHOW',
    ];
  }

  get allBillingStatuses(): BillingStatusType[] {
    return ['VALIDATED', 'NOT_VALIDATED', 'TRANSMITTED', 'TRANSMIT_ERROR'];
  }
}
