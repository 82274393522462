import { Component, Input } from '@angular/core';
import { G7Order } from '@core/models/g7-order.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'azz-g7-multi-return-error-popup',
  templateUrl: './multi-return-error-popup.component.html',
  styleUrls: ['./multi-return-error-popup.component.less'],
})
export class G7MultiReturnErrorPopupComponent {
  @Input() orders: G7Order[];

  constructor(private readonly modal: NgbActiveModal) {}

  public close(): void {
    this.modal.close();
  }
}
