<div class="azz-table-wrapper">
  <table class="table table-hover azz-table">
    <thead>
      <tr class="d-table-row">
        <th>{{ 'PA_DETAILS_APPROACHING' | translate }}</th>
        <th>{{ 'PA_DETAILS_REALIZED' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="azz-clickable">
        <td>{{ 'CORE_TIME' | translate }}</td>
        <td>{{ realTimeToDepartureAddress | azzSecondsToUIOrderTime }}</td>
      </tr>
      <tr class="azz-clickable">
        <td>{{ 'PA_CREATE_DISTANCE' | translate }}</td>
        <td>{{ distanceToDepartureAddress | azzKilometers }}</td>
      </tr>
    </tbody>
  </table>
</div>
