import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AzzPhoneInputModule, AzzRotateIndicatorModule, DialogModule, SpinnerButtonModule } from '@common/components';
import { DatePickerModule } from '@common/date-picker/date-picker.module';
import { FocusOnInputChangeModule, OpenDatepickerOnEnterClickModule } from '@common/directives';
import { CheckboxModule } from '@common/forms';
import { LabeledInputModule } from '@common/forms/labeled-input/labeled-input.module';
import { PhoneComponent } from '@controls/phone/phone.component';
import { CustomDialogModule } from '@core/modules/custom-dialog/custom-dialog.module';
import { OldTimeComponent } from '@core/modules/nouvelle-commande/components/date-time/time/time.component';
import { NgbCarouselModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { BaseCreateOrderComponent } from './base-create-order.component';
import { BaseCreateOrderRoutingModule } from './base-create-order.routing-module';
import { AzzCustomAutocompleteModule } from '../azz-custom-autocomplete/azz-custom-autocomplete.module';
import { AddressPoiAutocompleteComponent } from './components/address-poi-autocomplete/address-poi-autocomplete.component';
import { AttributeTagsComponent } from './components/attribute-tags/attribute-tags.component';
import { CustomerPreviousOrderComponent } from './components/customer-prev-order/customer-previous-order.component';
import { PrevOrderPaymentTypeComponent } from './components/customer-prev-order/prev-order-payment-type/prev-order-payment-type.component';
import { PrevOrderTagsComponent } from './components/customer-prev-order/prev-order-tags/prev-order-tags.component';
import { CustomerSummaryBlockComponent } from './components/customer-summary-block/customer-summary-block.component';
import { CustomerTypeaheadComponent } from './components/customer-typeahead/customer-typeahead.component';
import { DatePopupControlComponent } from './components/date-popup-control/date-popup-control.component';
import { OrderPreSaveDialogComponent } from './components/order-pre-save-dialog/order-pre-save.dialog.component';
import { OrderServiceVehicleTypeComponent } from './components/order-service-vehicle-type/order-service-vehicle-type.component';
import { SummaryBlockComponent } from './components/order-summary-block/summary-block.component';
import { AzzSecondsToUIOrderTimePipeModule } from '../custom-order/pipes/seconds-to-ui-order-time.pipe';
import { CustomOrderSharedModule } from '../custom-order/shared/custom-order-shared.module';

@NgModule({
  imports: [
    AzzCustomAutocompleteModule,
    AzzPhoneInputModule,
    AzzRotateIndicatorModule,
    AzzSecondsToUIOrderTimePipeModule,
    BaseCreateOrderRoutingModule,
    CheckboxModule,
    CommonModule,
    CustomOrderSharedModule,
    DatePickerModule,
    DialogModule,
    FocusOnInputChangeModule,
    FormsModule,
    LabeledInputModule,
    NgbCarouselModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    OpenDatepickerOnEnterClickModule,
    ReactiveFormsModule,
    SpinnerButtonModule,
    TranslateModule,
    CustomDialogModule,
    PhoneComponent,
    OldTimeComponent,
  ],
  declarations: [
    BaseCreateOrderComponent,
    CustomerPreviousOrderComponent,
    CustomerSummaryBlockComponent,
    CustomerTypeaheadComponent,
    SummaryBlockComponent,
    OrderPreSaveDialogComponent,
    PrevOrderTagsComponent,
    PrevOrderPaymentTypeComponent,
    OrderServiceVehicleTypeComponent,
    AddressPoiAutocompleteComponent,
    AttributeTagsComponent,
    DatePopupControlComponent,
  ],
  exports: [BaseCreateOrderComponent],
})
export class BaseCreateOrderModule {}
