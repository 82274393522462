import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DeactivationPopupComponent } from './deactivation-popup.component';
import { DatePickerModule } from '../../date-picker/date-picker.module';
import { ConfirmPopupModule } from '../confirm-popup/confirm-popup.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, ConfirmPopupModule, DatePickerModule],
  declarations: [DeactivationPopupComponent],
  exports: [DeactivationPopupComponent],
})
export class DeactivationPopupModule {}
