export const ORDER_LOG = {
  COMMON: 'COMMON',
  DISPATCH: 'DISPATCH',
  ERROR: 'ERROR',
  REDISPATCH: 'REDISPATCH',
} as const;

export type OrderLogType = (typeof ORDER_LOG)[keyof typeof ORDER_LOG];

export const ORDER_LOG_ACTION_MODIFICATION = {
  ORDER_CREATED: 'ORDER_CREATED',
  DRIVER_ASSIGN: 'DRIVER_ASSIGN',
  DRIVER_PREASSIGN: 'DRIVER_PREASSIGN',
  CALL_CUSTOMER_OK: 'CALL_CUSTOMER_OK',
  CALL_CUSTOMER_UNREACHABLE: 'CALL_CUSTOMER_UNREACHABLE',
  CALL_CUSTOMER_DOESNT_ANSWER: 'CALL_CUSTOMER_DOESNT_ANSWER',
  CALL_DRIVER_OK: 'CALL_DRIVER_OK',
  CALL_DRIVER_UNREACHABLE: 'CALL_DRIVER_UNREACHABLE',
  CALL_DRIVER_DOESNT_ANSWER: 'CALL_DRIVER_DOESNT_ANSWER',
  DRIVER_LATE: 'DRIVER_LATE',
  CUSTOMER_NO_SHOW: 'CUSTOMER_NO_SHOW',
  EXCEPTION_CREATED_AUTOMATICALLY: 'EXCEPTION_CREATED_AUTOMATICALLY',
  EXCEPTION_CREATED_BY_PA: 'EXCEPTION_CREATED_BY_PA',
  EXCEPTION_CREATED_BY_DRIVER: 'EXCEPTION_CREATED_BY_DRIVER',
  EXCEPTION_CLOSED: 'EXCEPTION_CLOSED',
  EXCEPTION_CLOSED_AUTOMATICALLY: 'EXCEPTION_CLOSED_AUTOMATICALLY',
  EXCEPTION_CLOSED_AUTOMATICALLY_1: 'EXCEPTION_CLOSED_AUTOMATICALLY_1',
  CANCEL_ORDER_BY_PHONE_ADVISOR: 'CANCEL_ORDER_BY_PHONE_ADVISOR',
  CANCEL_ORDER_BY_DRIVER: 'CANCEL_ORDER_BY_DRIVER',
  CANCEL_ORDER_BY_CUSTOMER: 'CANCEL_ORDER_BY_CUSTOMER',
  LATE_WAIT_THE_PASSENGER_X_MINUTES: 'LATE_WAIT_THE_PASSENGER_X_MINUTES',
  LATE_SEND_SMS_TO_PASSENGER_X_MINUTES: 'LATE_SEND_SMS_TO_PASSENGER_X_MINUTES',
  NO_TAXI_FOUND_TRY_AGAIN: 'NO_TAXI_FOUND_TRY_AGAIN',
  DRIVER_DELAYED: 'DRIVER_DELAYED',
  NO_DRIVER_ASSIGN: 'NO_DRIVER_ASSIGN',
  REFUSE_BY_DRIVER: 'REFUSE_BY_DRIVER',
  REFUSE_BY_TIMEOUT: 'REFUSE_BY_TIMEOUT',
  DRIVER_PROBLEM: 'DRIVER_PROBLEM',
  EMAIL_SENDED: 'EMAIL_SENDED',
  REDISPATCH_AGAIN: 'REDISPATCH_AGAIN',
  DRIVER_ASSIGN_MANUALLY: 'DRIVER_ASSIGN_MANUALLY',
  DRIVER_CHANGED_MANUALLY: 'DRIVER_CHANGED_MANUALLY',
  UPDATE_STATUS: 'UPDATE_STATUS',
  DRIVER_UNASSIGN: 'DRIVER_UNASSIGN',
  RESOLVE_TAXI_NOT_FOUND_BY_PA: 'RESOLVE_TAXI_NOT_FOUND_BY_PA',
  DRIVERS_CONNECTED_LIST: 'DRIVERS_CONNECTED_LIST',
  DRIVERS_VALID_LIST_DISPATCH_ZONE: 'DRIVERS_VALID_LIST_DISPATCH_ZONE',
  DRIVERS_VALID_LIST_BUSINESS_ZONE: 'DRIVERS_VALID_LIST_BUSINESS_ZONE',
  DRIVERS_VALID_LIST: 'DRIVERS_VALID_LIST',
  DRIVERS_REFUSED_LIST: 'DRIVERS_REFUSED_LIST',
  NO_DRIVERS_IN_ZONE_LEFT: 'NO_DRIVERS_IN_ZONE_LEFT',
  DRIVERS_IN_ZONE_LIST: 'DRIVERS_IN_ZONE_LIST',
  DRIVERS_NOT_EXCLUDED_ETA: 'DRIVERS_NOT_EXCLUDED_ETA',
  DRIVERS_NOT_EXCLUDED_ETA_PLUS_PARAM: 'DRIVERS_NOT_EXCLUDED_ETA_PLUS_PARAM',
  DRIVERS_WITH_DURATION_LIST: 'DRIVERS_WITH_DURATION_LIST',
  SCHEDULE_ORDER: 'SCHEDULE_ORDER',
  MAX_DISPATCH_ATTEMPTS_EXCEEDED: 'MAX_DISPATCH_ATTEMPTS_EXCEEDED',
  REDISPATCH_ATTEMPT: 'REDISPATCH_ATTEMPT',
  DRIVER_CONNECTED_LIST_SAVED_DISPATCH_ZONE: 'DRIVER_CONNECTED_LIST_SAVED_DISPATCH_ZONE',
  DRIVERS_VALID_CONNECTED_LIST_SAVED_DISPATCH_ZONE: 'DRIVERS_VALID_CONNECTED_LIST_SAVED_DISPATCH_ZONE',
  NO_PROPOSAL_ATTEMPTS_LEFT: 'NO_PROPOSAL_ATTEMPTS_LEFT',
} as const;

export type orderLogActionModificationType =
  (typeof ORDER_LOG_ACTION_MODIFICATION)[keyof typeof ORDER_LOG_ACTION_MODIFICATION];

export const ORDER_LOG_ACTION = {
  DIFFERENCES_MESSAGE: 'DIFFERENCES_MESSAGE',
  DISPATCH: 'DISPATCH',
  REDISPATCH: 'REDISPATCH',
} as const;

export type OrderLogActionType = (typeof ORDER_LOG_ACTION)[keyof typeof ORDER_LOG_ACTION];
