<div class="azz-control-group type" [formGroup]="form">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="vehicle_service" class="azz-transparent-input-label">
        {{ 'PA_CREATE_NEW_ORDER_SERVICES_HEADER' | translate }}
      </label>
      <select
        id="vehicle_service"
        name="vehicle_service"
        formControlName="serviceType"
        [compareWith]="compareFn"
        class="form-control azz-transparent-input azz-expandable"
      >
        <option
          *ngFor="let item of uiTypes.service"
          [ngValue]="item"
          [disabled]="isServiceTypeTagDisabled(item)"
          class="type__service-option"
        >
          <!-- || isServiceTypeIncompatibleWithPoi(item) -->
          {{ 'SERVICE_TYPE_' + item?.id | translate }}
        </option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="vehicle_type" class="azz-transparent-input-label">
        {{ 'PA_CREATE_NEW_ORDER_VEHICLE_TYPE_HEADER' | translate }}
      </label>
      <select
        id="vehicle_type"
        name="vehicle_type"
        formControlName="vehicleType"
        [compareWith]="compareFn"
        class="form-control azz-transparent-input azz-expandable"
      >
        <option *ngFor="let item of uiTypes.vehicle" [ngValue]="item" [disabled]="isVehicleTypeTagDisabled(item)">
          {{ 'VEHICLE_TYPE_' + item?.id | translate }}
        </option>
      </select>
    </div>
  </div>
</div>
