import { ITimerColorStep } from '@core/models/timer-color-step';
import { Action, createReducer, on } from '@ngrx/store';
import { closeTimer, openTimer } from '@store/actions';

export const timerFeatureKey = 'timer';

export interface State {
  isOpen: boolean;
  openTimespan?: number;
  colorSettings?: ITimerColorStep[];
}

const initialState: State = {
  isOpen: false,
};

const timerReducer = createReducer(
  initialState,
  on(openTimer, (_state, { openTimespan, colorSettings }) => ({
    isOpen: true,
    openTimespan,
    colorSettings,
  })),
  on(closeTimer, () => initialState)
);
export const reducer = (state = initialState, action: Action) => timerReducer(state, action);
