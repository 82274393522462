import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CustomersStore } from './store';
import { IPaginationState, ISortingState } from '../../models/common.model';
import { ICustomersData, ICustomersDataParams } from '../../models/customer.model';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersComponent implements OnInit, OnDestroy {
  public customersData$: Observable<ICustomersData>;
  public loading$: Observable<boolean>;
  public sorting$: Observable<ISortingState>;
  public filterModel$: Observable<Partial<ICustomersDataParams>>;

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.store.dispatch(CustomersStore.init());
    this.initData();
    this.getData();
  }

  public updateSorting(sorting: Partial<ISortingState>): void {
    this.store.dispatch(CustomersStore.updateSorting({ sorting }));
  }

  public updatePagination(pagination: Partial<IPaginationState>): void {
    this.store.dispatch(CustomersStore.updatePagination({ pagination }));
  }

  public updateFilterModel(filterModel: Partial<ICustomersDataParams>): void {
    this.store.dispatch(CustomersStore.updateFilterModel({ filterModel }));
  }

  ngOnDestroy() {
    this.store.dispatch(CustomersStore.destroy());
  }

  private initData(): void {
    this.customersData$ = this.store.pipe(select(CustomersStore.customersData));
    this.loading$ = this.store.pipe(select(CustomersStore.loading));
    this.filterModel$ = this.store.pipe(select(CustomersStore.filterModel));
    this.sorting$ = this.store.pipe(select(CustomersStore.sorting));
  }

  private getData(): void {
    this.store.dispatch(CustomersStore.getCustomers());
  }
}
