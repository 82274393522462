import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { toBoolean } from '@core/utils/to-boolean';

@Component({
  selector: 'azz-rotate-indicator7',
  templateUrl: './rotate-indicator.component.html',
  styleUrls: ['./rotate-indicator.component.less'],
})
export class AzzRotateIndicatorComponent implements OnChanges {
  @Input() visible: boolean;
  @Input() noDelay: any;
  @Input() size: 'extra-small' | 'small' | 'medium' | 'big' = 'small';
  @Input({ transform: toBoolean }) newDesign: boolean = false;

  private readonly MIN_DISPLAY_TIME_MS = 1000;

  ngOnChanges(changes: SimpleChanges) {
    const visibleState = changes.visible;
    let delay;

    if (!this.noDelay && !visibleState.currentValue && visibleState.previousValue) {
      delay = this.MIN_DISPLAY_TIME_MS;
    }

    this.setVisibility(visibleState.currentValue, delay);
  }

  public setVisibility(value, delay) {
    setTimeout(() => {
      this.visible = value;
    }, delay);
  }
}
