import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClearFormService {
  public clearForm$: Observable<string>;
  private readonly clearFormSubject = new Subject<string>();

  constructor() {
    this.clearForm$ = this.clearFormSubject.asObservable();
  }

  public clearForm(value?: string): void {
    this.clearFormSubject.next(value);
  }
}
