import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DialogModule, LoadingBlockModule } from '@common/components';
import { NgbRatingModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';
import { SessionsCountComponent } from './sessions-count/sessions-count.component';
import { SessionsMapComponent } from './sessions-map/sessions-map.component';
import { SessionsPopupComponent } from './sessions-popup/sessions-popup.component';
import { SessionsComponent } from './sessions.component';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    LeafletModule,
    LoadingBlockModule,
    NgbRatingModule,
    NgbTooltipModule,
    PaginationModule,
    RouterModule,
    TableNoMatchesModule,
    TranslateModule,
  ],
  declarations: [SessionsComponent, SessionsCountComponent, SessionsMapComponent, SessionsPopupComponent],
  exports: [SessionsComponent],
})
export class SessionsModule {}
