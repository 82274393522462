export const LOG_TYPE_DIFF_MSG_TRANSLATE = {
  'appointmentAddress.city': 'APPOINTMENT_ADDRESS_CITY',
  'appointmentAddress.description': 'APPOINTMENT_ADDRESS_DESCRIPTION',
  'appointmentAddress.number': 'APPOINTMENT_ADDRESS_NUMBER',
  'appointmentAddress.postalCode': 'APPOINTMENT_ADDRESS_POSTAL_CODE',
  'appointmentAddress.state': 'APPOINTMENT_ADDRESS_STATE',
  'appointmentAddress.street': 'APPOINTMENT_ADDRESS_STREET',
  'customer.civility': 'CUSTOMER_CIVILITY',
  'customer.confirmationMode': 'CUSTOMER_CONFIRMATION_MODE',
  'customer.email': 'EMAIL',
  'customer.lastName': 'LAST_NAME',
  'customer.name': 'FIRST_NAME',
  'customer.phoneNumber': 'PHONE_NUMBER',
  'customer.subscriberCode': 'SUBSCRIBER_CODE',
  'destinationAddress.city': 'DESTINATION_ADDRESS_CITY',
  'destinationAddress.description': 'DESTINATION_ADDRESS_DESCRIPTION',
  'destinationAddress.number': 'DESTINATION_ADDRESS_NUMBER',
  'destinationAddress.postalCode': 'DESTINATION_ADDRESS_POSTAL_CODE',
  'destinationAddress.state': 'DESTINATION_ADDRESS_STATE',
  'destinationAddress.street': 'DESTINATION_ADDRESS_STREET',
  anonymousPassenger: 'ANONYMOUS_PASSENGER',
  appointmentAddressComment: 'APPOINTMENT_ADDRESS_COMMENT',
  comment: 'COMMENT',
  customerId: 'CUSTOMER',
  date: 'DATE',
  destinationAddressComment: 'DESTINATION_ADDRESS_COMMENT',
  driverId: 'PILOT',
  fleetId: 'FLEET',
  passengers: 'PASSENGERS',
  paymentType: 'PAYMENT_MODE',
  price: 'PRICE',
  privateOrder: 'PRIVATE_ORDER',
  status: 'STATUS',
  statusDetails: 'STATUS_DETAILS',
  tags: 'TAGS',
  time: 'CORE_TIME',
  vehicleSize: 'VEHICLE_SIZE',
  vehicleType: 'VEHICLE_TYPE',
};

export const LOG_TYPE_DIFF_MSG_FIELD = {
  'appointmentAddress.city': 'appointmentAddress.city',
  'appointmentAddress.description': 'appointmentAddress.description',
  'appointmentAddress.number': 'appointmentAddress.number',
  'appointmentAddress.postalCode': 'appointmentAddress.postalCode',
  'appointmentAddress.state': 'appointmentAddress.state',
  'appointmentAddress.street': 'appointmentAddress.street',
  'customer.civility': 'customer.civility',
  'customer.confirmationMode': 'customer.confirmationMode',
  'customer.email': 'customer.email',
  'customer.lastName': 'customer.lastName',
  'customer.name': 'customer.name',
  'customer.phoneNumber': 'customer.phoneNumber',
  'customer.subscriberCode': 'customer.subscriberCode',
  'destinationAddress.city': 'destinationAddress.city',
  'destinationAddress.description': 'destinationAddress.description',
  'destinationAddress.number': 'destinationAddress.number',
  'destinationAddress.postalCode': 'destinationAddress.postalCode',
  'destinationAddress.state': 'destinationAddress.state',
  'destinationAddress.street': 'destinationAddress.street',
  anonymousPassenger: 'anonymousPassenger',
  appointmentAddressComment: 'appointmentAddressComment',
  billingStatus: 'billingStatus',
  comment: 'comment',
  customerId: 'customerId',
  date: 'date',
  destinationAddressComment: 'destinationAddressComment',
  driverId: 'driverId',
  fleetId: 'fleetId',
  passengers: 'passengers',
  paymentType: 'paymentType',
  price: 'price',
  privateOrder: 'privateOrder',
  status: 'status',
  statusDetails: 'statusDetails',
  tags: 'tags',
  time: 'time',
  vehicleSize: 'vehicleSize',
  vehicleType: 'vehicleType',
};
