<section class="pragmatic azz-details-page create-poi">
  <azz-loading-block [isLoading]="poiDataLoadingIndicator">
    <div class="azz-info-blocks-row azz-last">
      <div class="azz-info-block">
        <div class="row">
          <div class="col-12">
            <div class="azz-nav-block">
              <img src="assets/images/return-arrow.png" class="azz-back-btn" (click)="goBack()" alt="back" />
              <div class="azz-back-title">{{ 'GO_BACK' | translate }}</div>
            </div>
          </div>
        </div>
        <form class="row" [formGroup]="form" #formElemRef>
          <div class="col-lg-10 azz-control-group">
            <div class="row">
              <div
                class="col-lg-6 form-group"
                [ngClass]="{
                  'has-error': form.controls.label?.errors && form.controls.label.touched
                }"
              >
                <label for="poi-label" class="azz-transparent-input-label">{{ 'POI_LABEL' | translate }}*</label>
                <input
                  class="form-control azz-transparent-input azz-validate"
                  id="poi-label"
                  type="text"
                  formControlName="label"
                  name="poi_label"
                />
                <div
                  class="azz-validation-msg"
                  [ngClass]="{
                    active: form.controls.label.touched && form.controls?.label?.errors?.required
                  }"
                >
                  {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <label for="poi-type" class="azz-transparent-input-label">{{ 'TYPE_LABEL' | translate }}*</label>
                <select
                  id="poi-type"
                  name="poi_type"
                  class="form-control azz-transparent-input azz-validate azz-expandable"
                  formControlName="type"
                >
                  <option *ngFor="let item of poiTypes" [value]="item.id">
                    {{ 'POI_TYPE_' + item.id | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 form-group">
                <label for="poi-service-level" class="azz-transparent-input-label">
                  {{ 'SERVICE_LEVEL' | translate }}*
                </label>
                <select
                  id="poi-service-level"
                  name="poi_service_level"
                  class="form-control azz-transparent-input azz-validate azz-expandable"
                  formControlName="serviceLevel"
                >
                  <option *ngFor="let item of poiServiceLevels" [value]="item.value">
                    {{ 'POI_SERVICE_LEVEL_' + item.title | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 form-group">
                <label [for]="addressAutocompleteId" class="azz-transparent-input-label">
                  {{ 'FIND_GPS_POSITION_BY_STREET_NAME' | translate }}*
                </label>
                <azz-poi-address-autocomplete
                  [address]="address"
                  [ngId]="addressAutocompleteId"
                  [markerDragCoordsStr]="markerDragCoordsStr"
                  (setAddress)="onSetAddress()"
                >
                </azz-poi-address-autocomplete>
                <div class="create-poi__coords" *ngIf="markerDragCoordsStr">
                  <span class="create-poi__coords-label">{{ 'COORDINATES' | translate }}:</span>
                  <span>{{ markerDragCoordsStr }}</span>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <label [for]="poiCityId" class="azz-transparent-input-label">{{ 'CITY' | translate }}*</label>
                <azz-poi-city-autocomplete [ngId]="poiCityId"></azz-poi-city-autocomplete>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <azz-map-create-point-of-interest
                  [markerLatLngArr]="markerLatLngArr"
                  [selectedServiceLevel]="form.get('serviceLevel').value"
                  (dragMark)="onMarkerDrag($event)"
                  (createMark)="onMarkerCreate($event)"
                  (deleteMark)="onMarkerDelete()"
                >
                </azz-map-create-point-of-interest>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 form-group">
                <label for="description-link" class="azz-transparent-input-label">
                  {{ 'DESCRIPTION_LINK' | translate }}
                </label>
                <input
                  class="form-control azz-transparent-input azz-validate"
                  id="description-link"
                  type="text"
                  formControlName="descriptionLink"
                  name="description_link"
                />
              </div>
              <div class="col-lg-6 form-group">
                <label for="description" class="azz-transparent-input-label">{{ 'DESCRIPTION' | translate }}</label>
                <input
                  class="form-control azz-transparent-input azz-validate"
                  id="description"
                  type="text"
                  formControlName="description"
                  name="description"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 form-group">
                <div class="azz-transparent-input-label">{{ 'POI_DISPLAY_IN' | translate }}*</div>
                <div class="create-poi__checkbox-group">
                  <div
                    class="create-poi__checkbox-item"
                    [ngClass]="{
                      'has-error': form.controls.displayInDriverApp.invalid && form.controls.displayInDriverApp.touched
                    }"
                  >
                    <input
                      id="display-in-driver-app"
                      type="checkbox"
                      formControlName="displayInDriverApp"
                      name="display_in_driver_app"
                    />
                    <label for="display-in-driver-app">{{ 'POI_DRIVER_APP' | translate }}</label>
                  </div>
                  <div
                    class="create-poi__checkbox-item"
                    [ngClass]="{
                      'has-error': form.controls.displayInDriverApp.invalid && form.controls.displayInDriverApp.touched
                    }"
                  >
                    <input
                      id="display-in-crc-service"
                      type="checkbox"
                      formControlName="displayInCrcService"
                      name="display_in_crc_service"
                    />
                    <label for="display-in-crc-service">{{ 'POI_CRC_SERVICE' | translate }}</label>
                  </div>
                  <div
                    class="create-poi__checkbox-item"
                    [ngClass]="{
                      'has-error': form.controls.displayInDriverApp.invalid && form.controls.displayInDriverApp.touched
                    }"
                  >
                    <input
                      id="display-in-passenger-app"
                      type="checkbox"
                      formControlName="displayInPassengerApp"
                      name="display_in_passenger_app"
                    />
                    <label for="display-in-passenger-app">{{ 'POI_PASSENGER_APP' | translate }}</label>
                  </div>
                  <div
                    class="create-poi__checkbox-item"
                    [ngbTooltip]="displayInBillingPortalControl.disabled ? billingPortalTooltip : ''"
                    [ngClass]="{
                      'has-error': form.controls.displayInDriverApp.invalid && form.controls.displayInDriverApp.touched
                    }"
                  >
                    <input
                      id="display-in-billing-portal"
                      type="checkbox"
                      formControlName="displayInBillingPortal"
                      name="display_in_billing_portal"
                    />
                    <label for="display-in-billing-portal">{{ 'POI_BILLING_PORTAL' | translate }}</label>
                    <ng-template #billingPortalTooltip>
                      <span style="width: 100px; height: 100px">{{
                        'POI_BILLING_PORTAL_DISABLED_TOOLTIP' | translate
                      }}</span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <div class="azz-transparent-input-label">{{ 'POI_ADD_FIELD' | translate }}</div>
                <div class="create-poi__checkbox-group">
                  <div class="create-poi__checkbox-item">
                    <input id="flight-id" type="checkbox" formControlName="addFieldFlightId" name="flight_id" />
                    <label for="flight-id">{{ 'POI_FLIGHT_ID' | translate }}</label>
                  </div>
                  <div class="create-poi__checkbox-item">
                    <input id="train-id" type="checkbox" formControlName="addFieldTrainId" name="train_id" />
                    <label for="train-id">{{ 'POI_TRAIN_ID' | translate }}</label>
                  </div>
                  <div class="create-poi__checkbox-item">
                    <input id="room-id" type="checkbox" formControlName="addFieldRoomId" name="room_id" />
                    <label for="room-id">{{ 'POI_ROOM_ID' | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 azz-header">{{ 'POI_PASSENGER_ARRIVAL' | translate }}</div>
              <div class="col-lg-6 form-group">
                <label class="azz-transparent-input-label d-flex align-items-center" for="enter-price-attempt">
                  <span>{{ 'POI_ADDITIONAL_DURATION' | translate }}</span>
                  <span class="azz-transparent-input-label tooltip-box tooltip-box-without-padding question-icon">
                    <span class="custom-tooltip">
                      {{ 'POI_TIME_TO_REACH_POINT_TOOLTIP' | translate }}
                    </span>
                  </span>
                </label>
                <div class="d-flex align-items-center">
                  <input
                    class="form-control azz-transparent-input azz-validate create-poi__estimate-input"
                    id="enter-price-attempt"
                    type="number"
                    formControlName="additionalDurationEstimateTime"
                    name="enter_price_attempt"
                  />
                  <span>{{ 'MINUTES_SHORT_lABEL' | translate }}</span>
                </div>
                <div
                  class="form-control__error"
                  *ngIf="
                    form.controls.additionalDurationEstimateTime?.errors &&
                    form.controls.additionalDurationEstimateTime?.touched
                  "
                >
                  <small
                    *ngIf="
                      form.controls.additionalDurationEstimateTime?.errors?.min ||
                      form.controls.additionalDurationEstimateTime?.errors?.max
                    "
                  >
                    {{
                      'MIN_MAX_VALUE_ERROR' | translate: { min: minDurationEstimateTime, max: maxDurationEstimateTime }
                    }}
                  </small>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <div class="azz-transparent-input-label d-flex">
                  {{ 'POI_WELCOME_SIGN_WITH_NAME' | translate }}
                </div>
                <!--!!!d-flex!!! is used to align with the opposite line-->
                <div class="create-poi__radio-group">
                  <div class="create-poi__radio-item">
                    <label class="custom-radio">
                      <input
                        id="welcome-sign-true"
                        formControlName="welcomeSignWithName"
                        type="radio"
                        name="welcomeSignWithName"
                        [value]="true"
                      />
                      <span class="checkmark"></span>
                      {{ 'YES' | translate }}
                    </label>
                  </div>
                  <div class="create-poi__radio-item">
                    <label class="custom-radio">
                      <input
                        id="welcome-sign-false"
                        formControlName="welcomeSignWithName"
                        type="radio"
                        name="welcomeSignWithName"
                        [value]="false"
                      />
                      <span class="checkmark"></span>
                      {{ 'NO' | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 form-group">
                <label for="description-for-passenger-app" class="azz-transparent-input-label">
                  {{ 'POI_DESCRIPTION_FOR_PASSENGER_APP' | translate }}
                </label>
                <input
                  class="form-control azz-transparent-input azz-validate"
                  id="description-for-passenger-app"
                  type="text"
                  formControlName="descriptionForPassengerApp"
                  name="description_for_passenger_app"
                />
              </div>
              <div class="col-lg-6 form-group">
                <label for="description-link-sms-email" class="azz-transparent-input-label">
                  {{ 'POI_DESCRIPTION_LINK_SMS_MAIL' | translate }}
                </label>
                <input
                  class="form-control azz-transparent-input azz-validate"
                  id="description-link-sms-email"
                  type="text"
                  formControlName="descriptionLinkSmsOrEmail"
                  name="description_link_sms_email"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-10 create-poi__buttons-group">
            <button
              type="submit"
              (click)="submit()"
              name="submit"
              class="btn btn-rotate-flex azz-bg-green create-poi__submit"
              [disabled]="loadingIndicator"
            >
              <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
              {{ (isEditMode ? 'SAVE' : 'CREATE') | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </azz-loading-block>
</section>

<azz-change-poi-error-dialog></azz-change-poi-error-dialog>
