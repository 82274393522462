import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';

import { IConfirmPopupData } from '../../models/common.model';

@Injectable({ providedIn: 'root' })
export class CustomPopupService {
  constructor(private readonly dialog: NgbModal) {}

  public openConfirmPopup(
    component: any,
    data: Partial<IConfirmPopupData>,
    options: NgbModalOptions = { ariaLabelledBy: 'modal-basic-title' }
  ): NgbModalRef {
    const ref = this.dialog.open(component, options);
    this.addPopupData(ref, data);
    return ref;
  }

  public openPopup(component: any, data?: any, options?: NgbModalOptions): NgbModalRef {
    const ref = this.dialog.open(component, options || { ariaLabelledBy: 'modal-basic-title' });
    this.addPopupData(ref, data);
    return ref;
  }

  private addPopupData(ref: NgbModalRef, data: Partial<IConfirmPopupData> = {}): void {
    Object.keys(data).forEach(key => {
      ref.componentInstance[key] = data[key];
    });
  }
}
