import { IShortError } from '@core/models/common.model';
import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from '../actions';

export const forgotPasswordFeatureKey = 'forgot-password';

export interface State {
  loading: boolean;
  error: IShortError;
  email: string;
}

const initialState: State = {
  loading: false,
  error: null,
  email: '',
};

const forgotPasswordReducer = createReducer(
  initialState,
  on(AuthActions.forgotPassword, (state, { params }) => ({
    ...state,
    loading: true,
    email: params.email,
    error: null,
  })),
  on(AuthActions.forgotPasswordSuccess, state => ({ ...state, loading: false })),
  on(AuthActions.forgotPasswordError, (state, { error }) => ({ ...state, error, loading: false }))
);

export const reducer = (state: State | undefined, action: Action): State => forgotPasswordReducer(state, action);
