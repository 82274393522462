<div class="details__warning">
  <div>{{ 'FD_NEW_DRIVER_MSG' | translate }}</div>
  <div>{{ 'CAN_NOT_VALIDATE_DRIVER_WITH_DATA_FILLING' | translate }}</div>
</div>
<ul class="details__confirm-list">
  <li>
    <span>{{ 'PERSONAL_INFO' | translate }}: </span>
    <span class="details__confirmed">{{ 'FILLED' | translate }}</span>
  </li>
  <li>
    <span>{{ 'TAXI_PARKING_PERMIT' | translate }}: </span>
    <span [ngClass]="transportLicense && driverLicense ? 'details__confirmed' : 'details__not-confirmed'">
      {{ (transportLicense && driverLicense ? 'FILLED' : 'NOT_FILLED') | translate }}
    </span>
  </li>
  <li>
    <span>{{ 'EMAIl' | translate }}: </span>
    <span [ngClass]="driver.emailConfirmed ? 'details__confirmed' : 'details__not-confirmed'">
      {{ (driver.emailConfirmed ? 'CONFIRMED_M' : 'NOT_CONFIRMED_M') | translate }}
    </span>
  </li>
</ul>
<button
  class="custom-btn custom-btn--green btn-rotate-flex btn-rotate-flex--w-auto"
  [disabled]="!transportLicense || !driverLicense || !driver.emailConfirmed || btnLoading"
  (click)="openValidatePopup()"
>
  <azz-rotate-indicator7 [visible]="btnLoading"></azz-rotate-indicator7>
  {{ 'FD_VALIDATE_BUTTON' | translate }}
</button>
