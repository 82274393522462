import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IOrder } from '../../../../models/order.model';
import { PhoneAdvisorOrder } from '../../../../models/phone-advisor-order';

@Component({
  selector: 'azz-custom-order-passenger-info',
  templateUrl: './passenger-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerInfoComponent {
  @Input() order: IOrder | PhoneAdvisorOrder;
}
