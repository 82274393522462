import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IToastData } from '@common/models/IToastData';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsService } from '@services/alerts.service';
import { ToastModule } from 'primeng/toast';

import { CrossButtonComponent } from '../../../../modules/shared/controls/buttons/cross-button/cross-button.component';

@Component({
  selector: 'azz-toasts',
  standalone: true,
  imports: [ToastModule, NgIf, RouterLink, TranslateModule, CrossButtonComponent],
  templateUrl: './toasts.component.html',
  styleUrl: './toasts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastsComponent {
  private readonly alertsService = inject(AlertsService);

  protected readonly alertsKey = this.alertsService.key;

  protected asToastData(data: unknown): IToastData | null {
    if (!data) {
      return null;
    }

    return data as IToastData;
  }
}
