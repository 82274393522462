import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { LabeledInputComponent } from './labeled-input.component';

@NgModule({
  declarations: [LabeledInputComponent],
  imports: [ReactiveFormsModule, TranslateModule],
  exports: [LabeledInputComponent],
})
export class LabeledInputModule {}
