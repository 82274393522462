/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DriverAvailabilityEnumWebApiModel } from './driver-availability-enum-web.api-model';
import { PointResponseWebApiModel } from './point-response-web.api-model';
import { DriverSessionStatusEnumWebApiModel } from './driver-session-status-enum-web.api-model';
import { RouteEstimationResponseWebApiModel } from './route-estimation-response-web.api-model';

/**
 * Info about driver availability status
 */
export interface AvailableDriverItemResponseWebApiModel {
  /**
   * driver ID
   */
  driverId: string;
  /**
   * fleet ID
   */
  fleetId: string;
  /**
   * driver first name
   */
  firstName: string;
  /**
   * driver last name
   */
  lastName: string;
  /**
   * taxi number
   */
  taxiNumber: string;
  sessionStatus: DriverSessionStatusEnumWebApiModel;
  availability: DriverAvailabilityEnumWebApiModel;
  coordinates?: PointResponseWebApiModel;
  estimation?: RouteEstimationResponseWebApiModel;
}
export namespace AvailableDriverItemResponseWebApiModel {}
