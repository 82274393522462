<section>
  <div class="azz-header">{{ 'PA_DETAILS_CUSTOMERS_INFO' | translate }}</div>
  <div class="row">
    <div class="col-12">
      <label class="azz-transparent-input-label">{{ 'FR_SELECT_STATUS_LABEL' | translate }}</label>
      <div>{{ 'PA_CUSTOMERS_STATUS_' + customer?.status | translate }}</div>
    </div>
    <div class="col-12">
      <label class="azz-transparent-input-label">{{ 'CLIENT' | translate }}</label>
      <azz-driver-circle7
        [navigateUrl]="urlToCustomerDetailsPage"
        [driver]="{ lastName: customer?.lastName, firstName: customer?.name, id: customer?.id }"
      >
      </azz-driver-circle7>
    </div>
    <div class="col-12">
      <label class="azz-transparent-input-label">{{ 'CUSTOMER_PHONE' | translate }}</label>
      <div class="row-phone">
        <div>{{ order?.customer?.phoneNumber }}</div>
        <div class="phone-icon" style="margin-left: 65px">
          <azz-phone-call-button
            *ngIf="phoneCallButtonAvailable"
            [phoneNumber]="order?.customer?.phoneNumber"
            [link]="customerPhoneCallLink"
            class="phone-call-button"
          ></azz-phone-call-button>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="order?.customer?.email">
      <label class="azz-transparent-input-label">{{ 'CUSTOMER_EMAIL' | translate }}</label>
      <div>{{ order?.customer?.email }}</div>
    </div>
  </div>
</section>
