import { RouteUrls } from '@core/constant/route-urls';

import { PBX_CONNECTION_STATE } from './cti.constant';

export const PA_COMPETENCY_LEVEL = {
  EXPERT: 'EXPERT',
  CONFIRMED: 'CONFIRMED',
  NEW: 'NEW',
} as const;

export type PACompetencyLevelName = (typeof PA_COMPETENCY_LEVEL)[keyof typeof PA_COMPETENCY_LEVEL];

export enum PA_COMPETENCE_LEVEL_FILL {
  EXPERT = '#fdbb30',
  CONFIRMED = '#dddddd',
  NEW = '#ffb1d7',
}

export const PA_PBX_CONNECTION_FILL = {
  [PBX_CONNECTION_STATE.ACTIVE]: '#4CAF50',
  [PBX_CONNECTION_STATE.TRYING]: '#fdbb30',
  [PBX_CONNECTION_STATE.LOST]: '#fdbb30',
  [PBX_CONNECTION_STATE.NOT_ACTIVE]: '#fdbb30',
};

export const PA_NAV_TAB = {
  DRIVER_SESSIONS: 'DRIVER_SESSIONS',
  NEWS: 'NEWS',
  CHAT: 'CHAT',
  CLIENTS: 'CLIENTS',
  CREATE_ORDER: 'CREATE_ORDER',
  CREATE_ORDER_WITH_CTI: 'CREATE_ORDER_WITH_CTI',
  EXCEPTIONS: 'EXCEPTIONS',
  ORDERS: 'ORDERS',
  DIAL: 'DIAL',
  POI: 'POI',
  PROFILE: 'PROFILE',
} as const;

export type PANavTabName = (typeof PA_NAV_TAB)[keyof typeof PA_NAV_TAB];

const {
  dash: { phoneAdvisor },
} = RouteUrls;
export const PA_ROUTE = {
  DRIVER_SESSIONS: `${phoneAdvisor}/sessions`,
  NEWS: `${phoneAdvisor}/news`,
  CHAT: `${phoneAdvisor}/chat`,
  CLIENTS: `${phoneAdvisor}/customers`,
  CREATE_ORDER: `${phoneAdvisor}/create-order`,
  CREATE_ORDER_WITH_CTI: `${phoneAdvisor}/create-order-with-cti`,
  EXCEPTIONS: `${phoneAdvisor}/exceptions`,
  ORDERS: `${phoneAdvisor}/orders`,
  DIAL: `${phoneAdvisor}/dial`,
  POI: `${phoneAdvisor}/poi`,
  PROFILE: `${phoneAdvisor}/profile`,
  DRIVERS: `${phoneAdvisor}/drivers`,
};

export const PA_COMPETENCE_LEVEL_NAV_TAB_MAP: {
  [key in PACompetencyLevelName]: PANavTabName[];
} = {
  [PA_COMPETENCY_LEVEL.EXPERT]: [
    PA_NAV_TAB.DRIVER_SESSIONS,
    PA_NAV_TAB.NEWS,
    PA_NAV_TAB.CHAT,
    PA_NAV_TAB.CLIENTS,
    PA_NAV_TAB.CREATE_ORDER,
    PA_NAV_TAB.CREATE_ORDER_WITH_CTI,
    PA_NAV_TAB.EXCEPTIONS,
    PA_NAV_TAB.ORDERS,
    PA_NAV_TAB.POI,
    PA_NAV_TAB.DIAL,
    PA_NAV_TAB.PROFILE,
  ],
  [PA_COMPETENCY_LEVEL.CONFIRMED]: [
    PA_NAV_TAB.DRIVER_SESSIONS,
    PA_NAV_TAB.NEWS,
    PA_NAV_TAB.CLIENTS,
    PA_NAV_TAB.CREATE_ORDER,
    PA_NAV_TAB.CREATE_ORDER_WITH_CTI,
    PA_NAV_TAB.EXCEPTIONS,
    PA_NAV_TAB.ORDERS,
    PA_NAV_TAB.POI,
    PA_NAV_TAB.DIAL,
    PA_NAV_TAB.PROFILE,
  ],
  [PA_COMPETENCY_LEVEL.NEW]: [
    PA_NAV_TAB.CLIENTS,
    PA_NAV_TAB.CREATE_ORDER,
    PA_NAV_TAB.CREATE_ORDER_WITH_CTI,
    PA_NAV_TAB.ORDERS,
    PA_NAV_TAB.PROFILE,
  ],
};

export const PA_COMPETENCE_LEVEL_DEFAULT_ROUTE_MAP = {
  [PA_COMPETENCY_LEVEL.EXPERT]: PA_ROUTE.CREATE_ORDER,
  [PA_COMPETENCY_LEVEL.CONFIRMED]: PA_ROUTE.ORDERS,
  [PA_COMPETENCY_LEVEL.NEW]: PA_ROUTE.ORDERS,
};
