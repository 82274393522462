<form class="row align-items-end" [formGroup]="form">
  <div class="form-group col-lg-2">
    <label for="any-param" class="azz-transparent-input-label">
      {{ 'FIRST_NAME' | translate }}, {{ 'LAST_NAME' | translate | lowercase }}
    </label>
    <input
      id="any-param"
      name="any_param"
      type="text"
      class="form-control azz-transparent-input azz-search-field"
      formControlName="anyParam"
    />
  </div>
  <div class="form-group col-lg-2">
    <label for="phone-number" class="azz-transparent-input-label">{{ 'PHONE_NUMBER' | translate }}</label>
    <input
      id="phone-number"
      name="phone_number"
      type="text"
      class="form-control azz-transparent-input azz-search-field"
      formControlName="phoneNumber"
    />
  </div>
  <div class="form-group col-lg-2">
    <label for="subscriber-code" class="azz-transparent-input-label">{{ 'SUBSCRIBER_CODE' | translate }}</label>
    <input
      id="subscriber-code"
      name="subscriber_code"
      type="text"
      class="form-control azz-transparent-input azz-search-field"
      formControlName="subscriberCode"
    />
  </div>

  <div class="form-group col-lg-2">
    <label for="status" class="azz-transparent-input-label">{{ 'STATUS' | translate }}</label>
    <select
      id="status"
      name="status"
      class="form-control azz-transparent-input azz-expandable"
      formControlName="status"
    >
      <option [ngValue]="null">{{ 'ALL' | translate }}</option>
      <option *ngFor="let status of statuses" [value]="status">
        {{ 'PA_CUSTOMERS_STATUS_' + status | translate }}
      </option>
    </select>
  </div>
  <div class="form-group col-auto ms-lg-auto">
    <button type="button" class="custom-btn custom-btn--orange" routerLink="create">
      {{ 'PA_CUSTOMERS_CREATE_CUSTOMER' | translate }}
    </button>
  </div>
</form>
