import { AuthStore } from '@auth/store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as RegistrationReducer from '@registration/phone-advisor/store/reducers';

import * as CoffeeBreakReducer from './coffee-break.reducer';
import * as DeactivatedReducer from './deactivated.reducer';
import * as ProfileReducer from '../../modules-old/profile-page/store/reducers';

export const phoneAdvisorFeatureKey = 'phone-advisor';

export interface PhoneAdvisorState {
  [CoffeeBreakReducer.coffeeBreakFeatureKey]: CoffeeBreakReducer.State;
  [DeactivatedReducer.deactivatedFeatureKey]: DeactivatedReducer.State;
  [ProfileReducer.phoneAdvisorProfileFeatureKey]: ProfileReducer.State;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  [RegistrationReducer.registrationFeatureKey]: RegistrationReducer.State;
}

export const reducers: ActionReducerMap<PhoneAdvisorState> = {
  [CoffeeBreakReducer.coffeeBreakFeatureKey]: CoffeeBreakReducer.reducer,
  [DeactivatedReducer.deactivatedFeatureKey]: DeactivatedReducer.reducer,
  [ProfileReducer.phoneAdvisorProfileFeatureKey]: ProfileReducer.reducer,
  [RegistrationReducer.registrationFeatureKey]: RegistrationReducer.reducer,
};

export const clearState =
  (reducer: ActionReducer<PhoneAdvisorState>): ActionReducer<PhoneAdvisorState> =>
  (state, action) => {
    if (action?.type === AuthStore.logoutSuccess.type) {
      return reducer(undefined, action);
    }

    return reducer(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
