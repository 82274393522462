<div class="row">
  <div class="col-md-6">
    <div class="header-group">
      <h1>{{ 'THANK_YOU' | translate }}</h1>
    </div>
    <div>
      <div>{{ 'FARD_CONFIRM_EMAIL_CONFIRMED_MESSAGE' | translate }}</div>
      <div>{{ 'FARD_CONFIRM_START_USING_MESSAGE' | translate }}</div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <input
          routerLink="/welcome"
          type="submit"
          name="submit"
          [value]="'FARD_CONFIRM_LOG_IN_VALUE' | translate"
          class="btn btn-block azz-primary-btn"
        />
      </div>
    </div>
  </div>
</div>
