import { createAction, props } from '@ngrx/store';

import { IColor, IEnergy, IMake, IModel } from '../../../../models/vehicle.model';

export const init = createAction('[Vehicle Info] Init');
export const destroy = createAction('[Vehicle Info] Destroy');
export const getMakes = createAction('[Vehicle Info] Get Makes');
export const getMakesSuccess = createAction('[Vehicle Info] Get Makes Success', props<{ makes: IMake[] }>());
export const getMakesError = createAction('[Vehicle Info] Get Makes Error');
export const getModels = createAction('[Vehicle Info] Get Models', props<{ makeId: string }>());
export const getModelsSuccess = createAction('[Vehicle Info] Get Models Success', props<{ models: IModel[] }>());
export const getModelsError = createAction('[Vehicle Info] Get Models Error');
export const getEnergies = createAction('[Vehicle Info] Get Energies');
export const getEnergiesSuccess = createAction('[Vehicle Info] Get Energies Success', props<{ energies: IEnergy[] }>());
export const getEnergiesError = createAction('[Vehicle Info] Get Energies Error');
export const getColors = createAction('[Vehicle Info] Get Colors');
export const getColorsSuccess = createAction('[Vehicle Info] Get Colors Success', props<{ colors: IColor[] }>());
export const getColorsError = createAction('[Vehicle Info] Get Colors Error');
