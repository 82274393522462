import { Component } from '@angular/core';
import { NgOnDestroyService } from '@common/services';

@Component({
  selector: 'azz-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.less',
  providers: [NgOnDestroyService],
})
export class AuthComponent {}
