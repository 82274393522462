import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TAG_TYPES } from '@core/constant/tag.constant';

import { ITag } from '../../../../../models/tag.model';

@Component({
  selector: 'azz-prev-order-tags',
  templateUrl: './prev-order-tags.component.html',
  styleUrls: ['./prev-order-tags.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrevOrderTagsComponent {
  public tags: string[] = [];

  @Input() set allTags(allTags: ITag[]) {
    for (const tag of allTags) {
      if (![...TAG_TYPES.service, ...TAG_TYPES.vehicle, ...TAG_TYPES.payment].includes(tag.id)) {
        this.tags.push(tag.id);
      }
    }
  }
}
