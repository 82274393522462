export const getPassengersOrderDTO = ({ customerName, customerLastName, passengerName }: GetPassengersParams) => {
  const customerExists = !customerName && !customerLastName;
  if (!customerExists && !passengerName) {
    return '';
  }

  if (passengerName) {
    return passengerName;
  }

  return customerName ? `${customerName} ${customerLastName}` : customerLastName;
};

interface GetPassengersParams {
  customerName: string;
  customerLastName: string;
  passengerName: string;
}
