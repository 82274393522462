<ng-template #content let-modal>
  <div class="azz-popup dialog">
    <div class="dialog__header" [innerHTML]="header | translate: { poiLabel: poi?.label }"></div>

    <div class="azz-info-block">
      <ul class="dialog__list">
        <li class="dialog__item" *ngIf="!!fleets && !!fleets?.length">
          {{ 'POI_CANNOT_CHANGE_HAS_ORDER' | translate }}
          <ul class="dialog__fleets-list">
            <li *ngFor="let fleet of fleets">{{ fleet?.name }}</li>
          </ul>
        </li>
        <li class="dialog__item" *ngIf="hasDisplayInValue">
          {{ 'POI_CANNOT_DELETE_HAS_DISPLAY_IN_PROP' | translate }}
        </li>
      </ul>
    </div>

    <div class="azz-toolbar message-dialog-toolbar">
      <button type="button" class="btn btn-link message-dialog-primary-btn" (click)="onClose()">
        {{ 'CORE_OK_VALUE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
