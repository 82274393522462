import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TAG_TYPES } from '@core/constant/tag.constant';
import { includes } from 'lodash-es';

import { ITag } from '../../../../../models/tag.model';

@Component({
  selector: 'azz-prev-order-payment-type',
  templateUrl: './prev-order-payment-type.component.html',
  styleUrls: ['./prev-order-payment-type.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrevOrderPaymentTypeComponent {
  public paymentTypeId: string;
  @Input() set allTags(allTags: ITag[]) {
    for (const tag of allTags) {
      if (includes(TAG_TYPES.payment, tag.id)) {
        this.paymentTypeId = tag.id;
        break;
      }
    }
  }
}
