<form name="form" [formGroup]="form" #formElemRef>
  <div class="row">
    <div class="col-lg-6 form-group">
      <label for="make" class="azz-transparent-input-label">{{ 'DRIVER_PARAMS_MAKE_LABEL' | translate }}*</label>
      <select
        id="make"
        name="make"
        formControlName="make"
        [compareWith]="compareFn"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        (change)="changeModel()"
        required
      >
        <option *ngFor="let make of makes$ | async; trackBy: trackByFn" [ngValue]="make">
          {{ make.name }}
        </option>
      </select>
      <div *ngIf="form.controls?.make?.errors && form.controls?.make?.touched" class="form-control__error">
        <small *ngIf="form.controls?.make?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
      </div>
    </div>
    <div class="col-lg-6 form-group">
      <label for="model" class="azz-transparent-input-label">{{ 'DRIVER_PARAMS_MODEL_LABEL' | translate }}*</label>
      <select
        id="model"
        name="model"
        formControlName="model"
        [compareWith]="compareFn"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        required
      >
        <option *ngFor="let model of models$ | async; trackBy: trackByFn" [ngValue]="model">
          {{ model.name }}
        </option>
      </select>
      <div *ngIf="form.controls?.model?.errors && form.controls?.model?.touched" class="form-control__error">
        <small *ngIf="form.controls?.model?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
      </div>
    </div>
  </div>
  <app-vehicle-info-service-vehicle-types
    [form]="form"
    [serviceTypes]="serviceTypes$ | async"
    [vehicleTypes]="vehicleTypes$ | async"
    [driverVehicleTypeTagsIds]="driverVehicleTypeTagsIds"
    [driverServiceTypeTagsIds]="driverServiceTypeTagsIds"
  >
  </app-vehicle-info-service-vehicle-types>
  <div class="row">
    <div class="col-lg-6 form-group">
      <label for="energy" class="azz-transparent-input-label"> {{ 'DRIVER_PARAMS_ENERGY_LABEL' | translate }}* </label>
      <select
        id="energy"
        name="energy"
        formControlName="energy"
        [compareWith]="compareFn"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        required
      >
        <option *ngFor="let energy of energies$ | async; trackBy: trackByFn" [ngValue]="energy">
          {{ energy.name }}
        </option>
      </select>
      <div *ngIf="form.controls?.energy?.errors && form.controls?.energy?.touched" class="form-control__error">
        <small *ngIf="form.controls?.energy?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 form-group">
      <label for="color" class="azz-transparent-input-label"> {{ 'DRIVER_PARAMS_COLOR_LABEL' | translate }}* </label>
      <select
        id="color"
        name="color"
        formControlName="color"
        [compareWith]="compareFn"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        required
      >
        <option *ngFor="let color of colors$ | async; trackBy: trackByFn" [ngValue]="color">
          {{ color?.name }}
        </option>
      </select>
      <div *ngIf="form.controls?.color?.errors && form.controls?.color?.touched" class="form-control__error">
        <small *ngIf="form.controls?.color?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
      </div>
    </div>
    <div class="col-lg-6 form-group">
      <label for="production-year" class="azz-transparent-input-label"
        >{{ 'DRIVER_PARAMS_YEAR_LABEL' | translate }}*
      </label>
      <select
        id="production-year"
        name="production_year"
        formControlName="productionYear"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        required
      >
        <option *ngFor="let year of productionYears$ | async" [value]="year">{{ year }}</option>
      </select>
      <div
        *ngIf="form.controls?.productionYear?.errors && form.controls?.productionYear?.touched"
        class="form-control__error"
      >
        <small *ngIf="form.controls?.productionYear?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 form-group">
      <label for="plate" class="azz-transparent-input-label">{{ 'DRIVER_PARAMS_PLATE_LABEL' | translate }}*</label>
      <input
        id="plate"
        name="plate"
        type="text"
        maxlength="15"
        formControlName="plate"
        class="form-control azz-transparent-input azz-validate"
        required
      />
      <div *ngIf="form.controls?.plate?.errors && form.controls?.plate?.touched" class="form-control__error">
        <small *ngIf="form.controls?.plate?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
        <small *ngIf="form.controls?.plate?.errors?.azzNotNumUpCaseFrChars">
          {{ 'NUMBER_AND_CAPITALS_ALLOWED' | translate }}
        </small>
      </div>
    </div>
    <div class="col-lg-6 form-group" *ngIf="taxiNumber && showTaxiNumber">
      <label for="taxi-number" class="azz-transparent-input-label">{{ 'TAXI_NUMBER' | translate }}</label>
      <input
        id="taxi-number"
        name="taxi_number"
        type="text"
        formControlName="taxiNumber"
        class="form-control azz-transparent-input azz-validate"
        maxlength="5"
      />
      <div *ngIf="form.controls?.taxiNumber?.errors && form.controls?.taxiNumber?.touched" class="form-control__error">
        <small *ngIf="form.controls?.taxiNumber?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
      </div>
    </div>
  </div>
</form>
