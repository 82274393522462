import { Pipe, PipeTransform } from '@angular/core';
import { round } from '@core/utils/util';

@Pipe({
  name: 'azzKilometers',
})
export class AzzKilometersPipe implements PipeTransform {
  transform(kilometers: number, ...args: any[]) {
    if (typeof kilometers !== 'number' || isNaN(kilometers)) {
      return '-';
    }

    if (!kilometers || kilometers < 1) {
      return '< 1 km';
    }

    const roundedKilometers = round(kilometers, 1);
    return `${roundedKilometers} km`;
  }
}
