import { Injectable } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { ORDER_LOG_ACTION_MODIFICATION } from '@core/constant/order-log.constant';
import { IOrderRedispatchLogAction } from '@core/models/order-logs.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OrderRedispatchLogService extends NgOnDestroyService {
  constructor(private readonly translate: TranslateService) {
    super();
  }

  public generateMsg(action: IOrderRedispatchLogAction): string {
    let result;
    switch (action.modificationType) {
      case ORDER_LOG_ACTION_MODIFICATION.REDISPATCH_ATTEMPT:
        result = this.generateRedispatchAttemptListMsg(action);
        break;
      case ORDER_LOG_ACTION_MODIFICATION.MAX_DISPATCH_ATTEMPTS_EXCEEDED:
        result = this.generateMaxDispatchAttemptsExceededMsg(action);
        break;
      default:
        result = action?.message;
        break;
    }
    return result;
  }

  //  REDISPATCH_ATTEMPT
  private generateRedispatchAttemptListMsg(action: IOrderRedispatchLogAction): string {
    const message = 'REDISPATCH_ATTEMPT';
    const redispatchCounter = action?.redispatchCounter;
    const header = `<div>${this.translate.instant(message, { redispatchCounter })}</div>`;
    return header;
  }

  //  MAX_DISPATCH_ATTEMPTS_EXCEEDED
  private generateMaxDispatchAttemptsExceededMsg(action: IOrderRedispatchLogAction): string {
    const message = 'MAX_DISPATCH_ATTEMPTS_EXCEEDED';
    const maxRedispatch = action?.maxRedispatch;
    const header = `<div>${this.translate.instant(message, { maxRedispatch })}</div>`;
    return header;
  }
}
