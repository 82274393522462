import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import { routerReducer, RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';

export const routerFeatureKey = 'router';

export interface CustomRouteInfo {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export type State = RouterReducerState<CustomRouteInfo>;

export const reducer = routerReducer;

export class CustomRouterStateSerializer implements RouterStateSerializer<CustomRouteInfo> {
  serialize(routerState: RouterStateSnapshot): CustomRouteInfo {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, r => r.params),
      queryParams: mergeRouteParams(routerState.root, r => r.queryParams),
      data: mergeRouteData(routerState.root),
    };
  }
}

const mergeRouteParams = (route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params => {
  if (!route) {
    return {};
  }

  const currentParams = getter(route);
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;
  return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
};

const mergeRouteData = (route: ActivatedRouteSnapshot): Data => {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;

  return { ...currentData, ...mergeRouteData(primaryChild) };
};
