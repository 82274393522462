import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IDriverUserInfo } from '../../../../models/user-info.model';

@Component({
  selector: 'azz-custom-order-driver-data',
  templateUrl: './driver-data.component.html',
  styleUrls: ['../customer-driver.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDataComponent {
  @Input() driver: IDriverUserInfo;
  @Input() urlToDriverDetailsPage: string;
  @Input() phoneCallButtonAvailable: boolean;
  public customerPhoneCallLink =
    'https://api-mediation.flexiblecontactcenter.orange-business.com/clickToCall?phoneNumber=%2B';

  constructor() {}
}
