import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IDriverUserInfo } from '../../../models/user-info.model';

@Component({
  selector: 'app-multi-dropdown-option',
  templateUrl: './multi-dropdown-option.component.html',
  styleUrls: ['./multi-dropdown-option.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiDropdownOptionComponent {
  @Input() i: number;
  @Input() option: IDriverUserInfo;
  @Input() optionFormatFields: string[] = [];
  @Input() selectedOptions: { [key: string]: Partial<IDriverUserInfo> };
  @Output() toggleOption$ = new EventEmitter<Partial<IDriverUserInfo>>();

  public toggleOption(option: IDriverUserInfo): void {
    this.toggleOption$.emit({
      id: option.id,
      firstName: option.firstName,
      lastName: option.lastName,
    });
  }
}
