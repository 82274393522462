import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IDriverUserInfo } from '../../../models/user-info.model';
import { UtilService } from '../../../services/common/util.service';

@Component({
  selector: 'app-driver-details-avatar',
  templateUrl: './driver-details-avatar.component.html',
  styleUrls: ['./driver-details-avatar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsAvatarComponent {
  @Input() driver: IDriverUserInfo;

  constructor(private readonly utilService: UtilService) {}

  get driverInitials(): string {
    let initials = '';
    if (this.driver.firstName) {
      initials += this.driver.firstName[0];
    }
    if (this.driver.lastName) {
      initials += this.driver.lastName[0];
    }
    return initials.toUpperCase();
  }

  get driverFullName(): string {
    return this.utilService.tagRemoveFalsyValueFunc`${this.driver.firstName} ${this.driver.lastName}`;
  }

  get driverStatusClassModifier(): string {
    return this.driver.status.toLowerCase();
  }
}
