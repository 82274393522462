import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ProfileAvatarComponent } from './profile-avatar.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ProfileAvatarComponent],
  exports: [ProfileAvatarComponent],
})
export class ProfileAvatarModule {}
