import { createSelector } from '@ngrx/store';

import { phoneAdvisor } from './phone-advisor.selectors';
import * as PhoneAdvisorReducers from '../reducers';
import * as CoffeeBreakReducer from '../reducers/coffee-break.reducer';

export const selectCoffeeBreak = createSelector(
  phoneAdvisor,
  (state: PhoneAdvisorReducers.PhoneAdvisorState) => state[CoffeeBreakReducer.coffeeBreakFeatureKey]
);

export const selectIsCoffeeBreak = createSelector(
  selectCoffeeBreak,
  (state: CoffeeBreakReducer.State) => state.isCoffeeBreak
);

const selectStopCoffeeBreakError = createSelector(
  selectCoffeeBreak,
  (state: CoffeeBreakReducer.State) => state.stopCoffeeBreakError
);

export const selectCoffeeBreakIncorrectPassword = createSelector(selectStopCoffeeBreakError, errorResponse =>
  errorResponse
    ? errorResponse.status === 400 && errorResponse.url.indexOf('api/v1/userSession/stopCoffeeBreak') !== -1
    : false
);

export const selectIsCoffeeBreakPopupLoadingIndicator = createSelector(
  selectCoffeeBreak,
  (state: CoffeeBreakReducer.State) => state.isCoffeeBreakPopupLoadingIndicator
);
