import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UtilService } from '../../services/common/util.service';

@Component({
  selector: 'azz-attach-file-preview',
  templateUrl: './attach-file-preview.component.html',
  styleUrls: ['./attach-file-preview.component.less'],
})
export class AttachFilePreviewComponent {
  private readonly utilService = inject(UtilService);
  private readonly translate = inject(TranslateService);

  @ViewChild('formElemRef') formElemRef: ElementRef;
  @Input() azzAccept = 'image/png, image/jpeg';
  @Input() acceptedExtensionsArray = ['jpg', 'jpeg', 'png', 'JPG', 'PNG', 'JPEG'];
  @Input() attachPreviewForm: FormGroup;
  @Input() maxWidth: number;
  @Input() maxHeight: number;
  @Input() azzId: string;
  @Input() ngRequired: boolean;
  @Input() ngDisabled: boolean;
  @Output() azzOnDelete$ = new EventEmitter();
  @Output() azzOnAttached$ = new EventEmitter();
  @Output() azzOnError$ = new EventEmitter();
  @Input() typeOrExtensionErrorStr = this.translate.instant('CORE_FILE_TYPE_EXTENSION_INCORRECT');
  public attachedFile: File | null;
  public imagePath: string | null;
  public sizeError: boolean;
  public typeOrExtensionError: boolean;
  public azzCurrentData: string | null;
  private hasAtLeastOneError = false;
  private readonly MAX_FILE_SIZE = 10485760; // bytes

  constructor() {
    this.initForm();
  }

  @Input() set azzCurrent(value: string) {
    this.azzCurrentData = value;
    if (value) {
      this.attachPreviewForm.patchValue({ attachPreview: 'link' });
    } else {
      this.attachPreviewForm.patchValue({ attachPreview: null });
    }
  }

  public initForm() {
    this.attachPreviewForm = new FormGroup({
      attachPreview: new FormControl('', Validators.required),
    });
  }

  public isFormValid(): boolean {
    return this.attachPreviewForm.valid;
  }

  public markAsTouched(): void {
    this.attachPreviewForm.markAllAsTouched();
  }

  public deleteAttachedFile() {
    this.azzOnDelete$.emit(null);
    this.attachedFile = null;
    this.imagePath = null;
    this.azzCurrentData = null;
    this.resetErrors();
    this.resetForm();
  }

  public onFileChange(event: Event) {
    this.resetErrors();
    const files = (event.target as HTMLInputElement).files;
    const reader = new FileReader();

    if (files && files.length) {
      this.attachedFile = files[0];
      this.handleErrors();
      if (this.hasAtLeastOneError) {
        this.attachedFile = null;
        this.imagePath = null;
        this.azzCurrentData = null;
        this.resetForm();
        return;
      }

      reader.readAsDataURL(this.attachedFile);
      this.attachPreviewForm.patchValue({ attachPreview: 'link' });
      reader.onload = (evt: any) => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          if (this.maxWidth && this.maxHeight && (image.width !== this.maxWidth || image.height !== this.maxHeight)) {
            this.attachedFile = null;
            this.imagePath = null;
            this.azzOnAttached$.emit(null);
            this.azzOnError$.emit('SA_AD_DIMENSION_ERROR');
            return;
          }

          this.imagePath = evt.target.result;
          this.azzOnAttached$.emit(this.attachedFile);
        };
      };
      // Make it possible to trigger change event when adding the same file
      (event.target as HTMLInputElement).value = '';
    }
  }

  private resetForm() {
    this.attachPreviewForm.reset();
  }

  private handleErrors(): void {
    if (!this.isFileMimeTypeAccepted(this.attachedFile)) {
      this.typeOrExtensionError = true;
      this.hasAtLeastOneError = true;
    }
    if (!this.isFileExtensionAccepted()) {
      this.typeOrExtensionError = true;
      this.hasAtLeastOneError = true;
    }
    if (this.attachedFile.size > this.MAX_FILE_SIZE) {
      this.sizeError = true;
      this.hasAtLeastOneError = true;
    }
  }

  private resetErrors(): void {
    this.sizeError = false;
    this.typeOrExtensionError = false;
    this.hasAtLeastOneError = false;
  }

  private isFileMimeTypeAccepted(file: File): boolean {
    const acceptedImageTypes = this.utilService.getAcceptedImageTypesArrFromStr(this.azzAccept);
    return acceptedImageTypes.includes(file.type);
  }

  private isFileExtensionAccepted(): boolean {
    const fileName = this.getFileExtension(this.attachedFile.name);
    return this.acceptedExtensionsArray.includes(fileName);
  }

  private getFileExtension(fileName: string) {
    return fileName.split('.').pop();
  }
}
