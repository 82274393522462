import { NgModule, Optional, SkipSelf } from '@angular/core';
import { getAPIOriginByHost } from '@azz/env/get-api-origin-by-host.function';

import { PragmaticApiModule, PragmaticConfiguration, PragmaticConfigurationParameters } from './api/generated';

const configuration: PragmaticConfigurationParameters = {
  basePath: `${getAPIOriginByHost().getHost()}/backend/web2`,
  withCredentials: true,
};

@NgModule({
  declarations: [],
  imports: [PragmaticApiModule.forRoot(() => new PragmaticConfiguration(configuration))],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import in your base AppModule only.');
    }
  }
}
