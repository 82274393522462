import { CommonModule } from '@angular/common';
import { Directive, HostListener, Input, NgModule } from '@angular/core';
import { FormArray, FormGroup, NgControl, Validators } from '@angular/forms';

import { FormService } from '../../core/utils/form-service';

@Directive({
  selector: '[azzDynamicValidator]',
})
export class DynamicValidatorDirective {
  @Input() controlName: number | string;
  @Input() formGroupOrArray: FormGroup | FormArray;
  @Input() validators: Validators[] = [];
  constructor(
    private readonly ngControl: NgControl,
    private readonly formService: FormService
  ) {}

  @HostListener('keyup', ['$event'])
  onKeyup(evt) {
    if (evt.target.value) {
      this.formService.setValidators(this.formGroupOrArray, this.controlName, this.validators);
    } else {
      this.formService.removeValidators(this.formGroupOrArray, this.controlName);
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicValidatorDirective],
  exports: [DynamicValidatorDirective],
})
export class DynamicValidatorModule {}
