/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PointResponseWebApiModel } from './point-response-web.api-model';
import { CoordinatesZoneResponseWebApiModel } from './coordinates-zone-response-web.api-model';

/**
 * component with response data for address coordinates request
 */
export interface CoordinatesResponseWebApiModel {
  coordinates: PointResponseWebApiModel;
  /**
   * address label
   */
  label: string;
  /**
   * postal code
   */
  postal?: string;
  /**
   * state
   */
  state: string;
  /**
   * address city
   */
  city: string;
  /**
   * address street
   */
  streetName?: string;
  /**
   * address building number
   */
  streetNumber?: string;
  /**
   * poiId if passed coordinates are POI
   */
  poiId?: string;
  zones: CoordinatesZoneResponseWebApiModel;
}
