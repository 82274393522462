<form class="row" [formGroup]="form">
  <div class="form-group col-md-4">
    <label for="type" class="azz-transparent-input-label">{{ 'PA_EXCEPTION_TYPE_LABEL' | translate }}</label>
    <select id="type" name="type" class="form-control azz-transparent-input azz-expandable" formControlName="type">
      <option [ngValue]="null">{{ 'CORE_SHOW_ALL_OPT' | translate }}</option>
      <option *ngFor="let type of types" [value]="type">
        {{ 'PA_EXCEPTION_TYPE_' + type | translate }}
      </option>
    </select>
  </div>
</form>
