import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as RouterReducer from '../reducers/router.reducer';
import { CustomRouteInfo } from '../reducers/router.reducer';

export const selectRouter = createFeatureSelector<RouterReducer.State>(RouterReducer.routerFeatureKey);

export const selectRouterState = createSelector(selectRouter, (state: RouterReducer.State) => state?.state);

export const selectRouterUrl = createSelector(selectRouterState, (state: CustomRouteInfo) => state?.url);

export const selectRouterData = createSelector(selectRouterState, (state: CustomRouteInfo) => state?.data);

export const selectRouterParams = createSelector(selectRouterState, (state: CustomRouteInfo) => state?.params);

export const selectRouterQueryParams = createSelector(
  selectRouterState,
  (state: CustomRouteInfo) => state?.queryParams
);

export const selectRouterQueryParam = (param: string) =>
  createSelector(selectRouterQueryParams, queryParams => (queryParams ? queryParams[param] : null));

export const selectRouterParam = (param: string) =>
  createSelector(selectRouterParams, params => (params ? params[param] : null));
