import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from '@busacca/ng-pick-datetime';
import { AzzArrowDirectiveModule } from '@common/directives';
import { DisableControlModule } from '@common/directives/disable-control.directive';
import { CheckboxModule } from '@common/forms';
import { DropdownModule } from '@core/modules/dropdown';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmPopupModule } from '../../../common/components/confirm-popup/confirm-popup.module';
import { DialogModule } from '../../../common/components/dialog/dialog.module';
import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';
import { DatePickerModule } from '../../../common/date-picker/date-picker.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';
import { BeforeDispatchPopupComponent } from './components/before-dispatch-popup/before-dispatch-popup.component';
import { CityDropdownComponent } from './components/city-dropdown/city-dropdown.component';
import { G7OrdersFiltersComponent } from './components/g7-orders-filters/g7-orders-filters.component';
import { G7OrdersTableComponent } from './components/g7-orders-table/g7-orders-table.component';
import { InvalidatePopupComponent } from './components/invalidate-popup/invalidate-popup.component';
import { G7MultiReturnErrorPopupComponent } from './components/multi-return-error-popup/multi-return-error-popup.component';
import { MultiReturnPopupComponent } from './components/multi-return-popup/multi-return-popup.component';
import { G7MultiValidationErrorPopupComponent } from './components/multi-validation-error-popup/multi-validation-error-popup.component';
import { MultiValidationPopupComponent } from './components/multi-validation-popup/multi-validation-popup.component';
import { G7OrdersComponent } from './g7-orders.component';
import { G7OrdersStoreModule } from './store/g7-orders-store.module';

@NgModule({
  imports: [
    AzzArrowDirectiveModule,
    AzzRotateIndicatorModule,
    CheckboxModule,
    CommonModule,
    ConfirmPopupModule,
    DatePickerModule,
    DialogModule,
    DisableControlModule,
    DropdownModule,
    FormsModule,
    G7OrdersStoreModule,
    LoadingBlockModule,
    NgbDropdownModule,
    NgbModalModule,
    PaginationModule,
    ReactiveFormsModule,
    TableNoMatchesModule,
    TranslateModule,
  ],
  declarations: [
    BeforeDispatchPopupComponent,
    CityDropdownComponent,
    G7MultiReturnErrorPopupComponent,
    G7MultiValidationErrorPopupComponent,
    G7OrdersComponent,
    G7OrdersFiltersComponent,
    G7OrdersTableComponent,
    InvalidatePopupComponent,
    MultiReturnPopupComponent,
    MultiValidationPopupComponent,
  ],
  // entryComponents: [
  //   BeforeDispatchPopupComponent,
  //   G7MultiReturnErrorPopupComponent,
  //   G7MultiValidationErrorPopupComponent,
  //   InvalidatePopupComponent,
  //   MultiReturnPopupComponent,
  //   MultiValidationPopupComponent
  // ],
  exports: [G7OrdersComponent],
  providers: [{ provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
})
export class GeneralG7OrdersModule {}
