import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { isEqual } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import { ICustomersDataParams } from '../../../models/customer.model';

@Component({
  selector: 'app-customers-filters',
  templateUrl: './customers-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class CustomersFiltersComponent implements OnInit {
  @Output() updateFilterModel$ = new EventEmitter<Partial<ICustomersDataParams>>();
  public form: FormGroup;
  public statuses = ['ACTIVE', 'BLOCKED'];

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initForm();
  }

  @Input() set filterModel(value: Partial<ICustomersDataParams>) {
    if (!this.isValueEqual(value)) {
      this.form.patchValue(value, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.listenForFormChanges();
  }

  private initForm(): void {
    this.form = this.fb.group({
      anyParam: [null],
      phoneNumber: [null],
      subscriberCode: [null],
      status: [null],
    });
  }

  private isValueEqual(value: Partial<ICustomersDataParams>): boolean {
    return isEqual(value, this.form.getRawValue());
  }

  private listenForFormChanges(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updateFilterModel$.emit(this.form.getRawValue()));
  }
}
