import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@common/pipes/schedule-session-status.pipe';
import { OrderStatusType } from '@core/constant';
import { OrderInProgressStatusType } from '@core/constant/current-order-in-progress.consatnt';

@Pipe({
  name: 'azzOrderInProgress',
})
export class AzzOrderInProgressPipe implements PipeTransform {
  protected readonly orderStatusInProgress: {
    orderStatus: OrderInProgressStatusType[];
  } = {
    orderStatus: [
      'CORE_ORDER_STATUS_CREATE',
      'CORE_ORDER_STATUS_DISPATCHING',
      'CORE_ORDER_STATUS_NO_VEHICLE_FOUND',
      'CORE_ORDER_STATUS_CONFIRMED',
      'CORE_ORDER_STATUS_AT_DEPARTURE_ADDRESS',
      'CORE_ORDER_STATUS_TOWARDS_DESTINATION',
    ],
  };

  transform(orderStatus: OrderStatus | OrderStatusType | string, args?: any): string {
    if (typeof orderStatus !== 'string') {
      return '';
    }

    // @ts-ignore
    if (this.orderStatusInProgress.orderStatus.includes(orderStatus)) {
      return orderStatus;
    } else {
      return '';
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [AzzOrderInProgressPipe],
  exports: [AzzOrderInProgressPipe],
})
export class OrderInProgressStatusModule {}
