import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { IDeactivatePayload } from '../../../core/models/common.model';
import { FormService } from '../../../core/utils/form-service';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-deactivation-popup',
  templateUrl: './deactivation-popup.component.html',
  styleUrls: ['./deactivation-popup.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeactivationPopupComponent extends ConfirmPopupComponent {
  @ViewChild('formElemRef') formElemRef: ElementRef;
  public form: FormGroup;
  public minDate = moment().startOf('day');
  private readonly defaultTime = moment().startOf('day');

  constructor(
    modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    translateService: TranslateService,
    private readonly formService: FormService
  ) {
    super(modal, translateService);
    this.initForm();
  }

  public override submit(): void {
    this.formService.markFieldsAsTouched(this.form);
    if (this.isFormValid()) {
      this.modal.close(this.generatePayload());
      this.setDefaultTime();
    } else {
      this.formService.scrollToFirstInvalidControl([this.formElemRef]);
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      deactivationTime: [this.defaultTime, [Validators.required]],
    });
  }

  private generatePayload(): Partial<IDeactivatePayload> {
    const deactivationTime = this.form.value.deactivationTime.valueOf();
    return { deactivationTime };
  }

  private setDefaultTime(): void {
    this.form.patchValue({ deactivationTime: this.defaultTime });
  }

  private isFormValid(): boolean {
    return this.form.valid;
  }
}
