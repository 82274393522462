export enum ACTIVE_SESSION_STATE {
  DIALING = 'DIALING',
  RINGING = 'RINGING',
  ESTABLISHED = 'ESTABLISHED',
  CONNECTING = 'CONNECTING',
}

export enum REMOTE_STREAM_ACTION {
  SETUP = 'SETUP',
  CLEAN_UP = 'CLEAN_UP',
  TOGGLE_TRACK = 'TOGGLE_TRACK',
}

export enum CONTACT_TYPE {
  DRIVER = 'DRIVER',
  FLEET_OWNER = 'FLEET_OWNER',
  FLEET_ADMIN = 'FLEET_ADMIN',
  PHONE_ADVISOR = 'PHONE_ADVISOR',
}

export enum PBX_CONNECTION_STATE {
  ACTIVE = 'ACTIVE',
  TRYING = 'TRYING',
  LOST = 'LOST',
  NOT_ACTIVE = 'NOT_ACTIVE',
}
