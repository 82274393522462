import { AsyncPipe, DatePipe, Location, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Event, NavigationEnd, Router, RouterLink } from '@angular/router';
import { versionDate } from '@core/constant/app-version.consts';
import { UserRole } from '@core/constant/role.constant';
import { RouteUrls } from '@core/constant/route-urls';
import { IconLogoG7Component } from '@icons/logo/logoG7.component';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

@Component({
  selector: 'azz-footer',
  standalone: true,
  imports: [TranslateModule, RouterLink, DatePipe, IconLogoG7Component, NgIf, AsyncPipe],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  private readonly router = inject(Router);
  private readonly location = inject(Location);

  @Input() public className = 'footer';
  protected readonly versionDate = versionDate;
  protected readonly prefix$: Observable<string>;

  constructor() {
    this.prefix$ = this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      startWith({} as NavigationEnd),
      map(() => {
        const path = this.location.path();
        let prefix: string | undefined = undefined;

        if (path.startsWith(RouteUrls.dash[UserRole.billingUser])) {
          prefix = RouteUrls.dash[UserRole.billingUser];
        } else if (path.startsWith(RouteUrls.dash[UserRole.dispatcher])) {
          prefix = RouteUrls.dash[UserRole.dispatcher];
        } else if (path.startsWith(RouteUrls.dash[UserRole.driver])) {
          prefix = RouteUrls.dash[UserRole.driver];
        } else if (path.startsWith(RouteUrls.dash[UserRole.fleet])) {
          prefix = RouteUrls.dash[UserRole.fleet];
        } else if (path.startsWith(RouteUrls.dash[UserRole.phoneAdvisor])) {
          prefix = RouteUrls.dash[UserRole.phoneAdvisor];
        } else if (path.startsWith(RouteUrls.dash[UserRole.super])) {
          prefix = RouteUrls.dash[UserRole.super];
        }

        return prefix || '/';
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }
}
