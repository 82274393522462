<section class="details row azz-info-blocks-row azz-last pragmatic azz-details-page">
  <div class="col-12">
    <div class="azz-info-block">
      <div class="azz-nav-block">
        <img src="assets/images/return-arrow.png" class="azz-back-btn" routerLink="../" />
        <div class="azz-back-title">{{ 'CHAT' | translate }}</div>
      </div>
      <azz-loading-block [isLoading]="loadingIndicator">
        <div class="details__driver">{{ history?.driver?.name }}</div>
        <div class="details__session-time">
          {{ 'START_DATE' | translate }}
          {{ history?.entries[0].dateTime | date: 'dd/MM/yyyy, HH:mm:ss' }}
        </div>
        <ul class="details__list">
          <li class="row" *ngFor="let message of history?.entries">
            <div class="col-md-8">
              <azz-session-message [history]="history" [message]="message"></azz-session-message>
            </div>
          </li>
        </ul>
        <div class="details__session-time">
          {{ 'FT_FINISH_DATE' | translate }}
          {{ history?.entries[history.entries.length - 1].dateTime | date: 'dd/MM/yyyy, HH:mm:ss' }}
        </div>
      </azz-loading-block>
    </div>
  </div>
</section>
