<section class="azz-info-blocks-row azz-last">
  <div class="azz-info-block">
    <div class="azz-header">
      {{ (exceptionsData$ | async)?.totalElements || 0 }} {{ 'ORDERS' | translate | lowercase }}
    </div>
    <app-exceptions-filters [filterModel]="filterModel$ | async" (updateFilterModel$)="updateFilterModel($event)">
    </app-exceptions-filters>
    <azz-loading-block [isLoading]="loading$ | async">
      <app-exceptions-table
        [exceptionsData]="exceptionsData$ | async"
        (updatePagination$)="updatePagination($event)"
        (navigateToDriver$)="navigateToDriver($event)"
        (navigateToCustomer$)="navigateToCustomer($event)"
        (navigateToException$)="navigateToException($event)"
      >
      </app-exceptions-table>
    </azz-loading-block>
  </div>
</section>
