import { AuthStore } from '@auth/store';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { IColorManagementFull } from '../../../../models/color-management.model';
import { IDriverLicense, IDriverLicenseKind } from '../../../../models/driver-license.model';
import { ICountry } from '../../../../models/fleet.model';
import { IDriverOrdersStats } from '../../../../models/stats.model';
import { ITag } from '../../../../models/tag.model';
import { ITransportLicense } from '../../../../models/transport-license.model';
import { IDriverUserInfo } from '../../../../models/user-info.model';
import { IVehicle } from '../../../../models/vehicle.model';
import * as DriverDetailsActions from '../actions';

const defaultCountries = [{ isoCode: 'FR', localizedName: 'France', name: 'France', phoneCode: '+33' }];
const defaultDriverLicenseKinds = [
  { id: 'ARTISAN', name: 'Artisan' },
  { id: 'EMPLOYEE', name: 'Salarié' },
  { id: 'LESSEE-MANAGER', name: 'Locataire gérant' },
];

export const driverDetailsFeatureKey = 'driver-details';

export interface State {
  driver: IDriverUserInfo;
  driverLoading: boolean;
  ordersStats: IDriverOrdersStats;
  ordersStatsLoading: boolean;
  countries: ICountry[];
  driverLicenseKinds: IDriverLicenseKind[];
  driverLicense: IDriverLicense;
  vehicle: IVehicle;
  transportLicense: ITransportLicense;
  colorManagement: IColorManagementFull;
  tags: ITag[];
  blockBtnLoading: boolean;
  unblockBtnLoading: boolean;
  validateBtnLoading: boolean;
  saveBtnLoading: boolean;
  confirmLicenseBtnLoading: boolean;
  rejectLicenseBtnLoading: boolean;
}

const initialState: State = {
  driver: null,
  driverLoading: false,
  ordersStats: null,
  ordersStatsLoading: false,
  countries: [],
  driverLicenseKinds: [],
  driverLicense: null,
  vehicle: null,
  transportLicense: null,
  colorManagement: null,
  tags: [],
  blockBtnLoading: false,
  unblockBtnLoading: false,
  validateBtnLoading: false,
  saveBtnLoading: false,
  confirmLicenseBtnLoading: false,
  rejectLicenseBtnLoading: false,
};

const driverDetailsReducer = createReducer(
  initialState,
  on(DriverDetailsActions.getOrdersStats, state => ({ ...state, ordersStatsLoading: true })),
  on(DriverDetailsActions.getOrdersStatsSuccess, (state, { ordersStats }) => ({
    ...state,
    ordersStats,
    ordersStatsLoading: false,
  })),
  on(DriverDetailsActions.getOrdersStatsError, state => ({ ...state, ordersStatsLoading: false })),
  on(DriverDetailsActions.getCountriesSuccess, (state, { countries }) => ({ ...state, countries })),
  on(DriverDetailsActions.getCountriesError, state => ({ ...state, countries: defaultCountries })),
  on(DriverDetailsActions.getDriverLicenseKindsSuccess, (state, { driverLicenseKinds }) => ({
    ...state,
    driverLicenseKinds,
  })),
  on(DriverDetailsActions.getDriverLicenseKindsError, state => ({
    ...state,
    driverLicenseKinds: defaultDriverLicenseKinds,
  })),
  on(DriverDetailsActions.getDriverLicenseSuccess, (state, { driverLicense }) => ({
    ...state,
    driverLicense,
  })),
  on(DriverDetailsActions.getDriverLicenseError, state => ({ ...state, driverLicense: null })),
  on(DriverDetailsActions.getVehicleSuccess, (state, { vehicle }) => ({ ...state, vehicle })),
  on(DriverDetailsActions.getVehicleError, state => ({ ...state, vehicle: null })),
  on(DriverDetailsActions.getTransportLicenseSuccess, (state, { transportLicense }) => ({
    ...state,
    transportLicense,
  })),
  on(DriverDetailsActions.getTransportLicenseError, state => ({
    ...state,
    transportLicense: null,
  })),
  on(DriverDetailsActions.getDriver, state => ({ ...state, driverLoading: true })),
  on(DriverDetailsActions.getDriverSuccess, (state, { driver }) => ({
    ...state,
    driver,
    driverLoading: false,
  })),
  on(DriverDetailsActions.getDriverError, state => ({
    ...state,
    driver: null,
    driverLoading: false,
  })),
  on(DriverDetailsActions.getValidatedDriver, state => ({ ...state, driverLoading: true })),
  on(DriverDetailsActions.getValidatedDriverSuccess, (state, { driver }) => ({
    ...state,
    driver,
    driverLoading: false,
  })),
  on(DriverDetailsActions.getValidatedDriverError, state => ({ ...state, driverLoading: false })),
  on(DriverDetailsActions.getColorManagementSuccess, (state, { colorManagement }) => ({
    ...state,
    colorManagement,
  })),
  on(DriverDetailsActions.getColorManagementError, state => ({ ...state, colorManagement: null })),
  on(DriverDetailsActions.getDriverTagsSuccess, (state, { tags }) => ({ ...state, tags })),
  on(DriverDetailsActions.getDriverTagsError, state => ({ ...state, tags: [] })),
  on(DriverDetailsActions.updateDriverRatingSuccess, (state, { driver }) => ({ ...state, driver })),
  on(DriverDetailsActions.blockDriver, state => ({ ...state, blockBtnLoading: true })),
  on(DriverDetailsActions.blockDriverSuccess, (state, { driver }) => ({
    ...state,
    driver,
    blockBtnLoading: false,
  })),
  on(DriverDetailsActions.blockDriverError, state => ({ ...state, blockBtnLoading: false })),
  on(DriverDetailsActions.unblockDriver, state => ({ ...state, unblockBtnLoading: true })),
  on(DriverDetailsActions.unblockDriverSuccess, (state, { driver }) => ({
    ...state,
    driver,
    unblockBtnLoading: false,
  })),
  on(DriverDetailsActions.unblockDriverError, state => ({ ...state, unblockBtnLoading: false })),
  on(DriverDetailsActions.validateDriver, state => ({ ...state, validateBtnLoading: true })),
  on(DriverDetailsActions.validateDriverSuccess, state => ({
    ...state,
    validateBtnLoading: false,
  })),
  on(DriverDetailsActions.validateDriverError, state => ({ ...state, validateBtnLoading: false })),
  on(DriverDetailsActions.confirmTransportLicense, state => ({
    ...state,
    confirmLicenseBtnLoading: true,
  })),
  on(DriverDetailsActions.confirmTransportLicenseSuccess, state => ({
    ...state,
    confirmLicenseBtnLoading: false,
  })),
  on(DriverDetailsActions.confirmTransportLicenseError, state => ({
    ...state,
    confirmLicenseBtnLoading: false,
  })),
  on(DriverDetailsActions.rejectTransportLicense, state => ({
    ...state,
    rejectLicenseBtnLoading: true,
  })),
  on(DriverDetailsActions.rejectTransportLicenseSuccess, state => ({
    ...state,
    rejectLicenseBtnLoading: false,
  })),
  on(DriverDetailsActions.rejectTransportLicenseError, state => ({
    ...state,
    rejectLicenseBtnLoading: false,
  })),
  on(DriverDetailsActions.saveDriver, state => ({ ...state, saveBtnLoading: true })),
  on(DriverDetailsActions.saveDriverSuccess, (state, { response }) => {
    const { driver, vehicle, driverLicenseUpdate } = response;
    return {
      ...state,
      driver,
      vehicle,
      driverLicense: {
        ...state.driverLicense,
        ...driverLicenseUpdate,
      },
    };
  }),
  on(DriverDetailsActions.saveDriverError, state => ({ ...state, saveBtnLoading: false })),
  on(DriverDetailsActions.updateDriverTagsSuccess, (state, { tags }) => ({
    ...state,
    saveBtnLoading: false,
    tags,
  })),
  on(DriverDetailsActions.updateDriverTagsError, state => ({ ...state, saveBtnLoading: false })),
  on(DriverDetailsActions.destroy, () => initialState)
);

export const reducer = (state: State | undefined, action: Action) => driverDetailsReducer(state, action);

export const clearState =
  (r: ActionReducer<State>): ActionReducer<State> =>
  (state, action) => {
    if (action?.type === AuthStore.logoutSuccess.type) {
      return r(undefined, action);
    }

    return r(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
