<div class="form-group azz-last">
  <div class="azz-multiple" ngbDropdown #drop="ngbDropdown" autoClose="outside">
    <label [attr.for]="azzId + 'display-value'" class="azz-transparent-input-label">
      {{ azzLabel }}<span *ngIf="ngRequired && !azzNoStar">*</span>
    </label>
    <div class="g7-partners">
      <input
        [id]="azzId + 'display-value'"
        type="text"
        [name]="azzId + 'display_value'"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        [ngClass]="{ 'disabled-not-allowed': ngDisabled }"
        [placeholder]="azzPlaceholder"
        [ngModel]="data.displayValue"
        ngbDropdownToggle
        readonly
        [required]="ngRequired"
        [disabled]="ngDisabled"
      />
      <div
        *ngIf="inputCurrentValue && isPartnerRemovingIsPossible"
        class="azz-delete"
        (click)="closeDropdownMenu(drop, null)"
      ></div>
    </div>
    <div class="azz-dropdown-menu-wrapper" ngbDropdownMenu>
      <div class="azz-search-block azz-has-addon" *ngIf="!azzNoFilter">
        <div class="azz-search-addon"></div>
        <input
          type="text"
          name="search_query"
          (input)="watcherQuery$.emit(true)"
          [placeholder]="azzSearchPlaceholder || 'SEARCH_BY_NAME' | translate"
          class="form-control azz-transparent-input azz-search-field"
          [(ngModel)]="filter.query"
        />
      </div>
      <div class="azz-search-result-header" *ngIf="!azzNoFilter" (click)="changeFilterDirection()">
        {{ 'LAST_NAME' | translate }}
        <span
          class="arrow"
          [ngClass]="{
            'arrow-up': filter.direction === 'asc',
            'arrow-down': filter.direction === 'desc'
          }"
        >
        </span>
      </div>
      <ul class="list-unstyled azz-multiple-list" *ngIf="!config.loadingIndicator">
        <li *ngIf="azzNullAsAll === true">
          <div class="azz-checkbox-wrapper azz-hoverable">
            <input
              [id]="azzId + '-radio-all'"
              type="radio"
              [name]="azzId + '-radio-all'"
              class="azz-no-indicator"
              value="null"
              (click)="closeDropdownMenu(drop, null)"
            />
            <label [for]="azzId + '-radio-all'" class="azz-label azz-clickable">
              {{ azzAllOptionsLabel }}
            </label>
          </div>
        </li>
        <ng-container *ngIf="data?.optionsData?.content && data?.optionsData?.content?.length">
          <li *ngFor="let option of data?.optionsData?.content; let i_ndex = index">
            <div class="azz-checkbox-wrapper azz-hoverable">
              <input
                [id]="azzId + '-radio-' + i_ndex"
                type="radio"
                [name]="azzId + '-radio-' + i_ndex"
                class="azz-no-indicator"
                [value]="option"
                (click)="closeDropdownMenu(drop, option)"
              />
              <label [for]="azzId + '-radio-' + i_ndex" class="azz-label azz-clickable">
                {{ option }}
              </label>
            </div>
          </li>
        </ng-container>
      </ul>
      <div class="azz-loading-indicator">
        <azz-rotate-indicator7 [visible]="config.loadingIndicator"></azz-rotate-indicator7>
      </div>
      <azz-pagination
        (goToPrevPage)="prevPage()"
        (goToNextPage)="nextPage()"
        [prevDisabled]="!prevPageEnabled()"
        [nextDisabled]="!nextPageEnabled()"
      >
      </azz-pagination>
    </div>
  </div>
</div>
