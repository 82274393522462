/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PassengerRequestWebApiModel } from './passenger-request-web.api-model';

/**
 * component for specify passenger options if required
 */
export interface PassengerOptionsRequestWebApiModel {
  /**
   * true, if a passenger is anonymous
   */
  anonymous?: boolean;
  /**
   * true, if a customer is different with passenger
   */
  differentWithClient?: boolean;
  /**
   * list of passengers specified in order
   */
  passengers?: Array<PassengerRequestWebApiModel>;
}
