import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDeactivatePayload } from '../../models/common.model';
import {
  IDispatchersData,
  IDispatchersDataParams,
  IGetScheduleEntitiesParams,
  IRegisterDispatcherPayload,
  IScheduleEntity,
} from '../../models/dispatcher.model';
import { IInvitationBody, IInvitationData, IInvitationItem, IInvitationParams } from '../../models/invitation.model';
import { IDispatcherUserInfo } from '../../models/user-info.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class DispatcherService extends ApiService {
  public INVITE_API_URL = 'user/api/v1/invitation/dispatcher/';
  public API_URL = 'user/api/v1/dispatcher/';
  public REG_API_URL = 'api/v1/dispatcher/';
  public UPDATE_REG_API_URL = 'user/api/dispatcher/registration/';
  private readonly SCHEDULE_API_URL = 'user/api/v1/driver/mission';

  constructor(private readonly httpClient: HttpClient) {
    super(httpClient);
  }

  public inviteDispatcher(id: number, body: IInvitationBody): Observable<IInvitationItem> {
    return this.put(`${this.INVITE_API_URL}fleet/${id}/invite`, body);
  }

  public getInvitations(fleetId: number, params: Partial<IInvitationParams>): Observable<IInvitationData> {
    return this.get(`${this.INVITE_API_URL}fleet/${fleetId}`, params);
  }

  public reinvite(uuid: string): Observable<IInvitationItem> {
    return this.put(`${this.INVITE_API_URL}reinvite/${uuid}`, {});
  }

  public deleteInvitation(uuid: string): Observable<any> {
    return this.delete(this.INVITE_API_URL + uuid);
  }

  public getInvitationByUuid(uuid: string): Observable<IInvitationItem> {
    return this.get(this.INVITE_API_URL + uuid);
  }

  public registerDispatcher(body: IRegisterDispatcherPayload): Observable<IDispatcherUserInfo> {
    return this.post(`${this.REG_API_URL}registerDispatcher`, body);
  }

  public updateDispatcherRegistration(body: Partial<IDispatcherUserInfo>): Observable<IDispatcherUserInfo> {
    return this.post(`${this.UPDATE_REG_API_URL}updateDispatcherRegistration`, body);
  }

  public getDispatchersData(params: Partial<IDispatchersDataParams>): Observable<IDispatchersData> {
    return this.get(`${this.API_URL}search`, params);
  }

  public deactivateDispatcher(userId: number, body: IDeactivatePayload): Observable<any> {
    return this.post(`${this.API_URL}${userId}/block`, body);
  }

  public activateDispatcher(userId: number): Observable<any> {
    return this.post(`${this.API_URL}${userId}/unblock`, {});
  }

  public getScheduleEntities(params: IGetScheduleEntitiesParams): Observable<IScheduleEntity[]> {
    return this.get(this.SCHEDULE_API_URL, params);
  }

  public updateDispatcher(userId: number, body: IDispatcherUserInfo): Observable<IDispatcherUserInfo> {
    return this.put(`${this.API_URL}${userId}`, body);
  }
}
