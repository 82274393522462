import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { IDriverLicense, IDriverLicenseKind, IDriverLicenseUpdate } from '../../models/driver-license.model';
import { ITransportLicense } from '../../models/transport-license.model';
import { UtilService } from '../../services/common/util.service';
import { FormService } from '../../utils/form-service';
import { AttachFileComponent } from '../attach-file/attach-file.component';

@Component({
  selector: 'app-driver-transport-licenses',
  templateUrl: './driver-transport-licenses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTransportLicensesComponent {
  @Input() driverLicenseKinds: IDriverLicenseKind[];
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @ViewChild(AttachFileComponent) attachFilename: AttachFileComponent;
  @Input() availableDriverLicenseKinds: IDriverLicenseKind[];
  @Output() updateFile = new EventEmitter<File>();
  public attachedTransportLicense: File;
  public isEditable: boolean;
  public driverLicense: IDriverLicense;
  public transportLicense: ITransportLicense;

  public MAX_PERIOD_IN_YEARS = 20;
  public form: FormGroup;
  public data: any = {};

  constructor(
    private readonly utilService: UtilService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService
  ) {
    this.initForm();
  }

  @Input() set driverLicenseData(driverLicense: IDriverLicense) {
    if (driverLicense) {
      this.driverLicense = driverLicense;
      this.form.patchValue({
        driverLicenseKind: driverLicense.kind,
        driverLicenseNumber: driverLicense.number,
      });
    }
  }

  @Input() set transportLicenseData(transportLicense: ITransportLicense) {
    if (transportLicense) {
      this.transportLicense = transportLicense;
      this.form.patchValue({ transportLicenseNumber: transportLicense.number });
      this.resetTransportLicense();
    }
  }

  @Input() set editable(value: boolean) {
    this.isEditable = value;
    if (value) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  public getDriverLicenseUpdate(): IDriverLicenseUpdate {
    const { driverLicenseNumber, driverLicenseKind } = this.form.getRawValue();
    return {
      id: this.driverLicense.id,
      number: driverLicenseNumber,
      kind: driverLicenseKind,
    };
  }

  public attachTransportLicense(file: File): void {
    this.attachedTransportLicense = file;
  }

  public resetTransportLicense(): void {
    this.attachedTransportLicense = null;
  }

  public compareDriverLicenseKind(c1: IDriverLicenseKind, c2: IDriverLicenseKind): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public trackDriverLicenseKind(index: number, item: IDriverLicenseKind): string {
    if (!item) {
      return null;
    }
    return item.id;
  }

  public markFieldsAsTouched(): void {
    this.formService.markFieldsAsTouched(this.form);
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      driverLicenseKind: ['', [Validators.required]],
      driverLicenseNumber: ['', [Validators.required]],
      transportLicenseNumber: ['', [Validators.required]],
    });
  }
}
