/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * address data object containing all fields to specify address in order when creation or updation
 */
export interface AddressRequestWebApiModel {
  /**
   * latitude
   */
  lat: number;
  /**
   * longitude
   */
  lon: number;
  /**
   * postcode
   */
  postcode?: string;
  /**
   * state
   */
  state?: string;
  /**
   * city
   */
  city?: string;
  /**
   * street name
   */
  streetName?: string;
  /**
   * house number
   */
  streetNumber?: string;
  /**
   * poi ID if specified
   */
  poiId?: string;
  /**
   * comment for an address
   */
  comment?: string;
}
