<ng-template #content let-modal>
  <section class="modal-window">
    <p *ngIf="header" class="modal-window__text modal-window__header">{{ header | translate }}</p>
    <p *ngIf="message" class="modal-window__text modal-window__message">
      {{ message | translate }}
    </p>

    <div class="modal-window__btn-group">
      <button type="button" class="modal-window__btn modal-window__btn--submit" (click)="close()">
        {{ 'PA_DETAILS_DELETE' | translate }}
      </button>
      <button type="button" class="modal-window__btn modal-window__btn--cancel" (click)="clear()">
        {{ 'FACTURATION_VALID_BTN' | translate }}
      </button>
    </div>
  </section>
</ng-template>
