import { inject, Injectable } from '@angular/core';
import { IAdvert } from '@core/models/advert.model';
import { InfoService } from '@core/services/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { map, switchMap } from 'rxjs/operators';

import * as AdvertActions from '../actions/advert.actions';

@Injectable()
export class AdvertEffects extends EffectsBase {
  private readonly infoService = inject(InfoService);

  public getAdvert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdvertActions.getAdvert),
      switchMap(() =>
        this.infoService.getAdvertisement().pipe(
          map((advert: IAdvert) => AdvertActions.getAdvertSuccess({ advert })),
          this.catchError(AdvertActions.getAdvertError())
        )
      )
    )
  );
}
