import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ETAResponseParams } from '@core/modules/base-create-order/interfaces/eta.interface';
import { HereApi } from '@core/modules/base-create-order/interfaces/here-api-coordinates.interface';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

/** OSRM — Open Street Map, alternative to google maps */
@Injectable({ providedIn: 'root' })
export class OSRMService extends ApiService {
  // private readonly urlBuilder = inject(URLBuilderService);

  constructor(http: HttpClient) {
    super(http);
  }

  public getFastestRoute(coordinates: HttpParams): Observable<HereApi> {
    const url = '/driver-session/api/v1/estimation?';
    // console.log('coordinates: ', coordinates);
    return this.get(`${url}` + coordinates.toString());
  }

  public getApproximateETA(coordinates: HttpParams): Observable<ETAResponseParams[]> {
    const url = '/driver-session/api/v1/driversearch/approximateEta?';
    return this.get(`${url}` + coordinates.toString());
  }
}
