import { inject, Injectable } from '@angular/core';
import { ConfirmPopupComponent } from '@common/components/confirm-popup/confirm-popup.component';
import { G7_ORDER_PROCESSING_STATUS, G7_ORDER_STATUS } from '@core/constant/g7-order.constant';
import {
  IBeforeDispatchOrdersData,
  IG7OrderMultiReturnRes,
  IG7OrderMultiValidationRes,
  IG7OrdersData,
  IG7OrdersParams,
} from '@core/models/g7-order.model';
import { CustomPopupService } from '@core/services/common';
import { URLBuilderService } from '@core/services/common/url-builder.service';
import { G7OrdersService } from '@core/services/orders/g7-orders.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { from, merge, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { BeforeDispatchPopupComponent } from '../../components/before-dispatch-popup/before-dispatch-popup.component';
import { InvalidatePopupComponent } from '../../components/invalidate-popup/invalidate-popup.component';
import { G7MultiReturnErrorPopupComponent } from '../../components/multi-return-error-popup/multi-return-error-popup.component';
import { MultiReturnPopupComponent } from '../../components/multi-return-popup/multi-return-popup.component';
import { G7MultiValidationErrorPopupComponent } from '../../components/multi-validation-error-popup/multi-validation-error-popup.component';
import { MultiValidationPopupComponent } from '../../components/multi-validation-popup/multi-validation-popup.component';
import * as G7OrdersActions from '../actions';
import { G7OrdersStore } from '../index';

@Injectable()
export class G7OrdersEffects extends EffectsBase {
  private readonly g7OrdersService = inject(G7OrdersService);
  private readonly dialog = inject(NgbModal);
  private readonly customPopupService = inject(CustomPopupService);

  public getG7Orders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.getG7Orders),
      withLatestFrom(this.selectFromStore(G7OrdersStore.selectG7OrdersParams)), // ToDo Use another
      // combined selector
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, params]) =>
        this.g7OrdersService.getOrders(params).pipe(
          map((ordersData: IG7OrdersData) => G7OrdersActions.getG7OrdersSuccess({ ordersData })),
          this.catchError(G7OrdersActions.getG7OrdersError())
        )
      )
    )
  );

  public getG7OrdersAllValidatable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.getG7OrdersAllValidatable),
      withLatestFrom(this.selectFromStore(G7OrdersStore.selectG7OrdersParams)),
      switchMap(([_, params]) =>
        this.g7OrdersService.getOrders({
          ...params,
          processingStatus: params?.processingStatus?.length
            ? params.processingStatus
            : [G7_ORDER_PROCESSING_STATUS.WAITING_G7, G7_ORDER_PROCESSING_STATUS.CHANGED],
          status: params?.status?.length
            ? params.status
            : [G7_ORDER_STATUS.PENDING, G7_ORDER_STATUS.TO_PAY, G7_ORDER_STATUS.IN_ERROR],
          page: 0,
          size: 200,
        })
      ),
      map((ordersData: IG7OrdersData) => G7OrdersActions.getG7OrdersAllValidatableSuccess({ ordersData }))
    )
  );

  public updateFilterModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.updateG7OrdersFilterModel),
      debounceTime(500),
      map(() => G7OrdersActions.updateG7OrdersPagination({ pagination: { page: 0 } }))
    )
  );

  public updateG7OrdersSorting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.updateG7OrdersSorting),
      map(() => G7OrdersActions.getG7Orders())
    )
  );

  public updateG7OrdersPagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.updateG7OrdersPagination),
      map(() => G7OrdersActions.getG7Orders())
    )
  );

  public openBeforeDispatchPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.openBeforeDispatchPopup),
      switchMap(() => {
        const dialogRef = this.dialog.open(BeforeDispatchPopupComponent, {
          ariaLabelledBy: 'modal-basic-title',
        });
        const dialogObs$ = from(dialogRef.result).pipe(
          map(() => G7OrdersActions.closeBeforeDispatchPopup()),
          catchError(() => of(G7OrdersActions.closeBeforeDispatchPopup()))
        );
        return merge(dialogObs$, of(G7OrdersActions.getOrdersBeforeDispatch()));
      })
    )
  );

  public getOrdersBeforeDispatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.getOrdersBeforeDispatch),
      withLatestFrom(this.selectFromStore(G7OrdersStore.selectDispatchFullFilterModel)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, filterModel]) =>
        this.g7OrdersService.getBeforeDispatchOrdersData(filterModel).pipe(
          map((ordersData: IBeforeDispatchOrdersData) =>
            G7OrdersActions.getOrdersBeforeDispatchSuccess({ ordersData })
          ),
          this.catchError(G7OrdersActions.getOrdersBeforeDispatchError())
        )
      )
    )
  );

  public updateOrdersBeforeDispatchFilterModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.updateDispatchPagination),
      map(() => G7OrdersActions.getOrdersBeforeDispatch())
    )
  );

  public dispatchG7Orders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.dispatchG7Orders),
      withLatestFrom(this.selectFromStore(G7OrdersStore.selectDispatchOrdersBody)),
      mergeMap(([{ popup }, body]) =>
        this.g7OrdersService.dispatchToSaga7(body).pipe(
          map(() => {
            if (popup) {
              popup.close();
            }
            return G7OrdersActions.dispatchG7OrdersSuccess();
          }),
          this.catchError(G7OrdersActions.dispatchG7OrdersError())
        )
      )
    )
  );

  public dispatchG7OrdersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.dispatchG7OrdersSuccess),
      map(() => G7OrdersActions.updateG7OrdersPagination({ pagination: { page: 0 } }))
    )
  );

  public openValidationPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.openValidationPopup),
      switchMap(({ orderId, body }) => {
        const ref = this.customPopupService.openConfirmPopup(ConfirmPopupComponent, {
          message: 'GO_ORDER_ACCEPT_VALIDATION_DIALOG',
        });
        return from(ref.result).pipe(
          map(() => G7OrdersActions.validateG7Order({ orderId, body })),
          catchError(() => of(G7OrdersActions.closeValidationPopup()))
        );
      })
    )
  );

  public validateG7Order$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.validateG7Order),
      switchMap(({ orderId, body }) =>
        this.g7OrdersService.validateG7Order(orderId, body).pipe(
          switchMap(() => [
            G7OrdersActions.updateG7OrdersFilterModel({
              filterModel: {} as Partial<IG7OrdersParams>,
            }),
            G7OrdersActions.resetSelection({ orderId }),
          ]),
          this.catchError()
        )
      )
    )
  );

  public exportCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(G7OrdersActions.exportCsv),
        withLatestFrom(this.selectFromStore(G7OrdersStore.selectG7OrdersCsvParams)),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tap(([_, params]) => {
          const csvPath = this.g7OrdersService.exportG7OrdersCsv(params);
          const buildedUrl = this.urlBuilder.build(csvPath);
          window.open(buildedUrl);
        })
      ),
    { dispatch: false }
  );

  public openInvalidatePopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.openInvalidatePopup),
      switchMap(({ order }) => {
        const ref = this.customPopupService.openPopup(InvalidatePopupComponent, { order });
        return from(ref.result).pipe(
          map((message: string) =>
            message
              ? G7OrdersActions.invalidateG7Order({
                  orderId: order.tripNumber,
                  body: { message, valid: false },
                })
              : G7OrdersActions.closeInvalidatePopup()
          ),
          catchError(() => of(G7OrdersActions.closeInvalidatePopup()))
        );
      })
    )
  );

  public invalidateG7Order$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.invalidateG7Order),
      switchMap(({ orderId, body }) =>
        this.g7OrdersService.validateG7Order(orderId, body).pipe(
          map(() => G7OrdersActions.updateG7OrdersFilterModel({ filterModel: {} as Partial<IG7OrdersParams> })),
          this.catchError()
        )
      )
    )
  );

  public toggleAllSelection = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.toggleAllSelection),
      withLatestFrom(this.selectFromStore(G7OrdersStore.allSelectedOnPage)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_, allSelected]) => (allSelected ? G7OrdersActions.resetAllSelection() : G7OrdersActions.setAllSelection()))
    )
  );

  public openMultiValidationPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.openMultiValidationPopup),
      switchMap(() => {
        const ref = this.customPopupService.openPopup(MultiValidationPopupComponent);
        const ref$ = from(ref.result).pipe(
          map(() => G7OrdersActions.closeMultiValidationPopup()),
          catchError(() => of(G7OrdersActions.closeMultiValidationPopup()))
        );
        return merge(ref$, of(G7OrdersActions.updateMultiValidationPagination({ pagination: { page: 0 } })));
      })
    )
  );

  public updateMultiValidationPagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.updateMultiValidationPagination),
      map(() => G7OrdersActions.getMultiValidationOrders())
    )
  );

  public getMultiValidationOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.getMultiValidationOrders),
      withLatestFrom(
        this.selectFromStore(G7OrdersStore.selectG7OrdersDataSelectedOrders),
        this.selectFromStore(G7OrdersStore.multiValidationPagination)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, orders, { page, size }]) => {
        const ordersIds: number[] = orders.map(order => order.tripNumber);
        const orderIdsPaged = ordersIds.slice(page * size, page * size + size);

        return this.g7OrdersService.getMultiValidationOrders(orderIdsPaged).pipe(
          map(result => G7OrdersActions.getMultiValidationOrdersSuccess({ orders: result })),
          this.catchError(G7OrdersActions.getMultiValidationOrdersError())
        );
      })
    )
  );

  public multiValidateG7Orders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.multiValidateG7Orders),
      withLatestFrom(this.selectFromStore(G7OrdersStore.selectG7OrdersDataSelectedOrders)),
      mergeMap(([{ popup }, orders]) => {
        const tripNumbers: number[] = orders.map(order => order.tripNumber);

        return this.g7OrdersService.multiValidateG7({ tripNumbers, valid: true }).pipe(
          map((response: IG7OrderMultiValidationRes[]) => {
            if (popup) {
              popup.close();
            }
            return G7OrdersActions.multiValidateG7OrdersHandleRes({ response });
          }),
          catchError(err => {
            if (popup) {
              popup.close();
            }

            return this.catchErrorHandler(err, G7OrdersActions.multiValidateG7OrdersError());
          })
        );
      })
    )
  );

  public multiValidateG7OrdersHandleRes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.multiValidateG7OrdersHandleRes),
      switchMap(({ response }) => {
        const ordersWithErrors = response
          .filter(item => item.errorReason === 'order.g7.illegal.status')
          .map(i => i.result);
        const action = ordersWithErrors?.length
          ? G7OrdersActions.openMultiValidationErrorPopup({ orders: ordersWithErrors })
          : G7OrdersActions.multiValidateG7OrdersHandleSuccess();
        return merge(of(G7OrdersActions.updateG7OrdersPagination({ pagination: { page: 0 } })), of(action));
      })
    )
  );

  public openMultiValidationErrorPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.openMultiValidationErrorPopup),
      switchMap(({ orders }) => {
        const ref = this.customPopupService.openPopup(G7MultiValidationErrorPopupComponent, {
          orders,
        });
        return from(ref.result).pipe(
          map(() => G7OrdersActions.closeMultiValidationErrorPopup()),
          catchError(() => of(G7OrdersActions.closeMultiValidationErrorPopup()))
        );
      })
    )
  );

  public openMultiReturnPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.openMultiReturnPopup),
      switchMap(() => {
        const ref = this.customPopupService.openPopup(MultiReturnPopupComponent);
        const ref$ = from(ref.result).pipe(
          map(() => G7OrdersActions.closeMultiReturnPopup()),
          catchError(() => of(G7OrdersActions.closeMultiReturnPopup()))
        );
        return merge(ref$, of(G7OrdersActions.updateMultiReturnPagination({ pagination: { page: 0 } })));
      })
    )
  );

  public updateMultiReturnPagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.updateMultiReturnPagination),
      map(() => G7OrdersActions.getMultiReturnOrders())
    )
  );

  public getMultiReturnOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.getMultiReturnOrders),
      withLatestFrom(
        this.selectFromStore(G7OrdersStore.selectedOrdersIds),
        this.selectFromStore(G7OrdersStore.multiReturnPagination)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, ids, { page, size }]) => {
        const ordersIds = ids.slice(page * size, page * size + size);
        return this.g7OrdersService.getMultiReturnnOrders(ordersIds).pipe(
          map(orders => G7OrdersActions.getMultiReturnOrdersSuccess({ orders })),
          this.catchError(G7OrdersActions.getMultiReturnOrdersError())
        );
      })
    )
  );

  public multiReturnG7Orders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.multiReturnG7Orders),
      withLatestFrom(this.selectFromStore(G7OrdersStore.selectedOrdersIds)),
      mergeMap(([{ popup, message }, tripNumbers]) =>
        this.g7OrdersService.multiReturnG7({ tripNumbers, valid: false, message }).pipe(
          map((response: IG7OrderMultiReturnRes[]) => {
            if (popup) {
              popup.close();
            }
            return G7OrdersActions.multiReturnG7OrdersHandleRes({ response });
          }),
          catchError(err => {
            if (popup) {
              popup.close();
            }

            return this.catchErrorHandler(err, G7OrdersActions.multiReturnG7OrdersError());
          })
        )
      )
    )
  );

  public multiReturnG7OrdersHandleRes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.multiReturnG7OrdersHandleRes),
      switchMap(({ response }) => {
        const ordersWithErrors = response
          .filter(item => item.errorReason === 'order.g7.illegal.status')
          .map(i => i.result);
        const action = ordersWithErrors?.length
          ? G7OrdersActions.openMultiReturnErrorPopup({ orders: ordersWithErrors })
          : G7OrdersActions.multiReturnG7OrdersHandleSuccess();
        return merge(of(G7OrdersActions.updateG7OrdersPagination({ pagination: { page: 0 } })), of(action));
      })
    )
  );

  public openMultiReturnErrorPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(G7OrdersActions.openMultiReturnErrorPopup),
      switchMap(({ orders }) => {
        const ref = this.customPopupService.openPopup(G7MultiReturnErrorPopupComponent, { orders });
        return from(ref.result).pipe(
          map(() => G7OrdersActions.closeMultiReturnErrorPopup()),
          catchError(() => of(G7OrdersActions.closeMultiReturnErrorPopup()))
        );
      })
    )
  );

  protected readonly urlBuilder = inject(URLBuilderService);

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(G7OrdersActions.g7OrdersInit),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(G7OrdersActions.g7OrdersDestroy)))))
    );
  }
}
