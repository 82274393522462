<section class="azz-info-block invite">
  <form name="invite-users-by-csv">
    <p class="azz-header">{{ header | translate }}</p>
    <p>{{ message | translate }}</p>
    <ol class="reset-list">
      <li>
        1. {{ 'DIALOG_DOWNLOAD_TEXT' | translate }}
        <a [href]="csvTemplateUrl" class="invite__template-link">{{ 'DIALOG_MODEL_LINK' | translate }}</a
        >.
      </li>
      <li>2. {{ 'SUPER_AFFI_TEXT_FILL_EXAMPLE' | translate }}</li>
      <li>3. {{ 'SUPER_AFFI_TEXT_THEN_UPLOAD_FILE' | translate }}</li>
    </ol>
    <azz-attach-file
      azzId="csv-indicator"
      azzAccept=".csv"
      [azzAcceptMimeType]="csvMimeType"
      [acceptedExtensionsArray]="['csv']"
      [typeOrExtensionErrorStr]="'CSV_FILE_TYPE_EXTENSION_INCORRECT' | translate"
      [value]="file"
      (updateFile)="attachFile($event)"
    >
    </azz-attach-file>
    <div class="row justify-content-center" [class.invite__hidden]="!file">
      <button
        id="invite-by-csv"
        type="button"
        class="custom-btn custom-btn--orange btn-rotate-flex btn-rotate-flex--w-auto"
        [disabled]="btnLoading"
        (click)="invite()"
      >
        <azz-rotate-indicator7 [visible]="btnLoading"></azz-rotate-indicator7>
        {{ 'INVITE' | translate | uppercase }}
      </button>
    </div>
  </form>
  <ng-container *ngIf="invitations" [ngTemplateOutlet]="invitationsRef"></ng-container>
  <ng-template #invitationsRef>
    <div class="invite__modal">
      <button
        class="cross-btn-old cross-btn-old--popup invite__close-btn"
        type="button"
        (click)="closePopup()"
      ></button>
      <div class="invite__text-group">{{ invitations?.length }} {{ invitationSuccessText | translate }}</div>
      <button class="custom-btn custom-btn--orange" (click)="closePopup()">
        {{ 'INVITE_MORE' | translate }}
      </button>
    </div>
  </ng-template>
</section>
