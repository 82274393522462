<div class="pagination">
  <button
    type="button"
    class="custom-btn btn azz-default-btn pagination__btn"
    (click)="onPrevPageEmit()"
    [disabled]="prevDisabled"
  >
    {{ 'CORE_PREVIOUS_VALUE' | translate }}
  </button>
  <span class="pagination__page"> {{ currentPage }} </span>
  <button
    type="button"
    class="btn azz-primary-btn pagination__btn"
    (click)="onNextPageEmit()"
    [disabled]="nextDisabled"
  >
    {{ 'CORE_NEXT_VALUE' | translate }}
  </button>
</div>
