import { createSelector } from '@ngrx/store';

import { selectAuth } from './auth.selectors';
import * as AuthReducers from '../reducers';
import * as NewPasswordReducer from '../reducers/new-password.reducer';

export const selectNewPassword = createSelector(
  selectAuth,
  (state: AuthReducers.AuthState) => state[NewPasswordReducer.newPasswordFeatureKey]
);

export const selectNewPasswordLoading = createSelector(
  selectNewPassword,
  (state: NewPasswordReducer.State) => state.loading
);
