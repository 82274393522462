/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * payment type enum
 */
export type PaymentTypeEnumWebApiModel = 'CASH' | 'CAR' | 'ER' | 'AM';

export const PaymentTypeEnumWebApiModel = {
  Cash: 'CASH' as PaymentTypeEnumWebApiModel,
  Car: 'CAR' as PaymentTypeEnumWebApiModel,
  Er: 'ER' as PaymentTypeEnumWebApiModel,
  Am: 'AM' as PaymentTypeEnumWebApiModel,
};
