import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IOrderDiffMsgLogAction,
  IOrderDispatchLogAction,
  IOrderLogActionType,
  IOrderRedispatchLogAction,
} from '@core/models/order-logs.model';
import { ITag } from '@core/models/tag.model';
import { AppState } from '@core/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderDiffMsgLogService } from '../services/order-diff-msg-log.service';
import { OrderDispatchLogService } from '../services/order-dispatch-log.service';
import { OrderRedispatchLogService } from '../services/order-redispatch-log.service';
import { tagsSelector } from '../store/selectors';

@Component({
  selector: 'app-order-log-info',
  templateUrl: './order-log-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLogInfoComponent {
  public message$: Observable<string>;

  constructor(
    private readonly orderDiffMsgLogService: OrderDiffMsgLogService,
    private readonly orderDispatchLogService: OrderDispatchLogService,
    private readonly orderRedispatchLogService: OrderRedispatchLogService,
    private readonly store$: Store<AppState>
  ) {}

  @Input() set action(action: IOrderLogActionType) {
    switch (action.type) {
      case 'DIFFERENCES_MESSAGE':
        this.message$ = of(this.orderDiffMsgLogService.generateMsg(action as IOrderDiffMsgLogAction));
        break;
      case 'DISPATCH':
        this.message$ = this.store$.pipe(
          select(tagsSelector),
          map((tags: ITag[]) => this.orderDispatchLogService.generateMsg(action as IOrderDispatchLogAction, { tags }))
        );
        break;
      case 'REDISPATCH':
        this.message$ = of(this.orderRedispatchLogService.generateMsg(action as unknown as IOrderRedispatchLogAction));
        break;
      default:
        this.message$ = of(action.message ?? '');
    }
  }
}
