import { IShortError } from '@core/models/common.model';
import { ICurrentUserInfo } from '@core/models/user-info.model';
import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from '../actions';

export const authInfoFeatureKey = 'authInfo';

export interface State {
  loading: boolean;
  userInfo: ICurrentUserInfo | null;
  email: string;
  error: IShortError;
  isLogoutPopupShown: boolean;
  isPhoneAdvisorDeactivated: boolean;
  isSessionExpired: boolean;
}

export const initialState: State = {
  loading: false,
  userInfo: null,
  email: null,
  error: null,
  isLogoutPopupShown: false,
  isPhoneAdvisorDeactivated: false,
  isSessionExpired: false,
};

const authInfoReducer = createReducer(
  initialState,
  on(AuthActions.login, (state, { authData }) => ({
    ...state,
    loading: true,
    email: decodeURIComponent(authData.username),
    error: null,
  })),
  on(AuthActions.loginError, (state, { error }) => ({ ...state, loading: false, error })),
  on(AuthActions.loginSuccess, state => ({ ...state, email: null })),
  on(AuthActions.userInfoError, () => initialState),
  on(AuthActions.setUserInfo, (state, { userInfo }) => ({ ...state, userInfo })),
  on(AuthActions.firebaseSignInSuccess, state => ({ ...state, loading: false, error: null })),
  on(AuthActions.logoutSuccess, () => initialState),
  on(AuthActions.toggleLogoutPopup, (state, { isLogoutPopupShown }) => ({
    ...state,
    isLogoutPopupShown,
  })),
  on(AuthActions.togglePhoneAdvisorAccountDeactivated, (state, { isPhoneAdvisorDeactivated }) => ({
    ...state,
    isPhoneAdvisorDeactivated,
  })),
  on(AuthActions.toggleSessionExpired, (state, { isSessionExpired }) => ({
    ...state,
    isSessionExpired,
  }))
);

export const reducer = (state: State | undefined, action: Action): State => authInfoReducer(state, action);
