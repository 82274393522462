import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  DRIVER_SERVICE_AND_VEHICLE_TYPE_TAG_VALUE_ID_MAP,
  SERVICE_TYPE_TAG,
  SERVICE_VEHICLE_TYPE_DISABLED,
  VEHICLE_TYPE_TAG,
} from '../../../constant/tag.constant';

@Component({
  selector: 'app-vehicle-info-service-vehicle-types',
  templateUrl: './vehicle-info-service-vehicle-types.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleInfoServiceVehicleTypesComponent {
  @Input() form: FormGroup;
  @Input() serviceTypes: { id: string; value: string[] }[];
  @Input() vehicleTypes: { id: string; value: string[] }[];

  constructor(private readonly cd: ChangeDetectorRef) {}

  @Input() set driverVehicleTypeTagsIds(value: string[]) {
    this.setVehicleType(value);
  }

  @Input() set driverServiceTypeTagsIds(value: string[]) {
    this.setServiceType(value);
  }

  public isServiceTypeDisabled(option: { id: string; value: string[] }): boolean {
    const { vehicleType } = this.form.getRawValue();
    return SERVICE_VEHICLE_TYPE_DISABLED[option?.id] && SERVICE_VEHICLE_TYPE_DISABLED[vehicleType?.id];
  }

  public isVehicleTypeDisabled(option: { id: string; value: string[] }): boolean {
    const { serviceType } = this.form.getRawValue();
    return SERVICE_VEHICLE_TYPE_DISABLED[option?.id] && SERVICE_VEHICLE_TYPE_DISABLED[serviceType?.id];
  }

  public compareFn(c1: { id: string; value: string }, c2: { id: string; value: string }): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public trackByFn(index: number, item: any): string {
    if (!item) {
      return null;
    }
    return item.id;
  }

  public markForCheck(): void {
    this.cd.markForCheck();
  }

  private setVehicleType(value: string[]): void {
    if (value) {
      const patchedValue = value.length
        ? { id: DRIVER_SERVICE_AND_VEHICLE_TYPE_TAG_VALUE_ID_MAP[value.sort().join('')], value } // order makes sense cause of Map
        : { id: VEHICLE_TYPE_TAG.SEDAN, value };
      this.form.patchValue({ vehicleType: patchedValue });
    }
  }

  private setServiceType(value: string[]): void {
    if (value) {
      const patchedValue = value.length
        ? { id: DRIVER_SERVICE_AND_VEHICLE_TYPE_TAG_VALUE_ID_MAP[value.sort().join('')], value } // order makes sense cause of Map
        : { id: SERVICE_TYPE_TAG.STND, value };
      this.form.patchValue({ serviceType: patchedValue });
    }
  }
}
