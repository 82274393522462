import { AuthStore } from '@auth/store';
import { DIRECTION } from '@core/constant/direction.enum';
import { IPaginationState, ISortingState } from '@core/models/common.model';
import { ICustomersData, ICustomersDataParams } from '@core/models/customer.model';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import * as CustomersActions from '../actions';

export const customersFeatureKey = 'customers';

export interface State {
  customersData: ICustomersData;
  loading: boolean;
  filterModel: Partial<ICustomersDataParams>;
  pagination: IPaginationState;
  sorting: ISortingState;
}

const initialState: State = {
  customersData: null,
  loading: false,
  filterModel: {
    anyParam: null,
    phoneNumber: null,
    subscriberCode: null,
    status: null,
  },
  pagination: {
    page: 0,
    size: 15,
  },
  sorting: {
    sort: ['lastName'],
    direction: DIRECTION.DESC,
  },
};

const index = createReducer(
  initialState,
  on(CustomersActions.getCustomers, state => ({ ...state, loading: true })),
  on(CustomersActions.getCustomersSuccess, (state, { customersData }) => ({
    ...state,
    customersData,
    loading: false,
  })),
  on(CustomersActions.getCustomersError, state => ({
    ...state,
    customersData: null,
    loading: false,
  })),
  on(CustomersActions.updateFilterModel, (state, { filterModel }) => ({
    ...state,
    filterModel: { ...state.filterModel, ...filterModel },
  })),
  on(CustomersActions.updatePagination, (state, { pagination }) => ({
    ...state,
    pagination: { ...state.pagination, ...pagination },
  })),
  on(CustomersActions.updateSorting, (state, { sorting }) => ({
    ...state,
    sorting: { ...state.sorting, ...sorting },
  })),
  on(CustomersActions.destroy, () => initialState)
);

export const reducer = (state: State | undefined, action: Action) => index(state, action);

export const clearState =
  (r: ActionReducer<State>): ActionReducer<State> =>
  (state, action) => {
    if (action?.type === AuthStore.logoutSuccess.type) {
      return r(undefined, action);
    }

    return r(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
