import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'azz-loading-block',
  template: `
    <div class="azz-loading-block">
      <div class="azz-loading-popup" *ngIf="isLoading">
        <div class="azz-loading-popup-bg"></div>
        <div class="azz-loading-indicator">
          <span class="refresh__icon azz-rotate-animation"></span>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBlockComponent {
  @Input() isLoading: boolean;
}
