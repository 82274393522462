import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'azz-custom-order-driver-trip-data',
  templateUrl: './driver-trip-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTripDataComponent {
  @Input() realOrderTime: number;
  @Input() tripData: any;
  @Input() distanceToArrivalAddress: number;
  @Input() realOrderPrice: number;

  constructor() {}
}
