/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentTypeEnumWebApiModel } from './payment-type-enum-web.api-model';
import { OrderAddressResponseWebApiModel } from './order-address-response-web.api-model';

/**
 * object with order info data in frequent orders search response
 */
export interface FrequentOrderItemResponseWebApiModel {
  date: string;
  pickup: OrderAddressResponseWebApiModel;
  dropoff: OrderAddressResponseWebApiModel;
  paymentType: PaymentTypeEnumWebApiModel;
}
export namespace FrequentOrderItemResponseWebApiModel {}
