import { Data, Params } from '@angular/router';
import { Action, createReducer, on } from '@ngrx/store';

import * as PrevRouteActions from '../actions/prev-route.actions';

export const prevRouteFeatureKey = 'prev-route';

export interface State {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

const initialState: State = null;

const prevRouteReducer = createReducer(
  initialState,
  on(PrevRouteActions.setPrevRoute, (state, { prevRoute }) => prevRoute)
);

export const reducer = (state: State | undefined, action: Action) => prevRouteReducer(state, action);
