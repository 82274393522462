import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AzzRotateIndicatorComponent } from './rotate-indicator.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [AzzRotateIndicatorComponent],
  exports: [AzzRotateIndicatorComponent],
})
export class AzzRotateIndicatorModule {}
