/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PassengerOptionsRequestWebApiModel } from './passenger-options-request-web.api-model';
import { CustomerOptionsRequestWebApiModel } from './customer-options-request-web.api-model';
import { AddressRequestWebApiModel } from './address-request-web.api-model';

/**
 * request body for order creation request
 */
export interface CreateOrderRequestWebApiModel {
  pickup: AddressRequestWebApiModel;
  dropoff: AddressRequestWebApiModel;
  customerOptions: CustomerOptionsRequestWebApiModel;
  passengerOptions?: PassengerOptionsRequestWebApiModel;
  /**
   * preset driver ID if driver is selected
   */
  presetDriverId?: string;
  /**
   * dispatch delay in minutes. If not specified, default value would be selected from fleet properties and pickup address zone details
   */
  dispatchDelay?: number;
  /**
   * tags specific for order except payment types. Payment type should be specified in customer options
   */
  tags?: Array<string>;
  /**
   * order date and time in ISO 8601 format (YYYY-MM-DDThh:mm:ss±hh). If not specified, it\'s a creation date
   */
  date?: string;
  /**
   * common comment for all order
   */
  comment?: string;
}
