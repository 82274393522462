import { Component, Input } from '@angular/core';

@Component({
  selector: 'azz-icon-calendar',
  standalone: true,
  template: `
    <svg width="18" viewBox="0 0 18 18" fill="none" aria-hidden="true" [attr.class]="className">
      <rect x="0.5" y="2.5" width="17" height="15" rx="1.5" stroke="#4A33EC" />
      <path d="M4 1V4" stroke="#4A33EC" />
      <path d="M14 1V4" stroke="#4A33EC" />
      <path d="M17 6.49951L1 6.49951" stroke="#4A33EC" />
      <path d="M4 10L2 10" stroke="#4A33EC" stroke-width="2" />
      <path d="M4 14L2 14" stroke="#4A33EC" stroke-width="2" />
      <path d="M8 10L6 10" stroke="#4A33EC" stroke-width="2" />
      <path d="M8 14L6 14" stroke="#4A33EC" stroke-width="2" />
      <path d="M12 10L10 10" stroke="#4A33EC" stroke-width="2" />
      <path d="M12 14L10 14" stroke="#4A33EC" stroke-width="2" />
      <path d="M16 10L14 10" stroke="#4A33EC" stroke-width="2" />
      <path d="M16 14L14 14" stroke="#4A33EC" stroke-width="2" />
    </svg>
  `,
})
export class IconCalendarComponent {
  @Input() public className = 'icon-calendar';
}
