import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatePickerModule } from '@common/date-picker/date-picker.module';
import { AzzArrowDirectiveModule } from '@common/directives';
import { TranslateModule } from '@ngx-translate/core';

import { G7NotificationsComponent } from './g7-notifications.component';
import { NotificationService } from './services/notification.service';
import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    DatePickerModule,
    LoadingBlockModule,
    TableNoMatchesModule,
    PaginationModule,
    DropdownModule,
    AzzArrowDirectiveModule,
  ],
  declarations: [G7NotificationsComponent],
  exports: [G7NotificationsComponent],
  providers: [NotificationService],
})
export class G7CommonNotificationsModule {}
