import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { G7Order } from '../../../models/g7-order.model';
import { FLEET_DISABLED_FIELDS_MAP, G7_DISABLED_FIELDS_MAP } from '../azz-trip-details.component';

@Component({
  selector: 'azz-editable-toll',
  templateUrl: './editable-toll.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableTollComponent {
  @Input() mode: 'fleet' | 'g7';
  @Output() saveToll = new EventEmitter<any>();
  public order: G7Order;
  public oldToll: number;
  public toll: string | number;
  public isEditModeEnabled: boolean;
  private isEditable: boolean;

  @Input() set orderData(order: G7Order) {
    this.order = order;
    this.isEditable = !this.isProhibitedStatus() && !this.isOnboardPayment();
    this.oldToll = order?.toll;
    this.toll = order?.toll;
  }

  public toggleEditMode(value: boolean): void {
    if (this.isEditable) {
      this.isEditModeEnabled = value;
    }
  }

  public onBlur(): void {
    if (!this.isTollValid()) {
      this.setOldToll();
    }
    this.toggleEditMode(false);
    this.saveToll.emit(this.toll);
  }

  public keyDown(event: any) {
    const pattern = /[0-9]/;

    if (event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === '.') {
      return;
    }

    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  private isProhibitedStatus(): boolean {
    if (this.mode === 'fleet') {
      return FLEET_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    } else if (this.mode === 'g7') {
      return G7_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    }
    return false;
  }

  private isOnboardPayment(): boolean {
    return this.order?.firstPayment;
  }

  private setOldToll(): void {
    this.toll = this.oldToll;
  }

  private isTollValid(): boolean {
    return !!this.toll;
  }
}
