import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { IG7UserInvitationItem, IInvitationItem } from '../../models/invitation.model';
import { UtilService } from '../../services/common/util.service';
import { AttachFileComponent } from '../attach-file/attach-file.component';

@Component({
  selector: 'app-invite-users-by-csv',
  templateUrl: './invite-users-by-csv.component.html',
  styleUrls: ['./invite-users-by-csv.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteUsersByCsvComponent implements OnInit {
  @Input() invitations: IG7UserInvitationItem[] | IInvitationItem[];
  @Input() btnLoading: boolean;
  @Input() csvTemplateUrl: string;
  @Input() header: string;
  @Input() message: string;
  @Input() invitationSuccessText: string;
  @Output() invite$ = new EventEmitter<File>();
  @Output() closeInvitationPopup$ = new EventEmitter<void>();
  @ViewChild(AttachFileComponent) attachFileRef: AttachFileComponent;
  public file: File;
  public csvMimeType: string;

  constructor(private readonly utilService: UtilService) {}

  ngOnInit() {
    this.initData();
  }

  public closePopup(): void {
    this.closeInvitationPopup$.emit();
    this.reset();
  }

  public attachFile(file: File): void {
    this.file = file;
  }

  public reset(): void {
    this.file = null;
    this.attachFileRef.azzUploaded = false;
  }

  public invite(): void {
    this.invite$.emit(this.file);
  }

  private initData(): void {
    this.csvMimeType = this.utilService.getCsvMimeType();
  }
}
