import { Injectable } from '@angular/core';
import { getTagsError } from '@dash/modules/driver/modules-old/parameters/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ITag } from '../../../../models/tag.model';
import { TagService } from '../../../../services/common/tag.service';
import { getTagsAction, getTagsSuccessAction } from '../actions';

@Injectable()
export class OrderLogsEffects {
  public getTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getTagsAction),
      switchMap((): Observable<ITag[]> => this.tagService.getAllTags()),
      map((tags: ITag[]) => getTagsSuccessAction({ tags })),
      catchError((e: any) => of(getTagsError()))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly tagService: TagService
  ) {}
}
