/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';

import { DIRECTION } from '../../constant/direction.enum';

export class SortingHelper {
  public defaultColumns: any;
  public sortDirection: DIRECTION;
  public callback: any;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(
    defaultDirection,
    defaultColumns,
    resortCallback,
    public direction
  ) {
    this.defaultColumns = defaultColumns;
    this.sortDirection = defaultDirection;
    this.callback = resortCallback;
  }

  public getSortDirectionClass() {
    if (this.sortDirection === DIRECTION.ASC) {
      return 'arrow-up';
    } else {
      return 'arrow-down';
    }
  }

  public isSortedColumn(columns) {
    return isEqual(columns, this.defaultColumns);
  }

  public sortColumns(columns) {
    if (!isEqual(columns, this.defaultColumns)) {
      this.defaultColumns = columns;
      this.sortDirection = DIRECTION.DESC;
    } else {
      this._changeSortDirection();
    }

    if (this.callback) {
      this.callback();
    }
  }

  public getColumnNamesWithDirection() {
    const result = [];

    this.defaultColumns.forEach(value => {
      result.push(value + ',' + this.sortDirection);
    });

    return result;
  }

  public getColumnNamesString() {
    return this.defaultColumns.join(',');
  }

  public getDirection() {
    return this.sortDirection;
  }

  public _changeSortDirection() {
    if (this.sortDirection === this.direction.ASC) {
      this.sortDirection = this.direction.DESC;
    } else {
      this.sortDirection = this.direction.ASC;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class SortingHelperService {
  // ToDo Replace by new SortHelper
  constructor() {}

  public createSortingHelper(defaultDirection, defaultColumns, callback) {
    return new SortingHelper(defaultDirection, defaultColumns, callback, DIRECTION);
  }
}
