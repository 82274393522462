import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NouvelleCommandeComponent } from './nouvelle-commande.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '', component: NouvelleCommandeComponent, pathMatch: 'full' },
      { path: ':orderId', component: NouvelleCommandeComponent },
    ]),
  ],
})
export class NouvelleCommandeRoutingModule {}
