import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'azz-custom-order-full-trip-data',
  templateUrl: './custom-order-full-trip-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullTripDataComponent {
  @Input() tripData: any;
  @Input() realOrderTime: number;
  @Input() distanceToArrivalAddress: number;
  @Input() estimatedOrderPrice: number;
  @Input() estimatedPriceLoading: boolean;
  @Input() realOrderPrice: number;

  constructor() {}
}
