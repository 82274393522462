<ng-template #content let-modal>
  <div class="azz-popup delete-poi">
    <h1 class="message-dialog-header">{{ header | translate }}</h1>

    <div class="azz-info-block azz-pre-line message-dialog-message">
      <span>{{ message | translate }} </span><b>{{ retrievedValue?.label }}</b
      >?
    </div>

    <div class="azz-toolbar message-dialog-toolbar">
      <button type="button" class="btn btn-link message-dialog-default-btn" (click)="onClose()">
        {{ dismissValue || 'CANCEL' | translate | titlecase }}
      </button>
      <button
        type="button"
        class="btn btn-link message-dialog-primary-btn btn-rotate-flex delete-poi__success"
        (click)="onSuccess()"
      >
        <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
        {{ closeValue || 'FP_DELETE_HEADER' | translate }}
      </button>
    </div>
  </div>
</ng-template>
