import { inject, Injectable } from '@angular/core';

import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: 'root',
})
export class RootService {
  public readonly alerts = inject(AlertsService);
}
