import { OrderLogActionType, OrderLogType, orderLogActionModificationType } from '../constant/order-log.constant';
import { IPagination } from './common.model';
import { IOrder } from './order.model';
import { PhoneAdvisorOrder } from './phone-advisor-order';

export interface IOrderLogsParams {
  orderId: string;
  size: number;
  page: number;
  type: OrderLogType[];
}

export interface IOrderLogsData extends IPagination {
  content: IOrderLog[];
}

export interface IOrderLog {
  modificationDate: string;
  action: IOrderLogActionType;
  who: string;
  driverLink: string;
  taxiNumber: string;
  type: OrderLogType;
}

export type IOrderLogActionType = IOrderDiffMsgLogAction | IOrderDispatchLogAction | IOrderRedispatchLogAction;

export interface IOrderLogAction {
  modificationType: orderLogActionModificationType;
  type: OrderLogActionType;
  message?: string;
}

export interface IOrderDiffMsgLogAction extends IOrderLogAction {
  values?: IOrderLogActionValue[];
}

export interface IOrderDispatchLogAction extends IOrderLogAction {
  driverData: IOrderDispatchLogDriverData;
  driverDataExcluded: IOrderDispatchLogDriverData;
  geoAreaName: string;
  geoZoneName: string;
  tta: number;
  timeAddedToEtaOutsideZone: number;
  shortestEta: number;
}

export interface IOrderRedispatchLogAction extends IOrderLog {
  maxRedispatch: number;
  redispatchCounter: number;
  modificationType: orderLogActionModificationType;
  typeAction: OrderLogActionType;
  message?: string;
}

export interface IOrderLogActionValue {
  fieldName: string;
  valueBefore: string;
  valueAfter: string;
}

export type IOrderDispatchLogDriverData = IOrderDispatchLogDriver[];

export interface IOrderDispatchLogDriver {
  duration: number;
  eta: number;
  id: string;
  maxDistance: number;
  name: string;
  queuePosition: QueuePosition;
  reasonForExclusion: IOrderDispatchLogReason;
  tagList: string[];
  taxiNumber: string;
  excluded: boolean;
}

export interface QueuePosition {
  zoneId: string;
  zoneName: string;
  position: number;
  size: number;
}

export enum OrderDispatchLogReasons {
  TAGS = 'TAGS',
  ORDER = 'ORDER',
  STATUS = 'STATUS',
  REFUSED = 'REFUSED',
  REFUSED_BY_DRIVER_TIMEOUT = 'REFUSED_BY_DRIVER_TIMEOUT',
  REFUSED_BY_TIMEOUT = 'REFUSED_BY_TIMEOUT',
  RATING = 'RATING',
  DISTANCE = 'DISTANCE',
  UNREACHABLE = 'STATUS_UNREACHABLE',
  BUSY = 'STATUS_BUSY',
}

export type IOrderDispatchLogReason =
  | OrderDispatchLogReasons.TAGS
  | OrderDispatchLogReasons.ORDER
  | OrderDispatchLogReasons.STATUS
  | OrderDispatchLogReasons.REFUSED
  | OrderDispatchLogReasons.REFUSED_BY_DRIVER_TIMEOUT
  | OrderDispatchLogReasons.REFUSED_BY_TIMEOUT
  | OrderDispatchLogReasons.RATING
  | OrderDispatchLogReasons.DISTANCE
  | OrderDispatchLogReasons.BUSY
  | OrderDispatchLogReasons.UNREACHABLE;

export interface IOrderValidResponse {
  error: boolean;
  errorMessage: string;
  errorReason: string;
  id: string;
  result: IOrder | Partial<PhoneAdvisorOrder>;
}
