import { ITag } from '@core/models/tag.model';

export const sortAttributeTags = (items: ITag[], orderList: string[]) => {
  let returnItems: ITag[] = [];

  for (const order of orderList) {
    const ordered = [];
    for (const item of items) {
      if (item.id === order) {
        ordered.push(item);
      }
    }
    returnItems = returnItems.concat(ordered);
  }
  return returnItems;
};
