export enum BILLING_SOFTWARE {
  COMEOL = 'Comeol',
}

export const BILLING_STATUS = {
  NOT_VALIDATED: 'NOT_VALIDATED',
  VALIDATED: 'VALIDATED',
  TRANSMITTED: 'TRANSMITTED',
  TRANSMIT_ERROR: 'TRANSMIT_ERROR',
} as const;

export type BillingStatusType = keyof typeof BILLING_STATUS;
