import { inject, Injectable } from '@angular/core';
import { TagTypeName } from '@core/constant/tag.constant';
import { DriverService, UserService } from '@core/services/users';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, switchMap } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';

import { DriverAssignmentInfo } from './driver-assignment-info';
import { getSearchParams } from './getSearchParams';

@Injectable({
  providedIn: 'root',
})
export class DriverAssignmentService {
  private readonly driverService = inject(DriverService);
  private readonly userService = inject(UserService);

  private readonly isLoadingSubject$ = new BehaviorSubject(false);
  public readonly isLoading$ = this.isLoadingSubject$.asObservable();

  private readonly tags$ = new ReplaySubject<TagTypeName[][]>(1);
  private readonly reload$ = new ReplaySubject<void>();

  public readonly drivers$: Observable<DriverAssignmentInfo[]> = combineLatest([
    this.tags$.pipe(distinctUntilChanged(isEqual), debounceTime(1000)),
    this.reload$,
  ]).pipe(
    delay(0),
    tap(() => this.isLoadingSubject$.next(true)),
    switchMap(([tags]) =>
      this.driverService.getAllDriversStatuses(getSearchParams(this.userService.getCurrentUserInfo().fleet.id, tags))
    ),
    map(res => res.content),
    map(drivers => drivers.filter(driver => !!driver.sessionStatus)),
    map(drivers => drivers.map(driver => new DriverAssignmentInfo(driver))),
    tap(() => this.isLoadingSubject$.next(false)),
    shareReplay({ refCount: true, bufferSize: 1 })
  ) as Observable<DriverAssignmentInfo[]>;

  constructor() {
    this.reload();
  }

  public setTags(tags: TagTypeName[][]): void {
    this.tags$.next(tags);
  }

  public reload(): void {
    this.reload$.next();
  }
}
