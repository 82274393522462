import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderStatusType } from '@core/constant/order-status.constant';

@Component({
  selector: 'azz-change-remove-driver',
  templateUrl: './change-remove-driver.component.html',
  styleUrls: ['./change-remove-driver.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRemoveDriverComponent {
  @Output() removeDriver$ = new EventEmitter<void>();
  @Output() changeDriver$ = new EventEmitter<void>();
  public isSuitableStatus: boolean;
  @Input() set orderStatus(status: OrderStatusType) {
    this.isSuitableStatus = status === 'CONFIRMED' || status === 'AT_DEPARTURE_ADDRESS';
  }

  public removeDriver(): void {
    this.removeDriver$.emit();
  }
  public changeDriver(): void {
    this.changeDriver$.emit();
  }
}
