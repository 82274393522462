<div class="row poi-table">
  <div class="col-md-12">
    <div class="azz-table-wrapper">
      <table class="table table-hover azz-table">
        <thead>
          <tr class="d-table-row">
            <th class="col-4">{{ 'POI_LABEL' | translate }}</th>
            <th class="col-4">{{ 'POI_SERVICE_LABEL_COL_HEADER' | translate }}</th>
            <th class="col-4">{{ 'ACTIONS' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="poiData?.content?.length > 0">
          <!-- IE scrolling fix -->
          <tr class="azz-clickable" *ngFor="let poi of poiData?.content" (click)="onPoiClick($event, poi)">
            <td>{{ poi?.label }}</td>
            <td>
              <span *ngIf="poi?.serviceLevel">{{ 'POI_SERVICE_LAYER_' + poi?.serviceLevel | translate }}</span>
            </td>
            <td>
              <div class="poi-table__actions-group" *ngIf="hasRestrictionToShowG7Poi && !poi.g7">
                <button
                  class="azz-circle-btn azz-circle-btn--edit poi-table__edit-btn"
                  (click)="onUpdatePoi(poi?.id)"
                ></button>
                <button class="azz-circle-btn azz-circle-btn--delete" (click)="onDeletePoi(poi)"></button>
              </div>
              <div class="poi-table__actions-group" *ngIf="!hasRestrictionToShowG7Poi">
                <button
                  class="azz-circle-btn azz-circle-btn--edit poi-table__edit-btn"
                  (click)="onUpdatePoi(poi?.id)"
                ></button>
                <button class="azz-circle-btn azz-circle-btn--delete" (click)="onDeletePoi(poi)"></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<azz-table-no-matches [azzVisible]="!poiData?.content?.length">
  <ng-content></ng-content>
</azz-table-no-matches>
