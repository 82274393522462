import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IPaginationState, ISortingState } from '../../../models/common.model';
import { ICustomer, ICustomersData } from '../../../models/customer.model';
import { SortHelper } from '../../../utils/sort-helper';

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersTableComponent implements OnInit {
  @Input() customersData: ICustomersData;
  @Input() sorting: ISortingState;
  @Output() updatePagination$ = new EventEmitter<Partial<IPaginationState>>();
  @Output() updateSorting$ = new EventEmitter<Partial<ISortingState>>();
  public sortHelper: SortHelper;

  ngOnInit() {
    this.initData();
  }

  public trackByFunc(index: number, item: ICustomer): number {
    return item?.id || null;
  }

  public prevPage(): void {
    this.updatePagination$.emit({ page: this.getCurrentPage() - 1 });
  }

  public nextPage(): void {
    this.updatePagination$.emit({ page: this.getCurrentPage() + 1 });
  }

  public isPrevDisabled(): boolean {
    return !this.customersData || this.customersData?.first;
  }

  public isNextDisabled(): boolean {
    return !this.customersData || this.customersData?.last;
  }

  private initData(): void {
    this.sortHelper = new SortHelper(this.sorting.sort, this.sorting.direction, () =>
      this.updateSorting$.emit({
        sort: this.sortHelper.getColumns(),
        direction: this.sortHelper.getDirection(),
      })
    );
  }

  private getCurrentPage(): number {
    return this.customersData?.number || 0;
  }

  // public sortNumber(fieldToSort: string) {
  //   return (a, b) => a[fieldToSort] - b[fieldToSort];
  // }
}
