import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./login/auth-login.module').then(m => m.AuthLoginModule),
      },
      {
        path: 'forgot',
        loadChildren: () => import('./forgot/auth-forgot.module').then(m => m.AuthForgotModule),
      },
      {
        path: 'new-password',
        loadChildren: () => import('./new-password/new-password.module').then(m => m.NewPasswordModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
