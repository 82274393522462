<div class="row azz-info-blocks-row azz-last sessions">
  <div class="col-12">
    <div class="azz-info-block pragmatic azz-fleet-tchat">
      <div class="azz-header" *ngIf="sessionsData?.data?.length">
        {{ 'FT_SESSIONS_HEADER' | translate: { totalSessions: pageHistory?.getItemsCount() } }}
      </div>
      <form class="row">
        <div class="form-group col-md-3">
          <azz-dropdown
            azzId="select-driver"
            [azzLabel]="'FO_SELECT_DRIVER_LABEL' | translate"
            azzOptionFormat="firstName lastName"
            [azzPlaceholder]="'FO_SELECT_DRIVER_LABEL' | translate"
            [azzDataRequest]="loadDriversOuterContext()"
            [inputCurrentValue]="filterData?.driver"
            (azzSelect)="onDriverSelect($event)"
            defaultInputValue="CORE_SHOW_ALL_OPT"
            [azzNullAsAll]="true"
            [azzAllOptionsLabel]="'CORE_SHOW_ALL_OPT' | translate"
            [isPartnerRemovingIsPossible]="true"
          >
          </azz-dropdown>
        </div>
        <div class="form-group col-md-3">
          <label for="start-date" class="azz-transparent-input-label">{{ 'START_DATE' | translate }}</label>
          <div class="sessions__date-filter">
            <input
              id="start-date"
              name="date_start"
              class="form-control azz-transparent-input"
              autocomplete="off"
              [(ngModel)]="filterData.startDate"
              (input)="onSelectStartDate()"
              [owlDateTimeTrigger]="dt1"
              [owlDateTime]="dt1"
              [max]="maxDate"
            />
            <owl-date-time
              (afterPickerClosed)="onSelectStartDate()"
              [pickerType]="'calendar'"
              #dt1
              [firstDayOfWeek]="1"
            />
            <div
              *ngIf="filterData?.startDate"
              class="azz-delete azz-delete--not-shrink sessions__date-reset"
              (click)="onClearStartDate()"
            ></div>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="end-date" class="azz-transparent-input-label">{{ 'FT_FINISH_DATE' | translate }}</label>
          <div class="sessions__date-filter">
            <input
              id="end-date"
              name="end_date"
              class="form-control azz-transparent-input"
              autocomplete="off"
              [(ngModel)]="filterData.endDate"
              (input)="onSelectEndDate()"
              [owlDateTimeTrigger]="dt2"
              [owlDateTime]="dt2"
              [max]="maxDate"
            />
            <owl-date-time
              (afterPickerClosed)="onSelectEndDate()"
              [pickerType]="'calendar'"
              [firstDayOfWeek]="1"
              #dt2
            />
            <div
              *ngIf="filterData?.endDate"
              class="azz-delete azz-delete--not-shrink sessions__date-reset"
              (click)="onClearEndDate()"
            ></div>
          </div>
        </div>
      </form>
      <azz-loading-block [isLoading]="loadingIndicator">
        <div class="azz-table-wrapper">
          <table class="table table-hover azz-table">
            <thead>
              <tr class="d-table-row">
                <th *ngIf="!isSortingByDriverAvailable" class="col-4">
                  {{ 'FLEET_SESSIONS_COLUMN_DRIVER_NAME' | translate }}
                </th>
                <th
                  *ngIf="isSortingByDriverAvailable"
                  class="col-4"
                  (click)="sortingHelper.sortColumns(['driverName'])"
                >
                  <span
                    class="arrow"
                    *ngIf="sortingHelper.isSortedColumn(['driverName'])"
                    [azzArrow]="filterData?.direction"
                  >
                  </span>
                  {{ 'FLEET_SESSIONS_COLUMN_DRIVER_NAME' | translate }}
                </th>
                <th class="col-4" (click)="sortingHelper.sortColumns(['startTime'])">
                  <span
                    class="arrow"
                    *ngIf="sortingHelper.isSortedColumn(['startTime'])"
                    [azzArrow]="filterData?.direction"
                  >
                  </span>
                  {{ 'START_DATE' | translate }}
                </th>
                <th class="col-4" (click)="sortingHelper.sortColumns(['finishTime'])">
                  <span
                    class="arrow"
                    *ngIf="sortingHelper.isSortedColumn(['finishTime'])"
                    [azzArrow]="filterData?.direction"
                  >
                  </span>
                  {{ 'FT_FINISH_DATE' | translate }}
                </th>
              </tr>
            </thead>
            <tbody *ngIf="sessionsData?.data?.length > 0">
              <!-- IE scrolling fix -->
              <tr
                class="azz-clickable"
                *ngFor="let session of sessionsData?.data; trackBy: trackByFunc"
                [routerLink]="[session.id]"
              >
                <td>{{ session.driverFirstName + ' ' + session.driverLastName }}</td>
                <td>{{ session.startTime | date: 'dd.MM.yyyy HH:mm' }}</td>
                <td>{{ session.finishTime | date: 'dd.MM.yyyy HH:mm' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <azz-table-no-matches [azzVisible]="!sessionsData?.data?.length"></azz-table-no-matches>
        <azz-pagination
          [prevDisabled]="isPrevDisabled()"
          [nextDisabled]="isNextDisabled()"
          (goToPrevPage)="prevPage()"
          (goToNextPage)="nextPage()"
        >
        </azz-pagination>
      </azz-loading-block>
    </div>
  </div>
</div>
