import { CommonModule } from '@angular/common';
import { Directive, NgModule } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { azzFrLettersAndNumsValidator } from '../../core/utils/validators';

@Directive({
  selector: '[azzOnlyLettersNumbers]',
  providers: [{ provide: NG_VALIDATORS, useExisting: LettersNumbersValidatorDirective, multi: true }],
})
export class LettersNumbersValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return azzFrLettersAndNumsValidator(control);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [LettersNumbersValidatorDirective],
  exports: [LettersNumbersValidatorDirective],
})
export class LettersNumbersValidatorModule {}
