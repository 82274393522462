import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { DialogDirective } from '@common/components/dialog/dialog.component';
import { OrderType } from '@core/constant';
import { ICustomer, INewCustomer } from '@core/models/customer.model';
import { ITag } from '@core/models/tag.model';
import { defineOrderType } from '@core/modules/nouvelle-commande/components/order-pre-save-dialog/order-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'azz-order-pre-save-dialog',
  templateUrl: './order-pre-save.dialog.component.html',
  styleUrls: ['./order-pre-save-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPreSaveDialogComponent extends DialogDirective implements OnChanges {
  @Input() isPassengerAnonymous: boolean;
  @Input() passengerName: string;
  @Input() distance: number;
  @Input() duration: number;
  @Input() addresses: { appointmentAddress: any; destinationAddress: any };
  @Input() customer: ICustomer;
  @Input() newCustomer: INewCustomer;
  @Input() customerType: 'new' | 'old';
  @Input() estimatedPrice: number;
  @Input() estimatedPriceLoading: boolean;
  @Input() isEstimatedPriceShown: boolean;
  @Input() selectedCustomerPhoneNumber: string;
  @Input() tags: ITag[];
  @Input() public declare closeValue: string;
  @Input() paymentType: { id: string; value: string };
  @ViewChild('content') public declare dialogContent: ElementRef;
  public orderType: OrderType;

  constructor(dialogService: NgbModal) {
    super(dialogService);
  }

  @Input() orderDate: string;
  @Input() orderTime: string;

  ngOnChanges() {
    this.setContent(this.dialogContent);
    this.orderType = defineOrderType({ time: this.orderTime, date: this.orderDate });
  }

  public isImmediate(): boolean {
    return this.orderType === 'ORDER';
  }
}
