import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ORDER_SERVICE_TYPE_POI_SERVICE_LEVEL_ARRAY } from '../../../../constant/poi.constant';
import { ORDER_TAG_TYPE, SERVICE_TYPE_TAG, VEHICLE_TYPE_TAG } from '../../../../constant/tag.constant';

@Component({
  selector: 'azz-order-service-vehicle-type',
  templateUrl: './order-service-vehicle-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderServiceVehicleTypeComponent {
  @Input() form: FormGroup;
  @Input() departureAddress: any;
  // Feature<Point>;
  @Input() arrivalAddress: any;
  // Feature<Point>;
  public readonly uiTypes = {
    service: ORDER_TAG_TYPE.service,
    vehicle: ORDER_TAG_TYPE.vehicle,
  };

  public isServiceTypeIncompatibleWithPoi(option: { id: string; value: string }): boolean {
    const isDepartureOfPOIType = this.departureAddress.coordinates !== null;
    const isArrivalOfPOIType = this.arrivalAddress.cooridnates !== null;

    if (this.departureAddress && isDepartureOfPOIType) {
      return !ORDER_SERVICE_TYPE_POI_SERVICE_LEVEL_ARRAY[option.id].includes(
        this.departureAddress.properties.serviceLevel
      );
    } else if (this.arrivalAddress && isArrivalOfPOIType) {
      return !ORDER_SERVICE_TYPE_POI_SERVICE_LEVEL_ARRAY[option.id].includes(
        this.arrivalAddress.properties.serviceLevel
      );
    } else {
      return false;
    }
  }

  public isServiceTypeTagDisabled(option: { id: string; value: string }): boolean {
    const vehicleTypeValue = this.form.controls.vehicleType.value;
    return (
      option.id === SERVICE_TYPE_TAG.MV &&
      (vehicleTypeValue.id === VEHICLE_TYPE_TAG.SEDAN || vehicleTypeValue.id === VEHICLE_TYPE_TAG.BR)
    );
  }

  public isVehicleTypeTagDisabled(option: { id: string; value: string }): boolean {
    const serviceTypeValue = this.form.controls.serviceType.value;
    return (
      (option.id === VEHICLE_TYPE_TAG.SEDAN || option.id === VEHICLE_TYPE_TAG.BR) &&
      serviceTypeValue.id === SERVICE_TYPE_TAG.MV
    );
  }

  public compareFn(c1: { id: string; value: string | null }, c2: { id: string; value: string | null }): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
