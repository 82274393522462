import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { BILLING_STATUS, ORDER_STATUS } from '@core/constant';
import { PhoneAdvisorOrder } from '@core/models';
import { UtilService } from '@core/services/common';
import { boundMethod } from 'autobind-decorator';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'azz-custom-order-full-order-data',
  templateUrl: './full-order-data.component.html',
  styleUrls: ['./full-order-data.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class FullOrderDataComponent implements OnInit, OnChanges {
  @Input() public order: PhoneAdvisorOrder;
  @Input() public paymentTypeId: string;
  @Input() public serviceTypeId: string;
  @Input() public vehicleTypeId: string;
  @Input() public canChangeDispatchTime: boolean;
  @Input() public tagIdsExceptServiceVehiclePaymentType: string[];
  @Input() public isFacturation = false;
  @Input() public priceChangeLoadingIndicator = false;
  @Input() public dispatchTimeChangeLoadingIndicator = false;
  @Input() isUpdateDispatchTimeLoading = false;
  @Output() public priceChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public dispatchTimeChanged: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('dispatchTimeInput') dispatchTimeInput: ElementRef;
  public statuses = ORDER_STATUS;
  public billingStatuses = BILLING_STATUS;
  public orderPriceForm: FormGroup;

  public showOnlyHHmm: boolean;
  public showCrossIcon = false;
  public isDispatchDateInThePast = false;
  public dispatchDate: string;
  public orderDispatchBeforeMinutes: string;
  public dispatchTimeForm: FormGroup;
  private priceWasChaged = false;

  constructor(
    private readonly onDestroy$: NgOnDestroyService,
    private readonly utilService: UtilService
  ) {}

  private get priceControl(): AbstractControl {
    return this.orderPriceForm?.get('price');
  }

  @boundMethod
  public noSelectedAddressValidator(): ValidationErrors | null {
    return this.isDispatchDateInThePast ? { dispatchTimeInPast: true } : null;
  }

  public checkDateAndTime(dispatchDate: string) {
    const orderDate = dayjs(this.order.date);
    this.showOnlyHHmm = dayjs(dispatchDate).isSame(orderDate, 'day');
  }

  public ngOnInit(): void {
    this.orderDispatchBeforeMinutes = this.order?.orderDispatchBeforeMinutes + ' min';
    this.dispatchDate = this.order?.dispatchDate;
    this.checkDateAndTime(this.dispatchDate);

    this.dispatchTimeForm = new FormGroup({
      orderDispatchBeforeMinutes: new FormControl({ value: this.orderDispatchBeforeMinutes, disabled: false }, [
        Validators.required,
        Validators.min(1),
        Validators.max(360),
        this.noSelectedAddressValidator,
      ]),
    });

    if (!this.isFacturation) {
      return;
    }

    const price = this.order?.price ?? '';
    this.orderPriceForm = new FormGroup({
      price: new FormControl(price, []),
    });

    this.priceControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => (this.priceWasChaged = true)),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  public inputValidator(event: any) {
    if (event.target.value) {
      this.showCrossIcon = true;
    }

    const pattern = this.utilService.onlyNumbersRegexp;
    if (!pattern.test(event.target.value)) {
      const newVal = event.target.value.replace(/[^0-9]/g, '');
      this.dispatchTimeForm.controls.orderDispatchBeforeMinutes.patchValue(newVal);
    }
    if (event.target.value > 360 || isNaN(event.target.value)) {
      return;
    }
    this.dispatchDate = dayjs(this.order.date).subtract(event.target.value, 'minutes').toString();

    this.checkDateAndTime(this.dispatchDate);
    if (moment() > moment(this.dispatchDate)) {
      this.isDispatchDateInThePast = true;
      this.dispatchTimeForm.get('orderDispatchBeforeMinutes').setErrors({ dispatchTimeInPast: true });
    } else {
      this.isDispatchDateInThePast = false;
    }
  }

  public onPriceChanged(): void {
    if (this.priceWasChaged && (this.order?.price ? Number(this.priceControl.value) !== this.order.price : true)) {
      this.priceChanged.emit(this.priceControl.value);
      this.priceWasChaged = false;
    }
  }

  public onDispatchTimeChanged(): void {
    if (this.dispatchTimeForm?.controls?.orderDispatchBeforeMinutes?.errors) {
      return;
    }

    if (this.noSelectedAddressValidator()) {
      return;
    }

    const { orderDispatchBeforeMinutes: dispatchBeforeControl } = this.dispatchTimeForm.controls;
    if (Number(dispatchBeforeControl.value) === Number(this.order.orderDispatchBeforeMinutes)) {
      dispatchBeforeControl.patchValue(dispatchBeforeControl.value + ' min');
      return;
    }

    if (!dispatchBeforeControl.value?.includes('min')) {
      dispatchBeforeControl.patchValue(dispatchBeforeControl.value + ' min');
    }

    if (dispatchBeforeControl.value?.includes('min')) {
      const mins = dispatchBeforeControl.value.slice(0, dispatchBeforeControl.value.length - 4);
      this.dispatchTimeChangeLoadingIndicator = true;
      this.showCrossIcon = false;
      this.dispatchTimeChanged.emit(mins);
    }
    setTimeout(() => {
      this.showCrossIcon = false;
    }, 100);
  }

  onFocusEvent() {
    this.showCrossIcon = true;
    const dispatchBeforeControl = this.dispatchTimeForm.controls.orderDispatchBeforeMinutes as FormControl<string>;
    if (!dispatchBeforeControl.value.includes('min')) {
      dispatchBeforeControl.patchValue(dispatchBeforeControl.value + ' min');
    }

    if (dispatchBeforeControl.value.includes('min')) {
      dispatchBeforeControl.patchValue(dispatchBeforeControl.value.slice(0, dispatchBeforeControl.value.length - 4));
    }
  }

  public clearDispatchTimeInput() {
    this.dispatchDate = this.order?.dispatchDate;
    this.showCrossIcon = false;
    this.dispatchTimeChangeLoadingIndicator = false;

    this.dispatchTimeForm
      .get('orderDispatchBeforeMinutes')
      ?.reset({ value: this.order.orderDispatchBeforeMinutes, disabled: false });
    this.dispatchTimeInput.nativeElement.blur();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.canChangeDispatchTime = changes?.canChangeDispatchTime?.currentValue;
      if (this.dispatchTimeForm) {
        const dispatchBeforeControl = this.dispatchTimeForm.controls[
          'orderDispatchBeforeMinutes'
        ] as FormControl<string>;
        if (!this.canChangeDispatchTime) {
          dispatchBeforeControl.disable();
        } else {
          dispatchBeforeControl.enable();
        }
      }
    }
    if (typeof changes.priceChangeLoadingIndicator?.currentValue === 'boolean' && this.priceControl) {
      const controlAvailability = changes.priceChangeLoadingIndicator?.currentValue === true ? 'disable' : 'enable';
      this.priceControl[controlAvailability]();
    }
  }
}
