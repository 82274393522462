import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as UserNotificationsReducer from '../reducers/user-notifications.reducer';

const appUserNotifications = createFeatureSelector<UserNotificationsReducer.State>(
  UserNotificationsReducer.userNotificationsFeatureKey
);

export const userNotificationsData = createSelector(
  appUserNotifications,
  (state: UserNotificationsReducer.State) => state.notificationsData
);
