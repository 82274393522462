/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * object with data in response of exclusions count request
 */
export interface ExclusionCountResponseWebApiModel {
  /**
   * Number of exclusions available for PA in current fleet
   */
  count: number;
}
