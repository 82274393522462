/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ConfirmPopupComponent } from '@common/components/confirm-popup/confirm-popup.component';
import { NamedOption } from '@common/types';
import { ORDER_STATUS, PACompetencyLevelName, ROLE, RoleName } from '@core/constant';
import {
  DRIVER_SESSION_STATUS,
  DRIVER_SESSION_STATUS_ORDER,
  DriverSessionStatusType,
} from '@core/constant/driver-session.constant';
import {
  IDriverFullSession,
  IDriverFullSessionData,
  ISessionsCountByStatus,
  ISessionsCountByStatusParams,
} from '@core/models/driver-session.model';
import { ICurrentUserInfo, IPhoneAdvisorUserInfo } from '@core/models/user-info.model';
import { CustomPopupService, PaginationService } from '@core/services/common';
import { PageHistory } from '@core/services/common/pagination.service';
import { OrderService } from '@core/services/orders';
import { CanUserFreeDriverDuringSessionService } from '@core/services/sessions';
import { SessionService } from '@core/services/sessions/session.service';
import { UserService } from '@core/services/users/user.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from '@services/root.service';
import { partition } from 'lodash';
import { isNull } from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, from, interval, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, takeUntil } from 'rxjs/operators';

import { driversRouteToken, ordersRouteToken } from './injection-tokens';
import { SessionsMapComponent } from './sessions-map/sessions-map.component';

@Component({
  selector: 'azz-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['sessions.component.less'],
  providers: [CanUserFreeDriverDuringSessionService],
})
export class SessionsComponent implements OnInit, OnDestroy {
  protected readonly rootService = inject(RootService);

  @ViewChild(SessionsMapComponent) sessionMapComponent: SessionsMapComponent;

  public readonly routes = {
    drivers: inject(driversRouteToken),
    orders: inject(ordersRouteToken),
  } as const;

  public REQUEST_DELAY_MS = 300;
  public availableData: { statuses: NamedOption<DriverSessionStatusType | ''>[] };
  public filterData: { status: DriverSessionStatusType | ''; autorefresh: boolean; timeout: number };
  public sessionsData: IDriverFullSessionData;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public pageHistory: PageHistory;
  public userRole: RoleName;
  public PACompetencyLevel: PACompetencyLevelName = null;
  public tableWatcher$ = new Subject();
  public mapWatcher$ = new Subject();
  public countWatcher$ = new Subject();
  public currentSession: IDriverFullSession;
  public sessionTimeouts: NamedOption<number>[];
  public lastTimeUpdated;
  public isMapLoading$ = new BehaviorSubject<boolean>(false);
  public sessionsCountArr: ISessionsCountByStatus[] = [];
  public totalSessionsCount: number;
  public userInfo: ICurrentUserInfo;

  private fleetId: number;
  private readonly intervalDestroy$ = new Subject<void>();
  private readonly destroyRef = inject(DestroyRef);
  private readonly canUserFreeDriver = inject(CanUserFreeDriverDuringSessionService);

  constructor(
    private readonly driverSessionService: SessionService,
    private readonly paginationService: PaginationService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly userService: UserService,
    private readonly customPopupService: CustomPopupService,
    private readonly orderService: OrderService,
    private readonly store: Store
  ) {
    this.initData();
  }

  ngOnInit() {
    this.init();
    this.initLastUpdateWatcher();
    this.onAutoRefresh();
  }

  public getSelectedSession() {
    this.driverSessionService.selectedSession$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IDriverFullSession) => {
        this.currentSession = res;
      });
  }

  public onAutoRefresh() {
    this.intervalDestroy$.next(null);
    if (this.filterData.autorefresh) {
      interval(this.filterData.timeout)
        .pipe(takeUntil(this.intervalDestroy$))
        .subscribe(() => {
          this.tableWatcher$.next({ pageToken: this.pageHistory.getCurrent() });
          this.mapWatcher$.next(null);
          this.countWatcher$.next(null);
          this.driverSessionService.lastTimeUpdatedCoords$.next(null);
        });
    }
  }

  public onFilterByStatus(): void {
    this.pageHistory.reset();
    this.tableWatcher$.next(null);
    this.mapWatcher$.next(null);
    this.countWatcher$.next(null);
  }

  public closeSessionPopup() {
    if (this.sessionMapComponent && this.sessionMapComponent.dialog) {
      this.sessionMapComponent.dialog.closeDialog();
    }
  }

  public prevPage(): void {
    const pageToken = this.pageHistory.getPrevious();
    const cb = () => this.pageHistory.pop();
    this.tableWatcher$.next({ pageToken, cb });
  }

  public nextPage(): void {
    const pageToken = this.sessionsData.nextPageToken;
    const cb = () => this.pageHistory.push(pageToken);
    this.tableWatcher$.next({ pageToken, cb });
  }

  public isPrevDisabled(): boolean {
    return this.pageHistory.isEmpty();
  }

  public isNextDisabled(): boolean {
    return !this.sessionsData || !this.sessionsData.nextPageToken;
  }

  public isMoreThanOnePage(): boolean {
    return !this.isPrevDisabled() || !this.isNextDisabled();
  }

  public formatSpeed(speed: any): number {
    return parseFloat(((speed * 3600) / 1000).toFixed(2));
  }

  public deleteSession(session?: IDriverFullSession): void {
    const driverId = session ? session.driverId : this.currentSession.driverId;
    const confirmPopupResult = this.customPopupService.openConfirmPopup(ConfirmPopupComponent, {
      header: '',
      message: {
        message: 'FINISH_DRIVER_SESSION_STATUS',
      },
      submitBtn: 'FLEET_POPUP_CLOSE_SESSION_BUTTON',
      isButtonsCentered: true,
    }).result;

    from(confirmPopupResult)
      .pipe(
        switchMap(() => this.driverSessionService.deleteSession(driverId)),
        catchError((updateError: HttpErrorResponse) => {
          this.rootService.alerts.error(updateError.error.message);
          return of(null);
        }),
        finalize(() => this.tableWatcher$.next({ pageToken: this.pageHistory.getCurrent() })),
        finalize(() => this.mapWatcher$.next(null)),
        finalize(() => this.countWatcher$.next(null)),
        finalize(() => this.closeSessionPopup())
      )
      .subscribe();
  }

  public tryToFreeDriverFromSession(session?: IDriverFullSession): void {
    const driverId = session ? session.driverId : this.currentSession.driverId;
    const modalData = { header: '', message: '' };
    if (session.inQueueOrderId) {
      const case2: string[] = [
        ORDER_STATUS.APPROACH,
        ORDER_STATUS.CONFIRMED,
        ORDER_STATUS.AT_DEPARTURE_ADDRESS,
        ORDER_STATUS.TOWARDS_DESTINATION,
      ];
      const isCase2 = case2.includes(session.orderStatus);
      console.log('isCase2', isCase2, session.orderStatus);
      if (isCase2) {
        modalData.header = 'CHANGE_DRIVER_SESSION_STATUS_HEADER_CASE_2';
        modalData.message = 'CHANGE_DRIVER_SESSION_STATUS_MESSAGE_CASE_2';
      } else {
        modalData.header = 'CHANGE_DRIVER_SESSION_STATUS_HEADER_CASE_1';
        modalData.message = 'CHANGE_DRIVER_SESSION_STATUS_MESSAGE_CASE_1';
      }
    } else {
      modalData.message = 'CHANGE_DRIVER_SESSION_STATUS';
    }

    const confirmPopupResult = this.customPopupService.openConfirmPopup(ConfirmPopupComponent, {
      header: modalData.header,
      message: {
        message: modalData.message,
      },
      submitBtn: 'CHANGE',
      isButtonsCentered: true,
    }).result;

    from(confirmPopupResult)
      .pipe(
        switchMap(() => this.driverSessionService.changeDriverSessionFromOccupiedToFree(driverId)),
        catchError((updateError: HttpErrorResponse) => {
          this.rootService.alerts.error(updateError.error.message);
          return of(null);
        }),
        finalize(() => this.tableWatcher$.next({ pageToken: this.pageHistory.getCurrent() })),
        finalize(() => this.mapWatcher$.next(null)),
        finalize(() => this.countWatcher$.next(null)),
        finalize(() => this.closeSessionPopup())
      )
      .subscribe();
  }

  public onAutoRefreshBtnClick(): void {
    this.tableWatcher$.next({ pageToken: this.pageHistory.getCurrent() });
    this.mapWatcher$.next(null);
    this.countWatcher$.next(null);
  }

  public trackByFunc(index: number, item: IDriverFullSession): number {
    if (!item || !item.driverSession || isNull(item.driverSession)) {
      return null;
    }
    return item.driverSession.id;
  }

  public onSessionSelect(evt, session: IDriverFullSession) {
    if (evt.target.classList.contains('no-general-navigation')) {
      evt.preventDefault();
      this.navigateToDriverDetailsPage(session.driverId);
    } else {
      this.currentSession = session;
      this.driverSessionService.selectedSession$.next(session);
    }
  }

  public navigateToDriverDetailsPage(driverId: number) {
    if (driverId) {
      this.router.navigate([this.routes.drivers, driverId]);
    }
  }

  ngOnDestroy() {
    this.intervalDestroy$.next(null);
  }

  private initLastUpdateWatcher() {
    this.driverSessionService.lastTimeUpdatedCoords$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => (this.lastTimeUpdated = moment()));
  }

  private setTotalSessionsCount(arr: ISessionsCountByStatus[]): void {
    this.sessionsCountArr = [];
    this.totalSessionsCount = arr.reduce((acc: number, curr: ISessionsCountByStatus, i: number) => {
      this.sessionsCountArr[i] = { ...arr[i], order: DRIVER_SESSION_STATUS_ORDER[curr.status] };
      return acc + curr.count;
    }, 0);
    const sortFactory = (prop: string) => (a, b) => a[prop] - b[prop];
    this.sessionsCountArr.sort(sortFactory('order'));
  }

  private loadTableDriverSessions$(pageToken?: string): Observable<IDriverFullSessionData> {
    const params = {
      status: this.filterData.status,
      fleetId: this.fleetId,
      limit: 15,
      pageToken,
    };

    this.isLoading$.next(true);
    return this.driverSessionService.searchActive(params).pipe(
      finalize(() => this.isLoading$.next(false))
      // takeUntilDestroyed(this.destroyRef),
    );
  }

  private loadMapDriverSessions$(): Observable<IDriverFullSessionData> {
    const params = { fleetId: this.fleetId, status: this.filterData.status, limit: 100 };
    this.isMapLoading$.next(true);
    return this.driverSessionService.searchActive(params).pipe(
      finalize(() => this.isMapLoading$.next(false))
      // takeUntilDestroyed(this.destroyRef),
    );
  }

  private loadSessionsCount$(): Observable<ISessionsCountByStatus[]> {
    return this.driverSessionService
      .getSessionCountByStatus(this.generateSessionsCountParams())
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  private generateSessionsCountParams(): ISessionsCountByStatusParams {
    return {
      statuses: Object.keys(DRIVER_SESSION_STATUS).filter(
        status =>
          status !== DRIVER_SESSION_STATUS.CONNECTED &&
          status !== DRIVER_SESSION_STATUS.IN_QUEUE &&
          status !== DRIVER_SESSION_STATUS.BUSY
      ),
    };
  }

  private initDeleteSessionWatcher() {
    this.driverSessionService.deleteSession$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.deleteSession());
  }

  private initSessionTimeouts(): void {
    this.sessionTimeouts = [
      { name: '30 ' + this.translate.instant('SECONDS_LABEL'), value: 30 * 1000 },
      { name: '1 ' + this.translate.instant('MINUTE_lABEL'), value: 60 * 1000 },
      { name: '2 ' + this.translate.instant('MINUTES_lABEL'), value: 2 * 60 * 1000 },
      { name: '3 ' + this.translate.instant('MINUTES_lABEL'), value: 3 * 60 * 1000 },
    ];
    this.filterData.timeout = this.sessionTimeouts[0].value;
  }

  private initData(): void {
    this.availableData = { statuses: this.initStatuses() };
    this.filterData = { status: '', autorefresh: true, timeout: 0 };
    this.pageHistory = this.paginationService.createPageHistory();
  }

  private initStatuses(): NamedOption<DriverSessionStatusType | ''>[] {
    return [
      {
        name: this.translate.instant('FLEET_SESSIONS_SHOW_ALL_OPT') as string,
        value: '',
      },
      {
        name: this.translate.instant('CORE_DRIVER_SESSION_STATUS_CONNECTED') as string,
        value: 'CONNECTED',
      },
      {
        name: this.translate.instant('CORE_DRIVER_SESSION_STATUS_FREE') as string,
        value: 'FREE',
      },
      {
        name: this.translate.instant('CORE_DRIVER_SESSION_STATUS_OCCUPIED') as string,
        value: 'OCCUPIED',
      },
      {
        name: this.translate.instant('CORE_DRIVER_SESSION_STATUS_UNREACHABLE') as string,
        value: 'UNREACHABLE',
      },
    ];
  }

  private init(): void {
    this.userInfo = this.userService.getCurrentUserInfo();
    this.PACompetencyLevel = this.userService.getCurrentUserInfo<IPhoneAdvisorUserInfo>().user.competenceLevel;

    this.fleetId = this.userInfo?.fleet?.id;
    this.userRole = this.userInfo?.user?.roles[0];

    this.driverSessionService.setUrl(this.userRole);

    this.initTableWatcher();
    this.initMapWatcher();
    this.initSessionTimeouts();
    this.getSelectedSession();
    this.initCountWatcher();
    this.initDeleteSessionWatcher();
  }

  private loadOrderData(session: IDriverFullSession) {
    const api =
      this.userRole == ROLE.PHONE_ADVISOR
        ? this.orderService.getPaOrderById(session.currentOrderId)
        : this.orderService.getOrderById(session.currentOrderId);

    api.pipe(catchError(err => throwError(err))).subscribe(order => {
      const canCancel = this.canUserFreeDriver.bySession(session); // RNR7-5006
      return this.sessionsData.data.push({ ...session, canCancel, orderStatus: order.status });
    });
  }

  private initTableWatcher(): void {
    let pageToken;
    let cb;
    this.tableWatcher$
      .pipe(
        // @ts-ignore
        debounceTime(this.REQUEST_DELAY_MS),
        switchMap((data: { pageToken: string; cb: () => void }) => {
          pageToken = data && data.pageToken ? data.pageToken : null;
          cb = data && data.cb ? data.cb : null;
          return this.loadTableDriverSessions$(pageToken);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        response => {
          this.sessionsData = { data: [], nextPageToken: response.nextPageToken };
          const sessions = response.data;

          const [maybeCancellable, nonCancellable] = partition(sessions, session =>
            this.canUserFreeDriver.bySession(session)
          );
          nonCancellable.map(session => this.sessionsData.data.push({ ...session, canCancel: false }));
          maybeCancellable.map(session => this.loadOrderData(session));

          this.driverSessionService.lastTimeUpdatedCoords$.next(null);
          this.pageHistory.addKnownItemsByToken(pageToken, response.data ? response.data.length : 0);

          if (cb) {
            cb();
          }
        },
        () => (this.sessionsData = null)
      );
    this.tableWatcher$.next(null);
  }

  private initMapWatcher(): void {
    this.mapWatcher$
      .pipe(
        switchMap(() => this.loadMapDriverSessions$()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res: IDriverFullSessionData) => {
        this.driverSessionService.sessions$.next(res);
      });
    this.mapWatcher$.next(null);
  }

  private initCountWatcher(): void {
    this.countWatcher$
      .pipe(
        switchMap(() => this.loadSessionsCount$()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res: ISessionsCountByStatus[]) => this.setTotalSessionsCount(res));
    this.countWatcher$.next(null);
  }
}
