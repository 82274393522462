import { AbstractControl, FormGroup } from '@angular/forms';

const numbersRegexp = /^(|\d+)$/;
const notNumbersRegexp = /\D/;
const MIN_PHONE_NUMBER_LENGTH = 10;
const lettersRegexp = /^[\u00C0-\u017Fa-zA-Z\s'`]+([- ][\u00C0-\u017Fa-zA-Z\s'`]+)*$/;
const frLettersAndNumsRegExp = /^[\u00C0-\u017Fa-zA-Z'`0-9]+([- ][\u00C0-\u017Fa-zA-Z'`0-9]+)*/;
const anyExceptAllSpacesRegExp = /(\s*\S+\s*)+/;
const numbersUpperCaseFrCharsRegExp = /^[A-Z0-9ÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

export const getAnyExceptAllSpacesRegExp = (): RegExp => anyExceptAllSpacesRegExp;

export const getNumbersRegExp = (): RegExp => numbersRegexp;

export const customMatchingPasswords =
  (passwordKey: string, passwordConfirmationKey: string): ((FormGroup) => any) =>
  (group: FormGroup) => {
    const password = group.controls[passwordKey];
    const passwordConfirmation = group.controls[passwordConfirmationKey];

    if (passwordConfirmation.errors && !passwordConfirmation.errors.mismatchedPasswords) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    const error = password.value !== passwordConfirmation.value ? { mismatchedPasswords: true } : null;
    passwordConfirmation.setErrors(error);
  };

export const azzNotNumbers = (control: AbstractControl) => {
  if (!control.value || !control.value.length) {
    return null;
  }

  if (!notNumbersRegexp.test(control.value)) {
    return { azzNotNumbers: true };
  }

  return null;
};

export const azzOnlyNumbers = (control: AbstractControl) => {
  if (!control.value || !control.value.length) {
    return null;
  }

  if (!numbersRegexp.test(control.value)) {
    return { azzOnlyNumbers: true };
  }

  return null;
};

export const anyExceptAllSpacesValidator = (control: AbstractControl) => {
  if (!control.value || !control.value.length) {
    return null;
  }

  if (!anyExceptAllSpacesRegExp.test(control.value)) {
    return { azzAllSpaces: true };
  }

  return null;
};

export const numUpCaseFrCharsValidator = (control: AbstractControl) => {
  if (!control.value || !control.value.length) {
    return null;
  }

  if (!numbersUpperCaseFrCharsRegExp.test(control.value)) {
    return { azzNotNumUpCaseFrChars: true };
  }

  return null;
};

export const azzPhoneNumber = (control: AbstractControl) => {
  if (!control.value || !control.value.length) {
    return null;
  }

  if (notNumbersRegexp.test(control.value) || control.value.length < MIN_PHONE_NUMBER_LENGTH) {
    return { azzPhoneNumber: true };
  }

  return null;
};

export const azzLettersValidator = (control: AbstractControl) => {
  if (!control?.value?.length) {
    return null;
  }

  if (!lettersRegexp.test(control.value)) {
    return { azzNotLetters: true };
  }

  return null;
};

export const azzFrLettersAndNumsValidator = (control: AbstractControl) => {
  if (!control.value || !control.value.length) {
    return null;
  }

  if (!frLettersAndNumsRegExp.test(control.value)) {
    return { azzNotFrLettersAndNums: true };
  }

  return null;
};
