import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from '../actions';

export const newPasswordFeatureKey = 'new-password';

export interface State {
  loading: boolean;
}

const initialState: State = {
  loading: false,
};

const newPasswordReducer = createReducer(
  initialState,
  on(AuthActions.newPassword, () => ({ loading: true })),
  on(AuthActions.newPasswordSuccess, AuthActions.newPasswordError, () => initialState)
);

export const reducer = (state: State | undefined, action: Action) => newPasswordReducer(state, action);
