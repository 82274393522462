import dayjs from 'dayjs';

import { TimeContraintParams } from './dispatch-order.interfaces';

/** @Before the date an order is not active. The order is reserved or 'in advance' and is waiting until that minimal date passes */
export const getPartialEditTimeLimit = ({
  dispatchBeforeMinutes: dispatchBefore,
  updateTimeLimitMinutes: updateTimeLimit,
  orderDateRaw,
}: TimeContraintParams): dayjs.Dayjs => {
  if (!orderDateRaw) {
    console.warn("order date is undefined, dayjs will instantiate current time, be sure it's intentional");
  }
  const orderDate = dayjs(orderDateRaw);

  return orderDate.subtract(dispatchBefore + updateTimeLimit, 'minutes');
};
