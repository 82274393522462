import { OrderFormField } from './types/order-form.type';

// I was unable to disable customerType, otherwise the controls are missing
export const canBeDisabledOrderFormFields: Partial<keyof OrderFormField>[] = [
  'anonymousPassenger',
  'passengerName',
  'order',
  'destinationComment',
  'appointmentComment',
  'paymentType',
  'serviceType',
  'vehicleType',
  'customer',
  'comment',
];
