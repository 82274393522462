import { NgModule } from '@angular/core';
import {
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlMomentDateTimeModule,
} from '@busacca/ng-pick-datetime';

import { MOMENT_JS_FORMATS } from '../../core/constant';

@NgModule({
  imports: [OwlDateTimeModule, OwlMomentDateTimeModule],
  exports: [OwlDateTimeModule, OwlMomentDateTimeModule],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MOMENT_JS_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr' },
  ],
})
export class DatePickerModule {}
