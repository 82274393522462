import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IsSignedInGuard } from '@core/guards';

const routes: Routes = [
  {
    path: 'welcome',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'invitationAlreadyUsed',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  // TODO: remove this after backend refactoring
  {
    path: 'newPassword',
    redirectTo: 'auth/new-password',
  },
  {
    path: 'phoneAdvisor/registration/create',
    redirectTo: 'registration/phone-advisor/create',
  },
  {
    path: 'auth',
    canActivate: [IsSignedInGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/registration/RegistrationRouting').then(m => m.RegistrationRouting),
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/dash/dash.module').then(m => m.DashModule),
  },
];

@NgModule({
  // need to change proxies
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
  exports: [RouterModule],
  // providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class AppRoutingModule {}
