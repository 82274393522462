/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * customer block reason type enum
 */
export type CustomerBlockReasonEnumWebApiModel = 'PROD' | 'INVOICE';

export const CustomerBlockReasonEnumWebApiModel = {
  Prod: 'PROD' as CustomerBlockReasonEnumWebApiModel,
  Invoice: 'INVOICE' as CustomerBlockReasonEnumWebApiModel,
};
