/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * enum for sort direction used in search requests
 */
export type SortDirectionEnumWebApiModel = 'ASC' | 'DESC';

export const SortDirectionEnumWebApiModel = {
  Asc: 'ASC' as SortDirectionEnumWebApiModel,
  Desc: 'DESC' as SortDirectionEnumWebApiModel,
};
