import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IOrder, IOrderData } from '../../../models/order.model';

@Component({
  selector: 'azz-common-orders-table',
  templateUrl: './orders-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonOrdersTableComponent {
  @Input() ordersData: IOrderData;
  @Input() filterData: any;
  @Output() sortColumn$ = new EventEmitter<string>();
  @Output() navigateToOrderDetails$ = new EventEmitter<string>();
  @Output() navigateToDriverDetails$ = new EventEmitter<number>();
  @Output() navigateToCustomerDetails$ = new EventEmitter<number>();

  public sortColumn(value: string) {
    this.sortColumn$.emit(value);
  }

  public isSortedColumn(name: string): boolean {
    return this.filterData.sort === name;
  }

  public trackByFunc(index: number, item: IOrder): string {
    if (!item) {
      return null;
    }

    return item.id;
  }

  public navigateToOrderDetailsPage(event: any, orderId: string): void {
    const noGeneralNavigation = event.target.classList.contains('no-general-navigation');
    if (noGeneralNavigation) {
      event.preventDefault();
    } else {
      this.navigateToOrderDetails$.emit(orderId);
    }
  }

  public navigateToCustomerDetailsPage(customerId: number): void {
    if (!customerId) {
      return;
    }

    this.navigateToCustomerDetails$.emit(customerId);
  }

  public navigateToDriverDetailsPage(driverId: number): void {
    if (!driverId) {
      return;
    }

    this.navigateToDriverDetails$.emit(driverId);
  }
}
