import { FRENCH_PHONE_CODE } from '@dash/modules/phone-advisor/common/constants';

import { clearPhone } from './clearPhone';

export function normalizeNumber(phone: string): string {
  const hasPlusPrefix = phone.length && phone[0] === '+';
  phone = clearPhone(phone);

  if (hasPlusPrefix) {
    phone = '+' + phone;
  }

  if (phone.startsWith('0')) {
    return `${FRENCH_PHONE_CODE}${phone.substring(1)}`;
  }
  if (hasPlusPrefix) {
    return phone;
  }

  return `${FRENCH_PHONE_CODE}${phone}`;
}
