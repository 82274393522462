import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Selector, TypedAction } from '@ngrx/store/src/models';
import { AlertsService } from '@services/alerts.service';
import { catchError, MonoTypeOperatorFunction, Observable, pipe, throwError } from 'rxjs';

export abstract class EffectsBase {
  private readonly alertsService = inject(AlertsService);
  private readonly store = inject(Store);
  protected readonly actions$ = inject(Actions);

  protected alertSuccess(msg: string): void {
    this.alertsService.success(msg);
  }

  protected alertWarn(msg: string): void {
    this.alertsService.warn(msg);
  }

  protected alertErr(msg: string): void {
    this.alertsService.error(msg);
  }

  protected catchErrorHandler(response: HttpErrorResponse, ...actions: TypedAction<string>[]): Observable<never> {
    actions.forEach(action => {
      this.store.dispatch(action);
    });

    return throwError(() => response);
  }

  protected catchError<T>(...actions: TypedAction<string>[]): MonoTypeOperatorFunction<T> {
    return pipe(catchError(err => this.catchErrorHandler(err, ...actions)));
  }

  protected selectFromStore<TResult = object>(action: Selector<object, TResult>): Observable<TResult> {
    return this.store.pipe(select(action));
  }

  protected dispatch(action: TypedAction<string>): void {
    this.store.dispatch(action);
  }
}
