import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { PreRegistrationComponent } from './pre-registration/pre-registration.component';

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule],
  declarations: [PreRegistrationComponent, ConfirmRegistrationComponent, ConfirmEmailComponent],
  exports: [PreRegistrationComponent, ConfirmRegistrationComponent, ConfirmEmailComponent],
})
export class RegistrationModule {}
