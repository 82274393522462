import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateUtilService {
  public readonly DATE_FORMAT = 'DD.MM.YYYY';

  public getStartOfDay(date): any {
    const result = this.deepObjectCopy(date);
    result.setHours(0, 0, 0, 0);

    return result;
  }

  public getEndOfDay(date): any {
    const result = this.deepObjectCopy(date);

    result.setHours(23, 59, 59, 999);

    return result;
  }

  public deepObjectCopy(obj): any {
    // return JSON.parse(JSON.stringify(obj));
    return cloneDeep(obj);
  }

  public isToday(date): boolean {
    return moment().isSame(date, 'day');
  }

  public formatDate(date): any {
    return moment(date).format(this.DATE_FORMAT);
  }

  public convertFromUTCDate(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  }

  public convertToUTCDate(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }

  public convertToUTCDateString(date: Date): number {
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }

  public getMonthNames(): string[] {
    const result = [];

    for (let month = 0; month < 12; month++) {
      result.push(`CORE_MONTH_${month}`);
    }

    return result;
  }
}
