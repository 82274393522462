import { Injectable } from '@angular/core';
import { AddressCollectionGoogle } from '@core/modules/base-create-order/interfaces/address-collection-google.interface';

import { WindowRef } from './window-ref';

@Injectable({ providedIn: 'root' })
export class UtilService {
  public readonly emailRegexp = /[-0-9а-яА-ЯёЁa-zA-Z.+_]+@[-0-9а-яА-ЯёЁa-zA-Z.+_]+\.[а-яА-ЯёЁa-zA-Z]/;
  public readonly textAndNumbers = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/;
  public static numbersRegexp = /^\+?\d*$/;
  public readonly numbersRegexp = UtilService.numbersRegexp;
  public readonly notNumbersRegexp = /^\D*$/;
  public readonly cyrilicLettersRegexp = /(?:[а-яА-ЯёЁ].*){1}/mu;

  public readonly specialCharactersRegexp = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  public readonly smallCaseRegexp = /[a-z]/;
  public readonly capitalCaseRegexp = /[A-Z]/;
  public readonly anyNumbersRegexp = /\d/;
  public readonly onlyNumbersRegexp = /^[0-9]*$/;
  public readonly emailAndDotSignRegexp = /[@.\-]{};':"\\|,.<>\/?]/;
  public readonly addressSearchRegexp = /[^a-zA-Z0-9&@\-_.!~*'()$£€%А-Яа-яЁёéèêëàâîïôûùçœÉÈÊËÀÂÎÏÔÛÙÇŒ ]/g;

  public readonly MOBILE_CODES = ['06', '07'];
  private readonly MIN_PHONE_NUMBER_LENGTH = 9;
  private readonly MAX_PHONE_NUMBER_LENGTH = 10;
  private readonly MIN_PASSWORD_LENGTH = 6;
  private readonly MAX_PASSWORD_LENGTH = 20;
  private readonly MAX_LOGIN_PASSWORD_LENGTH = 100;
  private readonly MAX_ADDRESS_COMMENT_LENGTH = 128;
  private readonly CSV_MIME_TYPE = 'application/vnd.ms-excel, text/x-csv, text/csv';

  constructor(private readonly windowRef: WindowRef) {}

  public extractIds(objects) {
    const ids = [];

    objects.forEach(value => {
      ids.push(value.id);
    });

    return ids;
  }

  public getCsvMimeType(): string {
    return this.CSV_MIME_TYPE;
  }

  public getMinPhoneNumberLength() {
    return this.MIN_PHONE_NUMBER_LENGTH;
  }

  public getMaxPhoneNumberLength() {
    return this.MAX_PHONE_NUMBER_LENGTH;
  }

  public getMinPasswordLength() {
    return this.MIN_PASSWORD_LENGTH;
  }

  public getMaxPasswordLength() {
    return this.MAX_PASSWORD_LENGTH;
  }

  public getMaxAuthLoginPasswordLength() {
    return this.MAX_LOGIN_PASSWORD_LENGTH;
  }

  public getMaxAddressCommentLength() {
    return this.MAX_ADDRESS_COMMENT_LENGTH;
  }

  public getCyrilicLettersType() {
    return this.cyrilicLettersRegexp;
  }

  public capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public getAcceptedImageTypesArrFromStr(str: string) {
    const acceptedImageTypes = [];
    const testArr = str.split(',');
    for (const item of testArr) {
      acceptedImageTypes.push(item.trim());
    }
    return acceptedImageTypes;
  }

  public filterDateStart = (d: any, dateEnd: any, dateDiffer?: number): boolean => {
    if (d && dateEnd) {
      return dateDiffer
        ? d.diff(dateEnd, 'days') > -dateDiffer && d.diff(dateEnd, 'days') <= 0
        : d.diff(dateEnd, 'days') <= 0;
    }
    return true;
  };

  public filterDateEnd = (d: any, dateStart: any, dateDiffer?: number): boolean => {
    if (d && dateStart) {
      return dateDiffer
        ? d.diff(dateStart, 'days') >= 0 && d.diff(dateStart, 'days') < dateDiffer
        : d.diff(dateStart, 'days') >= 0;
    }
    return true;
  };

  public isIdInUrl(str: string, urlPart: string) {
    const regExp = new RegExp(`\/${urlPart}\/(\\d+)`);
    const matches = str.match(regExp);
    return matches && matches.length > 1;
  }

  public isStrInUrl(str: string, urlPart: string) {
    const regExp = new RegExp(`\/${urlPart}`);
    const matches = str.match(regExp);
    return matches && matches.length > 0;
  }

  public isMobileNumber(phoneNumber: string, phoneCodeLength = 0): boolean {
    const firstTwoDigits = phoneNumber.substr(phoneCodeLength, 2);
    return this.MOBILE_CODES.includes(firstTwoDigits);
  }

  public tagRemoveFalsyValueFunc(strings, ...values): string {
    let str = '';
    for (let i = 0; i < strings.length; i++) {
      if (i > 0) {
        if (values[i - 1]) {
          str += values[i - 1];
        }
      }
      str += strings[i];
    }
    str = str.replace(/\s+/g, ' ');
    str = str.replace(/ ,/g, ',');
    return str.trim();
  }

  tagRemoveFalsyValueFuncAndClearSpaces(strings, ...values) {
    const combined = strings.map((s, i) => s + (values[i - 1] || '')).join(' ');
    const filtered = combined.split(' ').filter(char => char !== '');
    return filtered.join(' ');
  }

  /**
   * is departure address equal to arrival address
   */
  public isEqualAddress(first: AddressCollectionGoogle, second: AddressCollectionGoogle): boolean {
    const isAddressMissing = !first || !second;

    const hasCoordinates = !!first?.coordinates && !!second?.coordinates;
    if (isAddressMissing || !hasCoordinates) {
      return false;
    }

    let { longitude: lon, latitude: lat } = first.coordinates;
    let { longitude: secLon, latitude: secLat } = second.coordinates;
    lon = +lon.toFixed(6);
    lat = +lat.toFixed(6);
    secLon = +secLon.toFixed(6);
    secLat = +secLat.toFixed(6);
    const isSameLongitude = lon === secLon;
    const isSameLatitude = lat === secLat;
    return isSameLongitude && isSameLatitude;
  }

  public pageHasScrollBar(): boolean {
    return this.windowRef.nativeWindow.innerWidth > this.windowRef.nativeWindow.document.body.clientWidth;
  }

  public setCarColorByStatus(orderStatus: string, driverStatus: string): string {
    if (orderStatus === 'TOWARDS_DESTINATION' && driverStatus === 'UNREACHABLE') {
      return 'assets/images/grey-car-scaled.png';
    }
    if (orderStatus === 'TOWARDS_DESTINATION') {
      return 'assets/images/yellow-car-scaled.png';
    } else {
      return 'assets/images/red-car-scaled.png';
    }
  }
}
