import { Component, ViewChild } from '@angular/core';
import { IChangePoiErrorDialogData, IPoi } from '@core/models/poi.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'azz-change-poi-error-dialog',
  templateUrl: './change-poi-error-dialog.component.html',
  styleUrls: ['./change-poi-error-dialog.component.less'],
})
export class ChangePoiErrorDialogComponent {
  @ViewChild('content') dialogContent: any;
  public header: string;
  public modalClass: string;
  public modalReference: any;
  public fleets: any[];
  public hasDisplayInValue: boolean;
  public poi: IPoi;

  constructor(private readonly dialogService: NgbModal) {}

  public showDialog(header: string, data: Partial<IChangePoiErrorDialogData>) {
    this.header = header;
    this.poi = data.poi;
    this.fleets = data.fleets;
    this.hasDisplayInValue = data.hasDisplayInValue;
    this.modalReference = this.dialogService.open(this.dialogContent, {
      windowClass: this.modalClass,
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  public onClose(): void {
    this.modalReference.close();
  }
}
