<ng-template #content let-modal>
  <div class="azz-popup">
    <h1 class="message-dialog-header">{{ header | translate }}</h1>

    <div class="azz-info-block azz-pre-line message-dialog-message">{{ message | translate }}</div>

    <div class="azz-toolbar message-dialog-toolbar">
      <button type="button" class="btn btn-link message-dialog-default-btn" (click)="onClose()">
        {{ dismissValue || 'CANCEL' | translate }}
      </button>
      <button type="button" class="btn btn-link message-dialog-primary-btn" (click)="onSuccess()">
        {{ closeValue || 'CORE_OK_VALUE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
