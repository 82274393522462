import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { G7Order } from '../../../../models/g7-order.model';

@Component({
  selector: 'azz-invalidate-popup',
  templateUrl: './invalidate-popup.component.html',
})
export class InvalidatePopupComponent {
  @Input() order: G7Order;
  public message: string;

  constructor(private readonly modal: NgbActiveModal) {}

  public close(): void {
    this.modal.close();
    // this.modal.dismiss();
  }

  public submit(message: string): void {
    this.modal.close(message);
  }
}
