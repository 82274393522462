<div class="tags-and-attributes-card card">
  <div class="card-container">
    <h2 class="header-card">{{ 'TAGS_AND_ATTRIBUTES_HEADER' | translate }}</h2>
    <div class="flex gap-3">
      <div class="col-md-6">
        <div class="attribute-tags">
          <span class="card-label">{{ 'TAGS_AND_ATTRIBUTES_LABEL_REQUIRED' | translate }}</span>
          <button
            *ngIf="isNotDefaultMandatoryTags$ | async"
            class="btn-square"
            tooltipClass="status-tooltip error-color"
            [ngbTooltip]="tagsMandatoryResetPlhldr"
            (click)="resetMandatoryTags()"
          >
            <azz-icon-return />
          </button>
        </div>

        <div class="container-buttons">
          <button class="button-tag-mandatory">
            {{ 'SERVICE_TYPE_' + form.controls.serviceType.value?.id | translate }}
          </button>
          <button class="button-tag-mandatory">
            {{ 'VEHICLE_TYPE_' + form.controls.vehicleType.value?.id | translate }}
          </button>
        </div>
        <div class="container-buttons">
          <div class="transparent-input-label">{{ 'TAGS_AND_ATTRIBUTES_LABEL_SELECTION' | translate }}</div>

          <div class="dropdown-list" ngbDropdown display="static" (openChange)="checkMenuState()">
            <input
              #typeHead="ngbTypeahead"
              (click)="typeHead.dismissPopup()"
              #input
              ng-trim="false"
              ngbDropdownToggle
              id="typeahead-focus"
              type="text"
              class="input-dropdown-list input-dropdown-list_expandable"
              [ngClass]="isCollapsed ? 'input-dropdown-list_collapse' : ''"
              [(ngModel)]="model"
              [ngbTypeahead]="search"
              [resultTemplate]="searchResultsTemplate"
              [inputFormatter]="formatter"
              [focusFirst]="true"
              (selectItem)="selectedItem($event)"
              [disabled]="form.disabled"
            />
            <ng-template #searchResultsTemplate let-result="result" let-term="term" class="flex panel-content none">
              <div *ngIf="result">
                <div
                  [ngClass]="
                    form.controls?.serviceType?.value.id === result.id ||
                    form.controls?.vehicleType.value.id === result.id ||
                    result.selected
                      ? 'icon checkbox'
                      : 'icon empty-checkbox'
                  "
                ></div>
                <ngb-highlight [term]="term" class="single-tag search-result" [result]="result.name" />
              </div>
              <div *ngIf="!result" class="transparent-input-label">{{ 'NO_TAGS_FOUND' | translate }}</div>
            </ng-template>

            <div *ngIf="model?.length < 1" ngbDropdownMenu [formGroup]="form">
              <div ngbAccordion [closeOthers]="false" #accordion="ngbAccordion">
                <div ngbAccordionItem="Services">
                  <h2 ngbAccordionHeader>
                    <button ngbAccordionButton>Services</button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <div *ngFor="let service of serviceTypes">
                          <label
                            class="attributes-tag-radio"
                            for="serviceType_{{ service.id }}"
                            [ngClass]="isServiceTypeTagDisabled(service) ? 'disabled' : ''"
                          >
                            <input
                              [ngClass]="form.controls?.serviceType?.value.id === service.id ? 'checked' : ''"
                              id="serviceType_{{ service.id }}"
                              [value]="service"
                              [compareWith]="compareFn"
                              type="radio"
                              formControlName="serviceType"
                              (change)="onTagSelected()"
                            />
                            {{ 'SERVICE_TYPE_' + service?.id | translate }}
                          </label>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div ngbAccordionItem="Type véhicule">
                  <h2 ngbAccordionHeader>
                    <button ngbAccordionButton>Type véhicule</button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <div *ngFor="let vehicle of vehicleTypes">
                          <label
                            class="attributes-tag-radio"
                            for="serviceType_{{ vehicle.id }}"
                            [ngClass]="isVehicleTypeTagDisabled(vehicle) ? 'disabled' : ''"
                          >
                            <input
                              [ngClass]="form.controls?.vehicleType?.value.id === vehicle.id ? 'checked' : ''"
                              id="serviceType_{{ vehicle.id }}"
                              [value]="vehicle"
                              [compareWith]="compareFn"
                              type="radio"
                              formControlName="vehicleType"
                              (change)="onTagSelected()"
                            />
                            {{ 'VEHICLE_TYPE_' + vehicle?.id | translate }}
                          </label>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div ngbAccordionItem="Chauffeur">
                  <h2 ngbAccordionHeader>
                    <button ngbAccordionButton>Chauffeur</button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <label class="attributes-tag" for="other" *ngFor="let tag of driversList">
                          <input
                            [disabled]="isTagsDisabled"
                            [id]="'tag' + tag.id"
                            type="checkbox"
                            [(ngModel)]="tag.selected"
                            [value]="tag"
                            [ngModelOptions]="{ standalone: true }"
                            [name]="'tag' + tag.id"
                            (change)="onTagSelected()"
                          />
                          <label [for]="'tag' + tag.id">
                            {{ 'CRC_TAG_' + tag.id | translate }}
                          </label>
                        </label>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div ngbAccordionItem="Voiture">
                  <h2 ngbAccordionHeader>
                    <button ngbAccordionButton>Voiture</button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <label class="attributes-tag" for="other" *ngFor="let tag of vehiclesList">
                          <input
                            [disabled]="isTagsDisabled"
                            [id]="'tag' + tag.id"
                            type="checkbox"
                            [(ngModel)]="tag.selected"
                            [value]="tag"
                            [ngModelOptions]="{ standalone: true }"
                            [name]="'tag' + tag.id"
                            (change)="onTagSelected()"
                          />
                          <label [for]="'tag' + tag.id">
                            {{ 'CRC_TAG_' + tag.id | translate }}
                          </label>
                        </label>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div ngbAccordionItem title="Paiement">
                  <h2 ngbAccordionHeader>
                    <button ngbAccordionButton>Paiement</button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <label class="attributes-tag" for="other" *ngFor="let tag of paymentList">
                          <input
                            [disabled]="isTagsDisabled"
                            [id]="'tag' + tag.id"
                            type="checkbox"
                            [(ngModel)]="tag.selected"
                            [value]="tag"
                            [ngModelOptions]="{ standalone: true }"
                            [name]="'tag' + tag.id"
                            (change)="onTagSelected()"
                          />
                          <label [for]="'tag' + tag.id">
                            {{ 'CRC_TAG_' + tag.id | translate }}
                          </label>
                        </label>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="flex">
          <span class="card-label">{{ 'ADDITIONAL_TAGS_HEADER' | translate }}</span>
          <button *ngIf="selectedTags?.length >= 3" class="clear-all-tags" (click)="uncheckAllTags()">
            <i class="delete-icon"></i>
            <span class="button-text">
              {{ 'CLEAR_ALL' | translate }}
            </span>
          </button>
        </div>
        <ng-container *ngIf="selectedTags?.length === 0">
          <span class="card-label-center">{{ 'NO_TAGS_SELECTED' | translate }}</span>
        </ng-container>
        <div class="flex all-tags overflow">
          <ng-container class="single-tag" *ngFor="let selected of listOfTags">
            <button *ngIf="selected?.selected" class="selected-tags">
              <span class="button-text">
                {{ 'CRC_TAG_' + selected.id | translate }}
              </span>
              <button class="cross-icon" (click)="uncheckTag(selected)"></button>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tagsMandatoryResetPlhldr>
  <small class="hover-text" [innerHTML]="'TAGS_AND_ATTRIBUTES_LABEL_REQUIRED_RESET' | translate"> </small>
</ng-template>
