<ng-template #content let-modal>
  <div class="azz-popup dialog">
    <div>{{ 'CAN_NOT_IDENTIFY_CITY_FOR_POIS' | translate }}</div>
    <div class="azz-info-block">
      <ul class="dialog__poi-list">
        <li *ngFor="let poi of poiList">{{ poi }}</li>
      </ul>
    </div>
    <div>{{ 'INDICATE_CITY_IN_FILE' | translate }}</div>

    <div class="azz-toolbar message-dialog-toolbar">
      <button type="button" class="btn btn-link message-dialog-primary-btn" (click)="onClose()">
        {{ 'CORE_OK_VALUE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
