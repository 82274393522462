import { IUserNotificationsData } from '@core/models/user-notification.model';
import { createAction, props } from '@ngrx/store';

export const initUserNotifications = createAction('[User Notifications] Init');
export const destroyUserNotifications = createAction('[User Notifications] Destroy');
export const getUserNotifications = createAction('[User Notifications] Get Notifications');
export const getUserNotificationsSuccess = createAction(
  '[User Notifications] Get Notifications Success',
  props<{ notificationsData: IUserNotificationsData }>()
);
export const getUserNotificationsError = createAction('[User Notifications] Get Notifications Error');
export const readUserNotification = createAction('[User Notifications] Read Notification', props<{ id: number }>());
export const readUserNotificationSuccess = createAction(
  '[User Notifications] Read Notification Success',
  props<{ id: number }>()
);
export const readUserNotificationError = createAction('[User Notifications] Read Notification Error');
