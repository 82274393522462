import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IDriverSessionHistory, IDriverSessionHistoryMessage } from '../../../../models/session.model';

@Component({
  selector: 'azz-session-message',
  templateUrl: './session-message.component.html',
  styleUrls: ['./session-message.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionMessageComponent {
  @Input() message: IDriverSessionHistoryMessage;
  @Input() history: IDriverSessionHistory;

  public getAvatar(message) {
    switch (message.authorType) {
      case 'DRIVER':
        return this.history.driver.avatar || 'assets/images/driver-avatar-placeholder.png';
      case 'OPERATOR':
        return this.history.operators[message.authorId] && this.history.operators[message.authorId].avatar
          ? this.history.operators[message.authorId].avatar
          : 'assets/images/bot-avatar-placeholder.png';
      default:
        return 'assets/images/bot-avatar-placeholder.png';
    }
  }

  public getName(message) {
    switch (message.authorType) {
      case 'DRIVER':
        return this.history.driver.name;
      case 'OPERATOR':
        return this.history.operators[message.authorId] ? this.history.operators[message.authorId].name : 'FT_OPERATOR';
      default:
        return 'FT_OPERATOR';
    }
  }
}
