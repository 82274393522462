import { IColor } from '../models/color-management.model';

export const COLOR_MANAGEMENT = () => {
  const data = {
    MAIN_COLORS: [
      { id: 'BLUE', name: 'CM_BLUE', class: 'azz-cm-blue' },
      { id: 'WHITE', name: 'CM_WHITE', class: 'azz-cm-white' },
      { id: 'RED', name: 'CM_RED', class: 'azz-cm-red' },
      { id: 'YELLOW', name: 'CM_YELLOW', class: 'azz-cm-yellow' },
    ],
    HALF_COLORS: [
      { id: 'BLUE1', name: 'CM_BLUE', class: 'azz-cm-blue', index: 1 },
      { id: 'BLUE2', name: 'CM_BLUE', class: 'azz-cm-blue', index: 2 },
      { id: 'WHITE1', name: 'CM_WHITE', class: 'azz-cm-white', index: 1 },
      { id: 'WHITE2', name: 'CM_WHITE', class: 'azz-cm-white', index: 2 },
      { id: 'RED1', name: 'CM_RED', class: 'azz-cm-red', index: 1 },
      { id: 'RED2', name: 'CM_RED', class: 'azz-cm-red', index: 2 },
      { id: 'YELLOW1', name: 'CM_YELLOW', class: 'azz-cm-yellow', index: 1 },
      { id: 'YELLOW2', name: 'CM_YELLOW', class: 'azz-cm-yellow', index: 2 },
    ],
    BY_ID: {},
  };

  [...data.MAIN_COLORS, ...data.HALF_COLORS].forEach((color: IColor) => {
    data.BY_ID[color.id] = color;
  });

  return data;
};

export enum DRIVER_PARAMS_COLOR {
  COLOR = 'color',
  HALF_COLOR = 'halfColor',
}
