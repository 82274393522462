import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';

import { ApiService } from './api.service';
import { IAuthData } from '../../models/auth.model';
import { ICurrentUserInfo } from '../../models/user-info.model';
import { AppStore } from '../../store';
import { PhoneAdvisorService } from '../users/phone-advisor.service';
import { UserService } from '../users/user.service';

@Injectable({ providedIn: 'root' })
export class LoginService extends ApiService {
  public LOGIN_URL = 'api/v1/auth/';
  private readonly loggedOutSuccess$ = new Subject<void>();

  constructor(
    private readonly httpClient: HttpClient,
    protected userService: UserService,
    private readonly phoneAdvisorService: PhoneAdvisorService,
    private readonly store: Store
  ) {
    super(httpClient);
  }

  public login(authData: IAuthData): Observable<any> {
    return this.post(`${this.LOGIN_URL}login`, this.generateAuthStr(authData), {}, this.generateLoginHeaders());
  }

  public logout() {
    return this.store.pipe(
      select(AppStore.selectPendingRequestsCount),
      takeUntil(this.loggedOutSuccess$),
      filter((count: number) => count === 0),
      take(1),
      switchMap(() => (this.isCtiLogoutRequired() ? this.ctiLogout$() : this.logout$()))
    );
  }

  private generateLoginHeaders(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  }

  private generateAuthStr(authData: IAuthData): string {
    const { username, password, recaptchaResponse, rememberMe } = authData;
    return rememberMe
      ? `username=${username}&password=${password}&remember_me=${rememberMe}&recaptchaResponse=${recaptchaResponse}`
      : `username=${username}&password=${password}&recaptchaResponse=${recaptchaResponse}`;
  }

  private isCtiLogoutRequired(): boolean {
    const userInfo = this.userService.getCurrentUserInfo();
    return userInfo
      ? this.isPhoneAdvisorRole(userInfo) && this.isCtiEnabled(userInfo) && this.hasAssignedAgent(userInfo)
      : false;
  }

  private logout$(): Observable<any> {
    return this.get(`${this.LOGIN_URL}logout`).pipe(finalize(() => this.loggedOutSuccess$.next(null)));
  }

  private ctiLogout$(): Observable<any> {
    return this.phoneAdvisorService.logoutAgent().pipe(
      catchError(() => of(null)),
      mergeMap(() => this.logout$())
    );
  }

  private isPhoneAdvisorRole(res: ICurrentUserInfo): boolean {
    return res.user.roles.indexOf('ROLE_PHONE_ADVISOR') !== -1;
  }

  private isCtiEnabled(res: ICurrentUserInfo): boolean {
    return res?.fleet?.ctiEnabled;
  }

  private hasAssignedAgent(res: ICurrentUserInfo): boolean {
    return 'agentUuid' in res.user ? !!res.user?.agentUuid : false;
  }
}
