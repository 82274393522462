import { IPhoneAdvisorUserInfo } from '@core/models/user-info.model';
import { createAction, props } from '@ngrx/store';

export const initProfile = createAction('[Phone Advisor Profile] Init');
export const destroyProfile = createAction('[Phone Advisor Profile] Destroy');
export const saveAvatar = createAction(
  '[Phone Advisor Profile] Save Avatar',
  props<{ avatar: File; user: IPhoneAdvisorUserInfo }>()
);
export const saveAvatarError = createAction('[Phone Advisor Profile] Save Avatar Error');
export const saveUser = createAction('[Phone Advisor Profile] Save User', props<{ user: IPhoneAdvisorUserInfo }>());
export const saveUserSuccess = createAction('[Phone Advisor Profile] Save User Success');
export const saveUserError = createAction('[Phone Advisor Profile] Save User Error');
