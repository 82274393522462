export * from './address.api-client';
import { AddressApiClient } from './address.api-client';
export * from './customer.api-client';
import { CustomerApiClient } from './customer.api-client';
export * from './driver.api-client';
import { DriverApiClient } from './driver.api-client';
export * from './exclusion.api-client';
import { ExclusionApiClient } from './exclusion.api-client';
export * from './fleet.api-client';
import { FleetApiClient } from './fleet.api-client';
export * from './order.api-client';
import { OrderApiClient } from './order.api-client';
export * from './route.api-client';
import { RouteApiClient } from './route.api-client';
export * from './tag.api-client';
import { TagApiClient } from './tag.api-client';
export const APIS = [
  AddressApiClient,
  CustomerApiClient,
  DriverApiClient,
  ExclusionApiClient,
  FleetApiClient,
  OrderApiClient,
  RouteApiClient,
  TagApiClient,
];
