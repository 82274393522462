import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { DatePickerModule } from '@common/date-picker/date-picker.module';
import { PhoneComponent } from '@controls/phone/phone.component';
import { OldTimeComponent } from '@core/modules/nouvelle-commande/components/date-time/time/time.component';
import { DelayDispatchComponent } from '@core/modules/nouvelle-commande/components/delay-dispatch/delay-dispatch.component';
import { IconReturnComponent } from '@icons/icon-return.component';
import {
  NgbAccordionModule,
  NgbCarouselModule,
  NgbDropdownModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutoFocus } from 'primeng/autofocus';
import { MessagesModule } from 'primeng/messages';

import { AzzRotateIndicatorModule, CustomDialogModule } from '..';
import { AddressPoiAutocompleteComponent } from './components/address-poi-autocomplete';
import { AttributeTagsComponent } from './components/attribute-tags';
import { OrderInProgressStatusModule } from './components/customer-current-orders/current-order-status.pipe';
import { CustomerCurrentOrdersComponent } from './components/customer-current-orders/customer-current-orders.component';
import { CustomerPreviousOrderComponent } from './components/customer-prev-order/customer-previous-order.component';
import { PrevOrderPaymentTypeComponent } from './components/customer-prev-order/prev-order-payment-type/prev-order-payment-type.component';
import { PrevOrderTagsComponent } from './components/customer-prev-order/prev-order-tags/prev-order-tags.component';
import { CustomerTypeaheadComponent } from './components/customer-typeahead/customer-typeahead.component';
import { DatePopupControlComponent } from './components/date-popup-control/date-popup-control.component';
import { OldDateComponent } from './components/date-time/date/date.component';
import { DriverAssignmentComponent } from './components/driver-assignment/driver-assignment.component';
import { OrderPreSaveDialogComponent } from './components/order-pre-save-dialog';
import { PreSaveConfirmDialogComponent } from './components/order-pre-save-dialog/pre-save-modal/pre-save-modal.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { NouvelleCommandeComponent } from './nouvelle-commande.component';
import { NouvelleCommandeRoutingModule } from './nouvelle-commande.routing-module';
import { AzzSecondsToUIOrderTimePipeModule } from '../custom-order/pipes/seconds-to-ui-order-time.pipe';

@NgModule({
  imports: [
    CommonModule,
    NouvelleCommandeRoutingModule,
    TranslateModule,
    NgbTypeaheadModule,
    NgbCarouselModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    DatePickerModule,
    AzzRotateIndicatorModule,
    RouterModule,
    AzzSecondsToUIOrderTimePipeModule,
    OrderInProgressStatusModule,
    InfiniteScrollModule,
    PhoneComponent,
    AutoFocus,
    OldDateComponent,
    OldTimeComponent,
    TooltipComponent,
    MatAutocompleteModule,
    ScrollingModule,
    MatInputModule,
    NgSelectModule,
    CustomDialogModule,
    IconReturnComponent,
    DelayDispatchComponent,
    MessagesModule,
  ],
  declarations: [
    NouvelleCommandeComponent,
    AddressPoiAutocompleteComponent,
    CustomerTypeaheadComponent,
    OrderPreSaveDialogComponent,
    AttributeTagsComponent,
    CustomerPreviousOrderComponent,
    CustomerCurrentOrdersComponent,
    PrevOrderPaymentTypeComponent,
    PrevOrderTagsComponent,
    PreSaveConfirmDialogComponent,
    DatePopupControlComponent,
    DriverAssignmentComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [NouvelleCommandeComponent],
})
export class NouvelleCommandeModule {}
