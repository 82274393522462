<div class="driver-assignment-card card">
  <div class="card-container">
    <h2 class="header-card">
      <img src="{{ 'assets/images/redesign/ORDER.svg' }}" class="order-type" alt="" />
      {{ 'DRIVER_ASSIGMENT_HEADER' | translate }}
    </h2>

    <div class="d-flex flex-column">
      <label for="driver-autocomplete" class="transparent-input-label">
        {{ 'DRIVER' | translate }}
        <!-- TODO: frontend - if input disabled, hide tooltip and icon info" -->
        <span [ngbTooltip]="tooltipContentDriver" placement="right" tooltipClass="status-tooltip">
          <img src="assets/images/redesign/info.svg" alt="" />
        </span>
      </label>

      <div class="d-flex align-items-center">
        <ng-select
          class="driver-autocomplete"
          labelForId="driver-autocomplete"
          bindValue="id"
          bindLabel="searchText"
          [clearable]="true"
          [loading]="isLoading$ | async"
          [items]="drivers$ | async"
          [disabled]="(disabled$ | async) || (isLoading$ | async)"
          [ngModel]="value$ | async"
          (ngModelChange)="setValue($event)"
          (focusin)="reload()"
        >
          <ng-template ng-label-tmp let-driver="item">
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: driver }"></ng-container>
          </ng-template>
          <ng-template ng-option-tmp let-driver="item">
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: driver }"></ng-container>
          </ng-template>
          <ng-template ng-notfound-tmp>
            @if ((drivers$ | async).length) {
              <p class="driver-autocomplete-empty">{{ 'DRIVER_NOT_FOUND_MANUAL' | translate }}</p>
            } @else {
              <p class="driver-autocomplete-empty">{{ 'NO_DRIVERS_AVAILABLE' | translate }}</p>
            }
          </ng-template>
        </ng-select>
        <button
          *ngIf="value$ | async"
          type="button"
          class="btn-square"
          tooltipClass="status-tooltip"
          [ngbTooltip]="tooltipUpdateDriver"
          [disabled]="(disabled$ | async) || (isLoading$ | async)"
          (click)="reload()"
          data-test-id="reload-drivers-btn"
        >
          <azz-icon-return />
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipContentDriver>
  <small>{{ 'HINT_DRIVER_ASSIGMENT' | translate }}</small>
  <ul class="list-none pl-0 flex flex-wrap justify-content-between mb-0 mt-3">
    <li class="flex align-items-center">
      <span class="tooltip-status free mr-2"></span>
      <span>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_FREE' | translate }}</span>
    </li>
    <li class="flex align-items-center">
      <span class="tooltip-status occupied mr-2"></span>
      <span>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_BUSY' | translate }}</span>
    </li>
    <li class="flex align-items-center">
      <span class="tooltip-status towards-destination mr-2"></span>
      <span>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_CUSTOMER_ON_BOARD' | translate }}</span>
    </li>
    <li class="flex align-items-center">
      <span class="tooltip-status unreachable mr-2"></span>
      <span>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_CUSTOMER_UNREACHABLE' | translate }}</span>
    </li>
  </ul>
</ng-template>

<ng-template #tooltipUpdateDriver>
  <small class="hover-text" [innerHTML]="'HINT_DRIVER_ASSIGMENT_UPDATE_DRIVER' | translate"> </small>
</ng-template>

<!-- TODO: frontend [ngClass]="{ disabled: driver.sessionStatus === 'UNREACHABLE' || driver.orders.length >= 2 }" move to ng-option -->
<ng-template #optionTemplate let-item>
  @if (drivers$ | async) {
    <ul class="d-flex driver-autocomplete-list" *ngIf="asDriver(item) as driver">
      @for (status of driver.statuses; track status) {
        <li>
          @switch (status) {
            @case (statusEnum.OCCUPIED) {
              <span class="session-status occupied" aria-label="OCCUPIED"></span>
            }
            @case (statusEnum.IN_QUEUE) {
              <span class="session-status in-queue" aria-label="IN QUEUE"></span>
            }
            @case (statusEnum.CONFIRMED) {
              <span class="session-status confirmed" aria-label="CONFIRMED"></span>
            }
            @case (statusEnum.AT_DEPARTURE_ADDRESS) {
              <span class="session-status at-departure-address" aria-label="AT DEPARTURE ADDRESS"></span>
            }
            @case (statusEnum.FREE) {
              <span class="session-status free" aria-label="FREE"></span>
            }
            @case (statusEnum.CONNECTED) {
              <span class="session-status connected" aria-label="CONNECTED"></span>
            }
            @case (statusEnum.TOWARDS_DESTINATION) {
              <span class="session-status towards-destination" aria-label="TOWARDS DESTINATION"></span>
            }
            @case (statusEnum.UNREACHABLE) {
              <span class="session-status unreachable" aria-label="UNREACHABLE"></span>
            }
          }
        </li>
      }
      <li class="driver-name">
        <span *ngIf="driver.taxiNumber">
          {{ driver.taxiNumber }}
          <span>&nbsp;-&nbsp;</span>
        </span>
        <span>
          {{ driver.firstName }}
        </span>
        <span>
          {{ driver.lastName }}
        </span>
      </li>
    </ul>
  }
</ng-template>
