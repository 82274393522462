<ng-template #content let-modal>
  <div class="message-dialog-header azz-driver-session-popup-header">
    <span>
      {{ data?.driverName }}<span *ngIf="data?.taxiNumber"> / {{ data?.taxiNumber }}</span>
    </span>
    <span class="azz-close-popup-button" (click)="onClose()">x</span>
  </div>
  <div class="azz-popup">
    <div class="azz-info-block azz-pre-line message-dialog-message">
      <div class="azz-session-data">
        <div class="row">
          <div class="col-md-3 azz-no-padding">{{ 'FLEET_POPUP_STATUS' | translate }}:</div>
          <div class="col-md-4 azz-font-bold azz-no-padding">
            {{ 'CORE_DRIVER_SESSION_STATUS_' + data.status | translate }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 azz-no-padding">{{ 'FLEET_POPUP_ZONE' | translate }}:</div>
          <div class="col-md-8 azz-font-bold azz-no-padding">
            <span *ngIf="data?.geoZoneName">
              <span>{{ data?.geoZoneName }}</span>
              <span>&nbsp;</span>
              <span *ngIf="data?.driverQueuePosition">({{ data?.driverQueuePosition }})</span>
            </span>
            <span *ngIf="!data?.geoZoneName">-</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 azz-no-padding">{{ 'FLEET_POPUP_VITESSE' | translate }}:</div>
          <div class="col-md-4 azz-font-bold azz-no-padding">
            <span *ngIf="data?.speed">{{ data?.speed }} km/h</span>
            <span *ngIf="!data?.speed">-</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 azz-no-padding">{{ 'FLEET_POPUP_DISPATCH_BLOQUE' | translate }}:</div>
          <div class="col-md-4 azz-font-bold azz-no-padding">
            {{ data?.blockedForDispatch ? 'CORE_TRUE' : ('CORE_FALSE' | translate) }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 azz-no-padding">{{ 'FLEET_POPUP_DEPUTE' | translate }}:</div>
          <div class="col-md-6 azz-font-bold azz-no-padding">
            {{ data?.driverSession?.startTime | date: 'dd.MM.yyyy HH:mm' }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 azz-no-padding">{{ 'FLEET_POPUP_DERNIERE' | translate }}:</div>
          <div class="col-md-6 azz-font-bold azz-no-padding">
            {{ lastTimeUpdated$ | async | date: 'dd.MM.yyyy HH:mm' }}
          </div>
        </div>
        <div class="azz-session-data-control">
          <div class="azz-session-driver-rating">
            <ngb-rating [rate]="data.rating" [max]="5" [readonly]="true" class="azz-driver-rating col-md-offset-3">
              <ng-template let-fill="fill" let-index="index">
                <span
                  class="glyphicon azz-margin-right-5px"
                  [ngClass]="{
                    'glyphicon-thumbs-o-up': fill === 100,
                    'glyphicon-thumbs-up': fill !== 100
                  }"
                >
                </span>
              </ng-template>
            </ngb-rating>
          </div>
          <div class="azz-toolbar buttons">
            <button
              type="button"
              class="btn btn-block azz-info-btn"
              (click)="navigateToDriverDetailsPage(data.driverId)"
            >
              {{ 'DRIVER' | translate }}
            </button>

            <button
              *ngIf="data.currentOrderId"
              type="button"
              class="btn btn-block azz-default-btn"
              (click)="navigateToOrderDetailsPage(data.currentOrderId)"
            >
              {{ 'CURRENT_ORDER' | translate }}
            </button>
            <button
              *ngIf="data.inQueueOrderId"
              type="button"
              class="btn btn-block azz-default-btn"
              (click)="navigateToOrderDetailsPage(data.inQueueOrderId)"
            >
              {{ 'INQUEUE_ORDER' | translate }}
            </button>

            <button type="button" class="btn btn-block azz-primary-btn" (click)="onCloseSession()">
              {{ 'FLEET_POPUP_CLOSE_SESSION' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
