import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAdvert } from '../../models/advert.model';
import { AppStore } from '../../store';

@Component({
  selector: 'advert',
  templateUrl: './advert.component.html',
  styleUrls: ['./advert.component.less'],
})
export class AdvertComponent implements OnInit {
  public advert$: Observable<IAdvert>;
  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.store.dispatch(AppStore.getAdvert());
    this.advert$ = this.store.pipe(select(AppStore.selectAdvert));
  }
}
