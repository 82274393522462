import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IForgotPasswordParams, INewPasswordBody } from '../../models/auth.model';
import { ICoffeeBreakStatus, IUserSessionStatus } from '../../models/session.model';
import { ICurrentUserInfo } from '../../models/user-info.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class UserInfoService extends ApiService {
  private readonly userInfoUrl = 'user/api/v1/user/';
  private readonly SESSION_API = 'api/v1/userSession/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getInfo(): Observable<ICurrentUserInfo> {
    return this.get(`${this.userInfoUrl}info`);
  }

  public forgotPassword(params: IForgotPasswordParams): Observable<any> {
    return this.get(`${this.userInfoUrl}forgotPasswordInit`, params);
  }

  public newPassword(body: INewPasswordBody): Observable<any> {
    return this.post(`${this.userInfoUrl}forgotPasswordSubmit`, body);
  }

  public getUsersSessionStatus(body: string[]): Observable<IUserSessionStatus[]> {
    return this.put(`${this.SESSION_API}sessionProbe`, body);
  }

  public getCoffeeBreakStatus(body: string[]): Observable<ICoffeeBreakStatus[]> {
    return this.put(`${this.SESSION_API}coffeeBreakProbe`, body);
  }
}
