import { IdOption } from '@common/types/id-option.type';
import { PaymentTypeTitle, ServiceTypeTitle, TagTypeName, VehicleTypeTitle } from '@core/constant/tag.constant';
import { ITag } from '@core/models/tag.model';

export const getServiceVehiclePaymentTypeTagsPayload = ({ paymentType, serviceType, vehicleType }: Params): ITag[] => {
  const result = [];

  if (paymentType.value) {
    result.push(generateServiceVehiclePaymentTypeTag(paymentType.id));
  }
  if (serviceType.value) {
    result.push(generateServiceVehiclePaymentTypeTag(serviceType.id));
  }
  if (vehicleType.value) {
    result.push(generateServiceVehiclePaymentTypeTag(vehicleType.id));
  }
  return result;
};

interface Params {
  paymentType: IdOption<PaymentTypeTitle, PaymentTypeTitle>;
  serviceType: IdOption<ServiceTypeTitle, ServiceTypeTitle>;
  vehicleType: IdOption<VehicleTypeTitle, VehicleTypeTitle>;
}

/**  only ID has sense, backend ignores other properties? so they are hardcoded */
export const generateServiceVehiclePaymentTypeTag = (id: TagTypeName): ITag => ({
  id,
  name: '',
  driverTag: false,
  orderProposalVisible: false,
  orderDetailsVisible: false,
  zoneDetailsVisible: false,
  crcVisible: false,
  crcPriority: 0,
});
