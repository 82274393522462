import { throttle } from 'lodash-es';

// TODO: add unit-tests
/**
 * method decorator for throttling
 *
 * @param time in ms
 *  @example: @throttled(1000) @HostListener('click') onClick() {...}
 */
export const throttled =
  (wait, options = {}): MethodDecorator =>
  // @ts-ignore
  (target, name: string, descriptor: PropertyDescriptor) => ({
    configurable: true,
    enumerable: descriptor.enumerable,
    get() {
      Object.defineProperty(this, name, {
        configurable: true,
        enumerable: descriptor.enumerable,
        value: throttle(descriptor.value, wait, options),
      });

      return this[name];
    },
  });
