import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { RatingComponent } from './rating.component';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbRatingModule],
  declarations: [RatingComponent],
  exports: [RatingComponent],
})
export class RatingModule {}
