import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomPatternValidators {
  static pattern(pattern: string | RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (value && typeof value === 'object') {
        if (value.hasOwnProperty('pattern') && typeof value.pattern.value === 'string') {
          return CustomPatternValidators.testPattern(pattern, value.pattern.value)
            ? null
            : { pattern: { value: control.value } };
        } else if (
          value.hasOwnProperty('pattern') &&
          value.pattern.value &&
          typeof value.pattern.value.address === 'string'
        ) {
          return CustomPatternValidators.testPattern(pattern, value.pattern.value.address)
            ? null
            : { pattern: { value: control.value } };
        }
      }
      return null;
    };
  }

  private static testPattern(pattern: string | RegExp, value: any): boolean {
    if (typeof pattern === 'string') {
      const regex = new RegExp(pattern);
      return regex.test(value);
    } else if (pattern instanceof RegExp) {
      return pattern.test(value);
    }
    return false;
  }
}
