import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IBillingRegistrationBody, IBillingUserInvitation } from '../../models/billing-user.model';
import { G7Order } from '../../models/g7-order.model';
import {
  IG7UserInvitationItem,
  IInvitationBody,
  IInvitationData,
  IInvitationItem,
  IInvitationParams,
} from '../../models/invitation.model';
import { IBaseUserInfo, IGeneralUserInfo } from '../../models/user-info.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class G7Service extends ApiService {
  private readonly BILLING_URL = 'user/api/v1/g7BillingUser/';
  private readonly BILLING_REG_URL = 'api/v1/g7BillingUser/registerG7BillingUser/';
  private readonly BILLING_UPDATE_REG_URL = `${this.BILLING_URL}registration/g7BillingUser/`;
  private readonly G7_ORDER_URL = 'api/v1/orderG7/';
  private readonly G7_INVITATION_URL = 'user/api/v1/invitation/g7BillingUser/';
  private readonly G7_REG_URL = 'api/v1/g7BillingUser/registerG7BillingUser/';
  private readonly G7_REG_UPDATE_URL =
    'user/api/v1/g7BillingUser/registration/g7BillingUser/updateG7BillingUserRegistration';
  private readonly G7_BILLING_USER_URL = 'user/api/v1/g7BillingUser/';

  constructor(http: HttpClient) {
    super(http);
  }

  public inviteG7User(body: IInvitationBody): Observable<IG7UserInvitationItem> {
    return this.put(`${this.G7_INVITATION_URL}invite`, body);
  }

  public inviteG7UsersByCsv(csvFile: File): Observable<IG7UserInvitationItem[]> {
    const formData = new FormData();
    formData.append('invitations', csvFile);
    return this.put(`${this.G7_INVITATION_URL}inviteList`, formData);
  }

  public reinvite(uuid: string): Observable<IInvitationItem> {
    return this.put(`${this.G7_INVITATION_URL}reinvite/${uuid}`, {});
  }

  public getInvitations(params: Partial<IInvitationParams>): Observable<IInvitationData> {
    return this.get(`${this.G7_INVITATION_URL}list`, params);
  }

  public deleteInvitation(uuid: string): Observable<any> {
    return this.delete(this.G7_INVITATION_URL + uuid);
  }

  public getG7Invitation(uuid: string): Observable<IBillingUserInvitation> {
    return this.get(`${this.G7_INVITATION_URL}${uuid}`);
  }

  public registerBillingUser(body: IBillingRegistrationBody): Observable<IGeneralUserInfo> {
    return this.post(this.BILLING_REG_URL, body);
  }

  public updateBillingUserRegistration(body: Partial<IGeneralUserInfo>): Observable<IGeneralUserInfo> {
    return this.post(`${this.BILLING_UPDATE_REG_URL}updateG7BillingUserRegistration`, body);
  }

  public registerG7user(data: any): Observable<any> {
    return this.post(this.G7_REG_URL, data);
  }

  public updateRegG7user(data: any): Observable<any> {
    return this.post(this.G7_REG_UPDATE_URL, data);
  }

  public getG7User(id: string | number): Observable<any> {
    return this.get(`order/api/v1/orderG7/${id}`);
  }

  public validateG7(id: string | number, data: any): Observable<G7Order> {
    return this.put(`order/${this.G7_ORDER_URL}${id}/validate`, data);
  }

  public saveG7orders(id: string | number, data: any, path: string): Observable<any> {
    return this.put(`order/${this.G7_ORDER_URL}${id}/${path}`, data);
  }

  public updateProfile(userId: number, body: IBaseUserInfo): Observable<IBaseUserInfo> {
    return this.put(`${this.G7_BILLING_USER_URL}${userId}`, body);
  }
}
