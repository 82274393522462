/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Order status enum
 */
export type OrderStatusEnumWebApiModel =
  | 'CREATED'
  | 'DISPATCHING'
  | 'ACCEPTED'
  | 'APPROACH'
  | 'DRIVER_ARRIVED'
  | 'CLIENT_ABOARD'
  | 'FINISHED'
  | 'FINISHED_AUTO'
  | 'ERROR'
  | 'CANCELLED_BY_CUSTOMER'
  | 'CANCELLED_BY_DRIVER'
  | 'CANCELLED_BY_PA'
  | 'DRIVER_NOT_FOUND'
  | 'CLIENT_ABSENT'
  | 'ATTENTE_CHAUFFER';

export const OrderStatusEnumWebApiModel = {
  Created: 'CREATED' as OrderStatusEnumWebApiModel,
  Dispatching: 'DISPATCHING' as OrderStatusEnumWebApiModel,
  Accepted: 'ACCEPTED' as OrderStatusEnumWebApiModel,
  Approach: 'APPROACH' as OrderStatusEnumWebApiModel,
  DriverArrived: 'DRIVER_ARRIVED' as OrderStatusEnumWebApiModel,
  ClientAboard: 'CLIENT_ABOARD' as OrderStatusEnumWebApiModel,
  Finished: 'FINISHED' as OrderStatusEnumWebApiModel,
  FinishedAuto: 'FINISHED_AUTO' as OrderStatusEnumWebApiModel,
  Error: 'ERROR' as OrderStatusEnumWebApiModel,
  CancelledByCustomer: 'CANCELLED_BY_CUSTOMER' as OrderStatusEnumWebApiModel,
  CancelledByDriver: 'CANCELLED_BY_DRIVER' as OrderStatusEnumWebApiModel,
  CancelledByPa: 'CANCELLED_BY_PA' as OrderStatusEnumWebApiModel,
  DriverNotFound: 'DRIVER_NOT_FOUND' as OrderStatusEnumWebApiModel,
  ClientAbsent: 'CLIENT_ABSENT' as OrderStatusEnumWebApiModel,
  AttenteChauffer: 'ATTENTE_CHAUFFER' as OrderStatusEnumWebApiModel,
};
