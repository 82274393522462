<div class="card-container summary-card-info">
  <div class="overflow-content">
    <div class="flex flex-column">
      <h2 class="header-card">{{ 'SUMMARY' | translate }}</h2>
      <div class="d-flex" *ngIf="orderType">
        <img src="{{ 'assets/images/redesign/' + orderType + '.svg' }}" alt="order type" class="order-type" />

        @if (orderType === 'ORDER') {
          <div class="label">{{ 'ORDER_TYPE_' + orderType | translate }}</div>
        } @else {
          <div class="label">{{ formattedDate }} {{ formattedTime }}</div>
        }
      </div>
    </div>

    @if (form.controls?.order?.controls?.dispatchDelay?.value && orderType !== 'ORDER') {
      <span class="card-label">{{ 'DISPATCH_TIME' | translate }}:</span>

      @if (isSameDates(orderDate, orderTimeInTimeFormat)) {
        <time class="label">
          {{ form.controls?.order?.controls?.dispatchDelay?.value }} min ({{ formattedDispatchDelayTime }})
        </time>
      } @else {
        <time class="label">
          {{ form.controls?.order?.controls?.dispatchDelay?.value }} min ({{
            orderTimeInTimeFormat.toDate() | date: 'dd/MM'
          }}
          {{ formattedDispatchDelayTime }})
        </time>
      }
    }

    <div class="flex">
      <div class="card-label">{{ 'CUSTOMER' | translate }}:&nbsp;</div>
      <span
        *ngIf="
          customer?.status === 'ACTIVE' ||
          (form?.value?.customer?.type === 'new' && form.controls?.customer?.controls?.lastName.value)
        "
        class="customer-status active"
        tooltipClass="status-tooltip error-color"
        [ngbTooltip]="activeTooltipContent"
      ></span>

      <span
        *ngIf="customer?.status === 'BLOCKED'"
        class="customer-status blocked"
        tooltipClass="status-tooltip error-color"
        [ngbTooltip]="blockedTooltipContent"
      ></span>

      <span class="label"
        >{{ form.controls?.customer?.controls?.name?.value }}
        {{ form.controls?.customer?.controls?.lastName?.value }}</span
      >
    </div>
    <div class="flex">
      <div class="label">{{ form.controls?.selectedCustomerPhoneNumber?.value }}</div>
    </div>
    <div class="flex" *ngIf="form.value.customer?.type === 'new'">
      <div class="label">{{ form.controls?.customer?.controls?.phoneNumber.value }}</div>
    </div>

    <div
      class="flex"
      *ngIf="
        form.controls?.customer?.controls?.name?.value + ' ' + form.controls?.customer?.controls?.lastName?.value !==
          form.controls?.passengerName.value &&
        form.controls?.customer?.controls?.lastName?.value !== form.controls?.passengerName.value
      "
    >
      <div *ngIf="!form?.controls?.anonymousPassenger?.value" class="flex">
        <div class="card-label">{{ 'PA_CREATE_PASSENGER' | translate }}:</div>
        <div class="label">
          {{ form.controls?.passengerName.value }}
        </div>
      </div>
    </div>

    <div class="flex" *ngIf="form?.controls?.anonymousPassenger?.value">
      <div class="card-label">{{ 'PA_CREATE_PASSENGER' | translate }}:</div>
      <div class="label">
        {{ 'ANONYMOUS_PASSENGER' | translate }}
      </div>
    </div>

    <div class="card-destination-tags">
      <div class="destination-tags">
        <div class="destination-pin">
          <img src="assets/images/redesign/pin-de.svg" alt="geo icon" class="geomark" />
          <span *ngIf="addresses?.appointmentAddress && !isSameAddress">
            <span *ngIf="addresses?.appointmentAddress.type === 'poi'">
              {{ addresses?.appointmentAddress?.poi?.label }}
            </span>
            <span *ngIf="addresses?.appointmentAddress.type !== 'poi'">
              {{ addresses?.appointmentAddress?.number }}
              {{ addresses?.appointmentAddress?.street }},
              {{ addresses?.appointmentAddress?.city }}
            </span>
          </span>
        </div>
        <div class="vl"></div>
        <div class="destination-pin">
          <img src="assets/images/redesign/pin-a.svg" alt="geo icon" class="geomark" />
          <span *ngIf="addresses?.destinationAddress && !isSameAddress">
            <span *ngIf="addresses?.destinationAddress?.type === 'poi'">
              {{ addresses.destinationAddress?.poi?.label }}
            </span>
            <span *ngIf="addresses?.destinationAddress?.type !== 'poi'">
              {{ addresses?.destinationAddress?.number }} {{ addresses.destinationAddress?.street }},
              {{ addresses?.destinationAddress?.city }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="card-label">{{ 'PA_CREATE_TAGS' | translate }}:</div>
      <div class="selected-tags">
        <span class="label-tags"
          >{{ 'SERVICE_TYPE_' + form.controls?.serviceType?.value.id | translate }},
          {{ 'VEHICLE_TYPE_' + form.controls?.vehicleType?.value.id | translate }}</span
        >
        <span *ngIf="tags.length">,</span>
        <span class="label-tags" *ngFor="let tag of tags; let i = index">
          {{ 'CRC_TAG_' + tag.id | translate }}{{ i === tags.length - 1 ? '' : ',&nbsp;' }}
        </span>
      </div>
    </div>

    <div class="d-flex" *ngIf="selectedDriver$ | async as driver">
      <div class="card-label">{{ 'PA_CREATE_DRIVER_SUMMARY' | translate }}:&nbsp;</div>
      <ul class="d-flex summary-driver-list">
        @for (status of driver.statuses; track status) {
          <li>
            @switch (status) {
              @case (statusEnum.OCCUPIED) {
                <span
                  class="session-status occupied"
                  aria-label="OCCUPIED"
                  [ngbTooltip]="tooltipStatusDriverBusy"
                  tooltipClass="status-tooltip"
                ></span>
              }
              @case (statusEnum.IN_QUEUE) {
                <span
                  class="session-status in-queue"
                  aria-label="IN QUEUE"
                  [ngbTooltip]="tooltipStatusDriverBusy"
                  tooltipClass="status-tooltip"
                ></span>
              }
              @case (statusEnum.CONFIRMED) {
                <span
                  class="session-status confirmed"
                  aria-label="CONFIRMED"
                  [ngbTooltip]="tooltipStatusDriverBusy"
                  tooltipClass="status-tooltip"
                ></span>
              }
              @case (statusEnum.AT_DEPARTURE_ADDRESS) {
                <span
                  class="session-status at-departure-address"
                  aria-label="AT DEPARTURE ADDRESS"
                  [ngbTooltip]="tooltipStatusDriverBusy"
                  tooltipClass="status-tooltip"
                ></span>
              }
              @case (statusEnum.FREE) {
                <span
                  class="session-status free"
                  aria-label="FREE"
                  [ngbTooltip]="tooltipStatusDriverFree"
                  tooltipClass="status-tooltip"
                ></span>
              }
              @case (statusEnum.CONNECTED) {
                <span
                  class="session-status connected"
                  aria-label="CONNECTED"
                  [ngbTooltip]="tooltipStatusDriverFree"
                  tooltipClass="status-tooltip"
                ></span>
              }
              @case (statusEnum.TOWARDS_DESTINATION) {
                <span
                  class="session-status towards-destination"
                  aria-label="TOWARDS DESTINATION"
                  [ngbTooltip]="tooltipStatusCustomerOnBoard"
                  tooltipClass="status-tooltip"
                ></span>
              }
              @case (statusEnum.UNREACHABLE) {
                <span
                  class="session-status unreachable"
                  aria-label="UNREACHABLE"
                  [ngbTooltip]="tooltipStatusCustomerUnreachable"
                  tooltipClass="status-tooltip"
                ></span>
              }
            }
          </li>
        }
        <li>
          <span *ngIf="driver.taxiNumber">
            {{ driver.taxiNumber }}
            <span>&nbsp;-&nbsp;</span>
          </span>
          <span>
            {{ driver.firstName }}
          </span>
          <span>
            {{ driver.lastName }}
          </span>
        </li>
      </ul>
    </div>

    <!-- tootips for session status -->
    <ng-template #tooltipStatusDriverBusy>
      <p>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_BUSY' | translate }}</p>
    </ng-template>

    <ng-template #tooltipStatusDriverFree>
      <p>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_FREE' | translate }}</p>
    </ng-template>

    <ng-template #tooltipStatusCustomerOnBoard>
      <p>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_CUSTOMER_ON_BOARD' | translate }}</p>
    </ng-template>

    <ng-template #tooltipStatusCustomerUnreachable>
      <p>{{ 'HINT_DRIVER_ASSIGMENT_STATUS_CUSTOMER_UNREACHABLE' | translate }}</p>
    </ng-template>
    <!-- end  driver assignment-->
    <div class="flex card-container-tags">
      <div class="label-header">{{ 'PAYMENT_TYPE_' + form.controls?.paymentType.value.id | translate }}</div>
    </div>

    <div *ngIf="distanceETA === 0 && orderType === immediateOrder" class="flex">
      <div class="label-header">{{ 'CREATE_ORDER_ESTIMATIONS' | translate }}:</div>
    </div>
    <div
      *ngIf="
        (distanceETA === null && orderType === immediateOrder) || (distanceETA > 1 && orderType === immediateOrder)
      "
      class="label-header"
    >
      {{ 'CREATE_ORDER_ESTIMATIONS' | translate }}
    </div>

    @if (selectedDriver$ | async; as driver) {
      @if (driver.statuses?.[0] === statusEnum.FREE || driver.statuses?.[0] === statusEnum.CONNECTED) {
        <div class="flex">
          <azz-rotate-indicator7 size="extra-small" newDesign [visible]="isLoading$ | async" />
          <div class="card-label">{{ 'APPROACH_ETA' | translate }}:</div>
          <div class="label" *ngIf="driverPickupEstimation$ | async as estimation">
            <span>{{ estimation.meters / 1000 | number: '1.0-1' }} km | </span>
            <span>{{ estimation.seconds | azzSecondsToUIOrderTime }}</span>
          </div>
        </div>
      }
    } @else {
      <ng-container *ngTemplateOutlet="noDriverETATmpl" />
    }

    <div class="flex">
      <div class="card-label" *ngIf="distance === null || distance === undefined">
        {{ 'CREATE_ORDER_ESTIMATIONS' | translate }}:
      </div>
      @if (distance && orderType === immediateOrder) {
        <div class="card-label">{{ 'COURSE_ESTIMATION' | translate }}:</div>
      } @else if (distance && orderType !== immediateOrder) {
        <div class="card-label">{{ 'CREATE_ORDER_ESTIMATIONS' | translate }}:</div>
      }
      <div class="label">
        <span *ngIf="distance">{{ distance / 1000 | number: '1.0-1' }} km | </span>
        <span *ngIf="duration">{{ duration | azzSecondsToUIOrderTime }} | </span>
        <span *ngIf="isEstimatedPriceShown" class="pre-save__price-block">
          <azz-rotate-indicator7
            size="extra-small"
            newDesign
            [visible]="isEstimatedPriceLoading"
            class="pre-save__price-rotate"
          >
          </azz-rotate-indicator7>
          <span>{{ estimatedPrice }} <span *ngIf="!isEstimatedPriceLoading">&euro;</span></span>
        </span>
      </div>
    </div>
  </div>

  <div class="d-flex gap-2">
    <button class="btn btn-primary" tabindex="-1" [disabled]="azzDisabled || isLoading" (click)="onSuccess()">
      <azz-rotate-indicator7 [visible]="isLoading" newDesign />
      {{ 'PA_CREATE_CUSTOMER' | translate }}
    </button>

    <button
      tabindex="-1"
      class="btn btn-secondary"
      [disabled]="!form.touched || isLoading"
      (click)="onCancel()"
      [attr.aria-label]="'PA_CANCEL_ORDER_IN_SUMMARY' | translate"
    >
      <img src="assets/images/redesign/refresh.svg" alt="" />
    </button>
  </div>
</div>

<azz-pre-save-modal (azzClear)="resetFields()"></azz-pre-save-modal>

<ng-template #activeTooltipContent>
  <small class="hover-text" [innerHTML]="'PA_CUSTOMERS_STATUS_ACTIVE' | translate"> </small>
</ng-template>

<ng-template #blockedTooltipContent>
  <small class="hover-text" [innerHTML]="'PA_CUSTOMERS_STATUS_BLOCKED' | translate"> </small>
</ng-template>

<ng-template #noDriverETATmpl>
  @if (orderType === immediateOrder && distanceShowType) {
    @switch (distanceShowType) {
      @case ('hide-nothing-exists') {
        <div class="eta-block-label">
          <div class="card-label">{{ 'APPROACH_ETA' | translate }}:</div>
          <div class="exception-red exception-red__no-driver"></div>
          <div class="no-driver">
            {{ 'NO_DRIVERS_AVAILABLE' | translate }}
          </div>
        </div>
      }
      @case ('hide-something-exists') {
        <div class="eta-block-label">
          <div class="card-label">{{ 'APPROACH_ETA' | translate }}:</div>
          <div class="exception-red exception-red__no-driver"></div>
          <div class="no-driver">
            {{ 'NO_DRIVERS_AVAILABLE_WITH_TAGS' | translate }}
          </div>
        </div>
      }
      @case ('show') {
        @if (distanceETA === null) {
          <div class="eta-block-label">
            <div class="card-label">{{ 'APPROACH_ETA' | translate }}:</div>
            <div class="exception-red"></div>
            <div class="no-driver">
              {{ 'NO_DRIVERS_AVAILABLE' | translate }}
            </div>
          </div>
        } @else {
          <div class="flex">
            <azz-rotate-indicator7 size="extra-small" newDesign [visible]="isEtaLoading" />
            <div class="card-label" *ngIf="distanceETA">{{ 'APPROACH_ETA' | translate }}:</div>
            <div class="label">
              <span *ngIf="distanceETA">{{ distanceETA / 1000 | number: '1.0-1' }} km | </span>
              <span *ngIf="durationETA">{{ durationETA | azzSecondsToUIOrderTime }}</span>
            </div>
          </div>
        }
      }
    }
  }
</ng-template>
