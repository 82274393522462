import { G7_ORDER_PROCESSING_STATUS, G7_ORDER_STATUS } from '../../../../constant/g7-order.constant';
import { G7Order } from '../../../../models/g7-order.model';

const PROCESSING_STATUS_VALIDATION_MAP = {
  [G7_ORDER_PROCESSING_STATUS.WAITING_G7]: true,
  [G7_ORDER_PROCESSING_STATUS.CHANGED]: true,
};
const STATUS_VALIDATION_MAP = {
  [G7_ORDER_STATUS.PENDING]: true,
  [G7_ORDER_STATUS.TO_PAY]: true,
  [G7_ORDER_STATUS.IN_ERROR]: true,
};

const PROCESSING_STATUS_FLEET_MESSAGE = {
  [G7_ORDER_PROCESSING_STATUS.WAITING_FLEET]: true,
  [G7_ORDER_PROCESSING_STATUS.WAITING_G7]: true,
  [G7_ORDER_PROCESSING_STATUS.CHANGED]: true,
};

const PROCESSING_STATUS_G7_MESSAGE = {
  ...PROCESSING_STATUS_FLEET_MESSAGE,
  [G7_ORDER_PROCESSING_STATUS.VALIDATE_G7]: true,
};

const STATUS_MESSAGE_MAP = {
  [G7_ORDER_STATUS.PENDING]: true,
  [G7_ORDER_STATUS.TO_PAY]: true,
  [G7_ORDER_STATUS.IN_ERROR]: true,
};

export const isValidationAllowed = (order: G7Order): boolean =>
  !!PROCESSING_STATUS_VALIDATION_MAP[order?.processingStatus] && !!STATUS_VALIDATION_MAP[order?.status];

export const isFleetMessageAllowed = (order: G7Order): boolean =>
  !!PROCESSING_STATUS_FLEET_MESSAGE[order?.processingStatus] && !!STATUS_MESSAGE_MAP[order?.status];

export const isG7MessageAllowed = (order: G7Order): boolean =>
  !!PROCESSING_STATUS_G7_MESSAGE[order?.processingStatus] && !!STATUS_MESSAGE_MAP[order?.status];
