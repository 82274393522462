import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ORDER_LOG, OrderLogType } from '@core/constant/order-log.constant';
import { Store } from '@ngrx/store';

import { getTagsAction } from './store/actions';
import { IOrderLogsData } from '../../models/order-logs.model';
import { AppState } from '../../store/reducers';

@Component({
  selector: 'app-order-logs-table',
  templateUrl: './order-logs-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLogsTableComponent implements OnInit {
  @Input() public orderHistoryData: IOrderLogsData;
  @Input() public isAutoDispatch = false;
  @Input() public isLoading = false;
  @Output() public prevPage = new EventEmitter<number>();
  @Output() public nextPage = new EventEmitter<number>();
  @Output() public filterByType = new EventEmitter<{ checked: boolean; type: OrderLogType }>();
  public errorHistoryType = ORDER_LOG.ERROR;
  public dispatchHistoryType = ORDER_LOG.DISPATCH;

  constructor(private readonly store$: Store<AppState>) {}

  public ngOnInit(): void {
    this.store$.dispatch(getTagsAction());
  }

  public filterOrderHistoryByType(data: { checked: boolean; type: OrderLogType }): void {
    this.filterByType.emit(data);
  }

  public prevOrderHistoryPage(): void {
    this.prevPage.emit(this.getOrderHistoryCurrentPage() - 1);
  }

  public nextOrderHistoryPage(): void {
    this.nextPage.emit(this.getOrderHistoryCurrentPage() + 1);
  }

  public isPrevOrderHistoryDisabled(): boolean {
    return !this.orderHistoryData || this.orderHistoryData?.first;
  }

  public isNextOrderHistoryDisabled(): boolean {
    return !this.orderHistoryData || this.orderHistoryData?.last;
  }

  private getOrderHistoryCurrentPage(): number {
    return this.orderHistoryData ? this.orderHistoryData.number : 0;
  }
}
