<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6 azz-header customers-info-header">{{ 'ACTIONS' | translate }}</div>
    </div>
    <azz-loading-block [isLoading]="historyLoadingIndicator">
      <div class="azz-table-wrapper">
        <table class="table table-hover azz-table">
          <thead>
            <tr class="d-table-row">
              <th class="col-4">
                {{ 'DATE' | translate }}
              </th>
              <th class="col-4">
                {{ 'ACTION' | translate }}
              </th>
              <th class="col-4">
                {{ 'WHO' | translate }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="customerHistory?.content.length > 0">
            <!-- IE scrolling fix -->
            <tr class="azz-clickable" *ngFor="let item of customerHistory?.content">
              <td>
                <span *ngIf="item?.modificationDate">
                  {{ item?.modificationDate | date: 'dd.MM.yyyy' }},<br />
                  {{ item?.modificationDate | date: 'HH:mm' }}
                </span>
              </td>
              <td>{{ item?.action }}</td>
              <td>{{ item?.who }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <azz-table-no-matches [azzVisible]="!customerHistory?.content?.length"></azz-table-no-matches>
      <ng-content></ng-content>
    </azz-loading-block>
  </div>
</div>
