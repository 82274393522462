<div class="azz-header">
  <span>{{ 'PA_DETAILS_ORDERS_INFO' | translate }}</span>
  <span> - {{ 'REFERENCE_LABEL' | translate }}: {{ order?.reference }}</span>
  <span *ngIf="order?.partnerId"> - {{ 'AUTHORIZATION_NUMBER' | translate }}: {{ order?.partnerId }}</span>
</div>
<div class="row">
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'ORDER_DETAILS_ORDER_STATUS_LABEL' | translate }}</label>
    <div>{{ 'CORE_ORDER_STATUS_' + order?.status | translate }}</div>
  </div>
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'DATE_AND_TYPE_LABEL' | translate }}</label>
    <div>
      <span *ngIf="order?.date">{{ order?.date | date: 'dd.MM.yyyy HH:mm' }} / </span>
      <span *ngIf="order?.type">{{ 'ORDER_TYPE_' + order?.type | translate }}</span>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <form [formGroup]="dispatchTimeForm" (submit)="onDispatchTimeChanged()" class="full-order-price-form">
      <label class="azz-transparent-input-label">{{ 'DISPATCH_DEFAULT_BEFORE_MINUTES_LABEL' | translate }}</label>
      <div class="flex" [ngClass]="!canChangeDispatchTime ? 'disabled' : ''">
        <input
          #dispatchTimeInput
          type="text"
          formControlName="orderDispatchBeforeMinutes"
          class="form-control azz-transparent-input azz-validate"
          (focus)="onFocusEvent()"
          (blur)="onDispatchTimeChanged()"
          (input)="inputValidator($event)"
          (keydown.enter)="dispatchTimeInput.blur()"
          onpaste="return false"
        />
        <span
          class="full-order-price-input-spinner spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          [class.hidden]="!isUpdateDispatchTimeLoading"
        ></span>
        <div
          *ngIf="showCrossIcon"
          class="azz-delete-circle details__delete-btn"
          (mousedown)="$event.preventDefault()"
          (click)="clearDispatchTimeInput()"
        ></div>
      </div>
      <div
        class="form-control__error"
        *ngIf="
          dispatchTimeForm?.controls?.orderDispatchBeforeMinutes?.errors &&
          dispatchTimeForm.controls.orderDispatchBeforeMinutes.touched
        "
      >
        <small *ngIf="dispatchTimeForm?.controls?.orderDispatchBeforeMinutes?.errors?.required">
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="dispatchTimeForm?.controls?.orderDispatchBeforeMinutes?.errors?.min">
          {{ 'DISPATCH_BEFORE_MIN_LENGTH' | translate: { minLength: 1 } }}
        </small>
        <small *ngIf="dispatchTimeForm?.controls?.orderDispatchBeforeMinutes?.errors?.max">
          {{ 'MAX_LENGTH_ERROR' | translate: { maxLength: 360 } }}
        </small>
        <small *ngIf="dispatchTimeForm?.controls?.orderDispatchBeforeMinutes?.errors?.dispatchTimeInPast">
          {{ 'DISPATCH_DATE_IN_THE_PAST' | translate }}
        </small>
      </div>
    </form>
  </div>
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'DISPATCH_BEFORE_MINUTES_DATE_LABEL' | translate }}</label>
    <div>
      <span *ngIf="showOnlyHHmm; else fullDateFormat">{{ dispatchDate | date: 'HH:mm' }} </span>
      <ng-template #fullDateFormat>
        <span>{{ dispatchDate | date: 'dd.MM.yyyy HH:mm' }} </span>
      </ng-template>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6" style="display: flex">
    <div>
      <label class="azz-transparent-input-label">{{ 'FR_APPOINTMENT_ADDRESS_COL_HEADER' | translate }}</label>
      <div *ngIf="order?.appointmentAddress?.poi?.label">
        {{ order?.appointmentAddress?.poi?.label }}
      </div>
      <div *ngIf="!order?.appointmentAddress?.poi?.label">
        {{ order?.appointmentAddress?.number }}
        {{ order?.appointmentAddress?.street }},
        {{ order?.appointmentAddress?.city }}
      </div>
    </div>
    <div style="margin-left: 30px; margin-top: 20px">
      <span
        *ngIf="order?.addressOutsideBusinessZone"
        class="dispatch-outside-icon"
        [ngbTooltip]="outsideBusinessZoneTooltipTemplate"
      ></span>
      <ng-template #outsideBusinessZoneTooltipTemplate>
        <span [innerHTML]="'DISPATCH_OUTSIDE_BUSINESS_ZONE_TOOLTIP' | translate"></span>
      </ng-template>
    </div>
  </div>
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'FR_DETAILS_DESTINATION_ADDRESS_LABEL' | translate }}</label>
    <div *ngIf="order?.destinationAddress?.poi?.label">
      {{ order?.destinationAddress?.poi?.label }}
    </div>
    <div *ngIf="!order?.destinationAddress?.poi?.label">
      {{ order?.destinationAddress?.number }}
      {{ order?.destinationAddress?.street }},
      {{ order?.destinationAddress?.city }}
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <div *ngIf="order?.appointmentAddressComment">
      <div class="azz-transparent-input-label">
        {{ 'PA_CREATE_NEW_ORDER_ORDER_DEPART_COMMENT' | translate }}
      </div>
      <div>{{ order?.appointmentAddressComment }}</div>
    </div>
  </div>
  <div class="col-md-6">
    <div *ngIf="order?.destinationAddressComment">
      <div class="azz-transparent-input-label">
        {{ 'PA_CREATE_NEW_ORDER_ORDER_DEST_COMMENT' | translate }}
      </div>
      <div>{{ order?.destinationAddressComment }}</div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'ORDER_DETAILS_PAYMENT_MODE_LABEL' | translate }}</label>
    <div *ngIf="paymentTypeId">{{ 'PAYMENT_TYPE_' + paymentTypeId | translate }}</div>
  </div>
  <div *ngIf="isFacturation" class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'FACTURATION_STATUS' | translate }}</label>
    <div>{{ 'CORE_BILLING_ORDER_STATUS_' + order?.billingStatus | translate }}</div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'SERVICES_LABEL' | translate }}</label>
    <div *ngIf="serviceTypeId">{{ 'SERVICE_TYPE_' + serviceTypeId | translate }}</div>
  </div>
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'TYPE_LABEL' | translate }}</label>
    <div *ngIf="vehicleTypeId">{{ 'VEHICLE_TYPE_' + vehicleTypeId | translate }}</div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <label class="azz-transparent-input-label">{{ 'TOTAL_AMOUNT' | translate }}</label>
    <ng-container
      *ngIf="
        isFacturation && order?.status === statuses.FINISHED && order?.billingStatus === billingStatuses.NOT_VALIDATED;
        else readonlyPrice
      "
    >
      <form
        *ngIf="orderPriceForm"
        [formGroup]="orderPriceForm"
        (submit)="onPriceChanged()"
        class="full-order-price-form"
      >
        <input
          class="form-control azz-transparent-input azz-search-field"
          formControlName="price"
          (blur)="onPriceChanged()"
        />
        <span
          class="full-order-price-input-spinner spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          [class.hidden]="!priceChangeLoadingIndicator"
        ></span>
      </form>
    </ng-container>
    <ng-template #readonlyPrice>
      <div>{{ order?.price === -1 ? ('CORE_TAXIMETER' | translate) : order?.price }}</div>
    </ng-template>
  </div>
  <div class="col-md-6" *ngIf="tagIdsExceptServiceVehiclePaymentType?.length">
    <label class="azz-transparent-input-label">{{ 'COMPLEMENTS_AND_TAGS_HEADER' | translate }}</label>
    <div>
      <span class="value" *ngFor="let id of tagIdsExceptServiceVehiclePaymentType; let index = index">
        {{ 'CRC_TAG_' + id | translate
        }}<span *ngIf="index !== tagIdsExceptServiceVehiclePaymentType.length - 1">, </span>
      </span>
    </div>
  </div>
  <div class="col-md-6" *ngIf="order?.comment || order?.appointmentAddressComment || order?.destinationAddressComment">
    <label class="azz-transparent-input-label">{{ 'ORDER_DETAILS_COMMENT_LABEL' | translate }}</label>
    <div>{{ order?.comment }}</div>
  </div>
</div>
