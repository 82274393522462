import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { VehicleInfoServiceVehicleTypesComponent } from './service-vehicle-types/vehicle-info-service-vehicle-types.component';
import { VehicleInfoStoreModule } from './store/vehicle-info-store.module';
import { VehicleInfoComponent } from './vehicle-info.component';

@NgModule({
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, VehicleInfoStoreModule],
  declarations: [VehicleInfoComponent, VehicleInfoServiceVehicleTypesComponent],
  exports: [VehicleInfoComponent],
})
export class VehicleInfoModule {}
