import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IExceptionDataParams } from '../../../../models/exception.model';
import * as ExceptionsReducer from '../reducers';

export const exceptions = createFeatureSelector<ExceptionsReducer.State>(ExceptionsReducer.exceptionsFeatureKey);

export const exceptionsData = createSelector(exceptions, state => state?.exceptionsData);

export const loading = createSelector(exceptions, state => state.loading);

export const filterModel = createSelector(exceptions, state => state.filterModel);

export const pagination = createSelector(exceptions, state => state.pagination);

export const params = createSelector(
  filterModel,
  pagination,
  (filter, pag) =>
    ({
      ...filter,
      ...pag,
    }) as Partial<IExceptionDataParams>
);
