/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ErrorResponseWebApiModel } from '../model/error-response-web.api-model';
// @ts-ignore
import { FleetSearchFieldEnumWebApiModel } from '../model/fleet-search-field-enum-web.api-model';
// @ts-ignore
import { FleetSearchResponseWebApiModel } from '../model/fleet-search-response-web.api-model';
// @ts-ignore
import { SortDirectionEnumWebApiModel } from '../model/sort-direction-enum-web.api-model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { PragmaticConfiguration } from '../configuration';

export interface WebFleetPropertiesGetRequestParams {
  /** fleet ID for specific fleet properties */
  fleetId?: string;
  /** if fleet ID specified and withDefaults&#x3D;true, response will return fleet properties including default values */
  withDefaults?: boolean;
  /** return only listed properties if specified */
  propertyName?: Array<string>;
}

export interface WebFleetsSearchRequestParams {
  /** query string for search by fleet name */
  q?: string;
  /** sort field */
  sort?: FleetSearchFieldEnumWebApiModel;
  /** sort direction */
  direction?: SortDirectionEnumWebApiModel;
  /** page number if specified */
  pageIndex?: number;
  /** max number of returned objects */
  pageSize?: number;
}

@Injectable()
export class FleetApiClient {
  protected basePath = 'https://develop-proxy-rn7.g7.fr/backend/web2';
  public defaultHeaders = new HttpHeaders();
  public configuration = new PragmaticConfiguration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: PragmaticConfiguration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get fleet properties (stub)
   * A method for web to provide fleet properties (default or specific fleet if fleetId specified)
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webFleetPropertiesGet(
    requestParameters?: WebFleetPropertiesGetRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<object>;
  public webFleetPropertiesGet(
    requestParameters?: WebFleetPropertiesGetRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<object>>;
  public webFleetPropertiesGet(
    requestParameters?: WebFleetPropertiesGetRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<object>>;
  public webFleetPropertiesGet(
    requestParameters?: WebFleetPropertiesGetRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const fleetId = requestParameters?.fleetId;
    const withDefaults = requestParameters?.withDefaults;
    const propertyName = requestParameters?.propertyName;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (fleetId !== undefined && fleetId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fleetId, 'fleetId');
    }
    if (withDefaults !== undefined && withDefaults !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>withDefaults, 'withDefaults');
    }
    if (propertyName) {
      propertyName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'propertyName');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/fleets/properties`;
    return this.httpClient.request<object>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Available fleets search (stub)
   * A method for web to search fleets with query by name.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webFleetsSearch(
    requestParameters?: WebFleetsSearchRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<FleetSearchResponseWebApiModel>;
  public webFleetsSearch(
    requestParameters?: WebFleetsSearchRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<FleetSearchResponseWebApiModel>>;
  public webFleetsSearch(
    requestParameters?: WebFleetsSearchRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<FleetSearchResponseWebApiModel>>;
  public webFleetsSearch(
    requestParameters?: WebFleetsSearchRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const q = requestParameters?.q;
    const sort = requestParameters?.sort;
    const direction = requestParameters?.direction;
    const pageIndex = requestParameters?.pageIndex;
    const pageSize = requestParameters?.pageSize;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (q !== undefined && q !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>q, 'q');
    }
    if (sort !== undefined && sort !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sort, 'sort');
    }
    if (direction !== undefined && direction !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>direction, 'direction');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageIndex, 'pageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'pageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/fleets`;
    return this.httpClient.request<FleetSearchResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }
}
