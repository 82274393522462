import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IDriverUserInfo } from '../../../models/user-info.model';

@Component({
  selector: 'app-driver-details-blocked-driver',
  templateUrl: 'driver-details-blocked-driver.component.html',
  styleUrls: ['./driver-details-blocked-driver.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsBlockedDriverComponent {
  @Input() driver: IDriverUserInfo;
  @Input() ngDisabled: boolean;
  @Output() openUnblockPopup$ = new EventEmitter();

  public openUnblockPopup(): void {
    this.openUnblockPopup$.emit();
  }
}
