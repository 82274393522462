import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { ITag } from '@core/models/tag.model';

@Component({
  selector: 'attribute-tags',
  templateUrl: './attribute-tags.component.html',
  styleUrls: ['./attribute-tags.component.less'],
  providers: [NgOnDestroyService],
})
export class AttributeTagsComponent {
  @Input() listOfTags: ITag[];
  @Input() isTagsDisabled: boolean;
  @Output() setChangeTag$ = new EventEmitter();

  public isAllTagsAvailable = false;

  constructor() {}

  public getAllTags() {
    const shortListOfTags = this.listOfTags?.slice(0, 6);
    return this.isAllTagsAvailable ? this.listOfTags : shortListOfTags;
  }

  public onChangeTag(): void {
    this.setChangeTag$.emit();
  }

  public toggleTagsShown() {
    this.isAllTagsAvailable = !this.isAllTagsAvailable;
  }
}
