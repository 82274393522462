import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PhoneAdvisorService } from '@core/services/users';
import { Store } from '@ngrx/store';
import { RootService } from '@services/root.service';
import { boundMethod } from 'autobind-decorator';
import moment from 'moment';
import { BehaviorSubject, from, fromEventPattern, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import {
  Invitation,
  Inviter,
  InviterInviteOptions,
  Referral,
  Registerer,
  RegistererOptions,
  RegistererRegisterOptions,
  RegistererState,
  RegistererUnregisterOptions,
  RequestPendingError,
  Session,
  SessionInviteOptions,
  SessionState,
  UserAgent,
  UserAgentOptions,
  UserAgentState,
} from 'sip.js';
import { IncomingRequestMessage, OutgoingRegisterRequest, URI } from 'sip.js/lib/core';
import { IncomingResponse } from 'sip.js/lib/core/messages/incoming-response';
import { holdModifier, SessionDescriptionHandler } from 'sip.js/lib/platform/web';

import { ContactService } from './contact.service';
import { CtiNotifyService } from './cti-notify.service';
import { ACTIVE_SESSION_STATE, PBX_CONNECTION_STATE, REMOTE_STREAM_ACTION } from '../../constant/cti.constant';
import { IActiveSession, IContact, IContactUser, IExtensionForAssignedAgent } from '../../models/cti.model';

const WEB_SOCKET_PARAMS = {
  address: '35.195.122.219',
  port: '7443',
};

@Injectable({ providedIn: 'root' })
export class CtiService {
  protected readonly rootService = inject(RootService);

  public pbxConnection$ = new Subject<PBX_CONNECTION_STATE>();
  private userAgent: UserAgent;
  private registerer: Registerer | undefined = undefined;
  private readonly session$ = new BehaviorSubject<Session | null>(null); // active call without constraints (for program logic)
  private readonly incomingCall$ = new BehaviorSubject<IContact>(null);
  private readonly activeSession$ = new BehaviorSubject<IActiveSession | null>(null); // For UI (can be on hold mode)
  private readonly activeSessions$ = new BehaviorSubject<IActiveSession[] | null>(null);
  private readonly hasAtLeastOneSession$ = new BehaviorSubject<boolean>(null);
  private activeSessionsMapIterable = this.initActiveSessionsMapIterable();
  private readonly transportOptions = {
    server: `wss://${WEB_SOCKET_PARAMS.address}:${WEB_SOCKET_PARAMS.port}`,
  };
  private readonly constraints = { audio: true, video: false };
  private connectRequested = false;
  private attemptingReconnection = false;
  private readonly transmit$ = new Subject<IActiveSession>();
  private readonly sessionStateMap: { [state in SessionState]?: (session: Session) => void } = {
    [SessionState.Establishing]: (session: Session) => this.handleEstablishingSession(session),
    [SessionState.Established]: (session: Session) => this.handleEstablishedSession(session),
    [SessionState.Terminated]: (session: Session) => this.handleTerminatedSession(session),
  };
  private readonly untilContact$ = new Subject<void>();

  constructor(
    private readonly ctiNotifyService: CtiNotifyService,
    private readonly paService: PhoneAdvisorService,
    private readonly contactService: ContactService,
    private readonly store: Store
  ) {}

  public get session(): Session | null {
    return this.session$.getValue();
  }

  public get incomingCallContact(): IContact | null {
    return this.incomingCall$.getValue();
  }

  public get hasAtLeastOneSession(): boolean {
    return this.hasAtLeastOneSession$.getValue();
  }

  public get activeSessions(): IActiveSession[] {
    return this.activeSessions$.getValue();
  }

  private get activeSession(): IActiveSession {
    return this.activeSession$.getValue();
  }

  @boundMethod
  private onAcceptOutgoingSession() {
    console.log('onAcceptOutgoingSession');
    this.activeSession$.next({
      ...this.activeSession,
      state: ACTIVE_SESSION_STATE.ESTABLISHED,
      date: moment().valueOf(),
    });
    this.activeSessionsMapIterable[this.activeSession.id] = this.activeSession;
    this.updateActiveSessions();
  }

  @boundMethod
  private onRejectOutgoingSession(response: IncomingResponse) {
    if (response.message.statusCode === 480) {
      this.rootService.alerts.error('NO_USER_ANSWER');
    }
  }

  @boundMethod
  private onDisconnect(error?: Error) {
    if (this.session) {
      this.hangup() // cleanup hung calls
        .subscribe(
          () => console.log('onDisconnect'),
          (e: Error) => console.error('Error occurred hanging up call after connection with server was lost.')
        );
    }
    if (this.registerer) {
      console.log('Unregistering...');
      this.registerer
        .unregister() // cleanup invalid registrations
        .catch((err: Error) => {
          console.error('[Error occurred unregistering after connection with server was lost.', err.toString());
        });
    }
    // Only attempt to reconnect if network/server dropped the connection.
    if (error) {
      console.log('ON DISCONNECT', this);
      this.attemptReconnection();
    }
  }

  @boundMethod
  private onInvite(invitation: Invitation) {
    // Reject any incoming INVITE if there is any active session
    if (this.session) {
      this.rejectInvitation(invitation);
      return;
    }
    this.initSession(invitation);
    this.getContactForIncomingCall(invitation?.remoteIdentity?.displayName);
    // this.getContactForIncomingCall('1498274335');
  }

  @boundMethod
  private onReInvite(request: IncomingRequestMessage, response: string, statusCode: number): void {
    console.log('onReInvite', request);
    // if (`${request.callId}${request.fromTag}` === this.activeSession.session.id) {
    //   console.log(this.activeSession);
    //   this.activeSession = this.activeSession.isHold ?
    //     this.activeSession = {...this.activeSession, isHold: false, isHoldDisabled: false, holdDate: null} :
    //     this.activeSession = {...this.activeSession, isHold: true, isHoldDisabled: true, holdDate: moment().valueOf()};
    //   this.activeSessionsMapIterable[this.activeSession.id] = this.activeSession;
    //   this.updateActiveSessions();
    //   this.ctiNotifyService.sendActiveSessionsChanged();
    //   this.ctiNotifyService.sendActiveSessionChanged();
    // }
  }

  public getPbxConnection$(): Observable<PBX_CONNECTION_STATE> {
    return this.pbxConnection$.asObservable();
  }

  public getIncomingCall(): Observable<IContact> {
    return this.incomingCall$.asObservable();
  }

  public getSession(): Observable<Session | undefined> {
    return this.session$.asObservable();
  }

  public getActiveSession(): Observable<IActiveSession> {
    return this.activeSession$.asObservable();
  }

  public getActiveSessions(): Observable<IActiveSession[]> {
    return this.activeSessions$.asObservable();
  }

  public getTransmit(): Observable<IActiveSession> {
    return this.transmit$.asObservable();
  }

  public sendTransmit(activeSession: IActiveSession): void {
    this.transmit$.next(activeSession);
  }

  // CREATE USER AGENT
  public createUserAgent(extensionData: IExtensionForAssignedAgent): void {
    this.userAgent = new UserAgent(this.generateUserAgentOptions(extensionData));
    this.connect()
      .pipe(
        mergeMap(() => this.register()),
        mergeMap(() => this.paService.loginAgent())
      )
      .subscribe(() => this.pbxConnection$.next(PBX_CONNECTION_STATE.ACTIVE));
  }

  public disconnect(): Observable<void> {
    console.log('Disconnecting UserAgent...');
    this.connectRequested = false;
    return from(this.userAgent.stop());
  }

  public unregister(registererUnregisterOptions?: RegistererUnregisterOptions): Observable<void> {
    if (!this.registerer) {
      return from(Promise.resolve());
    }

    return from(this.registerer.unregister(registererUnregisterOptions).then(() => {}));
  }

  public replyCall(): Observable<void> {
    // TODO Find way how to get information about CALLER to put this info into activeSession
    if (!this.session) {
      return throwError('Session does not exist.');
    }
    if (!(this.session instanceof Invitation)) {
      return throwError('Session not instance of Invitation.');
    }
    const invitationAcceptOptions = {
      sessionDescriptionHandlerOptions: {
        constraints: this.constraints,
      },
    };
    return from(
      this.session
        .accept(invitationAcceptOptions)
        .then(() => this.handleSuccessReplyCall())
        .catch(() => this.handleFailureReplyCall())
    );
  }

  public transmit(activeSession: IActiveSession, user: IContactUser): Observable<void> {
    const target = UserAgent.makeURI(`sip:${user.userAgentName}`);
    return from(this.activeSession.session.refer(target).then(res => console.log('refer response', res)));
  }

  public hangup(): Observable<void> {
    // ToDo think about using concrete session as argument for hangup btn
    return this.terminate();
  }

  public hold(activeSession: IActiveSession): Observable<any> {
    return this.setHold(true, activeSession);
  }

  public unhold(activeSession: IActiveSession): Observable<any> {
    return this.setHold(false, activeSession);
  }

  public call(user: IContact): Observable<void> {
    if (this.session) {
      return throwError('SESSION ALREADY EXISTS');
    }
    const target = UserAgent.makeURI(`sip:${user.phone.phoneNumber}`);
    // const target = 'hasOnlyPhoneNumber' in user ?
    //   UserAgent.makeURI(`tel:${user.phoneNumber}`) :
    //   UserAgent.makeURI(`sip:${user.userAgentName}`);
    if (!target) {
      return throwError('Failed to create a valid URI');
    }
    // Create a new Inviter for the outgoing Session
    const inviter = new Inviter(this.userAgent, target);
    this.initSession(inviter);
    return user.isUnknown ? this.callUnknownUser(inviter, user) : this.callKnownUser(inviter, user);
    // this.createDialingActiveSession(inviter, user);
    // return this.sendInvite(inviter, this.generateInviterInviteOptions());
  }

  private initActiveSessionsMapIterable(): Iterable<IActiveSession> {
    return {
      [Symbol.iterator]() {
        const activeSessions = Object.values(this);
        let index = 0;

        return {
          next: (): { value: IActiveSession; done: boolean } => {
            const dontHaveMoreSessions = index >= activeSessions.length;
            if (dontHaveMoreSessions) {
              return { value: undefined, done: true };
            }
            return { value: activeSessions[index++] as IActiveSession, done: false };
          },
        };
      },
    };
  }

  private generateUserAgentOptions(extensionData: IExtensionForAssignedAgent): UserAgentOptions {
    return {
      uri: this.createUserAgentUri(extensionData.name),
      authorizationUsername: this.getAuthorizationUserName(extensionData.name),
      authorizationPassword: extensionData.password,
      transportOptions: this.transportOptions,
      logBuiltinEnabled: false, // Indicates whether log messages should be written to the browser console.
      noAnswerTimeout: 60, //  Number of seconds after which an incoming call is rejected if not answered.
      delegate: {
        onInvite: this.onInvite,
        onDisconnect: this.onDisconnect,
      },
    };
  }

  private createUserAgentUri(userAgentName: string): URI | undefined {
    const uri = UserAgent.makeURI(`sip:${userAgentName}`);
    if (!uri) {
      throw new Error(`Failed to create valid URI from sip:${userAgentName}`);
    }
    return uri;
  }

  private connect(): Observable<void> {
    this.connectRequested = true;
    return this.userAgent.state !== UserAgentState.Started
      ? from(this.userAgent.start())
      : from(this.userAgent.reconnect());
  }

  private register(
    registererOptions?: RegistererOptions,
    registererRegisterOptions?: RegistererRegisterOptions
  ): Observable<OutgoingRegisterRequest> {
    if (!this.registerer) {
      this.registerer = new Registerer(this.userAgent, registererOptions);
      this.listenForRegisterState(this.registerer);
    }
    return from(this.registerer.register(registererRegisterOptions));
  }

  private listenForRegisterState(registerer: Registerer): void {
    const addRegistererStateHandler = handler => {
      registerer.stateChange.addListener(handler);
    };
    const removeRegistererStateHandler = handler => {
      registerer.stateChange.removeListener(handler);
      registerer = undefined;
    };
    fromEventPattern(addRegistererStateHandler, removeRegistererStateHandler)
      .pipe(takeWhile(state => state !== RegistererState.Terminated))
      .subscribe();
  }

  private getContactByPhone$(phone: string | number): Observable<IContact> {
    return this.contactService.getContactByPhone(encodeURIComponent(phone)).pipe(
      takeUntil(this.untilContact$),
      catchError((err: HttpErrorResponse) => this.handleIncomingCallContactError(err, phone))
    );
  }

  private getContactForIncomingCall(phone: string | number): void {
    this.getContactByPhone$(phone).subscribe((res: IContact) => this.handleIncomingCallContact(res));
  }

  private handleIncomingCallContact(user: IContact): void {
    this.incomingCall$.next(user);
  }

  private handleIncomingCallContactError(err: HttpErrorResponse, phone: string | number): Observable<IContact> {
    return err.status === 404 ? of({ phone: { phoneNumber: phone }, isUnknown: true } as IContact) : throwError(err);
  }

  private rejectInvitation(invitation: Invitation): void {
    from(invitation.reject()).subscribe(
      () => console.log('Session already in progress, Rejected INVITE'),
      err => console.log('Failed to reject INVITE', err)
    );
  }

  private initSession(session: Session): void {
    this.setSessionDelegate(session);
    this.session$.next(session);
    this.listenForSessionState(session);
  }

  private setSessionDelegate(session: Session): void {
    session.delegate = {
      onRefer: (referral: Referral): void => {
        console.log('Handle incoming REFER request', referral);
      },
      onInvite: this.onReInvite, // TODO invoked repeatedly. Need to implement logic handling if this is hold or unhold
    };
  }

  private listenForSessionState(session: Session) {
    const addSessionStateHandler = handler => {
      session.stateChange.addListener(handler);
    };
    const removeSessionStateHandler = handler => {
      session.stateChange.removeListener(handler);
    };

    fromEventPattern(addSessionStateHandler, removeSessionStateHandler)
      .pipe(
        tap(state => {
          if (this.sessionStateMap[state as SessionState]) {
            this.sessionStateMap[state as SessionState](session);
          }
        }),
        takeWhile(state => state !== SessionState.Terminated)
      )
      .subscribe();
  }

  private handleTerminatedSession(session: Session): void {
    this.removeSession(session);
    this.stopSessionMediaStream(session);
  }

  private handleEstablishingSession(session: Session): void {
    if (session instanceof Invitation) {
      this.handleIncomingSession(session);
    }
  }

  private handleEstablishedSession(session: Session): void {
    this.setupRemoteMedia(session);
  }

  private handleIncomingSession(session: Session): void {
    this.activeSession$.next({
      id: session.id,
      session,
      state: ACTIVE_SESSION_STATE.CONNECTING,
      user: this.incomingCallContact,
    });
    this.activeSessionsMapIterable[session.id] = this.activeSession;
    this.updateActiveSessions();
  }

  private callKnownUser(inviter: Inviter, user: IContact): Observable<void> {
    this.createDialingActiveSession(inviter, user);
    return this.sendInvite(inviter, this.generateInviterInviteOptions());
  }

  private callUnknownUser(inviter: Inviter, user: IContact): Observable<void> {
    return this.getContactByPhone$(user?.phone?.phoneNumber).pipe(
      mergeMap((contact: IContact) => {
        this.createDialingActiveSession(inviter, contact);
        return this.sendInvite(inviter, this.generateInviterInviteOptions());
      })
    );
  }

  private generateInviterInviteOptions(): InviterInviteOptions {
    return {
      requestDelegate: {
        onAccept: this.onAcceptOutgoingSession,
        onReject: this.onRejectOutgoingSession,
      },
      sessionDescriptionHandlerOptions: { constraints: this.constraints },
    };
  }

  private createDialingActiveSession(session: Session, user: IContact): void {
    this.activeSession$.next({ id: session.id, session, user, state: ACTIVE_SESSION_STATE.DIALING });
    this.activeSessionsMapIterable[session.id] = this.activeSession;
    this.updateActiveSessions();
  }

  private sendInvite(inviter: Inviter, options?: InviterInviteOptions): Observable<void> {
    return from(inviter.invite(options).then(() => this.handleSuccessSendInvite()));
  }

  private handleSuccessSendInvite(): void {
    this.activeSession$.next({ ...this.activeSession, state: ACTIVE_SESSION_STATE.RINGING });
    this.activeSessionsMapIterable[this.activeSession.id] = this.activeSession;
    this.updateActiveSessions();
  }

  private handleSuccessReplyCall(): void {
    if (this.session) {
      // Can be if callee reply a call, and caller hangup before starting a call
      this.activeSession$.next({
        ...this.activeSession,
        state: ACTIVE_SESSION_STATE.ESTABLISHED,
        date: moment().valueOf(),
      });
      this.activeSessionsMapIterable[this.activeSession.id] = this.activeSession;
      this.updateActiveSessions();
    }
  }

  private handleFailureReplyCall(): void {
    // TODO Handle failure attempt to reply call
  }

  private updateActiveSessions(): void {
    const sortedSessions = this.getSortedActiveSessionsArray();
    this.activeSessions$.next(sortedSessions);
    this.hasAtLeastOneSession$.next(!!sortedSessions.length);
  }

  private getSortedActiveSessionsArray(): IActiveSession[] {
    return Array.from(this.activeSessionsMapIterable).sort(this.sortActiveSessions);
  }

  private sortActiveSessions(a: IActiveSession, b: IActiveSession) {
    const aIsPending = a.isHold ? 1 : 0; // convert boolean to number
    const bIsPending = b.isHold ? 1 : 0; // convert boolean to number
    const aDate = a.date;
    const bDate = b.date;

    if (aIsPending > bIsPending) {
      return 1;
    }
    if (aIsPending < bIsPending) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }
    if (aDate < bDate) {
      return -1;
    }
  }

  private removeSession(session: Session): void {
    delete this.activeSessionsMapIterable[session.id];
    this.updateActiveSessions();
    if (this.activeSession && this.activeSession.id === session.id) {
      this.activeSession$.next(this.getNextActiveSession());
    }
    if (this.session && this.session.id === session.id) {
      this.session$.next(null);
      if (session instanceof Invitation) {
        this.incomingCall$.next(null);
        this.untilContact$.next(null);
      }
    }
  }

  private getNextActiveSession(): IActiveSession | null {
    const arr = this.getSortedActiveSessionsArray();
    return arr.length ? arr[0] : null;
  }

  private setupRemoteMedia(session: Session): void {
    if (!session) {
      throw new Error('Session does not exist.');
    }
    const remoteStream = this.getRemoteMediaStream(session);
    if (!remoteStream) {
      throw new Error('Remote media stream undefined.');
    }
    // If a track is added or removed, load and restart playback of media.
    remoteStream.onaddtrack = (): void => {
      this.ctiNotifyService.sendRemoteStreamAction({ action: REMOTE_STREAM_ACTION.TOGGLE_TRACK });
    };
    remoteStream.onremovetrack = (): void => {
      this.ctiNotifyService.sendRemoteStreamAction({ action: REMOTE_STREAM_ACTION.TOGGLE_TRACK });
    };
    this.ctiNotifyService.sendRemoteStreamAction({
      action: REMOTE_STREAM_ACTION.SETUP,
      stream: remoteStream,
    });
  }

  private getRemoteMediaStream(session: Session): MediaStream | undefined {
    const sdh = session.sessionDescriptionHandler;
    if (!sdh) {
      return undefined;
    }
    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error('Session description handler not instance of web SessionDescriptionHandler');
    }
    return sdh.remoteMediaStream;
  }

  private stopSessionMediaStream(session: Session): void {
    const remoteStream = this.getRemoteMediaStream(session);
    if (!remoteStream) {
      console.log('Remote media stream undefined.');
      return;
    }
    remoteStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    this.ctiNotifyService.sendRemoteStreamAction({ action: REMOTE_STREAM_ACTION.CLEAN_UP });
  }

  private terminate(): Observable<void> {
    if (!this.session) {
      return throwError('Session does not exist.');
    }
    switch (this.session.state) {
      case SessionState.Initial:
        if (this.session instanceof Inviter) {
          return from(this.session.cancel());
        } else if (this.session instanceof Invitation) {
          return from(this.session.reject());
        } else {
          throw new Error('Unknown session type.');
        }
      case SessionState.Establishing:
        if (this.session instanceof Inviter) {
          return from(this.session.cancel());
        } else if (this.session instanceof Invitation) {
          return from(this.session.reject());
        } else {
          throw new Error('Unknown session type.');
        }
      case SessionState.Established:
        return from(this.session.bye().then(() => {}));
      case SessionState.Terminating:
        break;
      case SessionState.Terminated:
        break;
      default:
        throwError('Unknown state');
    }
  }

  private setHold(hold: boolean, activeSession: IActiveSession): Observable<any> {
    const session = activeSession.session;
    if (!session) {
      return throwError('Session does not exist.');
    }

    const sessionDescriptionHandler = session.sessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
    }

    // Set the session's SDH re-INVITE modifiers to produce the appropriate SDP offer to place call on hold
    session.sessionDescriptionHandlerModifiersReInvite = hold ? [holdModifier] : [];
    // Send re-INVITE
    return from(
      session
        .invite(this.generateHoldSessionInviteOptions(hold, activeSession))
        .then(() => {
          console.log('THEN RE-INVITE');
          // Reset the session's SDH re-INVITE modifiers. Note that if the modifiers are not reset, they will be
          // applied to the SDP answer as well (which we do not want in this case).
          session.sessionDescriptionHandlerModifiersReInvite = [];
          this.enableSenderTracks(!hold, session); // mute/unmute
          if (hold) {
            this.ctiNotifyService.sendRemoteStreamAction({ action: REMOTE_STREAM_ACTION.CLEAN_UP });
          } else {
            this.ctiNotifyService.sendRemoteStreamAction({
              action: REMOTE_STREAM_ACTION.SETUP,
              stream: this.getRemoteMediaStream(session),
            });
          }
          // Make possible to get other calls while session is on hold
          this.session$.next(hold ? null : session);
        })
        .catch((error: Error) => {
          console.log('ERROR', error);
          if (error instanceof RequestPendingError) {
            console.log('A hold request is already in progress.');
          }
        })
    );
  }

  private generateHoldSessionInviteOptions(hold: boolean, activeSession: IActiveSession): SessionInviteOptions {
    return {
      requestDelegate: {
        onAccept: (): void => {
          console.log('ON ACCEPT RE-INVITE');
          this.activeSession$.next({
            ...activeSession,
            isHold: hold,
            holdDate: hold ? moment().valueOf() : null,
          });
          this.activeSessionsMapIterable[this.activeSession.id] = this.activeSession;
          this.updateActiveSessions();
        },
        onReject: (): void => {
          console.log('Re-invite was rejected');
        },
      },
    };
  }

  /** Helper function to enable/disable media tracks. */
  private enableSenderTracks(enable: boolean, session: Session): void {
    if (!session) {
      throw new Error('Session does not exist.');
    }
    const sessionDescriptionHandler = session.sessionDescriptionHandler as SessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
    }
    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }
    peerConnection.getSenders().forEach(sender => {
      if (sender.track) {
        sender.track.enabled = enable;
      }
    });
  }

  private attemptReconnection(reconnectionAttempt = 1): void {
    const reconnectionAttempts = 3;
    const reconnectionDelay = 5;

    if (!this.connectRequested) {
      console.log('Reconnection not currently desired');
      return; // If intentionally disconnected, don't reconnect.
    }

    if (this.attemptingReconnection) {
      console.log('Reconnection attempt already in progress');
    }

    if (reconnectionAttempt > reconnectionAttempts) {
      console.log('Reconnection maximum attempts reached');
      this.pbxConnection$.next(PBX_CONNECTION_STATE.LOST);
      return;
    }

    if (reconnectionAttempt === 1) {
      console.log(`Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying`);
    } else {
      console.log(
        `Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying in ${reconnectionDelay} seconds`
      );
    }

    this.attemptingReconnection = true;
    this.pbxConnection$.next(PBX_CONNECTION_STATE.TRYING);
    setTimeout(
      () => {
        if (!this.connectRequested) {
          console.log(`Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - aborted`);
          this.attemptingReconnection = false;
          return; // If intentionally disconnected, don't reconnect.
        }
        this.connect()
          .pipe(
            mergeMap(() => this.register()),
            mergeMap(() => this.paService.loginAgent())
          )
          .subscribe(
            () => {
              console.log(`Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - succeeded`);
              this.attemptingReconnection = false;
              this.pbxConnection$.next(PBX_CONNECTION_STATE.ACTIVE);
            },
            () => {
              console.log(`Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - failed`);
              this.attemptingReconnection = false;
              this.attemptReconnection(++reconnectionAttempt);
            }
          );
      },
      // reconnectionAttempt === 1 ? 0 : reconnectionDelay * 1000
      0
    );
  }

  private getAuthorizationUserName(extensionName: string): string {
    return extensionName.split('@')[0];
  }
}
