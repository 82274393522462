/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { CreateOrderRequestWebApiModel } from '../model/create-order-request-web.api-model';
// @ts-ignore
import { CreateOrderResponseWebApiModel } from '../model/create-order-response-web.api-model';
// @ts-ignore
import { ErrorResponseWebApiModel } from '../model/error-response-web.api-model';
// @ts-ignore
import { OrderResponseWebApiModel } from '../model/order-response-web.api-model';
// @ts-ignore
import { OrderSearchResponseWebApiModel } from '../model/order-search-response-web.api-model';
// @ts-ignore
import { OrderSortFieldEnumWebApiModel } from '../model/order-sort-field-enum-web.api-model';
// @ts-ignore
import { OrderStatusEnumWebApiModel } from '../model/order-status-enum-web.api-model';
// @ts-ignore
import { OrdersCountResponseWebApiModel } from '../model/orders-count-response-web.api-model';
// @ts-ignore
import { SortDirectionEnumWebApiModel } from '../model/sort-direction-enum-web.api-model';
// @ts-ignore
import { UpdateOrderRequestWebApiModel } from '../model/update-order-request-web.api-model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { PragmaticConfiguration } from '../configuration';

export interface WebOrderCancelRequestParams {
  /** RN7 order ID */
  orderId: string;
}

export interface WebOrderCreationRequestParams {
  /** order creation body */
  createOrderRequestWebApiModel?: CreateOrderRequestWebApiModel;
}

export interface WebOrderFindByIdRequestParams {
  /** RN7 order ID */
  orderId: string;
}

export interface WebOrderSearchRequestParams {
  /** search results starting from specified date in format (YYYY-MM-DD) including */
  dateFrom?: string;
  /** search results up to specified date in format (YYYY-MM-DD) including */
  dateTo?: string;
  /** list of statuses for filtering */
  statuses?: Array<OrderStatusEnumWebApiModel>;
  /** driver ID if present */
  driverId?: string;
  /** customer ID if present */
  customerId?: string;
  /** fleet ID if present */
  fleetId?: string;
  /** N authorization from parameter partnerId */
  authorization?: string;
  /** order reference */
  reference?: string;
  /** appointment address search string */
  pickup?: string;
  /** destination address search string */
  dropoff?: string;
  /** should zero price orders be included into results or not */
  zeroPrice?: boolean;
  /** sort field name */
  sort?: OrderSortFieldEnumWebApiModel;
  /** sort direction */
  direction?: SortDirectionEnumWebApiModel;
  /** page number if specified */
  pageIndex?: number;
  /** max number of returned objects */
  pageSize?: number;
}

export interface WebOrderUpdateRequestParams {
  /** RN7 order ID */
  orderId: string;
  /** Order update */
  updateOrderRequestWebApiModel?: UpdateOrderRequestWebApiModel;
}

@Injectable()
export class OrderApiClient {
  protected basePath = 'https://develop-proxy-rn7.g7.fr/backend/web2';
  public defaultHeaders = new HttpHeaders();
  public configuration = new PragmaticConfiguration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: PragmaticConfiguration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Cancel order (stub)
   * A method for WEB to cancel an order
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webOrderCancel(
    requestParameters: WebOrderCancelRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public webOrderCancel(
    requestParameters: WebOrderCancelRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public webOrderCancel(
    requestParameters: WebOrderCancelRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public webOrderCancel(
    requestParameters: WebOrderCancelRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const orderId = requestParameters?.orderId;
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling webOrderCancel.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/cancel`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Order creation (stub) (incomplete)
   * A method for web to create an order in RN7
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webOrderCreation(
    requestParameters?: WebOrderCreationRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<CreateOrderResponseWebApiModel>;
  public webOrderCreation(
    requestParameters?: WebOrderCreationRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<CreateOrderResponseWebApiModel>>;
  public webOrderCreation(
    requestParameters?: WebOrderCreationRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<CreateOrderResponseWebApiModel>>;
  public webOrderCreation(
    requestParameters?: WebOrderCreationRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const createOrderRequestWebApiModel = requestParameters?.createOrderRequestWebApiModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/orders`;
    return this.httpClient.request<CreateOrderResponseWebApiModel>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: createOrderRequestWebApiModel,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Search order by RN7 ID (stub) (incomplete)
   * A method for web to find order by ID
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webOrderFindById(
    requestParameters: WebOrderFindByIdRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrderResponseWebApiModel>;
  public webOrderFindById(
    requestParameters: WebOrderFindByIdRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrderResponseWebApiModel>>;
  public webOrderFindById(
    requestParameters: WebOrderFindByIdRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrderResponseWebApiModel>>;
  public webOrderFindById(
    requestParameters: WebOrderFindByIdRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const orderId = requestParameters?.orderId;
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling webOrderFindById.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<OrderResponseWebApiModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Order search (stub) (incomplete)
   * A method for web to find orders by query
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webOrderSearch(
    requestParameters?: WebOrderSearchRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrderSearchResponseWebApiModel>;
  public webOrderSearch(
    requestParameters?: WebOrderSearchRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrderSearchResponseWebApiModel>>;
  public webOrderSearch(
    requestParameters?: WebOrderSearchRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrderSearchResponseWebApiModel>>;
  public webOrderSearch(
    requestParameters?: WebOrderSearchRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const dateFrom = requestParameters?.dateFrom;
    const dateTo = requestParameters?.dateTo;
    const statuses = requestParameters?.statuses;
    const driverId = requestParameters?.driverId;
    const customerId = requestParameters?.customerId;
    const fleetId = requestParameters?.fleetId;
    const authorization = requestParameters?.authorization;
    const reference = requestParameters?.reference;
    const pickup = requestParameters?.pickup;
    const dropoff = requestParameters?.dropoff;
    const zeroPrice = requestParameters?.zeroPrice;
    const sort = requestParameters?.sort;
    const direction = requestParameters?.direction;
    const pageIndex = requestParameters?.pageIndex;
    const pageSize = requestParameters?.pageSize;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>dateFrom, 'dateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>dateTo, 'dateTo');
    }
    if (statuses) {
      statuses.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'statuses');
      });
    }
    if (driverId !== undefined && driverId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>driverId, 'driverId');
    }
    if (customerId !== undefined && customerId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>customerId, 'customerId');
    }
    if (fleetId !== undefined && fleetId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fleetId, 'fleetId');
    }
    if (authorization !== undefined && authorization !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>authorization, 'authorization');
    }
    if (reference !== undefined && reference !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>reference, 'reference');
    }
    if (pickup !== undefined && pickup !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pickup, 'pickup');
    }
    if (dropoff !== undefined && dropoff !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>dropoff, 'dropoff');
    }
    if (zeroPrice !== undefined && zeroPrice !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>zeroPrice, 'zeroPrice');
    }
    if (sort !== undefined && sort !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sort, 'sort');
    }
    if (direction !== undefined && direction !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>direction, 'direction');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageIndex, 'pageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'pageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/orders`;
    return this.httpClient.request<OrderSearchResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Order update (stub) (incomplete)
   * A method for WEB to update an order in RN7
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webOrderUpdate(
    requestParameters: WebOrderUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public webOrderUpdate(
    requestParameters: WebOrderUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public webOrderUpdate(
    requestParameters: WebOrderUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public webOrderUpdate(
    requestParameters: WebOrderUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const orderId = requestParameters?.orderId;
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling webOrderUpdate.');
    }
    const updateOrderRequestWebApiModel = requestParameters?.updateOrderRequestWebApiModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateOrderRequestWebApiModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Orders counts (stub)(to discuss)
   * A method for web to find numbers of orders grouped by type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webOrdersCount(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrdersCountResponseWebApiModel>;
  public webOrdersCount(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrdersCountResponseWebApiModel>>;
  public webOrdersCount(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrdersCountResponseWebApiModel>>;
  public webOrdersCount(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/orders/count`;
    return this.httpClient.request<OrdersCountResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }
}
