import { ActionReducerMap } from '@ngrx/store';

import * as AuthInfoReducer from './auth-info.reducer';
import * as ForgotPasswordReducer from './forgot-password.reducer';
import * as NewPasswordReducer from './new-password.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
  [AuthInfoReducer.authInfoFeatureKey]: AuthInfoReducer.State;
  [ForgotPasswordReducer.forgotPasswordFeatureKey]: ForgotPasswordReducer.State;
  [NewPasswordReducer.newPasswordFeatureKey]: NewPasswordReducer.State;
}

export const reducers: ActionReducerMap<AuthState> = {
  [AuthInfoReducer.authInfoFeatureKey]: AuthInfoReducer.reducer,
  [ForgotPasswordReducer.forgotPasswordFeatureKey]: ForgotPasswordReducer.reducer,
  [NewPasswordReducer.newPasswordFeatureKey]: NewPasswordReducer.reducer,
};
