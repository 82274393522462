<section class="row azz-info-blocks-row azz-last poi pragmatic">
  <div class="col-12">
    <div class="azz-info-block">
      <div class="row">
        <section class="col-lg-5">
          <section class="poi__search-group">
            <azz-search-point-of-interest (filterPoiByLabel$)="filterPoiByLabel($event)">
            </azz-search-point-of-interest>
          </section>
          <section class="poi__buttons-group">
            <button class="azz-add-block poi__btn poi__btn--add" type="button" (click)="navigateToCreatePoiPage()">
              <span class="azz-add-button"></span>
              {{ 'CREATE_NEW_POI' | translate }}
            </button>
            <button class="azz-add-block poi__btn poi__btn--import" type="button" (click)="openImportPoiDialog()">
              <span class="azz-add-button"></span>
              {{ 'IMPORT_POIS' | translate }}
            </button>
          </section>
          <section class="poi__table-group">
            <azz-loading-block [isLoading]="tableLoadingIndicator">
              <azz-table-points-of-interest
                [poiData]="poiTableData"
                [hasRestrictionToShowG7Poi]="hasRestrictionToShowG7Poi"
                (deletePoi)="onDeletePoi($event)"
                (updatePoi)="navigateToEditPage($event)"
                (clickPoi)="onPoiClick($event)"
              >
                <azz-pagination
                  (goToPrevPage)="prevTablePage()"
                  (goToNextPage)="nextTablePage()"
                  [prevDisabled]="isPrevTableBtnDisabled()"
                  [nextDisabled]="isNextTableBtnDisabled()"
                >
                </azz-pagination>
              </azz-table-points-of-interest>
            </azz-loading-block>
          </section>
        </section>
        <section class="col-lg-7">
          <azz-loading-block [isLoading]="mapLoadingIndicator">
            <azz-map-points-of-interest
              [poiData]="poiMapData"
              (filterPoiByMarker$)="filterPoiByMarker($event)"
              (resetFilterPoiByMarker$)="resetFilterPoiByMarker()"
            >
            </azz-map-points-of-interest>
          </azz-loading-block>
        </section>
      </div>
    </div>
  </div>
</section>

<azz-import-points-of-interest-dialog
  [downloadUrl]="downloadCsvUrl"
  (poiSuccessImport)="onPoiSuccessfullyImport()"
  (poiErrorImport)="onPoiImportError($event)"
>
</azz-import-points-of-interest-dialog>
<azz-delete-point-of-interest-dialog (azzConfirm)="deletePoi($event)"></azz-delete-point-of-interest-dialog>
<azz-change-poi-error-dialog></azz-change-poi-error-dialog>
<azz-import-poi-error-dialog></azz-import-poi-error-dialog>
