<section class="azz-info-blocks-row azz-last">
  <div class="azz-info-block">
    <div class="azz-header">
      {{ (customersData$ | async)?.totalElements || 0 }} {{ 'PA_CUSTOMERS_LABEL' | translate }}
    </div>

    <app-customers-filters [filterModel]="filterModel$ | async" (updateFilterModel$)="updateFilterModel($event)">
    </app-customers-filters>

    <azz-loading-block [isLoading]="loading$ | async">
      <app-customers-table
        [customersData]="customersData$ | async"
        [sorting]="sorting$ | async"
        (updatePagination$)="updatePagination($event)"
        (updateSorting$)="updateSorting($event)"
      >
      </app-customers-table>
    </azz-loading-block>
  </div>
</section>
