import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IDriverUserInfo } from '../../../models/user-info.model';

@Component({
  selector: 'app-driver-details-taxi-number',
  templateUrl: './driver-details-taxi-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsTaxiNumberComponent {
  @Input() form: FormGroup;
  @Input() set driver(value: IDriverUserInfo) {
    const taxiNumber = value?.parameters?.taxiNumber;
    if (taxiNumber) {
      this.form.patchValue({ taxiNumber });
    }
  }
}
