import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStore } from '@auth/store';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, delay, filter, map, takeUntil } from 'rxjs/operators';

import { G7OrdersStore } from './store';
import { NgOnDestroyService } from '../../../common/services/ng-on-destroy.service';
import { IPaginationState, ISortingState } from '../../models/common.model';
import { G7Order, IG7OrdersData, IG7OrderSingleValidateBody, IG7OrdersParams } from '../../models/g7-order.model';
import { AppStore } from '../../store';
import { AppState } from '../../store/reducers';

@Component({
  selector: 'g7-orders',
  templateUrl: './g7-orders.component.html',
  styleUrls: ['./g7-orders.component.less'],
  providers: [NgOnDestroyService],
})
export class G7OrdersComponent implements OnInit, OnDestroy {
  @Input() orderDetailsRoute: string;
  public ordersData$: Observable<IG7OrdersData>;
  public isG7User$: Observable<boolean>;
  public filterModel$: Observable<Partial<IG7OrdersParams>>;
  public loading$: Observable<boolean>;
  public sorting$: Observable<ISortingState>;
  public ordersAllowedValidation$: Observable<G7Order[]>;
  public selectedOrders$: Observable<{ [key: string]: boolean }>;
  public atLeastOneSelected$: Observable<boolean>;
  public atLeastOneSelectedOnPage$: Observable<boolean>;
  public allSelectedOnPage$: Observable<boolean>;
  public selectedOrdersLength$: Observable<number>;
  public hasOnlyOnBoardPayment$: Observable<boolean>;
  public isConfirmDisabled$: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store$: Store<AppState>,
    private readonly onDestroy$: NgOnDestroyService
  ) {}

  ngOnInit() {
    this.store$.dispatch(G7OrdersStore.g7OrdersInit());
    this.listenForRouter();
    this.initData();
  }

  public updateFilters(filters: Partial<IG7OrdersParams>) {
    this.router.navigate(['./'], { relativeTo: this.route, queryParams: { page: 0 } });
    this.store$.dispatch(G7OrdersStore.updateG7OrdersFilterModel({ filterModel: filters }));
  }

  public onUpdatePagination(pagination: Partial<IPaginationState>) {
    this.router.navigate(['./'], { relativeTo: this.route, queryParams: pagination });
  }

  public onUpdateSorting(sorting: Partial<ISortingState>) {
    this.store$.dispatch(G7OrdersStore.updateG7OrdersSorting({ sorting }));
  }

  public onOpenValidationPopup(data: { orderId: number; body: IG7OrderSingleValidateBody }): void {
    const { orderId, body } = data;
    this.store$.dispatch(G7OrdersStore.openValidationPopup({ orderId, body }));
  }

  public onOpenInvalidatePopup(order: G7Order): void {
    this.store$.dispatch(G7OrdersStore.openInvalidatePopup({ order }));
  }

  public onSelectOrder(orderId: number): void {
    this.store$.dispatch(G7OrdersStore.toggleSelection({ orderId }));
  }

  public onSelectAllOrders(): void {
    this.store$.dispatch(G7OrdersStore.toggleAllSelection());
  }

  public openMultiValidationPopup(): void {
    this.store$.dispatch(G7OrdersStore.openMultiValidationPopup());
  }

  public openMultiReturnPopup(): void {
    this.store$.dispatch(G7OrdersStore.openMultiReturnPopup());
  }

  public exportCsv(): void {
    this.store$.dispatch(G7OrdersStore.exportCsv());
  }

  public showDispatchPopup(): void {
    this.store$.dispatch(G7OrdersStore.openBeforeDispatchPopup());
  }

  ngOnDestroy() {
    this.store$.dispatch(G7OrdersStore.g7OrdersDestroy());
  }

  private listenForRouter(): void {
    combineLatest([
      this.store$.pipe(select(AppStore.selectRouterQueryParam('page'))),
      this.store$.pipe(select(AppStore.selectPrevRouteUrl)),
    ])
      .pipe(takeUntil(this.onDestroy$), debounceTime(100))
      .subscribe(([pageQueryParam, prevUrl]) => {
        const page = pageQueryParam ? Number(pageQueryParam) : 0;
        if (page) {
          if (prevUrl?.includes(this.orderDetailsRoute) || !prevUrl) {
            // !prevUrl if we reload page
            this.store$.dispatch(G7OrdersStore.updateG7OrdersPagination({ pagination: { page } }));
          } else {
            this.router.navigate(['./'], { relativeTo: this.route, queryParams: { page: 0 } });
          }
        } else {
          this.store$.dispatch(G7OrdersStore.updateG7OrdersPagination({ pagination: { page: 0 } }));
        }
      });
  }

  private initData(): void {
    this.isG7User$ = this.store$.pipe(select(AuthStore.selectIsG7User));
    this.ordersData$ = this.store$.pipe(
      select(G7OrdersStore.selectG7OrdersData),
      filter((data: IG7OrdersData) => !!data)
    );
    this.filterModel$ = this.store$.pipe(select(G7OrdersStore.selectG7OrdersFilterModel));
    this.hasOnlyOnBoardPayment$ = this.filterModel$.pipe(
      delay(700), // workaround for asynchronity issues,
      map(filters => filters?.firstPayment)
    );
    this.loading$ = this.store$.pipe(select(G7OrdersStore.selectG7OrdersLoading));
    this.sorting$ = this.store$.pipe(select(G7OrdersStore.selectG7OrdersSorting));
    this.selectedOrders$ = this.store$.pipe(select(G7OrdersStore.selectSelectedOrders));
    this.atLeastOneSelected$ = this.store$.pipe(select(G7OrdersStore.atLeastOneSelected));
    this.atLeastOneSelectedOnPage$ = this.store$.pipe(select(G7OrdersStore.atLeastOneSelectedOnPage));
    this.allSelectedOnPage$ = this.store$.pipe(select(G7OrdersStore.allSelectedOnPage));
    this.ordersAllowedValidation$ = this.store$.pipe(select(G7OrdersStore.selectG7OrdersAllowedValidation));
    this.selectedOrdersLength$ = this.store$.pipe(select(G7OrdersStore.selectG7OrdersDataSelectedOrdersCount));
    this.isConfirmDisabled$ = this.selectedOrdersLength$.pipe(map(len => len === 0));
  }
}
