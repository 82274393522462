import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DriverTagsEffects } from './effects';
import * as DriverTagsReducer from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(DriverTagsReducer.driverTagsFeatureKey, DriverTagsReducer.reducer, {
      metaReducers: DriverTagsReducer.metaReducers,
    }),
    EffectsModule.forFeature([DriverTagsEffects]),
  ],
})
export class DriverTagsStoreModule {}
