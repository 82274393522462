<div class="azz-table-wrapper">
  <table class="table table-hover azz-table">
    <thead>
      <tr class="d-table-row">
        <th class="col-2" (click)="sortHelper.sortColumns(['lastName'])">
          <span *ngIf="sortHelper.isSortedColumn(['lastName'])" [class]="sortHelper.getSortDirectionClass()"></span>
          {{ 'LAST_NAME' | translate }}
        </th>
        <th class="col-2" (click)="sortHelper.sortColumns(['name'])">
          <span *ngIf="sortHelper.isSortedColumn(['name'])" [class]="sortHelper.getSortDirectionClass()"></span>
          {{ 'FIRST_NAME' | translate }}
        </th>
        <th class="col-2">{{ 'PHONE_NUMBER' | translate }}</th>
        <th class="col-2">{{ 'EMAIL' | translate }}</th>
        <th class="col-2">{{ 'SUBSCRIBER_CODE' | translate }}</th>
        <th class="col-2" (click)="sortHelper.sortColumns(['status'])">
          <span *ngIf="sortHelper.isSortedColumn(['status'])" [class]="sortHelper.getSortDirectionClass()"></span>
          {{ 'STATUS' | translate }}
        </th>
      </tr>
    </thead>
    <tbody *ngIf="customersData?.content?.length > 0">
      <!-- IE scrolling fix -->
      <tr
        class="azz-clickable"
        *ngFor="let customer of customersData?.content; trackBy: trackByFunc"
        [routerLink]="[customer.id]"
      >
        <td>{{ customer?.lastName }}</td>
        <td>{{ customer?.name }}</td>
        <td>
          <span *ngFor="let item of customer?.phones">
            <span>{{ item?.phoneCode }}{{ item?.phoneNumber }}</span
            ><br />
          </span>
        </td>
        <td>
          <span *ngFor="let item of customer?.emails">
            <span>{{ item?.email }}</span
            ><br />
          </span>
        </td>
        <td>{{ customer?.subscriberCode }}</td>
        <td>{{ 'PA_CUSTOMERS_STATUS_' + customer?.status | translate }}</td>
      </tr>
    </tbody>
  </table>
</div>
<azz-table-no-matches [azzVisible]="!customersData?.content?.length"> </azz-table-no-matches>
<azz-pagination
  (goToPrevPage)="prevPage()"
  (goToNextPage)="nextPage()"
  [prevDisabled]="isPrevDisabled()"
  [nextDisabled]="isNextDisabled()"
>
</azz-pagination>
