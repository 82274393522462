import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { G7OrdersEffects } from './effects';
import * as G7OrdersReducer from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(G7OrdersReducer.g7OrdersFeatureKey, G7OrdersReducer.reducer, {
      metaReducers: G7OrdersReducer.metaReducers,
    }),
    EffectsModule.forFeature([G7OrdersEffects]),
  ],
})
export class G7OrdersStoreModule {}
