import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownComponent } from './dropdown.component';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';
import { PaginationModule } from '../pagination/pagination.module';

@NgModule({
  imports: [CommonModule, NgbDropdownModule, FormsModule, TranslateModule, PaginationModule, AzzRotateIndicatorModule],
  declarations: [DropdownComponent],
  exports: [DropdownComponent],
})
export class DropdownModule {}
