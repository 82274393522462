<section class="details">
  <span>{{ 'FD_DRIVER_BLOCKED_MSG' | translate }}</span>
  {{ driver?.blockedTime | date: 'dd.MM.yyyy' }}
  <br />
  <span>{{ 'FD_DRIVER_NOT_AUTHORIZED_TO_ACCESS_MSG' | translate }}</span>
  <span *ngIf="!ngDisabled">
    {{ 'CLICK' | translate }}
    <a class="details__link" (click)="openUnblockPopup()">{{ 'FD_DRIVER_HERE_LINK' | translate }}</a>
    <span> {{ 'FD_DRIVER_TO_UNBLOCK_MSG' | translate }}</span>
  </span>
</section>
