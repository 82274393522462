export enum LATE_EXCEPTION {
  SEND_SMS = 'SEND_SMS',
  WAIT_PASSENGER = 'WAIT_PASSENGER',
}

export enum CANCEL_ORDER {
  CANCEL_BY_PHONE_ADVISOR = 'CANCEL_BY_PHONE_ADVISOR',
  CANCEL_BY_CUSTOMER = 'CANCEL_BY_CUSTOMER',
  CANCEL_BY_PHONE_ADVISOR_AND_CREATE_NEW = 'CANCEL_BY_PHONE_ADVISOR_AND_CREATE_NEW',
}

export enum CALL_CUSTOMER {
  CALL_CUSTOMER_OK = 'CALL_CUSTOMER_OK',
  CALL_CUSTOMER_DOESNT_ANSWER = 'CALL_CUSTOMER_DOESNT_ANSWER',
  CALL_CUSTOMER_UNREACHABLE = 'CALL_CUSTOMER_UNREACHABLE',
}

export enum CALL_DRIVER {
  CALL_DRIVER_OK = 'CALL_DRIVER_OK',
  CALL_DRIVER_UNREACHABLE = 'CALL_DRIVER_UNREACHABLE',
  CALL_DRIVER_DOESNT_ANSWER = 'CALL_DRIVER_DOESNT_ANSWER',
}

export enum CANCEl_EXCEPTION {
  TRY_AGAIN = 'TRY_AGAIN',
}

export const CUSTOMER_BLOCK_REASON = {
  INVOICE: 'INVOICE',
  PROD: 'PROD',
} as const;

export type CustomerBlockReasonType = (typeof CUSTOMER_BLOCK_REASON)[keyof typeof CUSTOMER_BLOCK_REASON];

export const cancelOrderReasons = [
  {
    value: '',
    title: CANCEL_ORDER.CANCEL_BY_PHONE_ADVISOR,
  },
  {
    value: '',
    title: CANCEL_ORDER.CANCEL_BY_CUSTOMER,
  },
  {
    value: '',
    title: CANCEL_ORDER.CANCEL_BY_PHONE_ADVISOR_AND_CREATE_NEW,
  },
];

export const lateExceptionOptions = [
  {
    value: '',
    title: LATE_EXCEPTION.SEND_SMS,
  },
  {
    value: '',
    title: LATE_EXCEPTION.WAIT_PASSENGER,
  },
];

export const noTaxiOptions = [
  {
    value: '',
    title: 'TRY_AGAIN',
  },
];

export const callCustomerOptions = [
  {
    value: '',
    title: CALL_CUSTOMER.CALL_CUSTOMER_OK,
  },
  {
    value: '',
    title: CALL_CUSTOMER.CALL_CUSTOMER_DOESNT_ANSWER,
  },
  {
    value: '',
    title: CALL_CUSTOMER.CALL_CUSTOMER_UNREACHABLE,
  },
];

export const blockCustomerOptions: { value: string; title: CustomerBlockReasonType }[] = [
  {
    value: '',
    title: 'INVOICE',
  },
  {
    value: '',
    title: 'PROD',
  },
];

export const callDriverOptions = [
  {
    value: '',
    title: CALL_DRIVER.CALL_DRIVER_OK,
  },
  {
    value: '',
    title: CALL_DRIVER.CALL_DRIVER_DOESNT_ANSWER,
  },
  {
    value: '',
    title: CALL_DRIVER.CALL_DRIVER_UNREACHABLE,
  },
];

/**
 * Default International Code for France
 */
export const FRENCH_PHONE_CODE = '+33';
