import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { IMultiReturnG7Order, IMultiReturnPagination } from '@core/models/g7-order.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { G7OrdersStore } from '../../store';

@Component({
  selector: 'azz-multi-return-popup',
  templateUrl: './multi-return-popup.component.html',
  styleUrls: ['./multi-return-popup.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class MultiReturnPopupComponent implements OnInit {
  public tableLoading$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public orders: IMultiReturnG7Order[];
  public pagination: IMultiReturnPagination;
  public selectedOrdersLength: number;
  public message: string;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly store: Store,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  public get lastPage() {
    return Math.ceil(this.selectedOrdersLength / this.pagination.size) - 1;
  }

  ngOnInit() {
    combineLatest([
      this.store.pipe(select(G7OrdersStore.selectedOrdersLength)),
      this.store.pipe(select(G7OrdersStore.multiReturnPagination)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([length, pagination]) => {
        this.selectedOrdersLength = length;
        this.pagination = pagination;
      });
    this.store
      .pipe(select(G7OrdersStore.multiReturnOrders), takeUntil(this.destroyed$))
      .subscribe(orders => (this.orders = orders));
    this.tableLoading$ = this.store.pipe(select(G7OrdersStore.multiReturnTableLoading));
    this.loading$ = this.store.pipe(select(G7OrdersStore.multiReturnLoading));
  }

  public prevPage() {
    const page = this.pagination.page - 1;
    this.store.dispatch(G7OrdersStore.updateMultiReturnPagination({ pagination: { page } }));
  }

  public nextPage() {
    const page = this.pagination.page + 1;
    this.store.dispatch(G7OrdersStore.updateMultiReturnPagination({ pagination: { page } }));
  }

  public isPrevDisabled(): boolean {
    return !this.orders || this.pagination?.page === 0;
  }

  public isNextDisabled(): boolean {
    return !this.orders || this.pagination?.page === this.lastPage;
  }

  public close(): void {
    this.modal.close();
  }

  public submit(message: string): void {
    this.store.dispatch(
      G7OrdersStore.multiReturnG7Orders({
        popup: this.modal,
        message: message ?? null,
      })
    );
  }
}
