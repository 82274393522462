/**
 * @param title any string, like "Name  Surname"
 * removes whitespaves from start & from end, also removes whitespaces in between if they are bigger than one
 * @example ' john ' => 'john'
 * @example 'john     doe' => 'john doe'
 */
export const normalizeWhitespacesIn = (title: string): string => {
  if (!title) {
    return;
  }
  const splitted = title?.split(' ');
  const filtered = splitted.filter(char => char !== '');
  const joined = filtered.join(' ');
  return joined;
};
