import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderExceptionType } from '@core/constant';
import { CANCEL_ORDER } from '@dash/modules/phone-advisor/common/constants';
import { Observable } from 'rxjs';

import { IException, IExceptionData, IExceptionDataParams } from '../../models/exception.model';
import { IOrderLog } from '../../models/order-logs.model';
import { IGetPaOrdersParams, IPhoneAdvisorOrderData, PhoneAdvisorOrder } from '../../models/phone-advisor-order';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class PAOrderService extends ApiService {
  private readonly URL = 'order/api/v1/order/phone/advisor/';
  private readonly API_WS_URL = 'api-ws/api/v1/phoneAdvisorOrder';
  private readonly EXCEPTION_URL = 'order/api/v1/exclusion/';
  private createOrderState;

  constructor(http: HttpClient) {
    super(http);
  }

  public loadExceptionsAmount(): Observable<number> {
    return this.get(`${this.EXCEPTION_URL}count`);
  }

  public setCreateOrderState(state: any) {
    this.createOrderState = state;
  }

  public getCreateOrderState(): any {
    return this.createOrderState;
  }

  public cancelOrder(orderId: string): Observable<any> {
    return this.delete(`${this.API_WS_URL}/${orderId}`);
  }

  public createExceptionalOrder(orderId: string, type: OrderExceptionType): Observable<IException> {
    return this.post(`${this.EXCEPTION_URL}${orderId}/create`, { type });
  }

  public resolveExceptionalOrder(orderId: string): Observable<any> {
    return this.post(`${this.EXCEPTION_URL}${orderId}/resolve`, {});
  }

  public cancelExceptionalOrder(orderId: string, type: CANCEL_ORDER): Observable<any> {
    const payload = { type };
    return this.post(`${this.EXCEPTION_URL}${orderId}/cancel`, payload);
  }

  public changeDispatchTimeOrder(orderId: string, minutes: string): Observable<PhoneAdvisorOrder> {
    const payload = { minutes };
    return this.put(`${this.URL}${orderId}/dispatchBeforeMinutes`, payload);
  }

  public closeException(orderId: string): Observable<any> {
    return this.post(`${this.EXCEPTION_URL}${orderId}/close`, {});
  }

  public logExceptionalOrderEvents(orderId: string, modificationType: string): Observable<IOrderLog> {
    const payload = { modificationType };
    return this.post(`${this.EXCEPTION_URL}${orderId}/log`, payload);
  }

  public lockExceptionalOrder(orderId: string): Observable<IException> {
    return this.post(`${this.EXCEPTION_URL}${orderId}/lock`, {});
  }

  public unlockExceptionalOrder(orderId: string): Observable<any> {
    return this.post(`${this.EXCEPTION_URL}${orderId}/unlock`, {});
  }

  public getExceptions(params: Partial<IExceptionDataParams>): Observable<IExceptionData> {
    return this.get(`${this.EXCEPTION_URL}list`, params);
  }

  public getPAOrders(params: Partial<IGetPaOrdersParams>): Observable<IPhoneAdvisorOrderData> {
    return this.get(`${this.URL}list`, params);
  }

  public getPACurrentOrders(params: Partial<IGetPaOrdersParams>): Observable<IPhoneAdvisorOrderData> {
    return this.get(`${this.URL}list`, params);
  }

  public getPAFrequentOrders(params: Partial<IGetPaOrdersParams>): Observable<IPhoneAdvisorOrderData> {
    return this.get(`${this.URL}frequent`, params);
  }

  public getOrderById(orderId: string): Observable<PhoneAdvisorOrder> {
    return this.get(`${this.URL}${orderId}`);
  }

  public create(payload) {
    return this.post(`${this.API_WS_URL}`, payload);
  }

  public updateOrder(payload): Observable<any> {
    return this.put(`${this.API_WS_URL}`, payload);
  }

  public checkCustomerForReservations(params: Partial<IGetPaOrdersParams>): Observable<any> {
    return this.get(`${this.URL}list`, params);
  }
}
