import { IInvitationItem } from '@core/models/invitation.model';
import { Action, createReducer, on } from '@ngrx/store';

import * as RegistrationActions from '../actions';

export const registrationFeatureKey = 'registration';

export interface State {
  invitation: IInvitationItem | null;
  loading: boolean;
  btnLoading: boolean;
}

const initialState: State = {
  invitation: null,
  loading: false,
  btnLoading: false,
};

const registrationReducer = createReducer(
  initialState,
  on(RegistrationActions.getInvitation, state => ({ ...state, loading: true })),
  on(RegistrationActions.getInvitationSuccess, (state, { invitation }) => ({
    ...state,
    invitation,
    loading: false,
  })),
  on(RegistrationActions.getInvitationError, state => ({ ...state, loading: false })),
  on(RegistrationActions.register, state => ({ ...state, btnLoading: true })),
  on(RegistrationActions.updateRegistration, state => ({ ...state, btnLoading: true })),
  on(RegistrationActions.registerSuccess, state => ({
    ...state,
    btnLoading: false,
    invitation: null,
  })),
  on(RegistrationActions.registerError, state => ({ ...state, btnLoading: false })),
  on(RegistrationActions.updateRegistrationSuccess, state => ({ ...state, btnLoading: false })),
  on(RegistrationActions.updateRegistrationError, state => ({ ...state, btnLoading: false })),
  on(RegistrationActions.destroy, () => initialState)
);

export const reducer = (state: State | undefined, action: Action): State => registrationReducer(state, action);
