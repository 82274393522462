import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AzzArrowDirectiveModule } from '@common/directives/arrow.directive';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';
import { DatePickerModule } from '../../../common/date-picker/date-picker.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';
import { SessionChatDetailsComponent } from './details/session-chat-details.component';
import { SessionMessageComponent } from './details/session-message/session-message.component';
import { SessionsChatComponent } from './sessions-chat.component';

@NgModule({
  imports: [
    CommonModule,
    DatePickerModule,
    DropdownModule,
    FormsModule,
    AzzArrowDirectiveModule,
    LoadingBlockModule,
    PaginationModule,
    RouterModule,
    TableNoMatchesModule,
    TranslateModule,
    LoadingBlockModule,
  ],
  declarations: [SessionsChatComponent, SessionChatDetailsComponent, SessionMessageComponent],
  exports: [SessionsChatComponent, SessionChatDetailsComponent],
})
export class SessionsChatModule {}
