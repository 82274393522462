import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { DRIVER_STATUS } from '@core/constant';
import { IColorManagementFull } from '@core/models/color-management.model';
import { LocalStorageService, UtilService } from '@core/services/common';
import { FormService } from '@core/utils/form-service';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// import {DRIVER_STATUS} from '../../constant/driver-status.constant';
// import {IColorManagementFull} from '../../models/color-management.model';

import { DriverDetailsColorManagementComponent } from './color-management/driver-details-color-management.component';
import { DriverDetailsInfoComponent } from './driver-info/driver-details-info.component';
import { DriverDetailsStore } from './store';
import { IDriverLicense, IDriverLicenseKind, IDriverLicenseUpdate } from '../../models/driver-license.model';
import { IDriverParameters, IUpdateDriverByFleetPayload } from '../../models/driver.model';
import { ICountry } from '../../models/fleet.model';
import { IDriverOrdersStats } from '../../models/stats.model';
import { ITransportLicense } from '../../models/transport-license.model';
import { IDriverUserInfo } from '../../models/user-info.model';
import { IVehicle } from '../../models/vehicle.model';
import { AppStore } from '../../store';
import { azzLettersValidator, azzOnlyNumbers } from '../../utils/validators';
import { DriverTransportLicensesComponent } from '../driver-transport-licenses/driver-transport-licenses.component';
import { VehicleInfoComponent } from '../vehicle-info/vehicle-info.component';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.less'],
  providers: [NgOnDestroyService],
})
export class DriverDetailsComponent implements OnInit, OnDestroy {
  private readonly fb = inject(FormBuilder);
  private readonly utilService = inject(UtilService);
  private readonly formService = inject(FormService);
  private readonly router = inject(Router);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly store = inject(Store);
  private readonly destroyed$ = inject(NgOnDestroyService);

  @ViewChild(DriverDetailsInfoComponent) driverDetailsInfo: DriverDetailsInfoComponent;
  @ViewChild(DriverTransportLicensesComponent)
  driverTransportLicenses: DriverTransportLicensesComponent;
  @ViewChild(VehicleInfoComponent) vehicleInfo: VehicleInfoComponent;
  @ViewChild(DriverDetailsColorManagementComponent)
  colorManagementComponent: DriverDetailsColorManagementComponent;
  @ViewChild('formElemRef') formElemRef: ElementRef;

  public form: FormGroup;
  public driver: IDriverUserInfo;
  public driverLoading$: Observable<boolean>;
  public ordersStats$: Observable<IDriverOrdersStats>;
  public ordersStatsLoading$: Observable<boolean>;
  public countries$: Observable<ICountry[]>;
  public driverLicenseKinds$: Observable<IDriverLicenseKind[]>;
  public driverLicense: IDriverLicense;
  public vehicle: IVehicle;
  public transportLicense: ITransportLicense;
  public newTransportLicense: ITransportLicense;
  public colorManagement: IColorManagementFull;
  public colorManagementEnabled: boolean;
  public serviceTypeTagsIds$: Observable<string[]>;
  public vehicleTypeTagsIds$: Observable<string[]>;
  public blockBtnLoading$: Observable<boolean>;
  public unblockBtnLoading$: Observable<boolean>;
  public validateBtnLoading$: Observable<boolean>;
  public confirmLicenseBtnLoading$: Observable<boolean>;
  public rejectLicenseBtnLoading$: Observable<boolean>;
  public saveBtnLoading$: Observable<boolean>;
  public minPhoneLength = this.utilService.getMinPhoneNumberLength();
  public maxPhoneLength = this.utilService.getMaxPhoneNumberLength();
  public disabled: boolean;
  public isDriverBlocked: boolean;
  private driverId: number;

  constructor() {
    this.initForm();
  }

  get currentColor() {
    return this.form.value.currentColor;
  }

  @Input() set ngDisabled(value: boolean) {
    this.disabled = value;
    if (value) {
      this.form.disable();
    }
  }

  ngOnInit() {
    this.store.dispatch(DriverDetailsStore.init());
    this.initData();
    this.store
      .pipe(
        select(AppStore.selectRouterParam('driverId')),
        filter(val => !!val),
        takeUntil(this.destroyed$)
      )
      .subscribe((driverId: string) => {
        this.driverId = Number(driverId);
        this.getData();
      });
  }

  public openUnblockDriverPopup(): void {
    this.store.dispatch(DriverDetailsStore.openConfirmPopup({ operation: 'UNBLOCK' }));
  }

  public openBlockDriverPopup(): void {
    this.store.dispatch(DriverDetailsStore.openConfirmPopup({ operation: 'BLOCK' }));
  }

  public openValidateDriverPopup(): void {
    this.store.dispatch(DriverDetailsStore.openConfirmPopup({ operation: 'VALIDATE' }));
  }

  public openConfirmTransportLicensePopup(): void {
    this.store.dispatch(DriverDetailsStore.openConfirmPopup({ operation: 'CONFIRM_LICENSE' }));
  }

  public openRejectTransportLicensePopup(): void {
    this.store.dispatch(DriverDetailsStore.openConfirmPopup({ operation: 'REJECT_LICENSE' }));
  }

  public saveDriver(): void {
    this.markFieldsAsTouched();
    if (this.isFormValid()) {
      const payload = this.generatePayload();
      const serviceVehicleTypeTagsIds = this.vehicleInfo.getServiceAndVehicleTypeTagsId();
      this.store.dispatch(DriverDetailsStore.saveDriver({ payload, serviceVehicleTypeTagsIds }));
    } else {
      this.handleInvalidFormSubmit();
    }
  }

  public updateRating(rating: number): void {
    this.store.dispatch(DriverDetailsStore.updateDriverRating({ driverId: this.driverId, rating }));
  }

  public goBack(): void {
    const prevState = this.localStorageService.getLocalStorageItem('PREV_PAGE');
    this.router.navigate([prevState]);
  }

  ngOnDestroy() {
    this.store.dispatch(DriverDetailsStore.destroy());
  }

  private initForm(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, azzLettersValidator]],
      lastName: ['', [Validators.required, azzLettersValidator]],
      birthday: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.utilService.emailRegexp)]],
      phoneCode: ['', Validators.required],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(this.utilService.numbersRegexp),
          Validators.minLength(this.utilService.getMinPhoneNumberLength()),
          Validators.maxLength(this.utilService.getMaxPhoneNumberLength()),
        ],
      ],
      country: ['', [Validators.required]],
      currentColor: [null],
      taxiNumber: ['', [azzOnlyNumbers]],
    });
  }

  private initData(): void {
    this.store
      .pipe(select(DriverDetailsStore.driver), takeUntil(this.destroyed$))
      .subscribe((res: IDriverUserInfo) => (this.driver = res));
    this.store
      .pipe(select(DriverDetailsStore.isDriverBlocked), takeUntil(this.destroyed$))
      .subscribe((res: boolean) => (this.isDriverBlocked = res));
    this.driverLoading$ = this.store.pipe(select(DriverDetailsStore.driverLoading));
    this.ordersStats$ = this.store.pipe(select(DriverDetailsStore.ordersStats));
    this.ordersStatsLoading$ = this.store.pipe(select(DriverDetailsStore.ordersStatsLoading));
    this.countries$ = this.store.pipe(select(DriverDetailsStore.countries));
    this.driverLicenseKinds$ = this.store.pipe(select(DriverDetailsStore.driverLicenseKinds));
    this.store
      .pipe(select(DriverDetailsStore.driverLicense), takeUntil(this.destroyed$))
      .subscribe((res: IDriverLicense) => (this.driverLicense = res));
    this.store
      .pipe(select(DriverDetailsStore.vehicle), takeUntil(this.destroyed$))
      .subscribe((res: IVehicle) => (this.vehicle = res));
    this.store
      .pipe(select(DriverDetailsStore.transportLicense), takeUntil(this.destroyed$))
      .subscribe((res: ITransportLicense) => (this.transportLicense = res));
    this.store
      .pipe(select(DriverDetailsStore.newTransportLicense), takeUntil(this.destroyed$))
      .subscribe((res: ITransportLicense) => (this.newTransportLicense = res));
    this.store
      .pipe(select(DriverDetailsStore.colorManagement), takeUntil(this.destroyed$))
      .subscribe((res: IColorManagementFull) => (this.colorManagement = res));
    this.store
      .pipe(select(DriverDetailsStore.colorManagementEnabled), takeUntil(this.destroyed$))
      .subscribe((res: boolean) => (this.colorManagementEnabled = res));

    this.serviceTypeTagsIds$ = this.store.pipe(select(DriverDetailsStore.serviceTypeTagsIds));
    this.vehicleTypeTagsIds$ = this.store.pipe(select(DriverDetailsStore.vehicleTypeTagsIds));
    this.blockBtnLoading$ = this.store.pipe(select(DriverDetailsStore.blockBtnLoading));
    this.unblockBtnLoading$ = this.store.pipe(select(DriverDetailsStore.unblockBtnLoading));
    this.validateBtnLoading$ = this.store.pipe(select(DriverDetailsStore.validateBtnLoading));
    this.confirmLicenseBtnLoading$ = this.store.pipe(select(DriverDetailsStore.confirmLicenseBtnLoading));
    this.rejectLicenseBtnLoading$ = this.store.pipe(select(DriverDetailsStore.rejectLicenseBtnLoading));
    this.saveBtnLoading$ = this.store.pipe(select(DriverDetailsStore.saveBtnLoading));
  }

  private getData(): void {
    this.store.dispatch(DriverDetailsStore.getDriver({ driverId: this.driverId, colorManagement: !this.disabled }));
    this.store.dispatch(DriverDetailsStore.getOrdersStats({ driverId: this.driverId }));
    this.store.dispatch(DriverDetailsStore.getDriverLicense({ driverId: this.driverId }));
    this.store.dispatch(DriverDetailsStore.getVehicle({ driverId: this.driverId }));
    this.store.dispatch(DriverDetailsStore.getDriverTags({ driverId: this.driverId }));
    this.store.dispatch(DriverDetailsStore.getCountries());
    this.store.dispatch(DriverDetailsStore.getDriverLicenseKinds());
  }

  private markFieldsAsTouched(): void {
    this.formService.markFieldsAsTouched(this.form);
    this.colorManagementComponent?.markFieldsAsTouched();
    this.driverDetailsInfo?.markAutocompleteAsTouched();
    if (this.driverTransportLicenses) {
      this.driverTransportLicenses.markFieldsAsTouched();
    }
    this.vehicleInfo?.markFieldsAsTouched();
  }

  private handleInvalidFormSubmit(): void {
    const refArr = [this.formElemRef, this.driverDetailsInfo.autoComplete.formElemRef, this.vehicleInfo.formElemRef];
    if (this.driverTransportLicenses) {
      refArr.push(this.driverTransportLicenses.formElemRef);
    }
    this.formService.scrollToFirstInvalidControl(refArr);
  }

  private generatePayload(): IUpdateDriverByFleetPayload {
    return {
      driver: this.generateDriverPayload(),
      vehicle: this.generateVehiclePayload(),
      driverParameters: this.generateDriverParamsPayload(),
      driverLicenseUpdate: this.generateDriverLicenseUpdatePayload(),
    };
  }

  private generateDriverPayload(): IDriverUserInfo {
    const { firstName, lastName, email, phoneCode, phoneNumber } = this.form.getRawValue();
    return {
      ...this.driver,
      firstName,
      lastName,
      email,
      phone: {
        ...this.driver.phone,
        phoneCode,
        phoneNumber,
      },
      address: this.driverDetailsInfo?.generateAddress(),
      birthday: this.generateBirthday(),
    };
  }

  private generateVehiclePayload(): IVehicle {
    return {
      ...this.vehicle,
      ...this.vehicleInfo.generatePayload(),
    };
  }

  private generateBirthday(): any {
    const birthday = this.form.getRawValue().birthday;
    if (birthday && moment.isMoment(birthday)) {
      const offset = birthday.utcOffset();
      return birthday.utc().add(offset, 'm').valueOf();
    }
  }

  private generateDriverParamsPayload(): IDriverParameters {
    const { currentColor, taxiNumber } = this.form.getRawValue();
    const payload = {
      ...this.driver.parameters,
      taxiNumber: taxiNumber || null,
    };
    if (this.colorManagement?.colorEnabled) {
      const colorProp = this.colorManagement.halfColorEnabled ? 'halfColor' : 'color';
      payload[colorProp] = currentColor;
    }
    return payload;
  }

  private generateDriverLicenseUpdatePayload(): any {
    return this.driverTransportLicenses?.getDriverLicenseUpdate() || this.generateDefaultDriverLicenseUpdatePayload();
  }

  private generateDefaultDriverLicenseUpdatePayload(): IDriverLicenseUpdate {
    return {
      id: this.driverLicense?.id || null,
      kind: this.driverLicense?.kind || null,
      number: this.driverLicense?.number || null,
    };
  }

  private isFormValid(): boolean {
    return (
      this.form.valid &&
      this.driverDetailsInfo.isAutocompleteValid() &&
      this.isDriverTransportLicencesValid() &&
      this.vehicleInfo.isFormValid()
    );
  }

  private isDriverTransportLicencesValid(): boolean {
    return this.driverTransportLicenses ? this.driverTransportLicenses?.isFormValid() : true;
  }
}
