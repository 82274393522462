import { Component, Input } from '@angular/core';

@Component({
  selector: 'azz-icon-close',
  standalone: true,
  template: `
    <svg [attr.width]="iconSize" viewBox="0 0 12 12" fill="none" [attr.class]="className" aria-hidden="true">
      <path d="M1.40039 1.36711L10.7337 10.7004" [attr.stroke-width]="strokeWidth" stroke="currentColor" />
      <path d="M1.40039 10.7004L10.7336 1.36706" [attr.stroke-width]="strokeWidth" stroke="currentColor" />
    </svg>
  `,
})
export class IconCloseComponent {
  @Input() public className = 'icon-close';
  @Input() public strokeWidth = '1.3';
  @Input() public iconSize = '12';
}
