import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressCollectionGoogle } from '@core/modules/base-create-order/interfaces/address-collection-google.interface';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { EtalabFeatureCollection } from '../../models/geo-json.model';

export interface IFindAddressParams {
  limit: number;
  q: string;
  withPoi?: boolean;
  serviceLevel?: string[];
  fleetG7Id?: number;
  displayInBillingPortal?: boolean;
}

export interface IGetCitiesParams {
  q: string;
  size: number;
}

export interface IGetCityByPoint {
  lat: number;
  lon: number;
}

export interface GoogleCoordinates {
  postalCode: string;
  street: string;
  number: string;
  coordinates: {
    longitude: number;
    latitude: number;
  };
}

@Injectable({ providedIn: 'root' })
export class AddressesService extends ApiService {
  public readonly googleAddressURL = 'user/api/v1/address/google';
  public readonly googleAddressCoordinatesURL = 'user/api/v1/address/coordinates';
  public NEW_ADDRESS_URL = 'user/api/v1/address';
  public ADDRESS_URL = 'zone/api/v1/address';
  public ADDRESS_LIMIT = 10;

  constructor(http: HttpClient) {
    super(http);
  }

  public findAddress(params: IFindAddressParams): Observable<EtalabFeatureCollection> {
    return this.get(`${this.NEW_ADDRESS_URL}`, params);
  }

  public findGoogleAddress(params: IFindAddressParams): Observable<AddressCollectionGoogle[]> {
    return this.get(`${this.googleAddressURL}`, params);
  }

  public getGoogleCoordinates(params: { q: string }): Observable<GoogleCoordinates> {
    return this.get(`${this.googleAddressCoordinatesURL}`, params);
  }

  public getCities(params: Partial<IGetCitiesParams>): Observable<string[]> {
    return this.get(`${this.NEW_ADDRESS_URL}/cities`, params);
  }

  public getCityByPoint(params: IGetCityByPoint): Observable<{ id: any; name: string }> {
    return this.get(`${this.NEW_ADDRESS_URL}/cities/by/point`, params);
  }
}
