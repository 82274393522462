import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { throttled } from '@common/decorators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { FCCOrangeService } from './FCCOrange.service';

@Component({
  selector: 'azz-phone-call-button',
  template: `
    <ng-container *ngIf="showCallButton">
      <span class="hidden" (click)="onOutsidePhoneCall()"></span>
      <img class="image" [class.opaque]="isOpaque$ | async" src="/assets/images/phone-call.icon.svg" />
      <span *ngIf="isLoading$ | async">{{ 'FCCORANGE_PHONE_CALL_BUTTON_PROGRESS' | translate }}</span>
    </ng-container>
  `,
  styles: [
    `
      :host {
        max-width: 120px;
        display: flex;
        align-items: center;
      }

      .image {
        @icon-size: 35px;
        width: @icon-size;
        height: @icon-size;
        margin-right: 10px;
        cursor: pointer;
      }
      .image.opaque {
        opacity: 50%;
      }
    `,
  ],
  providers: [FCCOrangeService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzzPhoneCallButtonComponent implements OnChanges {
  // @ViewChild('linkElement') public linkElement: ElementRef;
  @Input() phoneNumber: string;
  @Input() link: string;

  public isLoading$: Observable<boolean>;
  public isOpaque$: Observable<boolean>;

  public showCallButton = false;

  public linkForRedirect: string;

  private readonly oneDigitRegex = /\d/;
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  private readonly isDisabledSubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  constructor(private readonly FCCOrange: FCCOrangeService) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isOpaque$ = this.isLoading$.pipe(
      withLatestFrom(this.isDisabledSubject.asObservable()),
      map(([isLoading, isDisabled]) => isLoading || isDisabled)
    );
  }

  @HostBinding('class.hidden') get hostClassHidden() {
    return !this.isCallButtonAvailable() ? 'hidden' : '';
  }

  @throttled(800)
  @HostListener('click')
  onHostClick(): void {
    if (this.linkForRedirect && !this.isLoadingSubject.value && !this.isDisabledSubject.value) {
      this.onOutsidePhoneCall();
    }
  }

  public onOutsidePhoneCall() {
    this.isLoadingSubject.next(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.FCCOrange.get(this.linkForRedirect).subscribe(({ code }) => {
      this.isLoadingSubject.next(false);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges({ phoneNumber, link }: SimpleChanges): void {
    if (phoneNumber?.currentValue && this.link) {
      this.linkForRedirect = this.generateLinkForRedirect();
    }
    this.showCallButton = this.isCallButtonAvailable();
  }

  private isCallButtonAvailable(): boolean {
    return !!this.phoneNumber.length && !!this.phoneNumber.match(this.oneDigitRegex)?.length;
  }

  private generateLinkForRedirect(): string {
    const phoneNumberWithoutCode = this.phoneNumber.slice(1);

    return `${this.link}${phoneNumberWithoutCode}`;
  }
}
