import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { MultipleDropdownComponent } from './multiple-dropdown.component';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';
import { PaginationModule } from '../pagination/pagination.module';
import { MultiDropdownOptionComponent } from './option/multi-dropdown-option.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgbDropdownModule, TranslateModule, AzzRotateIndicatorModule, PaginationModule],
  declarations: [MultipleDropdownComponent, MultiDropdownOptionComponent],
  exports: [MultipleDropdownComponent],
})
export class MultipleDropdownModule {}
