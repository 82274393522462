import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { PragmaticConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AddressApiClient } from './api/address.api-client';
import { CustomerApiClient } from './api/customer.api-client';
import { DriverApiClient } from './api/driver.api-client';
import { ExclusionApiClient } from './api/exclusion.api-client';
import { FleetApiClient } from './api/fleet.api-client';
import { OrderApiClient } from './api/order.api-client';
import { RouteApiClient } from './api/route.api-client';
import { TagApiClient } from './api/tag.api-client';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AddressApiClient,
    CustomerApiClient,
    DriverApiClient,
    ExclusionApiClient,
    FleetApiClient,
    OrderApiClient,
    RouteApiClient,
    TagApiClient,
  ],
})
export class PragmaticApiModule {
  public static forRoot(configurationFactory: () => PragmaticConfiguration): ModuleWithProviders<PragmaticApiModule> {
    return {
      ngModule: PragmaticApiModule,
      providers: [{ provide: PragmaticConfiguration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: PragmaticApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('PragmaticApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
