import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DriverTransportLicensesComponent } from './driver-transport-licenses.component';
import { AttachFileModule } from '../attach-file/attach-file.module';

@NgModule({
  imports: [CommonModule, AttachFileModule, ReactiveFormsModule, TranslateModule],
  declarations: [DriverTransportLicensesComponent],
  exports: [DriverTransportLicensesComponent],
})
export class DriverTransportLicensesModule {}
