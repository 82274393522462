<section class="modal-notification">
  <p class="modal-notification__header" *ngIf="header">{{ header | translate }}</p>
  <p class="modal-notification__message" *ngIf="message">{{ message | translate }}</p>

  <form class="row" [formGroup]="form" #formElemRef>
    <div class="form-group col-md-6">
      <label for="deactivation-time" class="azz-transparent-input-label">{{ 'DATE' | translate }}</label>
      <div class="popup__picker-group">
        <input
          id="deactivation-time"
          type="text"
          name="deactivation_time"
          class="form-control azz-transparent-input azz-validate"
          formControlName="deactivationTime"
          [owlDateTime]="dt2Start"
          [owlDateTimeTrigger]="dt2Start"
          [min]="minDate"
          autocomplete="off"
        />
        <button type="button" class="popup__picker-btn" [owlDateTimeTrigger]="dt2Start"></button>
      </div>
      <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1" #dt2Start />
    </div>
  </form>

  <div class="modal-notification__btn-group">
    <button type="button" class="custom-btn custom-btn--grey mr-3" (click)="close()">
      {{ closeBtn | translate | titlecase }}
    </button>
    <button type="button" class="custom-btn custom-btn--green" (click)="submit()">
      {{ submitBtn | translate }}
    </button>
  </div>
</section>
