import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { DIRECTION } from '@core/constant/direction.enum';
import { RouteUrls } from '@core/constant/route-urls';
import { IFleet, IFleetData } from '@core/models/fleet.model';
import { IG7OrderHistory, IG7OrderHistoryData } from '@core/models/g7-order-history.model';
import { DateUtilService, UtilService } from '@core/services/common';
import { G7OrdersService } from '@core/services/orders/g7-orders.service';
import { FleetService } from '@core/services/users/fleet.service';
import { UserService } from '@core/services/users/user.service';
import { getNumbersRegExp } from '@core/utils/validators';
import moment from 'moment';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'azz-g7-common-notifications',
  templateUrl: './g7-notifications.component.html',
  styleUrls: ['./g7-notifications.component.less'],
  providers: [NgOnDestroyService],
})
export class G7NotificationsComponent implements OnInit {
  @Input() isOrderNumberHidden: boolean;
  @ViewChild('f') ngForm: NgForm;
  public readonly REQUEST_DELAY_MS = 300;
  public availableData: { history: IG7OrderHistoryData };
  public loadingIndicator = false;
  public watcher$ = new Subject<number>();
  public filterData = this.initFilterData();
  public maxDate = moment();
  public onlyG7FleetId = true;
  public numbersRegExp = getNumbersRegExp();
  private fleet: IFleet;
  private orderG7Id: any = null;

  constructor(
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly g7OrdersService: G7OrdersService,
    private readonly dateUtils: DateUtilService,
    private readonly router: Router,
    private readonly utilService: UtilService,
    private readonly fleetService: FleetService,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.availableData = {
      history: null,
    };
  }

  ngOnInit() {
    this.init();

    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((response: Params) => {
      this.orderG7Id = response.orderId;
      this.watcher$.next(null);
    });
  }

  public prevPage(): void {
    this.watcher$.next(this.getCurrentPage() - 1);
  }

  public nextPage(): void {
    this.watcher$.next(this.getCurrentPage() + 1);
  }

  public isPrevDisabled(): boolean {
    return !this.availableData || !this.availableData.history || this.availableData.history.first;
  }

  public isNextDisabled(): boolean {
    return !this.availableData || !this.availableData.history || this.availableData.history.last;
  }

  public loadFleets_SavedContext(): (query: string, direction: string, pageNumber: number) => void {
    return (query, direction, pageNumber) => this.loadFleets({ query, direction, pageNumber });
  }

  public loadFleets(data: { query: string; direction: string; pageNumber: number }): Observable<IFleetData> {
    const { query, direction, pageNumber } = data;
    const sort = ['city,' + (direction || DIRECTION.ASC)];
    const params = {
      pageNumber,
      sort,
      withG7: true,
      city: query,
    };
    return this.fleetService.searchFleets(params).pipe(takeUntil(this.destroyed$));
  }

  public loadHistoryIfReferenceFormatValid(): void {
    if (this.ngForm.form.get('orders_search').valid) {
      this.watcher$.next(null);
    }
  }

  public onFleetSelect(event: IFleet): void {
    this.filterData.fleet = event;
    this.watcher$.next(null);
  }

  public filterDateStart = (d: moment.Moment): boolean => this.utilService.filterDateStart(d, this.filterData.dateEnd);

  public filterDateEnd = (d: moment.Moment): boolean => this.utilService.filterDateEnd(d, this.filterData.dateStart);

  public isSortedColumn(name: string): boolean {
    return this.filterData.sort === name;
  }

  public changeSortDirection(): void {
    if (this.filterData.direction === DIRECTION.ASC) {
      this.filterData.direction = DIRECTION.DESC;
    } else {
      this.filterData.direction = DIRECTION.ASC;
    }
  }

  public sortColumn(name: string): void {
    if (this.filterData.sort !== name) {
      this.filterData.sort = name;
      this.filterData.direction = DIRECTION.DESC;
    } else {
      this.changeSortDirection();
    }
    this.watcher$.next(null);
  }

  public trackByFunc(index: number, item: IG7OrderHistory): number {
    return item ? item.date : null;
  }

  public getStartUTCDateString(): number {
    const dateStart = this.dateUtils.getStartOfDay(this.filterData.dateStart.toDate());
    return this.dateUtils.convertToUTCDateString(dateStart);
  }

  public getEndUTCDateString(): number {
    const dateEnd = this.dateUtils.getEndOfDay(this.filterData.dateEnd.toDate());
    return this.dateUtils.convertToUTCDateString(dateEnd);
  }

  public enableLoadingIndicator(enabled: boolean): void {
    this.loadingIndicator = enabled;
  }

  public isHistoryHiddenForMainPageAndFiltersAvailable(): boolean {
    return !this.route.snapshot.params.orderId;
  }

  public onHistoryClick(orderId: number): void {
    if (this.isG7BillingUser()) {
      this.router.navigate([RouteUrls.dash.billingUser, 'orders', orderId]);
    }
  }

  public isG7BillingUser(): boolean {
    const currentRoles = this.userService.getCurrentUserInfo().user.roles;
    return currentRoles.indexOf('ROLE_G7_BILLING_USER') !== -1;
  }

  public resetDate(date: 'dateStart' | 'dateEnd'): void {
    this.filterData[date] = null;
    this.watcher$.next(null);
  }

  private initFilterData(): {
    orderG7Id: string;
    dateStart: moment.Moment;
    dateEnd: moment.Moment;
    hideIfValidated: boolean;
    sort: string;
    direction: DIRECTION;
    partnerId: string;
    fleet: IFleet;
  } {
    return {
      orderG7Id: this.route.snapshot.params.orderId || null,
      dateStart: null,
      dateEnd: null,
      hideIfValidated: this.isHistoryHiddenForMainPageAndFiltersAvailable(),
      sort: 'date',
      direction: DIRECTION.DESC,
      partnerId: null,
      fleet: null,
    };
  }

  private init(): void {
    this.fleet = this.userService.getCurrentUserInfo()?.fleet;
    this.initWatchers();
    this.watcher$.next(null);
  }

  private initWatchers(): void {
    this.watcher$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(this.REQUEST_DELAY_MS),
        switchMap((pageNumber: number) => this.loadG7OrdersHistoryObservable$(pageNumber))
      )
      .subscribe(
        (response: IG7OrderHistoryData) => {
          this.availableData.history = response;
        },
        () => (this.availableData.history = null)
      );
  }

  private loadG7OrdersHistoryObservable$(pageNumber?: number): Observable<IG7OrderHistoryData> {
    const params = {
      orderG7Id: this.getOrderG7Id() || null,
      dateGt: (this.filterData.dateStart && this.getStartUTCDateString()) || null,
      dateLt: (this.filterData.dateEnd && this.getEndUTCDateString()) || null,
      hideIfValidated: this.filterData.hideIfValidated,
      fleetG7Id: this.filterData?.fleet?.idG7 || this.fleet?.idG7 || null,
      partnerId: this.filterData.partnerId || null,
      sort: this.filterData.sort,
      direction: this.filterData.direction,
      size: 15,
      page: pageNumber,
    };
    this.enableLoadingIndicator(true);
    return this.g7OrdersService.getHistory(params).pipe(
      takeUntil(this.destroyed$),
      finalize(() => this.enableLoadingIndicator(false)),
      catchError(() => EMPTY)
    );
  }

  private getOrderG7Id(): number {
    return this.orderG7Id ? this.orderG7Id : this.filterData.orderG7Id;
  }

  private getCurrentPage(): number {
    return this.availableData ? this.availableData.history.number : 0;
  }
}
