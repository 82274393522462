export const ORDER_STATUS = {
  APPROACH: 'APPROACH',
  AT_DEPARTURE_ADDRESS: 'AT_DEPARTURE_ADDRESS',
  CANCELLED: 'CANCELLED',
  CANCELLED_AUTO: 'CANCELLED_AUTO',
  CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_DRIVER: 'CANCELLED_BY_DRIVER',
  CANCELLED_BY_PHONE_ADVISOR: 'CANCELLED_BY_PHONE_ADVISOR',
  CONFIRMED: 'CONFIRMED',
  CREATE: 'CREATE',
  CUSTOMER_NO_SHOW: 'CUSTOMER_NO_SHOW',
  DISPATCHING: 'DISPATCHING',
  FINISHED: 'FINISHED',
  FINISHED_AUTO: 'FINISHED_AUTO',
  INVALID: 'INVALID',
  NO_VEHICLE_FOUND: 'NO_VEHICLE_FOUND',
  TOWARDS_DESTINATION: 'TOWARDS_DESTINATION',
  IN_QUEUE: 'IN_QUEUE',
} as const;

export type OrderStatusType = (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS];
