import { AuthStore } from '@auth/store';
import { DRIVER_SERVICE_TYPE_TAG_ID_MAP, DRIVER_VEHICLE_TYPE_TAG_ID_MAP } from '@core/constant/tag.constant';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import * as DriverTagsActions from '../actions';

export const driverTagsFeatureKey = 'driver-tags';

export interface State {
  driverTagsIds: string[]; // except service & vehicle type tags, cause of ui
  currentDriverTagsIdsMap: { [key: string]: boolean }; // except service & vehicle type tags, cause of ui
  selectedTagsIdsMap: { [key: string]: boolean };
  loading: boolean;
}

const initialState: State = {
  driverTagsIds: [],
  currentDriverTagsIdsMap: {},
  selectedTagsIdsMap: {},
  loading: false,
};

const driverTagsReducer = createReducer(
  initialState,
  on(DriverTagsActions.getCombinedTags, state => ({ ...state, loading: true })),
  on(DriverTagsActions.getCombinedTagsSuccess, (state, { allTags, currentDriverTags }) => {
    const driverTagsIds = allTags
      .filter(t => t.driverTag && !DRIVER_SERVICE_TYPE_TAG_ID_MAP[t.id] && !DRIVER_VEHICLE_TYPE_TAG_ID_MAP[t.id])
      .map(tag => tag.id);
    const currentDriverTagsIdsMap = currentDriverTags
      .filter(t => t.driverTag && !DRIVER_SERVICE_TYPE_TAG_ID_MAP[t.id] && !DRIVER_VEHICLE_TYPE_TAG_ID_MAP[t.id])
      .reduce((acc, curr) => {
        acc[curr.id] = true;
        return acc;
      }, {});
    return {
      ...state,
      loading: false,
      driverTagsIds,
      currentDriverTagsIdsMap,
      selectedTagsIdsMap: currentDriverTagsIdsMap,
    };
  }),
  on(DriverTagsActions.getCombinedTagsError, state => ({ ...state, loading: false })),
  on(DriverTagsActions.updateDriverTags, state => ({ ...state, loading: true })),
  on(DriverTagsActions.updateDriverTagsSuccess, state => ({
    ...state,
    loading: false,
    currentDriverTagsIdsMap: { ...state.selectedTagsIdsMap },
  })),
  on(DriverTagsActions.updateDriverTagsError, state => ({
    ...state,
    loading: false,
    selectedTagsIdsMap: { ...state.currentDriverTagsIdsMap },
  })),
  on(DriverTagsActions.toggleTag, (state, { id }) => ({
    ...state,
    selectedTagsIdsMap: { ...state.selectedTagsIdsMap, [id]: !state.selectedTagsIdsMap[id] },
  })),
  on(DriverTagsActions.destroy, state => ({
    ...state,
    selectedTagsIdsMap: {},
    currentDriverTagsIdsMap: {},
    loading: false,
  }))
);

export const reducer = (state: State | undefined, action: Action) => driverTagsReducer(state, action);

export const clearState =
  (r: ActionReducer<State>): ActionReducer<State> =>
  (state, action) => {
    if (action?.type === AuthStore.logoutSuccess.type) {
      return r(undefined, action);
    }

    return r(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
