import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { DIRECTION, OrderStatusesFactory, OrderStatusType } from '@core/constant';
import { IAllDriversData, IDriver } from '@core/models';
import { DateUtilService, UtilService } from '@core/services/common';
import { OrderService } from '@core/services/orders';
import { CustomerService, DriverService, UserService } from '@core/services/users';
import { EOrderType } from '@dash/components/orders-old/orders-tab/orders-tab.component';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ICustomersData } from '../../models/customer.model';
import { IDropdownOrderStatus, IGetOrdersParams, IOrderData } from '../../models/order.model';

@Component({
  selector: 'azz-common-orders',
  templateUrl: './orders.component.html',
  providers: [NgOnDestroyService],
})
export class CommonOrdersComponent implements OnInit {
  @Input() public ordersRoute: string;
  @Input() public driversRoute: string;
  @Input() public customersRoute: string;

  @Input({ required: true })
  public set orderType(type: EOrderType) {
    this._orderType = type;

    this.watcher$.next(null);
  }

  public REQUEST_DELAY_MS = 300;
  public fleetId: number;
  public ordersData: IOrderData;
  public statuses: IDropdownOrderStatus[];
  public filterData: any;
  public loadingIndicator: boolean;
  public maxDate = new Date();
  public watcher$ = new Subject<number | null>();
  protected orders;
  protected _orderType: EOrderType;

  constructor(
    protected dateUtils: DateUtilService,
    protected orderService: OrderService,
    protected userService: UserService,
    protected utilService: UtilService,
    protected orderStatusesFactory: OrderStatusesFactory,
    protected router: Router,
    protected driverService: DriverService,
    protected customerService: CustomerService,
    protected readonly destroyed$: NgOnDestroyService
  ) {}

  public ngOnInit(): void {
    this.initData();
    this.initWatcher();
    this.onDataChanged();
  }

  public prevPage(): void {
    this.onDataChanged(this.getCurrentPage() - 1);
  }

  public nextPage(): void {
    this.onDataChanged(this.getCurrentPage() + 1);
  }

  public isPrevDisabled(): boolean {
    return !this.ordersData || this.ordersData.first;
  }

  public isNextDisabled(): boolean {
    return !this.ordersData || this.ordersData.last;
  }

  public isSortedColumn(name: string): boolean {
    return this.filterData.sort === name;
  }

  public sortColumn(name: string): void {
    if (this.filterData.sort !== name) {
      this.filterData = { ...this.filterData, sort: name, direction: DIRECTION.DESC };
    } else {
      this.changeSortDirection();
    }
    this.onDataChanged();
  }

  public loadDriversOuterContext(): (
    query: string,
    direction: string,
    pageNumber: number
  ) => Observable<IAllDriversData> {
    return (query, direction, pageNumber) => this.loadDrivers(query, direction, pageNumber);
  }

  public loadDrivers(query: string, direction: string, pageNumber: number): Observable<IAllDriversData> {
    const sort = ['firstName,' + (direction || DIRECTION.ASC), 'lastName,' + (direction || DIRECTION.ASC)];
    const params = {
      pageNumber,
      pageSize: 10,
      name: query,
      sort,
      fleetId: this.fleetId,
    };
    return this.driverService.getAllDrivers(params).pipe(takeUntil(this.destroyed$));
  }

  public onSelectDriver(event: IDriver): void {
    this.filterData.driver = event;
    this.onDataChanged();
  }

  public loadClientsOuterContext(): (
    query: string,
    direction: string,
    pageNumber: number
  ) => Observable<ICustomersData> {
    return (query: string, direction: string, pageNumber: number) => this.loadCustomers(query, direction, pageNumber);
  }

  public loadCustomers(query: string, direction: string, pageNumber: number): Observable<ICustomersData> {
    const sort = ['firstName,' + (direction || DIRECTION.ASC), 'lastName,' + (direction || DIRECTION.ASC)];
    const params = { pageNumber, pageSize: 15, sort, anyParam: query, fleetId: this.fleetId };
    return this.customerService.getCustomers(params).pipe(takeUntil(this.destroyed$));
  }

  public onSelectCustomer(customer): void {
    this.filterData.customer = customer;
    this.onDataChanged();
  }

  public filterDateStart = (d: unknown): boolean => this.utilService.filterDateStart(d, this.filterData.dateEnd);

  public filterDateEnd = (d: unknown): boolean => this.utilService.filterDateEnd(d, this.filterData.dateStart);

  public getStartUTCDateString(): number {
    const dateStart = this.dateUtils.getStartOfDay(this.filterData.dateStart.toDate());
    return this.dateUtils.convertToUTCDateString(dateStart);
  }

  public getEndUTCDateString(): number {
    const dateEnd = this.dateUtils.getEndOfDay(this.filterData.dateEnd.toDate());
    return this.dateUtils.convertToUTCDateString(dateEnd);
  }

  public enableLoadingIndicator(enabled: boolean): void {
    this.loadingIndicator = enabled;
  }

  public navigateToOrderDetailsPage(orderId: string): void {
    void this.router.navigate([this.ordersRoute, orderId]);
  }

  public navigateToCustomerDetailsPage(customerId: number): void {
    if (!customerId) {
      return;
    }

    void this.router.navigate([this.customersRoute, customerId]);
  }

  public navigateToDriverDetailsPage(driverId: number): void {
    if (!driverId) {
      return;
    }

    void this.router.navigate([this.driversRoute, driverId]);
  }

  public onDataChanged(page?: number): void {
    this.watcher$.next(page);
  }

  protected initData(): void {
    this.filterData = {
      dateStart: null,
      dateEnd: null,
      status: this.orderStatusesFactory.defaultStatus,
      driver: null,
      customer: null,
      customerPhone: null,
      subscriberCode: null,
      trip: null,
      zeroPrice: null,
      sort: 'date',
      direction: DIRECTION.DESC,
      partnerId: null,
    };
    this.statuses = this.orderStatusesFactory.statuses;
    this.fleetId = this.userService.getCurrentUserInfo().fleet.id;
  }

  protected initWatcher(): void {
    this.watcher$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(this.REQUEST_DELAY_MS),
        tap(() => this.enableLoadingIndicator(true)),
        switchMap((page: number) => this.getOrders$(page)),
        tap(() => this.enableLoadingIndicator(false))
      )
      .subscribe((res: IOrderData) => (this.ordersData = res));
    this.onDataChanged();
  }

  protected getOrders$(page?: number): Observable<IOrderData> {
    const params = this.generateParams(page);

    return this.orderService.getOrders(params).pipe(
      debounceTime(0),
      takeUntil(this.destroyed$),
      catchError(() => {
        this.enableLoadingIndicator(false);
        return of(null);
      })
    );
  }

  protected generateParams(page?: number): Partial<IGetOrdersParams> {
    let type: string[] = this._orderType === EOrderType.Immediate ? ['ORDER'] : undefined;
    type = this._orderType === EOrderType.Reservation ? ['RESERVATION', 'IN_ADVANCE'] : type;
    let status: OrderStatusType[] = this.filterData.status.value;
    if (this._orderType === EOrderType.Ongoing) {
      status = ['APPROACH', 'DISPATCHING', 'CONFIRMED', 'AT_DEPARTURE_ADDRESS', 'TOWARDS_DESTINATION', 'IN_QUEUE'];
    }

    return {
      dateGt: (this.filterData.dateStart && this.getStartUTCDateString()) || null,
      dateLt: (this.filterData.dateEnd && this.getEndUTCDateString()) || null,
      driverId: (this.filterData.driver && this.filterData.driver.id) || null,
      type,
      status,
      zeroPrice: this.filterData.zeroPrice || null,
      customerId: (this.filterData.customer && this.filterData.customer.id) || null,
      customerPhoneNumber: this.filterData.customerPhone ? encodeURIComponent(this.filterData.customerPhone) : null,
      subscriberCode: this.filterData.subscriberCode || null,
      orderReference: this.filterData.trip || null,
      sort: this.filterData.sort || null,
      direction: this.filterData.direction || null,
      withDriverDetails: true,
      partnerId: this.filterData.partnerId || null,
      size: 15,
      page,
    };
  }

  protected getCurrentPage(): number {
    return this.ordersData ? this.ordersData.number : 0;
  }

  private changeSortDirection(): void {
    this.filterData =
      this.filterData.direction === DIRECTION.ASC
        ? { ...this.filterData, direction: DIRECTION.DESC }
        : { ...this.filterData, direction: DIRECTION.ASC };
  }
}

null;
