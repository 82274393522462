<p-iconField iconPosition="right">
  <p-calendar
    [showIcon]="true"
    [minDate]="minDate.toDate()"
    [maxDate]="maxDate.toDate()"
    [ngModel]="value$ | async"
    dateFormat="DD/MM/YYYY"
    iconDisplay="input"
    (ngModelChange)="setAsDate($event)"
    [firstDayOfWeek]="1"
  >
    <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
      <azz-icon-calendar (click)="clickCallBack($event)" />
    </ng-template>
  </p-calendar>

  <p-inputMask
    pAutoFocus
    data-test-id="date-info"
    [styleClass]="isErrorState ? 'error-border' : ''"
    [mask]="dateFormat"
    [ngModel]="value$ | async"
    (onBlur)="blur($event)"
    (onInput)="onInput($event)"
  />
</p-iconField>
