import { AuthStore } from '@auth/store';
import { DRIVER_STATUS } from '@core/constant/driver-status.constant';
import { DRIVER_SERVICE_TYPE_TAG_ID_MAP, DRIVER_VEHICLE_TYPE_TAG_ID_MAP } from '@core/constant/tag.constant';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as DriverDetailsReducer from '../reducers';

const driverDetails = createFeatureSelector<DriverDetailsReducer.State>(DriverDetailsReducer.driverDetailsFeatureKey);
export const driver = createSelector(driverDetails, state => state?.driver);
export const isDriverBlocked = createSelector(driver, state => state?.status === DRIVER_STATUS.BLOCKED);
export const driverLoading = createSelector(driverDetails, state => state?.driverLoading);
export const ordersStats = createSelector(driverDetails, state => state?.ordersStats);
export const ordersStatsLoading = createSelector(driverDetails, state => state?.ordersStatsLoading);
export const countries = createSelector(driverDetails, state => state?.countries);
export const driverLicenseKinds = createSelector(driverDetails, state => state?.driverLicenseKinds);
export const driverLicense = createSelector(driverDetails, state => state?.driverLicense);
export const vehicle = createSelector(driverDetails, state => state?.vehicle);
export const transportLicense = createSelector(driverDetails, state => state?.transportLicense);
export const newTransportLicense = createSelector(transportLicense, state => (state?.status === 'NEW' ? state : null));
export const colorManagement = createSelector(driverDetails, state => state?.colorManagement);
export const colorManagementEnabled = createSelector(AuthStore.fleetSelector, state => state?.colorManagementEnabled);
export const tags = createSelector(driverDetails, state => state?.tags);
const tagsIds = createSelector(tags, state => state.map(tag => tag.id));
export const serviceTypeTagsIds = createSelector(tagsIds, state =>
  state.filter(id => DRIVER_SERVICE_TYPE_TAG_ID_MAP[id])
);
export const vehicleTypeTagsIds = createSelector(tagsIds, state =>
  state.filter(id => DRIVER_VEHICLE_TYPE_TAG_ID_MAP[id])
);
export const blockBtnLoading = createSelector(driverDetails, state => state?.blockBtnLoading);
export const unblockBtnLoading = createSelector(driverDetails, state => state?.unblockBtnLoading);
export const validateBtnLoading = createSelector(driverDetails, state => state?.validateBtnLoading);
export const confirmLicenseBtnLoading = createSelector(driverDetails, state => state?.confirmLicenseBtnLoading);
export const rejectLicenseBtnLoading = createSelector(driverDetails, state => state?.rejectLicenseBtnLoading);
export const saveBtnLoading = createSelector(driverDetails, state => state?.saveBtnLoading);
