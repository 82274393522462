import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feature } from 'geojson';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';
import { IGetPoiDataParams, IPoi, IPoiData } from '../../models/poi.model';

@Injectable({ providedIn: 'root' })
export class PoiService extends ApiService {
  public API_URL = 'order/api/v1/poi/';
  public POI_TYPE_URL = 'user/api/v1/address/poi';
  private readonly isPoiCreated = new BehaviorSubject<boolean>(false);
  private readonly isPoiUpdated = new BehaviorSubject<boolean>(false);
  private readonly fleetBusinessZoneGeoJSON$ = new BehaviorSubject<Feature>(null);

  constructor(http: HttpClient) {
    super(http);
  }

  public getPoiData(params: Partial<IGetPoiDataParams>): Observable<IPoiData> {
    return this.get(this.API_URL, params);
  }

  public getPoiById(poiId: string): Observable<IPoi> {
    return this.get(`${this.API_URL}${poiId}`);
  }

  public importPoi(poiFile: File): Observable<IPoi[]> {
    const data = new FormData();
    data.append('file', poiFile);

    return this.post(`${this.API_URL}import`, data);
  }

  public createPoi(data: IPoi): Observable<IPoi> {
    return this.post(`${this.API_URL}`, data);
  }

  public deletePoi(poiId: string): Observable<any> {
    return this.delete(`${this.API_URL}${poiId}`);
  }

  public updatePoi(poiId: string, body: IPoi): Observable<IPoi> {
    return this.put(`${this.API_URL}${poiId}`, body);
  }

  public getIsPoiCreatedValue(): Observable<boolean> {
    return this.isPoiCreated.asObservable();
  }

  public setIsPoiCreatedValue(value: boolean): void {
    this.isPoiCreated.next(value);
  }

  public getIsPoiUpdatedValue(): Observable<boolean> {
    return this.isPoiUpdated.asObservable();
  }

  public setIsPoiUpdatedValue(value: boolean): void {
    this.isPoiUpdated.next(value);
  }

  public getFleetBusinessZoneGeoJSON(): Observable<Feature> {
    return this.fleetBusinessZoneGeoJSON$.asObservable();
  }

  public setFleetBusinessZoneGeoJSON(value: Feature): void {
    this.fleetBusinessZoneGeoJSON$.next(value);
  }

  public getPoiTypes(): Observable<{ id: string; name: string }[]> {
    return this.get(this.POI_TYPE_URL);
  }
}
