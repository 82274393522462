import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FRENCH_PHONE_CODE } from '@dash/modules/phone-advisor/common/constants';
import intlTelInput, { Iti } from 'intl-tel-input';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { InputTextModule } from 'primeng/inputtext';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

import { clearPhone } from './clearPhone';
import { normalizeNumber } from './normalizeNumber';

const frenchMasks = {
  [FRENCH_PHONE_CODE]: '00 0 00 00 00 00',
  '+262': '000-00000-0000',
  '+508': '000-00-0000',
  '+590': '000(000)000-000',
};

const frenchCodes = Object.keys(frenchMasks);

@Component({
  selector: 'azz-phone',
  standalone: true,
  imports: [NgxMaskDirective, AsyncPipe, FormsModule, InputTextModule],
  templateUrl: './phone.component.html',
  styleUrl: './phone.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    provideNgxMask(),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true,
    },
  ],
})
export class PhoneComponent implements ControlValueAccessor, AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild('input', { static: false }) protected input: ElementRef<HTMLInputElement>;
  @Output() public phoneChange = new EventEmitter<string>();
  @Input() public inputId: string;
  @Input() public dataTestId: string;

  protected value$ = new BehaviorSubject<string>('');
  protected disabled$ = new BehaviorSubject<boolean>(false);
  protected phoneMask$: Observable<string>;

  protected onTouched?: VoidFunction;
  private onChange?: (value: string | undefined) => void;
  public phoneConfig: Iti;

  constructor() {
    this.phoneMask$ = this.value$.pipe(
      filter(Boolean),
      map(phone => {
        const code = frenchCodes.find(c => phone.startsWith(c));
        if (!code) {
          return 'separator';
        }

        return frenchMasks[code] as string;
      }),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  public ngAfterViewInit(): void {
    this.phoneConfig = intlTelInput(this.input.nativeElement, {
      initialCountry: 'fr',
      countryOrder: ['us', 'gb', 'fr'],
    });

    this.initChangeEvents();
  }

  /* ControlValueAccessor */
  public writeValue(val: string): void {
    if (val === '+0') {
      this.phoneConfig.setNumber(FRENCH_PHONE_CODE);
      this.writeValue(FRENCH_PHONE_CODE);
      return;
    }

    this.value$.next(val);

    if (!this.onTouched) {
      return;
    }

    if (!val) {
      this.value$.next(val);
    }

    const phone = clearPhone(val);
    const formattedPhone = phone ? `+${phone}` : '';

    this.onChange(formattedPhone);
    this.phoneChange.emit(formattedPhone);
  }

  public registerOnChange(fn: VoidFunction): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  /* ControlValueAccessor end */

  public setValue(phoneInput: HTMLInputElement): void {
    setTimeout(() => {
      this.writeValue(phoneInput.value);
    });
  }

  protected touch(): void {
    this.onTouched();
  }

  private internalPaste = false;

  protected onPaste($event: ClipboardEvent): boolean {
    if (this.internalPaste) {
      this.internalPaste = false;
      return;
    }

    const pasteData = $event.clipboardData.getData('text/plain');
    if (!this.input.nativeElement.selectionStart || pasteData.startsWith('0') || pasteData.startsWith('+')) {
      const formattedPhone = normalizeNumber(pasteData);
      this.writeValue(formattedPhone);
      this.phoneConfig.setNumber(formattedPhone);

      $event.preventDefault();
      return false;
    }

    this.internalPaste = true;
    const dataTransfer = new DataTransfer();
    dataTransfer.setData('text', clearPhone(pasteData));
    this.input.nativeElement.dispatchEvent(
      new ClipboardEvent('paste', {
        clipboardData: dataTransfer,
        bubbles: true,
      })
    );
  }

  private onCountryChange(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const code = this.phoneConfig.getSelectedCountryData()?.dialCode;
    if (code) {
      this.writeValue(String(code));
    }
  }

  private initChangeEvents(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: PhoneComponent = this;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.input.nativeElement.addEventListener('close:countrydropdown', self.onCountryChange.bind(self));

    this.destroyRef.onDestroy(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.input.nativeElement.removeEventListener('close:countrydropdown', self.onCountryChange.bind(self));
    });
  }
}
