import { ORDER_TAG_TYPE } from '@core/constant';
import { UIAddress } from '@core/modules/base-create-order/interfaces';
import { OrderForm, OrderFormField } from '@core/modules/base-create-order/types/order-form.type';
import { FRENCH_PHONE_CODE } from '@dash/modules/phone-advisor/common/constants';
import { Action, createReducer, on } from '@ngrx/store';
import { orderFormActions } from '@store/actions';

export const OrderFormFeatureKey = 'order-form';
export type AllowedFieldType = 'searchField' | 'arrivalAddress' | 'departureAddress' | keyof OrderForm;

export type State = {
  formFields: OrderFormField & {
    address: {
      arrival: UIAddress | null;
      departure: UIAddress | null;
    };
  };
  allowedFields: AllowedFieldType[];
};

export const initialState: State = {
  formFields: {
    anonymousPassenger: false,
    appointmentComment: '',
    destinationComment: '',
    comment: '',
    selectedCustomerPhoneNumber: '',
    driver: null,
    passengerName: '',
    paymentType: ORDER_TAG_TYPE.payment[0],
    serviceType: ORDER_TAG_TYPE.service[0],
    vehicleType: ORDER_TAG_TYPE.vehicle[0],
    order: {
      date: null,
      time: null,
      type: null,
    },
    customer: {
      id: null,
      type: 'old',
      name: '',
      lastName: '',
      phoneCode: FRENCH_PHONE_CODE,
      phoneNumber: FRENCH_PHONE_CODE,
      phoneType: null,
    },
    address: {
      arrival: null,
      departure: null,
    },
    tags: [],
  },
  allowedFields: [],
};

const index = createReducer(
  initialState,
  on(orderFormActions.SAVE_ALL_FIELDS, (state, { saved }) => {
    const previousRoute = state.formFields.address;
    const route = saved.address;
    const address = {
      arrival: route?.arrival ? route.arrival : previousRoute.arrival,
      departure: route?.departure ? route.departure : previousRoute.departure,
    };

    const { order } = initialState.formFields;

    return {
      formFields: { ...state.formFields, order, ...saved, address },
      allowedFields: state.allowedFields,
    };
  }),
  on(orderFormActions.CLEAR_ALL_FIELDS, ({ allowedFields }) => ({ ...initialState, allowedFields })),
  on(orderFormActions.SET_ALLOWED_FIELDS, (state, { allowedFields }) => {
    return { ...state, allowedFields };
  })
);

export const reducer = (state: State | undefined, action: Action) => index(state, action);
