import { Injectable } from '@angular/core';

class PageTokens {
  constructor(
    private readonly previous: any,
    private readonly current: any
  ) {}

  public getPrevious() {
    return this.previous;
  }

  public getCurrent() {
    return this.current;
  }
}

export class PageHistory {
  private data: any[] = [];
  private visitedTokens: any = {};
  private knownItemsCount = 0;
  private prevClickCount = 0;

  constructor() {
    this.reset();
  }

  public push(pageToken) {
    let previous = null;
    if (this.data.length > 0) {
      previous = this.data[this.data.length - 1].getCurrent();
    }
    this.data.push(new PageTokens(previous, pageToken));
    if (this.prevClickCount > 0) {
      this.prevClickCount--;
    }
  }

  public pop() {
    if (this.data.length > 0) {
      this.prevClickCount++;
      return this.data.pop().getPrevious();
    }
  }

  public getPrevious() {
    if (this.data.length > 0) {
      return this.data[this.data.length - 1].getPrevious();
    }
    return null;
  }

  public getCurrent() {
    if (this.data.length > 0) {
      return this.data[this.data.length - 1].getCurrent();
    }
    return null;
  }

  public isEmpty() {
    return this.data.length === 0;
  }

  public getItemsCount() {
    return this.knownItemsCount;
  }

  public addKnownItemsByToken(pageToken, itemsCount) {
    if (pageToken == null) {
      // null or undefined
      pageToken = null;
    }
    if (!(pageToken in this.visitedTokens)) {
      if (this.prevClickCount === 0) {
        this.knownItemsCount += itemsCount;
      }
      this.visitedTokens[pageToken] = true;
    }
  }

  public addItemsCountByToken(data: { pageToken: string; itemsCount: number; increaseCount?: boolean }): void {
    const token = data.pageToken == null ? null : data.pageToken;
    if (!(token in this.visitedTokens)) {
      if (this.prevClickCount === 0 && data.increaseCount) {
        this.knownItemsCount += data.itemsCount;
      }
      this.visitedTokens[token] = true;
    }
  }

  public resetAll(): void {
    this.data = [];
    this.visitedTokens = {};
    this.prevClickCount = 0;
    this.knownItemsCount = 0;
  }

  public reset() {
    this.data = [];
    this.visitedTokens = {};
    this.prevClickCount = 0;
  }
}

@Injectable({ providedIn: 'root' })
export class PaginationService {
  public createPageHistory() {
    return new PageHistory();
  }
}
