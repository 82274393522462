import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DIRECTION } from '../../constant/direction.enum';
import { IAllDriversData, IGetAllDriversParams } from '../../models/driver.model';
import { ICreateNewsPayload } from '../../models/news-model';
import { IDriverUserInfo } from '../../models/user-info.model';

@Component({
  selector: 'app-multiple-dropdown',
  templateUrl: './multiple-dropdown.component.html',
  styleUrls: ['./multiple-dropdown.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleDropdownComponent implements OnInit {
  @Input() searchRequest: (params: Partial<IGetAllDriversParams>) => Observable<IAllDriversData>;
  @Input() optionFormatFields: string[] = [];
  @Input() azzId: string;
  @Input() azzLabel: string;
  @Input() azzAllOptionsLabel: string;
  @Input() azzPlaceholder: string;
  @Input() azzSearchPlaceholder: string;
  @Input() ngDisabled: boolean;
  @Input() ngRequired: boolean;
  @Input() recipientsList: Partial<IDriverUserInfo>[];
  @ViewChild('ngForm') ngForm: NgForm;

  public onDestroy$ = new Subject<void>();
  public detailsComponentInitialized = false;
  public displayValue: string;
  public REQUEST_DELAY_MS = 500;
  public allSelected = true;
  public selectedOptionsTemp: { [key: string]: Partial<IDriverUserInfo> } = {};
  public filterModel = {
    name: '',
    pageNumber: 0,
    pageSize: 15,
    sort: [`firstName,${DIRECTION.ASC}`, `lastName,${DIRECTION.ASC}`],
    direction: DIRECTION.ASC,
  };
  public watcher$ = new Subject<number>();
  public optionsData: IAllDriversData;
  public loading: boolean;
  private readonly DEBOUNCE = 500;

  ngOnInit() {
    this.initWatcher();
    this.isAllSelected();
  }

  public toggleAllSelected(): void {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.selectAllOptions();
    } else {
      this.resetAllOptions();
    }
    this.updateDisplayValue();
  }

  public isAllSelected() {
    if (!this.recipientsList) {
      return;
    }
    if (this.recipientsList) {
      this.allSelected = false;
      this.detailsComponentInitialized = true;
    }
  }

  public generateNewsParams(): Partial<ICreateNewsPayload> {
    const selectedValues = this.getSelectedValues();
    return {
      recipientType: this.allSelected ? 'ALL' : selectedValues.length > 1 ? 'SEVERAL' : 'SINGLE',
      recipients: this.allSelected ? null : selectedValues.map(i => i.id),
    };
  }

  public toggleOption(data: Partial<IDriverUserInfo>) {
    this.selectedOptionsTemp = {
      ...this.selectedOptionsTemp,
      [data.id]: this.selectedOptionsTemp[data.id]
        ? false
        : { id: data.id, firstName: data.firstName, lastName: data.lastName },
    };
    this.setAllSelected();
    this.updateDisplayValue();
  }

  public changeFilterDirection(): void {
    this.filterModel.direction = this.filterModel.direction === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;
    this.watcher$.next(null);
  }

  public getCurrentPage(): number {
    return this.optionsData ? this.optionsData.number : 0;
  }

  public prevPage(): void {
    this.watcher$.next(this.getCurrentPage() - 1);
  }

  public nextPage(): void {
    this.watcher$.next(this.getCurrentPage() + 1);
  }

  public isPrevDisabled(): boolean {
    return !this.optionsData || this.optionsData.first;
  }

  public isNextDisabled(): boolean {
    return !this.optionsData || this.optionsData.last;
  }

  public isPaginationVisible(): boolean {
    return this.optionsData && this.optionsData.totalPages > 1;
  }

  public isFormValid(): boolean {
    return this.ngForm.valid;
  }

  private initWatcher(): void {
    this.watcher$
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(this.DEBOUNCE),
        tap(() => (this.loading = true)),
        switchMap((pageNumber?: number) => this.searchRequest(this.generateParams(pageNumber))),
        tap(() => (this.loading = false)),
        catchError(() => {
          this.loading = false;
          return of(null);
        })
      )
      .subscribe((res: IAllDriversData) => this.handleData(res));
    this.watcher$.next(null);
  }

  private handleData(res: IAllDriversData): void {
    this.optionsData = res;
    if (this.allSelected && this.optionsData) {
      this.selectAllOptions();
    }
    this.updateDisplayValue();
  }

  private updateDisplayValue(): void {
    let result = ''; // is invalid form value

    if (this.allSelected) {
      result = this.azzAllOptionsLabel;
    } else {
      const values = this.getSelectedValues();
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (i === 0) {
          result += `${value.firstName} ${value.lastName}`;
        } else {
          result += ` + ${values.length - 1}`;
          break;
        }
      }
    }
    this.displayValue = result;
  }

  private getSelectedValues(): Partial<IDriverUserInfo>[] {
    if (this.detailsComponentInitialized) {
      this.detailsComponentInitialized = false;
      return this.recipientsList;
    }
    return Object.values(this.selectedOptionsTemp).filter(val => !!val);
  }

  private setAllSelected(): void {
    this.allSelected = this.getSelectedValues()?.length === this.optionsData?.content?.length;
  }

  private resetAllOptions(): void {
    this.selectedOptionsTemp = {};
  }

  private selectAllOptions(): void {
    this.optionsData?.content?.forEach(i => {
      this.selectedOptionsTemp[i.id] = {
        id: i.id,
        firstName: i.firstName,
        lastName: i.lastName,
      };
    });
  }

  private generateParams(pageNumber?: number): Partial<IGetAllDriversParams> {
    return {
      name: this.filterModel.name,
      pageSize: 15,
      pageNumber,
      sort: [`firstName,${this.filterModel.direction}`, `lastName,${this.filterModel.direction}`],
    };
  }
}
