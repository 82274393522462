import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IDriverUserInfo } from '../../../../models/user-info.model';
import { IVehicle } from '../../../../models/vehicle.model';

@Component({
  selector: 'azz-custom-order-vehicle-data',
  templateUrl: './vehicle-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleDataComponent {
  @Input() driver: IDriverUserInfo;
  @Input() vehicle: IVehicle;
}
