import { ElementRef, Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { UtilService } from '../services/common/util.service';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(private readonly utilService: UtilService) {}

  public markFieldsAsTouched(form: FormGroup | FormArray) {
    Object.keys(form.controls).forEach(control => {
      form.get(control).markAsTouched();
      form.get(control).markAsDirty();
    });
  }

  public scrollToFirstInvalidControl(formsElemRefArr: ElementRef<HTMLElement>[]) {
    let firstInvalidControl;
    for (const elemRef of formsElemRefArr) {
      firstInvalidControl = elemRef.nativeElement.querySelector('.ng-invalid:not(form)');
      if (firstInvalidControl) {
        break;
      }
    }

    if (!firstInvalidControl) {
      return;
    }

    // Temporary hack because of attach-file component template & reactive forms mixture
    if (this.utilService.pageHasScrollBar()) {
      this.scrollAndFocusInvalidControl(firstInvalidControl);
    } else {
      firstInvalidControl.focus();
    }
  }

  public setValidators(form: FormGroup | FormArray, controlName?: string | number, validators?: any[]): void {
    form.controls[controlName].setValidators(validators);
    form.controls[controlName].updateValueAndValidity();
  }

  public removeValidators(form: FormGroup | FormArray, controlName?: string | number): void {
    form.controls[controlName].clearValidators();
    form.controls[controlName].updateValueAndValidity();
  }

  private scrollAndFocusInvalidControl(elem: HTMLElement): void {
    window.scroll({
      top: this.getTopOffset(elem),
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(window, 'scroll')
      .pipe(debounceTime(100), take(1))
      .subscribe(() => elem.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
