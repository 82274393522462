import { Action, createReducer, on } from '@ngrx/store';
import { isBoolean } from 'lodash-es';

import * as ScheduleActions from '../actions/schedule.actions';

export const scheduleFeatureKey = 'schedule';

export type State = {
  planning: {
    isFullWidth: boolean;
    isSchedulerLoading: boolean;
    focusTime: string;
  };
};

const initialState: State = {
  planning: {
    isFullWidth: false,
    isSchedulerLoading: false,
    focusTime: null,
  },
};

const scheduleReducer = createReducer(
  initialState,
  on(ScheduleActions.toggleFullWidth, ({ planning: { isFullWidth: previous, ...rest } }, { isFullWidth }) => ({
    planning: {
      ...rest,
      isFullWidth: isBoolean(isFullWidth) ? isFullWidth : !previous,
    },
  })),
  on(ScheduleActions.setFocusTimeForSchedule, ({ planning }, { focusTime }) => ({
    planning: {
      ...planning,
      focusTime,
    },
  })),
  on(ScheduleActions.setLoadingStateForSchedule, ({ planning }, { isSchedulerLoading }) => ({
    planning: {
      ...planning,
      isSchedulerLoading,
    },
  }))
);

export const reducer = (state: State | undefined, action: Action) => scheduleReducer(state, action);
