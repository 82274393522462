<div class="modal-notification">
  <p class="modal-notification__message">
    {{ 'DRIVER_OPERATION_MESSAGE_' + operation | translate }}
  </p>
  <div class="modal-notification__btn-group">
    <button type="button" class="custom-btn custom-btn--grey mr-3" (click)="close()">
      {{ 'CANCEL' | translate | titlecase }}
    </button>
    <button type="button" class="custom-btn custom-btn--green" (click)="submit()">
      {{ 'DRIVER_OPERATION_BUTTON_' + operation | translate }}
    </button>
  </div>
</div>
