import { Component, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'azz-tooltip',
  standalone: true,
  imports: [TranslateModule, NgbTooltip],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.less',
})
export class TooltipComponent {
  @Input() dispatchBeforeMinutesRestrictions: { min: number; max: number };
}
