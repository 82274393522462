import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { Feature } from 'geojson';
import {
  DivIcon,
  FeatureGroup,
  Icon,
  latLng,
  LatLngExpression,
  Layer,
  Map,
  MapOptions,
  Marker,
  Point,
  tileLayer,
} from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { COORDS } from '../../../constant/coords.constant';
import { POI_ICON_URL } from '../../../constant/poi.constant';
import { IPoi, IPoiData } from '../../../models/poi.model';
import { PoiService } from '../../../services/common/poi.service';
import { CreateZoneService } from '../../../services/map/create-zone.service';

@Component({
  selector: 'azz-map-points-of-interest',
  templateUrl: './map-points-of-interest.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class MapPointsOfInterestComponent implements OnChanges {
  @Input() poiData: IPoiData;
  @Output() filterPoiByMarker$ = new EventEmitter<{ label: string; serviceLevel: string }>();
  @Output() resetFilterPoiByMarker$ = new EventEmitter<void>();
  public geoOptions: MapOptions;
  public geoLayers: Layer[];
  public defaultIconSize = 35;
  public largeIconSize = 50;
  public selectedMarker: Marker;
  public selectedMarkerIcon: Icon | DivIcon;
  public poiDictionary: Record<string, IPoi> = {};
  public markerDictionary: Record<string, Marker<any>> = {};
  private map: Map;
  private readonly zoom = 6;
  private readonly createdMarkerZoom = 12;
  private readonly poiLayers = new FeatureGroup();
  private readonly businessZoneLayer = new FeatureGroup();
  private businessZoneGeoJSON: any;
  // @ts-ignore
  private readonly layersOnlyBorderOptions = this.createZoneService.getLayersOnlyBorderOptions();
  private readonly poiDataReady$ = new BehaviorSubject<boolean>(null);

  constructor(
    private readonly poiService: PoiService,
    private readonly createZoneService: CreateZoneService,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initDefaultMapOptionsAndLayers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.poiData.currentValue) {
      this.poiDataReady$.next(true);
    }
  }

  public initDefaultMapOptionsAndLayers(): void {
    this.geoOptions = {
      zoom: this.zoom,
      center: latLng(COORDS.FRANCE_CENTER_LAT, COORDS.FRANCE_CENTER_LNG),
    };
    this.geoLayers = [tileLayer(COORDS.TILE, { maxZoom: 18, attribution: '...' })];
  }

  public onMapReady(map: Map) {
    this.map = map;
    this.map.addLayer(this.poiLayers);
    this.map.addLayer(this.businessZoneLayer);
    this.listenForPoiData();
    this.listenForFleetBusinessZoneGeoJSON();
  }

  public listenForPoiData(): void {
    this.poiDataReady$
      .pipe(
        takeUntil(this.destroyed$),
        filter(val => !!val)
      )
      .subscribe(() => {
        for (const poi of this.poiData.content) {
          this.poiDictionary[poi.id] = poi;
          const icon = new Icon({
            iconUrl: poi.serviceLevel ? POI_ICON_URL[poi.serviceLevel] : POI_ICON_URL.ALL,
            iconSize: new Point(this.defaultIconSize, this.defaultIconSize),
          });
          const marker = new Marker([poi.position.latitude, poi.position.longitude], { icon });
          marker.feature =
            marker.feature ||
            ({
              id: poi.id,
              label: poi.label,
              serviceLevel: poi.serviceLevel,
            } as any);
          marker.bindPopup(poi.label, {
            closeButton: false,
            closeOnEscapeKey: false,
            closeOnClick: false,
          });
          marker.on('click', this.onMarkerClick, this);
          marker.addTo(this.poiLayers);
          this.markerDictionary[poi.id] = marker;
        }
      });
  }

  public onMarkerClick(evt: any) {
    if (this.selectedMarker) {
      if (evt.target.feature.id === this.selectedMarker.feature.id) {
        this.toggleIconSize(this.selectedMarkerIcon);
        evt.target.setIcon(this.selectedMarkerIcon);
        this.selectedMarker.setZIndexOffset(0);
        this.selectedMarker = null;
        this.selectedMarkerIcon = null;
        this.resetFilterPoiByMarker$.emit();
      } else {
        const { label, serviceLevel } = evt.target.feature;
        this.filterPoiByMarker$.emit({ label, serviceLevel });
        // reset previous marker
        this.toggleIconSize(this.selectedMarkerIcon);
        this.selectedMarker.setIcon(this.selectedMarkerIcon);
        this.selectedMarker.setZIndexOffset(0);
        // set new marker
        this.selectedMarker = evt.target;
        this.selectedMarkerIcon = this.selectedMarker.options.icon;
        this.toggleIconSize(this.selectedMarkerIcon);
        this.selectedMarker.setIcon(this.selectedMarkerIcon);
        this.selectedMarker.setZIndexOffset(1000);
      }
    } else {
      this.selectedMarker = evt.target;
      const { label, serviceLevel } = evt.target.feature;
      this.filterPoiByMarker$.emit({ label, serviceLevel });
      this.selectedMarkerIcon = this.selectedMarker.options.icon;
      this.toggleIconSize(this.selectedMarkerIcon);
      this.selectedMarker.setIcon(this.selectedMarkerIcon);
      this.selectedMarker.setZIndexOffset(1000);
    }
  }

  public toggleIconSize(currentIcon: Icon | DivIcon): void {
    currentIcon.options.iconSize =
      Object.values(currentIcon.options.iconSize).indexOf(this.defaultIconSize) !== -1
        ? [this.largeIconSize, this.largeIconSize]
        : [this.defaultIconSize, this.defaultIconSize];
  }

  public resetSelectedMarker(): void {
    if (this.selectedMarker) {
      this.selectedMarker.closePopup();
      this.toggleIconSize(this.selectedMarkerIcon);
      this.selectedMarker.setIcon(this.selectedMarkerIcon);
      this.selectedMarker.setZIndexOffset(0);
      this.selectedMarker = null;
      this.selectedMarkerIcon = null;
    }
  }

  public setMarkerView(data: LatLngExpression): void {
    if (this.map) {
      this.map.setView(data, this.createdMarkerZoom);
    }
  }

  public showMarkerOnTableClick(poiId: string) {
    this.selectedMarker = this.markerDictionary[poiId];
    this.selectedMarkerIcon = this.selectedMarker.options.icon;
    this.toggleIconSize(this.selectedMarkerIcon);
    this.selectedMarker.setIcon(this.selectedMarkerIcon);
    this.selectedMarker.openPopup();
    this.selectedMarker.setZIndexOffset(1000);
  }

  public removeMarkerFromMap(poiId: string): void {
    this.poiLayers.removeLayer(this.markerDictionary[poiId]);
  }

  private listenForFleetBusinessZoneGeoJSON(): void {
    this.poiService
      .getFleetBusinessZoneGeoJSON()
      .pipe(
        takeUntil(this.destroyed$),
        filter(val => !!val)
      )
      .subscribe((res: Feature) => {
        this.businessZoneGeoJSON = this.createZoneService.convertToGeoJSON(res);
        this.businessZoneLayer.addLayer(this.businessZoneGeoJSON);
        this.businessZoneLayer.setStyle(this.layersOnlyBorderOptions);
        this.map.fitBounds(this.businessZoneGeoJSON.getBounds());
        this.poiService.setFleetBusinessZoneGeoJSON(null);
      });
  }
}
