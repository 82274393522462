import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IPaginationState } from '../../../models/common.model';
import { IException, IExceptionData } from '../../../models/exception.model';

@Component({
  selector: 'app-exceptions-table',
  templateUrl: './exceptions-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExceptionsTableComponent {
  @Input() exceptionsData: IExceptionData;
  @Output() updatePagination$ = new EventEmitter<Partial<IPaginationState>>();
  @Output() navigateToDriver$ = new EventEmitter<number>();
  @Output() navigateToCustomer$ = new EventEmitter<number>();
  @Output() navigateToException$ = new EventEmitter<string>();

  public prevPage(): void {
    this.updatePagination$.emit({ page: this.getCurrentPage() - 1 });
  }

  public nextPage(): void {
    this.updatePagination$.emit({ page: this.getCurrentPage() + 1 });
  }

  public isPrevDisabled(): boolean {
    return !this.exceptionsData || this.exceptionsData.first;
  }

  public isNextDisabled(): boolean {
    return !this.exceptionsData || this.exceptionsData.last;
  }

  public trackByFunc(index: number, item: IException): string {
    return item?.id || null;
  }

  private getCurrentPage(): number {
    return this.exceptionsData?.number || 0;
  }
}
