import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';

import { DropdownMultipleService } from './dropdown-multiple.service';
import { DIRECTION } from '../../constant/direction.enum';
import { NEWS_RECIPIENT_TYPE } from '../../constant/news-recipient.constant';

@Component({
  selector: 'azz-dropdown-multiple',
  templateUrl: './dropdown-multiple.component.html',
})
export class DropdownMultipleComponent implements OnInit {
  @Input() azzId: string;
  @Input() azzLabel: string;
  @Input() azzAllOptionsLabel: string;
  @Input() azzMode: any;
  @Input() azzOptionFormat: any;
  @Input() azzPlaceholder: string;
  @Input() azzSearchPlaceholder: string;
  @Input() azzAllByDefault: boolean;
  @Input() azzDataRequest: any;
  @Input() azzSelectType: any;
  @Input() ngDisabled: boolean;
  @Input() ngRequired: boolean;
  @Input() data: any;
  @Input() hideAllCheckbox: boolean;
  @Input() withoutDefaultSelectAll: boolean; // todo to be replaced...
  @Input() hideSearch: boolean;
  @Input() recalculateDisplayValueByDefault: boolean; // doesn't works cause parent doesn't provide it

  public onDestroy$ = new Subject<void>();
  public REQUEST_DELAY_MS = 500;
  public isOpened: boolean;
  public loadingIndicator: boolean;
  public displayValue: any;
  public optionsData: any;
  public optionFormatFields: any;
  public filter: any;
  public filterWatcher$ = new Subject();
  public selectedOptions: string[];

  constructor(private readonly azzDropDownService: DropdownMultipleService) {
    this.isOpened = false;
    this.loadingIndicator = false;

    this.displayValue = null;
    this.optionsData = null;
    this.optionFormatFields = null;
    this.filter = {
      direction: DIRECTION.ASC,
      query: null,
      selectedAll: true,
    };
  }

  ngOnInit() {
    this.init();
  }

  public changeFilterDirection(): void {
    this.filter.direction = this.filter.direction === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;
    this.filterWatcher$.next(this.filter.direction);
  }

  public onOptionClick() {
    this.filter.selectedAll = this.optionsData.content.length === this.getSelectedOptionsFullData().length;
    this.azzSelectType =
      this.optionsData.content.length === this.getSelectedOptionsFullData().length ? NEWS_RECIPIENT_TYPE.ALL : null;
    this.updateDisplayValue();
    this.azzDropDownService.azzDropDownMultipleValueChanged.next(this.getSelectedOptionsFullData());
  }

  public getSelectedOptions() {
    if (this.optionsData.content) {
      return this.optionsData.content.filter(item => item.selected).map(item => item.id);
    }
  }

  public getSelectedOptionsFullData() {
    return this.optionsData.content.filter(item => item.selected);
  }

  public isCheckedAll() {
    return this.azzSelectType === NEWS_RECIPIENT_TYPE.ALL;
  }

  public selectAllOptions() {
    this.optionsData.content.map(item => (item.selected = true));
  }

  public toggleAllOptions() {
    this.checkAllOptions(!this.isCheckedAll());
    this.updateDisplayValue();
    this.azzDropDownService.azzDropDownMultipleValueChanged.next(this.getSelectedOptionsFullData()); // can't find
    // where subscribe block is
  }

  public isPaginationVisible(): boolean {
    return this.optionsData && this.optionsData.totalPages > 1;
  }

  public prevPageEnabled(): boolean {
    return this.optionsData && this.optionsData.number > 0;
  }

  public nextPageEnabled(): boolean {
    return this.optionsData && this.optionsData.number < this.optionsData.totalPages - 1;
  }

  public prevPage(): void {
    if (this.prevPageEnabled()) {
      this.invokeSearch(this.optionsData.number - 1);
    }
  }

  public nextPage(): void {
    if (this.nextPageEnabled()) {
      this.invokeSearch(this.optionsData.number + 1);
    }
  }

  private init(): void {
    this.parseOptionFormat();
    this.loadInitialData();
    this.initWatchers();
  }

  private parseOptionFormat(): void {
    this.optionFormatFields = this.azzOptionFormat.split(' ');
  }

  private loadInitialData(): void {
    this.invokeSearch(0, () => this.setDefaultValues);
  }

  private initWatchers(): void {
    this.filterWatcher$
      .pipe(takeUntil(this.onDestroy$), debounceTime(this.REQUEST_DELAY_MS), distinctUntilChanged())
      .subscribe(() => this.invokeSearch(this.optionsData?.number || 0));
  }

  private invokeSearch(pageNumber: number, cb?: () => void): void {
    this.enableLoadingIndicator(true);
    this.saveSelectedOptions();
    this.azzDataRequest({
      query: this.filter.query,
      direction: this.filter.direction,
      pageNumber,
    })
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(this.REQUEST_DELAY_MS),
        finalize(() => this.enableLoadingIndicator(false))
      )
      .subscribe(
        response => {
          this.optionsData = response;
          if (this.withoutDefaultSelectAll) {
            this.filter.selectedAll = false;
          } // Doesn't work, cause parent doesn;t
          // provide this value at all
          this.filter.selectedAll =
            !!this.optionsData.content && this.optionsData.content.length === this.getSelectedOptionsFullData().length;
          // doesn't work first time
          this.azzSelectType =
            !!this.optionsData.content && this.optionsData.content.length === this.getSelectedOptionsFullData().length
              ? NEWS_RECIPIENT_TYPE.ALL
              : null; // doesn't work first time
          // this.filter.selectedAll && this.azzSelectType can be replaced by one property

          if (this.recalculateDisplayValueByDefault) {
            this.updateDisplayValue();
          } // Doesn't work
          this.refreshEnabledCheckboxes(); // doesn't work
          this.reSelectOptions(); // doesn't work

          if (!this.selectedOptions) {
            // this.azzSelectType === NEWS_RECIPIENT_TYPE.ALL; !!!!!Don't know meaning of this code!!!!!
            if (!this.withoutDefaultSelectAll) {
              this.toggleAllOptions(); // always works, always checks all options, here shows "tous" label
            }
          }

          if (cb) {
            cb();
          }
        },
        () => (this.optionsData = null)
      );
  }

  private setDefaultValues(): void {
    if (this.azzAllByDefault) {
      this.checkAllOptions(true);
    }
  }

  private refreshEnabledCheckboxes(): void {
    if (this.azzSelectType === NEWS_RECIPIENT_TYPE.ALL) {
      this.checkAllOptions(true);
    }
  }

  private updateDisplayValue(): void {
    let result = '';

    if (this.isCheckedAll() || this.optionsData.content.length === this.getSelectedOptionsFullData().length) {
      result = this.azzAllOptionsLabel;
    } else {
      const selectedOptions = this.getSelectedOptionsFullData();

      if (selectedOptions && selectedOptions.length) {
        const firstItem = selectedOptions[0];
        for (let i = 0; i < this.optionFormatFields.length; i++) {
          if (i > 0) {
            result += ' ';
          }
          result += firstItem[this.optionFormatFields[i]];
        }
        if (selectedOptions.length > 1) {
          result += ' + ' + (selectedOptions.length - 1);
        }
      }
    }

    this.displayValue = result;
  }

  private checkAllOptions(checked: boolean): void {
    if (checked) {
      this.azzSelectType = NEWS_RECIPIENT_TYPE.ALL;
      this.optionsData.content.map(item => (item.selected = true));
    } else {
      this.optionsData.content.map(item => (item.selected = false));
      this.azzSelectType = null;
    }
  }

  private enableLoadingIndicator(enabled: boolean): void {
    this.loadingIndicator = enabled;
  }

  private reSelectOptions() {
    if (!this.selectedOptions) {
      return []; // not need in return value
    }

    this.optionsData.content.map(item => {
      if (this.selectedOptions.includes(item.id) && this.azzAllByDefault) {
        item.selected = true;
      }
    });
  }

  private saveSelectedOptions(): void {
    if (!this.optionsData) {
      return;
    } // first time when component is created

    this.selectedOptions = this.getSelectedOptions();
  }
}
