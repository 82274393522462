<div class="row customer">
  <div class="form-group col-12 summary-customer-info">
    <div class="azz-font-bold header d-flex align-items-center">
      {{ customerFullName }}
      <span tabindex="-1" class="header-link header-link--edit" (click)="editCustomer()">edit</span>

      <span *ngIf="customerData?.status === 'ACTIVE'" class="customer-status active"></span>

      <span
        *ngIf="customerData?.status === 'BLOCKED'"
        class="customer-status blocked"
        [ngbTooltip]="tooltipContentTemplate"
        placement="bottom"
        tooltipClass="summary-tooltip"
      ></span>
      <ng-template #tooltipContentTemplate>
        <small
          [innerHTML]="
            'CUSTOMER_BLOCKED_EXPLANTION_SUMMARY'
              | translate: { blockTime: customerBlockTime, blockReason: customerBlockReason }
          "
        >
        </small>
      </ng-template>
    </div>

    <div class="info-row" *ngIf="customerData?.subscriberCode">
      <span class="label">{{ 'PA_CREATE_NEW_SUBSCRIBER_CODE' | translate }}:</span>
      <span class="value">{{ customerData.subscriberCode }}</span>
    </div>

    <div class="info-row" *ngIf="customerData?.phones?.length">
      <span *ngFor="let phone of getSortedPhonesByOrderNumber(); let i = index" class="value">
        {{ phone.phoneCode }}{{ phone.phoneNumber }}
        <span *ngIf="i !== getSortedPhonesByOrderNumber().length - 1"> / </span>
      </span>
    </div>
    <div class="info-row" *ngIf="customerData?.emails?.length">
      <span *ngFor="let item of getSortedEmailsByOrderNumber(); let i = index" class="value">
        {{ item.email }}
        <span *ngIf="i !== getSortedEmailsByOrderNumber().length - 1"> / </span>
      </span>
    </div>
  </div>
</div>
