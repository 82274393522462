/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * object with tag info used in tags search responses
 */
export interface TagItemResponseWebApiModel {
  /**
   * tag ID
   */
  id: string;
  /**
   * tag name
   */
  name: string;
  driverTag?: boolean;
  crcVisible?: boolean;
  orderProposalVisible?: boolean;
  orderDetailsVisible?: boolean;
  zoneDetailsVisible?: boolean;
  paymentMode?: boolean;
  crcPriority?: number;
}
