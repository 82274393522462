import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomControlBase } from '@common/components/forms/custom-control.base';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'azz-delay-dispatch',
  standalone: true,
  templateUrl: './delay-dispatch.component.html',
  styleUrl: './delay-dispatch.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DelayDispatchComponent),
      multi: true,
    },
  ],
  imports: [AsyncPipe, FormsModule, InputNumberModule],
})
export class DelayDispatchComponent extends CustomControlBase<number> {
  @Input() public isErrorState: number;
  @Input({ required: true }) public defaultValue: number | null;

  protected set(event: Event): void {
    const { ariaValueNow: value } = event.target as HTMLInputElement;

    if (this.disabled$.value) {
      this.setValue(null);
    } else {
      this.setValue(value || value === '0' ? +value : this.defaultValue);
    }
  }
}
