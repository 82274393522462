import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@azz/env';
import * as Sentry from '@sentry/angular-ivy';
import { SentryErrorHandler } from '@sentry/angular-ivy';

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler extends SentryErrorHandler {
  constructor() {
    super({
      showDialog: true,
      logErrors: environment.production,
    });
  }

  public override handleError(error: Error | HttpErrorResponse): void {
    Sentry.captureException(error);
  }
}
