import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IOrderValidResponse } from '@core/models/order-logs.model';
import { BusinessZoneParams } from '@core/modules/nouvelle-commande/interfaces/business-zone.interface';
import { Observable } from 'rxjs';

import { IAppConfigParams } from '../../models/app-config-params.model';
import { IFleetAgent } from '../../models/cti.model';
import {
  IComeol,
  IComeolUpdateParams,
  IFleet,
  IFleetCitiesData,
  IFleetCitiesParams,
  IFleetData,
  IFleetParametersForOtherUser,
  IRankedFleetData,
  IRankedFleetParams,
  ISearchFleetParams,
  KbiModel,
} from '../../models/fleet.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class FleetService extends ApiService {
  private readonly FLEET_URL = 'user/api/v1/fleet/';
  private readonly FLEET_KBIS_URL = 'user/api/v1/kbis/';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getGeneralFleetParamsConfig(): Observable<IAppConfigParams> {
    return this.get(`${this.FLEET_URL}config`);
  }

  public updateGeneralFleetParamsConfig(payload: IAppConfigParams): Observable<IAppConfigParams> {
    return this.put(`${this.FLEET_URL}config`, payload);
  }

  public getFleetParamsConfig(fleeId: number): Observable<IAppConfigParams> {
    return this.get(`${this.FLEET_URL}${fleeId}/parameters/config`);
  }

  public updateFleetParamsConfig(fleeId: number, payload: IAppConfigParams): Observable<IAppConfigParams> {
    return this.put(`${this.FLEET_URL}${fleeId}/parameters/config`, payload);
  }

  public getFleetConfigForAll(): Observable<{
    orderUpdateTimeLimitSeconds: number;
    defaultDispatchBeforeMinutes: number;
  }> {
    return this.get(`${this.FLEET_URL}config/for/all`);
  }

  public updateFleet(id: string | number, fleet: any): Observable<any> {
    return this.put(this.FLEET_URL + `${id}`, fleet);
  }

  public getParameters(id: number): Observable<any> {
    return this.get(`${this.FLEET_URL}${id}/parameters`);
  }

  public getFleetParametersForOtherUser(id: number): Observable<IFleetParametersForOtherUser> {
    return this.get(`${this.FLEET_URL}${id}/parameters/for/pa`);
  }

  public updateParameters(id: string | number, parameters: any): Observable<any> {
    return this.put(`${this.FLEET_URL}${id}/parameters`, parameters);
  }

  public saveGeoArea(id: string | number, area: any): Observable<any> {
    return this.post(`${this.FLEET_URL}${id}/parameters/geoArea`, area);
  }

  public searchFleets(params: Partial<ISearchFleetParams>): Observable<IFleetData> {
    return this.get<IFleetData>(`${this.FLEET_URL}search`, params);
  }

  public blockFleet(fleetId: number): Observable<any> {
    return this.put(`${this.FLEET_URL}${fleetId}/block`, {});
  }

  public unblockFleet(fleetId: number): Observable<any> {
    return this.put(`${this.FLEET_URL}${fleetId}/unblock`, {});
  }

  public getKbis(fleetId: number): Observable<KbiModel> {
    return this.get<KbiModel>(`${this.FLEET_KBIS_URL}${fleetId}`);
  }

  public updateRating(fleetId: number, rating: any): Observable<any> {
    return this.put(`${this.FLEET_URL}${fleetId}/rating`, { rating });
  }

  public getRankedFleets(params: Partial<IRankedFleetParams>): Observable<IRankedFleetData> {
    return this.get(`${this.FLEET_URL}ratingList`, params);
  }

  public setFleetGeoArea(fleetId: number, payload): Observable<any> {
    return this.post(`${this.FLEET_URL}${fleetId}/parameters/geoArea`, payload);
  }

  public updateFleetCti(fleetId: number, enable: boolean): Observable<IFleet> {
    return this.put(`${this.FLEET_URL}${fleetId}/cti`, { enable });
  }

  public createAgents(fleetId: number, count: number): Observable<IFleetAgent[]> {
    return this.post(`${this.FLEET_URL}${fleetId}/agents/${count}`, {});
  }

  public loadFleetAgents(fleetId: number): Observable<IFleetAgent[]> {
    return this.get(`${this.FLEET_URL}${fleetId}/agents`);
  }

  public deleteFleetAgent(fleetId: number, agentId: string): Observable<IFleetAgent> {
    return this.delete(`${this.FLEET_URL}${fleetId}/agent/${agentId}`);
  }

  public getConfigForNotLoggedIn(): Observable<{ captchaEnabled: boolean }> {
    return this.get(`${this.FLEET_URL}config/for/not/logged/in`);
  }

  public getFleetCities(params: Partial<IFleetCitiesParams>): Observable<IFleetCitiesData> {
    return this.get(`${this.FLEET_URL}search/city`, params);
  }

  public getFleetConfig(fleetId: number): Observable<IAppConfigParams> {
    return this.get(`${this.FLEET_URL}${fleetId}/config`);
  }

  public updateFleetConfig(fleetId: number, params: IAppConfigParams): Observable<any> {
    return this.put(`${this.FLEET_URL}${fleetId}/config`, params);
  }

  public getFleetsByAreaId(body: string[]): Observable<BusinessZoneParams[]> {
    return this.post(`${this.FLEET_URL}findByArea`, body);
  }

  public updateFleetComeol(fleetId: number, params: Partial<IComeol>): Observable<IComeol> {
    return this.put(`${this.FLEET_URL}${fleetId}/comeol`, params);
  }

  public uploadFleetComeol(params: IComeolUpdateParams): Observable<IOrderValidResponse[]> {
    return this.post(`${this.FLEET_URL}comeol/upload`, params);
  }
}
