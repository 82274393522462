import { Attribute, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment, { Moment } from 'moment';

@Component({
  selector: 'azz-date-popup-control',
  template: `
    <div class="relative">
      <input
        azzOpenOnEnterClick
        class="transparent-input"
        [class]="type === 'timer' ? 'transparent-input_time' : 'transparent-input_date'"
        #dateInput
        [id]="id"
        [name]="id"
        [formControl]="innerControl"
        [min]="range.min"
        [max]="range.max"
        [owlDateTimeTrigger]="datePickerRef"
        [owlDateTime]="datePickerRef"
        readonly="true"
      />
      <div *ngIf="showNowTitle && innerControl.enabled" class="date-picker-now" [owlDateTimeTrigger]="datePickerRef">
        {{ 'PA_CREATE_NOW' | translate }}
      </div>

      <owl-date-time
        #datePickerRef
        [pickerType]="type"
        [firstDayOfWeek]="1"
        (afterPickerClosed)="onAfterPickerClosed(dateInput)"
      />
    </div>
  `,
  styleUrls: ['../../nouvelle-commande.component.less'],

  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DatePopupControlComponent, multi: true }],
})
export class DatePopupControlComponent implements OnInit, ControlValueAccessor {
  @Input() range: { min: Moment; max: Moment } = { min: moment().startOf('day'), max: null };
  @Input() label: string;
  @Input() type: 'calendar' | 'timer' = 'calendar';
  @Input() showNowTitle = false;
  @Output() dateInPastChange = new EventEmitter<boolean>();

  public readonly id: string = '';
  public readonly innerControl = new FormControl<Moment>(null);

  public onChange: (date: Moment) => void;
  public onTouch: () => void;

  constructor(@Attribute('label') label: string, @Attribute('formControlName') id: string) {
    this.label = label;
    this.id = id;
  }

  ngOnInit(): void {}

  writeValue(selectedDate: Moment): void {
    this.innerControl.setValue(selectedDate);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.innerControl?.disable();
    } else {
      this.innerControl?.enable();
    }
  }

  onAfterPickerClosed(ref?: HTMLInputElement): void {
    this.dateInPastChange.emit(true);
    ref?.focus();
    this.showNowTitle = false;
    this.onChange(this.innerControl.value);
  }
}
