import { ITag } from '@core/models/tag.model';
import { IVehicle } from '@core/models/vehicle.model';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Driver Params] Init');
export const destroy = createAction('[Driver Params] Destroy');
export const getVehicle = createAction('[Driver Params] Get Vehicle');
export const getVehicleSuccess = createAction('[Driver Params] Get Vehicle Success', props<{ vehicle: IVehicle }>());
export const getVehicleError = createAction('[Driver Params] Get Vehicle Error');
export const getTags = createAction('[Driver Params] Get Tags');
export const getTagsSuccess = createAction('[Driver Params] Get Tags Success', props<{ tags: ITag[] }>());
export const getTagsError = createAction('[Driver Params] Get Tags Error');
