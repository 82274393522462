import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, inject, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomControlBase } from '@common/components/forms/custom-control.base';
import { first } from 'rxjs';

import { AssignmentDriverOrderStatus, DriverAssignmentInfo } from './driver-assignment-info';
import { DriverAssignmentService } from './driver-assignment.service';

@Component({
  selector: 'azz-driver-assignment',
  templateUrl: './driver-assignment.component.html',
  styleUrls: ['./driver-assignment.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DriverAssignmentComponent),
      multi: true,
    },
  ],
})
export class DriverAssignmentComponent extends CustomControlBase<number> {
  @Output() public driverBecomeDisabled = new EventEmitter<DriverAssignmentInfo>();

  private readonly driverAssignmentService = inject(DriverAssignmentService);

  protected readonly statusEnum = AssignmentDriverOrderStatus;
  protected readonly isLoading$ = this.driverAssignmentService.isLoading$;
  protected readonly drivers$ = this.driverAssignmentService.drivers$;
  private selectedDriver: DriverAssignmentInfo;

  constructor() {
    super();

    this.drivers$.pipe(takeUntilDestroyed()).subscribe(drivers => {
      const selectedDriverId = this.checkSelectedDriver(drivers);
      if (!selectedDriverId) {
        this.setValue(null);
      }
    });
  }

  protected asDriver(driver: unknown): DriverAssignmentInfo {
    return driver as DriverAssignmentInfo;
  }

  public override setValue(driverId: number | null): void {
    this.value$.next(driverId);

    this.drivers$.pipe(first()).subscribe(drivers => {
      this.checkSelectedDriver(drivers, driverId);
    });

    this.onChange(driverId);
  }

  public override writeValue(value: number): void {
    this.drivers$.pipe(first()).subscribe(drivers => {
      const selectedDriverId = this.checkSelectedDriver(drivers, value);

      super.writeValue(selectedDriverId);
    });
  }

  protected reload(): void {
    this.driverAssignmentService.reload();
  }

  private checkSelectedDriver(drivers: DriverAssignmentInfo[], newDriverId: number = NaN): number | null {
    if (Number.isNaN(newDriverId)) {
      newDriverId = this.value$.value;
    }

    if (!newDriverId) {
      this.selectedDriver = null;
      return null;
    }

    const found = drivers.find(driver => driver.id === newDriverId);
    if (!found || found.disabled) {
      this.driverBecomeDisabled.emit(found || this.selectedDriver);

      this.selectedDriver = null;
      return null;
    }

    this.selectedDriver = found;
    return newDriverId;
  }
}
