<ng-template #resultTemplate let-result="result" let-term="term">
  <div *ngIf="result && result?.poi" class="autocomplete__poi">
    <ngb-highlight [result]="result?.label ? result?.label : result?.poi?.label" [term]="term"></ngb-highlight>
    <img src="assets/images/redesign/poi-pin.svg" alt="geo icon" class="geomark" />
  </div>
  <div *ngIf="result && !result?.poi">
    <ngb-highlight [result]="result?.address + ', ' + result?.city" [term]="term"></ngb-highlight>
  </div>
  <div (click)="notFound()" *ngIf="!result">{{ 'ADDRESS_NOT_FOUND' | translate }}</div>
</ng-template>

<form class="card azz-multiple autocomplete" [formGroup]="form" #formElemRef>
  <div class="d-flex align-items-center">
    <input
      type="text"
      formControlName="searchField"
      class="transparent-input"
      azzFocusOnInputChange
      [id]="ngId"
      [ngbTypeahead]="search"
      [resultTemplate]="resultTemplate"
      [inputFormatter]="formatter"
      (selectItem)="onAddressSelect($event)"
      (blur)="loadingIndicator = false"
    />
    <span *ngIf="form.controls.searchField.value && !loadingIndicator" class="clear-input" (click)="reset()"></span>
    <azz-rotate-indicator7 class="loading-indicator" size="extra-small" newDesign [visible]="loadingIndicator" />
  </div>
  <span class="validation-warning" *ngIf="isDepartureAddressOutsideOfBusinessZone">
    {{ 'DISPATCH_OUTSIDE_BUSINESS_ZONE_WARNING' | translate }}
  </span>
  <div *ngIf="form?.controls?.searchField.touched && form?.controls?.searchField?.errors" class="autocomplete__error">
    <div *ngIf="form?.controls?.searchField.errors?.noSelectedAddress && form?.controls?.searchField?.errors?.pattern">
      {{ 'PA_CHOOSE_ADDRESS_NOT_FROM_LIST' | translate }}
    </div>
    <div *ngIf="form?.controls?.searchField?.errors?.addressesEquality">
      {{ addressEqualityError | translate }}
    </div>
    <div *ngIf="!form?.controls?.searchField?.errors?.pattern">
      {{ 'CORE_INVALID_ADDRESS_VALIDATION_ERROR' | translate }}
    </div>
  </div>
</form>
