<div class="azz-info-blocks-row azz-last orders row pragmatic">
  <div class="col-12">
    <div class="azz-info-block">
      <div class="azz-header">
        {{ (ordersData$ | async)?.totalElements || 0 }} {{ 'ORDERS' | translate | lowercase }}
      </div>
      <g7-orders-filters
        [isG7User]="isG7User$ | async"
        [filterModel]="filterModel$ | async"
        (updateFilters)="updateFilters($event)"
      >
        <button
          *ngIf="isG7User$ | async"
          class="custom-btn custom-btn--orange"
          [disabled]="!(atLeastOneSelected$ | async)"
          (click)="openMultiReturnPopup()"
        >
          {{ 'RENVOI_PARTENAIRE' | translate }}
          <span *ngIf="selectedOrdersLength$ | async as length">({{ length }})</span>
        </button>
        <button
          *ngIf="isG7User$ | async"
          class="custom-btn custom-btn--green"
          [disabled]="isConfirmDisabled$ | async"
          (click)="openMultiValidationPopup()"
        >
          {{ 'CONFIRMED' | translate }}
          <span>({{ selectedOrdersLength$ | async }})</span>
        </button>
        <button
          *ngIf="(ordersData$ | async) && (isG7User$ | async)"
          class="custom-btn custom-btn--orange"
          (click)="showDispatchPopup()"
        >
          {{ 'GO_ORDER_DISPATCH_SAGA' | translate }}
        </button>
        <button class="custom-btn custom-btn--orange" (click)="exportCsv()">
          {{ 'GO_EXPORT_CSV_BUTTON' | translate }}
        </button>
      </g7-orders-filters>
      <azz-loading-block [isLoading]="loading$ | async">
        <azz-g7-orders-table
          [ordersData]="ordersData$ | async"
          [isG7User]="isG7User$ | async"
          [sorting]="sorting$ | async"
          [selectedOrders]="selectedOrders$ | async"
          [atLeastOneSelectedOnPage]="atLeastOneSelectedOnPage$ | async"
          [allSelectedOnPage]="allSelectedOnPage$ | async"
          [ordersAllowedValidation]="ordersAllowedValidation$ | async"
          (updatePagination$)="onUpdatePagination($event)"
          (updateSorting$)="onUpdateSorting($event)"
          (openValidationPopup$)="onOpenValidationPopup($event)"
          (openInvalidatePopup$)="onOpenInvalidatePopup($event)"
          (selectOrder$)="onSelectOrder($event)"
          (selectAllOrders$)="onSelectAllOrders()"
        >
        </azz-g7-orders-table>
      </azz-loading-block>
    </div>
  </div>
</div>
