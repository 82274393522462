import { Injectable } from '@angular/core';
import { getAPIOriginByHost } from '@azz/env/get-api-origin-by-host.function';

/** logic related to url manimulation like adding api prefix or setting request domain */
@Injectable({
  providedIn: 'root',
})
export class URLBuilderService {
  private readonly apiPrefix = 'backend';

  public build(url: string | null): string {
    // if (url.includes('login')) {
    //   debugger;
    // }

    if (!url) {
      return '';
    }

    const prefixedURL = this.wrapByApiPrefix(url); // called in interceptor to add api prefix
    const wrappedURL = this.wrapWithDomainByHost(prefixedURL);
    return wrappedURL;
  }

  public wrapByApiPrefix(url: string): string {
    if (!this.shouldAddAPIPrefix(url)) {
      return url;
    }

    const prefix = url.startsWith('/') ? this.apiPrefix : `${this.apiPrefix}/`;
    return `${prefix}${url}`;
  }

  private wrapWithDomainByHost(url: string): string {
    if (url.includes('flexiblecontactcenter')) {
      return url;
    }

    return `${getAPIOriginByHost().getHost()}/${url}`;
  }

  private shouldAddAPIPrefix(url: string): boolean {
    return !this.hasApiPrefix(url) && !this.hasAssets(url) && !this.hasProtocol(url);
  }

  private shouldHaveExplicitURL(url: string): boolean {
    return url.includes('estimation') && !url.includes('estimation/price');
  }

  private hasAssets(url: string): boolean {
    return url.includes('assets');
  }

  private hasProtocol(url: string): boolean {
    return url.includes('https');
  }

  private hasApiPrefix(url: string): boolean {
    return url.includes(this.apiPrefix);
  }

  private hasEstimatedPrice(url: string): boolean {
    return url.includes('estimation/price');
  }
}
