import { Directive, HostBinding, Input, NgModule } from '@angular/core';
import { DIRECTION } from '@core/constant';

/**
 * adds arrow icon to span tag (up or down depending on input value)
 *
 * @Param Boolean
 */
@Directive({
  selector: '[azzArrow]',
})
export class AzzArrowDirective {
  @Input('azzArrow') arrow = DIRECTION.DESC;

  @HostBinding('class') get arrowClass() {
    if (this.arrow === undefined || this.arrow === null) {
      return 'arrow-up';
    }

    return this.arrow === DIRECTION.DESC ? 'arrow-up' : 'arrow-down';
  }
}

@NgModule({
  declarations: [AzzArrowDirective],
  exports: [AzzArrowDirective],
})
export class AzzArrowDirectiveModule {}
