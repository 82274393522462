import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IUserNotificationsData } from '@core/models/user-notification.model';
import { UserNotificationService } from '@core/services/users/user-notification.service';
import { createEffect, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import * as UserMessagesActions from '../actions/user-messages.actions';

@Injectable()
export class UserMessagesEffects extends EffectsBase {
  private readonly userNotificationService = inject(UserNotificationService);

  public getUserMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMessagesActions.getUserMessages),
      switchMap(() => this.userNotificationService.getNotifications({ message: true })),
      map((messagesData: IUserNotificationsData) => UserMessagesActions.getUserMessagesSuccess({ messagesData })),
      catchError((err: HttpErrorResponse) =>
        this.catchErrorHandler(err, UserMessagesActions.getUserMessagesFailed({ error: err }))
      )
    )
  );

  public readUserMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserMessagesActions.readUserMessage),
      filter(({ messageId }) => Boolean(messageId)),
      switchMap(({ messageId }) => this.userNotificationService.readNotification({ ids: [messageId] })),
      map(() => UserMessagesActions.readUserMessageSuccess(null)),
      tap(() => this.dispatch(UserMessagesActions.getUserMessages())),
      catchError((error: HttpErrorResponse) =>
        this.catchErrorHandler(error, UserMessagesActions.readUserMessageFailed(error))
      )
    )
  );
}
