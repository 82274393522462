import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {
  IAllDriversData,
  IDriverParameters,
  IDriversData,
  IDriversDataParams,
  IGetAllDriversParams,
  IUpdateDriverByDriverPayload,
  IUpdateDriverByFleetPayload,
  IValidateDriverPayload,
} from '../../models/driver.model';
import { IDriverUserInfo } from '../../models/user-info.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class DriverService extends ApiService {
  public passwordValidate = new Subject<any>();
  private readonly DRIVERS_URL = 'user/api/v1/driver/';
  private readonly DRIVERS_URL_STATUS = 'driver-session/api/v1/driversession/';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAllDriversStatuses(params: Partial<IGetAllDriversParams>): Observable<IAllDriversData> {
    return this.get(`${this.DRIVERS_URL_STATUS}allDrivers`, params);
  }

  public getAllDrivers(params: Partial<IGetAllDriversParams>): Observable<IAllDriversData> {
    return this.get(`${this.DRIVERS_URL}fleet/search`, params);
  }

  public getDayOffs(driverId): Observable<any> {
    return this.get(`${this.DRIVERS_URL}${driverId}/dayOffs`);
  }

  public getDriverById(driverId: number): Observable<IDriverUserInfo> {
    return this.get(`${this.DRIVERS_URL}${driverId}`);
  }

  public getDriverParams(driverId): Observable<IDriverParameters> {
    return this.get(`${this.DRIVERS_URL}${driverId}/parameters`);
  }

  public getDevices(): Observable<any> {
    return this.get(`${this.DRIVERS_URL}device`);
  }

  public getFleetDrivers(fleetId: number, params: Partial<IDriversDataParams>): Observable<IDriversData> {
    return this.get(`${this.DRIVERS_URL}fleet/${fleetId}`, params);
  }

  public approveDriver(driverId: number, payload: IValidateDriverPayload): Observable<any> {
    return this.post(`${this.DRIVERS_URL}${driverId}/approve`, payload);
  }

  public blockDriver(driverId: number): Observable<IDriverUserInfo> {
    return this.post(`${this.DRIVERS_URL}${driverId}/block`, {});
  }

  public unblockDriver(driverId: number): Observable<IDriverUserInfo> {
    return this.post(`${this.DRIVERS_URL}${driverId}/unblock`, {});
  }

  public updateDriverRating(driverId, rating: number): Observable<IDriverUserInfo> {
    return this.post(`${this.DRIVERS_URL}${driverId}/rating/${rating}`, {});
  }

  public updateDriverParams(driverData): Observable<any> {
    return this.post(`${this.DRIVERS_URL}${driverData.driverId}/parameters`, driverData);
  }

  public updateDriverByFleet(payload: IUpdateDriverByFleetPayload): Observable<any> {
    return this.put(`${this.DRIVERS_URL}update/byFleet`, payload);
  }

  public updateDriverByDriver(body: IUpdateDriverByDriverPayload): Observable<any> {
    return this.put(`${this.DRIVERS_URL}update/byDriver`, body);
  }

  public updateDriver(driverId: number, body: IDriverUserInfo): Observable<IDriverUserInfo> {
    return this.put(`${this.DRIVERS_URL}${driverId}`, body);
  }
}
