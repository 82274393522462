import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ORDER, OrderType } from '@core/constant';
import { ITag } from '@core/models';
import { defineOrderType } from '@core/modules/nouvelle-commande/components/order-pre-save-dialog/order-type';

@Component({
  selector: 'app-summary-block',
  templateUrl: './summary-block.component.html',
  styleUrls: ['./summary-block.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryBlockComponent implements OnChanges {
  @Input() customerData: any;
  @Input() estimatedTime: string;
  @Input() distanceETA: number;
  @Input() durationETA: number;
  @Input() distanceEstimation: any;
  @Input() durationEstimation: number;
  @Input() tags: ITag[];
  @Input() passenger: any;
  @Input() comment: any;
  @Input() estimatedPrice: any;
  @Input() estimatedPriceLoading: boolean;
  @Input() isEstimatedPriceShown: boolean;
  @Input() isEtaLoading: boolean;
  @Input() noRoute: boolean;
  @Input() orderDate: string;
  @Input() orderTime: string;

  public orderType: OrderType;
  public readonly immediateOrder = ORDER.ORDER;

  ngOnChanges(): void {
    this.orderType = defineOrderType({ time: this.orderTime, date: this.orderDate });
  }
}
