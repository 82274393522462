import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as PendingRequestsReducer from '../reducers/pending-requests.reducer';

export const selectPendingRequests = createFeatureSelector<PendingRequestsReducer.State>(
  PendingRequestsReducer.pendingRequestsFeatureKey
);

export const selectPendingRequestsCount = createSelector(
  selectPendingRequests,
  (state: PendingRequestsReducer.State) => state.count
);
