import { createAction, props } from '@ngrx/store';

export const toggleFullWidth = createAction('[App] Toggle Full Width for Schedule', props<{ isFullWidth?: boolean }>());

export const setFocusTimeForSchedule = createAction(
  '[App] Set Focus Time For Schedule',
  props<{
    focusTime: string;
  }>()
);

export const setLoadingStateForSchedule = createAction(
  '[Planning Module, Schedule] Set Loading State for Schedule',
  props<{ isSchedulerLoading: boolean }>()
);
