/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * one passenger data in order details response
 */
export interface OrderPassengerItemResponseWebApiModel {
  /**
   * passenger name
   */
  firstName: string;
  /**
   * passenger last name
   */
  lastName?: string;
  /**
   * passenger phone
   */
  phone?: string;
}
