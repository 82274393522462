import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BaseCreateOrderComponent } from './base-create-order.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '', component: BaseCreateOrderComponent, pathMatch: 'full' },
      { path: ':orderId', component: BaseCreateOrderComponent },
    ]),
  ],
})
export class BaseCreateOrderRoutingModule {}
