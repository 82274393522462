<footer [attr.class]="className">
  <ul class="footer-list mb-0 pl-0" *ngIf="prefix$ | async as prefix">
    <li class="list-none footer-item">
      <span>{{ 'VERSION' | translate }} {{ versionDate | date: 'dd/MM/yyyy' }}</span>
    </li>
    <li class="list-none footer-item">
      <a [routerLink]="[prefix, 'public', 'discover']" class="footer-link" data-test-id="footer-link">{{
        'DISCOVER' | translate
      }}</a>
    </li>
    <li class="list-none footer-item">
      <a [routerLink]="[prefix, 'public', 'contact']" class="footer-link" data-test-id="footer-link">{{
        'CONTACT' | translate
      }}</a>
    </li>
    <li class="list-none footer-item">
      <a [routerLink]="[prefix, 'public', 'legal']" class="footer-link" data-test-id="footer-link">{{
        'LEGAL_NOTICE' | translate
      }}</a>
    </li>
    <li class="list-none footer-item">
      <a
        href="https://rn7-prod-g7.gitbook.io/rn7"
        target="_blank"
        [attr.aria-label]="'ARIA_LABEL_BTN_OPEN_IN_NEW_TAB' | translate"
        class="footer-link"
        data-test-id="footer-link"
        >{{ 'CGV' | translate }}</a
      >
    </li>
    <li class="list-none footer-item">
      <a
        data-test-id="footer-link"
        [routerLink]="[prefix, 'public', 'agreement']"
        target="_blank"
        [attr.aria-label]="'ARIA_LABEL_BTN_OPEN_IN_NEW_TAB' | translate"
        class="footer-link"
        >{{ 'USER_AGREEMENT' | translate }}</a
      >
    </li>
    <li class="list-none footer-item">
      <a
        href="https://www.g7.fr/"
        target="_blank"
        [attr.aria-label]="'ARIA_LABEL_BTN_REDIRECT_G7' | translate"
        data-test-id="footer-link"
      >
        <azz-icon-logo-G7 />
      </a>
    </li>
  </ul>
</footer>
