import { userRole } from '@auth/store/selectors';
import { RoleName } from '@core/constant/role.constant';
import { createSelector } from '@ngrx/store';

export const phoneCallButtonSelector = createSelector(userRole, (role: RoleName[]): boolean =>
  role
    ? [
        'ROLE_PHONE_ADVISOR',
        'ROLE_PHONE_ADVISOR_INACTIVE',
        'ROLE_PHONE_ADVISOR_DEACTIVATED',
        'ROLE_DISPATCHER',
        'ROLE_DISPATCHER_INACTIVE',
        'ROLE_DISPATCHER_INCOMPLETE',
        'ROLE_DISPATCHER_DEACTIVATED',
      ].some(roleItem => role.includes(roleItem as RoleName))
    : false
);
