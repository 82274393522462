<section class="modal-notification">
  <p class="modal-notification__message">{{ 'GO_ORDER_DENY_VALIDATION_DIALOG' | translate }}</p>
  <p>
    <span>Nº {{ order?.tripNumber }}</span>
    <span *ngIf="order?.tripCategory">-{{ order?.tripCategory }}</span>
    <span> du {{ order?.dateMillis | date: 'dd.MM.yyyy' : 'UTC' }}</span>
  </p>
  <textarea
    id="message-to-fleet"
    [(ngModel)]="message"
    name="message_to_fleet"
    class="form-control azz-transparent-input azz-textarea azz-validate"
  >
  </textarea>
  <div class="modal-notification__btn-group">
    <button type="button" class="custom-btn custom-btn--grey mr-3" (click)="close()">
      {{ 'CANCEL' | translate | titlecase }}
    </button>
    <button type="button" class="custom-btn custom-btn--green" [disabled]="!message" (click)="submit(message)">
      {{ 'CORE_OK_VALUE' | translate }}
    </button>
  </div>
</section>
