import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AzzButtonDropdownModule,
  AzzPhoneInputModule,
  AzzRotateIndicatorModule,
  LoadingBlockModule,
} from '@common/components';
import { DynamicValidatorModule } from '@common/directives';
import { PhoneComponent } from '@controls/phone/phone.component';
import { TranslateModule } from '@ngx-translate/core';

import { AzzCustomerDetailsComponent } from './azz-customer-details/azz-customer-details.component';
import { AzzCustomerModificationHistoryComponent } from './azz-customer-modification-history/azz-customer-modification-history.component';
import { AzzCustomerOrdersComponent } from './azz-customer-orders/azz-customer-orders.component';
import { CustomersComponent } from './customers.component';
import { CustomersFiltersComponent } from './filters/customers-filters.component';
import { CustomersStoreModule } from './store/customers-store.module';
import { CustomersTableComponent } from './table/customers-table.component';
import { CustomDialogModule } from '../custom-dialog/custom-dialog.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';

@NgModule({
  imports: [
    AzzButtonDropdownModule,
    AzzPhoneInputModule,
    AzzRotateIndicatorModule,
    CommonModule,
    CustomDialogModule,
    CustomersStoreModule,
    DynamicValidatorModule,
    FormsModule,
    LoadingBlockModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    TableNoMatchesModule,
    TranslateModule,
    PhoneComponent,
  ],
  declarations: [
    CustomersComponent,
    CustomersTableComponent,
    CustomersFiltersComponent,
    AzzCustomerDetailsComponent,
    AzzCustomerOrdersComponent,
    AzzCustomerModificationHistoryComponent,
  ],
  exports: [CustomersComponent, AzzCustomerDetailsComponent],
})
export class CustomersModule {}
