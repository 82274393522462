import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE } from '@core/constant/poi.constant';
import { EtalabFeatureCollection } from '@core/models/geo-json.model';
import { UtilService } from '@core/services/common';
import { AddressesService, IFindAddressParams } from '@core/services/common/addresses.service';
import { FormService } from '@core/utils/form-service';
import { Feature } from 'geojson';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CustomAutocompleteComponent } from '../azz-custom-autocomplete.component';

@Component({
  selector: 'azz-search-address-poi-autocomplete',
  templateUrl: './search-address-poi-autocomplete.component.html',
  styleUrls: ['./search-address-poi-autocomplete.component.less'],
  providers: [NgOnDestroyService],
})
export class SearchAddressPoiAutocompleteComponent extends CustomAutocompleteComponent implements OnChanges {
  @Input() withPoi: boolean;
  @Input() vehicleType: string;
  @Input() displayInBillingPortal: boolean;
  @Input() fleetG7Id: number;
  @ViewChild('formElemRef') public declare formElemRef: ElementRef;
  @ViewChild('searchField') public searchField: ElementRef;

  constructor(
    addressesService: AddressesService,
    formService: FormService,
    destroyed$: NgOnDestroyService,
    private readonly utilService: UtilService
  ) {
    super(addressesService, formService, destroyed$);
  }

  public override search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.REQUEST_DELAY_MS),
      distinctUntilChanged(),
      tap(() => this.enableLoadingIndicator(true)),
      switchMap((searchValue: string) => {
        const params = {
          limit: 10,
          q: searchValue,
          withPoi: this.withPoi,
          serviceLevel: this.vehicleType ? this.convertVehicleTypeToPoiServiceLevelArray() : null,
          fleetG7Id: this.fleetG7Id || null,
          displayInBillingPortal: this.displayInBillingPortal || null,
        };
        this.typing.emit();
        this.resetAddressValue();
        return this.findAddress(params);
      }),
      tap(() => this.enableLoadingIndicator(false)),
      takeUntil(this.destroyed$)
    );

  public override findAddress(params: IFindAddressParams): Observable<Feature[]> {
    return params.q
      ? this.addressesService.findAddress(params).pipe(
          filter((response: EtalabFeatureCollection) => !!response && !!response.features),
          map((response: EtalabFeatureCollection) =>
            response.features.length && response.features.length > 0 ? response.features : [null]
          ),
          catchError(() => EMPTY),
          // catchError(() => of([null])), CHOOSE APPROPRIATE VARIANT HOW TO HANDLE NETWORK ERROR
          takeUntil(this.destroyed$)
        )
      : of([]);
  }

  public override formatter = (address: any) => {
    if (address.properties) {
      return address.properties.type === 'poi'
        ? address.properties.label
        : address.properties.name
          ? `${address.properties.name}${address.properties.city ? ',' : ''} ${address.properties.city}`
          : this.utilService.tagRemoveFalsyValueFunc`${address.properties.number}${
              address.properties.adverbeMultiplicatif
            } ${address.properties.typeVoie} ${address.properties.street}${address.properties.city ? ',' : ''} ${
              address.properties.postalCode
            } ${address.properties.city}`;
    } else {
      return address;
    }
  };

  public convertVehicleTypeToPoiServiceLevelArray(): string[] {
    return POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE[this.vehicleType] &&
      typeof POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE[this.vehicleType] === 'string'
      ? POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE[this.vehicleType].split(',')
      : [''];
  }
}
