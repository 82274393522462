/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * POI service level enum. Note that v1 used there both single and united types like BUSINESS_AND_LUX which is a combination of BUSINESS and LUX types there. It\'s better to use only one of this enum values if object can contain only one such type, and use array of enums when there could be few values passes, for example in search params.
 */
export type PoiServiceLevelEnumWebApiModel = 'STANDARD' | 'BUSINESS' | 'LUX';

export const PoiServiceLevelEnumWebApiModel = {
  Standard: 'STANDARD' as PoiServiceLevelEnumWebApiModel,
  Business: 'BUSINESS' as PoiServiceLevelEnumWebApiModel,
  Lux: 'LUX' as PoiServiceLevelEnumWebApiModel,
};
