import { Component, OnInit } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IBeforeDispatchOrdersData } from '../../../../models/g7-order.model';
import { G7OrdersStore } from '../../store';

@Component({
  selector: 'azz-dispatch-saga7-dialog',
  templateUrl: './before-dispatch-popup.component.html',
  styleUrls: ['./before-dispatch-popup.component.less'],
  providers: [NgOnDestroyService],
})
export class BeforeDispatchPopupComponent implements OnInit {
  public tableLoading$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public dispatchOrdersData: IBeforeDispatchOrdersData;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly store: Store,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  ngOnInit() {
    this.store
      .pipe(select(G7OrdersStore.selectDispatchOrdersData), takeUntil(this.destroyed$))
      .subscribe((res: IBeforeDispatchOrdersData) => (this.dispatchOrdersData = res));
    this.tableLoading$ = this.store.pipe(select(G7OrdersStore.selectDispatchTableLoading));
    this.loading$ = this.store.pipe(select(G7OrdersStore.selectDispatchLoading));
  }

  public close(): void {
    this.modal.close();
  }

  public dispatch(): void {
    this.store.dispatch(G7OrdersStore.dispatchG7Orders({ popup: this.modal }));
  }

  public prevPage(): void {
    this.store.dispatch(
      G7OrdersStore.updateDispatchPagination({
        pagination: { page: this.getCurrentHistoryPage() - 1 },
      })
    );
  }

  public nextPage(): void {
    this.store.dispatch(
      G7OrdersStore.updateDispatchPagination({
        pagination: { page: this.getCurrentHistoryPage() + 1 },
      })
    );
  }

  public getCurrentHistoryPage(): number {
    return this.dispatchOrdersData?.number || 0;
  }

  public isPrevBtnDisabled(): boolean {
    return !this.dispatchOrdersData || this.dispatchOrdersData.first;
  }

  public isNextBtnDisabled(): boolean {
    return !this.dispatchOrdersData || this.dispatchOrdersData.last;
  }
}
