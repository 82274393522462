/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * object with customer info used in order info responses
 */
export interface OrderCustomerResponseWebApiModel {
  /**
   * customer ID
   */
  customerId: string;
  /**
   * customer first name
   */
  firstName: string;
  /**
   * customer last name
   */
  lastName: string;
  /**
   * customer phone number specified for this order
   */
  phone: string;
}
