import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'azz-driver-circle7',
  templateUrl: './azz-driver-circle.component.html',
  styleUrls: ['./azz-driver-circle.component.less'],
})
export class AzzDriverCircleComponent {
  @Input() driver: any;
  @Input() userId: any;
  @Input() navigateUrl: any;

  constructor(private readonly router: Router) {}

  public getDriverInitials(): string {
    let result = '';
    if (this.driver) {
      const firstName = this.driver.firstName;
      const lastName = this.driver.lastName;
      if (firstName) {
        result += firstName[0];
      }
      if (lastName) {
        result += lastName[0];
      }
    }
    return result.toUpperCase();
  }

  public showDriverDetails(): void {
    if (this.navigateUrl) {
      this.router.navigate([this.navigateUrl, this.driver.id]);
    }
  }
}
