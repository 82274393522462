<div class="form-group azz-last">
  <div class="azz-multiple" ngbDropdown autoClose="outside">
    <label for="display-value" class="azz-transparent-input-label">
      {{ azzLabel }}<span *ngIf="ngRequired">*</span>
    </label>
    <input
      id="display-value"
      type="text"
      name="display_value"
      class="form-control azz-transparent-input azz-validate azz-expandable"
      [placeholder]="azzPlaceholder"
      [(ngModel)]="displayValue"
      ngbDropdownToggle
      readonly
      [required]="ngRequired"
      [disabled]="ngDisabled"
    />
    <div class="azz-dropdown-menu-wrapper" ngbDropdownMenu>
      <ul class="list-unstyled azz-multiple-list" *ngIf="azzMode === 'float'">
        <!-- Used ng-hide instead of filter to avoid the problem with closing the dropdown window -->
        <li *ngFor="let option of optionsData?.content; let i = index" [hidden]="!option?.selected">
          <div class="azz-checkbox-wrapper">
            <input
              [id]="azzId + '-top-checkbox-' + i"
              type="checkbox"
              class="azz-no-indicator"
              [name]="azzId + '-top-checkbox-' + i"
              [(ngModel)]="option.selected"
              (ngModelChange)="onOptionClick()"
            />
            <label [for]="azzId + '-top-checkbox-' + i" class="azz-label">
              <span *ngFor="let field of optionFormatFields">{{ option[field] }}</span>
            </label>
          </div>
        </li>
      </ul>
      <div class="azz-search-block azz-has-addon" *ngIf="!hideSearch">
        <div class="azz-search-addon"></div>
        <input
          type="text"
          name="search_query"
          [placeholder]="azzSearchPlaceholder || 'SEARCH_BY_NAME' | translate"
          class="form-control azz-transparent-input azz-search-field"
          [(ngModel)]="filter.query"
        />
      </div>
      <div class="azz-search-result-header" (click)="changeFilterDirection()" *ngIf="!hideAllCheckbox">
        {{ 'LAST_NAME' | translate }}
        <span
          class="arrow"
          [ngClass]="{
            'arrow-up': filter.direction === 'asc',
            'arrow-down': filter.direction === 'desc'
          }"
        >
        </span>
      </div>
      <ul class="list-unstyled azz-multiple-list" *ngIf="!loadingIndicator">
        <li *ngIf="!filter.query && !hideAllCheckbox">
          <div class="azz-checkbox-wrapper">
            <input
              [id]="azzId + '-bottom-checkbox-all'"
              type="checkbox"
              class="azz-no-indicator"
              [name]="azzId + '-bottom-checkbox-all'"
              [checked]="isCheckedAll()"
              [(ngModel)]="filter.selectedAll"
              (click)="toggleAllOptions()"
            />
            <label [for]="azzId + '-bottom-checkbox-all'" class="azz-label">
              {{ azzAllOptionsLabel }}
            </label>
          </div>
        </li>
        <!-- Used ng-hide instead of filter to avoid the problem with closing the dropdown window -->
        <li
          *ngFor="let option of optionsData?.content; let i = index"
          [hidden]="azzMode === 'float' && option?.selected"
        >
          <div class="azz-checkbox-wrapper">
            <input
              [id]="azzId + '-bottom-checkbox-' + i"
              type="checkbox"
              class="azz-no-indicator"
              [name]="azzId + '-bottom-checkbox-' + i"
              [(ngModel)]="option.selected"
              (ngModelChange)="onOptionClick()"
            />
            <label [for]="azzId + '-bottom-checkbox-' + i" class="azz-label">
              <span *ngFor="let field of optionFormatFields">{{ option[field] }}</span>
            </label>
          </div>
        </li>
      </ul>
      <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
      <azz-pagination
        *ngIf="isPaginationVisible()"
        (goToPrevPage)="prevPage()"
        (goToNextPage)="nextPage()"
        [prevDisabled]="!prevPageEnabled()"
        [nextDisabled]="!nextPageEnabled()"
      >
      </azz-pagination>
    </div>
  </div>
</div>
