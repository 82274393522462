import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IStringParameters } from '@core/models/common.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPopupComponent {
  @Input() header: string;
  @Input() message: string;
  @Input() closeBtn = 'CANCEL';
  @Input() submitBtn = 'CORE_OK_VALUE';
  @Input() hasCancelButton = true;
  @Input() isButtonsCentered = false;

  constructor(
    protected modal: NgbActiveModal,
    private readonly translate: TranslateService
  ) {}

  public close(): void {
    this.modal.dismiss();
  }

  public processTextParameter(param: IStringParameters | string = ''): string {
    const defaultValue = '';

    if (param) {
      if (typeof param === 'string') {
        return this.translate.instant(param);
      }

      const { message } = param;

      if (!message) {
        return defaultValue;
      }

      delete param.message;

      return this.translate.instant(message, param);
    }

    return defaultValue;
  }

  public submit(): void {
    this.modal.close('submit');
  }
}
