import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as UserMessagesReducer from '../reducers/user-messages.reducer';

const appUserMessages = createFeatureSelector<UserMessagesReducer.State>(UserMessagesReducer.userMessagesFeatureKey);

export const userMessagesData = createSelector(
  appUserMessages,
  (state: UserMessagesReducer.State) => state?.messagesData
);
