import { Pipe, PipeTransform } from '@angular/core';
import { round } from '@core/utils/util';

@Pipe({
  name: 'azzMetersToKilometers',
})
export class AzzMetersToKilometersPipe implements PipeTransform {
  transform(meters: number, ...args: any[]) {
    return this.makeMetersIntoUIFormat(meters);
  }

  private makeMetersIntoUIFormat(meters: number): string {
    const kilometers = round(meters / 1000, 1);

    if (!kilometers || kilometers < 1) {
      return '< 1 km';
    }

    return `${kilometers} km`;
  }
}
