import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IObjectMap } from '@core/models/common.model';
import { FRENCH_PHONE_CODE } from '@dash/modules/phone-advisor/common/constants';
import { isEmpty } from 'lodash-es';

export interface PhoneNumberGroupValidatorParams {
  phoneCode: string;
  phoneNumber: string;
  minLength: number;
  maxLength: number;
}

export const phoneNumbersGroupValidator =
  (
    { phoneCode, phoneNumber, minLength, maxLength }: Partial<PhoneNumberGroupValidatorParams> = {
      phoneCode: 'phoneCode',
      phoneNumber: 'phoneNumber',
      minLength: 9,
      maxLength: 10,
    }
  ): ValidatorFn =>
  (group: AbstractControl): ValidationErrors => {
    phoneCode = phoneCode ?? 'phoneCode';
    phoneNumber = phoneNumber ?? 'phoneNumber';
    maxLength = maxLength ?? 10;
    const phoneCodeControl = group.get(phoneCode);
    const phoneNumberControl = group.get(phoneNumber);
    const phoneCodeValue = phoneCodeControl?.value;
    const phoneNumberValue = phoneNumberControl?.value;
    const validErrors: IObjectMap<boolean> = {};

    if (phoneCodeValue !== FRENCH_PHONE_CODE || !phoneNumberValue) {
      return null;
    }

    const rawPhoneNumber = phoneNumberValue?.slice(phoneCodeValue.length).length;
    if (minLength && rawPhoneNumber < minLength) {
      validErrors.minlength = true;
    }

    if (rawPhoneNumber > maxLength) {
      validErrors.maxlength = true;
    }

    return isEmpty(validErrors) ? null : validErrors;
  };
