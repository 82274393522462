import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AzzArrowDirectiveModule } from '@common/directives/arrow.directive';
import { NgOnDestroyService } from '../../../common/services/ng-on-destroy.service';
import { DIRECTION } from '../../constant/direction.enum';
import { RoleName } from '../../constant/role.constant';
import { IAllDriversData, IDriver, ISessionData } from '../../models/driver.model';
import { IDriverSession, IGetDriverSessionsDataParams } from '../../models/session.model';
import { PaginationService } from '../../services/common/pagination.service';
import { SortingHelperService } from '../../services/common/sorting.helper.service';
import { UtilService } from '../../services/common/util.service';
import { SessionService } from '../../services/sessions/session.service';
import { DriverService } from '../../services/users/driver.service';
import { UserService } from '../../services/users/user.service';

interface IFilterData {
  startDate: moment.Moment;
  endDate: moment.Moment;
  driver: IDriver;
  direction: DIRECTION;
}

@Component({
  selector: 'azz-sessions-chat',
  templateUrl: './sessions-chat.component.html',
  styleUrls: ['./sessions-chat.component.less'],
  viewProviders: [AzzArrowDirectiveModule],
})
export class SessionsChatComponent implements OnInit {
  public REQUEST_DELAY_MS = 300;
  public filterData: IFilterData;
  public sessionsData: ISessionData;
  public loadingIndicator: boolean;
  public pageHistory: any;
  public sortingHelper: any;
  public maxDate = new Date();
  public isSortingByDriverAvailable = true;

  private readonly watcher$ = new Subject<{ pageToken?: string; cb?: () => void; increaseCount?: boolean }>();
  private fleetId: number;
  private userRole: RoleName;

  constructor(
    private readonly translate: TranslateService,
    private readonly sessionService: SessionService,
    private readonly paginationService: PaginationService,
    protected userService: UserService,
    private readonly sortingHelperService: SortingHelperService,
    private readonly utilService: UtilService,
    private readonly driverService: DriverService,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initData();
  }

  ngOnInit() {
    this.initWatcher();
  }

  public onDriverSelect(driver: IDriver): void {
    this.filterData.driver = driver;
    this.changeFilter();
  }

  public onSelectStartDate(): void {
    this.enableSortingByDriver(false);
    this.sortingHelper.sortColumns(['startTime']);
    if (this.filterData.startDate) {
      this.filterData.endDate = null;
    } else {
      this.enableSortingByDriver(true);
    }
    this.changeFilter();
  }

  public onSelectEndDate(): void {
    this.enableSortingByDriver(false);
    this.sortingHelper.sortColumns(['finishTime']);
    if (this.filterData.endDate) {
      this.filterData.startDate = null;
    } else {
      this.enableSortingByDriver(true);
    }
    this.changeFilter();
  }

  public onClearStartDate(): void {
    this.filterData.startDate = null;
    this.enableSortingByDriver(true);
    this.changeFilter();
  }

  public onClearEndDate(): void {
    this.filterData.endDate = null;
    this.enableSortingByDriver(true);
    this.changeFilter();
  }

  public filterDateStart = (d: moment.Moment): boolean => this.utilService.filterDateStart(d, this.filterData.endDate);

  public filterDateEnd = (d: moment.Moment): boolean => this.utilService.filterDateEnd(d, this.filterData.startDate);

  public nextPage(): void {
    const pageToken = this.sessionsData.nextPageToken;
    const cb = () => this.pageHistory.push(pageToken);
    this.watcher$.next({ pageToken, cb, increaseCount: true });
  }

  public prevPage(): void {
    const pageToken = this.pageHistory.getPrevious();
    const cb = () => this.pageHistory.pop();
    this.watcher$.next({ pageToken, cb });
  }

  public isNextDisabled(): boolean {
    return !this.sessionsData || !this.sessionsData.nextPageToken;
  }

  public isPrevDisabled(): boolean {
    return this.pageHistory.isEmpty();
  }

  public loadDriversOuterContext(): (
    query: string,
    direction: string,
    pageNumber: number
  ) => Observable<IAllDriversData> {
    return (query, direction, pageNumber) => this.loadDrivers(query, direction, pageNumber);
  }

  public trackByFunc(index: number, item: IDriverSession): number {
    if (!item) {
      return null;
    }
    return item.id;
  }

  private initData(): void {
    this.filterData = {
      startDate: null,
      endDate: null,
      driver: null,
      direction: null,
    };
    this.pageHistory = this.paginationService.createPageHistory();
    this.sortingHelper = this.sortingHelperService.createSortingHelper(DIRECTION.DESC, ['startTime'], () => {
      this.pageHistory.resetAll();
      this.watcher$.next({ increaseCount: true });
    });
    this.fleetId = this.userService.getCurrentUserInfo()?.fleet?.id;
    this.userRole = this.userService.getCurrentUserInfo()?.user?.roles[0];
    this.sessionService.setUrl(this.userRole);
  }

  private initWatcher(): void {
    let pageToken;
    let cb;
    let increaseCount;

    this.watcher$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(this.REQUEST_DELAY_MS),
        // @ts-ignore
        tap(() => this.enableLoadingIndicator(true)),
        switchMap((data: { pageToken: string; cb: () => void; increaseCount?: boolean }) => {
          pageToken = data && data.pageToken ? data.pageToken : null;
          cb = data && data.cb ? data.cb : null;
          increaseCount = data && data.increaseCount ? data.increaseCount : null;
          return this.loadDriverSessions$(pageToken);
        }),
        tap(() => this.enableLoadingIndicator(false))
      )
      .subscribe((response: ISessionData) => {
        this.sessionsData = response;
        this.pageHistory.addItemsCountByToken({
          pageToken,
          itemsCount: response.data ? response.data.length : 0,
          increaseCount,
        });
        if (cb) {
          cb();
        }
      });
    this.watcher$.next({ increaseCount: true });
  }

  private loadDriverSessions$(pageToken?: string): Observable<any> {
    return this.sessionService.getDriverSessions(this.generateParams(pageToken)).pipe(
      takeUntil(this.destroyed$),
      catchError(() => {
        this.enableLoadingIndicator(false);
        return EMPTY;
      })
    );
  }

  private generateParams(pageToken?: string): Partial<IGetDriverSessionsDataParams> {
    return {
      driverId: this.filterData.driver ? this.filterData.driver.id : null,
      startDate: this.filterData.startDate ? this.convertDate(this.filterData.startDate) : null,
      finishDate: this.filterData.endDate ? this.convertDate(this.filterData.endDate) : null,
      finished: true,
      sort: this.sortingHelper.getColumnNamesString(),
      direction: this.sortingHelper.getDirection(),
      limit: 15,
      pageToken,
    };
  }

  private loadDrivers(query, direction, pageNumber): Observable<IAllDriversData> {
    const sort = ['firstName,' + (direction || DIRECTION.ASC), 'lastName,' + (direction || DIRECTION.ASC)];
    const params = {
      fleetId: this.fleetId,
      pageNumber,
      pageSize: 10,
      name: query,
      sort,
    };
    return this.driverService.getAllDrivers(params).pipe(takeUntil(this.destroyed$));
  }

  private changeFilter(): void {
    this.pageHistory.resetAll();
    this.watcher$.next({ increaseCount: true });
  }

  private convertDate(date: moment.Moment) {
    const offset = moment(date).utcOffset();
    return moment(date).utc().add(offset, 'm').valueOf();
  }

  private enableSortingByDriver(value: boolean) {
    this.isSortingByDriverAvailable = value;
  }

  private enableLoadingIndicator(enabled: boolean): void {
    this.loadingIndicator = enabled;
  }
}
