/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { AddressSuggestResponseWebApiModel } from '../model/address-suggest-response-web.api-model';
// @ts-ignore
import { CoordinatesResponseWebApiModel } from '../model/coordinates-response-web.api-model';
// @ts-ignore
import { ErrorResponseWebApiModel } from '../model/error-response-web.api-model';
// @ts-ignore
import { PoiServiceLevelEnumWebApiModel } from '../model/poi-service-level-enum-web.api-model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { PragmaticConfiguration } from '../configuration';

export interface WebAddressCoordinatesRequestParams {
  /** full address string if specified */
  address?: string;
  /** poiId if specified */
  poiId?: string;
  /** serviceLevel for POI validation if specified */
  serviceLevel?: PoiServiceLevelEnumWebApiModel;
  /** if true, response will find business and dispatch zone for found coordinates */
  requestZones?: boolean;
}

export interface WebAddressSuggestRequestParams {
  /** query string */
  q?: string;
  /** if POI should be returned in response or not. True by default */
  withPoi?: boolean;
  /** service level for POIs in response. By default no filtering by POI service level. */
  serviceLevel?: PoiServiceLevelEnumWebApiModel;
  /** page number if specified */
  pageIndex?: number;
  /** max number of returned objects */
  pageSize?: number;
}

@Injectable()
export class AddressApiClient {
  protected basePath = 'https://develop-proxy-rn7.g7.fr/backend/web2';
  public defaultHeaders = new HttpHeaders();
  public configuration = new PragmaticConfiguration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: PragmaticConfiguration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Search address info (stub)
   * A method for web to find full address info by address string or poi ID
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webAddressCoordinates(
    requestParameters?: WebAddressCoordinatesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<CoordinatesResponseWebApiModel>;
  public webAddressCoordinates(
    requestParameters?: WebAddressCoordinatesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<CoordinatesResponseWebApiModel>>;
  public webAddressCoordinates(
    requestParameters?: WebAddressCoordinatesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<CoordinatesResponseWebApiModel>>;
  public webAddressCoordinates(
    requestParameters?: WebAddressCoordinatesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const address = requestParameters?.address;
    const poiId = requestParameters?.poiId;
    const serviceLevel = requestParameters?.serviceLevel;
    const requestZones = requestParameters?.requestZones;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (address !== undefined && address !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>address, 'address');
    }
    if (poiId !== undefined && poiId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>poiId, 'poiId');
    }
    if (serviceLevel !== undefined && serviceLevel !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>serviceLevel, 'serviceLevel');
    }
    if (requestZones !== undefined && requestZones !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>requestZones, 'requestZones');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/addresses/coordinates`;
    return this.httpClient.request<CoordinatesResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Search address suggestions by query (stub)
   * A method for web to find address suggestions by partial search query
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public webAddressSuggest(
    requestParameters?: WebAddressSuggestRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<AddressSuggestResponseWebApiModel>;
  public webAddressSuggest(
    requestParameters?: WebAddressSuggestRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<AddressSuggestResponseWebApiModel>>;
  public webAddressSuggest(
    requestParameters?: WebAddressSuggestRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<AddressSuggestResponseWebApiModel>>;
  public webAddressSuggest(
    requestParameters?: WebAddressSuggestRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const q = requestParameters?.q;
    const withPoi = requestParameters?.withPoi;
    const serviceLevel = requestParameters?.serviceLevel;
    const pageIndex = requestParameters?.pageIndex;
    const pageSize = requestParameters?.pageSize;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (q !== undefined && q !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>q, 'q');
    }
    if (withPoi !== undefined && withPoi !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>withPoi, 'withPoi');
    }
    if (serviceLevel !== undefined && serviceLevel !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>serviceLevel, 'serviceLevel');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageIndex, 'pageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'pageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearerToken) required
    localVarCredential = this.configuration.lookupCredential('bearerToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v2/addresses/suggest`;
    return this.httpClient.request<AddressSuggestResponseWebApiModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }
}
