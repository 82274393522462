<ng-template #content let-modal>
  <div class="azz-popup import-poi">
    <h2 class="import-poi__header">{{ header | translate }}</h2>
    <div class="import-poi__upload-group">
      <h4 class="import-poi__description-text">
        {{ 'POI_IMPORT_DIALOG_DESCRIPTION_TEXT' | translate }}
      </h4>
      <ul class="import-poi__list">
        <li class="import-poi__list-item">
          1. {{ 'DIALOG_DOWNLOAD_TEXT' | translate }}
          <a [attr.href]="downloadUrl" class="azz-font-bold import-poi__model-link" target="_blank">
            {{ 'DIALOG_MODEL_LINK' | translate }} </a
          >.
        </li>
        <li class="import-poi__list-item">2. {{ 'DIALOG_FILL_AS_EXAMPLE' | translate }}</li>
        <li class="import-poi__list-item">3. {{ 'DIALOG_UPLOAD_FILE_TEXT' | translate }}</li>
      </ul>

      <azz-attach-file
        azzId="attach-csv-poi"
        azzAccept=".csv"
        [azzAcceptMimeType]="csvMimeType"
        [acceptedExtensionsArray]="['csv']"
        [value]="attachedFile"
        [typeOrExtensionErrorStr]="'CSV_FILE_TYPE_EXTENSION_INCORRECT' | translate"
        (updateFile)="onFileUpdate($event)"
      >
      </azz-attach-file>
    </div>
    <div class="import-poi__btn-group">
      <button type="button" class="import-poi__btn import-poi__btn--cancel" (click)="onClose()">
        {{ 'CANCEL' | translate | titlecase }}
      </button>
      <button
        type="button"
        class="btn-rotate-flex import-poi__btn import-poi__btn--import"
        [disabled]="!attachedFile"
        (click)="importPoi()"
      >
        <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
        {{ 'POI_IMPORT_DIALOG_SAVE_BUTTON' | translate }}
      </button>
    </div>
  </div>
</ng-template>
