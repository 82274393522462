import { AuthStore } from '@auth/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IPaginationState, ISortingState } from '../../../../models/common.model';
import { ICustomersDataParams } from '../../../../models/customer.model';
import { sortNumber } from '../../../../utils/util';
import * as CustomersReducer from '../reducers';

const generatePagination = (pag: IPaginationState): Partial<ICustomersDataParams> => ({
  pageNumber: pag.page,
  pageSize: pag.size,
});
const generateSorting = (sortState: ISortingState): Partial<ICustomersDataParams> => ({
  sort: (sortState.sort as string[]).map(v => `${v},${sortState.direction}`),
});

const customers = createFeatureSelector<CustomersReducer.State>(CustomersReducer.customersFeatureKey);
export const customersData = createSelector(customers, state =>
  state?.customersData
    ? {
        ...state?.customersData,
        content: state?.customersData.content.map(customer => ({
          ...customer,
          phones: customer?.phones?.slice()?.sort(sortNumber('orderNumber')),
          emails: customer?.emails?.slice()?.sort(sortNumber('orderNumber')),
        })),
      }
    : null
);
export const loading = createSelector(customers, state => state?.loading);
export const filterModel = createSelector(customers, state => state?.filterModel);
export const pagination = createSelector(customers, state => state?.pagination);
export const sorting = createSelector(customers, state => state?.sorting);
export const params = createSelector(
  filterModel,
  pagination,
  sorting,
  AuthStore.fleetSelector,
  (filter, pag, sort, fleet) =>
    ({
      ...filter,
      ...generatePagination(pag),
      ...generateSorting(sort),
      fleetId: fleet?.id,
    }) as Partial<ICustomersDataParams>
);
