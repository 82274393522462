import { AuthStore } from '@auth/store';
import { DIRECTION } from '@core/constant';
import {
  IBeforeDispatchOrdersData,
  IG7OrdersData,
  IG7OrdersParams,
  IMultiReturnG7Order,
  IMultiReturnPagination,
  IMultiValidationG7Order,
  IMultiValidationPagination,
} from '@core/models/g7-order.model';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { IObjectMap, IPaginationState, ISortingState } from '../../../../models/common.model';
import { isValidationAllowed } from '../../components/common/utils';
import * as G7OrdersActions from '../actions';

export const g7OrdersFeatureKey = 'g7-orders';

export interface State {
  ordersData: IG7OrdersData;
  ordersDataAllValidatable: IG7OrdersData;
  ordersDataAllValidatableSelected: boolean;
  disableDataAllValidatableSelected: boolean;
  selectedOrders: IObjectMap<boolean>;
  multiValidationPagination: IMultiValidationPagination;
  multiReturnPagination: IMultiReturnPagination;
  multiValidationOrders: IMultiValidationG7Order[];
  multiReturnOrders: IMultiReturnG7Order[];
  multiValidationTableLoading: boolean;
  multiValidationLoading: boolean;
  multiReturnTableLoading: boolean;
  multiReturnLoading: boolean;
  loading: boolean;
  filterModel: Partial<IG7OrdersParams>;
  pagination: IPaginationState;
  sorting: ISortingState;
  dispatchOrdersData: IBeforeDispatchOrdersData;
  dispatchPagination: IPaginationState;
  dispatchTableLoading: boolean;
  dispatchLoading: boolean;
}

export const initialState: State = {
  ordersData: null,
  ordersDataAllValidatable: null,
  ordersDataAllValidatableSelected: false,
  disableDataAllValidatableSelected: false,
  selectedOrders: {},
  multiValidationPagination: {
    page: 0,
    size: 5,
  },
  multiReturnPagination: {
    page: 0,
    size: 5,
  },
  multiValidationOrders: [],
  multiReturnOrders: [],
  multiValidationTableLoading: false,
  multiValidationLoading: false,
  multiReturnTableLoading: false,
  multiReturnLoading: false,
  loading: false,
  filterModel: {
    dateGt: null,
    dateLt: null,
    id: null,
    tripCategory: null,
    processingStatus: null,
    status: null,
    fleetCityName: null,
    partnerId: null,
    zeroPrice: null,
  },
  pagination: {
    page: 0,
    size: 15,
  },
  sorting: {
    sort: 'date',
    direction: DIRECTION.DESC,
  },
  dispatchOrdersData: null,
  dispatchPagination: {
    page: 0,
    size: 5,
  },
  dispatchTableLoading: false,
  dispatchLoading: false,
};

const g7OrdersReducer = createReducer(
  initialState,
  on(G7OrdersActions.getG7Orders, state => ({ ...state, loading: true })),
  on(G7OrdersActions.getG7OrdersSuccess, (state, { ordersData }) => ({ ...state, ordersData, loading: false })),
  on(G7OrdersActions.toggleG7OrdersAllValidatable, state => {
    if (state.ordersDataAllValidatableSelected) {
      return {
        ...state,
        ordersDataAllValidatableSelected: false,
        disableDataAllValidatableSelected: true,
        selectedOrders: {},
      };
    } else {
      return { ...state, ordersDataAllValidatableSelected: true, disableDataAllValidatableSelected: false };
    }
  }),
  on(G7OrdersActions.getG7OrdersAllValidatable, state => ({ ...state, loading: true })),
  on(G7OrdersActions.getG7OrdersAllValidatableSuccess, (state, { ordersData }) => ({
    ...state,
    ordersDataAllValidatable: ordersData,
    loading: false,
  })),
  on(G7OrdersActions.updateG7OrdersFilterModel, (state, { filterModel }) => {
    const { firstPayment, ...previousFilters } = state.filterModel;
    const { firstPayment: updatedFirstPayment, ...updatedFilters } = filterModel;

    let mergedFilters = { ...previousFilters, ...updatedFilters } as IG7OrdersParams;

    // we want to get initial state with all orders `including` "payment on board"
    if (updatedFirstPayment) {
      mergedFilters = { ...mergedFilters, firstPayment: updatedFirstPayment };
    }
    return {
      ...state,
      filterModel: { ...mergedFilters },
      selectedOrders: {},
      ordersDataAllValidatableSelected: false,
      disableDataAllValidatableSelected: false,
    };
  }),
  on(G7OrdersActions.updateG7OrdersPagination, (state, { pagination }) => ({
    ...state,
    pagination: { ...state.pagination, ...pagination },
  })),
  on(G7OrdersActions.updateG7OrdersSorting, (state, { sorting }) => ({
    ...state,
    sorting: { ...state.sorting, ...sorting },
  })),
  on(G7OrdersActions.getG7OrdersError, state => ({ ...state, ordersData: null, loading: false })),
  on(G7OrdersActions.getOrdersBeforeDispatch, state => ({ ...state, dispatchTableLoading: true })),
  on(G7OrdersActions.getOrdersBeforeDispatchSuccess, (state, { ordersData }) => ({
    ...state,
    dispatchOrdersData: ordersData,
    dispatchTableLoading: false,
  })),
  on(G7OrdersActions.getOrdersBeforeDispatchError, state => ({
    ...state,
    dispatchTableLoading: false,
    dispatchOrdersData: null,
  })),
  on(G7OrdersActions.updateDispatchPagination, (state, { pagination }) => ({
    ...state,
    dispatchPagination: { ...state.dispatchPagination, ...pagination },
  })),
  on(G7OrdersActions.dispatchG7Orders, state => ({ ...state, dispatchLoading: true })),
  on(G7OrdersActions.dispatchG7OrdersSuccess, state => ({ ...state, dispatchLoading: false })),
  on(G7OrdersActions.dispatchG7OrdersError, state => ({ ...state, dispatchLoading: false })),
  on(G7OrdersActions.closeBeforeDispatchPopup, state => ({
    ...state,
    dispatchOrdersData: null,
    dispatchPagination: { page: 0, size: 5 },
  })),
  on(G7OrdersActions.g7OrdersDestroy, state => ({
    ...state,
    ordersData: null,
    ordersDataAllValidatable: null,
    ordersDataAllValidatableSelected: false,
    disableDataAllValidatableSelected: false,
    selectedOrders: {},
  })),
  on(G7OrdersActions.toggleSelection, (state, { orderId }) => {
    const selectedOrders = {
      ...state.selectedOrders,
      [orderId]: !state.selectedOrders[orderId],
    };

    let { ordersDataAllValidatableSelected } = state;
    if (ordersDataAllValidatableSelected) {
      const allowedOrders = state.ordersData.content.filter(isValidationAllowed);
      const selectedToTrueOrders = Object.keys(selectedOrders).filter(order => !!selectedOrders[order]);

      if (allowedOrders.length !== selectedToTrueOrders.length) {
        ordersDataAllValidatableSelected = false;
      }
    }

    return {
      ...state,
      selectedOrders,
      disableDataAllValidatableSelected: false,
      ordersDataAllValidatableSelected,
    };
  }),
  on(G7OrdersActions.setAllSelection, state => {
    const selectedOrders = { ...state.selectedOrders };
    const allowedOrders = state.ordersData.content.filter(isValidationAllowed);
    allowedOrders.forEach(order => (selectedOrders[order.tripNumber] = true));
    return {
      ...state,
      selectedOrders: { ...state.selectedOrders, ...selectedOrders },
      ordersDataAllValidatableSelected: false,
      disableDataAllValidatableSelected: false,
    };
  }),
  on(G7OrdersActions.resetAllSelection, state => {
    const selectedOrders = { ...state.selectedOrders };
    const allowedOrders = state.ordersData.content.filter(isValidationAllowed);
    allowedOrders.forEach(order => (selectedOrders[order.tripNumber] = false));
    return {
      ...state,
      selectedOrders: { ...state.selectedOrders, ...selectedOrders },
      ordersDataAllValidatableSelected: false,
      disableDataAllValidatableSelected: false,
    };
  }),
  on(G7OrdersActions.resetSelection, (state, { orderId }) => {
    const selectedOrders = { ...state.selectedOrders };
    if (state.selectedOrders[orderId]) {
      selectedOrders[orderId] = false;
    }
    return {
      ...state,
      selectedOrders,
      ordersDataAllValidatableSelected: false,
      disableDataAllValidatableSelected: false,
    };
  }),
  on(G7OrdersActions.updateMultiValidationPagination, (state, { pagination }) => ({
    ...state,
    multiValidationPagination: { ...state.multiValidationPagination, ...pagination },
  })),
  on(G7OrdersActions.getMultiValidationOrders, state => ({ ...state, multiValidationTableLoading: true })),
  on(G7OrdersActions.getMultiValidationOrdersSuccess, (state, { orders }) => ({
    ...state,
    multiValidationTableLoading: false,
    multiValidationOrders: orders,
  })),
  on(G7OrdersActions.getMultiValidationOrdersError, state => ({
    ...state,
    multiValidationTableLoading: false,
    multiValidationOrders: null,
  })),
  on(G7OrdersActions.closeMultiValidationPopup, state => ({
    ...state,
    multiValidationPagination: {
      page: 0,
      size: 5,
    },
    multiValidationTableLoading: false,
    multiValidationLoading: false,
    multiValidationOrders: [],
  })),
  on(G7OrdersActions.multiValidateG7Orders, state => ({ ...state, multiValidationLoading: true })),
  on(G7OrdersActions.multiValidateG7OrdersHandleRes, state => ({
    ...state,
    multiValidationLoading: false,
    selectedOrders: {},
    ordersDataAllValidatableSelected: false,
    disableDataAllValidatableSelected: false,
  })),

  // Multi return G7 order

  on(G7OrdersActions.updateMultiReturnPagination, (state, { pagination }) => ({
    ...state,
    multiReturnPagination: { ...state.multiReturnPagination, ...pagination },
  })),
  on(G7OrdersActions.getMultiReturnOrders, state => ({ ...state, multiReturnTableLoading: true })),
  on(G7OrdersActions.getMultiReturnOrdersSuccess, (state, { orders }) => ({
    ...state,
    multiReturnTableLoading: false,
    multiReturnOrders: orders,
  })),
  on(G7OrdersActions.getMultiReturnOrdersError, state => ({
    ...state,
    multiReturnTableLoading: false,
    multiReturnOrders: null,
  })),
  on(G7OrdersActions.closeMultiReturnPopup, state => ({
    ...state,
    multiReturnPagination: {
      page: 0,
      size: 5,
    },
    multiReturnTableLoading: false,
    multiReturnLoading: false,
    multiReturnOrders: [],
  })),
  on(G7OrdersActions.multiReturnG7Orders, state => ({ ...state, multiReturnLoading: true })),
  on(G7OrdersActions.multiReturnG7OrdersHandleRes, state => ({
    ...state,
    multiReturnLoading: false,
    selectedOrders: {},
    ordersDataAllValidatableSelected: false,
    disableDataAllValidatableSelected: false,
  })),
  on(G7OrdersActions.multiReturnG7OrdersError, state => ({ ...state, multiReturnLoading: false }))
);

export const reducer = (state: State | undefined, action: Action) => g7OrdersReducer(state, action);

export const clearState =
  (reducerCleared: ActionReducer<State>): ActionReducer<State> =>
  (state, action) => {
    if (action?.type === AuthStore.logoutSuccess.type) {
      return reducerCleared(undefined, action);
    }

    return reducerCleared(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
