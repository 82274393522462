import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'azz-spinner-button7',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.less'],
})
export class AzzSpinnerButtonComponent {
  @Input() loadingIndicator: boolean;
  @Input() azzClass;
  @Input() azzValue;
  @Input() azzOnClick;
  @Input() azzDisabled: boolean;
  @Input() buttonType;
  @Input() outputType;
  @Input() NgTabindex = 0;
  @Input() customFocusTriggerCounter: number;
  @Output() azzClick = new EventEmitter();

  public onClick() {
    this.azzClick.emit(this.outputType);
  }

  public blockButton(runIndicator?: boolean) {
    this.loadingIndicator = runIndicator;
    this.azzDisabled = true;
  }

  public unBlockButton() {
    this.loadingIndicator = false;
    this.azzDisabled = false;
  }
}
