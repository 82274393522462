import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { DIRECTION } from '@core/constant';
import { G7OrderStatusFactory } from '@core/constant/g7-order-status.constant';
import { IFleet, IFleetCitiesData } from '@core/models/fleet.model';
import { IG7OrdersParams } from '@core/models/g7-order.model';
import { UtilService } from '@core/services/common';
import { FleetService } from '@core/services/users/fleet.service';
import { isEqual, omit } from 'lodash-es';
import moment from 'moment';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

interface LoadFleetsParams {
  query: number;
  direction: DIRECTION;
  pageNumber: number;
}

interface G7FiltersForm {
  dateGt: Date | null;
  dateLt: Date | null;
  id: number | null;
  tripCategory: string | null;
  processingStatus: string | null;
  partnerId: number | null;
  zeroPrice: boolean | null;
  paymentOnBoardOnly: boolean | null;
  fleetG7Id: number | null;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g7-orders-filters',
  templateUrl: './g7-orders-filters.component.html',
  styleUrls: ['./g7-orders-filters.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class G7OrdersFiltersComponent implements AfterViewInit {
  private readonly fb = inject(FormBuilder);
  private readonly utilService = inject(UtilService);
  private readonly g7OrderStatus = inject(G7OrderStatusFactory);
  private readonly fleetService = inject(FleetService);
  private readonly destroyed$ = inject(NgOnDestroyService);

  @Input() isG7User: boolean;
  @Output() updateFilters = new EventEmitter<Partial<IG7OrdersParams>>();
  public filterData: any;
  public form: FormGroup;
  public maxDate = moment();
  public categories = ['A', 'B', 'C'];
  public processingStatuses = this.g7OrderStatus.getAllStatuses();
  public onlyG7FleetId = true;

  constructor() {
    this.initForm();
  }

  get orderId() {
    return this.form.get('id').value;
  }

  get startDate() {
    return this.form.get('dateGt').value;
  }

  get endDate() {
    return this.form.get('dateLt').value;
  }

  get fleetCityName() {
    return this.form.get('fleetCityName').value;
  }

  private get typedFormValues(): G7FiltersForm {
    return this.form.value as G7FiltersForm;
  }

  @Input() set filterModel(value: Partial<IG7OrdersParams>) {
    const parsedResult = this.convertStoreValuesToFormValues(value);
    if (!this.isValueEqual(parsedResult)) {
      this.form.patchValue(parsedResult, { emitEvent: true });
    }
  }

  ngAfterViewInit() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        filter(() => this.form.get('id').valid),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe(() => {
        this.updateFilters.emit(this.convertFormValuesToStoreValues(this.typedFormValues));
      });
  }

  public filterDateStart = (d: moment.Moment): boolean => {
    if (d && this.endDate) {
      const maxDiff = moment(d).add(3, 'months').diff(d, 'days');
      const endDate = this.endDate ? moment(this.endDate).startOf('day') : this.endDate;
      return this.utilService.filterDateStart(d, endDate, maxDiff);
    }
    return true;
  };

  public filterDateEnd = (d: moment.Moment): boolean => {
    if (d && this.startDate) {
      const maxDiff = moment(this.startDate).add(3, 'months').diff(this.startDate, 'days');
      return this.utilService.filterDateEnd(d, this.startDate, maxDiff);
    }
    return true;
  };

  public loadCitiesCallBack() {
    return (query, direction, pageNumber) => this.loadCities({ query, direction, pageNumber });
  }

  public getLoadFleetsWithContextCallback() {
    return (query, direction, pageNumber) => this.loadFleets({ query, direction, pageNumber });
  }

  public loadFleets(data: LoadFleetsParams) {
    const { query, direction, pageNumber } = data;
    const sort = ['' + (direction || DIRECTION.ASC)];

    const params = {
      pageNumber,
      sort,
      withG7: true,
      g7Id: query,
    };

    return this.fleetService.searchFleets(params).pipe(takeUntil(this.destroyed$));
  }

  public onFleetG7IdSelect(fleet: IFleet): void {
    this.form.patchValue({ fleetG7Id: fleet?.idG7 || null });
  }

  public onFleetCitySelect(cityName: string) {
    this.form.patchValue({ fleetCityName: cityName || null });
  }

  public resetDate(date: 'dateGt' | 'dateLt'): void {
    this.form.get(date).reset();
  }

  private isValueEqual(parsedResult: any): boolean {
    const withoutDatesStore = omit(parsedResult, ['dateGt', 'dateLt']);
    const withoutDatesForm = omit(this.form?.value, ['dateGt', 'dateLt']);
    const { dateGt, dateLt } = parsedResult;
    return (
      isEqual(withoutDatesStore, withoutDatesForm) &&
      this.isDateEqual(dateGt, this.form?.value?.dateGt) &&
      this.isDateEqual(dateLt, this.form?.value?.dateLt)
    );
  }

  private convertStoreValuesToFormValues(filters: Partial<IG7OrdersParams>) {
    const { firstPayment, ...otherFilters } = filters;
    return {
      ...this.typedFormValues,
      ...otherFilters,
      dateGt: filters?.dateGt ? moment.utc(filters?.dateGt) : null,
      dateLt: filters?.dateLt ? moment.utc(filters?.dateLt) : null,
      paymentOnBoardOnly: firstPayment ?? null,
    };
  }

  private convertFormValuesToStoreValues(formValue: any): Partial<IG7OrdersParams> {
    const { dateGt, dateLt, paymentOnBoardOnly, ...unmodified } = formValue;
    return {
      ...unmodified,
      dateGt: dateGt?.startOf('day').valueOf() || null,
      dateLt: dateLt?.endOf('day').valueOf() || null,
      firstPayment: paymentOnBoardOnly,
    };
  }

  private initForm(): void {
    this.form = this.fb.group({
      dateGt: null,
      dateLt: null,
      id: [null, [Validators.pattern(this.utilService.numbersRegexp)]],
      tripCategory: null,
      processingStatus: null,
      fleetCityName: null,
      partnerId: null,
      zeroPrice: null,
      paymentOnBoardOnly: null,
      fleetG7Id: null,
    });

    this.filterData = {
      from: null,
      to: null,
      cityName: null,
      fleetG7Id: null,
    };
  }

  private isDateEqual(storeDate: moment.Moment | null, formDate: moment.Moment | null): boolean {
    return storeDate ? (formDate ? moment(formDate).isSame(storeDate) : false) : true;
  }

  private loadCities(data): Observable<IFleetCitiesData> {
    const { query, direction, pageNumber } = data;
    const sort = ['name,' + (direction || DIRECTION.ASC)];
    const params = {
      pageNumber,
      sort,
      name: query,
      pageSize: 15,
    };
    return this.fleetService.getFleetCities(params);
  }
}
