import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ConfirmPopupComponent } from '@common/components/confirm-popup/confirm-popup.component';
import { CustomPopupService } from '@core/services/common';
import { URLBuilderService } from '@core/services/common/url-builder.service';
import { RootService } from '@services/root.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/** FCC — flexible contact center */
interface FCCOrangeResponse {
  code: FCCOrangeCustomErrors;
  message: string;
}

export const AuthenticationFCCOrangeURL = 'https://web-desktop.flexiblecontactcenter.orange-business.com/';

export enum FCCOrangeCustomErrors {
  success = '0',
  incorrentPhone = 'API-5002',
  alreadyCalling = 'API-7004',
  /*
   * in case of unauthenticated user a redirect happens that returns unknown error in statusCode
   * a better solution would be to ask FCC Orange to return custom code and/or configure their CORS policy
   */
  unauthenticated = 'unauthenticated',
}

@Injectable()
export class FCCOrangeService {
  private readonly rootService = inject(RootService);

  constructor(
    private readonly popupService: CustomPopupService,
    private readonly http: HttpClient,
    private readonly urlBuilder: URLBuilderService
  ) {}

  public get(url: string): Observable<FCCOrangeResponse> {
    return this.http.get<FCCOrangeResponse>(this.urlBuilder.build(url), { withCredentials: true }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.statusText === 'Unknown Error') {
          return of({
            code: FCCOrangeCustomErrors.unauthenticated,
            message: 'You are unauthenticated',
          });
        } else {
          throw new Error('Something went wrong... Unhandled Exception');
        }
      }),
      tap(customError => {
        this.checkRulesForFCCOrangeCustomResponseCodes(customError);
      })
    );
  }

  private checkRulesForFCCOrangeCustomResponseCodes(customError: FCCOrangeResponse): void {
    switch (customError.code) {
      case FCCOrangeCustomErrors.success:
        return;
      case FCCOrangeCustomErrors.incorrentPhone:
        this.rootService.alerts.error('FCCORANGE_PHONE_CALL_BUTTON_INCORRECT', {
          subText: customError.code,
        });
        return;
      case FCCOrangeCustomErrors.alreadyCalling:
        this.rootService.alerts.error('FCCORANGE_PHONE_CALL_BUTTON_ALREADY_CALLING', {
          subText: customError.code,
        });
        return;
      case FCCOrangeCustomErrors.unauthenticated:
        this.confirnRedirectToAuthenticate();
        return;
      default:
        this.rootService.alerts.error(customError.message, {
          subText: customError.code,
        });

        return;
    }
  }

  /** confirm redirect when user is not authenticated */
  private confirnRedirectToAuthenticate(redirectUrl?: string) {
    const confirm = this.popupService.openConfirmPopup(ConfirmPopupComponent, {
      message: 'FCCORANGE_PHONE_CALL_BUTTON_AUTHENTICATE_MESSAGE',
      closeBtn: 'CANCEL',
      submitBtn: 'FCCORANGE_PHONE_CALL_BUTTON_AUTHENTICATE',
    });
    confirm.result.then(() => {
      console.log('redirectUrl: ', redirectUrl);
      console.log('', AuthenticationFCCOrangeURL);
      window.open(redirectUrl ? redirectUrl : AuthenticationFCCOrangeURL, '_blank');
    });
  }
}
