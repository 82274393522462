import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { includes } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import {
  DRIVER_SERVICE_AND_VEHICLE_TYPE_TAG_VALUE_ID_MAP,
  DRIVER_SERVICE_TYPE_TAG,
  DRIVER_VEHICLE_TYPE_TAG,
  SERVICE_TYPE_TAG,
  VEHICLE_TYPE_TAG,
} from '../../constant/tag.constant';
import { IDriverParameters } from '../../models/driver.model';
import { IColor, IEnergy, IMake, IModel, IVehicle } from '../../models/vehicle.model';
import { VehicleService } from '../../services/common/vehicle.service';
import { DriverService } from '../../services/users/driver.service';
import { numUpCaseFrCharsValidator } from '../../utils/validators';

@Component({
  selector: 'azz-vehicle-data',
  templateUrl: './vehicle-data.component.html',
  providers: [NgOnDestroyService],
})
export class VehicleDataComponent implements OnInit {
  @Input() withTaxiNumber: any;
  @ViewChild('formElemRef') formElemRef: ElementRef;

  public form: FormGroup;
  public makes: IMake[];
  public models: IModel[];
  public energies: IEnergy[];
  public colors: IColor[];
  public productionYears: number[] = [];
  public serviceTypes = DRIVER_SERVICE_TYPE_TAG;
  public vehicleTypes = DRIVER_VEHICLE_TYPE_TAG;
  private vehicle: IVehicle;
  private readonly MIN_PRODUCTION_YEAR_DIFFERENCE = 10;

  constructor(
    private readonly vehicleService: VehicleService,
    private readonly driverService: DriverService,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initForm();
  }

  get make(): AbstractControl {
    return this.form.controls.make;
  }

  @Input() set vehicleData(value: IVehicle) {
    this.vehicle = value;
    if (this.vehicle) {
      this.handleVehicle();
    }
  }
  @Input() set ngDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    }
  }
  @Input() set vehicleTypeTagIds(value: string[]) {
    this.setVehicleType(value);
  }
  @Input() set serviceTypeTagIds(value: string[]) {
    this.setServiceType(value);
  }

  ngOnInit() {
    this.loadMakes();
    this.loadEnergies();
    this.loadColors();
    this.initProductionYears();
  }

  public isServiceTypeTagDisabled(option: { id: string; value: string[] }): boolean {
    const vehicleType = this.form.controls.vehicleType.value;
    const { SEDAN, BR } = VEHICLE_TYPE_TAG;
    const { MV } = SERVICE_TYPE_TAG;
    return vehicleType ? option.id === MV && includes([SEDAN, BR], vehicleType.id) : false;
  }

  public isVehicleTypeTagDisabled(option: { id: string; value: string[] }): boolean {
    const serviceType = this.form.controls.serviceType.value;
    const { SEDAN, BR } = VEHICLE_TYPE_TAG;
    const { MV } = SERVICE_TYPE_TAG;
    return serviceType ? includes([SEDAN, BR], option.id) && serviceType.id === MV : false;
  }

  public getServiceAndVehicleTypeTagsId() {
    return [...this.form.controls.serviceType.value.value, ...this.form.controls.vehicleType.value.value];
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public reloadModels(): void {
    this.models = [];
    this.form.controls.model.reset();
    this.loadModels();
  }

  public trackByFn(index: number, item: any): string | number {
    if (!item) {
      return null;
    }
    return item.id;
  }

  private initForm(): void {
    this.form = new FormGroup({
      make: new FormControl(null, [Validators.required]),
      model: new FormControl(null, [Validators.required]),
      energy: new FormControl(null, [Validators.required]),
      color: new FormControl(null, [Validators.required]),
      serviceType: new FormControl(null, [Validators.required]),
      vehicleType: new FormControl(null, [Validators.required]),
      productionYear: new FormControl(null, [Validators.required]),
      plate: new FormControl(null, [Validators.required, numUpCaseFrCharsValidator]),
      taxiNumber: new FormControl({ value: null, disabled: true }),
    });
  }

  private loadMakes(): void {
    this.vehicleService
      .getMakes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IMake[]) => (this.makes = response));
  }

  private loadModels(): void {
    this.vehicleService
      .getModelByMake(this.make.value.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IModel[]) => (this.models = response));
  }

  private loadEnergies(): void {
    this.vehicleService
      .getEnergies()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IEnergy[]) => (this.energies = response));
  }

  private loadColors(): void {
    this.vehicleService
      .getColors()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IColor[]) => (this.colors = response));
  }

  private initProductionYears(): void {
    let currentYear = new Date().getFullYear();
    const minYear = currentYear - this.MIN_PRODUCTION_YEAR_DIFFERENCE;
    for (currentYear; currentYear >= minYear; currentYear--) {
      this.productionYears.push(currentYear);
    }
  }

  private loadTaxiNumber(): void {
    if (this.withTaxiNumber) {
      this.driverService
        .getDriverParams(this.vehicle.driverId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: IDriverParameters) => this.form.patchValue({ taxiNumber: res.taxiNumber }));
    }
  }

  private handleVehicle(): void {
    this.updateForm();
    this.loadModels();
    this.loadTaxiNumber();
  }

  private setVehicleType(value: string[]): void {
    if (value) {
      const patchedValue = value.length
        ? { id: DRIVER_SERVICE_AND_VEHICLE_TYPE_TAG_VALUE_ID_MAP[value.join('')], value }
        : { id: VEHICLE_TYPE_TAG.SEDAN, value };
      this.form.patchValue({ vehicleType: patchedValue });
    }
  }

  private setServiceType(value: string[]): void {
    if (value) {
      const patchedValue = value.length
        ? { id: DRIVER_SERVICE_AND_VEHICLE_TYPE_TAG_VALUE_ID_MAP[value.join('')], value }
        : { id: SERVICE_TYPE_TAG.STND, value };
      this.form.patchValue({ serviceType: patchedValue });
    }
  }

  private updateForm(): void {
    const { make, model, energy, color, productionYear, plate } = this.vehicle;
    this.form.patchValue({ make, model, energy, color, productionYear, plate });
  }
}
