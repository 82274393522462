import { IdOption, ServiceTagsOptions } from '@common/types/id-option.type';

export const VEHICLE_TYPE_TAG = {
  SEDAN: 'SEDAN',
  BR: 'BR',
  '5P': '5P',
  '6P': '6P',
  '7P': '7P',
  '8P': '8P',
} as const;

export const SERVICE_TYPE_TAG = {
  STND: 'STND',
  SP: 'SP',
  CA: 'CA',
  MV: 'MV',
  VP: 'VP',
} as const;

export const PAYMENT_TYPE_TAG = {
  DEFAULT: 'DEFAULT',
  CSH: 'CSH',
  CAR: 'CAR',
  ER: 'ER',
  AM: 'AM',
} as const;

export const DRIVER_SERVICE_TYPE_TAG = [
  { id: 'STND', value: [] },
  { id: 'SP', value: ['SP'] },
  { id: 'CA', value: ['CA', 'SP'] },
  { id: 'MV', value: ['CA', 'MV', 'SP'] },
  { id: 'VP', value: ['CA', 'SP', 'VP'] },
];

export const DRIVER_VEHICLE_TYPE_TAG = [
  { id: 'SEDAN', value: [] },
  { id: 'BR', value: ['BR'] },
  { id: '5P', value: ['5P', 'BR'] },
  { id: '6P', value: ['5P', '6P', 'BR'] },
  { id: '7P', value: ['5P', '6P', '7P', 'BR'] },
  { id: '8P', value: ['5P', '6P', '7P', '8P', 'BR'] },
];

export const DRIVER_SERVICE_TYPE_TAG_ID_MAP = {
  SP: true,
  CA: true,
  MV: true,
  VP: true,
};

export const DRIVER_VEHICLE_TYPE_TAG_ID_MAP = {
  BR: true,
  '5P': true,
  '6P': true,
  '7P': true,
  '8P': true,
};

export const TAG_TYPES: {
  service: ServiceTypeTitle[];
  vehicle: VehicleTypeTitle[];
  payment: PaymentTypeTitle[];
} = {
  service: ['CA', 'MV', 'SP', 'VP'],
  vehicle: ['5P', '6P', '7P', '8P', 'BR'],
  payment: ['CAR', 'CSH', 'ER', 'AM'],
};

export const DRIVER_SERVICE_AND_VEHICLE_TYPE_TAG_VALUE_ID_MAP = {
  SP: 'SP',
  CASP: 'CA',
  CAMVSP: 'MV',
  CASPVP: 'VP',
  BR: 'BR',
  '5PBR': '5P',
  '5P6PBR': '6P',
  '5P6P7PBR': '7P',
  '5P6P7P8PBR': '8P',
};

export type PaymentTypeTitle = (typeof PAYMENT_TYPE_TAG)[keyof typeof PAYMENT_TYPE_TAG];
export type VehicleTypeTitle = (typeof VEHICLE_TYPE_TAG)[keyof typeof VEHICLE_TYPE_TAG];
export type ServiceTypeTitle = (typeof SERVICE_TYPE_TAG)[keyof typeof SERVICE_TYPE_TAG];
export type TagTypeName = ServiceTypeTitle | VehicleTypeTitle | PaymentTypeTitle;

export const ORDER_TAG_TYPE: {
  service: ServiceTagsOptions<ServiceTypeTitle>[];
  vehicle: ServiceTagsOptions<VehicleTypeTitle>[];
  payment: IdOption<PaymentTypeTitle>[];
} = {
  service: [
    { id: 'STND', value: null, name: 'Standard', selected: false },
    { id: 'SP', value: 'SP', name: 'Service Plus', selected: false },
    { id: 'CA', value: 'CA', name: 'Club Affaire', selected: false },
    { id: 'MV', value: 'MV', name: 'Maxicab Vip', selected: false },
    { id: 'VP', value: 'VP', name: 'Vehicule Premium', selected: false },
  ],
  vehicle: [
    { id: 'SEDAN', value: null, name: 'Berline (4 Pers. Max)', selected: false },
    { id: 'BR', value: 'BR', name: 'Break (4 Pers. Max)', selected: false },
    { id: '5P', value: '5P', name: 'Navette 5 Personnes', selected: false },
    { id: '6P', value: '6P', name: 'Navette 6 Personnes', selected: false },
    { id: '7P', value: '7P', name: 'Navette 7 Personnes', selected: false },
    { id: '8P', value: '8P', name: 'Navette 8 Personnes', selected: false },
  ],
  payment: [
    { id: 'DEFAULT', value: 'DEFAULT' },
    { id: 'CSH', value: null },
    { id: 'CAR', value: 'CAR' },
    { id: 'ER', value: 'ER' },
    { id: 'AM', value: 'AM' },
  ],
};

export const ORDER_TAG_TYPE_MAP: {
  payment: { [key in PaymentTypeTitle]: IdOption<PaymentTypeTitle> };
  service: { [key in ServiceTypeTitle]: IdOption<ServiceTypeTitle> };
  vehicle: { [key in VehicleTypeTitle]: IdOption<VehicleTypeTitle> };
} = {
  payment: {
    DEFAULT: { id: 'DEFAULT', value: 'DEFAULT' },
    AM: { id: 'AM', value: 'AM' },
    CAR: { id: 'CAR', value: 'CAR' },
    CSH: { id: 'CSH', value: null },
    ER: { id: 'ER', value: 'ER' },
  },
  service: {
    CA: { id: 'CA', value: 'CA' },
    MV: { id: 'MV', value: 'MV' },
    SP: { id: 'SP', value: 'SP' },
    STND: { id: 'STND', value: null },
    VP: { id: 'VP', value: 'VP' },
  },
  vehicle: {
    '5P': { id: '5P', value: '5P' },
    '6P': { id: '6P', value: '6P' },
    '7P': { id: '7P', value: '7P' },
    '8P': { id: '8P', value: '8P' },
    BR: { id: 'BR', value: 'BR' },
    SEDAN: { id: 'SEDAN', value: null },
  },
};

export const SERVICE_VEHICLE_TYPE_DISABLED = {
  [SERVICE_TYPE_TAG.MV]: true,
  [VEHICLE_TYPE_TAG.SEDAN]: true,
  [VEHICLE_TYPE_TAG.BR]: true,
};
