import { isEqual } from 'lodash-es';

import { DIRECTION } from '../constant/direction.enum';

/** We have two sort helpers, we need to use only one because the implementation is the same */
export class SortHelper {
  constructor(
    private columns: string | string[],
    private direction: DIRECTION,
    private readonly cd: () => void
  ) {}

  static getSortWithDirection(columns: string | string[], direction: DIRECTION): string | string[] {
    return typeof columns === 'string' ? `${columns},${direction}` : columns.map(column => `${column},${direction}`);
  }

  public getSortDirectionClass(): 'arrow arrow-up' | 'arrow arrow-down' {
    return this.direction === DIRECTION.ASC ? 'arrow arrow-up' : 'arrow arrow-down';
  }

  public isSortedColumn(columns: string | string[]): boolean {
    return isEqual(columns, this.columns);
  }

  public sortColumns(columns: string[]): void {
    if (!this.isSortedColumn(columns)) {
      this.columns = columns;
      this.direction = DIRECTION.DESC;
    } else {
      this.changeSortDirection();
    }
    if (this.cd) {
      this.cd();
    }
  }

  public getColumns(): string | string[] {
    return this.columns;
  }

  public getColumnsNamesStr(): string {
    return (this.columns as string[]).join(',');
  }

  public getDirection(): DIRECTION {
    return this.direction;
  }

  private changeSortDirection(): void {
    this.direction = this.direction === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;
  }
}
