import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ICustomerHistoryData } from '../../../models/customer.model';

@Component({
  selector: 'azz-customer-modification-history',
  templateUrl: './azz-customer-modification-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzzCustomerModificationHistoryComponent {
  @Input() customerHistory: ICustomerHistoryData;
  @Input() historyLoadingIndicator: boolean;
}
