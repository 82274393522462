import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { UtilService } from '@core/services/common';
import { boundMethod } from 'autobind-decorator';
import { Feature, Point } from 'geojson';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { EtalabFeatureCollection } from '../../models/geo-json.model';
import { AddressesService, IFindAddressParams } from '../../services/common/addresses.service';
import { FormService } from '../../utils/form-service';

@Component({
  selector: 'azz-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  providers: [NgOnDestroyService],
})
export class AddressAutocompleteComponent implements OnInit {
  @Output() typing = new EventEmitter<void>();
  @Output() selectAddress = new EventEmitter<Partial<Feature<Point>>>();
  @Input() ngId: string;
  @Input() ngDisabled: boolean;
  @ViewChild('formElemRef') formElemRef: ElementRef;

  public form: FormGroup;
  public loadingIndicator: boolean;
  public address: Partial<Feature<Point>> | null;
  private readonly REQUEST_DELAY_MS = 300;

  constructor(
    private readonly addressesService: AddressesService,
    private readonly formService: FormService,
    private readonly utilService: UtilService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly onDestroy$: NgOnDestroyService
  ) {}

  @boundMethod
  public search(text$: Observable<string>) {
    return text$.pipe(
      tap(() => {
        this.resetAddress();
        this.typing.emit();
      }),
      tap(() => this.enableLoadingIndicator(true)),
      debounceTime(this.REQUEST_DELAY_MS),
      distinctUntilChanged(),
      switchMap((searchValue: string) => this.findAddress(this.generateParams(searchValue))),
      tap(() => {
        this.enableLoadingIndicator(false);
        this.customDetectChanges();
      }),
      takeUntil(this.onDestroy$)
    );
  }

  @boundMethod
  public formatter(a: string): string {
    const address = a as Partial<Feature<Point>>;
    if (typeof address !== 'string' && address?.properties) {
      return address.properties.name
        ? `${address.properties.name}, ${address.properties.city}`
        : this.utilService
            .tagRemoveFalsyValueFunc`${address.properties.housenumber} ${address.properties.street}, ${address.properties.city}`;
    } else {
      return a;
    }
  }

  @boundMethod
  private noSelectedAddressValidator(): ValidationErrors | null {
    return this.address ? null : { noSelectedAddress: true };
  }

  public setAutocompleteFieldValue(searchField: Partial<Feature<Point>>): void {
    this.form.patchValue({ searchField });
    this.address = searchField;
    this.detectAndUpdateSearchField();
  }

  public markFieldsAsTouched(): void {
    this.formService.markFieldsAsTouched(this.form);
    this.customDetectChanges();
  }

  public enableLoadingIndicator(enabled: boolean): void {
    this.loadingIndicator = enabled;
  }

  public onAddressSelect(event: { item: Feature<Point>; preventDefault: () => void }): void {
    this.address = event.item;
    this.selectAddress.emit(this.address);
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public detectAndUpdateSearchField(): void {
    this.form.get('searchField')?.updateValueAndValidity();
    this.customDetectChanges();
  }

  public reset(): void {
    if (this.address) {
      this.address = null;
    }
    this.form.reset();
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      searchField: [{ value: '', disabled: this.ngDisabled }, [this.noSelectedAddressValidator]],
    });
  }

  private resetAddress(): void {
    if (this.address) {
      this.address = null;
      this.form.controls.searchField.updateValueAndValidity();
    }
  }

  private findAddress(params: IFindAddressParams): Observable<Feature[]> {
    return params.q
      ? this.addressesService.findAddress(params).pipe(
          filter((response: EtalabFeatureCollection) => !!response?.features),
          map((response: EtalabFeatureCollection) => (response?.features?.length ? response.features : [null])),
          catchError(() => {
            this.enableLoadingIndicator(false);
            return of([]);
          }),
          takeUntil(this.onDestroy$)
        )
      : of([]);
  }

  private generateParams(searchValue: string): IFindAddressParams {
    return { limit: 10, q: searchValue };
  }

  private customDetectChanges(): void {
    if (this.changeDetectionRef && !(this.changeDetectionRef as ViewRef).destroyed) {
      this.changeDetectionRef.detectChanges();
    }
  }
}
