import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUrls } from '@core/constant/route-urls';
import { UserService } from '@core/services/users';
import { Observable } from 'rxjs';

const { registration, dash } = RouteUrls;
const predefinedRoutes = [
  // ToDo use ROLE_MAP instead of string values
  dash.fleet,
  dash.driver,
  dash.super,
  dash.billingUser,
  dash.phoneAdvisor,
  dash.dispatcher,
  `${registration.fleet}/firstLogin`,
  `${registration.driver}/edit`,
  `${registration.fleet}/admin/edit`,
  `${registration.billingUser}/edit`,
  `${registration.phoneAdvisor}/edit`,
  `${registration.dispatcher}/edit`,
];

@Injectable({ providedIn: 'root' })
export class IsSignedInGuard {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const routeToGo = this.userService.getUserDefaultRoute();

    return predefinedRoutes.includes(routeToGo) ? this.router.navigate([routeToGo]) : true;
  }
}
