<div class="azz-table-wrapper orders">
  <table class="table table-hover azz-table">
    <thead>
      <tr class="d-table-row">
        <th class="col-2" *ngIf="isG7User">{{ 'CITY' | translate }}</th>
        <th class="col-1" *ngIf="isG7User">{{ 'GO_ORDERS_G7_CODE_HEADER' | translate }}</th>
        <th (click)="sortColumn('date')" class="col-2">
          <span [azzArrow]="sorting?.direction" *ngIf="isSortedColumn('date')"></span>
          {{ 'DATE' | translate }}
        </th>
        <th class="col-2" *ngIf="isG7User">{{ 'REFERENCE_LABEL' | translate }}</th>
        <th class="col-1" *ngIf="!isG7User">{{ 'AUTHORIZATION_NUMBER' | translate }}</th>
        <th class="col-1" (click)="sortColumn('tripCategory')">
          <span [azzArrow]="sorting?.direction" *ngIf="isSortedColumn('tripCategory')"></span>
          {{ 'GO_ORDER_CATEGORY' | translate }}
        </th>
        <th class="col-2" *ngIf="isG7User">{{ 'AUTHORIZATION_NUMBER' | translate }}</th>
        <th class="col-3">{{ 'FO_APPOINTMENT_ADDRESS_COL_HEADER' | translate }}</th>
        <th (click)="sortColumn('totalPrice')" class="col-1">
          <span [azzArrow]="sorting?.direction" *ngIf="isSortedColumn('totalPrice')"></span>
          {{ 'TOTAL' | translate }}
        </th>
        <th class="col-2">{{ 'CLIENT' | translate }}</th>
        <th class="col-2">
          {{ 'GO_ORDERS_RN7_STATUS_HEADER' | translate }}
        </th>
        <th class="col-2" *ngIf="isG7User">{{ 'GO_DENY_VALIDATE_HEADER' | translate }}</th>
        <th class="col-1" *ngIf="isG7User">
          <input
            [class.not-all-selected]="atLeastOneSelectedOnPage && !allSelectedOnPage"
            class="reset-mr"
            [checked]="allSelectedOnPage"
            [disabled]="!ordersAllowedValidation?.length"
            (change)="selectAllOrders()"
            id="mass-validation"
            type="checkbox"
            name="mass_validation"
          />
          <label for="mass-validation"></label>
        </th>
      </tr>
      <tr class="orders__select-all" *ngIf="!(isAllValidatableSelectedDisabled$ | async)">
        <th colspan="15">
          <div>
            <span>
              <b>
                {{ counter$ | async }}
              </b>
            </span>
            @if (isAllValidatableSelected$ | async) {
              <span>
                {{ 'GO_ORDERS_G7_CODE_DESELECT_ALL_TITLE' | translate }}
              </span>
              <span (click)="unsetAllDataSelection()">
                {{ 'GO_ORDERS_G7_CODE_DESELECT_ALL' | translate }}
              </span>
            } @else {
              <span>
                {{ 'GO_ORDERS_G7_CODE_SELECT_ALL_TITLE' | translate }}
              </span>
              <span (click)="setAllDataSelection()">
                {{ 'GO_ORDERS_G7_CODE_SELECT_ALL' | translate }}
              </span>
            }
          </div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="ordersData?.content?.length > 0">
      <tr
        class="azz-clickable d-table-row"
        (click)="onOrderClick($event, order)"
        *ngFor="let order of ordersData?.content; let index = index; trackBy: trackByFunc"
      >
        <td *ngIf="isG7User">{{ order?.fleetCity }}</td>
        <td *ngIf="isG7User">{{ order?.fleetG7Id }}</td>
        <td>
          <span *ngIf="order?.dateMillis">
            {{ order?.dateMillis | date: 'dd.MM.yyyy' : 'UTC' }},<br />
            {{ order?.dateMillis | date: 'HH:mm' : 'UTC' }}
          </span>
        </td>
        <td *ngIf="isG7User">{{ order?.tripNumber }}</td>
        <td *ngIf="!isG7User">{{ order?.partnerId }}</td>
        <td>{{ order?.tripCategory }}</td>
        <td *ngIf="isG7User">{{ order?.partnerId }}</td>
        <td>
          <!--DEPARTURE ADDRESS-->
          <span *ngIf="order?.appointmentAddress?.poi?.label">{{ order?.appointmentAddress?.poi?.label }}</span>
          <span *ngIf="!order?.appointmentAddress?.poi?.label">
            <span *ngIf="!order.appointmentAddress?.street; then thenAppointmentBlock; else elseAppointmentBlock">
            </span>
            <ng-template #thenAppointmentBlock
              ><b>{{ 'GO_ORDER_UNKNOWN_ADDRESS' | translate }}</b></ng-template
            >
            <ng-template #elseAppointmentBlock>
              {{ order?.appointmentAddress?.number }}{{ order?.appointmentAddress?.adverbeMultiplicatif }}
              {{ order?.appointmentAddress?.typeVoie }}
              {{ order?.appointmentAddress?.street }}<span *ngIf="order.appointmentAddress?.city">,</span>
              {{ order?.appointmentAddress?.postalCode }}
              {{ order?.appointmentAddress?.city }}
            </ng-template>
          </span>
          -> <br />
          <!--ARRIVAL ADDRESS-->
          <span *ngIf="order?.destinationAddress?.poi?.label">{{ order?.destinationAddress?.poi?.label }}</span>
          <span *ngIf="!order?.destinationAddress?.poi?.label">
            <span *ngIf="!order.destinationAddress?.street; then thenDestinationBlock; else elseDestinationBlock">
            </span>
            <ng-template #thenDestinationBlock
              ><b>{{ 'GO_ORDER_UNKNOWN_ADDRESS' | translate }}</b></ng-template
            >
            <ng-template #elseDestinationBlock>
              {{ order?.destinationAddress?.number }}{{ order?.destinationAddress?.adverbeMultiplicatif }}
              {{ order?.destinationAddress?.typeVoie }}
              {{ order?.destinationAddress?.street }}<span *ngIf="order.destinationAddress?.city">,</span>
              {{ order?.appointmentAddress?.postalCode }}
              {{ order?.destinationAddress?.city }}
            </ng-template>
          </span>
        </td>
        <td [ngClass]="{ 'azz-color-red': order.price === 0 }">
          {{ (order?.totalPrice <= 0 ? 0 : order?.totalPrice) + ' \u20AC' }}
        </td>
        <td>
          {{ order?.customer?.name }}<br />
          {{ order?.customer?.phoneNumber }}
        </td>
        <td>
          <span *ngIf="order?.processingStatus">{{ 'GO_ORDER_STATUS_' + order?.processingStatus | translate }}</span>
        </td>
        <td *ngIf="isG7User">
          <div class="orders__btn-group" *ngIf="isG7MessageAllowed(order)">
            <button class="orders__btn orders__btn--invalidate" (click)="openInvalidatePopup($event, order)"></button>
          </div>
        </td>
        <td *ngIf="isG7User" class="no-general-navigation">
          <span *ngIf="isValidationAllowed(order)" class="no-general-navigation" (click)="$event.stopPropagation()">
            <input
              class="no-general-navigation"
              type="checkbox"
              name="mass_validation"
              [id]="'mass-validation-' + index"
              [checked]="selectedOrders[order?.tripNumber]"
              (change)="selectOrder(order?.tripNumber)"
            />
            <label class="no-general-navigation" [for]="'mass-validation-' + index"></label>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<azz-table-no-matches [azzVisible]="!ordersData?.content?.length"></azz-table-no-matches>
<azz-pagination
  (goToPrevPage)="prevPage()"
  (goToNextPage)="nextPage()"
  [prevDisabled]="isPrevDisabled()"
  [nextDisabled]="isNextDisabled()"
>
</azz-pagination>
