import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserRole } from '@core/constant/role.constant';
import { RouteUrls } from '@core/constant/route-urls';
import { UserService } from '@core/services/users';

const roles = RouteUrls.dash;

export const redirectCanActivate: CanActivateFn = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  return router.createUrlTree([userService.getUserDefaultRoute()]);
};

export const roleGuard = (role: UserRole): CanActivateFn => {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);

    if (userService.getUserDefaultRoute() === roles[role]) {
      return true;
    }

    void router.navigate(['/welcome']);
  };
};
