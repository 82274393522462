import { Component, Input } from '@angular/core';

@Component({
  selector: 'azz-icon-return',
  standalone: true,
  template: `
    <svg width="15" viewBox="0 0 15 15" fill="none" [attr.class]="className" aria-hidden="true">
      <path
        d="M8.91817 4.59668C8.91817 4.7182 8.96907 4.83474 9.05967 4.92067C9.15026 5.0066 9.27314 5.05488 9.40127 5.05488H14.25V0.460298C14.25 0.33822 14.1989 0.221141 14.1079 0.134818C14.0168 0.0484956 13.8934 0 13.7647 0C13.636 0 13.5125 0.0484956 13.4215 0.134818C13.3305 0.221141 13.2794 0.33822 13.2794 0.460298V3.4617C12.3705 2.19944 11.0454 1.25921 9.51215 0.788663C7.97885 0.318113 6.32419 0.34389 4.80801 0.861946C3.29183 1.38 2 2.361 1.13538 3.65088C0.27076 4.94077 -0.117683 6.46649 0.0310493 7.98845C0.179782 9.51042 0.857265 10.9424 1.95712 12.0596C3.05697 13.1768 4.51689 13.9159 6.10763 14.1608C7.69837 14.4058 9.32983 14.1427 10.7458 13.4129C12.1618 12.683 13.2822 11.5278 13.9309 10.1287C13.9789 10.0152 13.9785 9.88847 13.9298 9.77528C13.8811 9.66208 13.7878 9.57131 13.6698 9.52216C13.5517 9.47301 13.4182 9.46934 13.2973 9.51192C13.1765 9.55451 13.0779 9.64001 13.0223 9.75034C12.4593 10.9665 11.4842 11.9694 10.2517 12.6C9.01915 13.2306 7.59989 13.4527 6.21912 13.2311C4.83836 13.0095 3.57519 12.3568 2.63011 11.3766C1.68502 10.3965 1.11215 9.14507 1.00243 7.82094C0.892708 6.49682 1.25241 5.17586 2.02446 4.06773C2.7965 2.9596 3.93665 2.12777 5.26396 1.70427C6.59126 1.28077 8.02968 1.28986 9.35092 1.73009C10.6722 2.17033 11.8005 3.01649 12.5569 4.13428H9.4057C9.34151 4.13372 9.27785 4.1453 9.21843 4.16833C9.15901 4.19137 9.10503 4.22541 9.05964 4.26846C9.01425 4.31151 8.97837 4.36271 8.95407 4.41906C8.92978 4.47542 8.91758 4.5358 8.91817 4.59668Z"
        [attr.fill]="iconColor"
      />
    </svg>
  `,
})
export class IconReturnComponent {
  @Input() public iconColor = 'currentColor';
  @Input() public className = 'icon-return';
}
