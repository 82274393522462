import { OrderStatusType } from '@core/constant/order-status.constant';
import dayjs from 'dayjs';

import { DispatchOrderWrapperClass } from './abstract-dispatch-order.class';
import { getPartialEditTimeLimit } from './get-active-order-date-limit.function';
import { getAllFieldsEditableTimeLimit } from './get-editable-order-date-limit.function';

export class ManualDispatchOrderClass extends DispatchOrderWrapperClass {
  public readonly type = 'manual' as const;

  constructor() {
    super();
  }

  isEditAllowed(status: OrderStatusType): boolean {
    return this.isAllowedToEditAllFields(status);
  }

  isAllowedToEditAllFields(status: OrderStatusType): boolean {
    if (!this.hasTimeConstaints()) {
      console.warn('NO TIME CONSTRAINTS PROVIDED, IS IT INTENTIONAL?');
      return false;
    }
    const now = dayjs();

    const partialEditDateLimit = getPartialEditTimeLimit(this.timeConstraints);

    const isOrderEditable = {
      byTime: now.isBefore(partialEditDateLimit),
      byStatus: this.rulesByOrderStatus.canCancelOrEdit.includes(status),
    } as const;

    return !this.isOnlyAddressEditAllowed(status) && isOrderEditable.byTime && isOrderEditable.byStatus;
  }

  isOnlyAddressEditAllowed(status: OrderStatusType) {
    const now = dayjs();
    const partialEditDateLimit = getPartialEditTimeLimit(this.timeConstraints);
    const fullEditDateLimit = getAllFieldsEditableTimeLimit(this.timeConstraints);

    const isOnlyAddressesAllowed = {
      byTime: now.isBefore(fullEditDateLimit) && now.isAfter(partialEditDateLimit),
      byStatus: this.rulesByOrderStatus.canCancelOrEdit.includes(status),
    };

    return isOnlyAddressesAllowed.byTime && isOnlyAddressesAllowed.byStatus;
  }

  isAllowedToEditOnlyAddressesOnlyByException(): boolean {
    throw new Error('Method [ManualDispatchOrderClass.isAllowedToEditOnlyAddressesOnlyByException] not implemented.');
  }
}
