<ng-container *ngIf="tags.length === 0; else selectedTagsTemplate">
  <div *ngIf="tags.length === 0" class="tags-container">
    <span class="recent-order-content text">{{ 'TAG_STND' | translate }}, </span>
    <span class="recent-order-content text">{{ 'TAG_SEDAN' | translate }}</span>
  </div>
</ng-container>

<ng-template #selectedTagsTemplate>
  <div class="tags-container">
    <span class="recent-order-content text" *ngFor="let id of tags; let last = last">
      {{ 'TAG_' + id | translate }}<span *ngIf="!last">, </span>
    </span>
  </div>
</ng-template>
