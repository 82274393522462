import { Directive, EventEmitter, Output, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { IShowDialogOptions } from '../../models/dialog.model';

@Directive()
export abstract class CustomDialogDirective {
  @Output() azzClose = new EventEmitter<any>();
  @Output() azzSuccess = new EventEmitter<any>();
  @Output() azzSubmit = new EventEmitter<any>();
  public header: string;
  public message: string;
  public cancelValue: string;
  public hasCancelButton = true;
  public hasOkButton = true;
  public okValue: string;
  public modalClass: string;
  public retrievedValue: any;
  public dialogContent: any;
  public headerTranslateParams: { [property: string]: string | number };
  public messageTranslateParams: { [property: string]: string | number };
  public isSubmitted = false;
  public customCancelBtnClass: string;
  public customOkBtnClass: string;

  protected modalReferenceArray: NgbModalRef[] = [];

  protected constructor(protected dialogService: NgbModal) {}

  public showDialog(header: string, message: string, options?: IShowDialogOptions): NgbModalRef {
    this.header = header;
    this.message = message;
    if (options) {
      this.cancelValue = options.cancelValue;
      this.okValue = options.okValue;
      this.hasCancelButton = options.hasCancelButton ?? true;
      this.headerTranslateParams = options.headerTranslateParams;
      this.hasOkButton = options.hasOkButton ?? true;
      this.messageTranslateParams = options.messageTranslateParams;
      this.retrievedValue = options.retrievedValue;
      this.modalClass = options.modalClass;
      this.isSubmitted = options.isSubmitted;
      this.customCancelBtnClass = options.customCancelBtnClass;
      this.customOkBtnClass = options.customOkBtnClass;
    }
    const modalReference = this.dialogService.open(this.dialogContent, {
      windowClass: this.modalClass,
      ariaLabelledBy: 'modal-basic-title',
    });
    modalReference.result.then(
      () => {
        this.modalReferenceOnSuccess();
      },
      () => {
        this.modalReferenceOnClose();
      }
    );
    this.modalReferenceArray.push(modalReference);
    return modalReference;
  }

  public onClose(): void {
    if (this.retrievedValue) {
      this.azzClose.emit({ value: this.retrievedValue });
    }
    const removedModalReference = this.modalReferenceArray.pop();
    removedModalReference.close();
  }

  public onSuccess(): void {
    if (this.retrievedValue) {
      this.azzSuccess.emit({ value: this.retrievedValue });
      this.retrievedValue = null;
    } else {
      this.azzSuccess.emit();
    }
    const removedModalReference = this.modalReferenceArray.pop();
    removedModalReference.close();
  }

  public onSubmit(): void {
    if (this.retrievedValue) {
      this.azzSubmit.emit({ value: this.retrievedValue });
    } else {
      this.azzSubmit.emit();
    }
  }

  public closeDialog(): void {
    this.retrievedValue = null;
    const removedModalReference = this.modalReferenceArray.pop();
    removedModalReference.close();
  }

  public closeAllDialogs(): void {
    this.dialogService.dismissAll();
  }

  public hasOpenModals(): boolean {
    return this.dialogService.hasOpenModals();
  }

  public modalReferenceOnSuccess(): void {}

  public modalReferenceOnClose(): void {}

  protected setContent(content: TemplateRef<unknown>) {
    this.dialogContent = content;
  }
}
