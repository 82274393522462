import { Directive, ElementRef, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[azzOpenOnEnterClick]',
})
export class OpenDatepickerOnEnterClickDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      this.el.nativeElement.click();
    }
  }
}

@NgModule({
  declarations: [OpenDatepickerOnEnterClickDirective],
  exports: [OpenDatepickerOnEnterClickDirective],
})
export class OpenDatepickerOnEnterClickModule {}
