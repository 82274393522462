import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DriverTagsListComponent } from './driver-tags-list/driver-tags-list.component';
import { DriverTagsComponent } from './driver-tags.component';
import { DriverTagsStoreModule } from './store/driver-tags-store.module';
import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, LoadingBlockModule, DriverTagsStoreModule],
  declarations: [DriverTagsComponent, DriverTagsListComponent],
  exports: [DriverTagsComponent],
})
export class DriverTagsModule {}
