import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { initSentry } from './init-sentry.function';

if (environment.production) {
  enableProdMode();
  initSentry();
}

document.addEventListener('DOMContentLoaded', async () => {
  const module = await platformBrowserDynamic().bootstrapModule(AppModule);

  if (environment.production) {
    return;
  }

  /**
   * Run in the browser console 'ng.profiler.timeChangeDetection'
   * to get number of change detection cycles and time per check
   */
  const applicationRef = module.injector.get(ApplicationRef);
  const appComponent = applicationRef.components[0];
  enableDebugTools(appComponent);
});
