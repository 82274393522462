import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUrls } from '@core/constant/route-urls';
import { UserInfoService } from '@core/services/users/user-info.service';
import { createEffect, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as AuthActions from '../actions';

@Injectable()
export class NewPasswordEffects extends EffectsBase {
  private readonly userInfoService = inject(UserInfoService);
  private readonly router = inject(Router);

  public newPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.newPassword),
      switchMap(({ body }) =>
        this.userInfoService.newPassword(body).pipe(
          map(() => AuthActions.newPasswordSuccess()),
          catchError(err => {
            if (err?.status === 404 && err?.error?.reason === 'not.found') {
              void this.router.navigate([RouteUrls.auth.forgot]);
            }

            this.alertErr('RESET_PASSWORD_LINK_EXPIRED');

            return of(err);
          })
        )
      )
    )
  );

  public newPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.newPasswordSuccess),
        tap(() => {
          void this.router.navigate([RouteUrls.auth.login]);
          this.alertSuccess('NEW_PASSWORD_SAVED');
        })
      ),
    { dispatch: false }
  );
}
