import { Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { CustomAutocompleteComponent } from '@core/modules/azz-custom-autocomplete';
import { AddressesService } from '@core/services/common';
import { FormService } from '@core/utils/form-service';
import { boundMethod } from 'autobind-decorator';

@Component({
  selector: 'azz-poi-address-autocomplete',
  templateUrl: './poi-address-autocomplete.component.html',
  providers: [NgOnDestroyService],
})
export class PoiAddressAutocompleteComponent extends CustomAutocompleteComponent implements OnChanges {
  public markerCoordsStr: string;

  constructor(addressesService: AddressesService, formService: FormService, destroyed$: NgOnDestroyService) {
    super(addressesService, formService, destroyed$);
  }

  get searchField(): AbstractControl {
    return this.form.controls.searchField;
  }

  @Input() set markerDragCoordsStr(value: string) {
    this.markerCoordsStr = value;
    if (this.form) {
      this.form.controls.searchField.updateValueAndValidity();
    }
  }

  @boundMethod
  public addressAutocompleteValidator(): ValidationErrors | null {
    return this.markerCoordsStr ? null : { markerNotAdded: true };
  }

  public override formatter = (address: { properties: { name: string; city: string } } | string): string =>
    typeof address === 'string' ? address : `${address.properties.name}, ${address.properties.city}`;

  public reset(): void {
    this.form.reset();
  }
  protected override initForm(): void {
    this.form = new FormGroup({
      searchField: new FormControl('', [this.addressAutocompleteValidator]),
    });
  }
}
