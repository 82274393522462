import { OrderType } from '@core/constant/order.constant';
import { TIME } from '@core/constant/time.constant';
import moment from 'moment';

export const defineOrderType = (params: { time: string; date: moment.Moment }): OrderType => {
  const maxMinutesForImmediate = 31;
  const maxMinutesForReservation = TIME.HOURS_IN_DAY * TIME.MINUTES_IN_HOUR + maxMinutesForImmediate;

  const date = params.date.format('YYYY-MM-DD');
  const orderDate = moment(`${date}T${params.time}`);
  const minutes = orderDate.diff(moment(), 'minutes');

  if (minutes > maxMinutesForImmediate && minutes < maxMinutesForReservation) {
    return 'IN_ADVANCE';
  } else if (minutes >= maxMinutesForReservation) {
    return 'RESERVATION';
  } else if (minutes <= maxMinutesForImmediate) {
    return 'ORDER';
  }
};
