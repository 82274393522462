import { Injectable } from '@angular/core';
import { boundMethod } from 'autobind-decorator';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';

@Injectable()
export class LoadWrapperService {
  public readonly isLoading$: Observable<boolean>;
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isLoading$ = this.loadingSubject.asObservable();
  }

  @boundMethod
  private loadOn() {
    this.loadingSubject.next(true);
  }

  @boundMethod
  private loadOff() {
    this.loadingSubject.next(false);
  }

  public showLoadingUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(this.loadOn),
      // delay(2000),
      concatMap(() => obs$),
      finalize(this.loadOff)
    );
  }
}
