<button
  azzFocusOnInputChange
  class="d-flex align-items-center justify-content-center"
  [attr.type]="buttonType"
  [focusTriggerCounter]="customFocusTriggerCounter"
  [ngClass]="azzClass"
  [disabled]="azzDisabled"
  (click)="onClick()"
>
  <div style="pointer-events: none">
    <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
  </div>
  {{ azzValue }}
</button>
