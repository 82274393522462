import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ICreateSuperAdmin,
  ICreateSuperAdminPayload,
  IDeactivateSuperAdminParams,
  ISuperAdminsData,
  ISuperAdminsParams,
} from '../../models/super-admin.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class SuperAdminService extends ApiService {
  private readonly API_URL = 'user/api/v1/superAdmin/';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public createSuperAdmin(payload: ICreateSuperAdminPayload): Observable<ICreateSuperAdmin> {
    return this.post(this.API_URL, payload);
  }

  public getSuperAdmins(params: Partial<ISuperAdminsParams>): Observable<ISuperAdminsData> {
    return this.get(this.API_URL, params);
  }

  public deactivateSuperAdmin(
    userId: number,
    params: Partial<IDeactivateSuperAdminParams>
  ): Observable<ICreateSuperAdmin> {
    return this.put(`${this.API_URL}deactivate/${userId}`, {}, params);
  }

  public activateSuperAdmin(userId: number): Observable<ICreateSuperAdmin> {
    return this.put(`${this.API_URL}activate/${userId}`, {});
  }

  public deleteSuperAdmin(userId: number): Observable<void> {
    return this.delete(`${this.API_URL}${userId}`);
  }

  public getSuperAdminInvitation(invitationId: string): Observable<ICreateSuperAdmin> {
    return this.get(`${this.API_URL}invitation/${invitationId}`);
  }

  public updateSuperAdminInvitation(invitationId: string, payload: ICreateSuperAdmin): Observable<ICreateSuperAdmin> {
    return this.put(`${this.API_URL}invitation/${invitationId}`, payload);
  }
}
