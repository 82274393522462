import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewRef } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'azz-search-point-of-interest',
  templateUrl: './search-point-of-interest.component.html',
  styleUrls: ['./search-point-of-interest.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class SearchPointOfInterestComponent {
  @Output() filterPoiByLabel$ = new EventEmitter<string>();
  public poiLabel: string;
  private readonly watcher$ = new Subject<string>();
  private readonly REQUEST_DELAY_MS = 300;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initWatcher();
  }

  public initWatcher(): void {
    this.watcher$
      .pipe(takeUntil(this.destroyed$), debounceTime(this.REQUEST_DELAY_MS))
      .subscribe((poiLabel: string) => {
        this.filterPoiByLabel$.emit(poiLabel);
      });
  }

  public searchPoiByLabel(poiLabel: string): void {
    this.watcher$.next(poiLabel);
  }

  public setPoiLabel(poiLabel: string): void {
    this.poiLabel = poiLabel;
    this.customDetectChanges();
  }

  public resetPoiLabel(): void {
    this.poiLabel = null;
    this.watcher$.next(this.poiLabel);
    this.customDetectChanges();
  }

  public customDetectChanges(): void {
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }
}
