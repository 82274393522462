import { inject, Injectable } from '@angular/core';
import { DRIVER_SERVICE_TYPE_TAG_ID_MAP, DRIVER_VEHICLE_TYPE_TAG_ID_MAP } from '@core/constant/tag.constant';
import { ITag } from '@core/models';
import { TagService } from '@core/services/common';
import { createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { forkJoin, Observable } from 'rxjs';
import { exhaustMap, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import * as DriverTagsActions from '../actions';
import { DriverTagsStore } from '../index';

@Injectable()
export class DriverTagsEffects extends EffectsBase {
  private readonly tagService = inject(TagService);

  public getCombinedTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverTagsActions.getCombinedTags),
      switchMap(({ driverId }) =>
        forkJoin([this.tagService.getAllTags(), this.tagService.getDriverTags(driverId)]).pipe(
          map(([allTags, currentDriverTags]) =>
            DriverTagsActions.getCombinedTagsSuccess({ allTags, currentDriverTags })
          ),
          this.catchError(DriverTagsActions.getCombinedTagsError())
        )
      )
    )
  );

  public updateDriverTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverTagsActions.updateDriverTags),
      withLatestFrom(this.selectFromStore(DriverTagsStore.selectedTagsIdsMap)),
      switchMap(([{ driverId }, selectedTagsIdsMap]) =>
        this.tagService.getDriverTags(driverId).pipe(
          mergeMap((tags: ITag[]) => {
            const selectedTagIds = Object.keys(selectedTagsIdsMap).filter(id => selectedTagsIdsMap[id]);
            const serviceVehicleTypeTagsIds = tags
              .map(t => t.id)
              .filter(id => DRIVER_SERVICE_TYPE_TAG_ID_MAP[id] || DRIVER_VEHICLE_TYPE_TAG_ID_MAP[id]);
            return this.tagService.saveDriverTags(driverId, [...serviceVehicleTypeTagsIds, ...selectedTagIds]).pipe(
              map(() => DriverTagsActions.updateDriverTagsSuccess()),
              this.catchError(DriverTagsActions.updateDriverTagsError())
            );
          })
        )
      )
    )
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(DriverTagsActions.init),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(DriverTagsActions.destroy)))))
    );
  }
}
