<form class="card azz-multiple autocomplete" [formGroup]="form" #formElemRef>
  <div>
    <ng-template #rt let-r="result" let-t="term">
      <ngb-highlight *ngIf="r" [result]="r?.properties?.name + ', ' + r?.properties?.city" [term]="t"></ngb-highlight>
      <div *ngIf="!r">{{ 'ADDRESS_NOT_FOUND' | translate }}</div>
    </ng-template>

    <div class="d-flex align-items-center">
      <input
        azzEmitOnEvent
        sourceEvent="blur"
        class="form-control azz-transparent-input azz-validate"
        type="text"
        formControlName="searchField"
        [id]="ngId"
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        (selectItem)="onAddressSelect($event)"
        (triggerAction$)="enableLoadingIndicator(false)"
      />
      <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
    </div>
  </div>
  <div *ngIf="form.invalid && form.touched" class="form-control__error">
    <small *ngIf="searchField?.errors?.markerNotAdded">{{ 'POI_CREATE_MARKER_NOT_ADDED' | translate }}</small>
  </div>
</form>
