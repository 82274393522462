<div class="layout-form">
  <p-messages
    [value]="messages()"
    [showTransitionOptions]="'500ms'"
    [hideTransitionOptions]="'500ms'"
    [enableService]="false"
  />
  <form [formGroup]="form" #formElemRef class="create-order-form">
    <!-- order-info block -->
    <div class="card order-info-card">
      <div class="card-container">
        <h2 class="header-card">{{ 'PA_DETAILS_ORDERS_INFO' | translate }}</h2>
        <div class="flex gap-5" formGroupName="order">
          <div class="card-container-time">
            <div class="d-flex align-item-center gap-5">
              <div class="card-date-time">
                <div class="transparent-input-label" for="dateInfo">{{ 'FN_DATE_LABEL' | translate }}*</div>
                <azz-old-date
                  *ngIf="(refreshing$ | async) === false"
                  formControlName="date"
                  id="dateInfo"
                  pAutoFocus
                  [autofocus]="true"
                  [minDate]="dateRange.min"
                  [maxDate]="dateRange.max"
                  [isErrorState]="
                    form.controls.order.controls.date.touched && form.controls.order.controls.date.invalid
                  "
                />

                <div class="transparent-input-error" *ngIf="form.controls.order.controls.date as control">
                  <small>
                    @if (control.touched && control.invalid) {
                      {{ 'PA_CREATE_ORDER_DATE_INCORRECT' | translate }}
                    }
                  </small>
                </div>
              </div>

              <div class="card-date-time" *ngIf="form.controls.order.controls.time as control">
                <div class="transparent-input-label" for="timeInfo">{{ 'FN_TIME_LABEL' | translate }}*</div>
                <azz-old-time
                  formControlName="time"
                  id="timeInfo"
                  [isErrorState]="control.touched && control.invalid"
                />
                <div class="transparent-input-error">
                  <small>
                    @if (control.touched && control.invalid) {
                      <!--                      @if (control.errors.required || control.errors.minTime || control.errors.invalid) {-->
                      {{ 'PA_CREATE_ORDER_HOUR_INCORRECT' | translate }}
                      <!--                      }-->
                    }
                  </small>
                </div>
              </div>
            </div>
          </div>

          <!-- temporary solution, html tags is bad -->
          <div>
            <div class="transparent-input-label delay-label">
              {{ 'DISPATCH_TIME' | translate }}
              <azz-tooltip
                *ngIf="form.controls.order.controls.dispatchDelay.value"
                [dispatchBeforeMinutesRestrictions]="dispatchBeforeMinutesRestrictions$ | async"
              />
            </div>
            <div class="d-flex align-center">
              <div class="delay-input-container">
                <azz-delay-dispatch
                  formControlName="dispatchDelay"
                  [defaultValue]="(isDispatchDelayEnabled$ | async) ? (dispatchBeforeMinutes$ | async) : null"
                  [isErrorState]="
                    form.controls.order.controls.dispatchDelay.errors?.min ||
                    form.controls.order.controls.dispatchDelay.errors?.max
                  "
                />
                <div class="transparent-input-error" *ngIf="form.controls.order.controls.dispatchDelay as control">
                  <small>
                    @if (control.invalid) {
                      @if (control.errors?.min || control.errors?.max) {
                        {{ 'DISPATCH_DELAY_VALUE_INCORRECT' | translate }}
                      }
                    }
                  </small>
                </div>
                <azz-rotate-indicator7
                  *ngIf="(orderType$ | async) !== 'ORDER'"
                  class="loading-indicator"
                  size="extra-small"
                  [visible]="dispatchBeforeMinutesLoading$ | async"
                  newDesign
                />
              </div>
              <span class="delay-unit">{{ 'DELAY_UNIT' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="card-container-tags">
            <div class="transparent-input-label">{{ 'DT_APPOINTMENT_ADDRESS_COL_HEADER' | translate }}*</div>
            <azz-address-poi-autocomplete
              #departureAddressRef
              ngId="departure-address"
              addressEqualityError="DEPARTURE_ADDRESS_SAME_AS_ARRIVAL_ADDRESS"
              [withPoi]="true"
              [orderServiceType]="form.value?.serviceType?.id"
              [correspondingAddress]="arrivalAddress"
              [isDepartureAddressOutsideOfBusinessZone]="isDepartureAddressOutsideOfBusinessZone"
              (selectAddress)="onDepartureAddressSelect($event)"
              (typing)="onDepartureAddressTyping()"
              (clear)="clearDepartureAdress()"
            />
          </div>
          <div class="card-container-tags adress-input">
            <div class="transparent-input-label">{{ 'DT_DESTINATION_ADDRESS_COL_HEADER' | translate }}*</div>
            <azz-address-poi-autocomplete
              #arrivalAddressRef
              ngId="arrival-address"
              addressEqualityError="ARRIVAL_ADDRESS_SAME_AS_DEPARTURE_ADDRESS"
              [withPoi]="true"
              [orderServiceType]="form.value?.serviceType?.id"
              [correspondingAddress]="departureAddress"
              [customFocusTriggerCounter]="destinationAddressFocusTriggerCounter"
              (selectAddress)="onArrivalAddressSelect($event)"
              (typing)="onArrivalAddressTyping()"
              (clear)="clearArrivalAddress()"
            />
          </div>
        </div>

        <button class="btn-invert" type="button" (click)="invertAddresses()"></button>

        <div class="flex">
          <div class="card-container-tags">
            <div class="transparent-input-label">{{ 'ADRESS_COMMENTARY_DEPARTURE' | translate }}</div>
            <input
              id="depart_comment"
              type="text"
              name="depart_comment"
              formControlName="appointmentComment"
              class="transparent-input"
            />
            <div
              class="pragmatic-form-control__error"
              *ngIf="form.controls?.appointmentComment?.errors && form.controls?.appointmentComment?.touched"
            >
              <small *ngIf="form.controls.appointmentComment.errors?.maxlength">
                {{ 'ADRESS_COMMENT_MAX_LENGTH' | translate }}
              </small>
              <small *ngIf="form.controls['appointmentComment'].hasError('hasCyrilicLetters')">
                {{ 'ADRESS_COMMENT_NO_CYRYLICS' | translate }}
              </small>
            </div>
          </div>
          <div class="card-container-tags adress-input">
            <div class="transparent-input-label">{{ 'ADRESS_COMMENTARY_ARRIVE' | translate }}</div>
            <input
              id="dest_comment"
              type="text"
              name="dest_comment"
              formControlName="destinationComment"
              class="transparent-input"
            />
            <div
              class="pragmatic-form-control__error"
              *ngIf="form.controls?.destinationComment?.errors && form.controls?.destinationComment?.touched"
            >
              <small *ngIf="form.controls.destinationComment.errors?.maxlength">
                {{ 'ADRESS_COMMENT_MAX_LENGTH' | translate }}
              </small>
              <small *ngIf="form.controls['destinationComment'].hasError('hasCyrilicLetters')">
                {{ 'ADRESS_COMMENT_NO_CYRYLICS' | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- client-info block -->
    <div class="card client-info-card">
      <div class="card-container">
        <h2 class="header-card">{{ 'PA_CREATE_NEW_ORDER_CUSTOMER_INFO_HEADER' | translate }}</h2>
        <div class="content" formGroupName="customer">
          <div class="tabs-content">
            <label class="btn-tabs" [ngClass]="{ 'highlight-text': form.value.customer?.type === customerType.old }">
              {{ 'PA_SEARCH_CUSTOMER' | translate }}
              <input
                (change)="changeCustomer()"
                id="search_customer"
                formControlName="type"
                class="btn-tab"
                type="radio"
                checked="checked"
                name="type"
                value="old"
              />
              <span class="border-bottom"></span>
            </label>

            <label class="btn-tabs" [ngClass]="{ 'highlight-text': form.value.customer?.type === customerType.new }">
              {{ 'PA_CREATE_CUSTOMER' | translate }}
              <input
                (change)="changeCustomer()"
                id="new_customer"
                class="btn-tab"
                type="radio"
                formControlName="type"
                name="type"
                value="new"
              />
              <span class="border-bottom"></span>
            </label>

            <hr />
          </div>
          <div class="col-md-12" *ngIf="form.value.customer?.type === customerType.old">
            <label [for]="'history-search'" class="transparent-input-label">
              {{ 'COMMANDE_PA_CREATE_NEW_ORDER_CUSTOMER_SEARCH_HEADER' | translate }}*
            </label>
            <azz-customer-typeahead
              ngId="history-search"
              (selectCustomer$)="onCustomerSelect($event)"
              (typing)="resetCustomer()"
              (createCustomer$)="switchToCreateCustomer($event)"
              (searchValues$)="checkCustomerAutocompleteRules($event)"
            >
            </azz-customer-typeahead>
          </div>

          <div
            class="col-md-12 selector"
            *ngIf="form.value.customer?.type === customerType.old && selectedCustomer?.phones?.length"
          >
            <label class="transparent-input-label"> {{ 'PHONE_NUMBER' | translate }}*</label>
            <div *ngIf="selectedCustomer?.phones?.length === 1" class="single-phone-number">
              {{ selectedCustomer?.phones[0]?.phoneCode }}{{ selectedCustomer?.phones[0]?.phoneNumber }}
            </div>
            <div *ngIf="selectedCustomer?.phones?.length > 1">
              <div ngbDropdown (openChange)="isPhoneNumberDropdownCollapsed = !isPhoneNumberDropdownCollapsed">
                <input
                  type="text"
                  class="input-dropdown-list transparent-input input-dropdown-list_expandable"
                  [ngClass]="isPhoneNumberDropdownCollapsed ? 'input-dropdown-list_collapse' : ''"
                  data-toggle="dropdown"
                  [value]="form.controls['selectedCustomerPhoneNumber'].value"
                  ngbDropdownToggle
                  readonly
                />
                <div ngbDropdownMenu>
                  <button
                    ngbDropdownItem
                    *ngFor="let item of selectedCustomer?.phones"
                    (click)="
                      form.controls['selectedCustomerPhoneNumber']?.setValue(item?.phoneCode + item?.phoneNumber)
                    "
                    [ngClass]="{
                      'dropdown-selected-item':
                        form.controls['selectedCustomerPhoneNumber']?.value === item?.phoneNumber,
                    }"
                  >
                    {{ item?.phoneCode }}{{ item?.phoneNumber }}
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="withCTI">
              <input
                id="add_new_customer_phone_number"
                type="checkbox"
                [(ngModel)]="addNewCustomerPhoneNumber"
                [ngModelOptions]="{ standalone: true }"
                name="add-new-customer-phone-number"
              />
              <label for="add_new_customer_phone_number" class="create__new-customer-phone">
                {{ 'ADD_NEW_CUSTOMER_NUMBER' | translate }}
              </label>
            </div>
          </div>

          <!-- Customer Type New START  -->
          <ng-container *ngIf="form.value.customer?.type === customerType.new">
            <div class="form-group col-md-12">
              <label for="lastName" class="transparent-input-label">{{ 'LAST_NAME' | translate }}*</label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                maxlength="101"
                (input)="syncPassengerName()"
                (blur)="onBlurFormatLastNameText()"
                formControlName="lastName"
                class="transparent-input"
              />
              <div
                class="pragmatic-form-control__error"
                *ngIf="
                  form.controls?.customer?.controls?.lastName?.errors &&
                  form.controls?.customer?.controls.lastName?.touched
                "
              >
                <small *ngIf="form.controls?.customer?.controls?.lastName?.errors?.required">
                  {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
                </small>
                <small *ngIf="form.controls?.customer?.controls?.lastName?.errors?.minlength">
                  {{ 'NEW_CUSTOMER_LASTNAME_MIN_MAX_LENGTH' | translate: { minLength: 2, maxLength: 100 } }}
                </small>
                <small *ngIf="form.controls?.customer?.controls?.lastName?.errors?.maxlength">
                  {{ 'NEW_CUSTOMER_LASTNAME_MIN_MAX_LENGTH' | translate: { minLength: 2, maxLength: 100 } }}
                </small>
                <small *ngIf="form.controls?.customer?.controls?.lastName?.errors?.azzNotFrLettersAndNums">
                  {{ 'COMMANDE_CORE_ONLY_LETTERS_NUMBERS_VALIDATION_ERROR' | translate }}
                </small>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="firstName" class="transparent-input-label">{{ 'FIRST_NAME' | translate }}</label>
              <input
                id="firstName"
                type="text"
                name="firstName"
                maxlength="101"
                (input)="syncPassengerName()"
                (blur)="onBlurFormaFirstNameText()"
                formControlName="name"
                class="transparent-input"
              />
              <div
                class="pragmatic-form-control__error"
                *ngIf="
                  form.controls?.customer?.controls?.name?.errors && form.controls?.customer?.controls?.name?.touched
                "
              >
                <small *ngIf="form.controls.customer?.controls?.name?.errors?.azzNotFrLettersAndNums">
                  {{ 'COMMANDE_CORE_ONLY_LETTERS_NUMBERS_VALIDATION_ERROR' | translate }}
                </small>
              </div>
            </div>

            <div class="form-group col-md-12">
              <label for="phone_number" class="transparent-input-label"> {{ 'PHONE_NUMBER' | translate }}* </label>
              <azz-phone inputId="phone_number" formControlName="phoneNumber" />

              <div *ngIf="phoneAlreadyExists" class="hover-dropdown">
                <small class="error-text">
                  {{ 'PHONE_NUMBER_ALREADY_EXISTS' | translate }}
                </small>
                <small class="link" (click)="redirectToExistingClient()">
                  {{ 'REDIRECT_TO_EXISTING_CLIENT' | translate }}
                </small>
              </div>

              <div class="pragmatic-form-control__error">
                <ng-container *ngIf="customerPhoneControl.touched && customerPhoneControl?.errors">
                  <small *ngIf="customerPhoneControl?.errors.required || customerPhoneControl?.errors?.emptyInput">
                    {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
                  </small>
                  <small *ngIf="customerPhoneControl?.errors?.pattern">
                    {{ 'COMMANDE_CORE_INVALID_PHONE_NUMBER_VALIDATION_ERROR' | translate }}
                  </small>
                </ng-container>
                <ng-container *ngIf="customerPhoneControl.dirty && customerPhoneControl.errors">
                  <small *ngIf="customerPhoneControl.errors.minLength">
                    {{ 'CORE_PHONE_NUMBER_NUM_LENGTH' | translate: { num: 9 } }}
                  </small>
                  <small *ngIf="customerPhoneControl.errors.maxLength">
                    {{ 'CORE_PHONE_NUMBER_NUM_LENGTH' | translate: { num: 9 } }}
                  </small>
                </ng-container>
              </div>
            </div> </ng-container
          ><!-- Customer Type New END -->
        </div>
        <div class="content">
          <div class="col-md-12" *ngIf="!form.value.anonymousPassenger">
            <div class="form-group">
              <label for="passenger_name" class="transparent-input-label transparent-input-label-wide">
                {{ 'PA_CREATE_NEW_ORDER_PASSENGER_NAME' | translate }}</label
              >
              <input
                id="passenger_name"
                type="text"
                name="passenger_name"
                formControlName="passengerName"
                class="transparent-input"
              />
            </div>
          </div>
          <div class="col-md-12 checkbox-input">
            <input
              id="passenger_anonyme"
              type="checkbox"
              formControlName="anonymousPassenger"
              name="passenger_anonyme"
            />
            <label for="passenger_anonyme" class="checkbox-label transparent-input-label-wide">
              {{ 'ANONYMOUS_PASSENGER' | translate }}
            </label>
          </div>
          <div class="col-md-12">
            <label class="transparent-input-label">{{ 'ORDER_DETAILS_PAYMENT_MODE_LABEL' | translate }}*</label>
            <div>
              <div ngbDropdown #dropdown="ngbDropdown" (openChange)="changeDropdownState()">
                <div>
                  <input
                    type="text"
                    class="input-dropdown-list transparent-input input-dropdown-list_expandable"
                    [ngClass]="{
                      'input-dropdown-list-error':
                        form.controls['paymentType']?.value.id === 'DEFAULT' && isDropdownTouched,
                      'input-dropdown-list_collapse': isPaymentDropdownCollapsed,
                    }"
                    data-toggle="dropdown"
                    [value]="'PAYMENT_TYPE_' + form.controls['paymentType']?.value.id | translate"
                    ngbDropdownToggle
                    readonly
                    [disabled]="form.disabled"
                  />
                </div>
                <div
                  class="pragmatic-form-control__error"
                  *ngIf="form.controls['paymentType']?.value.id === 'DEFAULT' && isDropdownTouched"
                >
                  <small>
                    {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
                  </small>
                </div>

                <ul ngbDropdownMenu aria-labelledby="contractPeriodDropDown">
                  <li
                    *ngFor="let payment of uiTypes.payment | slice: 1"
                    ngbDropdownItem
                    (click)="form.controls['paymentType']?.setValue(payment)"
                    class="dropdown-item"
                    [ngClass]="{ 'dropdown-selected-item': form.controls['paymentType']?.value.id === payment?.id }"
                  >
                    {{ 'PAYMENT_TYPE_' + payment?.id | translate }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label for="comment" class="transparent-input-label"> {{ 'PA_CREATE_INFO_PROVISION' | translate }}</label>
            <input
              tabindex="-1"
              id="comment"
              type="text"
              name="comment"
              formControlName="comment"
              maxlength="128"
              rows="1"
              class="transparent-input"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- attribute-tags block -->
    <azz-attribute-tags
      [listOfTags]="listOfTags"
      (setChangeTag)="onChangeTag()"
      [isTagsDisabled]="isTagsDisabled"
      [form]="form"
    />

    <!-- order-pre-save(summary) block -->
    <azz-order-pre-save-dialog
      [form]="form"
      [orderDate]="(orderSubGroup.controls.date.valueChanges | async) || orderSubGroup.controls.date.value"
      [orderTime]="(orderSubGroup.controls.time.valueChanges | async) || orderSubGroup.controls.time.value"
      [isEstimatedPriceLoading]="estimatedPriceLoading"
      [isLoading]="isButtonBlocked"
      [azzDisabled]="isValidateButtonDisabled"
      [distance]="geoMeasurmentCourseEstimation.distance"
      [duration]="geoMeasurmentCourseEstimation.duration"
      [estimatedPrice]="estimatedPrice"
      [isEstimatedPriceShown]="isEstimatedPriceShown"
      [addresses]="addresses"
      [customer]="selectedCustomer"
      [newCustomer]="newCustomer"
      [isEtaLoading]="isEtaLoading"
      [distanceShowType]="distanceShowType$ | async"
      [distanceETA]="geoMeasurmentETA.distance"
      [durationETA]="geoMeasurmentETA.duration"
      [departureAddress]="departureAddress"
      [tags]="getSelectedFilters()"
      (azzConfirm)="submit()"
      (azzClose)="onCancelBtnClick()"
      [orderTimeInTimeFormat]="recalculatedDispatchTime"
    />

    <azz-custom-confirm-dialog (azzSubmit)="createWithoutDriverSuccess()" (azzClose)="createWithoutDriverCancel()" />

    <azz-driver-assignment formControlName="driver" (driverBecomeDisabled)="driverBecomeDisabled($event)" />

    <!-- customer-previous-order(frequent-orders) block -->
    <azz-customer-previous-order
      [customerPrevOrders]="customerMostFrequentOrders"
      (setPreviousOrderData)="setPreviousOrderData($event)"
    />

    <!-- customer-current-ordersr(current-orders) block -->
    <azz-customer-current-orders
      [customerCurrentOrdersData]="customerCurrentOrdersData"
      (loadNextPage)="getCustomerOrders()"
    />
  </form>
</div>
