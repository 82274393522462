import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownMultipleComponent } from './dropdown-multiple.component';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';
import { PaginationModule } from '../pagination/pagination.module';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, NgbDropdownModule, AzzRotateIndicatorModule, PaginationModule],
  declarations: [DropdownMultipleComponent],
  exports: [DropdownMultipleComponent],
})
export class DropdownMultipleModule {}
