import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { AddressesService } from '@core/services/common';
import { IFindAddressParams } from '@core/services/common/addresses.service';
import { FormService } from '@core/utils/form-service';
import { boundMethod } from 'autobind-decorator';
import { Feature } from 'geojson';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'azz-poi-city-autocomplete',
  templateUrl: './poi-city-autocomplete.component.html',
  styleUrls: ['./poi-city-autocomplete.component.less'],
  providers: [NgOnDestroyService],
})
export class PoiCityAutocompleteComponent {
  @Input() ngId: string;
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @Output() setCity = new EventEmitter<boolean>();
  @Output() type = new EventEmitter();
  public form: FormGroup;
  public loadingIndicator: boolean;
  private readonly REQUEST_DELAY_MS = 300;
  private selectedCity: string;
  private noCityFound: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly addressesService: AddressesService,
    private readonly cd: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initForm();
  }

  get city() {
    return this.form.value.city;
  }

  @boundMethod
  private noCityFoundValidator(): ValidationErrors | null {
    return this.noCityFound ? { noCityFound: true } : null;
  }

  @boundMethod
  private noSelectedCity(): ValidationErrors | null {
    return this.selectedCity ? null : { noSelectedCity: true };
  }

  public search = (text$: Observable<string>) =>
    text$.pipe(
      tap(() => this.resetCity()),
      debounceTime(this.REQUEST_DELAY_MS),
      distinctUntilChanged(),
      tap(() => this.enableLoadingIndicator(true)),
      switchMap((searchValue: string) => {
        const params = {
          limit: 10,
          q: searchValue,
        };
        this.type.emit();
        this.resetCity();
        return this.findCity(params);
      }),
      tap(() => this.enableLoadingIndicator(false)),
      takeUntil(this.destroyed$)
    );

  public findCity(params: IFindAddressParams): Observable<Feature[]> {
    return params.q
      ? this.addressesService.getCities(params).pipe(
          map((response: string[]) => (response.length ? response : [null])),
          takeUntil(this.destroyed$),
          catchError(() => {
            this.enableLoadingIndicator(false);
            return EMPTY;
          })
        )
      : of([]);
  }

  public onCitySelect(event: { item: string; preventDefault: () => void }): void {
    this.selectedCity = event.item;
    this.noCityFound = false;
    this.setCity.emit(true);
  }

  public setCityFromOutside(city: string): void {
    this.form.patchValue({ city });
    this.selectedCity = city;
    this.noCityFound = false;
    this.formService.markFieldsAsTouched(this.form);
    this.detectAndUpdate();
  }

  public setNoCityFound(): void {
    this.form.patchValue({ city: null });
    this.selectedCity = null;
    this.noCityFound = true;
    this.formService.markFieldsAsTouched(this.form);
    this.detectAndUpdate();
  }

  public reset(): void {
    this.form.reset();
    this.noCityFound = false;
    this.selectedCity = null;
    this.form.controls.city.updateValueAndValidity();
  }

  public detectAndUpdate(): void {
    this.form.controls.city.updateValueAndValidity();
    this.customDetectChanges();
  }

  public enableLoadingIndicator(enabled: boolean): void {
    this.loadingIndicator = enabled;
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  private initForm(): void {
    this.form = this.fb.group({
      city: ['', [this.noCityFoundValidator, this.noSelectedCity]],
    });
  }

  private customDetectChanges(): void {
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  private resetCity(): void {
    if (this.selectedCity) {
      this.selectedCity = null;
      this.form.controls.city.updateValueAndValidity();
      this.form.reset();
      this.setCity.emit();
    }
  }
}
