import { AdvertEffects } from './advert.effects';
import { ExceptionsCountEffects } from './exceptions-count.effects';
import { PrevRouteEffects } from './prev-route.effects';
import { UserMessagesEffects } from './user-messages.effects';
import { UserNotificationsEffects } from './user-notifications.effects';

export const AppEffects = [
  PrevRouteEffects,
  AdvertEffects,
  ExceptionsCountEffects,
  UserNotificationsEffects,
  UserMessagesEffects,
];
