import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { ICountry } from '../../models/fleet.model';

@Injectable({ providedIn: 'root' })
export class CountryService extends ApiService {
  private readonly countryUrl = 'user/api/v1/country';

  constructor(http: HttpClient) {
    super(http);
  }

  public getCountries(): Observable<ICountry[]> {
    return this.get(this.countryUrl);
  }
}
