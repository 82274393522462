import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DIRECTION } from '@core/constant';
import { IPaginationState, ISortingState } from '@core/models/common.model';
import { G7Order, IG7OrdersData, IG7OrderSingleValidateBody } from '@core/models/g7-order.model';
import { G7OrdersStore } from '@core/modules/g7-orders/store';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { Observable } from 'rxjs';

import { isG7MessageAllowed, isValidationAllowed } from '../common/utils';

@Component({
  selector: 'azz-g7-orders-table',
  templateUrl: './g7-orders-table.component.html',
  styleUrls: ['./g7-orders-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class G7OrdersTableComponent {
  @Input() ordersData: IG7OrdersData;
  @Input() sorting: ISortingState;
  @Input() isG7User: boolean;
  @Input() selectedOrders: { [key: string]: boolean };
  @Input() atLeastOneSelectedOnPage: boolean;
  @Input() allSelectedOnPage: boolean;
  @Input() ordersAllowedValidation: G7Order[];
  @Output() updatePagination$ = new EventEmitter<Partial<IPaginationState>>();
  @Output() updateSorting$ = new EventEmitter<Partial<ISortingState>>();
  @Output() openValidationPopup$ = new EventEmitter<{
    orderId: number;
    body: IG7OrderSingleValidateBody;
  }>();
  @Output() openInvalidatePopup$ = new EventEmitter<G7Order>();
  @Output() selectOrder$ = new EventEmitter<number>();
  @Output() selectAllOrders$ = new EventEmitter<void>();

  public isAllValidatableSelected$: Observable<boolean>;
  public isAllValidatableSelectedDisabled$: Observable<boolean>;
  public counter$: Observable<number>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>
  ) {
    this.isAllValidatableSelected$ = this.store.pipe(select(G7OrdersStore.selectG7OrdersDataAllValidatableSelected));
    this.isAllValidatableSelectedDisabled$ = this.store.pipe(
      select(G7OrdersStore.selectG7OrdersDataAllValidatableSelectedDisabled)
    );

    this.counter$ = this.store.pipe(select(G7OrdersStore.selectG7OrdersDataSelectedOrdersCount));
  }

  public sortColumn(sort: string): void {
    if (this.sorting?.sort !== sort) {
      // ToDo Check if state is changed after sending the same sort value
      this.updateSorting$.emit({ sort, direction: DIRECTION.DESC });
    } else {
      this.changeSortDirection();
    }
  }

  public changeSortDirection(): void {
    const direction = this.sorting?.direction === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;
    this.updateSorting$.emit({ direction });
  }

  public isSortedColumn(name: string): boolean {
    return this.sorting?.sort === name;
  }

  public prevPage(): void {
    this.updatePagination$.emit({ page: this.getCurrentPage() - 1 });
  }

  public nextPage(): void {
    this.updatePagination$.emit({ page: this.getCurrentPage() + 1 });
  }

  public isPrevDisabled(): boolean {
    return !this.ordersData || this.ordersData?.first;
  }

  public isNextDisabled(): boolean {
    return !this.ordersData || this.ordersData?.last;
  }

  public onOrderClick(evt: any, order: G7Order): void {
    void this.router.navigate([order.tripNumber], { relativeTo: this.route });
  }

  public isG7MessageAllowed(order: G7Order): boolean {
    return isG7MessageAllowed(order);
  }

  public openValidationPopup(evt: Event, order: G7Order): void {
    evt.stopPropagation();
    this.openValidationPopup$.emit({ orderId: order.tripNumber, body: { valid: true } });
  }

  public openInvalidatePopup(evt: Event, order: G7Order): void {
    evt.stopPropagation();
    this.openInvalidatePopup$.emit(order);
  }

  public selectOrder(orderId: number): void {
    this.selectOrder$.emit(orderId);
  }

  public selectAllOrders() {
    this.selectAllOrders$.emit();
  }

  public isValidationAllowed(order: G7Order): boolean {
    return isValidationAllowed(order);
  }

  public trackByFunc(index: number, item: G7Order): number {
    return item?.tripNumber || null;
  }

  public unsetAllDataSelection() {
    this.store.dispatch(G7OrdersStore.toggleG7OrdersAllValidatable());
  }

  public setAllDataSelection() {
    this.store.dispatch(G7OrdersStore.getG7OrdersAllValidatable());
    this.store.dispatch(G7OrdersStore.toggleG7OrdersAllValidatable());
  }

  private getCurrentPage(): number {
    return this.ordersData?.number || 0;
  }
}
