import { DRIVER_SESSION_STATUS } from '@core/constant';
import { IDriverUserInfo } from '@core/models/user-info.model';

export enum AssignmentDriverOrderStatus {
  OCCUPIED = 'OCCUPIED',
  IN_QUEUE = 'IN_QUEUE',
  CONFIRMED = 'CONFIRMED',
  AT_DEPARTURE_ADDRESS = 'AT_DEPARTURE_ADDRESS',
  FREE = 'FREE',
  CONNECTED = 'CONNECTED',
  TOWARDS_DESTINATION = 'TOWARDS_DESTINATION',
  UNREACHABLE = 'UNREACHABLE',
}

const workingStatuses: string[] = [
  AssignmentDriverOrderStatus.OCCUPIED,
  AssignmentDriverOrderStatus.IN_QUEUE,
  AssignmentDriverOrderStatus.CONFIRMED,
  AssignmentDriverOrderStatus.AT_DEPARTURE_ADDRESS,
  AssignmentDriverOrderStatus.FREE,
  AssignmentDriverOrderStatus.CONNECTED,
  AssignmentDriverOrderStatus.TOWARDS_DESTINATION,
  AssignmentDriverOrderStatus.UNREACHABLE,
];

function getAssignmentStatuses(driverInfo: IDriverUserInfo): string[] {
  const orders = driverInfo.orders?.filter(order => workingStatuses.includes(order.status));
  let statuses: string[];
  if (!orders?.length) {
    statuses = [driverInfo.sessionStatus];
  } else {
    statuses = orders.map(order => order.status);
  }

  return statuses;
}

export class DriverAssignmentInfo {
  public id: number;
  public statuses: string[];
  public taxiNumber: string;
  public firstName: string;
  public lastName: string;
  public searchText: string;
  public disabled: boolean;

  constructor(driverInfo: IDriverUserInfo) {
    this.id = driverInfo.id;
    this.taxiNumber = driverInfo.taxiNumber;
    this.firstName = driverInfo.firstName;
    this.lastName = driverInfo.lastName;
    this.searchText = `${driverInfo.taxiNumber} ${driverInfo.firstName} ${driverInfo.lastName}`;
    this.statuses = getAssignmentStatuses(driverInfo);
    this.disabled = this.statuses.length >= 2 || driverInfo.sessionStatus === DRIVER_SESSION_STATUS.UNREACHABLE;
  }
}
