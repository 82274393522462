import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from 'geojson';
import {
  control,
  Control,
  DrawEvents,
  FeatureGroup,
  Icon,
  latLng,
  LatLng,
  Layer,
  Map,
  MapOptions,
  Marker,
  Point,
  tileLayer,
} from 'leaflet';
import 'leaflet.fullscreen/Control.FullScreen.js';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgOnDestroyService } from '@common/services';
import { COORDS } from '@core/constant/coords.constant';
import { POI_ICON_URL } from '@core/constant/poi.constant';
import { PoiService } from '@core/services/common';
import { CreateZoneService } from '@core/services/map/create-zone.service';

@Component({
  selector: 'azz-map-create-point-of-interest',
  templateUrl: './map-create-point-of-interest.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class MapCreatePointOfInterestComponent implements OnChanges, OnDestroy {
  private readonly translate = inject(TranslateService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly poiService = inject(PoiService);
  private readonly createZoneService = inject(CreateZoneService);
  private readonly destroyed$ = inject(NgOnDestroyService);

  @Input() selectedServiceLevel: string;
  @Input() markerLatLngArr: number[];
  @Output() dragMark = new EventEmitter<LatLng>();
  @Output() createMark = new EventEmitter<LatLng>();
  @Output() deleteMark = new EventEmitter<void>();
  public geoOptions: MapOptions;
  public geoLayers: Layer[];
  private readonly poiLayer = new FeatureGroup();
  private map: Map;
  private readonly zoom = 6;
  private readonly createdMarkerZoom = 6;
  private poiDrawControlFull: Control;
  private poiDrawControlEdit: Control;
  private readonly defaultIconSize = 35;
  private createdMarker: Marker;
  private readonly changingMarkerCoordsByAutocomplete$ = new BehaviorSubject<'exist' | 'not-exist'>(null);
  private readonly businessZoneLayer = new FeatureGroup();
  private businessZoneGeoJSON: any;
  private readonly layersOnlyBorderOptions = this.createZoneService.getLayersOnlyBorderOptions();

  constructor() {
    this.initDefaultMapOptionsAndLayers();
    this.initPoiDrawControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedServiceLevel && changes.selectedServiceLevel.currentValue && this.createdMarker) {
      const icon = this.createMarkerIcon(changes.selectedServiceLevel.currentValue, this.defaultIconSize);
      this.createdMarker.setIcon(icon);
    }
    if (changes.markerLatLngArr && changes.markerLatLngArr.currentValue) {
      if (this.createdMarker) {
        this.changingMarkerCoordsByAutocomplete$.next('exist');
      } else {
        this.changingMarkerCoordsByAutocomplete$.next('not-exist');
      }
    }
  }

  public onMapReady(map: Map) {
    this.map = map;
    this.map.addLayer(this.poiLayer);
    this.map.addLayer(this.businessZoneLayer);
    this.map.addControl(this.poiDrawControlFull);
    this.setPoiDrawEvents();
    this.addFullScreenControl();
    this.listenForChangingMarkerCoordsByAutocomplete();
    this.listenForFleetBusinessZoneGeoJSON();
  }

  public setMarkerView({ data, resetZoom = true }: { data: LatLng; resetZoom?: boolean }): void {
    if (this.map) {
      const zoom = resetZoom ? this.createdMarkerZoom : this.map.getZoom();
      this.map.setView(data, zoom);
    }
  }

  public listenForChangingMarkerCoordsByAutocomplete(): void {
    this.changingMarkerCoordsByAutocomplete$
      .pipe(
        takeUntil(this.destroyed$),
        filter(val => !!val)
      )
      .subscribe((val: 'exist' | 'not-exist') => {
        if (val === 'not-exist') {
          const icon = this.createMarkerIcon(this.selectedServiceLevel, this.defaultIconSize);
          this.createdMarker = new Marker(new LatLng(this.markerLatLngArr[0], this.markerLatLngArr[1]), {
            icon,
            draggable: true,
          });
          this.setPoiDragEvent();
          this.poiLayer.addLayer(this.createdMarker);
          this.map.removeControl(this.poiDrawControlFull);
          this.map.addControl(this.poiDrawControlEdit);
        } else {
          this.createdMarker.setLatLng(new LatLng(this.markerLatLngArr[0], this.markerLatLngArr[1]));
        }
      });
  }

  public setPoiDragEvent(): void {
    this.createdMarker.on('dragend', this.onPoiDrag, this);
  }

  public removePoiDragEvent(): void {
    if (this.createdMarker) {
      this.createdMarker.off('dragend', this.onPoiDrag, this);
    }
  }

  public onPoiDrag(evt: any): void {
    this.dragMark.emit(evt.target.getLatLng());
  }

  ngOnDestroy() {
    this.removeFullScreenEvents();
    this.removePoiDrawEvents();
    this.removePoiDragEvent();
  }

  private listenForFleetBusinessZoneGeoJSON(): void {
    this.poiService
      .getFleetBusinessZoneGeoJSON()
      .pipe(
        takeUntil(this.destroyed$),
        filter(val => !!val)
      )
      .subscribe((res: Feature) => {
        this.businessZoneGeoJSON = this.createZoneService.convertToGeoJSON(res);
        this.businessZoneLayer.addLayer(this.businessZoneGeoJSON);
        this.businessZoneLayer.setStyle(this.layersOnlyBorderOptions);
        this.map.fitBounds(this.businessZoneGeoJSON.getBounds());
        this.poiService.setFleetBusinessZoneGeoJSON(null);
      });
  }

  private initDefaultMapOptionsAndLayers() {
    this.geoOptions = {
      zoom: this.zoom,
      center: latLng(COORDS.FRANCE_CENTER_LAT, COORDS.FRANCE_CENTER_LNG),
      scrollWheelZoom: false,
    };
    this.geoLayers = [tileLayer(COORDS.TILE, { maxZoom: 18, attribution: '...' })];
  }

  private initPoiDrawControl(): void {
    this.poiDrawControlFull = new Control.Draw({
      position: 'topleft',
      draw: {
        polygon: false,
        rectangle: false,
        polyline: false,
        circlemarker: false,
        circle: false,
        marker: {
          icon: new Icon({
            iconUrl: POI_ICON_URL.ALL,
            iconSize: new Point(this.defaultIconSize, this.defaultIconSize),
          }),
        },
      },
      edit: {
        featureGroup: this.poiLayer,
        edit: false,
      },
    });
    this.poiDrawControlEdit = new Control.Draw({
      position: 'topleft',
      draw: {
        polygon: false,
        rectangle: false,
        polyline: false,
        circlemarker: false,
        circle: false,
        marker: false,
      },
      edit: {
        featureGroup: this.poiLayer,
        edit: false,
      },
    });
  }

  private setPoiDrawEvents(): void {
    // @ts-ignore
    this.map.on('draw:created', this.onPoiCreate, this);
    // @ts-ignore
    this.map.on('draw:deleted', this.onPoiDelete, this);
  }

  private removePoiDrawEvents(): void {
    // @ts-ignore
    this.map.off('draw:created', this.onPoiCreate, this);
    // @ts-ignore
    this.map.off('draw:deleted', this.onPoiDelete, this);
  }

  private onPoiCreate(evt: DrawEvents.Created): void {
    if (evt.layerType === 'marker') {
      const defaultMarker = evt.layer as Marker;
      const markerLatLng = defaultMarker.getLatLng();
      const icon = this.createMarkerIcon(this.selectedServiceLevel, this.defaultIconSize);
      this.createdMarker = new Marker(markerLatLng, { icon, draggable: true });
      this.setPoiDragEvent();
      this.poiLayer.addLayer(this.createdMarker);
      this.map.removeControl(this.poiDrawControlFull);
      this.map.addControl(this.poiDrawControlEdit);
      this.createMark.emit(markerLatLng);
      this.cd.detectChanges();
    }
  }

  private createMarkerIcon(serviceLevel: string, iconSize: number): Icon {
    return new Icon({
      iconUrl: serviceLevel ? POI_ICON_URL[serviceLevel] : POI_ICON_URL.ALL,
      iconSize: new Point(iconSize, iconSize),
    });
  }

  private onPoiDelete(evt: DrawEvents.Deleted): void {
    if (evt.layers.getLayers().length) {
      this.map.removeControl(this.poiDrawControlEdit);
      this.map.addControl(this.poiDrawControlFull);
      this.removePoiDragEvent();
      this.createdMarker = null;
      this.deleteMark.emit();
      this.cd.detectChanges();
    }
  }

  private addFullScreenControl(): void {
    control
      .fullscreen({
        position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
        title: this.translate.instant('FULLSCREEN_MODE'), // change the title of the button, default Full Screen
        titleCancel: this.translate.instant('EXIT_FULLSCREEN_MODE'), // change the title of the button when fullscreen is on, default Exit
        // Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force seperate button to detach from zoom buttons, default false
        forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
      })
      .addTo(this.map);
    this.setFullScreenEvents();
  }

  private setFullScreenEvents(): void {
    this.map.on('enterFullscreen', this.invalidateMapSize, this);
    this.map.on('exitFullscreen', this.invalidateMapSize, this);
  }

  private removeFullScreenEvents(): void {
    this.map.off('enterFullscreen', this.invalidateMapSize, this);
    this.map.off('exitFullscreen', this.invalidateMapSize, this);
  }

  private invalidateMapSize(): void {
    this.map.invalidateSize();
  }
}
