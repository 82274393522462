import { NgModule, Pipe, PipeTransform } from '@angular/core';

const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;

@Pipe({
  name: 'azzSecondsToUIOrderTime',
})
export class AzzSecondsToUIOrderTimePipe implements PipeTransform {
  transform(seconds: number, ...args: any[]): string {
    return this.makeSecondsIntoUIFormat(seconds);
  }

  private makeSecondsIntoUIFormat(seconds: number): string {
    if (typeof seconds !== 'number' || isNaN(seconds)) {
      console.warn('[AzzSecondsPipe] provided type is not number');
      return '-';
    }
    const allMinutes = Math.round(seconds / SECONDS_IN_MINUTE);
    const wholeHours = Math.trunc(allMinutes / MINUTES_IN_HOUR);
    const minutesUnderOneHour = allMinutes - wholeHours * MINUTES_IN_HOUR;
    if (seconds < 60) {
      return '< 1 min';
    } else if (seconds >= 3599 && seconds < 3600) {
      return `${allMinutes} min`;
    } else if (allMinutes < MINUTES_IN_HOUR) {
      return `${allMinutes} min`;
    } else if (allMinutes > MINUTES_IN_HOUR && minutesUnderOneHour === 0) {
      return `${wholeHours}h`;
    } else if (allMinutes > MINUTES_IN_HOUR) {
      return `${wholeHours}h ${minutesUnderOneHour} min`;
    } else if (allMinutes === MINUTES_IN_HOUR) {
      return '1h';
    }
  }
}

@NgModule({
  declarations: [AzzSecondsToUIOrderTimePipe],
  exports: [AzzSecondsToUIOrderTimePipe],
})
export class AzzSecondsToUIOrderTimePipeModule {}
