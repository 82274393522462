import { Action, createReducer, on } from '@ngrx/store';

import * as PhoneAdvisorProfileActions from '../actions';

export const phoneAdvisorProfileFeatureKey = 'profile';

export interface State {
  btnLoading: boolean;
}

const initialState: State = {
  btnLoading: false,
};
const phoneAdvisorProfileReducer = createReducer(
  initialState,
  on(PhoneAdvisorProfileActions.saveAvatar, state => ({ ...state, btnLoading: true })),
  on(PhoneAdvisorProfileActions.saveAvatarError, state => ({ ...state, btnLoading: false })),
  on(PhoneAdvisorProfileActions.saveUser, state => ({ ...state, btnLoading: true })),
  on(PhoneAdvisorProfileActions.saveUserSuccess, state => ({ ...state, btnLoading: false })),
  on(PhoneAdvisorProfileActions.saveUserError, state => ({ ...state, btnLoading: false })),
  on(PhoneAdvisorProfileActions.destroyProfile, () => initialState)
);

export const reducer = (state: State | undefined, action: Action) => phoneAdvisorProfileReducer(state, action);
