<div class="row">
  <div class="col-12">
    <div class="azz-header">{{ availableData?.history?.totalElements || 0 }} {{ 'ACTIONS' | translate }}</div>

    <form class="row" *ngIf="isHistoryHiddenForMainPageAndFiltersAvailable()" #f="ngForm">
      <div class="form-group col-md-2">
        <label for="orders-date-start" class="azz-transparent-input-label">{{ 'START_DATE' | translate }}</label>
        <div class="history__date-group">
          <input
            id="orders-date-start"
            type="text"
            name="orders_date_start"
            class="form-control azz-transparent-input azz-validate"
            autocomplete="off"
            (dateTimeChange)="this.watcher$.next(null)"
            readonly
            [(ngModel)]="filterData.dateStart"
            [disabled]="!!filterData.orderG7Id"
            [owlDateTimeTrigger]="dt2Start"
            [owlDateTime]="dt2Start"
            [max]="maxDate"
            [owlDateTimeFilter]="filterDateStart"
          />
          <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1" #dt2Start />
          <button
            *ngIf="filterData?.dateStart"
            type="button"
            class="remove-btn"
            (click)="resetDate('dateStart')"
          ></button>
        </div>
      </div>
      <div class="form-group col-md-2">
        <label for="orders-date-end" class="azz-transparent-input-label">{{ 'END_DATE' | translate }}</label>
        <div class="history__date-group">
          <input
            id="orders-date-end"
            type="text"
            name="orders_date_end"
            class="form-control azz-transparent-input azz-validate"
            autocomplete="off"
            (dateTimeChange)="this.watcher$.next(null)"
            readonly
            [(ngModel)]="filterData.dateEnd"
            [disabled]="!!filterData.orderG7Id"
            [owlDateTimeTrigger]="dt2End"
            [owlDateTime]="dt2End"
            [max]="maxDate"
            [owlDateTimeFilter]="filterDateEnd"
          />
          <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1" #dt2End />
          <button *ngIf="filterData?.dateEnd" type="button" class="remove-btn" (click)="resetDate('dateEnd')"></button>
        </div>
      </div>
      <div class="form-group col-md-3">
        <label for="orders-search" class="azz-transparent-input-label">
          {{ 'FO_SEARCH_TRIP_LABEL' | translate }}
        </label>
        <div class="azz-has-addon">
          <div class="azz-search-addon"></div>
          <input
            id="orders-search"
            name="orders_search"
            type="text"
            [pattern]="numbersRegExp"
            (input)="loadHistoryIfReferenceFormatValid()"
            class="form-control azz-transparent-input azz-search-field"
            [(ngModel)]="filterData.orderG7Id"
          />
        </div>
      </div>
      <div class="form-group col-md-2">
        <label for="partnerId" class="azz-transparent-input-label">{{ 'AUTHORIZATION_NUMBER' | translate }}</label>
        <input
          id="partnerId"
          name="orders_partnerId"
          type="text"
          (input)="this.watcher$.next(null)"
          [disabled]="!!filterData.orderG7Id"
          class="form-control azz-transparent-input azz-search-field"
          [(ngModel)]="filterData.partnerId"
        />
      </div>
      <div class="form-group col-md-3">
        <azz-dropdown
          azzId="select-fleet"
          [azzLabel]="'CITY' | translate"
          [isCityAllowed]="true"
          azzOptionFormat="address.city"
          [onlyG7FleetId]="onlyG7FleetId"
          [azzPlaceholder]="'SUPER_TAXI_SELECT_FLEET_PLACEHOLDER' | translate"
          [azzDataRequest]="loadFleets_SavedContext()"
          [inputCurrentValue]="filterData.fleet"
          (azzSelect)="onFleetSelect($event)"
          defaultInputValue="GO_ORDER_ALL_PARTNERS"
          [azzNullAsAll]="true"
          [ngDisabled]="!!filterData.orderG7Id"
          [azzAllOptionsLabel]="'GO_ORDER_ALL_PARTNERS' | translate"
          [isPartnerRemovingIsPossible]="true"
        >
        </azz-dropdown>
      </div>
    </form>

    <azz-loading-block [isLoading]="loadingIndicator">
      <div class="azz-table-wrapper">
        <table class="table table-hover azz-table">
          <thead>
            <tr class="d-table-row">
              <th class="col-1" (click)="sortColumn('date')">
                <span *ngIf="isSortedColumn('date')" [azzArrow]="filterData?.direction"> </span>
                {{ 'DATE' | translate }}
              </th>
              <th class="col-1" *ngIf="!isOrderNumberHidden">
                {{ 'REFERENCE_LABEL' | translate }}
              </th>
              <th class="col-1">{{ 'AUTHORIZATION_NUMBER' | translate }}</th>
              <th class="col-4" [ngClass]="{ 'col-5': isOrderNumberHidden }">
                {{ 'GN_ACTION_COL_HEADER' | translate }}
              </th>
              <th class="col-2">{{ 'GN_USER_COL_HEADER' | translate }}</th>
              <th class="col-2">{{ 'CITY' | translate }}</th>
              <th class="col-1">{{ 'GO_ORDERS_G7ID_HEADER' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="availableData?.history?.content?.length > 0">
            <!-- IE scrolling fix -->
            <tr
              class="azz-clickable"
              *ngFor="let item of availableData?.history?.content; trackBy: trackByFunc"
              (click)="onHistoryClick(item.orderNumber)"
            >
              <td>
                {{ item.date | date: 'dd.MM.yyyy' }},
                {{ item.date | date: 'HH:mm' }}
              </td>
              <td *ngIf="!isOrderNumberHidden">{{ item?.orderNumber }}</td>
              <td>{{ item?.partnerId }}</td>
              <td>
                <ul class="azz-g7-marker-list">
                  <li *ngFor="let action of item.actions">
                    {{ action }}
                  </li>
                </ul>
              </td>
              <td>{{ item?.authorName }}</td>
              <td>{{ item?.fleetCity }}</td>
              <td>{{ item?.fleetG7Id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <azz-table-no-matches [azzVisible]="!availableData.history?.content?.length"></azz-table-no-matches>
      <azz-pagination
        (goToPrevPage)="prevPage()"
        (goToNextPage)="nextPage()"
        [prevDisabled]="isPrevDisabled()"
        [nextDisabled]="isNextDisabled()"
      >
      </azz-pagination>
    </azz-loading-block>
  </div>
</div>
