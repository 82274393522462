import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { IDriverStatistics } from '../../models/driver.model';
import {
  IGetOrdersStatsParams,
  IOrdersStatsData,
  IStatsFromToParams,
  IStatsPeriodParams,
} from '../../models/stats.model';

@Injectable({ providedIn: 'root' })
export class StatisticsService extends ApiService {
  private readonly STATISTICS_URL = 'statistics/api/v1/statistics/';

  constructor(private readonly httpClient: HttpClient) {
    super(httpClient);
  }

  public getCurrentRevenue(params: IStatsPeriodParams): Observable<IDriverStatistics> {
    return this.get(`${this.STATISTICS_URL}current/revenue`, params);
  }

  public getCurrentNewDrivers(params: IStatsPeriodParams): Observable<IDriverStatistics> {
    return this.get(`${this.STATISTICS_URL}current/new-drivers`, params);
  }

  public getCurrentCompletedTrips(params: IStatsPeriodParams): Observable<IDriverStatistics> {
    return this.get(`${this.STATISTICS_URL}current/completed-trips`, params);
  }

  public getPeriodRevenue(params: IStatsFromToParams) {
    return this.get(`${this.STATISTICS_URL}period/revenue`, params);
  }

  public getPeriodNewDrivers(params: IStatsFromToParams) {
    return this.get(`${this.STATISTICS_URL}period/new-drivers`, params);
  }

  public getPeriodCompletedTrips(params: IStatsFromToParams) {
    return this.get(`${this.STATISTICS_URL}period/completed-trips`, params);
  }

  public getDriverCurrentRevenue(params: IStatsPeriodParams): Observable<IDriverStatistics> {
    return this.get(`${this.STATISTICS_URL}driver/current/revenue`, params);
  }

  public getDriverCurrentCompletedTrips(params: IStatsPeriodParams): Observable<IDriverStatistics> {
    return this.get(`${this.STATISTICS_URL}driver/current/completed-trips`, params);
  }

  public getOrdersStats(params: IGetOrdersStatsParams): Observable<IOrdersStatsData<string>> {
    return this.get(`${this.STATISTICS_URL}period/trips`, params);
  }
}
