/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerCivilityEnumWebApiModel } from './customer-civility-enum-web.api-model';
import { CustomerBlockReasonEnumWebApiModel } from './customer-block-reason-enum-web.api-model';

/**
 * object with customer info used in customers search response
 */
export interface CustomerItemResponseWebApiModel {
  /**
   * customer ID
   */
  id: string;
  /**
   * customer name
   */
  firstName?: string;
  /**
   * customer last name
   */
  lastName: string;
  civility?: CustomerCivilityEnumWebApiModel;
  /**
   * customer creation time in ISO 8601 format (YYYY-MM-DDThh:mm:ss±hh)
   */
  creationTime?: string;
  /**
   * last modification time in ISO 8601 format (YYYY-MM-DDThh:mm:ss±hh)
   */
  modificationTime?: string;
  /**
   * notify customer by email
   */
  emailNotificationEnabled?: boolean;
  /**
   * notify customer by sms
   */
  smsNotificationEnabled?: boolean;
  /**
   * subscriber code
   */
  subscriberCode?: string;
  /**
   * customer phones
   */
  phones: Array<string>;
  /**
   * customer emails
   */
  emails?: Array<string>;
  /**
   * customer status. Possible values are ACTIVE, BLOCKED
   */
  status?: string;
  /**
   * block time if status is BLOCKED in ISO 8601 format (YYYY-MM-DDThh:mm:ss±hh)
   */
  blockTime?: string;
  blockReason?: CustomerBlockReasonEnumWebApiModel;
}
export namespace CustomerItemResponseWebApiModel {}
