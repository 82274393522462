<form class="card azz-multiple autocomplete" [formGroup]="form" #formElemRef>
  <ng-template #rt let-r="result" let-t="term">
    <ngb-highlight *ngIf="r" [result]="r.properties.name + ', ' + r.properties.city" [term]="t"></ngb-highlight>
    <div *ngIf="!r">{{ 'ADDRESS_NOT_FOUND' | translate }}</div>
  </ng-template>
  <div class="d-flex align-items-center">
    <input
      (blur)="enableLoadingIndicator(false)"
      [id]="ngId"
      type="text"
      formControlName="searchField"
      [ngbTypeahead]="search"
      (selectItem)="onAddressSelect($event)"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      class="form-control azz-transparent-input azz-validate"
    />
    <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
  </div>
  <div class="form-control__error" *ngIf="form.controls.searchField.touched && form.controls.searchField.errors">
    <small *ngIf="form.controls.searchField.errors?.noSelectedAddress">
      {{ 'PA_CHOOSE_ADDRESS_NOT_FROM_LIST' | translate }}
    </small>
  </div>
</form>
