<div class="azz-editable-price" (click)="toggleEditMode(true); $event.stopPropagation()">
  <div *ngIf="!isEditModeEnabled" [class.azz-warning]="toll === 0">
    {{ toll + ' &euro;' }}
  </div>
  <input
    *ngIf="isEditModeEnabled"
    id="price"
    name="price"
    type="text"
    azzBlurOnTabClick
    class="form-control"
    [(ngModel)]="toll"
    (keydown)="keyDown($event)"
    (blur)="onBlur()"
  />
</div>
