<div class="message__container">
  <img class="message__avatar" [src]="getAvatar(message)" />
  <div class="message__block">
    <div class="message__author-date">
      <div class="message__author">{{ getName(message) | translate }}</div>
      <div class="message__date">{{ message.dateTime | date: 'dd.MM.yyyy HH:mm:ss' }}</div>
    </div>
    <div [innerHTML]="message.content"></div>
  </div>
</div>
