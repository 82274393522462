<div class="azz-table-wrapper">
  <table class="table table-hover azz-table">
    <thead>
      <tr class="d-table-row">
        <th (click)="sortColumn('date')" class="col-1">
          <span *ngIf="isSortedColumn('date')" [azzArrow]="filterData?.direction"></span>
          {{ 'DATE' | translate }}
        </th>
        <th class="col-1">{{ 'REFERENCE_LABEL' | translate }}</th>
        <th class="col-1">{{ 'AUTHORIZATION_NUMBER' | translate }}</th>
        <th class="col-2">{{ 'FO_STATUS_COL_HEADER' | translate }}</th>
        <th class="col-2">{{ 'FO_DRIVER_COL_HEADER' | translate }}</th>
        <th class="col-2">{{ 'CLIENT' | translate }}</th>
        <th class="col-2">{{ 'FO_APPOINTMENT_ADDRESS_COL_HEADER' | translate }}</th>
        <th (click)="sortColumn('price')" class="col-1">
          <span *ngIf="isSortedColumn('price')" [azzArrow]="filterData?.direction"></span>
          {{ 'TOTAL_AMOUNT' | translate }}
        </th>
      </tr>
    </thead>
    <tbody *ngIf="ordersData?.content?.length > 0">
      <!-- IE scrolling fix -->
      <tr
        class="azz-clickable"
        (click)="navigateToOrderDetailsPage($event, order.id)"
        *ngFor="let order of ordersData?.content; trackBy: trackByFunc"
      >
        <td>
          <span *ngIf="order?.date">
            {{ order.date | date: 'dd.MM.yyyy' }},<br />
            {{ order.date | date: 'HH:mm' }}
          </span>
        </td>
        <td>{{ order?.reference }}</td>
        <td>{{ order?.partnerId }}</td>
        <td>{{ 'CORE_ORDER_STATUS_' + order.status | translate }}</td>
        <td>
          <span class="no-general-navigation" (click)="navigateToDriverDetailsPage(order.driverId)">
            <span class="no-general-navigation" *ngIf="order?.driver?.firstName || order?.driver?.lastName">
              {{ order?.driver?.firstName }} {{ order?.driver?.lastName }}<br />
            </span>
            <span class="no-general-navigation" *ngIf="order?.driver?.taxiNumber">
              {{ order?.driver?.taxiNumber }}<br />
            </span>
            <span class="no-general-navigation"> {{ order?.driver?.phoneCode }}{{ order?.driver?.phoneNumber }} </span>
          </span>
        </td>
        <td>
          <span class="no-general-navigation" (click)="navigateToCustomerDetailsPage(order.customer.id)">
            {{ order?.customer?.name }}<br />
            {{ order?.customer?.phoneNumber }}
          </span>
        </td>
        <td>
          <span *ngIf="order?.appointmentAddress?.poi?.label">
            {{ order?.appointmentAddress?.poi?.label }}
          </span>
          <span *ngIf="!order?.appointmentAddress?.poi?.label">
            {{ order?.appointmentAddress?.number }}
            {{ order?.appointmentAddress?.street }},
            {{ order?.appointmentAddress?.city }}
          </span>
          -> <br />
          <span *ngIf="order?.destinationAddress?.poi?.label">
            {{ order?.destinationAddress?.poi?.label }}
          </span>
          <span *ngIf="!order?.destinationAddress?.poi?.label">
            {{ order?.destinationAddress?.number }}
            {{ order?.destinationAddress?.street }},
            {{ order?.destinationAddress?.city }}
          </span>
        </td>
        <td [class.azz-color-red]="order.price === 0">
          {{ order.price !== -1 ? order.price + ' \u20AC' : ('FO_TAXIMETER_VALUE' | translate) }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
