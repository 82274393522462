import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LogoutPopupComponent } from './logout-popup.component';
import { AzzRotateIndicatorModule } from '../../../../common/components/rotate-indicator/rotate-indicator.module';

@NgModule({
  imports: [CommonModule, TranslateModule, AzzRotateIndicatorModule],
  declarations: [LogoutPopupComponent],
  exports: [LogoutPopupComponent],
})
export class LogoutPopupModule {}
