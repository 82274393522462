import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DriverTagsStore } from './store';

@Component({
  selector: 'app-driver-tags',
  templateUrl: './driver-tags.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTagsComponent implements OnInit, OnDestroy {
  @Input() driverId: number;
  @Input() azzReadOnly: boolean;
  public driverTagsIds$: Observable<string[]>;
  public selectedTagsIdsMap$: Observable<{ [key: string]: boolean }>;
  public loading$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.store.dispatch(DriverTagsStore.init());
    this.initData();
    this.getData();
  }

  public toggleTag(id: string): void {
    this.store.dispatch(DriverTagsStore.toggleTag({ id }));
    this.store.dispatch(DriverTagsStore.updateDriverTags({ driverId: this.driverId }));
  }

  ngOnDestroy() {
    this.store.dispatch(DriverTagsStore.destroy());
  }

  private initData(): void {
    this.driverTagsIds$ = this.store.pipe(select(DriverTagsStore.driverTagsIds));
    this.selectedTagsIdsMap$ = this.store.pipe(select(DriverTagsStore.selectedTagsIdsMap));
    this.loading$ = this.store.pipe(select(DriverTagsStore.loading));
  }

  private getData(): void {
    this.store.dispatch(DriverTagsStore.getCombinedTags({ driverId: this.driverId }));
  }
}
