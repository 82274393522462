import { IUserNotificationsData } from '@core/models/user-notification.model';
import { Action, createReducer, on } from '@ngrx/store';

import * as UserNotificationsActions from '../actions/user-notifications.actions';

export const userNotificationsFeatureKey = 'user-notifications';

export interface State {
  notificationsData: IUserNotificationsData;
}

export const initialState: State = {
  notificationsData: null,
};

const index = createReducer(
  initialState,
  on(
    UserNotificationsActions.getUserNotificationsSuccess,
    (state, { notificationsData }: { notificationsData: IUserNotificationsData }) => ({
      ...state,
      notificationsData,
    })
  ),
  on(UserNotificationsActions.getUserNotificationsError, state => ({
    ...state,
    notificationsData: null,
  })),
  on(UserNotificationsActions.readUserNotificationSuccess, (state, { id }) => ({
    ...state,
    notificationsData: {
      ...state.notificationsData,
      notifications: state.notificationsData.notifications.map(i => (i.id === id ? { ...i, read: true } : i)),
      unreadAmount: state.notificationsData.unreadAmount - 1,
    },
  }))
);

export const reducer = (state: State | undefined, action: Action) => index(state, action);
