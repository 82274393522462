import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { COLOR_MANAGEMENT } from '../../../constant/color.management.constant';
import { IColorManagementFull } from '../../../models/color-management.model';
import { IDriverUserInfo } from '../../../models/user-info.model';
import { FormService } from '../../../utils/form-service';

@Component({
  selector: 'app-driver-details-color-management',
  templateUrl: './driver-details-color-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsColorManagementComponent {
  @Input() form: FormGroup;
  @Input() driver: IDriverUserInfo;
  public colorManagement: IColorManagementFull;

  constructor(
    private readonly formService: FormService,
    private readonly cd: ChangeDetectorRef
  ) {}

  get currentColor() {
    return this.form.value.currentColor;
  }

  @Input() set colorManagementData(value: IColorManagementFull) {
    if (value) {
      this.colorManagement = value;
      setTimeout(() => {
        // cause currentColor getter is used in parent component as well
        this.setCurrentColor();
        this.setCurrentColorValidator();
      });
    }
  }

  public getColorClass(colorId) {
    const classes = {};
    classes[COLOR_MANAGEMENT().BY_ID[colorId].class] = true;
    return classes;
  }

  public setCurrentColor(): void {
    const { halfColor, color } = this.driver?.parameters || {};
    const currentColor = this.colorManagement.halfColorEnabled ? halfColor : color;
    this.form.patchValue({ currentColor });
  }

  public setCurrentColorValidator(): void {
    if (this.colorManagement.halfColorEnabled || this.colorManagement.colorEnabled) {
      this.formService.setValidators(this.form, 'currentColor', [Validators.required]);
    }
  }

  public markFieldsAsTouched(): void {
    this.form.get('currentColor').markAsTouched();
    this.cd.detectChanges();
  }
}
