import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { VehicleInfoServiceVehicleTypesComponent } from './service-vehicle-types/vehicle-info-service-vehicle-types.component';
import { VehicleInfoStore } from './store';
import { IColor, IEnergy, IMake, IModel, IVehicle } from '../../models/vehicle.model';
import { FormService } from '../../utils/form-service';
import { numUpCaseFrCharsValidator } from '../../utils/validators';

@Component({
  selector: 'app-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleInfoComponent implements OnInit, OnDestroy {
  @Input() driverVehicleTypeTagsIds: string[];
  @Input() driverServiceTypeTagsIds: string[];
  @Input() showTaxiNumber = true;
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @ViewChild(VehicleInfoServiceVehicleTypesComponent)
  serviceVehicleTypes: VehicleInfoServiceVehicleTypesComponent;
  public form: FormGroup;
  public makes$: Observable<IMake[]>;
  public models$: Observable<IModel[]>;
  public energies$: Observable<IEnergy[]>;
  public colors$: Observable<IColor[]>;
  public productionYears$: Observable<number[]>;
  public serviceTypes$: Observable<{ id: string; value: string[] }[]>;
  public vehicleTypes$: Observable<{ id: string; value: string[] }[]>;
  public taxiNumber: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly formService: FormService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.initForm();
  }

  @Input() set vehicle(value: IVehicle) {
    if (value) {
      this.handleVehicle(value);
    }
  }

  @Input() set ngDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    }
  }

  @Input() set taxiNumberData(value: string) {
    if (value) {
      this.handleTaxiNumber(value);
    }
  }

  ngOnInit() {
    this.store.dispatch(VehicleInfoStore.init());
    this.initData();
    this.getData();
  }

  public getServiceAndVehicleTypeTagsId() {
    const { serviceType, vehicleType } = this.form.getRawValue();
    return [...serviceType.value, ...vehicleType.value];
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public changeModel(): void {
    this.form.controls.model.reset();
    this.getModels();
  }

  public trackByFn(index: number, item: any): string | number {
    if (!item) {
      return null;
    }
    return item.id;
  }

  public markFieldsAsTouched(): void {
    this.form.markAllAsTouched();
    this.cd.markForCheck();
    this.serviceVehicleTypes.markForCheck();
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  public generatePayload(): Partial<IVehicle> {
    const { make, model, energy, color, productionYear, plate } = this.form.getRawValue();
    return { make, model, energy, color, productionYear, plate };
  }

  ngOnDestroy() {
    this.store.dispatch(VehicleInfoStore.destroy());
  }

  private initForm(): void {
    this.form = this.fb.group({
      make: [null, [Validators.required]],
      model: [null, [Validators.required]],
      energy: [null, [Validators.required]],
      color: [null, [Validators.required]],
      serviceType: [null, [Validators.required]],
      vehicleType: [null, [Validators.required]],
      productionYear: [null, [Validators.required]],
      plate: [null, [Validators.required, numUpCaseFrCharsValidator]],
      taxiNumber: [{ value: null, disabled: true }],
    });
  }

  private initData(): void {
    this.makes$ = this.store.pipe(select(VehicleInfoStore.makes));
    this.models$ = this.store.pipe(select(VehicleInfoStore.models));
    this.energies$ = this.store.pipe(select(VehicleInfoStore.energies));
    this.colors$ = this.store.pipe(select(VehicleInfoStore.colors));
    this.productionYears$ = this.store.pipe(select(VehicleInfoStore.productionYears));
    this.serviceTypes$ = this.store.pipe(select(VehicleInfoStore.serviceTypes));
    this.vehicleTypes$ = this.store.pipe(select(VehicleInfoStore.vehicleTypes));
  }

  private getData(): void {
    this.store.dispatch(VehicleInfoStore.getMakes());
    this.store.dispatch(VehicleInfoStore.getEnergies());
    this.store.dispatch(VehicleInfoStore.getColors());
  }

  private getModels(): void {
    this.store.dispatch(VehicleInfoStore.getModels({ makeId: this.form.value.make?.id }));
  }

  private handleVehicle(value: IVehicle): void {
    this.updateForm(value);
    this.getModels();
  }

  private handleTaxiNumber(value: string): void {
    this.taxiNumber = value;
    this.form.patchValue({ taxiNumber: value });
  }

  private updateForm(vehicle: IVehicle): void {
    const { make, model, energy, color, productionYear, plate } = vehicle;
    this.form.patchValue({ make, model, energy, color, productionYear, plate });
  }
}
