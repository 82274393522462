/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerCivilityEnumWebApiModel } from './customer-civility-enum-web.api-model';

/**
 * object to specify customer info for customer creation
 */
export interface CustomerCreateRequestWebApiModel {
  /**
   * customer name
   */
  firstName?: string;
  /**
   * customer last name
   */
  lastName: string;
  civility?: CustomerCivilityEnumWebApiModel;
  /**
   * notify customer by email
   */
  emailNotificationEnabled?: boolean;
  /**
   * notify customer by sms
   */
  smsNotificationEnabled?: boolean;
  /**
   * subscriber code
   */
  subscriberCode?: string;
  /**
   * list of customer phones
   */
  phones: Array<string>;
  /**
   * list of customer emails
   */
  emails?: Array<string>;
}
export namespace CustomerCreateRequestWebApiModel {}
