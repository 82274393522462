import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IG7OrderHistoryData, IG7OrderHistoryParams } from '../../models/g7-order-history.model';
import {
  G7Order,
  IBeforeDispatchOrdersData,
  IBeforeDispatchParams,
  IDispatchToSaga7Body,
  IG7OrderDispatchSummaryParams,
  IG7OrderMultiReturnBody,
  IG7OrderMultiReturnRes,
  IG7OrderMultiValidationBody,
  IG7OrderMultiValidationRes,
  IG7OrdersCsvParams,
  IG7OrdersData,
  IG7OrderSingleValidateBody,
  IG7OrdersParams,
  IMultiValidationG7Order,
  ITransmissionHistoryCsvParams,
} from '../../models/g7-order.model';
import { ITip } from '../../models/tip-grid.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class G7OrdersService extends ApiService {
  public G7_API_URL = 'order/api/v1/orderG7';
  public G7_TIP_URL = 'user/api/v1/fleet/g7';
  public G7_HISTORY_URL = 'order/api/v1/orderG7History/list';
  public G7_SAGA_VALIDATION = 'api-ws/api/v1/orderG7/saga7/';
  public G7_TOTAL_URL = 'order/api/v1/orderG7/fleet/total';

  constructor(http: HttpClient) {
    super(http);
  }

  public getHistory(params: Partial<IG7OrderHistoryParams>): Observable<IG7OrderHistoryData> {
    return this.get(this.G7_HISTORY_URL, params);
  }

  public getOrders(params: Partial<IG7OrdersParams>): Observable<IG7OrdersData> {
    return this.get(this.G7_API_URL, params);
  }

  public getOrder(id: number): Observable<G7Order> {
    return this.get(`${this.G7_API_URL}/${id}`);
  }

  public validateG7Order(id: number, body: IG7OrderSingleValidateBody): Observable<any> {
    return this.put(`${this.G7_API_URL}/${id}/validate`, body);
  }

  public multiValidateG7(body: Partial<IG7OrderMultiValidationBody>): Observable<IG7OrderMultiValidationRes[]> {
    return this.put(`${this.G7_API_URL}/validate`, body);
  }

  public multiReturnG7(body: Partial<IG7OrderMultiReturnBody>): Observable<IG7OrderMultiReturnRes[]> {
    return this.put(`${this.G7_API_URL}/validate`, body);
  }

  public saveG7orders(id: string | number, data: any, path: string): Observable<any> {
    return this.put(`${this.G7_API_URL}/${id}/${path}`, data);
  }

  public exportG7OrdersCsv(params: Partial<IG7OrdersCsvParams>) {
    return `${this.G7_API_URL}/csv${this.createQueryParams(params)}`;
  }

  public getOrdersAttachments(id: number): Observable<any> {
    return this.get(`${this.G7_API_URL}/${id}/attachments`);
  }

  public getG7Tips(): Observable<ITip[]> {
    return this.get(`${this.G7_TIP_URL}/g7TipList`);
  }

  public setG7Tips(payload: any): Observable<any> {
    return this.put(`${this.G7_TIP_URL}/bind`, null, payload);
  }

  public dispatchToSaga7(body: Partial<IDispatchToSaga7Body>): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`${this.G7_SAGA_VALIDATION}upload`, body, {}, headers);
  }

  public getBeforeDispatchOrdersData(params: Partial<IBeforeDispatchParams>): Observable<IBeforeDispatchOrdersData> {
    return this.get(this.G7_TOTAL_URL, params);
  }

  public getG7OrderDispatchSummary(params: IG7OrderDispatchSummaryParams): Observable<any> {
    return this.get(`${this.G7_SAGA_VALIDATION}transmission/history`, params);
  }

  public exportTransmissionHistoryCsv(params?: Partial<ITransmissionHistoryCsvParams>) {
    const queryParams = this.createQueryParams(params);
    return `${this.G7_SAGA_VALIDATION}transmission/history/csv${queryParams}`;
  }

  public getMultiValidationOrders(ordersIds: number[]): Observable<IMultiValidationG7Order[]> {
    return this.get(`${this.G7_API_URL}/validate`, { id: ordersIds });
  }

  public getMultiReturnnOrders(ordersIds: number[]): Observable<IMultiValidationG7Order[]> {
    return this.get(`${this.G7_API_URL}/validate`, { id: ordersIds });
  }

  public saveFleetOrderWithValidation(orderId: number, params: any): Observable<any> {
    return this.put(`${this.G7_API_URL}/${orderId}`, params);
  }
}
