import { Directive, HostListener, Input, NgModule } from '@angular/core';

import { CreateZoneService } from '../../core/services/map/create-zone.service';

@Directive({
  selector: '[azzShowLayerOnHover]',
})
export class ShowLayerOnHoverDirective {
  @Input() leafletId: number;
  @Input() layer: any;
  @Input() zoneTypeGeoJSON: string;

  constructor(private readonly createZoneService: CreateZoneService) {}

  @HostListener('mouseover', ['$event'])
  onMouseOver() {
    this.createZoneService.setLayerHover(this.zoneTypeGeoJSON, this.leafletId, true);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.createZoneService.setLayerHover(this.zoneTypeGeoJSON, this.leafletId, false);
  }
}

@NgModule({
  declarations: [ShowLayerOnHoverDirective],
  exports: [ShowLayerOnHoverDirective],
})
export class ShowLayerOnHoverModule {}
