import { AuthStore } from '@auth/store';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { IPaginationState } from '../../../../models/common.model';
import { IExceptionData, IExceptionDataParams } from '../../../../models/exception.model';
import * as ExceptionsActions from '../actions';

export const exceptionsFeatureKey = 'exceptions';

export interface State {
  exceptionsData: IExceptionData;
  loading: boolean;
  filterModel: Partial<IExceptionDataParams>;
  pagination: IPaginationState;
}

export const initialState: State = {
  exceptionsData: null,
  loading: false,
  filterModel: {
    type: null,
  },
  pagination: {
    page: 0,
    size: 15,
  },
};

const index = createReducer(
  initialState,
  on(ExceptionsActions.getExceptions, state => ({ ...state, loading: true })),
  on(ExceptionsActions.getExceptionsSuccess, (state, { exceptionsData }) => ({
    ...state,
    exceptionsData,
    loading: false,
  })),
  on(ExceptionsActions.getExceptionsError, state => ({
    ...state,
    exceptionsData: null,
    loading: false,
  })),
  on(ExceptionsActions.updateFilterModel, (state, { filterModel }) => ({
    ...state,
    filterModel: { ...state.filterModel, ...filterModel },
  })),
  on(ExceptionsActions.updatePagination, (state, { pagination }) => ({
    ...state,
    pagination: { ...state.pagination, ...pagination },
  })),
  on(ExceptionsActions.destroy, () => initialState)
);

export const reducer = (state: State | undefined, action: Action) => index(state, action);

export const clearState =
  (r: ActionReducer<State>): ActionReducer<State> =>
  (state, action) => {
    if (action?.type === AuthStore.logoutSuccess.type) {
      return r(undefined, action);
    }

    return r(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
