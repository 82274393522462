<section>
  <div class="azz-header">{{ 'RATING' | translate }}</div>

  <div class="form-group">
    <div class="azz-transparent-input-label">{{ 'RATING' | translate }}</div>
    <div class="azz-transparent-input-value">
      <ngb-rating
        class="azz-driver-rating"
        [(rate)]="rating"
        [max]="5"
        [resettable]="true"
        [readonly]="readonly"
        (rateChange)="onRatingChange(rating)"
      >
        <ng-template let-fill="fill" let-index="index">
          <span class="glyphicon glyphicon-thumbs-up azz-margin-right-5px" [class.glyphicon-thumbs-o-up]="fill === 100">
          </span>
        </ng-template>
      </ngb-rating>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 form-group">
      <div class="azz-transparent-input-label">{{ 'APP_VERSION' | translate }}</div>
      <div class="azz-transparent-input azz-color-black">
        <span *ngIf="driverData?.status === activeDriverStatus">
          {{ driverData?.appVersion }}
        </span>
      </div>
    </div>
    <div class="col-md-4 form-group">
      <div class="azz-transparent-input-label">{{ 'COMPLETED_TRIPS' | translate }}</div>
      <div class="azz-transparent-input azz-color-black">{{ ordersStats?.finished || 0 }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 form-group">
      <div class="azz-transparent-input-label">{{ 'FD_DID_NOT_RESPOND_LABEL' | translate }}</div>
      <div class="azz-transparent-input azz-color-black">{{ ordersStats?.didNotRespond || 0 }}</div>
    </div>
    <div class="col-md-4 form-group">
      <div class="azz-transparent-input-label">{{ 'FD_REJECTED_LABEL' | translate }}</div>
      <div class="azz-transparent-input azz-color-black">{{ ordersStats?.rejected || 0 }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 form-group">
      <div class="azz-transparent-input-label">{{ 'FD_CLIENT_CANCELED_LABEL' | translate }}</div>
      <div class="azz-transparent-input azz-color-black">{{ ordersStats?.cancelled || 0 }}</div>
    </div>
    <div class="col-md-4 form-group">
      <div class="azz-transparent-input-label">{{ 'FD_CLIENT_NO_SHOW_LABEL' | translate }}</div>
      <div class="azz-transparent-input azz-color-black">{{ ordersStats?.clientNotShow || 0 }}</div>
    </div>
  </div>
</section>
