import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { G7DenyValidationDialogComponent } from './g7-deny-validation-dialog/g7-deny-validation-dialog.component';
import { AzzRotateIndicatorModule } from '../rotate-indicator/rotate-indicator.module';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, AzzRotateIndicatorModule],
  declarations: [ConfirmDialogComponent, G7DenyValidationDialogComponent],
  exports: [ConfirmDialogComponent, G7DenyValidationDialogComponent],
})
export class DialogModule {}
