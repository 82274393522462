import { PACompetencyLevelName, ROLE } from '@core/constant';
import { DRIVER_STATUS } from '@core/constant/driver-status.constant';
import { ICurrentUserInfo, IFleetUserInfo, IGeneralUserInfo } from '@core/models/user-info.model';
import { createSelector } from '@ngrx/store';

import { selectAuth } from './auth.selectors';
import * as AuthReducers from '../reducers';
import * as AuthInfoReducer from '../reducers/auth-info.reducer';

const NO_RATING_USER_ROLES = {
  [ROLE.ADMIN]: true,
  [ROLE.ADMIN_DEACTIVATED]: true,
  [ROLE.G7]: true,
  [ROLE.G7_INACTIVE]: true,
  [ROLE.G7_INCOMPLETE]: true,
};

const DRIVER_ROLES = {
  [ROLE.DRIVER]: true,
  [ROLE.DRIVER_INACTIVE]: true,
  [ROLE.DRIVER_INCOMPLETE]: true,
};

const isDriverRole = (userInfo: IGeneralUserInfo): boolean => userInfo.roles.some(role => !!DRIVER_ROLES[role]);

const hasRating = (userInfo: IGeneralUserInfo): boolean => userInfo.roles.some(role => !NO_RATING_USER_ROLES[role]);

export const authInfo = createSelector(
  selectAuth,
  (state: AuthReducers.AuthState) => state[AuthInfoReducer.authInfoFeatureKey]
);

export const userInfoSelector = createSelector(authInfo, (state: AuthInfoReducer.State) => state?.userInfo);

export const userSelector = createSelector(userInfoSelector, state => state?.user);

export const userRole = createSelector(userSelector, state => state?.roles);

export const fleetSelector = createSelector(userInfoSelector, state => state?.fleet);

export const selectIsG7User = createSelector(
  userSelector,
  state => (state?.roles || []).indexOf('ROLE_G7_BILLING_USER') !== -1
);

export const isPhoneAdvisor = createSelector(
  userSelector,
  state => (state?.roles || []).indexOf('ROLE_PHONE_ADVISOR') !== -1
);

export const isDispatcher = createSelector(
  userSelector,
  state => (state?.roles || []).indexOf('ROLE_DISPATCHER') !== -1
);

export const isDriver = createSelector(userSelector, state => (state?.roles || []).indexOf('ROLE_DRIVER') !== -1);

export const isCtiEnabled = createSelector(userSelector, state => {
  const fleetUserInfo = state as IFleetUserInfo;
  return fleetUserInfo ? fleetUserInfo?.fleet?.ctiEnabled : false;
});

export const competenceLevel = createSelector(userSelector, state =>
  state ? ('competenceLevel' in state ? (state.competenceLevel as PACompetencyLevelName) : null) : null
);

export const colorManagementEnabled = createSelector(fleetSelector, state => state?.colorManagementEnabled || false);

export const isUserActive = createSelector(userSelector, state =>
  (state?.roles || []).every(role => role.indexOf('INACTIVE') === -1)
);

export const isDriverBlocked = createSelector(
  isDriver,
  userSelector,
  (isDriverUser, state) => isDriverUser && 'status' in state && state?.status === DRIVER_STATUS.BLOCKED
);

export const selectAuthError = createSelector(authInfo, (state: AuthInfoReducer.State) => state.error);

export const selectAuthLoading = createSelector(authInfo, (state: AuthInfoReducer.State) => state.loading);

export const selectLogoutPopupShown = createSelector(
  authInfo,
  (state: AuthInfoReducer.State) => state.isLogoutPopupShown
);

export const selectAuthRequestedEmail = createSelector(authInfo, (state: AuthInfoReducer.State) => state.email);

export const selectUserRatingData = createSelector(userInfoSelector, (state: ICurrentUserInfo | null) => {
  if (state && hasRating(state.user)) {
    const isDriverUser = isDriverRole(state.user);
    const { fleet, user } = state;
    return {
      logo: fleet?.logo,
      fullName: isDriverUser ? `${user?.firstName} ${user?.lastName}` : `${fleet?.name}`,
      rating: isDriverUser && 'rating' in user ? user?.rating : fleet.rating,
      ratingMessage:
        isDriverUser && 'status' in user && user.status === DRIVER_STATUS.BLOCKED ? 'DN_USER_BLOCKED_MESSAGE' : null,
      isDriver: isDriverUser,
    };
  } else {
    return null;
  }
});
