import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CustomersEffects } from './effects';
import * as CustomersReducer from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(CustomersReducer.customersFeatureKey, CustomersReducer.reducer, {
      metaReducers: CustomersReducer.metaReducers,
    }),
    EffectsModule.forFeature([CustomersEffects]),
  ],
})
export class CustomersStoreModule {}
