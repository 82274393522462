export enum G7_ORDER_PROCESSING_STATUS {
  WAITING_FLEET = 'WAITING_FLEET',
  WAITING_G7 = 'WAITING_G7',
  VALIDATE_G7 = 'VALIDATE_G7',
  TRANSMIT_ERROR = 'TRANSMIT_ERROR',
  CHANGED = 'CHANGED',
  TRANSMITTED = 'TRANSMITTED',
  WAITING_DATA = 'WAITING_DATA',
}

export enum G7_ORDER_STATUS {
  PENDING = 'PENDING',
  TO_PAY = 'TO_PAY',
  IN_ERROR = 'IN_ERROR',
  BILLING = 'BILLING',
  CANCEL = 'CANCEL',
}

export enum G7_ORDER_HISTORY_AUTHOR_TYPE {
  BY_FLEET = 'BY_FLEET',
  BY_G7 = 'BY_G7',
  BY_SYSTEM = 'BY_SYSTEM',
}
