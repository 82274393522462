import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { LocalStorageService } from '@core/services/common';
import { AppState } from '@core/store/reducers';
import { phoneCallButtonSelector } from '@core/store/selectors/phone-call-button.selectors';
import { select, Store } from '@ngrx/store';
import { Feature, Point } from 'geojson';
import moment from 'moment';
import { Observable } from 'rxjs';

import { IAddress, ICountry } from '../../../models/fleet.model';
import { IDriverUserInfo } from '../../../models/user-info.model';
import { FormService } from '../../../utils/form-service';
import { AddressAutocompleteComponent } from '../../address-autocomplete/address-autocomplete.component';

@Component({
  selector: 'app-driver-details-info',
  templateUrl: './driver-details-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsInfoComponent implements OnInit {
  @ViewChild(AddressAutocompleteComponent) autoComplete: AddressAutocompleteComponent;
  @Input() form: FormGroup;
  @Input() countries: ICountry[];
  @Input() ngDisabled: boolean;
  @Input() minPhoneLength: number;
  @Input() maxPhoneLength: number;
  @Input() phoneCallButtonAvailable: boolean;
  public maxBirthdayDate = moment().subtract(18, 'years').startOf('day');
  public driverAddressId = 'driver-address';
  public driver: IDriverUserInfo;
  public isPhoneCallButtonAvailable$: Observable<boolean>;
  public phoneCallButtonSelectorOn: boolean;
  public customerPhoneCallLink =
    'https://api-mediation.flexiblecontactcenter.orange-business.com/clickToCall?phoneNumber=%2B';
  private address: Partial<Feature<Point>>;

  constructor(
    private readonly store$: Store<AppState>,
    private readonly formService: FormService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public get phoneCodeControl(): AbstractControl {
    return this.form.get('phoneCode');
  }

  public get phoneNumberControl(): AbstractControl {
    return this.form.get('phoneNumber');
  }

  @Input() set driverData(value: IDriverUserInfo) {
    if (value) {
      this.driver = value;
      this.updateForm(value);
      this.setAddress();
      this.setAutoCompleteAddress();
    }
  }

  public ngOnInit(): void {
    this.isPhoneCallButtonAvailable$ = this.store$.pipe(select(phoneCallButtonSelector));
    this.isPhoneCallButtonAvailable$.subscribe(user => (this.phoneCallButtonSelectorOn = !!user));
  }

  public checkPhone(): boolean {
    if (this.phoneCallButtonSelectorOn && this.localStorageService.checkTelephnyEnabled()) {
      return true;
    } else {
      return false;
    }
  }

  public compareCountry(c1: ICountry, c2: ICountry): boolean {
    return c1 && c2 ? c1.isoCode === c2.isoCode : c1 === c2;
  }

  public trackCountry(index: number, item: ICountry): string {
    return item?.isoCode || null;
  }

  public onAddressSelect(address: Partial<Feature<Point>>): void {
    this.address = address;
  }

  public onAddressType(): void {
    this.address = null;
  }

  public markAutocompleteAsTouched(): void {
    this.formService.markFieldsAsTouched(this.autoComplete.form);
  }

  public isAutocompleteValid(): boolean {
    return this.autoComplete.isFormValid();
  }

  public generateAddress(): IAddress {
    if (!this.address) {
      return null;
    }
    const { postcode: postalCode, city, street, name, housenumber } = this.address.properties;
    return {
      postalCode,
      city,
      street: street || name,
      number: housenumber || null,
      country: this.form.get('country').value || ({ isoCode: 'FR' } as ICountry),
    };
  }

  private updateForm(driver: IDriverUserInfo): void {
    const {
      firstName,
      lastName,
      birthday,
      email,
      phone: { phoneCode, phoneNumber },
      address: { country },
    } = driver;
    this.form.patchValue({
      firstName,
      lastName,
      birthday: moment(birthday),
      email,
      phoneCode,
      phoneNumber,
      country,
    });
  }

  private setAddress(): void {
    if (this.driver?.address) {
      const { city, postalCode: postcode, street, number: housenumber } = this.driver?.address;
      this.address = {
        properties: { city, postcode, street, housenumber },
      };
    }
  }

  private setAutoCompleteAddress(): void {
    setTimeout(() => this.autoComplete.setAutocompleteFieldValue(this.address));
  }
}
