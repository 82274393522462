import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UiOrderRulesService } from '@common/services';

@Component({
  selector: 'azz-edit-order-buttons',
  template: `
    <section class="modify gap-1">
      <button
        *ngIf="showModifyButton"
        class="custom-btn custom-btn--orange modify__btn"
        type="button"
        [disabled]="isDisabled"
        (click)="onUpdateOrder()"
      >
        {{ 'PA_DETAILS_MODIFY' | translate }}
      </button>
      <button class="custom-btn custom-btn--orange" (click)="onCancelOrder()" type="button" [disabled]="isDisabled">
        {{ 'PA_DETAILS_DELETE' | translate }}
      </button>
    </section>
  `,
  styles: [
    `
      .modify {
        display: flex;
        margin-bottom: 30px;
      }

      .modify__btn {
        margin-right: 30px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UiOrderRulesService],
})
export class EditOrderButtonsComponent {
  @Input() isDisabled: boolean;
  @Input() isEditButtonShown = true;
  @Output() updateOrder = new EventEmitter<boolean>();
  @Output() cancelOrder = new EventEmitter<boolean>();

  public showModifyButton = true;

  public onUpdateOrder() {
    this.updateOrder.emit();
  }

  public onCancelOrder() {
    this.cancelOrder.emit();
  }
}
