import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'azz-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.less'],
})
export class AzzButtonDropdownComponent {
  @Input() buttonName: string;
  @Input() innerButtonsName: any[];
  @Input() firstTranslatePart = '';
  @Input() blockUnblockIndicator = false;
  @Input() buttonClassName = 'azz-bg-red azz-button';
  @Output() selectProperty = new EventEmitter();

  public onButtonClick(name: string) {
    this.selectProperty.emit(name);
  }
}
