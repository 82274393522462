import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'azz-import-poi-error-dialog',
  templateUrl: './import-poi-error-dialog.component.html',
  styleUrls: ['./import-poi-error-dialog.component.less'],
})
export class ImportPoiErrorDialogComponent {
  @ViewChild('content') dialogContent: any;
  public modalReference: any;
  public poiList: string[];

  constructor(private readonly dialogService: NgbModal) {}

  public showDialog(poiList: string[]) {
    this.poiList = poiList;
    this.modalReference = this.dialogService.open(this.dialogContent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  public onClose(): void {
    this.modalReference.close();
  }
}
