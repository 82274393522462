<section>
  <div class="azz-header">
    <span>{{ 'PA_DETAILS_ORDERS_INFO' | translate }}</span>
    <span> - {{ 'REFERENCE_LABEL' | translate }}: {{ order?.reference }}</span>
  </div>
  <div class="row">
    <div class="col-md-6">
      <label class="azz-transparent-input-label">{{ 'ORDER_DETAILS_ORDER_STATUS_LABEL' | translate }}</label>
      <div>{{ 'CORE_ORDER_STATUS_' + order?.status | translate }}</div>
    </div>
    <div class="col-md-6">
      <label class="azz-transparent-input-label">{{ 'DATE_AND_TYPE_LABEL' | translate }}</label>
      <div>
        <span *ngIf="order?.date">{{ order?.date | date: 'dd.MM.yyyy HH:mm' }} / </span>
        <span *ngIf="order?.type">{{ 'ORDER_TYPE_' + order?.type | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <label class="azz-transparent-input-label">{{ 'FR_APPOINTMENT_ADDRESS_COL_HEADER' | translate }}</label>
      <div *ngIf="order?.appointmentAddress?.poi?.label">
        {{ order?.appointmentAddress?.poi?.label }}
      </div>
      <div *ngIf="!order?.appointmentAddress?.poi?.label">
        {{ order?.appointmentAddress?.number }}
        {{ order?.appointmentAddress?.street }},
        {{ order?.appointmentAddress?.city }}
      </div>
    </div>
    <div class="col-md-6">
      <label class="azz-transparent-input-label">{{ 'FR_DETAILS_DESTINATION_ADDRESS_LABEL' | translate }}</label>
      <div *ngIf="order?.destinationAddress?.poi?.label">
        {{ order?.destinationAddress?.poi?.label }}
      </div>
      <div *ngIf="!order?.destinationAddress?.poi?.label">
        {{ order?.destinationAddress?.number }}
        {{ order?.destinationAddress?.street }},
        {{ order?.destinationAddress?.city }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <label class="azz-transparent-input-label">{{ 'ORDER_DETAILS_PAYMENT_MODE_LABEL' | translate }}</label>
      <div *ngIf="paymentTypeId">{{ 'PAYMENT_TYPE_' + paymentTypeId | translate }}</div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="
      tagIdsExceptServiceVehiclePaymentType?.length ||
      order?.comment ||
      order?.appointmentAddressComment ||
      order?.destinationAddressComment
    "
  >
    <div class="col-md-6" *ngIf="tagIdsExceptServiceVehiclePaymentType?.length">
      <label class="azz-transparent-input-label">{{ 'COMPLEMENTS_AND_TAGS_HEADER' | translate }}</label>
      <div>
        <span class="value" *ngFor="let id of tagIdsExceptServiceVehiclePaymentType; let last = last">
          {{ 'CRC_TAG_' + id | translate }}<span *ngIf="!last">, </span>
        </span>
      </div>
    </div>
    <div
      class="col-md-6"
      *ngIf="order?.comment || order?.appointmentAddressComment || order?.destinationAddressComment"
    >
      <label class="azz-transparent-input-label">{{ 'ORDER_DETAILS_COMMENT_LABEL' | translate }}</label>
      <div>{{ order?.comment }}</div>
      <div>{{ order?.appointmentAddressComment }}</div>
      <div>{{ order?.destinationAddressComment }}</div>
    </div>
  </div>
</section>
