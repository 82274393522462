import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDeactivatePayload } from '../../models/common.model';
import {
  IFleetAdmin,
  IFleetAdminData,
  IFleetAdminsDataParams,
  IFleetOwnerData,
  IFleetOwnersParams,
} from '../../models/fleet.model';
import { IFleetUserInfo } from '../../models/user-info.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class FleetAdminService extends ApiService {
  private readonly FLEET_ADMIN_URL = 'user/api/v1/fleetAdmin/';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getFleetAdmin(userId: string | number): Observable<IFleetAdmin> {
    return this.get(`${this.FLEET_ADMIN_URL}${userId}`);
  }

  public getFleetAdminsData(params: Partial<IFleetAdminsDataParams>): Observable<IFleetAdminData> {
    return this.get(`${this.FLEET_ADMIN_URL}fleet/staff`, params);
  }

  public deactivateFleetAdmin(id: number, payload: IDeactivatePayload): Observable<IFleetAdmin> {
    return this.post(`${this.FLEET_ADMIN_URL}${id}/block`, payload);
  }

  public activateFleetAdmin(id: number): Observable<IFleetAdmin> {
    return this.post(`${this.FLEET_ADMIN_URL}${id}/unblock`, {});
  }

  public getFleetOwner(fleetId: number): Observable<IFleetUserInfo> {
    return this.get(`${this.FLEET_ADMIN_URL}fleet/${fleetId}/owner`);
  }

  public updateFleetAdmin(userId: string | number, fleetAdmin: IFleetAdmin): Observable<any> {
    return this.put(`${this.FLEET_ADMIN_URL}${userId}`, fleetAdmin);
  }

  public search(
    pageNumber: number,
    sort,
    name: string,
    country: string,
    city: string,
    activated: boolean,
    validated: boolean,
    fixedPrice: boolean,
    blocked: boolean,
    kbisAttached: boolean
  ): Observable<any> {
    return this.get(`${this.FLEET_ADMIN_URL}owner/search`, {
      pageNumber,
      sort,
      name,
      country,
      city,
      activated,
      validated,
      fixedPrice,
      blocked,
      kbisAttached,
    });
  }

  public getFleetOwners(params: Partial<IFleetOwnersParams>): Observable<IFleetOwnerData> {
    return this.get(`${this.FLEET_ADMIN_URL}owner/search`, params);
  }

  public validate(data: any): Observable<any> {
    return this.post(`${this.FLEET_ADMIN_URL}validate`, data);
  }

  public updateG7(data: any): Observable<any> {
    return this.put(`${this.FLEET_ADMIN_URL}update`, data);
  }

  public resendFleetInvitation(fleetId: number): Observable<any> {
    return this.post(`${this.FLEET_ADMIN_URL}registration/owner/fleet/${fleetId}/resendInvitation`, {});
  }
}
