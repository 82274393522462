import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, finalize } from 'rxjs/operators';

import { DIRECTION } from '../../constant/direction.enum';
import { UtilService } from '../../services/common/util.service';

@Component({
  selector: 'azz-dropdown',
  templateUrl: './dropdown.component.html',
  providers: [NgbDropdownConfig],
})
export class DropdownComponent implements OnInit {
  @Input() searchResultHeader: string;
  @Input() onlyG7FleetId: boolean;
  @Input() inputCurrentValue: any;
  @Input() defaultInputValue = '';
  @Input() azzId: string;
  @Input() azzLabel: string;
  @Input() azzAllOptionsLabel: string;
  @Input() azzOptionFormat: any;
  @Input() azzPlaceholder: string;
  @Input() azzSearchPlaceholder: string;
  @Input() azzNullAsAll: any;
  @Input() azzDataRequest: any;
  @Input() ngDisabled: boolean;
  @Input() ngModel: any;
  @Input() ngRequired: boolean;
  @Input() azzNoFilter: any;
  @Input() azzNoStar: any;
  @Input() azzFleetData: any;
  @Input() isPartnerRemovingIsPossible: boolean;
  @Input() isCityAllowed = false;
  @Input() isFleetIdG7Allowed = false;
  @Output() loadFleets = new EventEmitter();
  @Output() azzSelect = new EventEmitter();

  public watcherQuery$ = new EventEmitter();
  public _REQUEST_DELAY_MS = 500;
  public data: any;
  public config: any;
  public filter: any;
  public selectedValue: any;

  constructor(
    protected changeDetectionRef: ChangeDetectorRef,
    protected translate: TranslateService,
    protected utilService: UtilService
  ) {
    this.config = {
      isOpened: false,
      loadingIndicator: false,
    };

    this.data = {
      displayValue: null,
      optionsData: null,
      optionFormatFields: null,
    };

    this.filter = {
      direction: DIRECTION.ASC,
      query: '',
    };
  }

  ngOnInit() {
    this.init();
    this.initValue();
  }

  public changeFilterDirection() {
    if (this.filter.direction === DIRECTION.ASC) {
      this.filter.direction = DIRECTION.DESC;
    } else {
      this.filter.direction = DIRECTION.ASC;
    }
    this.watcherQuery$.emit();
  }

  public closeDropdownMenu(drop: any, option: any) {
    this.selectedValue = option;
    this.inputCurrentValue = option;
    this.config.isOpened = false;
    this.data.displayValue = option ? this.updateDisplayValue() : option;
    this.initValue();
    this.azzSelect.emit(option);
    this.watcherQuery$.emit();
    drop.close();
  }

  public isPaginationVisible() {
    return this.data.optionsData && this.data.optionsData.totalPages > 1;
  }

  public prevPageEnabled() {
    return this.data.optionsData && this.data.optionsData.number > 0;
  }

  public nextPageEnabled() {
    return this.data.optionsData && this.data.optionsData.number < this.data.optionsData.totalPages - 1;
  }

  public prevPage() {
    if (this.prevPageEnabled()) {
      this.invokeSearch(this.data.optionsData.number - 1);
    }
  }

  public nextPage() {
    if (this.nextPageEnabled()) {
      this.invokeSearch(this.data.optionsData.number + 1);
    }
  }

  public index(obj: any, prop: string): any {
    return obj[prop];
  }

  public convertStrIntoObjProp(obj: any, str: string): any {
    if (str.indexOf('.') !== -1 && str.split('.').length > 1) {
      const result = str.split('.').reduce(this.index, obj);
      return result ? result : '';
    } else {
      return obj[str] ? obj[str] : '';
    }
  }

  protected initValue() {
    if (this.inputCurrentValue) {
      if (this.isFleetIdG7Allowed) {
        this.data.displayValue = this.inputCurrentValue.idG7;
      } else if (this.isCityAllowed) {
        this.data.displayValue = this.inputCurrentValue.address.city;
      } else if (this.inputCurrentValue.name) {
        this.data.displayValue = this.utilService
          .tagRemoveFalsyValueFunc`${this.inputCurrentValue.name} ${this.inputCurrentValue.lastName}`;
      } else {
        this.data.displayValue = this.utilService
          .tagRemoveFalsyValueFunc`${this.inputCurrentValue.firstName} ${this.inputCurrentValue.lastName}`;
      }
    } else if (!this.inputCurrentValue && this.defaultInputValue) {
      this.data.displayValue = this.translate.instant(this.defaultInputValue);
    } else {
      this.data.displayValue = '';
    }
  }

  protected init() {
    this.parseOptionFormat();
    this.loadInitialData();
    this.initWatchers();
  }

  protected loadInitialData() {
    this.invokeSearch(0);
  }

  protected initWatchers() {
    const defaultPageNumber = 0;
    this.watcherQuery$.pipe(debounceTime(this._REQUEST_DELAY_MS)).subscribe(response => {
      if (response) {
        // Should invoke with pageNumber 0 if we need to search fleet by texting his name in searching field
        this.invokeSearch(defaultPageNumber);
      } else {
        this.invokeSearch((this.data.optionsData && this.data.optionsData.number) || 0);
      }
    });
  }

  protected updateDisplayValue() {
    let result = '';
    if (this.data.optionFormatFields) {
      for (let i = 0; i < this.data.optionFormatFields.length; i++) {
        if (i > 0) {
          result += ' ';
        }
        result += this.selectedValue[this.data.optionFormatFields[i]];
      }
    }
    this.data.displayValue = result;
    return result;
  }

  private parseOptionFormat(): void {
    this.data.optionFormatFields = this.azzOptionFormat.split(' ');
  }

  private invokeSearch(pageNumber: number): void {
    this.enableLoadingIndicator(true);
    this.loadFleets.emit();
    if (this.onlyG7FleetId) {
      this.azzDataRequest(this.filter.query, this.filter.direction, pageNumber)
        .pipe(finalize(() => this.enableLoadingIndicator(false)))
        .subscribe(
          (response: any) => (this.data.optionsData = response),
          () => (this.data.optionsData = null)
        );
    } else {
      this.azzDataRequest(this.filter.query, this.filter.direction, pageNumber)
        .pipe(finalize(() => this.enableLoadingIndicator(false)))
        .subscribe(
          (response: any) => (this.data.optionsData = response),
          () => (this.data.optionsData = null)
        );
    }
  }

  private enableLoadingIndicator(enabled) {
    this.config.loadingIndicator = enabled;
    this.changeDetectionRef.markForCheck();
  }
}
