import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'azz-custom-order-driver-approaching-data',
  templateUrl: './driver-approaching-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverApproachingDataComponent {
  @Input() realTimeToDepartureAddress: number;
  @Input() distanceToDepartureAddress: number;

  constructor() {}
}
