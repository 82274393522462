<section class="form-group" [class.azz-last]="last" *ngFor="let id of tagsIds; let last = last; let index = index">
  <input
    [id]="'tag-' + id"
    type="checkbox"
    [name]="'tag_' + id"
    [checked]="selectedTagsIdsMap[id]"
    (change)="toggleTag(id)"
  />
  <label [for]="'tag-' + id">{{ 'TAG_' + id | translate | uppercase }}</label>
</section>
