<azz-loading-block [isLoading]="loadingIndicator">
  <section class="azz-details-page pragmatic row azz-info-blocks-row azz-last order">
    <div class="col-12">
      <div class="azz-info-block">
        <div class="azz-nav-block">
          <img src="assets/images/return-arrow.png" class="azz-back-btn" (click)="goBack()" alt="go back" />
          <div class="azz-back-title">{{ 'ORDERS' | translate }}</div>
        </div>
        <div class="form-group azz-warning" *ngIf="!isProhibitedStatusForModify() && order?.price <= 0">
          {{ 'CORE_ZERO_PRICE_DRIVER_WARNING' | translate }}
        </div>
        <div class="azz-block-header">
          {{ order?.date | date: 'dd.MM.yyyy, HH:mm' : 'UTC' }}
        </div>
        <div class="azz-control-group">
          <div class="row">
            <div class="col-md-4 form-group">
              <div class="azz-transparent-input-label">{{ 'CLIENT' | translate }}</div>
              <div class="azz-transparent-input">{{ order?.customer?.name }}</div>
            </div>
            <div class="col-md-4 form-group">
              <div class="azz-transparent-input-label">{{ 'CUSTOMER_PHONE' | translate }}</div>
              <div class="azz-transparent-input">{{ order?.customer?.phoneNumber }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 form-group">
              <div class="azz-transparent-input-label">{{ 'CUSTOMER_EMAIL' | translate }}</div>
              <div class="azz-transparent-input">{{ order?.customer?.email }}</div>
            </div>
          </div>
        </div>
        <div class="azz-control-group">
          <div class="row">
            <div class="col-md-4 form-group">
              <label [for]="'pickup-address'" class="azz-transparent-input-label">
                {{ 'DEPARTURE_ADDRESS_LABEL' | translate }}
              </label>
              <azz-search-address-poi-autocomplete
                [address]="pickupAddress"
                [withPoi]="true"
                [displayInBillingPortal]="true"
                [fleetG7Id]="order?.fleetG7Id"
                (setAddress)="addressChanged()"
                ngId="pickup-address"
                [ngDisabled]="isAddressBlocked()"
              >
              </azz-search-address-poi-autocomplete>
            </div>
            <div class="col-md-6 auto-changed" *ngIf="order?.appointmentAddress?.autoChanged">
              <span class="auto-changed__icon"></span>
              <span class="auto-changed__hint" [innerHTML]="'ADDRESS_AUTO_CHANGED_HINT' | translate"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group" #formElemRef [ngClass]="{ 'has-error': wrongAddress }">
                <label [for]="'destination-address'" class="azz-transparent-input-label">
                  {{ 'ARRIVAL_ADDRESS_LABEL' | translate }}</label
                >
                <azz-search-address-poi-autocomplete
                  [address]="destinationAddress"
                  [withPoi]="true"
                  [displayInBillingPortal]="true"
                  [fleetG7Id]="order?.fleetG7Id"
                  (setAddress)="addressChanged()"
                  [ngDisabled]="isAddressBlocked()"
                  ngId="destination-address"
                >
                  <!--                  [isAddressNotSelectedErrorShown]="false"-->
                </azz-search-address-poi-autocomplete>
              </div>
            </div>
            <div class="col-md-6 auto-changed" *ngIf="order?.destinationAddress?.autoChanged">
              <span class="auto-changed__icon"></span>
              <span class="auto-changed__hint" [innerHTML]="'ADDRESS_AUTO_CHANGED_HINT' | translate"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <div class="azz-transparent-input-label">
                  {{ 'FR_DETAILS_COMMENT_ADDRESS_LABEL' | translate }}
                </div>
                <!--                <div class="azz-transparent-input">{{order?.commentAddress}}</div>-->
                <div class="azz-transparent-input"></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <div class="azz-transparent-input-label">
                  {{ 'FR_DETAILS_DATE_TIME_LABEL' | translate }}
                </div>
                <div class="azz-transparent-input">
                  {{ order?.date | date: 'dd.MM.yyyy, HH:mm' : 'UTC' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="azz-control-group">
          <div class="form-group">
            <input
              *ngIf="order"
              id="client-absent"
              type="checkbox"
              [(ngModel)]="order.clientAbsent"
              [disabled]="isProhibitedStatusForModify()"
              (change)="onSetShow()"
              name="client_absent"
            />
            <label for="client-absent">{{ 'CUSTOMER_ABSENT' | translate }}</label>
          </div>
          <div *ngIf="order?.clientAbsent">
            <!--EDIT PRICE FOR ABSENT CLIENT-->
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="azz-transparent-input-label">{{ 'CLIENT_ABSENT_AMOUNT' | translate }}</label>
                <div class="azz-transparent-input">
                  <azz-editable-price
                    *ngIf="order"
                    [mode]="mode"
                    [orderData]="order"
                    (savePrice)="onChangePrice($event)"
                  >
                  </azz-editable-price>
                </div>
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-md-4 form-group">
                <label class="azz-transparent-input-label">{{ 'TOTAL' | translate }}</label>
                <div class="azz-transparent-input">
                  <span [class.azz-warning]="order?.totalPrice === 0"> {{ order?.totalPrice }} &euro; </span>
                </div>
              </div>
              <div *ngIf="order?.firstPayment" class="col-md-4 form-group">
                <span class="order__onboard-payment">{{ 'ONBOARD_PAYMENT' | translate }}</span>
              </div>
            </div>
          </div>
          <!--EDIT PRICE FOR ABSENT CLIENT-->
          <div *ngIf="!order?.clientAbsent">
            <!--EDIT PRICE FOR EXISTING CLIENT-->
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="azz-transparent-input-label">{{ 'AMOUNT' | translate }}</label>
                <div class="azz-transparent-input">
                  <azz-editable-price
                    *ngIf="order"
                    [mode]="mode"
                    [orderData]="order"
                    (savePrice)="onChangePrice($event)"
                  >
                  </azz-editable-price>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="azz-transparent-input-label">
                  {{ 'TIP' | translate: { value: order?.tripCategory || '' } }}
                </label>
                <div class="azz-transparent-input">{{ categoryValue || '0' }} <span>&euro;</span></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 form-group">
                <label class="azz-transparent-input-label">{{ 'TOLL' | translate }}</label>
                <div class="azz-transparent-input">
                  <azz-editable-toll *ngIf="order" [mode]="mode" [orderData]="order" (saveToll)="onChangeToll($event)">
                  </azz-editable-toll>
                </div>
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-md-4 form-group">
                <label class="azz-transparent-input-label">{{ 'TOTAL' | translate }}</label>
                <div class="azz-transparent-input">
                  <span [class.azz-warning]="order?.totalPrice === 0"> {{ order?.totalPrice }} &euro; </span>
                </div>
              </div>
              <div *ngIf="order?.firstPayment" class="col-md-4 form-group">
                <span class="order__onboard-payment">{{ 'ONBOARD_PAYMENT' | translate }}</span>
              </div>
            </div>
          </div>
          <!--EDIT PRICE FOR EXISTING CLIENT-->
        </div>

        <div class="azz-control-group">
          <div class="azz-header">{{ 'INFO_G7' | translate }}</div>
          <div class="row">
            <div class="col-md-6 form-group" *ngIf="order">
              <div class="azz-transparent-input-label">{{ 'TIPS_GRID' | translate }}</div>
              <div class="grid-tips d-flex">
                <label class="custom-radio col-md-4" *ngFor="let grid of gridTips">
                  {{ grid?.name }}
                  <input type="radio" disabled [ngModel]="order.tipId" [value]="grid.id" name="tipsGrid" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="col-md-6 form-group">
              <div class="azz-transparent-input-label">{{ 'CATEGORY' | translate }}</div>
              <div class="grid-tips d-flex" *ngIf="order">
                <label class="custom-radio col-md-4" *ngFor="let category of categories"
                  >{{ category.category }} ({{ category.value }}%)
                  <input
                    type="radio"
                    checked="checked"
                    name="category"
                    [value]="category.category"
                    [disabled]="!isAllowedToChangeCategory"
                    (change)="onCategoryChange(category.value)"
                    [(ngModel)]="order.tripCategory"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="azz-control-group">
          <div class="row">
            <div class="col-md-4 form-group">
              <div class="azz-transparent-input-label">
                {{ 'FR_DETAILS_FLEET_ID_LABEL' | translate }}
              </div>
              <div class="azz-transparent-input">{{ order?.fleetG7Id }}</div>
            </div>
            <div class="col-md-4 form-group">
              <div class="azz-transparent-input-label">
                {{ 'FO_TRIP_NUMBER_LABEL' | translate }}
              </div>
              <div class="azz-transparent-input">{{ order?.tripNumber }}</div>
            </div>
            <div class="col-md-4 form-group" *ngIf="order?.partnerId">
              <div class="azz-transparent-input-label">
                {{ 'AUTHORIZATION_NUMBER' | translate }}
              </div>
              <div class="azz-transparent-input">{{ order?.partnerId }}</div>
            </div>
          </div>
        </div>
        <div class="azz-control-group azz-last">
          <div class="row">
            <div class="col-md-4 form-group">
              <div class="azz-transparent-input-label">
                {{ 'GO_ORDERS_SAGA7_STATUS_HEADER' | translate }}
              </div>
              <div class="azz-transparent-input">
                {{ 'GO_ORDER_SAGA7_STATUS_' + order?.status | translate }}
              </div>
            </div>
            <div class="col-md-4 form-group">
              <div class="azz-transparent-input-label">
                {{ 'GO_ORDERS_RN7_STATUS_HEADER' | translate }}
              </div>
              <div class="azz-transparent-input">
                {{ 'GO_ORDER_STATUS_' + order?.processingStatus | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 form-group">
              <div class="azz-transparent-input-label">{{ 'DRIVER' | translate }}</div>
              <azz-driver-circle7 [navigateUrl]="navigateUrlForCicleComponent">
                <!--                [driver]="order?.driver"-->
              </azz-driver-circle7>
            </div>
          </div>

          <div class="row" *ngIf="mode === 'fleet'">
            <div class="col-md-4">
              <div class="azz-transparent-input-label">{{ 'DOCUMENTS' | translate }}</div>
            </div>
          </div>

          <div
            class="col-12"
            [ngStyle]="{ display: file.deleted ? 'none' : 'block' }"
            *ngFor="let file of uploadedFiles; let i = index"
          >
            <azz-attach-file
              [azzId]="'document-' + i"
              [value]="up(file.filename)"
              [href]="file.fileUrl"
              [outputFileName]="true"
              [hideCloseButton]="mode === 'g7'"
              [hideAttacher]="
                ((userRoles[0] === 'ROLE_FLEET_OWNER' || userRoles[0] === 'ROLE_FLEET_ADMIN') &&
                  order?.processingStatus === G7_ORDER_PROCESSING_STATUS.VALIDATE_G7) ||
                mode === 'g7' ||
                !!file.filename ||
                !!file.binary
              "
              azzAccept="image/png, image/jpeg, application/pdf"
              azzAcceptMimeType="image/png, image/jpeg, application/pdf"
              (updateFile)="setDocumentImage($event, file, i)"
            >
            </azz-attach-file>
          </div>

          <div *ngIf="order?.processingStatus !== 'TRANSMITTED'" class="row justify-content-center">
            <div class="col-md-4">
              <!--  -->
              <azz-spinner-button7
                *ngIf="
                  order?.processingStatus !== G7_ORDER_PROCESSING_STATUS.WAITING_FLEET &&
                  (userRoles[0] === 'ROLE_FLEET_OWNER' || userRoles[0] === 'ROLE_FLEET_ADMIN')
                "
                buttonType="button"
                azzClass="btn btn-block azz-primary-btn azz-button button-primary"
                [azzValue]="'TRIP_SAVE_CHANGES' | translate"
                [azzDisabled]="isSaveSpinner"
                [loadingIndicator]="isSaveSpinner"
                [azzDisabled]="!orderChanged"
                (azzClick)="saveWithoutValidation()"
              >
              </azz-spinner-button7>
            </div>
          </div>

          <!-- Fleet save -->
          <div class="row justify-content-center" *ngIf="mode === 'fleet' && isFleetMessageAllowed(order)">
            <div
              class="row justify-content-center save-buttons-row"
              *ngIf="order?.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_FLEET"
            >
              <div class="col-md-6">
                <azz-spinner-button7
                  buttonType="button"
                  azzClass="btn btn-block azz-primary-btn azz-button button-green"
                  [azzValue]="'TRIP_SAVE_WITH_VALIDATION' | translate"
                  [loadingIndicator]="isSaveSpinner"
                  (azzClick)="saveWithValidation()"
                ></azz-spinner-button7>
              </div>
              <div class="col-md-6">
                <azz-spinner-button7
                  buttonType="button"
                  azzClass="btn btn-block azz-primary-btn azz-button button-primary"
                  [azzValue]="'TRIP_SAVE_CHANGES' | translate"
                  [loadingIndicator]="isSaveSpinner"
                  [azzDisabled]="!orderChanged"
                  (azzClick)="saveWithoutValidation()"
                ></azz-spinner-button7>
              </div>
            </div>

            <div class="form-group col-md-12">
              <label class="azz-transparent-input-label" for="message">{{ 'DENY_MESSAGE_HEADER' | translate }}</label>
              <textarea
                id="message"
                type="text"
                maxlength="500"
                name="message"
                [(ngModel)]="messageToFleet"
                class="form-control azz-transparent-input azz-textarea azz-validate"
              ></textarea>
            </div>

            <div class="col-md-4">
              <!-- Submit button -->
              <azz-spinner-button7
                *ngIf="order?.processingStatus !== G7_ORDER_PROCESSING_STATUS.WAITING_FLEET"
                buttonType="button"
                azzClass="btn btn-block azz-primary-btn azz-button button-primary"
                [azzValue]="'TRIP_SEND' | translate"
                [azzDisabled]="isSaveSpinner"
                [loadingIndicator]="isSaveSpinner"
                (azzClick)="saveAsFleet()"
              >
              </azz-spinner-button7>

              <azz-spinner-button7
                *ngIf="order?.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_FLEET"
                buttonType="button"
                azzClass="btn btn-block azz-primary-btn azz-button button-primary"
                [azzValue]="'TRIP_SEND' | translate"
                [azzDisabled]="isSaveSpinner"
                [loadingIndicator]="isSaveSpinner"
                (azzClick)="saveSameOrderForWaitingFleet()"
              >
              </azz-spinner-button7>
            </div>
          </div>

          <!-- G7 Save -->
          <div class="row justify-content-center" *ngIf="mode === 'g7'">
            @if (
              !(isConfirmSpinner || order?.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_FLEET || isBlocked())
            ) {
              <div class="col-md-6" *ngIf="isValidationButtonsVisible()">
                <azz-spinner-button7
                  buttonType="button"
                  azzClass="btn btn-block azz-primary-btn azz-button button-green"
                  [azzValue]="'CONFIRMED' | translate"
                  [loadingIndicator]="isConfirmSpinner"
                  (azzClick)="onValidate()"
                >
                </azz-spinner-button7>
              </div>
            }
            @if (!(isSaveSpinner || isBlocked())) {
              <div class="col-md-6" [ngClass]="g7OffsetClassForButtonWrapper()">
                <azz-spinner-button7
                  buttonType="button"
                  azzClass="btn btn-block azz-primary-btn azz-button button-primary"
                  [azzValue]="'SAVE_CHANGES' | translate"
                  [loadingIndicator]="isSaveSpinner"
                  (azzClick)="saveAsBillingUser()"
                >
                </azz-spinner-button7>
              </div>
            }
          </div>

          <div class="row azz-top-10 justify-content-center" *ngIf="mode === 'g7' && isG7MessageAllowed(order)">
            <div class="form-group col-12">
              <label class="azz-transparent-input-label" for="g7-message">{{
                'DENY_MESSAGE_G7_HEADER' | translate
              }}</label>
              <textarea
                type="text"
                id="g7-message"
                maxlength="500"
                name="message"
                [(ngModel)]="messageToFleet"
                class="form-control azz-transparent-input azz-textarea azz-validate"
              ></textarea>
            </div>
            <div class="col-md-4">
              <azz-spinner-button7
                buttonType="button"
                azzClass="btn btn-block azz-primary-btn azz-button button-primary"
                [azzValue]="'FD_SUBMIT_VALUE' | translate"
                [azzDisabled]="isDeclineSpinner"
                [loadingIndicator]="isDeclineSpinner"
                (azzClick)="onDecline()"
              >
              </azz-spinner-button7>
            </div>
          </div>

          <azz-g7-common-notifications [isOrderNumberHidden]="true"></azz-g7-common-notifications>
        </div>
      </div>
    </div>
  </section>
</azz-loading-block>

<azzapp-confirm-dialog (azzConfirm)="validate({ valid: true }, 'isConfirmSpinner')"> </azzapp-confirm-dialog>
<azz-g7-deny-validation-dialog
  [order]="order"
  [messageToFleet]="messageToFleet"
  [hideMessageField]="true"
  (azzConfirm)="validate({ valid: false, message: messageToFleet }, 'isDeclineSpinner')"
>
</azz-g7-deny-validation-dialog>
