import { CommonModule } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[azzEmitOnEvent]',
  providers: [NgOnDestroyService],
})
export class EmitOnEventDirective implements OnInit {
  @Input() sourceEvent: string;
  @Output() triggerAction$ = new EventEmitter();
  public DEBOUNCE_TIME_MS = 300;

  constructor(
    private readonly el: ElementRef,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  ngOnInit() {
    fromEvent(this.el.nativeElement, this.sourceEvent)
      .pipe(debounceTime(this.DEBOUNCE_TIME_MS), takeUntil(this.destroyed$))
      .subscribe(() => this.triggerAction$.emit());
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [EmitOnEventDirective],
  exports: [EmitOnEventDirective],
})
export class EmitOnEventModule {}
