import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-driver-tags-list',
  templateUrl: './driver-tags-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTagsListComponent {
  @Input() tagsIds: string[];
  @Input() selectedTagsIdsMap: { [key: string]: boolean };
  @Output() toggleTag$ = new EventEmitter<string>();

  public toggleTag(id: string): void {
    this.toggleTag$.emit(id);
  }
}
