<div class="prev">
  <span class="prev__label">{{ 'PA_CREATE_PREV_ORDER_HEADER' | translate }}:</span>
  <ngb-carousel
    tabindex="-1"
    [showNavigationArrows]="showNavigationArrows"
    [showNavigationIndicators]="showNavigationIndicators"
  >
    <ng-template ngbSlide *ngFor="let order of customerPrevOrders">
      <div>
        <span class="prev__label">{{ 'PREV_ORDER_PICKUP_ADDRESS_SHORT' | translate }}: </span>
        <span *ngIf="order?.appointmentAddress?.poi?.label">
          {{ order?.appointmentAddress?.poi?.label }}
          <span *ngIf="order?.appointmentAddress?.poi?.deleted">
            (<span class="prev__deleted-label">{{ 'DELETED' | translate }}</span
            >)
          </span>
        </span>
        <span *ngIf="!order?.appointmentAddress?.poi?.label">
          {{ order?.appointmentAddress?.number }}
          {{ order?.appointmentAddress?.street }},
          {{ order?.appointmentAddress?.city }}
        </span>
      </div>
      <div>
        <span class="prev__label">{{ 'PREV_ORDER_DESTINATION_ADDRESS_SHORT' | translate }}: </span>
        <span *ngIf="order?.destinationAddress?.poi?.label">
          {{ order?.destinationAddress?.poi?.label }}
          <span *ngIf="order?.destinationAddress?.poi?.deleted">
            (<span class="prev__deleted-label">{{ 'DELETED' | translate }}</span
            >)
          </span>
        </span>
        <span *ngIf="!order?.destinationAddress?.poi?.label">
          {{ order?.destinationAddress?.number }}
          {{ order?.destinationAddress?.street }},
          {{ order?.destinationAddress?.city }}
        </span>
      </div>
      <div>
        <span class="prev__label">{{ 'PREV_ORDER_PHONE_SHORT' | translate }}: </span>
        {{ order?.customer?.phoneNumber }}
      </div>

      <ng-container *ngIf="order?.tags?.length">
        <azz-prev-order-payment-type [allTags]="order?.tags"></azz-prev-order-payment-type>
        <azz-prev-order-tags [allTags]="order?.tags"></azz-prev-order-tags>
      </ng-container>

      <div class="prev__order-btn-group">
        <button class="prev__order-btn" (click)="onPreviousOrderClick(order)" tabindex="-1">
          {{ 'PA_CREATE_PREV_CUSTOMER_ORDER' | translate }}
        </button>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
