<form [formGroup]="form">
  <div class="form-group" [class.has-error]="!currentColor">
    <label [for]="'color-selector'">{{ 'COLOR' | translate }}</label>
    <ng-select
      [items]="colorManagement.colorDayOffsList"
      bindLabel="colorName"
      bindValue="color"
      labelForId="color-selector"
      [searchable]="false"
      formControlName="currentColor"
    >
      <ng-template ng-label-tmp let-item="item">
        <span class="details__option-color" [ngClass]="getColorClass(item.color)"></span>
        <span>{{ item?.colorName }}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <span class="details__option-color" [ngClass]="getColorClass(item.color)"></span>
        <span>{{ item?.colorName }}</span>
      </ng-template>
    </ng-select>
  </div>
</form>
