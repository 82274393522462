import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ICustomer, ICustomersData, ICustomersDataParams } from '../../models/customer.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class CustomerService extends ApiService {
  // Move customer methods from phone advisor service
  public CUSTOMERS_URL = 'user/api/v1/customer/';

  constructor(private readonly httpClient: HttpClient) {
    super(httpClient);
  }

  public getCustomers(params: Partial<ICustomersDataParams>): Observable<ICustomersData> {
    return this.get(`${this.CUSTOMERS_URL}search`, params);
  }

  public getCustomersExactFirst(params: Partial<ICustomersDataParams>): Observable<ICustomersData> {
    return this.get(`${this.CUSTOMERS_URL}search/exactFirst`, params);
  }

  public getCustomerById(id: number): Observable<ICustomer> {
    return this.get(`${this.CUSTOMERS_URL}${id}`);
  }

  public createCustomer(data: any) {
    return this.post(`${this.CUSTOMERS_URL}`, data);
  }

  public updateCustomerData(data: any) {
    return this.put(`${this.CUSTOMERS_URL}`, data);
  }

  public getCustomerHistory(params: {
    pageNumber: number;
    pageSize: number;
    sorts: string[];
    customerId: number;
  }): Observable<any> {
    return this.get(`${this.CUSTOMERS_URL}history`, params);
  }

  /**
   * @param phone - phone number
   * @param code - international code (+33 for France)
   */
  public checkPhoneNumberExist(phone: string, code: string): Observable<any> {
    const phoneNumber = phone.slice(code.length);

    return this.get(`${this.CUSTOMERS_URL}phone/number/${phoneNumber}/code/${code}/check`);
  }
}
