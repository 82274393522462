import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { IStopCoffeeBreakBody } from '../../models/coffee-break.model';
import { IFleetAgent } from '../../models/cti.model';

@Injectable({ providedIn: 'root' })
export class CoffeeBreakService extends ApiService {
  private readonly SESSION_URL = 'api/v1/userSession/';
  private readonly PHONE_ADVISOR_URL = 'user/api/v1/phoneAdvisor/';

  constructor(
    http: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) {
    super(http);
  }

  public startCoffeeBreak(): Observable<any> {
    return this.put(`${this.SESSION_URL}startCoffeeBreak`, {});
  }

  public stopCoffeeBreak(body: IStopCoffeeBreakBody): Observable<any> {
    return this.put(`${this.SESSION_URL}stopCoffeeBreak`, body);
  }

  public startUserAgentCoffeeBreak(): Observable<IFleetAgent> {
    return this.put(`${this.PHONE_ADVISOR_URL}cofeebreakAgent`, {});
  }

  public getCoffeeBreakState(): boolean {
    return this.localStorageService.getLocalStorageItem('COFFEE_BREAK');
  }

  public setCoffeeBreakState(value: boolean) {
    this.localStorageService.setLocalStorageItem('COFFEE_BREAK', value);
  }

  public getCtiCoffeeBreakState(): boolean {
    return this.localStorageService.getLocalStorageItem('CTI_COFFEE_BREAK');
  }

  public setCtiCoffeeBreakState(value: boolean) {
    this.localStorageService.setLocalStorageItem('CTI_COFFEE_BREAK', value);
  }
}
