import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'azz-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  @Input() prevDisabled: boolean;
  @Input() nextDisabled: boolean;
  @Input() currentPage: number;
  @Output() goToPrevPage = new EventEmitter();
  @Output() goToNextPage = new EventEmitter();

  public onPrevPageEmit() {
    this.goToPrevPage.emit();
  }

  public onNextPageEmit() {
    this.goToNextPage.emit();
  }
}
