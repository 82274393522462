import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgOnDestroyService, UiOrderRulesService } from '@common/services';
import { PhoneAdvisorOrder } from '@core/models';
import dayjs from 'dayjs';
import { isNull } from 'lodash-es';
import { BehaviorSubject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'azz-open-exception',
  template: `
    <button
      *ngIf="canRaiseException"
      type="button"
      class="custom-btn custom-btn--orange"
      (click)="openException.emit()"
    >
      {{ 'PA_DETAILS_OPEN_EXCEPTION' | translate }}
    </button>
  `,
  styles: [
    `
      :host {
        display: block;
        margin-bottom: 30px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class OpenExceptionComponent {
  @Input() isManualDispatch = false;
  @Output() openException = new EventEmitter<void>();

  private readonly flags = {
    isAllowedStatus: false,
    isAllowedTime: true,
    isExceptional: false,
    hasAssignedDriver: false,
  };
  private readonly isNotEditableTimeSubject = new BehaviorSubject(false);

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly uiOrderRules: UiOrderRulesService
  ) {}

  public get canRaiseException(): boolean {
    const { isExceptional, hasAssignedDriver, isAllowedStatus, isAllowedTime } = this.flags;
    return !isExceptional && hasAssignedDriver && isAllowedTime && isAllowedStatus;
  }

  @Input() set order(order: PhoneAdvisorOrder | null) {
    if (isNull(order)) {
      return;
    }

    const { exceptional, driverId, status } = order;
    this.flags.isAllowedStatus = this.uiOrderRules.dispatchOrder.canOpenExceptionByStatus(status);
    this.flags.isExceptional = exceptional;
    this.flags.hasAssignedDriver = !!driverId;
  }

  @Input() set editableOrderTime(editableTime: dayjs.Dayjs) {
    if (!this.isManualDispatch && editableTime) {
      this.startTimer(editableTime);
    }
  }

  private startTimer(editableTime: dayjs.Dayjs): void {
    timer(0, 20_000)
      .pipe(takeUntil(this.isNotEditableTimeSubject.asObservable()))
      .subscribe(() => {
        const now = dayjs();

        this.flags.isAllowedTime = now.isBefore(editableTime);
        const notAllowedTime = !this.flags.isAllowedTime;
        this.isNotEditableTimeSubject.next(notAllowedTime);
        this.cd.markForCheck();
      });
  }
}
