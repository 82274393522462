/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import { COORDS } from '@core/constant/coords.constant';
import { ROLE } from '@core/constant/role.constant';
import { IDriverFullSession, IDriverFullSessionData } from '@core/models/driver-session.model';
import { IFleetParameters, IFleetParametersForOtherUser } from '@core/models/fleet.model';
import { GeoAreaService } from '@core/services/common/geoArea.service';
import { OrderService } from '@core/services/orders';
import { SessionService } from '@core/services/sessions/session.service';
import { FleetService } from '@core/services/users/fleet.service';
import { UserService } from '@core/services/users/user.service';
import { icon, latLng, Map, marker, Marker, tileLayer } from 'leaflet';
import { isNull } from 'lodash-es';
import { map, mergeMap, Observable, of, switchMap, takeUntil } from 'rxjs';

import { SessionsPopupComponent } from '../sessions-popup/sessions-popup.component';

enum Car {
  red = 'red',
  green = 'green',
  yellow = 'yellow',
  grey = 'grey',
}

@Component({
  selector: 'azz-sessions-map',
  templateUrl: './sessions-map.component.html',
  providers: [NgOnDestroyService],
})
export class SessionsMapComponent implements OnInit {
  @Input() driversRoute: string;
  @Input() ordersRoute: string;
  @Input() userRole: string;
  @ViewChild(SessionsPopupComponent) dialog: SessionsPopupComponent;
  public readonly IMG_PATH = 'assets/images/';

  public DEFAULT_COUNTRY_ISO = 'FR';
  public styles: any;
  public sessionData: IDriverFullSession[];
  public statuses: any;
  public currentSession: IDriverFullSession;
  public options: any;
  public marker: Marker[];
  public map: Map;
  public fleetParameters: IFleetParameters;
  public fleetPropertiesForPA: IFleetParametersForOtherUser;

  constructor(
    private readonly driverSessionService: SessionService,
    private readonly ngZone: NgZone,
    private readonly fleetService: FleetService,
    private readonly userService: UserService,
    private readonly geoAreaService: GeoAreaService,
    private readonly destroyed$: NgOnDestroyService,
    private readonly orderService: OrderService
  ) {
    this.options = {
      layers: [tileLayer(COORDS.TILE, { maxZoom: 18, attribution: '...' })],
      zoom: 7,
      center: latLng(COORDS.PARIS_LAT, COORDS.PARIS_LNG),
    };
  }

  ngOnInit() {
    this.initStatuses();
    this.listenForDriverSessions();
    this.listenForSelectedSession();
    this.setMinMaxLatLngCoords();
  }

  public isPhoneAdvisor(): boolean {
    const currentRoles = this.userService.getCurrentUserInfo().user.roles;
    return currentRoles.indexOf(ROLE.PHONE_ADVISOR) !== -1;
  }

  public initStatuses() {
    this.statuses = {
      CONNECTED: { selected: this.getSelectedCarIconPath(Car.green) },
      FREE: { selected: this.getSelectedCarIconPath(Car.green) },
      OCCUPIED: {
        selected: this.getSelectedCarIconPath(Car.red),
      },
      UNREACHABLE: { selected: this.getSelectedCarIconPath(Car.grey) },
    };
  }

  public clickedMarker(clickedMarker: any) {
    this.setIcons();
    clickedMarker.iconUrl = this.statuses[clickedMarker.status].selected;
    this.dialog.showDialog('', '');
    this.driverSessionService.selectedSession$.next(clickedMarker);
  }

  private getCarStatusIcon(sessionStatus: string, orderStatus: string): string {
    switch (sessionStatus) {
      case 'CONNECTED':
      case 'FREE':
        return this.getCarIconPath(Car.green);
      case 'OCCUPIED':
        if (orderStatus === 'TOWARDS_DESTINATION') {
          return this.getCarIconPath(Car.yellow);
        } else {
          return this.getCarIconPath(Car.red);
        }
      case 'UNREACHABLE':
        return this.getCarIconPath(Car.grey);
      default:
        throw new Error(`Invalid session status: ${sessionStatus}`);
    }
  }

  private loadOrderStatus(session: IDriverFullSession): Observable<string> {
    if (!session.currentOrderId) {
      return of('');
    }
    const api$ =
      this.userRole === ROLE.PHONE_ADVISOR
        ? this.orderService.getPaOrderById(session.currentOrderId)
        : this.orderService.getOrderById(session.currentOrderId);

    return api$.pipe(
      takeUntil(this.destroyed$),
      map(order => order.status)
    );
  }

  public setIcons() {
    this.sessionData.forEach(session => {
      this.loadOrderStatus(session).subscribe(orderStatus => {
        session.iconUrl = this.getCarStatusIcon(session.status, orderStatus);
        session.marker = marker([session.location.latitude, session.location.longitude], {
          icon: icon({ iconUrl: session.iconUrl }),
        });

        session.marker.on('click', () => {
          this.ngZone.run(() => {
            this.clickedMarker(session);
          });
        });
      });
    });
  }

  public onMapReady(map: Map) {
    this.map = map;
  }

  private getCarIconPath(type: Car): string {
    return `${this.IMG_PATH}${type}-car-scaled.png`;
  }

  private getSelectedCarIconPath(type: Car): string {
    return `${this.IMG_PATH}fiat-${type}-selected.png`;
  }

  private listenForDriverSessions(): void {
    // @ts-ignore
    this.driverSessionService.sessions$.pipe(takeUntil(this.destroyed$)).subscribe((res: IDriverFullSessionData) => {
      // todo move to methods
      this.sessionData = res.data.filter(session => !!session?.location && !isNull(session.location));
      this.setIcons();
    });
  }

  private listenForSelectedSession(): void {
    this.driverSessionService.selectedSession$.pipe(takeUntil(this.destroyed$)).subscribe((res: IDriverFullSession) => {
      this.currentSession = res;
      if (!res.location) {
        throw Error(
          'ERROR, location doesnt exist, listenForSelectedSession cannot work, this.map.panTo depends on location'
        );
      }
      this.map.panTo(latLng([res.location.latitude, res.location.longitude]));
    });
  }

  private setMinMaxLatLngCoords(): void {
    const fleetId = this.userService.getCurrentUserInfo()?.fleet?.id;

    if (!fleetId) {
      throw Error('ERROR, fleetId doesnt exist, setMinMaxLatLngCoords cannot work');
    }

    const observable$ = this.isPhoneAdvisor()
      ? this.fleetService.getFleetParametersForOtherUser(fleetId).pipe(
          switchMap((response: IFleetParametersForOtherUser) => {
            this.fleetPropertiesForPA = response;
            return this.geoAreaService.findGeoArea(this.fleetPropertiesForPA.geoArea.id, {
              include: 'coordinates',
            });
          })
        )
      : this.fleetService.getParameters(fleetId).pipe(
          mergeMap((response: IFleetParameters) => {
            this.fleetParameters = response;
            return this.geoAreaService.findGeoArea(this.fleetParameters.geoArea.id, {
              include: 'coordinates',
            });
          })
        );

    observable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => this.map.fitBounds(this.calculateMinMaxLatLngCoords(data)));
  }

  private calculateMinMaxLatLngCoords(data: any): any {
    let minLat;
    let minLong;
    let maxLat;
    let maxLong;
    const minLatLongArr = [];
    const maxLatLongArr = [];

    data.coordinates.forEach(point => {
      if (!minLat && !minLong && !maxLat && !maxLong) {
        if (point === null) {
          throw Error('ERROR, point is null, calculateMinMaxLatLngCoords cannot calculate');
        }

        minLat = point.latitude;
        minLong = point.longitude;
        maxLat = point.latitude;
        maxLong = point.longitude;
      }
      minLat = Math.min(point.latitude, minLat);
      minLong = Math.min(point.longitude, minLat);
      maxLat = Math.max(point.latitude, maxLat);
      maxLong = Math.max(point.longitude, maxLong);
    });
    minLatLongArr.push(minLat);
    minLatLongArr.push(minLong);
    maxLatLongArr.push(maxLat);
    maxLatLongArr.push(maxLong);
    return [minLatLongArr, maxLatLongArr];
  }
}
