import { IShortError } from '@core/models/common.model';
import { createSelector } from '@ngrx/store';

import { selectAuth } from './auth.selectors';
import * as AuthReducers from '../reducers';
import * as ForgotPasswordReducer from '../reducers/forgot-password.reducer';

export const selectForgotPassword = createSelector(
  selectAuth,
  (state: AuthReducers.AuthState) => state[ForgotPasswordReducer.forgotPasswordFeatureKey]
);

export const selectForgotPasswordEmail = createSelector(
  selectForgotPassword,
  (state: ForgotPasswordReducer.State) => state.email
);

export const selectForgotPasswordLoading = createSelector(
  selectForgotPassword,
  (state: ForgotPasswordReducer.State) => state.loading
);

const selectForgotPasswordError = createSelector(
  selectForgotPassword,
  (state: ForgotPasswordReducer.State) => state.error
);

export const selectForgotPasswordEmailNotExist = createSelector(
  selectForgotPasswordError,
  (error: IShortError) => error?.status === 404 && error?.reason === 'not.found'
);
