<div *ngIf="readonlyFields$ | async as flags" class="azz-details-page azz-info-blocks-row azz-last pragmatic">
  <div class="azz-info-block">
    <form [formGroup]="form" #formElemRef>
      <!-- CREATE NEW ORDER HEADER -->
      <div class="row">
        <div class="col-9">
          <div class="azz-nav-block">
            <div class="azz-back-title azz-font-bold">{{ getHeaderText() }}</div>
          </div>
        </div>
      </div>

      <!-- CUSTOMER SECTION -->
      <div class="row">
        <div class="col-9">
          <div class="azz-header">{{ 'PA_CREATE_NEW_ORDER_CUSTOMER_INFO_HEADER' | translate }}</div>
          <div class="azz-control-group" formGroupName="customer">
            <!-- TODO: extract into custom form control -->
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="custom-radio">
                    {{ 'PA_SEARCH_CUSTOMER' | translate }}
                    <input
                      id="search_customer"
                      formControlName="type"
                      type="radio"
                      checked="checked"
                      name="type"
                      value="old"
                      [readonly]="flags?.isFieldReadonlyFor?.customer"
                      (change)="changeCustomer()"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              @if (!isEditingMode) {
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="custom-radio">
                      {{ 'PA_CREATE_CUSTOMER' | translate }}
                      <input
                        id="new_customer"
                        type="radio"
                        formControlName="type"
                        name="type"
                        value="new"
                        (change)="changeCustomer()"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              }
            </div>
            <div class="row">
              @if (form.getRawValue()?.customer?.type === 'old') {
                <div class="form-group col-md-6">
                  <label [for]="'history-search'" class="azz-transparent-input-label">
                    {{ 'PA_CREATE_NEW_ORDER_CUSTOMER_SEARCH_HEADER' | translate }}
                  </label>
                  <div class="azz-has-addon">
                    <div class="azz-search-addon"></div>
                    <azz-customer-typeahead
                      ngId="history-search"
                      [isDisabled]="flags?.isFieldReadonlyFor?.customer"
                      (selectCustomer$)="onCustomerSelect($event)"
                      (typing)="resetCustomer()"
                      (createCustomer$)="switchToCreateCustomer($event)"
                      (searchValues$)="checkCustomerAutocompleteRules($event)"
                    >
                    </azz-customer-typeahead>
                  </div>
                </div>

                <div class="form-group col-md-6" *ngIf="selectedCustomer?.phones?.length">
                  <label for="customer-phone-number" class="azz-transparent-input-label">
                    {{ 'PHONE_NUMBER' | translate }}
                  </label>
                  <select
                    id="customer-phone-number"
                    name="customer_phone_number"
                    class="form-control azz-transparent-input azz-validate azz-expandable"
                    azzFocusOnInputChange
                    [formControl]="form.controls.selectedCustomerPhoneNumber"
                    [focusTriggerCounter]="focusTriggerCounters.customerPhones"
                  >
                    <option *ngFor="let item of selectedCustomer?.phones" [value]="item.phoneCode + item.phoneNumber">
                      {{ item?.phoneCode }}{{ item.phoneNumber }}
                    </option>
                  </select>
                </div>
              } @else {
                <div class="form-group col-md-6">
                  <label for="lastName" class="azz-transparent-input-label">{{ 'LAST_NAME' | translate }}</label>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    maxlength="100"
                    formControlName="lastName"
                    class="form-control azz-transparent-input azz-validate"
                    [disabled]="flags?.isFieldReadonlyFor?.customer"
                    (input)="syncPassengerName()"
                  />
                  <div
                    class="form-control__error"
                    *ngIf="
                      form.controls?.customer?.controls?.lastName?.errors &&
                      form.controls?.customer?.controls.lastName?.touched
                    "
                  >
                    <small *ngIf="form.controls.customer.controls.lastName.errors?.required">
                      {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
                    </small>
                    <small *ngIf="form.controls.customer.controls.lastName.errors?.azzNotFrLettersAndNums">
                      {{ 'CORE_ONLY_LETTERS_NUMBERS_VALIDATION_ERROR' | translate }}
                    </small>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="firstName" class="azz-transparent-input-label">{{ 'FIRST_NAME' | translate }}</label>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    maxlength="100"
                    formControlName="name"
                    class="form-control azz-transparent-input azz-validate"
                    [disabled]="flags?.isFieldReadonlyFor?.customer"
                    (input)="syncPassengerName()"
                  />
                  <div
                    class="form-control__error"
                    *ngIf="
                      form.controls?.customer?.controls?.name?.errors &&
                      form.controls?.customer?.controls?.name?.touched
                    "
                  >
                    <small *ngIf="form.controls.customer?.controls?.name.errors?.azzNotFrLettersAndNums">
                      {{ 'CORE_ONLY_LETTERS_NUMBERS_VALIDATION_ERROR' | translate }}
                    </small>
                  </div>
                </div>

                <div class="form-group col-md-5" [class.has-error]="phoneAlreadyExists">
                  <label for="phone_number" class="azz-transparent-input-label">
                    {{ 'PHONE_NUMBER' | translate }}
                  </label>
                  <azz-phone inputId="phone_number" formControlName="phoneNumber" class="azz-phone-customer" />

                  <div class="form-control__error">
                    <small *ngIf="customerPhoneControl?.errors?.pattern && customerPhoneControl?.dirty">
                      {{ 'CORE_INVALID_PHONE_NUMBER_VALIDATION_ERROR' | translate }}
                    </small>
                    <small *ngIf="phoneAlreadyExists">
                      {{ 'PHONE_NUMBER_ALREADY_EXISTS' | translate }}
                    </small>
                    <small *ngIf="phoneAlreadyExists" class="link" (click)="redirectToExistingClient()">
                      {{ 'REDIRECT_TO_EXISTING_CLIENT' | translate }}
                    </small>
                    <small *ngIf="customerPhoneControl.errors?.minLength && customerPhoneControl?.dirty">
                      {{ 'CORE_PHONE_NUMBER_NUM_LENGTH' | translate: { num: 9 } }}
                    </small>
                    <small *ngIf="customerPhoneControl.errors?.maxLength && customerPhoneControl?.dirty">
                      {{ 'CORE_PHONE_NUMBER_NUM_LENGTH' | translate: { num: 9 } }}
                    </small>
                  </div>
                </div>
                <div class="form-group col-md-5" *ngIf="isPhoneTypeAvailable()">
                  <label class="azz-transparent-input-label">{{ 'PA_CUSTOMERS_PHONE_TYPE' | translate }}*</label>
                  <div class="azz-fake-input" *ngIf="isMobileNumber(customerPhoneControl.value); else elsePhoneType">
                    {{ 'PA_CUSTOMERS_MOBILE_PHONE' | translate }}
                  </div>
                  <ng-template #elsePhoneType>
                    <div class="azz-fake-input">
                      {{ 'PA_CUSTOMERS_LANDLINE_PHONE' | translate }}
                    </div>
                  </ng-template>
                </div>
              }
            </div>
          </div>
        </div>
        <div class="col-3 create__summary-bg">
          @if (selectedCustomer) {
            <azz-customer-summary-block [customerData]="selectedCustomer" (setCreateOrderState)="setCreateOrderState()">
            </azz-customer-summary-block>
          }
        </div>
      </div>

      <!-- ORDER INFO SECTION -->
      <div class="row">
        <div class="col-9">
          <div class="azz-header">
            {{ 'PA_CREATE_NEW_ORDER_ORDER_INFORMATION_HEADER' | translate }}
          </div>
        </div>
        <div class="col-3 create__summary-bg"></div>
        <div class="col-9">
          <div class="azz-control-group">
            <div class="row" formGroupName="order">
              <div class="col-md-3">
                <azz-date-popup-control label="FN_NEWS_DETAILS_DATE_LABEL" formControlName="date" [range]="dateRange">
                </azz-date-popup-control>
              </div>
              <div class="col-md-3">
                <azz-date-popup-control
                  label="HOURS"
                  formControlName="time"
                  [isTime]="true"
                  [type]="'timer'"
                  [showNowTitle]="showNowLabelForOrderTime$ | async"
                ></azz-date-popup-control>
              </div>
            </div>

            <div class="row">
              <div class="form-group create__address-block">
                <label [for]="'departure-address'" class="azz-transparent-input-label">
                  {{ 'FR_DETAILS_APPOINTMENT_ADDRESS_LABEL' | translate }}
                </label>
                <azz-address-poi-autocomplete
                  #departureAddressRef
                  ngId="departure-address"
                  addressEqualityError="DEPARTURE_ADDRESS_SAME_AS_ARRIVAL_ADDRESS"
                  [withPoi]="true"
                  [orderServiceType]="form.value?.serviceType?.id"
                  [correspondingAddress]="arrivalAddress"
                  [isDepartureAddressOutsideOfBusinessZone]="isDepartureAddressOutsideOfBusinessZone"
                  (selectAddress)="onDepartureAddressSelect($event)"
                  (typing)="onDepartureAddressTyping()"
                >
                </azz-address-poi-autocomplete>
              </div>
              <div class="form-group create__invert">
                <button
                  [disabled]="
                    !departureAddress ||
                    !arrivalAddress ||
                    departureAddressRef?.form?.invalid ||
                    arrivalAddressRef?.form?.invalid
                  "
                  class="create__invert-btn"
                  type="button"
                  (click)="invertAddresses()"
                ></button>
              </div>
              <div class="create__address-block form-group">
                <label [for]="'arrival-address'" class="azz-transparent-input-label">
                  {{ 'FR_DETAILS_DESTINATION_ADDRESS_LABEL' | translate }}</label
                >
                <azz-address-poi-autocomplete
                  #arrivalAddressRef
                  ngId="arrival-address"
                  addressEqualityError="ARRIVAL_ADDRESS_SAME_AS_DEPARTURE_ADDRESS"
                  [withPoi]="true"
                  [orderServiceType]="form.value?.serviceType?.id"
                  [correspondingAddress]="departureAddress"
                  [customFocusTriggerCounter]="focusTriggerCounters.destinationAddress"
                  (selectAddress)="onArrivalAddressSelect($event)"
                  (typing)="onArrivalAddressTyping()"
                >
                </azz-address-poi-autocomplete>
              </div>
            </div>

            <div class="row" *ngIf="!isEditingMode">
              <div class="col-md-7">
                <div class="form-group"></div>
              </div>
              <div class="col-md-5">
                <div class="form-group validate-additional-button" [class.disabled]="isValidateButtonDisabled">
                  <azz-spinner-button7
                    [buttonType]="'button'"
                    [customFocusTriggerCounter]="focusTriggerCounters.submitButton"
                    [azzDisabled]="isValidateButtonDisabled"
                    [azzClass]="'btn btn-block azz-button azz-bg-green azz-color-white'"
                    [azzValue]="(isEditingMode ? 'UPDATE' : 'FD_VALIDATE_BUTTON') | translate"
                    (click)="isEditingMode ? onUpdateOrder() : onValidateNewOrderButtonClick()"
                  >
                  </azz-spinner-button7>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 flex align-center">
                <azz-labeled-input
                  [label]="'PA_CREATE_NEW_ORDER_ORDER_DEPART_COMMENT' | translate"
                  formControlName="appointmentComment"
                ></azz-labeled-input>
              </div>
              <div class="col-md-6 flex align-center">
                <azz-labeled-input
                  formControlName="destinationComment"
                  [label]="'PA_CREATE_NEW_ORDER_ORDER_DEST_COMMENT' | translate"
                ></azz-labeled-input>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 create__summary-bg">
          <app-summary-block
            [orderDate]="(orderSubGroup.controls.date.valueChanges | async) || orderSubGroup.controls.date.value"
            [orderTime]="(orderSubGroup.controls.time.valueChanges | async) || orderSubGroup.controls.time.value"
            [passenger]="form.value.passengerName"
            [comment]="form.value.comment"
            [distanceEstimation]="geoMeasurmentCourseEstimation.distance"
            [durationEstimation]="geoMeasurmentCourseEstimation.duration"
            [distanceETA]="geoMeasurmentETAForImmediateOrder.distance"
            [durationETA]="geoMeasurmentETAForImmediateOrder.duration"
            [estimatedPrice]="estimatedPrice"
            [tags]="getSelectedFilters()"
            [estimatedTime]="''"
            [noRoute]="noRoute"
            [customerData]="selectedCustomer"
            [isEtaLoading]="loadWrapper.isLoading$ | async"
            [estimatedPriceLoading]="isEstimatedPriceLoading"
            [isEstimatedPriceShown]="isEstimatedPriceShown"
          >
          </app-summary-block>
          @if (customerPreviousOrders?.length > 0) {
            <azz-customer-previous-order
              [customerPrevOrders]="customerPreviousOrders"
              (setPreviousOrderData$)="setPreviousOrderData($event)"
            >
            </azz-customer-previous-order>
          }
        </div>
        <div class="col-9 create__mrg-btm">
          <div class="row">
            <div class="col-md-6 mb-4 flex align-center" *ngIf="!form.value.anonymousPassenger">
              <azz-labeled-input
                formControlName="passengerName"
                [label]="'PA_CREATE_NEW_ORDER_PASSENGER_NAME' | translate"
              ></azz-labeled-input>
            </div>
            <div class="col-md-6 flex align-center">
              <azz-checkbox label="ANONYMOUS_PASSENGER" formControlName="anonymousPassenger"></azz-checkbox>
            </div>
          </div>

          <div class="azz-header">
            {{ 'PA_CREATE_NEW_ORDER_VEHICLE_HEADER' | translate }}
          </div>

          <azz-order-service-vehicle-type
            [form]="form"
            [departureAddress]="departureAddress"
            [arrivalAddress]="arrivalAddress"
          >
          </azz-order-service-vehicle-type>

          <div class="azz-header">
            {{ 'PA_CREATE_NEW_ORDER_PAYMENT_METHOD_HEADER' | translate }}
          </div>

          <div class="azz-control-group">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="payment_type" class="azz-transparent-input-label">
                  {{ 'ORDER_DETAILS_PAYMENT_MODE_LABEL' | translate }}
                </label>
                <select
                  id="payment_type"
                  name="payment_type"
                  formControlName="paymentType"
                  class="form-control azz-transparent-input azz-expandable"
                  [compareWith]="compareFn"
                >
                  <option *ngFor="let payment of uiTypes.payment" [ngValue]="payment">
                    {{ 'PAYMENT_TYPE_' + payment?.id | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <attribute-tags
            [listOfTags]="listOfTags"
            [isTagsDisabled]="isTagsDisabled"
            (setChangeTag$)="onChangeTag()"
          ></attribute-tags>

          <div class="azz-control-group create__reset-mrg-btm">
            <div class="row">
              <div class="form-group col-md-12 create__reset-mrg-btm">
                <label for="comment" class="azz-transparent-input-label">
                  {{ 'PA_CREATE_INFO_PROVISION' | translate }}</label
                >
                <textarea
                  tabindex="-1"
                  id="comment"
                  type="text"
                  name="comment"
                  formControlName="comment"
                  maxlength="128"
                  rows="1"
                  class="form-control azz-transparent-input azz-validate"
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 create__summary-bg create__mrg-btm">
          <div class="row" *ngIf="form.value.passengerName || form.value.anonymousPassenger">
            <div class="col-md-12 summary-customer-info passenger-info-margin">
              <div class="info-row">
                <span class="label">{{ 'PA_CREATE_PASSENGER' | translate }}:</span>
                <span class="value">
                  {{ form.value.anonymousPassenger ? anonymousPassengerLabel : form.value.passengerName }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ACTION BUTTONS' SECTION -->
      <div class="create__pd-btm">
        <div class="row justify-content-center flex-row-reverse">
          <div [ngClass]="isValidateButtonDisabled ? 'col-md-4 disabled' : 'col-md-4'">
            <azz-spinner-button7
              [buttonType]="'button'"
              [loadingIndicator]="isButtonBlocked"
              [azzDisabled]="isValidateButtonDisabled"
              [azzClass]="'btn btn-block azz-button azz-bg-green azz-color-white'"
              [azzValue]="submitButtonTitle$ | async"
              (click)="isEditingMode ? onUpdateOrder() : onValidateNewOrderButtonClick()"
            >
            </azz-spinner-button7>
          </div>
          <div class="col-md-4">
            <azz-spinner-button7
              [buttonType]="'button'"
              [azzClass]="'btn btn-block azz-button azz-bg-red azz-color-white'"
              [azzValue]="'CANCEL' | translate"
              (click)="onCancelBtnClick()"
            >
            </azz-spinner-button7>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<azz-order-pre-save-dialog
  [isPassengerAnonymous]="form.controls?.anonymousPassenger?.value"
  [passengerName]="form.controls?.passengerName?.value"
  [distance]="geoMeasurmentCourseEstimation.distance"
  [duration]="geoMeasurmentCourseEstimation.duration"
  [estimatedPrice]="estimatedPrice"
  [estimatedPriceLoading]="isEstimatedPriceLoading"
  [isEstimatedPriceShown]="isEstimatedPriceShown"
  [addresses]="addresses"
  [customer]="selectedCustomer"
  [newCustomer]="newCustomer"
  [selectedCustomerPhoneNumber]="form.value.selectedCustomerPhoneNumber"
  [customerType]="form.value.customer?.type"
  [tags]="getSelectedFilters()"
  [paymentType]="form.controls?.paymentType?.value"
  (azzConfirm)="submit()"
>
</azz-order-pre-save-dialog>

<azz-custom-confirm-dialog (azzClose)="resetUrlStateAndReloadPage()"> </azz-custom-confirm-dialog>
