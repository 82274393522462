import { DriverOperationType } from '@core/constant';
import { createAction, props } from '@ngrx/store';

import { IColorManagementFull } from '../../../../models/color-management.model';
import { IDriverLicense, IDriverLicenseKind } from '../../../../models/driver-license.model';
import { IUpdateDriverByFleetPayload, IValidateDriverPayload } from '../../../../models/driver.model';
import { ICountry } from '../../../../models/fleet.model';
import { IDriverOrdersStats } from '../../../../models/stats.model';
import { ITag } from '../../../../models/tag.model';
import { ITransportLicense } from '../../../../models/transport-license.model';
import { IDriverUserInfo } from '../../../../models/user-info.model';
import { IVehicle } from '../../../../models/vehicle.model';

export const init = createAction('[Driver Details] Init');
export const destroy = createAction('[Driver Details] Destroy');
export const getDriverLicenseKinds = createAction('[Driver Details] Get Driver License Kinds');
export const getDriverLicenseKindsSuccess = createAction(
  '[Driver Details] Get Driver License Kinds Success',
  props<{ driverLicenseKinds: IDriverLicenseKind[] }>()
);
export const getDriverLicenseKindsError = createAction('[Driver Details] Get Driver License Kinds Error');
export const getDriverLicense = createAction('[Driver Details] Get Driver License', props<{ driverId: number }>());
export const getDriverLicenseSuccess = createAction(
  '[Driver Details] Get Driver License Success',
  props<{ driverLicense: IDriverLicense }>()
);
export const getDriverLicenseError = createAction('[Driver Details] Get Driver License Error');
export const getOrdersStats = createAction('[Driver Details] Get Orders Stats', props<{ driverId: number }>());
export const getOrdersStatsSuccess = createAction(
  '[Driver Details] Get Orders Stats Success',
  props<{ ordersStats: IDriverOrdersStats }>()
);
export const getOrdersStatsError = createAction('[Driver Details] Get Orders Stats Error');
export const getCountries = createAction('[Driver Details] Get Countries');
export const getCountriesSuccess = createAction(
  '[Driver Details] Get Countries Success',
  props<{ countries: ICountry[] }>()
);
export const getCountriesError = createAction('[Driver Details] Get Countries Error');
export const getVehicle = createAction('[Driver Details] Get Vehicle', props<{ driverId: number }>());
export const getVehicleSuccess = createAction('[Driver Details] Get Vehicle Success', props<{ vehicle: IVehicle }>());
export const getVehicleError = createAction('[Driver Details] Get Vehicle Error');
export const getTransportLicense = createAction(
  '[Driver Details] Get Transport License',
  props<{ vehicleId: number }>()
);
export const getTransportLicenseSuccess = createAction(
  '[Driver Details] Get Transport License Success',
  props<{ transportLicense: ITransportLicense }>()
);
export const getTransportLicenseError = createAction('[Driver Details] Get Transport License Error');
export const getDriver = createAction(
  '[Driver Details] Get Driver',
  props<{ driverId: number; colorManagement: boolean }>()
);
export const getDriverSuccess = createAction(
  '[Driver Details] Get Driver Success',
  props<{ driver: IDriverUserInfo }>()
);
export const getDriverError = createAction('[Driver Details] Get Driver Error');
export const getValidatedDriver = createAction('[Driver Details] Get Validated Driver', props<{ driverId: number }>());
export const getValidatedDriverSuccess = createAction(
  '[Driver Details] Get Validated Driver Success',
  props<{ driver: IDriverUserInfo }>()
);
export const getValidatedDriverError = createAction('[Driver Details] Get Validated Driver Error');
export const getColorManagement = createAction('[Driver Details] Get Color Management');
export const getColorManagementSuccess = createAction(
  '[Driver Details] Get Color Management Success',
  props<{ colorManagement: IColorManagementFull }>()
);
export const getColorManagementError = createAction('[Driver Details] Get Color Management Error');
export const getDriverTags = createAction('[Driver Details] Get Driver Tags', props<{ driverId: number }>());
export const getDriverTagsSuccess = createAction('[Driver Details] Get Driver Tags Success', props<{ tags: ITag[] }>());
export const getDriverTagsError = createAction('[Driver Details] Get Driver Tags Error');
export const updateDriverRating = createAction(
  '[Driver Details] Update Driver Rating',
  props<{ driverId: number; rating: number }>()
);
export const updateDriverRatingSuccess = createAction(
  '[Driver Details] Update Driver Rating Success',
  props<{ driver: IDriverUserInfo }>()
);
export const updateDriverRatingError = createAction('[Driver Details] Update Driver Rating Error');
export const openConfirmPopup = createAction(
  '[Driver Details] Open Confirm Popup',
  props<{ operation: DriverOperationType }>()
);
export const closeConfirmPopup = createAction('[Driver Details] Close Confirm Popup');
export const blockDriver = createAction('[Driver Details] Block Driver', props<{ driverId: number }>());
export const blockDriverSuccess = createAction(
  '[Driver Details] Block Driver Success',
  props<{ driver: IDriverUserInfo }>()
);
export const blockDriverError = createAction('[Driver Details] Block Driver Error');
export const unblockDriver = createAction('[Driver Details] Unblock Driver', props<{ driverId: number }>());
export const unblockDriverSuccess = createAction(
  '[Driver Details] Unblock Driver Success',
  props<{ driver: IDriverUserInfo }>()
);
export const unblockDriverError = createAction('[Driver Details] Unblock Driver Error');
export const validateDriver = createAction(
  '[Driver Details] Validate Driver',
  props<{ driverId: number; payload: IValidateDriverPayload }>()
);
export const validateDriverSuccess = createAction('[Driver Details] Validate Driver Success');
export const validateDriverError = createAction('[Driver Details] Validate Driver Error');
export const confirmTransportLicense = createAction(
  '[Driver Details] Confirm Transport License',
  props<{ transportLicenseId: number }>()
);
export const confirmTransportLicenseSuccess = createAction('[Driver Details] Confirm Transport License Success');
export const confirmTransportLicenseError = createAction('[Driver Details] Confirm Transport License Error');
export const rejectTransportLicense = createAction(
  '[Driver Details] Reject Transport License',
  props<{ transportLicenseId: number }>()
);
export const rejectTransportLicenseSuccess = createAction('[Driver Details] Reject Transport License Success');
export const rejectTransportLicenseError = createAction('[Driver Details] Reject Transport License Error');
export const saveDriver = createAction(
  '[Driver Details] Save Driver',
  props<{ payload: IUpdateDriverByFleetPayload; serviceVehicleTypeTagsIds: string[] }>()
);
export const saveDriverSuccess = createAction(
  '[Driver Details] Save Driver Success',
  props<{ response: IUpdateDriverByFleetPayload }>()
);
export const saveDriverError = createAction('[Driver Details] Save Driver Error');
export const updateDriverTags = createAction(
  '[Driver Details] Update Driver Tags',
  props<{ serviceVehicleTypeTagsIds: string[] }>()
);
export const updateDriverTagsSuccess = createAction(
  '[Driver Details] Update Driver Tags Success',
  props<{ tags: ITag[] }>()
);
export const updateDriverTagsError = createAction('[Driver Details] Update Driver Tags Error');
