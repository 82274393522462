import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { StorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AuthModule } from '@auth/auth.module';
import { environment } from '@azz/env';
import { CustomErrorHandler } from '@common/custom-error-handler';
import { DatePickerModule } from '@common/date-picker/date-picker.module';
import { CustomInterceptor } from '@common/interceptor';
import { LayoutComponent } from '@core/components/layout/layout.component';
import { AdvertModule } from '@core/modules';
import { LogoutPopupModule } from '@core/modules/custom-dialog/logout-popup/logout-popup.module';
import { AppStore } from '@core/store';
import { AppEffects } from '@core/store/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { CustomRouterStateSerializer } from '@store/reducers/router.reducer';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';

// required for AOT compilation
export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const appInitializerFactory = (translate: TranslateService) => () => {
  translate.setDefaultLang('fr');
  return lastValueFrom(translate.use('fr'));
};

// const FIREBASE_CONF = new InjectionToken<AzzFirebaseConfig>('FirebaseConfig');
@NgModule({
  declarations: [AppComponent],
  imports: [
    StorageModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    StoreModule.forRoot(AppStore.reducers),
    EffectsModule.forRoot(AppEffects),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 50 }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    LeafletModule,
    LogoutPopupModule,
    AdvertModule,
    DatePickerModule,
    NgbModule,
    NgSelectModule,
    CoreModule,
    LayoutComponent,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)), // eslint-disable-line
    provideAuth(() => getAuth()), // eslint-disable-line
    provideFirestore(() => getFirestore()), // eslint-disable-line
    // provideStorage(() => getStorage(getApp())),
    provideDatabase(() => getDatabase()), // eslint-disable-line
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    environment.production ? { provide: ErrorHandler, useClass: CustomErrorHandler } : [],
    MessageService,
    provideFirebaseApp(() => initializeApp(environment.firebase)), // eslint-disable-line
    provideAuth(() => getAuth()), // eslint-disable-line
    provideFirestore(() => getFirestore()), // eslint-disable-line
    // provideStorage(() => getStorage(getApp())),
    provideDatabase(() => getDatabase()), // eslint-disable-line
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
