import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomControlBase } from '@common/components/forms/custom-control.base';
import moment from 'moment';
import { InputMaskModule } from 'primeng/inputmask';

const regExpAlphabet = /[a-zA-Z|_]/g;

@Component({
  selector: 'azz-old-time',
  standalone: true,
  imports: [InputMaskModule, FormsModule, AsyncPipe],
  templateUrl: './time.component.html',
  styleUrl: './time.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OldTimeComponent),
      multi: true,
    },
  ],
})
export class OldTimeComponent extends CustomControlBase<string> {
  @Input() public isErrorState: boolean = false;

  protected readonly timeSlot = 'HH:mm';
  protected readonly timeFormat = '99:99';

  private set(time: string): void {
    // todo: move this to commander when we get rid of the old commander
    if (!time) {
      this.value$.next(null);
      time = moment(new Date()).format('HH:mm');

      setTimeout(() => {
        this.value$.next(time);
      });
    } else {
      this.value$.next(time);
    }
    this.onChange(time);
  }

  private setMaskedTime(value: string): void {
    this.onTouched();

    if (!value) {
      this.set(undefined);
      return;
    }

    const invalid = regExpAlphabet.test(value);
    if (invalid) {
      this.set(null);
      return;
    }

    const [hh, mm] = value.split(':');
    if (+hh > 23 || +mm > 59) {
      this.set(null);
      return;
    }

    this.set(value);
  }

  protected blur(ev: Event): void {
    const target = ev.target as HTMLInputElement;

    this.setMaskedTime(target.value);
  }
}
