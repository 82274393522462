import { Attribute, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { helpers } from '@core/modules/nouvelle-commande/components/date-time/date/helpers';
import moment, { Moment } from 'moment';

@Component({
  selector: 'azz-date-popup-control',
  template: `
    <label [for]="id" class="azz-transparent-input-label">
      {{ label | translate }}
    </label>
    <div class="relative">
      <input
        azzOpenOnEnterClick
        class="form-control azz-transparent-input azz-validate"
        #dateInput
        [id]="id"
        [name]="id"
        [formControl]="innerControl"
        [min]="range.min"
        [max]="range.max"
        [owlDateTimeTrigger]="datePickerRef"
        [owlDateTime]="datePickerRef"
      />
      <div *ngIf="showNowTitle && innerControl.enabled" class="date-picker-now" [owlDateTimeTrigger]="datePickerRef">
        {{ 'PA_CREATE_NOW' | translate }}
      </div>
      <button
        tabindex="-1"
        class="toggle-date-picker-btn"
        [class]="type === 'timer' ? 'toggle-time-btn' : 'toggle-date-btn'"
        [owlDateTimeTrigger]="datePickerRef"
      ></button>
      <owl-date-time
        #datePickerRef
        [pickerType]="type"
        [firstDayOfWeek]="1"
        (afterPickerClosed)="onAfterPickerClosed(dateInput)"
      />
    </div>
  `,
  styles: [
    `
      :host {
        position: relative;
      }

      .date-picker-now {
        position: absolute;
        z-index: 1;
        width: 85%;
        height: 33px;
        padding-top: 5px;
        padding-bottom: 5px;
        top: 0;
        left: 0;
        background-color: white;
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DatePopupControlComponent, multi: true }],
})
export class DatePopupControlComponent implements OnInit, ControlValueAccessor {
  @Input() range: { min: Moment; max: Moment } = { min: moment().startOf('day'), max: null };
  @Input() label: string;
  @Input() type: 'calendar' | 'timer' = 'calendar';
  @Input() showNowTitle = false;
  @Input() isTime: boolean = false;
  public readonly id: string | number = '';

  public readonly innerControl = new FormControl<Moment>(null);

  public onChange: (date: string | Moment) => void;
  public onTouch: () => void;

  constructor(@Attribute('label') label: string, @Attribute('formControlName') id: string) {
    this.label = label;
    this.id = id;
  }

  ngOnInit(): void {}

  writeValue(selectedDate: Moment | string): void {
    if (!moment.isMoment(selectedDate)) {
      const dateStr = selectedDate as string;

      if (this.isTime) {
        this.innerControl.setValue(
          selectedDate ? helpers.convertDateTimeStrToMoment(helpers.getCurrentDateStr(), dateStr) : null
        );
      } else {
        this.innerControl.setValue(selectedDate ? helpers.convertDateStrToMoment(dateStr) : null);
      }
    } else {
      this.innerControl.setValue(selectedDate);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.innerControl?.disable();
    } else {
      this.innerControl?.enable();
    }
  }

  onAfterPickerClosed(ref?: HTMLInputElement): void {
    ref?.focus();
    this.showNowTitle = false;

    if (this.isTime) {
      this.onChange(this.innerControl.value.format('HH:mm'));
    } else {
      this.onChange(this.innerControl.value);
    }
  }
}
