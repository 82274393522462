/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PointResponseWebApiModel } from './point-response-web.api-model';

/**
 * object with info about address in order info responses
 */
export interface OrderAddressResponseWebApiModel {
  point?: PointResponseWebApiModel;
  /**
   * label that could be specified in orders list page. \'[number][street],[city]\' or \'[poi.label]\'
   */
  label: string;
  /**
   * postal code
   */
  postal?: string;
  /**
   * city
   */
  city: string;
  /**
   * street
   */
  streetName?: string;
  /**
   * building number
   */
  streetNumber?: string;
  /**
   * poi ID
   */
  poiId?: string;
}
