import { OrderType } from '@core/constant/order.constant';
import { TIME } from '@core/constant/time.constant';
import { helpers } from '@core/modules/nouvelle-commande/components/date-time/date/helpers';
import moment from 'moment';

export const defineOrderType = (data: { time: string; date: string }): OrderType => {
  if (!data.date || !data.time) {
    return 'ORDER';
  }

  const now = moment();
  const maxMinutesForImmediate = 29;
  const maxMinutesForReservation = TIME.HOURS_IN_DAY * TIME.MINUTES_IN_HOUR;

  const orderDate = helpers.convertDateTimeStrToMoment(data.date, data.time);

  const minutesDifference = orderDate.diff(now, 'minute');

  if (minutesDifference <= maxMinutesForImmediate) {
    return 'ORDER';
  } else if (minutesDifference > maxMinutesForImmediate && minutesDifference <= maxMinutesForReservation - 1) {
    return 'IN_ADVANCE';
  } else {
    return 'RESERVATION';
  }
};
