<div class="azz-info-blocks-row azz-last" *ngIf="sessionsCountArr?.length">
  <div class="azz-info-block">
    <azz-sessions-count [sessionsCountArr]="sessionsCountArr" [totalSessionsCount]="totalSessionsCount">
    </azz-sessions-count>
  </div>
</div>

<div class="azz-info-blocks-row azz-last">
  <div class="azz-info-block">
    <azz-loading-block [isLoading]="isMapLoading$ | async">
      <azz-sessions-map
        [userRole]="userRole"
        [ordersRoute]="routes.orders"
        [driversRoute]="routes.drivers"
      ></azz-sessions-map>
    </azz-loading-block>
  </div>
</div>

<div class="azz-info-blocks-row azz-last">
  <div class="azz-info-block">
    <div class="row align-items-center">
      <div class="form-group col-lg-2">
        <label for="session-status" class="azz-transparent-input-label">
          {{ 'FLEET_SESSIONS_SELECT_STATUS_LABEL' | translate }}
        </label>
        <select
          id="session-status"
          name="history_status"
          class="form-control azz-transparent-input azz-expandable"
          (change)="onFilterByStatus()"
          [(ngModel)]="filterData.status"
        >
          @for (status of availableData.statuses; track status.name) {
            <option [value]="status.value">{{ status.name }}</option>
          }
        </select>
      </div>
      <div class="form-group col-lg-auto">
        <button class="custom-btn custom-btn--orange azz-sessions-refresh-btn" (click)="onAutoRefreshBtnClick()">
          {{ 'FLEET_SESSIONS_REFRESH' | translate }}
        </button>
      </div>
      <div class="form-group col-lg-auto">
        <input
          id="autorefresh"
          type="checkbox"
          name="autorefresh"
          (change)="onAutoRefresh()"
          [(ngModel)]="filterData.autorefresh"
        />
        <label for="autorefresh">
          {{ 'FLEET_SESSIONS_AUTO_REFRESH' | translate }}
        </label>
      </div>
      <div class="form-group col-lg-2">
        <label for="session-timeout" class="azz-transparent-input-label"> </label>
        <select
          id="session-timeout"
          name="session_timeout"
          [(ngModel)]="filterData.timeout"
          (ngModelChange)="onAutoRefresh()"
          class="form-control azz-transparent-input azz-expandable"
        >
          @for (timeout of sessionTimeouts; track timeout.value) {
            <option [value]="timeout.value">{{ timeout.name }}</option>
          }
        </select>
      </div>
    </div>
    <azz-loading-block [isLoading]="isLoading$ | async">
      <div class="azz-table-wrapper">
        <table class="table table-hover azz-table">
          <thead>
            <tr class="d-table-row">
              <th class="col-2">{{ 'FLEET_SESSIONS_COLUMN_DRIVER_NAME' | translate }}</th>
              <th class="col-1">{{ 'FD_TAXI_NUBMER_COL_HEADER' | translate }}</th>
              <th class="col-1">{{ 'FLEET_SESSIONS_COLUMN_STATUS' | translate }}</th>
              <th class="col-2">{{ 'FLEET_SESSIONS_COLUMN_ZONE' | translate }}</th>
              <th class="col-1">{{ 'FLEET_SESSIONS_COLUMN_SPEED' | translate }}</th>
              <th class="col-1">{{ 'FLEET_SESSIONS_COLUMN_BLOCKED_FOR_DISPATCH' | translate }}</th>
              <th class="col-2">{{ 'FLEET_SESSIONS_COLUMN_RATING' | translate }}</th>
              <th class="col-1">{{ 'FLEET_SESSIONS_COLUMN_STARTED' | translate }}</th>
              <th class="col-1">{{ 'FLEET_SESSIONS_COLUMN_LAST_UPDATED' | translate }}</th>
              <th class="col-1">{{ 'GO_ACTIONS_COL_HEADER' | translate }}</th>
            </tr>
          </thead>
          @if (sessionsData?.data.length > 0) {
            <tbody>
              <!-- IE scrolling fix -->
              @for (session of sessionsData?.data; track trackByFunc) {
                <tr class="azz-clickable" (click)="onSessionSelect($event, session)">
                  <td>
                    <span class="no-general-navigation">{{ session?.driverName }}</span>
                  </td>
                  <td>{{ session?.taxiNumber }}</td>
                  <td>{{ 'CORE_DRIVER_SESSION_STATUS_' + session?.status | translate }}</td>
                  <td>
                    <span *ngIf="session?.geoZoneName">
                      <span>{{ session?.geoZoneName }}</span>
                      <span>&nbsp;</span>
                      <span *ngIf="session?.driverQueuePosition">({{ session?.driverQueuePosition }})</span>
                    </span>
                  </td>
                  <td>{{ formatSpeed(session.speed) }}</td>
                  <td>
                    {{ session?.blockedForDispatch ? 'CORE_TRUE' : ('CORE_FALSE' | translate) }}
                  </td>
                  <td>
                    <ngb-rating
                      class="azz-driver-rating"
                      [(rate)]="session.rating"
                      [max]="5"
                      [resettable]="true"
                      [readonly]="true"
                    >
                      <ng-template let-fill="fill" let-index="index">
                        <span
                          class="glyphicon azz-margin-right-5px glyphicon-thumbs-up"
                          [class.glyphicon-thumbs-o-up]="fill === 100"
                        >
                        </span>
                      </ng-template>
                    </ngb-rating>
                  </td>
                  <td>{{ session?.driverSession?.startTime | date: 'dd.MM.yyyy, HH:mm:ss' }}</td>
                  <td>{{ lastTimeUpdated | date: 'dd.MM.yyyy, HH:mm:ss' }}</td>
                  <td (click)="$event.stopPropagation()">
                    <div class="session-buttons">
                      <div
                        class="azz-finish-session center-block"
                        [ngbTooltip]="'FLEET_POPUP_CLOSE_SESSION' | translate"
                        (click)="deleteSession(session)"
                      ></div>
                      @if (session?.canCancel) {
                        <div
                          class="azz-change-session center-block"
                          [ngbTooltip]="'FLEET_POPUP_CHANGE_SESSION' | translate"
                          (click)="tryToFreeDriverFromSession(session)"
                        ></div>
                      }
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          }
        </table>
      </div>
      <azz-table-no-matches [azzVisible]="!sessionsData?.data.length"></azz-table-no-matches>
      <azz-pagination
        (goToPrevPage)="prevPage()"
        (goToNextPage)="nextPage()"
        [prevDisabled]="isPrevDisabled()"
        [nextDisabled]="isNextDisabled()"
      >
      </azz-pagination>
    </azz-loading-block>
  </div>
</div>
