/**
 * Possible web client origins per each environment
 */
export const ENV_HOSTS = {
  prod: ['rn7.g7.fr', 'prod-proxy-rn7.g7.fr', 'www.rn7.g7.fr'],
  pretest: ['rn7-g7-pretest-421518.appspot.com', 'pretest-proxy-rn7.g7.fr'],
  stage: ['azzapp-v11-test.appspot.com', 'stage-proxy-rn7.g7.fr'],
  preprod: ['rn7-g7-preprod.appspot.com', 'test-proxy-rn7.g7.fr'],
  develop: ['rn7-develop.appspot.com', 'develop-proxy-rn7.g7.fr'],
  local: ['localhost:4200'],
  docker: ['azzapp-monorepo-frontend-1', 'localhost:8085']
};
