import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PhoneAdvisorOrder } from '../../../../models/phone-advisor-order';

@Component({
  selector: 'azz-customer-previous-order',
  templateUrl: './customer-previous-order.component.html',
  styleUrls: ['./customer-previous-order.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerPreviousOrderComponent {
  @Input() customerPrevOrders: PhoneAdvisorOrder[];
  @Output() setPreviousOrderData = new EventEmitter<PhoneAdvisorOrder>();

  constructor() {}

  public onPreviousOrderClick(order: PhoneAdvisorOrder): void {
    this.setPreviousOrderData.emit(order);
  }
}
