import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { G7_ORDER_PROCESSING_STATUS } from '@core/constant/g7-order.constant';
import { G7Order } from '@core/models/g7-order.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from '@services/root.service';

import { FLEET_DISABLED_FIELDS_MAP, G7_DISABLED_FIELDS_MAP } from '../azz-trip-details.component';

@Component({
  selector: 'azz-editable-price',
  templateUrl: './editable-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditablePriceComponent {
  private readonly rootService = inject(RootService);

  @Input() public mode: 'fleet' | 'g7';
  @Output() public savePrice = new EventEmitter<number>();
  public oldPrice: number;
  // TODO: frontend  Operator '>' cannot be applied to types 'string | number' and 'number'.
  public price: number; // string cause of [(ngModel)]
  public isEditModeEnabled: boolean;
  private order: G7Order;
  private isEditable: boolean;
  private readonly minPrice = 0;
  public maxPrice: number;

  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store
  ) {}

  @Input() set orderData(order: G7Order) {
    this.order = order;
    this.isEditable = order?.clientAbsent
      ? !this.isProhibitedStatus()
      : !this.isProhibitedStatus() && !this.isOnboardPayment();
    this.oldPrice = order?.price;
    this.price = order?.price;
    this.maxPrice = order?.maxPrice;
  }

  public toggleEditMode(value: boolean): void {
    if (this.isEditable) {
      this.isEditModeEnabled = value;
    }
  }

  public onBlur(): void {
    if (!this.isPriceValid()) {
      this.price = 9.99;
    }
    this.toggleEditMode(false);
    this.savePrice.emit(this.price);
  }

  public keyDown(event: any) {
    const pattern = /[0-9]/;

    if (
      event.key === 'Delete' ||
      event.key === 'Backspace' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === '.'
    ) {
      return;
    }

    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  private isProhibitedStatus(): boolean {
    if (this.order?.firstPayment && this.order?.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_G7) {
      return G7_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    }
    if (this.mode === 'fleet') {
      return FLEET_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    } else if (this.mode === 'g7') {
      return G7_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    }
    return false;
  }

  private isOnboardPayment(): boolean {
    return this.order?.firstPayment;
  }

  public isPriceValid(): boolean {
    if (!this.price) {
      return false;
    } else if (this.minPrice && Number(this.price) < this.minPrice) {
      const message = this.translate.instant('CORE_MIN_TRIP_PRICE_ERROR_MESSAGE', {
        minPrice: this.minPrice,
      });
      this.rootService.alerts.error(message);
      return false;
    } else if (this.maxPrice && Number(this.price) > this.maxPrice) {
      const message = this.translate.instant('CORE_MAX_TRIP_PRICE_ERROR_MESSAGE', {
        maxPrice: this.maxPrice,
      });
      this.rootService.alerts.error(message);
      return false;
    } else {
      return true;
    }
  }
}
