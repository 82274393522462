<form [formGroup]="form" #formElemRef>
  <div class="azz-header">{{ 'FD_LICENSE_HEADER' | translate }}</div>
  <div class="row">
    <div class="col-md-6 form-group">
      <div class="azz-transparent-input-label">{{ 'STATUS' | translate }}*</div>
      <select
        id="driver-license-kind"
        name="driver_license_kind"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        formControlName="driverLicenseKind"
        [compareWith]="compareDriverLicenseKind"
        required
      >
        <option *ngFor="let kind of driverLicenseKinds; trackBy: trackDriverLicenseKind" [ngValue]="kind">
          {{ kind?.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 form-group">
      <div class="azz-transparent-input-label">{{ 'FD_DRIVER_LICENSE_LABEL' | translate }}</div>
      <a
        [href]="driverLicense?.fileUrl"
        class="azz-transparent-input-value"
        target="_blank"
        [ngClass]="{ 'azz-disabled-link': !driverLicense?.fileUrl }"
      >
        {{ 'FD_DOWNLOAD_DRIVER_LICENSE_LABEL' | translate }}
      </a>
    </div>

    <div class="col-md-6 form-group">
      <label for="driver-license-number">{{ 'FD_DRIVER_LICENSE_NUMBER_LABEL' | translate }}*</label>
      <input
        id="driver-license-number"
        type="text"
        maxlength="12"
        name="driver_license_number"
        class="form-control azz-transparent-input azz-validate"
        formControlName="driverLicenseNumber"
        required
      />
      <div
        class="form-control__error"
        *ngIf="form?.controls?.driverLicenseNumber?.errors && form.controls.driverLicenseNumber.touched"
      >
        <small *ngIf="form.controls?.driverLicenseNumber?.errors?.required">
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="form.controls?.driverLicenseNumber?.errors?.pattern">
          {{ 'CORE_ONLY_NUMBERS_FIELD_VALIDATION_ERROR' | translate }}
        </small>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <a
          [href]="transportLicense?.fileUrl"
          class="azz-transparent-input-value"
          target="_blank"
          [ngClass]="{ 'azz-disabled-link': !transportLicense.fileUrl }"
        >
          {{ 'FD_DOWNLOAD_TRANSPORT_LICENSE_LABEL' | translate }}
        </a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="transport-license-number">{{ 'FD_TRANSPORT_LICENSE_NUMBER_LABEL' | translate }}*</label>
        <input
          id="transport-license-number"
          type="text"
          name="transport_license_number"
          maxlength="20"
          class="form-control azz-transparent-input azz-validate"
          formControlName="transportLicenseNumber"
        />
        <!-- max length from comment https://redmine.noveogroup.com/issues/88102 -->
        <div
          class="form-control__error"
          *ngIf="form?.controls?.transportLicenseNumber?.errors && form.controls.transportLicenseNumber.touched"
        >
          <small *ngIf="form.controls?.transportLicenseNumber?.errors?.required">
            {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
          </small>
          <small *ngIf="form.controls?.transportLicenseNumber?.errors?.pattern">
            {{ 'CORE_ONLY_NUMBERS_FIELD_VALIDATION_ERROR' | translate }}
          </small>
        </div>
      </div>
    </div>
  </div>
</form>
