<div class="summary">
  <div class="azz-control-group reset-margin-bottom" *ngIf="orderType">
    <div class="row">
      <div class="col-md-12 summary-customer-info">
        <div class="info-row status-margin">
          <span class="label">{{ 'ORDER_TYPE_' + orderType | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="azz-control-group reset-margin-bottom">
    <div class="row">
      <div class="col-md-12 summary-customer-info">
        <div class="info-row">
          <div class="label">
            {{ 'CREATE_ORDER_ESTIMATIONS' | translate }}:
            <span *ngIf="noRoute" class="summary__no-route">{{ 'ROUTE_NOT_FOUND_DECISION' | translate }}</span>
          </div>
          <div>
            <div *ngIf="distanceETA === null && orderType === immediateOrder">
              <div class="label">
                {{ 'APPROACH_ETA' | translate }}
              </div>
              <div class="eta-block-label">
                <div class="exception-red"></div>
                <div class="no-driver">
                  {{ 'NO_DRIVERS_AVAILABLE' | translate }}
                </div>
              </div>
            </div>
            <div class="summary__estimations" *ngIf="orderType === immediateOrder">
              <div *ngIf="distanceETA" class="label">
                {{ 'APPROACH_ETA' | translate }}
              </div>
              <span class="summary__price-block">
                <azz-rotate-indicator7 [visible]="isEtaLoading"></azz-rotate-indicator7>
                <span class="label" *ngIf="distanceETA">{{ distanceETA | azzMetersToKilometers }} | </span>
                <span class="label" *ngIf="durationETA">{{ durationETA | azzSecondsToUIOrderTime }} </span>
              </span>
            </div>
          </div>
          <div *ngIf="distanceEstimation || durationEstimation || isEstimatedPriceShown">
            <div *ngIf="distanceEstimation" class="label">
              {{ 'COURSE_ESTIMATION' | translate }}
            </div>
            <div class="summary__estimations" *ngIf="!noRoute">
              <span class="label" *ngIf="distanceEstimation"
                >{{ distanceEstimation / 1000 | number: '1.0-1' }} km |
              </span>
              <span class="label" *ngIf="durationEstimation">{{ durationEstimation | azzSecondsToUIOrderTime }} </span>
              <span *ngIf="isEstimatedPriceShown" class="summary__price-block">
                <azz-rotate-indicator7 [visible]="estimatedPriceLoading" class="summary__price-rotate">
                </azz-rotate-indicator7>
                <span class="label" *ngIf="estimatedPrice">{{ estimatedPrice }} <span>&euro;</span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="azz-control-group reset-margin-bottom">
    <div class="row" *ngIf="tags.length">
      <div class="col-md-12 summary-customer-info">
        <div class="info-row">
          <span class="label">{{ 'PA_CREATE_TAGS' | translate }}:</span>
          <div class="in-column-list">
            <span class="value" *ngFor="let tag of tags">{{ 'CRC_TAG_' + tag.id | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="comment">
      <div class="col-md-12 summary-customer-info">
        <div class="info-row azz-word-break">
          <span class="label">{{ 'PA_CREATE_INFO_PROVISION' | translate }}: </span>
          <span class="value">{{ comment }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
