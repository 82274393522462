import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { InviteUsersByCsvComponent } from './invite-users-by-csv.component';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';
import { AttachFileModule } from '../attach-file/attach-file.module';

@NgModule({
  imports: [CommonModule, AttachFileModule, TranslateModule, AzzRotateIndicatorModule],
  declarations: [InviteUsersByCsvComponent],
  exports: [InviteUsersByCsvComponent],
})
export class InviteUsersByCsvModule {}
