import { CommonModule } from '@angular/common';
import { Directive, ElementRef, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[azzBlurOnTabClick]',
})
export class BlurOnClickTabDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(evt) {
    if (evt.key === 'Tab') {
      this.el.nativeElement.blur();
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [BlurOnClickTabDirective],
  exports: [BlurOnClickTabDirective],
})
export class BlurOnClickTabModule {}
