import { AuthStore } from '@auth/store';
import { Action, ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';

import { ITag } from '../../../../models/tag.model';
import { getTagsSuccessAction } from '../actions';

export const orderLogFeatureKey = 'order-logs';

export interface State {
  tags: ITag[];
}

const initialState: State = {
  tags: [],
};

const orderLogsReducer = createReducer(
  initialState,
  on(getTagsSuccessAction, (state: State, { tags }: { tags: ITag[] }) => ({ ...state, tags }))
);

export const reducer = (state: State | undefined, action: Action) => orderLogsReducer(state, action);

export const clearState =
  (reducerCleared: ActionReducer<State>): ActionReducer<State> =>
  (state: State | undefined, action: Action) => {
    if (action.type === AuthStore.logoutSuccess.type) {
      return reducerCleared(undefined, action);
    }

    return reducerCleared(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
