import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { IShowDialogOptions } from '@core/models/dialog.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { CustomDialogDirective } from '@core/modules/custom-dialog/custom-dialog.component';

@Component({
  selector: 'azz-custom-confirm-dialog',
  templateUrl: './custom-confirm-dialog.component.html',
})
export class CustomConfirmDialogComponent extends CustomDialogDirective implements AfterViewInit {
  @Input() loadingIndicator: boolean;
  @ViewChild('content') public declare dialogContent: TemplateRef<unknown>;

  constructor(dialogService: NgbModal) {
    super(dialogService);
  }

  public ngAfterViewInit(): void {
    this.setContent(this.dialogContent);
  }

  public override showDialog(header: string, message: string, options?: IShowDialogOptions) {
    const dialogInstance = super.showDialog(header, message, options);
    dialogInstance.dismissed.pipe(takeUntil(dialogInstance.hidden)).subscribe(() => this.azzClose.emit());
    dialogInstance.closed.pipe(takeUntil(dialogInstance.hidden)).subscribe(() => this.azzClose.emit());
    return dialogInstance;
  }

  public override modalReferenceOnClose(): void {
    super.modalReferenceOnClose();

    this.azzClose.emit();
  }
}
