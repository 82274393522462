<div
  class="card order-history-info-card"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
  [scrollWindow]="false"
>
  <div class="card-container history-card">
    <h2 class="header-card">{{ 'ORDER_HISTORY_CARD_HEADER' | translate }}</h2>
    <div *ngIf="customerCurrentOrdersData === undefined || customerCurrentOrdersData === null" class="no-recent-orders">
      <p>{{ 'NO_RECENT_ORDERS_EMPTY' | translate }}</p>
    </div>

    <div *ngIf="customerCurrentOrdersData?.length === 0" class="no-recent-orders">
      <p>{{ 'PA_CUSOTMER_HAS_NO_ACTIVE_ORDERS' | translate }}</p>
    </div>

    <div *ngIf="customerCurrentOrdersData?.length >= 1" class="recent-cards-container">
      <div *ngFor="let order of customerCurrentOrdersData">
        <div class="recent-order-card">
          <div class="flex">
            <div class="recent-order-label text">{{ 'DATE' | translate }}</div>
            <div class="recent-order-content text">
              {{ order.date | date: 'dd.MM.yyyy' }} {{ order.date | date: 'HH:mm' }}
            </div>
          </div>

          <div class="flex">
            <span class="recent-order-label text">{{ 'PA_CREATE_PASSENGER' | translate }}:</span>
            <span *ngIf="order.anonymousPassenger; else notAnonymous" class="recent-order-content text">{{
              'ANONYMOUS_PASSENGER' | translate
            }}</span>
            <ng-template #notAnonymous>
              <span class="recent-order-content text">{{
                order.passengers[0] ? order.passengers[0] : order.customer?.name
              }}</span>
            </ng-template>
          </div>

          <div class="flex">
            <span class="recent-order-label text">{{ 'FR_DRIVER_COL_HEADER' | translate }}:</span>
            <button type="button" *ngIf="order.driverId" class="text link" (click)="redirectToDriverPage(order)">
              {{ order.driver?.firstName + ' ' + order.driver?.lastName }}
            </button>
          </div>

          <div class="flex">
            <span class="recent-order-label text">{{ 'FP_STATUS_HEADER' | translate }}:</span>
            <span class="recent-order-content text">{{
              'CORE_ORDER_STATUS_' + order.status | azzOrderInProgress | translate
            }}</span>
            <span
              class="exception-red"
              *ngIf="order?.exceptional"
              [ngbTooltip]="tooltipContent"
              placement="top"
              tooltipClass="status-tooltip error-color"
            ></span>
            <ng-template #tooltipContent>
              <small [innerHTML]="'PA_EXCEPTION_TYPE_' + order['exception'].type | translate"> </small>
            </ng-template>
          </div>

          <div class="flex">
            <span class="recent-order-label text"> {{ 'PREV_ORDER_PICKUP_ADDRESS_SHORT' | translate }}: </span>
            <span class="recent-order-content text" *ngIf="order?.appointmentAddress?.poi?.label">
              {{ order?.appointmentAddress?.poi?.label }}
              <span *ngIf="order?.appointmentAddress?.poi?.deleted">
                (<span class="text-deleted">{{ 'DELETED' | translate }}</span
                >)
              </span>
            </span>

            <span class="recent-order-content text" *ngIf="!order?.appointmentAddress?.poi?.label">
              {{ order?.appointmentAddress?.number }}
              {{ order?.appointmentAddress?.street }},
              {{ order?.appointmentAddress?.city }}
            </span>
          </div>
          <div class="flex">
            <span class="recent-order-label text">{{ 'PREV_ORDER_DESTINATION_ADDRESS_SHORT' | translate }}: </span>
            <span class="recent-order-content text" *ngIf="order?.destinationAddress?.poi?.label">
              {{ order?.destinationAddress?.poi?.label }}
              <span *ngIf="order?.destinationAddress?.poi?.deleted">
                (<span class="text-deleted">{{ 'DELETED' | translate }}</span
                >)
              </span>
            </span>

            <span class="recent-order-content text" *ngIf="!order?.destinationAddress?.poi?.label">
              {{ order?.destinationAddress?.number }}
              {{ order?.destinationAddress?.street }},
              {{ order?.destinationAddress?.city }}
            </span>
          </div>

          <button class="recent-order-btn" (click)="redirectToOrderPage(order)">
            {{ 'REDIRECT_TO_ORDER_PAGE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
