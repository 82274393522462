import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { IAdvert } from '../../models/advert.model';
import { IAgreementData } from '../../models/agreement.model';
import { IContactData, IContactMessage } from '../../models/contact.model';
import { IDiscoverData } from '../../models/discover.model';
import { ILegalData } from '../../models/legal.model';

@Injectable({ providedIn: 'root' })
export class InfoService extends ApiService {
  public INFO_SERVICE_URL = 'storage/api/v1/info/';

  constructor(private readonly httpClient: HttpClient) {
    super(httpClient);
  }

  public getContact(): Observable<IContactData> {
    return this.get(`${this.INFO_SERVICE_URL}contact`);
  }

  public saveContact(body: IContactData): Observable<IContactData> {
    return this.post(`${this.INFO_SERVICE_URL}contact`, body);
  }

  public getLegalNotice(): Observable<ILegalData> {
    return this.get(`${this.INFO_SERVICE_URL}legal-notice`);
  }

  public saveLegalNotice(body: ILegalData): Observable<ILegalData> {
    return this.post(`${this.INFO_SERVICE_URL}legal-notice`, body);
  }

  public getDiscover(): Observable<IDiscoverData> {
    return this.get(`${this.INFO_SERVICE_URL}discover`);
  }

  public saveDiscover(discover: IDiscoverData): Observable<IDiscoverData> {
    return this.post(`${this.INFO_SERVICE_URL}discover`, discover);
  }

  public getCGV() {
    return this.get(`${this.INFO_SERVICE_URL}cgv`);
  }

  public saveCGV(cgv) {
    return this.post(`${this.INFO_SERVICE_URL}cgv`, { content: cgv });
  }

  public getUserAgreement(): Observable<IAgreementData> {
    return this.get(`${this.INFO_SERVICE_URL}user-agreement`);
  }

  public saveUserAgreement(body: IAgreementData): Observable<IAgreementData> {
    return this.post(`${this.INFO_SERVICE_URL}user-agreement`, body);
  }

  public getAdvertisement(): Observable<IAdvert> {
    return this.get(`${this.INFO_SERVICE_URL}advertisement`);
  }

  public saveAdvertisement(link, advertisementImage) {
    return this.post(`${this.INFO_SERVICE_URL}advertisement`, {
      adUrl: link,
      imageFilename: advertisementImage,
    });
  }

  public removeAdvertisement() {
    return this.delete(`${this.INFO_SERVICE_URL}advertisement`);
  }

  public sendEmailToSuperAdmin(body: IContactMessage): Observable<any> {
    return this.post(`${this.INFO_SERVICE_URL}contact/send/email`, body);
  }
}
