import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as PrevRouteReducer from '../reducers/prev-route.reducer';
import { CustomRouteInfo } from '../reducers/router.reducer';

export const selectPrevRoute = createFeatureSelector<PrevRouteReducer.State>(PrevRouteReducer.prevRouteFeatureKey);
export const selectPrevRouteUrl = createSelector(selectPrevRoute, state => state?.url);
export const selectPrevRouteData = createSelector(selectPrevRoute, state => state?.data);
export const selectPrevRouteParams = createSelector(selectPrevRoute, (state: CustomRouteInfo) => state?.params);
export const selectPrevRouteQueryParams = createSelector(
  selectPrevRoute,
  (state: CustomRouteInfo) => state?.queryParams
);
export const selectPrevRouteQueryParam = (param: string) =>
  createSelector(selectPrevRouteQueryParams, queryParams => (queryParams ? queryParams[param] : null));
export const selectPrevRouteParam = (param: string) =>
  createSelector(selectPrevRouteParams, params => (params ? params[param] : null));
