import * as Sentry from '@sentry/angular-ivy';
import {BrowserTracing} from '@sentry/angular-ivy';
import {environment} from './environments/environment';

export const initSentry = () => {
  console.log('Sentry ', `'https://${environment.firebase.storageBucket}`);
  Sentry.init({
    dsn: 'https://7b11675c94bd4461a8d99cc48e76916b@o4504848470245376.ingest.sentry.io/4504854054764544',
    environment: environment.type,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          'https://yourserver.io/api',
          `'https://${environment.firebase.storageBucket}`
        ],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1
  });
};
