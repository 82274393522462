<form class="card azz-multiple autocomplete" [formGroup]="form">
  <div #formElemRef>
    <ng-template #rt let-r="result" let-t="term">
      <div *ngIf="r && r?.properties?.type === 'poi'" class="autocomplete__poi">
        <ngb-highlight [result]="r.properties.label" [term]="t"></ngb-highlight>
        <img src="assets/images/black-marker.svg" alt="poi icon" class="autocomplete__poi-icon" />
      </div>
      <ngb-highlight
        *ngIf="r && r?.properties?.type !== 'poi'"
        [result]="r.properties.name + ', ' + r.properties.city"
        [term]="t"
      >
      </ngb-highlight>
      <div *ngIf="!r">{{ 'ADDRESS_NOT_FOUND' | translate }}</div>
    </ng-template>
    <div class="d-flex align-items-center">
      <input
        #searchField
        azzEmitOnEvent
        sourceEvent="blur"
        (triggerAction$)="enableLoadingIndicator(false)"
        [id]="ngId"
        type="text"
        formControlName="searchField"
        azzFocusOnInputChange
        [focusTriggerCounter]="customFocusTriggerCounter"
        [ngbTypeahead]="search"
        (selectItem)="onAddressSelect($event)"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        class="form-control azz-transparent-input azz-validate"
        [ngClass]="{
          'autocomplete__input-not-selected':
            isAddressNotSelectedErrorShown && (!address || !address.value) && form.controls.searchField.touched
        }"
      />
      <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
    </div>
  </div>
  <div
    *ngIf="isAddressNotSelectedErrorShown && (!address || !address.value) && form.controls.searchField.touched"
    [ngClass]="{
      'autocomplete__text-not-selected':
        isAddressNotSelectedErrorShown && (!address || !address.value) && form.controls.searchField.touched
    }"
  >
    {{ 'PA_CHOOSE_ADDRESS_NOT_FROM_LIST' | translate }}
  </div>
</form>
