<div class="modal-notification">
  <div>{{ 'GO_ORDER_DISPATCH_SAGA7_DIALOG' | translate }}</div>
  <div>{{ 'DISPATCH_DIALOG_SUMMARY_HEADER' | translate }}:</div>
  <azz-loading-block [isLoading]="tableLoading$ | async">
    <div class="azz-table-wrapper">
      <table class="table table-hover azz-table">
        <thead>
          <tr class="d-table-row">
            <th class="col-3">{{ 'CITY' | translate }}</th>
            <th class="col-3">{{ 'GO_ORDERS_G7_CODE_HEADER' | translate }}</th>
            <th class="col-3">{{ 'NUMBER_OF_ORDERS' | translate }}</th>
            <th class="col-3">{{ 'TOTAL_PRICE' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="dispatchOrdersData?.content?.length > 0">
          <!-- IE scrolling fix -->
          <tr class="azz-clickable d-table-row" *ngFor="let item of dispatchOrdersData?.content">
            <td>{{ item?.fleetCity }}</td>
            <td>{{ item?.fleetG7Id }}</td>
            <td>{{ item?.ordersAmount }}</td>
            <td>{{ item?.total + ' \u20AC' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <azz-table-no-matches [azzVisible]="!dispatchOrdersData?.content?.length"></azz-table-no-matches>
    <azz-pagination
      (goToPrevPage)="prevPage()"
      (goToNextPage)="nextPage()"
      [prevDisabled]="isPrevBtnDisabled()"
      [nextDisabled]="isNextBtnDisabled()"
    >
    </azz-pagination>
  </azz-loading-block>

  <div class="modal-notification__btn-group">
    <button type="button" class="custom-btn custom-btn--grey mr-3" [disabled]="loading$ | async" (click)="close()">
      {{ 'CANCEL' | translate | titlecase }}
    </button>
    <button
      type="button"
      class="custom-btn custom-btn--green btn-rotate-flex btn-rotate-flex--w-auto"
      [disabled]="loading$ | async"
      (click)="dispatch()"
    >
      <azz-rotate-indicator7 [visible]="loading$ | async"></azz-rotate-indicator7>
      {{ 'CORE_OK_VALUE' | translate }}
    </button>
  </div>
</div>
