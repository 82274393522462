import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IExceptionData } from '@core/models/exception.model';
import { PAOrderService } from '@core/services/orders/phone-advisor-order.service';
import { createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as ExceptionsActions from '../actions';
import { ExceptionsStore } from '../index';

@Injectable()
export class ExceptionsEffects extends EffectsBase {
  private readonly paOrderService = inject(PAOrderService);
  private readonly router = inject(Router);

  public getExceptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExceptionsActions.getExceptions),
      withLatestFrom(this.selectFromStore(ExceptionsStore.params)),
      switchMap(([_, params]) =>
        this.paOrderService.getExceptions(params).pipe(
          map((exceptionsData: IExceptionData) => ExceptionsActions.getExceptionsSuccess({ exceptionsData })),
          this.catchError(ExceptionsActions.getExceptionsError())
        )
      )
    )
  );

  public updateFilterModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExceptionsActions.updateFilterModel),
      debounceTime(500),
      map(() => ExceptionsActions.updatePagination({ pagination: { page: 0 } }))
    )
  );

  public updatePagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExceptionsActions.updatePagination),
      map(() => ExceptionsActions.getExceptions())
    )
  );

  public lockException$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExceptionsActions.lockException),
      mergeMap(({ id, route }) =>
        this.paOrderService.lockExceptionalOrder(id).pipe(
          map(() => ExceptionsActions.lockExceptionSuccess({ id, route })),
          catchError(response => {
            if (response.status === 403) {
              this.alertErr(response.error.message);
            }

            return this.catchErrorHandler(response, ExceptionsActions.lockExceptionError());
          })
        )
      )
    )
  );

  public lockExceptionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExceptionsActions.lockExceptionSuccess),
        tap(({ id, route }) => this.router.navigate([route, id]))
      ),
    { dispatch: false }
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(ExceptionsActions.init),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(ExceptionsActions.destroy)))))
    );
  }
}
