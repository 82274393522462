import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LatLng, latLngBounds, Map } from 'leaflet';

@Component({
  selector: 'azz-geo-map',
  templateUrl: './azz-geo-map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzzGeoMapComponent {
  @Input() geoOptions: any;
  @Input() geoLayers: any;
  public map: Map;

  public onMapReady(map: Map) {
    this.map = map;
  }

  public fitBounds(latLngArr: LatLng[]): void {
    const bounds = latLngBounds(latLngArr);
    this.map.fitBounds(bounds);
  }
}
