import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DRIVER_STATUS } from '../../../core/constant/driver-status.constant';
import { IDriverOrdersStats } from '../../../core/models/stats.model';
import { IDriverUserInfo } from '../../../core/models/user-info.model';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent {
  @Input() ordersStats: IDriverOrdersStats;
  @Input() readonly: boolean;
  @Output() changeRate = new EventEmitter<number>();
  public driverData: IDriverUserInfo;
  public rating: number;
  public activeDriverStatus = DRIVER_STATUS.ACTIVE;
  @Input() set driver(driver: IDriverUserInfo) {
    if (driver) {
      this.driverData = driver;
      this.rating = driver?.rating;
    }
  }

  public onRatingChange(rating: number): void {
    this.changeRate.emit(rating);
  }
}
