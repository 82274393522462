import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CustomConfirmDialogComponent } from './custom-confirm-dialog/custom-confirm-dialog.component';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, AzzRotateIndicatorModule],
  declarations: [CustomConfirmDialogComponent],
  exports: [CustomConfirmDialogComponent],
})
export class CustomDialogModule {}
