import { createAction, props } from '@ngrx/store';

export const toggleExceptionsCountTimer = createAction(
  '[App Exceptions Count] Toggle Exceptions Count Timer',
  props<{ activate: boolean }>()
);

export const getExceptionsCount = createAction('[App Exceptions Count] Get Exceptions Count');

export const getExceptionsCountSuccess = createAction(
  '[App Exceptions Count] Get Exceptions Count Success',
  props<{ count: number }>()
);

export const getExceptionsCountError = createAction('[App Exceptions Count] Get Exceptions Count Error');

export const resetExceptionsCount = createAction('[App Exceptions Count] Reset Exceptions Count');
