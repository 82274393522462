import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { ExceptionsStore } from './store';
import { IPaginationState } from '../../models/common.model';
import { IExceptionData, IExceptionDataParams } from '../../models/exception.model';
import { AppStore } from '../../store';

@Component({
  selector: 'app-exceptions',
  templateUrl: './exceptions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class ExceptionsComponent implements OnInit, OnDestroy {
  @Input() exceptionsRoute: string;
  @Input() driversRoute: string;
  @Input() customersRoute: string;
  public exceptionsData$: Observable<IExceptionData>;
  public loading$: Observable<boolean>;
  public filterModel$: Observable<Partial<IExceptionDataParams>>;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  ngOnInit() {
    this.store.dispatch(ExceptionsStore.init());
    this.initData();
    this.getData();
    this.listenForExceptionAmountChanged();
  }

  public updatePagination(pagination: Partial<IPaginationState>): void {
    this.store.dispatch(ExceptionsStore.updatePagination({ pagination }));
  }

  public updateFilterModel(filterModel: Partial<IExceptionDataParams>): void {
    this.store.dispatch(ExceptionsStore.updateFilterModel({ filterModel }));
  }

  public navigateToDriver(id: number): void {
    this.router.navigate([this.driversRoute, id]);
  }

  public navigateToCustomer(id: number): void {
    this.router.navigate([this.customersRoute, id]);
  }

  public navigateToException(id: string): void {
    this.store.dispatch(ExceptionsStore.lockException({ id, route: this.exceptionsRoute }));
  }

  ngOnDestroy() {
    this.store.dispatch(ExceptionsStore.destroy());
  }

  private initData(): void {
    this.exceptionsData$ = this.store.pipe(select(ExceptionsStore.exceptionsData));
    this.loading$ = this.store.pipe(select(ExceptionsStore.loading));
    this.filterModel$ = this.store.pipe(select(ExceptionsStore.filterModel));
  }

  private getData(): void {
    this.store.dispatch(ExceptionsStore.getExceptions());
  }

  private listenForExceptionAmountChanged(): void {
    this.store
      .pipe(select(AppStore.selectExceptionsCount), skip(1), takeUntil(this.destroyed$))
      .subscribe(() => this.updatePagination({ page: 0 }));
  }
}
