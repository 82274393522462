<div class="search-poi">
  <label for="search-poi"></label>
  <form class="azz-has-addon" [ngClass]="{ 'search-poi--active': poiLabel }">
    <div class="azz-search-addon"></div>
    <input
      id="search-poi"
      name="search_poi"
      type="text"
      [(ngModel)]="poiLabel"
      (input)="searchPoiByLabel($event.target['value'])"
      [placeholder]="'POI_LABEL' | translate"
      class="form-control azz-transparent-input azz-search-field"
    />
    <button type="button" class="search-poi__btn" (click)="resetPoiLabel()"></button>
  </form>
</div>
