import { HttpErrorResponse } from '@angular/common/http';
import { IAuthData } from '@core/models/auth.model';
import { IStopCoffeeBreakBody } from '@core/models/coffee-break.model';
import { createAction, props } from '@ngrx/store';

export const tryOpenConfirmCoffeeBreakDialog = createAction('[Coffee Break] Try Open Confirm Coffee Break Dialog');

export const openConfirmCoffeeBreakDialog = createAction('[Coffee Break] Open Confirm Coffee Break Dialog');

export const closeConfirmCoffeeBreakDialog = createAction('[Coffee Break] Close Confirm Coffee Break Dialog');

export const startCoffeeBreak = createAction('[Coffee Break] Start Coffee Break');

export const startCtiCoffeeBreak = createAction('[Coffee Break] Start Cti Coffee Break');

export const coffeeBreakSuccess = createAction('[Coffee Break] Coffee Break Success');

export const coffeeBreakError = createAction('[Coffee Break] Coffee Break Error');

export const ctiCoffeeBreakSuccess = createAction('[Coffee Break] Cti Coffee Break Success');

export const ctiCoffeeBreakError = createAction('[Coffee Break] Cti Coffee Break Error');

export const stopCoffeeBreak = createAction('[Coffee Break] Stop Coffee Break', props<IStopCoffeeBreakBody>());

export const stopCtiCoffeeBreak = createAction('[Coffee Break] Stop Cti Coffee Break', props<IStopCoffeeBreakBody>());

export const loginUserAgent = createAction('[Coffee Break] Login User Agent');

export const loginUserAgentSuccess = createAction('[Coffee Break] Login User Agent Success');

export const loginUserAgentError = createAction('[Coffee Break] Login User Agent Error');

export const stopCoffeeBreakSuccess = createAction('[Coffee Break] Stop Coffee Break Success');

export const stopCtiCoffeeBreakSuccess = createAction('[Coffee Break] Stop Cti Coffee Break Success');

export const stopCoffeeBreakError = createAction(
  '[Coffee Break] Stop Coffee Break Error',
  props<{ error: HttpErrorResponse }>()
);

export const resetStopCoffeeBreakError = createAction('[Coffee Break] Reset Stop Coffee Break Error');

export const coffeeBreakLogout = createAction(
  '[Coffee Break] Coffee Break Logout',
  props<{ authData: IAuthData; fleetId: number }>()
);

export const coffeeBreakLogoutSuccess = createAction(
  '[Coffee Break] Coffee Break Logout Success',
  props<{ authData: IAuthData; fleetId: number }>()
);

export const coffeeBreakLogoutError = createAction('[Coffee Break] Coffee Break Logout Error');
