<div class="row azz-details-page pragmatic azz-info-blocks-row details">
  <div class="col-12">
    <div class="azz-info-block">
      <div class="azz-nav-block">
        <img src="assets/images/return-arrow.png" class="azz-back-btn" alt="back" (click)="goBack()" />
        <div class="azz-back-title">{{ 'PA_CUSTOMERS_GO_BACK' | translate }}</div>
      </div>
      <form name="form" [formGroup]="form" #formElemRef (ngSubmit)="submit()">
        <div class="row" *ngIf="!isCreateMode">
          <div class="col-lg-6 col-md-6 status-row">
            <h1
              [ngClass]="{
                'azz-header': customerStatus?.value === 'ACTIVE',
                'status-blocked': customerStatus?.value === 'BLOCKED',
              }"
            >
              {{ customer?.lastName }} {{ customer?.name }}
            </h1>
            <label class="status-label" *ngIf="customerStatus?.value === 'ACTIVE'">{{ customerStatus?.title }}</label>
            <button
              *ngIf="customerStatus?.value === 'BLOCKED'"
              [disabled]="isDisabled"
              class="custom-btn custom-btn--red status-block-btn"
              type="button"
            >
              <span>{{ 'PA_CUSTOMERS_STATUS_BLOCKED' | translate }}</span>
            </button>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="details__date" *ngIf="customer?.modificationTime">
              <b>{{ 'PA_CUSTOMERS_LAST_EDITED' | translate }}:</b>
              {{ customer?.modificationTime | date: 'dd.MM.yyyy' }},
              {{ customer?.modificationTime | date: 'HH:mm' }}
            </div>
            <div class="details__date azz-time-data" *ngIf="customer?.creationTime">
              <b>{{ 'PA_CUSTOMERS_CREATE_DATE' | translate }}:</b>
              {{ customer?.creationTime | date: 'dd.MM.yyyy' }},
              {{ customer?.creationTime | date: 'HH:mm' }}
            </div>
          </div>
          <div class="row" *ngIf="customerStatus?.value === 'BLOCKED'">
            <div class="col-12">
              <div class="row underline-notification details__exception-type-block">
                <small>
                  {{
                    'CUSTOMER_BLOCKED_EXPLANATION_COMMANDER'
                      | translate: { blockTime: customerBlockTime, blockReason: customerBlockReason }
                  }}
                  <br />
                  {{ 'CUSTOMER_BLOCKED_EXPLANATION_CANNOT_COMMAND' | translate }}
                  {{ 'CUSTOMER_UNLOCK_CLICK' | translate }}
                  <a class="link" (click)="onClickUnblockCustomerButton()">{{ 'COMMON_HERE' | translate }}</a>
                  {{ 'CUSTOMER_TO_UNLOCK_IT' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-5">
            <label class="azz-transparent-input-label" for="customer-code">{{ 'SUBSCRIBER_CODE' | translate }}</label>
            <input
              id="customer-code"
              formControlName="code"
              type="text"
              name="customer_code"
              class="form-control azz-transparent-input azz-validate"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-1">
            <label class="azz-transparent-input-label" for="honorific-prefix">{{
              'CUSTOMER_CIVILITY' | translate
            }}</label>
            <select
              id="honorific-prefix"
              formControlName="prefix"
              class="form-control azz-transparent-input azz-validate azz-expandable"
            >
              <option [value]="prefix.value" *ngFor="let prefix of availableData?.prefixes">
                {{ prefix.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-4">
            <label class="azz-transparent-input-label" for="first-name">{{ 'FIRST_NAME' | translate }}</label>
            <input
              id="first-name"
              formControlName="firstName"
              type="text"
              name="first_name"
              maxlength="100"
              class="form-control azz-transparent-input azz-validate"
              (blur)="onBlurFormaFirstNameText()"
            />
            <div class="form-control__error" *ngIf="form.controls.firstName?.errors && form.controls.firstName.touched">
              <small *ngIf="form?.controls?.firstName?.errors?.azzNotFrLettersAndNums">
                {{ 'CORE_ONLY_LETTERS_NUMBERS_VALIDATION_ERROR' | translate }}
              </small>
            </div>
          </div>
          <div class="form-group col-lg-5">
            <label class="azz-transparent-input-label" for="last-name">{{ 'LAST_NAME' | translate }}*</label>
            <input
              id="last-name"
              formControlName="lastName"
              type="text"
              name="last_name"
              maxlength="100"
              class="form-control azz-transparent-input azz-validate"
              (blur)="onBlurFormatLastNameText()"
              required
            />
            <div class="form-control__error" *ngIf="form.controls.lastName?.errors && form.controls.lastName.touched">
              <small *ngIf="form.controls.lastName?.errors?.required">
                {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
              </small>
              <small *ngIf="form?.controls?.lastName?.errors?.azzNotFrLettersAndNums">
                {{ 'CORE_ONLY_LETTERS_NUMBERS_VALIDATION_ERROR' | translate }}
              </small>
            </div>
          </div>
        </div>
        <div *ngIf="getPhonesFormArray()?.controls as phonesFormArray" formArrayName="phoneNumbers">
          <div *ngFor="let item of phonesFormArray; let i = index; let first = first">
            <div class="row phone-number-form-group" [formGroupName]="i">
              <div class="form-group col-lg-4" *ngIf="item.get('phoneNumber') as control">
                <label class="azz-transparent-input-label" [for]="'customer-number_' + i"
                  >{{ 'PHONE_NUMBER' | translate }} *</label
                >
                <div class="details__phone-group">
                  <azz-phone
                    class="azz-phone-customer"
                    formControlName="phoneNumber"
                    (input)="phoneNumberWatcher.next({ item: item, index: i })"
                  />
                  <div
                    *ngIf="!first && !isPhoneTypeAvailable(control.value, item.get('phoneCode').value)"
                    class="azz-delete-circle details__delete-btn"
                    (click)="deleteAdditionalPhone(i)"
                  ></div>
                </div>
                <div class="form-control__error" *ngIf="control.touched || item.errors">
                  <small *ngIf="control.value.length <= 4">
                    {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
                  </small>
                  <small *ngIf="control.errors?.pattern">
                    {{ 'CORE_INVALID_PHONE_NUMBER_VALIDATION_ERROR' | translate }}
                  </small>
                  <small *ngIf="item.errors?.minlength">
                    {{ 'CORE_PHONE_NUMBER_NUM_LENGTH' | translate: { num: minPhoneLength } }}
                  </small>
                  <small *ngIf="item.errors?.maxlength">
                    {{ 'CORE_PHONE_NUMBER_NUM_LENGTH' | translate: { num: minPhoneLength } }}
                  </small>
                </div>
                <div
                  class="form-control__error"
                  *ngIf="(control.touched || control.dirty) && item?.errors?.phoneAlreadyExists"
                >
                  <small>{{ 'PHONE_NUMBER_ALREADY_EXISTS' | translate }}</small>
                </div>
              </div>
              <div
                *ngIf="isPhoneTypeAvailable(item.get('phoneNumber').value, item.get('phoneCode').value)"
                class="col-lg-3 details__phone-group"
              >
                <div class="from-group">
                  <label class="azz-transparent-input-label">{{ 'PA_CUSTOMERS_PHONE_TYPE' | translate }}*</label>
                  <div
                    class="azz-fake-input details__phone-group"
                    *ngIf="isMobileNumber(item.get('phoneNumber').value); else elsePhoneType"
                  >
                    {{ 'PA_CUSTOMERS_MOBILE_PHONE' | translate }}
                    <div
                      *ngIf="!first && !isDisabled"
                      class="azz-delete-circle details__delete-btn"
                      (click)="deleteAdditionalPhone(i)"
                    ></div>
                  </div>
                  <ng-template #elsePhoneType>
                    <div class="azz-fake-input details__phone-group">
                      {{ 'PA_CUSTOMERS_LANDLINE_PHONE' | translate }}
                      <div
                        *ngIf="!first && !isDisabled"
                        class="azz-delete-circle details__delete-btn"
                        (click)="deleteAdditionalPhone(i)"
                      ></div>
                    </div>
                  </ng-template>
                </div>
              </div>
              <azz-phone-call-button
                *ngIf="checkPhone()"
                [phoneNumber]="item.get('phoneNumber').value"
                [link]="customerPhoneCallLink"
              ></azz-phone-call-button>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-lg-5">
            <button
              [disabled]="isDisabled"
              class="azz-add-block details__add-btn"
              type="button"
              (click)="addPhoneNumber()"
            >
              <span class="azz-add-button"></span>
              <span>{{ 'PA_CUSTOMERS_ADD_NUMBER' | translate }}</span>
            </button>
          </div>
        </div>
        <div class="row" formArrayName="emails">
          <div
            *ngFor="let email of getEmailsFormArray().controls; let i = index; let first = first"
            class="form-group col-lg-5"
          >
            <div>
              <label class="azz-transparent-input-label" [for]="'email_' + i">{{ 'EMAIL' | translate }}</label>
              <div class="details__email-group">
                <input
                  [id]="'email_' + i"
                  type="email"
                  azzDynamicValidator
                  [formGroupOrArray]="getEmailsFormArray()"
                  [formControlName]="i"
                  [controlName]="i"
                  [validators]="getEmailValidators()"
                  name="email"
                  maxlength="100"
                  class="form-control azz-transparent-input azz-validate"
                />
                <div
                  *ngIf="!first && !isDisabled"
                  class="azz-delete-circle details__delete-btn"
                  (click)="deleteAdditionalEmail(i)"
                ></div>
              </div>
              <div
                class="form-control__error"
                *ngIf="getEmailsFormArray().at(i).errors?.pattern && getEmailsFormArray().at(i).touched"
              >
                <small *ngIf="getEmailsFormArray().at(i).errors?.pattern">
                  {{ 'CORE_INVALID_EMAIL_VALIDATION_ERROR' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-lg-5">
            <button
              [disabled]="isDisabled"
              class="azz-add-block details__add-btn"
              type="button"
              (click)="addNewEmail()"
            >
              <span class="azz-add-button"></span>
              <span>{{ 'PA_CUSTOMERS_ADD_EMAIL' | translate }}</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-8 details__checkbox-group">
            <div class="extensions__checkbox-group">
              <input
                id="smsNotificationEnabled"
                type="checkbox"
                name="smsNotificationEnabled"
                formControlName="smsNotificationEnabled"
              />
              <label for="smsNotificationEnabled">{{ 'CLIENT_NOTIFICATION_SMS' | translate }}</label>
            </div>
            <div class="extensions__checkbox-group">
              <input
                id="emailNotificationEnabled"
                type="checkbox"
                name="emailNotificationEnabled"
                formControlName="emailNotificationEnabled"
              />
              <label for="emailNotificationEnabled">{{ 'CLIENT_NOTIFICATION_EMAIL' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <!--- START  Block & Unblock customer -->
          <div class="col-md-3" *ngIf="!isCreateMode">
            <div *ngIf="isPhoneAdvisor">
              <button
                type="button"
                *ngIf="customer?.status === 'ACTIVE'"
                class="btn-rotate-flex custom-btn custom-btn--red"
                (click)="onClickBlockCustomerButton()"
              >
                <azz-rotate-indicator7 [visible]="blockUnblockIndicator"></azz-rotate-indicator7>
                <span>{{ 'BLOCK_CLIENT' | translate }}</span>
              </button>
            </div>
            <div *ngIf="!isPhoneAdvisor && customer?.status === 'ACTIVE'">
              <azz-button-dropdown
                [innerButtonsName]="blockCustomerOptions"
                [blockUnblockIndicator]="blockUnblockIndicator"
                buttonClassName="btn-rotate-flex drp-down custom-btn custom-btn--red"
                firstTranslatePart="FLEET_"
                (selectProperty)="onSelectedButtonClick($event)"
                [buttonName]="'BLOCK_CLIENT' | translate"
              ></azz-button-dropdown>
            </div>

            <div>
              <button
                *ngIf="customer?.status === 'BLOCKED'"
                type="button"
                class="btn-rotate-flex custom-btn custom-btn--green"
                (click)="onClickUnblockCustomerButton()"
              >
                <azz-rotate-indicator7 [visible]="blockUnblockIndicator"></azz-rotate-indicator7>
                <span>{{ 'UNBLOCK_CLIENT' | translate }}</span>
              </button>
            </div>
          </div>
          <!--- END Block & Unblock customer -->
          <div class="col-md-3">
            <button
              type="submit"
              name="submit"
              [disabled]="isDisabled || hasAnyPhoneNumberEqualityError() || !allPhoneNumberCheckedOnEquality()"
              class="btn-rotate-flex custom-btn custom-btn--green"
            >
              <azz-rotate-indicator7 [visible]="isCustomerLoading$ | async" />
              <span *ngIf="isCreateMode; else pa_update_customer">{{ 'PA_CREATE_CUSTOMER' | translate }}</span>
              <ng-template #pa_update_customer>
                <span>{{ 'PA_UPDATE_CUSTOMER' | translate }}</span>
              </ng-template>
            </button>
          </div>
        </div>
        <azz-customer-orders
          *ngIf="!isCreateMode"
          [customerOrdersData]="customerOrdersData"
          [orderDetailsUrl]="orderDetailsUrl"
          [driverDetailsUrl]="driverDetailsUrl"
          [ordersLoadingIndicator]="isOrdersLoading"
        >
          <azz-pagination
            (goToPrevPage)="prevOrdersPage()"
            (goToNextPage)="nextOrdersPage()"
            [prevDisabled]="isOrdersPrevDisabled()"
            [nextDisabled]="isOrdersNextDisabled()"
          >
          </azz-pagination>
        </azz-customer-orders>
        <azz-customer-modification-history
          *ngIf="!isCreateMode"
          [customerHistory]="customerHistory"
          [historyLoadingIndicator]="isHistoryLoading"
        >
          <azz-pagination
            (goToPrevPage)="prevHistoryPage()"
            (goToNextPage)="nextHistoryPage()"
            [prevDisabled]="isPrevHistoryBtnDisabled()"
            [nextDisabled]="isNextHistoryBtnDisabled()"
          >
          </azz-pagination>
        </azz-customer-modification-history>
      </form>
    </div>
  </div>
</div>

<azz-custom-confirm-dialog
  #confirmDialog
  [loadingIndicator]="blockUnblockIndicator"
  (azzSubmit)="submitBlockCustomer()"
  (azzClose)="resetBlockParams()"
></azz-custom-confirm-dialog>
