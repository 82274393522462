import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITag } from '@core/models/tag.model';

@Component({
  selector: 'azz-prev-order-tags',
  templateUrl: './prev-order-tags.component.html',
  styleUrls: ['./prev-order-tags.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrevOrderTagsComponent {
  public tags: string[] = [];
  public sortTags: string[] = [
    'STND',
    'SP',
    'CA',
    'MV',
    'VP',
    'BR',
    'SEDAN',
    '5P',
    '6P',
    '7P',
    '8P',
    'PA',
    'AN',
    'HO',
    'HT',
    'TDE',
    'AV',
    'RA',
    'RL',
    'DEM',
    'VB',
    'LO',
    'GC',
    'PB',
    'TP',
  ];
  public serviceTags: string[] = ['STND', 'SP', 'CA', 'MV', 'VP'];
  public vehicleTags: string[] = ['SEDAN', '5P', '6P', '7P', '8P', 'BR'];

  @Input() set allTags(allTags: ITag[]) {
    let hasServiceTag = false;
    let hasVehicleTag = false;
    for (const tag of allTags) {
      if (this.sortTags.includes(tag.id)) {
        this.tags.push(tag.id);
      }
      if (this.serviceTags.includes(tag.id)) {
        hasServiceTag = true;
      }
      if (this.vehicleTags.includes(tag.id)) {
        hasVehicleTag = true;
      }
    }
    if (!hasServiceTag) {
      this.tags.push('STND');
    }
    if (!hasVehicleTag) {
      this.tags.push('SEDAN');
    }
    this.tags = this.sortPrevOrderTags(this.tags, this.sortTags);
  }

  public sortPrevOrderTags(items: string[], orderList: string[]) {
    let returnItems: string[] = [];
    for (const order of orderList) {
      const ordered = [];
      for (const item of items) {
        if (item === order) {
          ordered.push(item);
        }
      }
      returnItems = returnItems.concat(ordered);
    }
    return returnItems;
  }
}
