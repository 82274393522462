import { inject, Injectable } from '@angular/core';
import { environment } from '@azz/env';
import { PAOrderService } from '@core/services/orders/phone-advisor-order.service';
import { createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular-ivy';
import { EffectsBase } from '@store/effects/base.effects';
import { EMPTY, of, timer } from 'rxjs';
import { catchError, map, switchMap, takeWhile } from 'rxjs/operators';

import * as ExceptionsCountActions from '../actions/exceptions-count.actions';

@Injectable()
export class ExceptionsCountEffects extends EffectsBase {
  private readonly paOrderService = inject(PAOrderService);

  public toggleExceptionsCountTimer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExceptionsCountActions.toggleExceptionsCountTimer),
      switchMap(({ activate }) =>
        activate
          ? timer(0, 1000 * 10).pipe(
              takeWhile(() => activate),
              map(() => ExceptionsCountActions.getExceptionsCount())
            )
          : of(ExceptionsCountActions.resetExceptionsCount())
      )
    )
  );

  public getExceptionsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExceptionsCountActions.getExceptionsCount),
      switchMap(() =>
        this.paOrderService.loadExceptionsAmount().pipe(
          map((count: number) => ExceptionsCountActions.getExceptionsCountSuccess({ count })),
          catchError(err => {
            if (environment.production || environment.type === 'production') {
              Sentry.captureException(err);
            }
            return EMPTY;
          })
        )
      )
    )
  );
}
