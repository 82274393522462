<div class="btn-group drp-down full-width" ngbDropdown role="group" aria-label="Button group with nested dropdown">
  <button class="custom-btn" type="button" [ngClass]="buttonClassName" ngbDropdownToggle>
    <azz-rotate-indicator7 [visible]="blockUnblockIndicator"> </azz-rotate-indicator7>
    {{ buttonName }}
  </button>

  <div class="dropdown-menu" ngbDropdownMenu>
    <span *ngFor="let button of innerButtonsName">
      <button
        class=""
        ngbDropdownItem
        type="button"
        *ngIf="!button.isHidden"
        (click)="onButtonClick(button.title)"
        [innerHTML]="firstTranslatePart + button.title | translate: { amount: 10 }"
      ></button>
    </span>
  </div>
</div>
