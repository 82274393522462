import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomControlBase } from '@common/components/forms/custom-control.base';
import { helpers } from '@core/modules/nouvelle-commande/components/date-time/date/helpers';
import { IconCalendarComponent } from '@icons/icon-calendar.component';
import moment from 'moment';
import { PrimeTemplate } from 'primeng/api';
import { AutoFocus } from 'primeng/autofocus';
import { CalendarModule } from 'primeng/calendar';
import { IconFieldModule } from 'primeng/iconfield';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';

const regExpAlphabet = /[a-zA-Z]/g;
const placeholderMask = '__/__/____';

@Component({
  selector: 'azz-old-date',
  standalone: true,
  imports: [
    CalendarModule,
    IconCalendarComponent,
    PrimeTemplate,
    FormsModule,
    IconFieldModule,
    InputMaskModule,
    AsyncPipe,
    InputTextModule,
    AutoFocus,
  ],
  templateUrl: './date.component.html',
  styleUrl: './date.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OldDateComponent),
      multi: true,
    },
  ],
})
export class OldDateComponent extends CustomControlBase<string> {
  @Input()
  public minDate: moment.Moment;

  @Input()
  public maxDate: moment.Moment;

  @Input()
  public isErrorState: boolean = false;

  protected readonly dateFormat = '99/99/9999';
  private lastInputValue: string | null = null;

  protected setAsDate(value: Date): void {
    this.set(moment(value).format('L'));
  }

  protected set(date?: string | null): void {
    // todo: move this to commander when we get rid of the old commander
    if (date === undefined) {
      date = helpers.getCurrentDateStr();
    }

    this.setValue(date);
  }

  private setMaskedDate(value: string): void {
    this.onTouched();

    if (value === placeholderMask) {
      this.value$.next(null);

      setTimeout(() => {
        this.set(undefined);
      });
      return;
    }

    if (!value) {
      this.set(undefined);
      return;
    }

    const invalid = regExpAlphabet.test(value);
    if (invalid) {
      this.set(null);
      return;
    }

    let dateMoment = helpers.autocompleteDateMoment(value);

    if (!dateMoment.isValid()) {
      this.set(null);
      return;
    }

    if (moment().diff(dateMoment, 'day') > 30) {
      dateMoment = dateMoment.year(moment().year() + 1);
    }

    this.set(dateMoment.format('L'));
  }

  protected blur(event: Event): void {
    const target = event.target as HTMLInputElement;

    this.setMaskedDate(target.value || this.lastInputValue);

    this.lastInputValue = null;
  }

  protected onInput($event: Event): void {
    const target = $event.target as HTMLInputElement;
    this.lastInputValue = target.value;
  }
}
