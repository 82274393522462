import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomersData } from '@core/models/customer.model';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PACompetencyLevelName } from '../../constant/phone-advisor.constant';
import { IExtensionForAssignedAgent, IFleetAgent } from '../../models/cti.model';
import { IPhoneAdvisorsData, IPhoneAdvisorsDataParams } from '../../models/phone-advisor.model';
import { IPhoneAdvisorUserInfo } from '../../models/user-info.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class PhoneAdvisorService extends ApiService {
  public PHONE_ADVISOR_URL = 'user/api/v1/phoneAdvisor/';
  public CUSTOMERS_URL = 'user/api/v1/customer/';
  public PHONE_ADVISOR_SESSIONS_URL = 'api/v1/userSession/';
  private readonly agentId$ = new Subject<number>();

  constructor(private readonly httpClient: HttpClient) {
    super(httpClient);
  }

  get agentId(): Observable<number> {
    return this.agentId$.asObservable();
  }

  public getPhoneAdvisorsData(params: Partial<IPhoneAdvisorsDataParams>): Observable<IPhoneAdvisorsData> {
    return this.get(`${this.PHONE_ADVISOR_URL}search`, params);
  }

  public getPhoneAdvisorById(id: number): Observable<IPhoneAdvisorUserInfo> {
    return this.get(`${this.PHONE_ADVISOR_URL}${id}`);
  }

  public deactivatePhoneAdvisor(phoneAdvisorId, data) {
    return this.put(`${this.PHONE_ADVISOR_URL}deactivate/${phoneAdvisorId}`, data);
  }

  public activatePhoneAdvisor(phoneAdvisorId) {
    return this.put(`${this.PHONE_ADVISOR_URL}restore/${phoneAdvisorId}`, null);
  }

  public killSession(userLogin) {
    return this.put(`${this.PHONE_ADVISOR_SESSIONS_URL}killSession/${userLogin}`, null);
  }

  public updatePhoneAdvisor(userId: number, body: IPhoneAdvisorUserInfo): Observable<IPhoneAdvisorUserInfo> {
    return this.put(`${this.PHONE_ADVISOR_URL}${userId}`, body);
  }

  public getCustomers(params: {
    pageNumber?: number;
    pageSize?: number;
    sort?: string[];
    anyParam?: string;
    name?: string;
    lastName?: string;
    subscriberCode?: string;
    phoneNumber?: string;
    phoneCode?: string;
    fleetId?: any;
    compoundName?: string;
    status?: string;
  }): Observable<ICustomersData> {
    return this.get(`${this.CUSTOMERS_URL}search`, params);
  }

  public getCustomerById(id: string | number) {
    return this.get(`${this.CUSTOMERS_URL}${id}`);
  }

  public createCustomer(data: any) {
    return this.post(`${this.CUSTOMERS_URL}`, data);
  }

  public updateCustomerData(data: any) {
    return this.put(`${this.CUSTOMERS_URL}`, data);
  }

  public blockCustomer(data: any) {
    return this.put(`${this.CUSTOMERS_URL}`, data);
  }

  public getFreeAgents(): Observable<IFleetAgent[]> {
    return this.get(`${this.PHONE_ADVISOR_URL}agents`);
  }

  public getDataForAssignAgentForPA(userId: number): Observable<IFleetAgent> {
    return this.get(`${this.PHONE_ADVISOR_URL}agent/${userId}`);
  }

  public getExtensionForAssignedAgent(): Observable<IExtensionForAssignedAgent> {
    return this.get(`${this.PHONE_ADVISOR_URL}extension`);
  }

  public loginAgent(): Observable<IFleetAgent> {
    return this.put(`${this.PHONE_ADVISOR_URL}loginAgent`, {}).pipe(
      tap((res: IFleetAgent) => this.agentId$.next(res.agentId))
    );
  }

  public logoutAgent(): Observable<IFleetAgent> {
    return this.put(`${this.PHONE_ADVISOR_URL}logoutAgent`, {});
  }

  public getCompetenceLevels(): Observable<PACompetencyLevelName[]> {
    return this.get(`${this.PHONE_ADVISOR_URL}levels`);
  }

  public getUserAgentState(): Observable<{ state: string }> {
    return this.get(`${this.PHONE_ADVISOR_URL}agentState`);
  }
}
