import { Injectable } from '@angular/core';

import { LocalStorageType } from '../../constant/local-storage.constant';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public clear(): void {
    localStorage.clear();
  }

  public getLocalStorageItem(name: LocalStorageType): any {
    return JSON.parse(localStorage.getItem(name));
  }

  public setLocalStorageItem(name: LocalStorageType, value: any): void {
    localStorage.setItem(name, JSON.stringify(value));
  }

  public checkTelephnyEnabled(): boolean {
    const telephoneStatus = this.getLocalStorageItem('CURRENT_USER_INFO')?.fleet?.telephonyEnabled;
    return telephoneStatus;
  }

  public checkSmsEnabled(): boolean {
    const smsStatus = this.getLocalStorageItem('CURRENT_USER_INFO')?.fleet?.smsEnabled;
    return smsStatus;
  }
}
