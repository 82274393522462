import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { createAction, props } from '@ngrx/store';

import { IPaginationState, ISortingState } from '../../../../models/common.model';
import {
  G7Order,
  IBeforeDispatchOrdersData,
  IG7OrderMultiReturnRes,
  IG7OrderMultiValidationRes,
  IG7OrdersData,
  IG7OrderSingleValidateBody,
  IG7OrdersParams,
  IMultiReturnG7Order,
  IMultiReturnPagination,
  IMultiValidationG7Order,
  IMultiValidationPagination,
} from '../../../../models/g7-order.model';

export const g7OrdersInit = createAction('[G7 Orders] Init');
export const g7OrdersDestroy = createAction('[G7 Orders] Destroy');

export const toggleSelection = createAction('[G7 Orders] Toggle Selection', props<{ orderId: number }>());
export const toggleAllSelection = createAction('[G7 Orders] Toggle All Selection');
export const setAllSelection = createAction('[G7 Orders] Set All Selection');
export const resetAllSelection = createAction('[G7 Orders] Reset All Selection');
export const resetSelection = createAction('[G7 Orders] Reset selection', props<{ orderId: number }>());
export const getG7Orders = createAction('[G7 Orders] Get G7 Orders');
export const getG7OrdersSuccess = createAction(
  '[G7 Orders] Get G7 Orders Success',
  props<{ ordersData: IG7OrdersData }>()
);
export const toggleG7OrdersAllValidatable = createAction('[G7 Orders] Toggle G7 Orders All Validatable Selected');
export const getG7OrdersAllValidatable = createAction('[G7 Orders] Get G7 Orders All Validatable');
export const getG7OrdersAllValidatableSuccess = createAction(
  '[G7 Orders] Get G7 Orders All Validatable Success',
  props<{ ordersData: IG7OrdersData }>()
);
export const getG7OrdersError = createAction('[G7 Orders] Get G7 Orders Error');
export const updateG7OrdersFilterModel = createAction(
  '[G7 Orders] Update G7 Orders Filter Model',
  props<{ filterModel: Partial<IG7OrdersParams> }>()
);
export const updateG7OrdersPagination = createAction(
  '[G7 Orders] Update G7 Orders Pagination',
  props<{ pagination: Partial<IPaginationState> }>()
);
export const updateG7OrdersSorting = createAction(
  '[G7 Orders] Update G7 Orders Sorting',
  props<{ sorting: Partial<ISortingState> }>()
);
export const openBeforeDispatchPopup = createAction('[G7 Orders] Open Before Dispatch Popup');
export const closeBeforeDispatchPopup = createAction('[G7 Orders] Close Before Dispatch Popup');
export const getOrdersBeforeDispatch = createAction('[G7 Orders] Get Orders Before Dispatch');
export const getOrdersBeforeDispatchSuccess = createAction(
  '[G7 Orders] Get Orders Before Dispatch Success',
  props<{ ordersData: IBeforeDispatchOrdersData }>()
);
export const getOrdersBeforeDispatchError = createAction('[G7 Orders] Get Orders Before Dispatch Error');
export const updateDispatchPagination = createAction(
  '[G7 Orders] Update Dispatch Pagination',
  props<{ pagination: Partial<IPaginationState> }>()
);
export const dispatchG7Orders = createAction('[G7 Orders] Dispatch G7 Orders', props<{ popup: NgbActiveModal }>());
export const dispatchG7OrdersSuccess = createAction('[G7 Orders] Dispatch G7 Orders Success');
export const dispatchG7OrdersError = createAction('[G7 Orders] Dispatch G7 Orders Error');
export const openValidationPopup = createAction(
  '[G7 Orders] Open Validation Popup',
  props<{ orderId: number; body: IG7OrderSingleValidateBody }>()
);
export const closeValidationPopup = createAction('[G7 Orders] Close Validation Popup');
export const validateG7Order = createAction(
  '[G7 Orders] Validate G7 Order',
  props<{ orderId: number; body: IG7OrderSingleValidateBody }>()
);
export const exportCsv = createAction('[G7 Orders] Export Csv');
export const openInvalidatePopup = createAction('[G7 Orders] Open Invalidate Popup', props<{ order: G7Order }>());
export const closeInvalidatePopup = createAction('[G7 Orders] Close Invalidate Popup');
export const invalidateG7Order = createAction(
  '[G7 Orders] Invalidate G7 Order',
  props<{ orderId: number; body: IG7OrderSingleValidateBody }>()
);
export const openMultiValidationPopup = createAction('[G7 Orders] Open Multi Validation popup');

export const closeMultiValidationPopup = createAction('[G7 Orders] Close Multi Validation popup');

export const updateMultiValidationPagination = createAction(
  '[G7 Orders] Update Multi Validation Pagination',
  props<{ pagination: Partial<IMultiValidationPagination> }>()
);

export const getMultiValidationOrders = createAction('[G7 Orders] Get Multi Validation Orders');
export const getMultiValidationOrdersSuccess = createAction(
  '[G7 Orders] Get Multi Validation Orders Success',
  props<{ orders: IMultiValidationG7Order[] }>()
);

export const getMultiValidationOrdersError = createAction('[G7 Orders] Get Multi Validation Error');
export const multiValidateG7Orders = createAction(
  '[G7 Orders] Multi Validate G7 Orders',
  props<{ popup: NgbActiveModal }>()
);

export const multiValidateG7OrdersHandleRes = createAction(
  '[G7 Orders] Multi Validate G7 Orders Handle Res',
  props<{ response: IG7OrderMultiValidationRes[] }>()
);

export const multiValidateG7OrdersHandleSuccess = createAction('[G7 Orders] Multi Validate G7 Orders Handle Success');

export const openMultiValidationErrorPopup = createAction(
  '[G7 Orders] Open Multi Validate G7 Orders Error Popup',
  props<{ orders: G7Order[] }>()
);

export const closeMultiValidationErrorPopup = createAction('[G7 Orders] Close Multi Validate G7 Orders Error Popup');
export const multiValidateG7OrdersError = createAction('[G7 Orders] Multi Validate G7 Orders Error');

// Multi return G7 order

export const openMultiReturnPopup = createAction('[G7 Orders] Open Multi Return popup');

export const closeMultiReturnPopup = createAction('[G7 Orders] Close Multi Return popup');

export const updateMultiReturnPagination = createAction(
  '[G7 Orders] Update Multi Return Pagination',
  props<{ pagination: Partial<IMultiReturnPagination> }>()
);

export const getMultiReturnOrders = createAction('[G7 Orders] Get Multi Return Orders');

export const getMultiReturnOrdersSuccess = createAction(
  '[G7 Orders] Get Multi Return Orders Success',
  props<{ orders: IMultiReturnG7Order[] }>()
);

export const getMultiReturnOrdersError = createAction('[G7 Orders] Get Multi Return Error');

export const multiReturnG7Orders = createAction(
  '[G7 Orders] Multi Return G7 Orders',
  props<{ popup: NgbActiveModal; message: string }>()
);

export const multiReturnG7OrdersHandleRes = createAction(
  '[G7 Orders] Multi Return G7 Orders Handle Res',
  props<{ response: IG7OrderMultiReturnRes[] }>()
);

export const multiReturnG7OrdersHandleSuccess = createAction('[G7 Orders] Multi Return G7 Orders Handle Success');

export const openMultiReturnErrorPopup = createAction(
  '[G7 Orders] Open Multi Return G7 Orders Error Popup',
  props<{ orders: G7Order[] }>()
);

export const closeMultiReturnErrorPopup = createAction('[G7 Orders] Close Multi Return G7 Orders Error Popup');
export const multiReturnG7OrdersError = createAction('[G7 Orders] Multi Return G7 Orders Error');
