import { OrderForm } from './types/order-form.type';

export const baseFormFieldTitles: Array<keyof OrderForm> = [
  'anonymousPassenger',
  'appointmentComment',
  'comment',
  'destinationComment',
  'passengerName',
  'selectedCustomerPhoneNumber',

  'order',
  'customer',

  'paymentType',
  'serviceType',
  'vehicleType',
];
