<ng-template #content let-modal>
  <div class="azz-popup custom-dialog">
    <h3 class="custom-dialog__header" *ngIf="header" [innerHTML]="header | translate: headerTranslateParams"></h3>
    <p class="custom-dialog__message" *ngIf="message" [innerHTML]="message | translate: messageTranslateParams"></p>

    <div class="custom-dialog__btn-group">
      <button
        *ngIf="hasCancelButton"
        type="button"
        class="custom-btn custom-dialog__cancel-btn"
        [ngClass]="customCancelBtnClass ? customCancelBtnClass : 'custom-btn--grey'"
        [disabled]="loadingIndicator"
        (click)="onClose()"
      >
        {{ cancelValue || 'CANCEL' | translate | titlecase }}
      </button>
      <button
        *ngIf="hasOkButton"
        type="button"
        class="custom-btn btn-rotate-flex btn-rotate-flex--w-auto"
        [ngClass]="customOkBtnClass ? customOkBtnClass : 'custom-btn--green'"
        (click)="isSubmitted ? onSubmit() : onSuccess()"
      >
        <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
        {{ okValue || 'CORE_OK_VALUE' | translate | titlecase }}
      </button>
    </div>
  </div>
</ng-template>
