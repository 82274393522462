import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IDriverLicense } from '../../../models/driver-license.model';
import { ITransportLicense } from '../../../models/transport-license.model';
import { IDriverUserInfo } from '../../../models/user-info.model';

@Component({
  selector: 'app-driver-details-not-validated',
  templateUrl: './driver-details-not-validated.component.html',
  styleUrls: ['./driver-details-not-validated.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsNotValidatedComponent {
  @Input() transportLicense: ITransportLicense;
  @Input() driverLicense: IDriverLicense;
  @Input() driver: IDriverUserInfo;
  @Input() btnLoading: boolean;
  @Output() openValidatePopup$ = new EventEmitter<void>();

  public openValidatePopup(): void {
    this.openValidatePopup$.emit();
  }
}
