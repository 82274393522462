import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { ITag } from '../../models/tag.model';

@Injectable({ providedIn: 'root' })
export class TagService extends ApiService {
  private readonly tagUrl = 'user/api/v1/tag/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getAllTags(): Observable<ITag[]> {
    return this.get(`${this.tagUrl}tags`);
  }

  public getDriverTags(driverId: number): Observable<ITag[]> {
    return this.get(`${this.tagUrl}${driverId}/drivertags`);
  }

  public saveDriverTags(driverId: number, ids: string[]): Observable<ITag[]> {
    return this.post(`${this.tagUrl}${driverId}/drivertags`, { ids });
  }

  public getCrcTags(): Observable<ITag[]> {
    return this.get(`${this.tagUrl}crcordertags`);
  }
}
