<azz-loading-block [isLoading]="loading$ | async">
  <section class="row" *ngIf="driverTagsIds$ | async as driverTagsIds">
    <div class="col-md-4">
      <fieldset [disabled]="azzReadOnly">
        <app-driver-tags-list
          [tagsIds]="driverTagsIds | slice: 0 : driverTagsIds?.length / 2 + (driverTagsIds?.length % 2)"
          [selectedTagsIdsMap]="selectedTagsIdsMap$ | async"
          (toggleTag$)="toggleTag($event)"
        >
        </app-driver-tags-list>
      </fieldset>
    </div>
    <div class="col-md-4">
      <fieldset [disabled]="azzReadOnly">
        <app-driver-tags-list
          [tagsIds]="
            driverTagsIds | slice: driverTagsIds?.length / 2 + (driverTagsIds?.length % 2) : driverTagsIds?.length
          "
          [selectedTagsIdsMap]="selectedTagsIdsMap$ | async"
          (toggleTag$)="toggleTag($event)"
        >
        </app-driver-tags-list>
      </fieldset>
    </div>
  </section>
</azz-loading-block>
