<form [formGroup]="form">
  <div class="row">
    <div class="form-group col-md-4">
      <label for="first-name">{{ 'FIRST_NAME' | translate }}*</label>
      <input
        id="first-name"
        type="text"
        name="first_name"
        formControlName="firstName"
        maxlength="100"
        class="form-control azz-transparent-input azz-validate"
        required
      />
      <div class="form-control__error" *ngIf="form.controls?.firstName?.errors && form.controls?.firstName?.touched">
        <small *ngIf="form.controls.firstName.errors?.required">
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="form.controls.firstName.errors?.azzNotLetters">
          {{ 'CORE_NOT_NUMBERS_FIELD_VALIDATION_ERROR' | translate }}
        </small>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label for="last-name">{{ 'LAST_NAME' | translate }}*</label>
      <input
        id="last-name"
        type="text"
        name="last_name"
        formControlName="lastName"
        maxlength="100"
        class="form-control azz-transparent-input azz-validate"
        required
      />
      <div class="form-control__error" *ngIf="form.controls?.lastName?.errors && form.controls?.lastName?.touched">
        <small *ngIf="form.controls.lastName.errors?.required">
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="form.controls.lastName.errors?.azzNotLetters">
          {{ 'CORE_NOT_NUMBERS_FIELD_VALIDATION_ERROR' | translate }}
        </small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-4">
      <label for="birth-date">{{ 'BIRTHDAY' | translate }}*</label>
      <input
        id="birth-date"
        type="text"
        [max]="maxBirthdayDate"
        name="birth_date"
        formControlName="birthday"
        class="form-control azz-transparent-input azz-validate"
        required
        [owlDateTimeTrigger]="dt1"
        [owlDateTime]="dt1"
      />
      <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1" startView="multi-years" #dt1 />
      <div *ngIf="form.controls.birthday.invalid && form.controls.birthday.touched" class="form-control__error">
        <small *ngIf="form.controls?.birthday?.errors?.required">{{
          'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
        }}</small>
      </div>
    </div>
    <div class="form-group col-md-4">
      <label for="email">{{ 'EMAIL' | translate }}*</label>
      <input
        id="email"
        type="email"
        name="email"
        formControlName="email"
        maxlength="100"
        class="form-control azz-transparent-input azz-validate"
        required
      />
      <div class="form-control__error" *ngIf="form?.controls?.email?.errors && form.controls.email.touched">
        <small *ngIf="form.controls?.email?.errors?.required">
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="form.controls?.email?.errors?.pattern">
          {{ 'CORE_INVALID_EMAIL_VALIDATION_ERROR' | translate }}
        </small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 form-group">
      <label for="driver-country">{{ 'COUNTRY' | translate }}*</label>
      <select
        id="driver-country"
        name="driver_country"
        class="form-control azz-transparent-input azz-validate azz-expandable"
        [compareWith]="compareCountry"
        formControlName="country"
      >
        <option *ngFor="let country of countries; trackBy: trackCountry" [ngValue]="country">
          {{ country.localizedName }}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 form-group">
      <label [for]="driverAddressId" class="azz-transparent-input-label">{{ 'SEARCH_ADDRESS' | translate }}</label>
      <azz-address-autocomplete
        [ngDisabled]="ngDisabled"
        [ngId]="driverAddressId"
        (typing)="onAddressType()"
        (selectAddress)="onAddressSelect($event)"
      >
      </azz-address-autocomplete>
    </div>
  </div>

  <!-- Phone Number START -->
  <div class="row flex-row align-items-center">
    <div class="form-group col-md-2">
      <label for="driver-phone-code">{{ 'PHONE_CODE' | translate }}*</label>
      <select
        id="driver-phone-code"
        name="driver_phone_code"
        class="form-control azz-transparent-input azz-validate"
        formControlName="phoneCode"
        required
      >
        <option *ngFor="let country of countries; trackBy: trackCountry" [value]="country.phoneCode">
          {{ country.phoneCode }}
        </option>
      </select>
    </div>

    <div class="form-group col-md-4">
      <label for="fleet-phone-number">{{ 'PHONE_NUMBER' | translate }}*</label>
      <input
        id="fleet-phone-number"
        type="text"
        name="fleet_phone_number"
        formControlName="phoneNumber"
        maxlength="20"
        class="form-control azz-transparent-input azz-validate"
        required
      />
      <div class="form-control__error" *ngIf="phoneNumberControl?.errors && phoneNumberControl.touched">
        <small *ngIf="phoneNumberControl.errors?.required">
          {{ 'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="phoneNumberControl.errors?.pattern">
          {{ 'CORE_INVALID_PHONE_NUMBER_VALIDATION_ERROR' | translate }}
        </small>
        <small *ngIf="phoneNumberControl.errors?.minlength">
          {{ 'MIN_LENGTH_ERROR' | translate: { minLength: minPhoneLength } }}
        </small>
        <small *ngIf="phoneNumberControl.errors?.maxlength">
          {{ 'MAX_LENGTH_ERROR' | translate: { maxLength: maxPhoneLength } }}
        </small>
      </div>
    </div>

    <azz-phone-call-button
      *ngIf="checkPhone()"
      [phoneNumber]="phoneCodeControl.value + phoneNumberControl.value"
      [link]="customerPhoneCallLink"
    ></azz-phone-call-button>
    <!--Removed Device block Due to task 131044-->
  </div>
  <!-- Phone Number END -->
  <div class="row">
    <div class="col-md-4 form-group">
      <div class="azz-transparent-input-label">{{ 'FD_REGISTRATION_DATE' | translate }}</div>
      <div class="azz-transparent-input azz-color-black">
        {{ driver?.creationTime | date: 'dd.MM.yyyy' }}
      </div>
    </div>
  </div>
</form>
