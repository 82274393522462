import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Component({
  selector: 'azz-pre-save-modal',
  templateUrl: './pre-save-modal.component.html',
  styleUrls: ['./pre-save-modal.component.less'],
})
export class PreSaveConfirmDialogComponent {
  @ViewChild('content') dialogContent: any;
  @Output() azzClear = new EventEmitter();

  public message: string;
  public header: string;
  public modalClass: string;
  public messageTranslateParams: { [property: string]: string | number };
  private modalReference: NgbModalRef;

  constructor(
    private readonly dialogService: NgbModal,
    private readonly store: Store
  ) {}

  public showDialog(header: string, message: string, options?: any): void {
    this.message = message;
    this.header = header;
    if (options) {
      this.messageTranslateParams = options.messageTranslateParams;
      this.modalClass = options.modalClass;
    }
    this.modalReference = this.dialogService.open(this.dialogContent, {
      windowClass: this.modalClass,
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalReference.result.then(
      () => this.onClose(),
      () => this.onClose()
    );
  }

  public close(): void {
    this.modalReference.close();
  }

  public clear(): void {
    this.azzClear.emit();
    this.modalReference.close();
  }

  public hasOpenModals(): boolean {
    return !!this.modalReference;
  }

  private onClose(): void {
    this.resetModalReference();
  }

  private resetModalReference(): void {
    this.modalReference = null;
  }
}
