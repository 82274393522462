<div class="azz-icon-group profile">
  <div class="azz-icon-cropper">
    <img class="azz-icon" *ngIf="imagePath || azzCurrent" [src]="imagePath || azzCurrent + '=s80-c'" alt="avatar" />
    <div class="azz-initials" *ngIf="!imagePath && !azzCurrent">{{ firstName[0] }}{{ lastName[0] }}</div>
  </div>
  <div class="azz-uploader-block">
    <label for="profile-picture">{{ 'FP_UPDATE_PROFILE_PICTURE_LABEL' | translate }}</label>
    <input
      id="profile-picture"
      type="file"
      name="profile_picture"
      accept="image/png, image/jpeg"
      (change)="onAvatarSelect($event)"
      [disabled]="ngDisabled"
      [required]="ngRequired"
    />
    <div class="azz-instruct">{{ 'FP_INSTRUCT_10MB_MAX' | translate }}</div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="profile__error profile__error--size" [ngClass]="{ 'profile__error--active': this.sizeError }">
  {{ 'CORE_FILE_SIZE_INVALID' | translate }}
</div>
<div class="profile__error profile__error--type" [ngClass]="{ 'profile__error--active': this.typeOrExtensionError }">
  {{ 'CORE_FILE_TYPE_EXTENSION_INVALID' | translate }}
</div>
