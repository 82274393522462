<ng-template #resultTemplate let-result="result" let-term="term">
  <div *ngIf="result && result?.poi" class="autocomplete__poi">
    <ngb-highlight [result]="result?.label ? result?.label : result?.poi?.label" [term]="term"></ngb-highlight>
    <img src="assets/images/geo-pointer.png" alt="geo icon" class="geomark" />
    <!-- <img src="assets/images/black-marker.svg" alt="poi icon" class="autocomplete__poi-icon"> -->
  </div>
  <div *ngIf="result && !result?.poi">
    <ngb-highlight [result]="result?.address + ', ' + result?.city" [term]="term"></ngb-highlight>
  </div>
  <div *ngIf="!result">{{ 'ADDRESS_NOT_FOUND' | translate }}</div>
</ng-template>

<form class="card azz-multiple autocomplete" [formGroup]="form" #formElemRef>
  <div class="d-flex align-items-center">
    <input
      formControlName="searchField"
      type="text"
      azzFocusOnInputChange
      class="form-control azz-transparent-input azz-validate"
      [id]="ngId"
      [focusTriggerCounter]="customFocusTriggerCounter"
      [ngbTypeahead]="search"
      [resultTemplate]="resultTemplate"
      [inputFormatter]="formatter"
      (blur)="loadingIndicator = false"
      (selectItem)="onAddressSelect($event)"
    />
    <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
  </div>
  <span class="validation-warning" *ngIf="isDepartureAddressOutsideOfBusinessZone">
    {{ 'DISPATCH_OUTSIDE_BUSINESS_ZONE_WARNING' | translate }}
  </span>
  <div *ngIf="form.controls.searchField.touched && form.controls.searchField.errors" class="autocomplete__error">
    <div *ngIf="form.controls.searchField.errors?.noSelectedAddress">
      {{ 'PA_CHOOSE_ADDRESS_NOT_FROM_LIST' | translate }}
    </div>
    <div *ngIf="form.controls.searchField.errors?.addressesEquality">
      {{ addressEqualityError | translate }}
    </div>
  </div>
</form>
