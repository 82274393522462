import { Injectable } from '@angular/core';
import { LOG_TYPE_DIFF_MSG_FIELD, LOG_TYPE_DIFF_MSG_TRANSLATE } from '@core/constant/order-logs.constant';
import { IOrderDiffMsgLogAction, IOrderLogActionValue } from '@core/models/order-logs.model';
import { UtilService } from '@core/services/common';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Injectable()
export class OrderDiffMsgLogService {
  constructor(
    private readonly translate: TranslateService,
    private readonly utilService: UtilService
  ) {}

  private static isValueTruthy(value: string): boolean {
    return value !== null && value !== '' && value !== '[]';
  }

  private static generateListMsg(list: string[]): string {
    const items = list.map((msg: string) => `<li>${msg}</li>`).join('');

    return `<ul class="m-0 p-0">${items}</ul>`;
  }

  public generateMsg({ values = [] }: IOrderDiffMsgLogAction): string {
    const messages: string[] = [];
    values.forEach((value: IOrderLogActionValue) => {
      const { valueBefore, valueAfter } = value;
      if (OrderDiffMsgLogService.isValueTruthy(valueBefore) && OrderDiffMsgLogService.isValueTruthy(valueAfter)) {
        messages.push(this.generateModifiedMsg(value));
      } else if (!OrderDiffMsgLogService.isValueTruthy(valueBefore)) {
        messages.push(this.generateAddMsg(value));
      } else if (!OrderDiffMsgLogService.isValueTruthy(valueAfter)) {
        messages.push(this.generateDeleteMsg(value));
      }
    });

    return messages.length > 1 ? OrderDiffMsgLogService.generateListMsg(messages) : messages[0];
  }

  private generateModifiedMsg(value: IOrderLogActionValue): string {
    const { fieldName, valueBefore, valueAfter } = value;
    return (
      this.utilService.capitalize(this.generateFieldName(fieldName)) +
      ` ${this.translate.instant('MODIFIED')}` +
      ` ${this.translate.instant('FROM')} ` +
      this.generateValue(fieldName, valueBefore) +
      ` ${this.translate.instant('TO')} ` +
      this.generateValue(fieldName, valueAfter)
    );
  }

  private generateAddMsg(value: IOrderLogActionValue): string {
    const { fieldName, valueAfter } = value;
    return `${this.translate.instant('ADD')} ${this.generateFieldName(fieldName)} ${this.generateValue(
      fieldName,
      valueAfter
    )}`;
  }

  private generateDeleteMsg(value: IOrderLogActionValue): string {
    const { fieldName, valueBefore } = value;
    return `${this.translate.instant('DELETE')} ${this.generateFieldName(fieldName)} ${this.generateValue(
      fieldName,
      valueBefore
    )}`;
  }

  private generateFieldName(fieldName: string): string {
    if (fieldName === 'billingStatus') {
      return this.translate.instant('FACTURATION_STATUS');
    }

    return LOG_TYPE_DIFF_MSG_TRANSLATE[fieldName]
      ? this.translate.instant(LOG_TYPE_DIFF_MSG_TRANSLATE[fieldName]).toLowerCase()
      : fieldName;
  }

  private generateValue(fieldName: string, value: string): string {
    let result: string;
    switch (fieldName) {
      case LOG_TYPE_DIFF_MSG_FIELD.status:
        return (result = this.translate.instant(`CORE_ORDER_STATUS_${value}`));
      case LOG_TYPE_DIFF_MSG_FIELD.date:
        return (result = moment(value).format('YYYY-MM-DD'));
      case LOG_TYPE_DIFF_MSG_FIELD.time:
        return (result = moment(value).format('HH:mm'));
      case LOG_TYPE_DIFF_MSG_FIELD.billingStatus:
        return this.translate.instant(`CORE_BILLING_ORDER_STATUS_${value}`);
      default:
        result = value;
    }

    return result;
  }
}
