import { inject, Injectable } from '@angular/core';
import { IUserNotificationsData } from '@core/models/user-notification.model';
import { UserNotificationService } from '@core/services/users/user-notification.service';
import { createEffect, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import * as UserNotificationsActions from '../actions/user-notifications.actions';

@Injectable()
export class UserNotificationsEffects extends EffectsBase {
  private readonly userNotificationService = inject(UserNotificationService);

  public getNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserNotificationsActions.getUserNotifications),
      switchMap(() =>
        this.userNotificationService.getNotifications({ message: false }).pipe(
          map((notificationsData: IUserNotificationsData) =>
            UserNotificationsActions.getUserNotificationsSuccess({ notificationsData })
          ),
          this.catchError(UserNotificationsActions.getUserNotificationsError())
        )
      )
    )
  );

  public readNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserNotificationsActions.readUserNotification),
      mergeMap(({ id }) =>
        this.userNotificationService.readNotification({ ids: [id] }).pipe(
          map(() => UserNotificationsActions.readUserNotificationSuccess({ id })),
          this.catchError(UserNotificationsActions.readUserNotificationError())
        )
      )
    )
  );
}
