<p-toast position="bottom-right" [key]="alertsKey">
  <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
    <div class="p-toast-message-content">
      <span class="p-toast-message-icon">
        @switch (message.severity) {
          @case ('success') {
            <span class="pi-check-circle pi"></span>
          }
          @case ('warn') {
            <span class="pi-exclamation-triangle pi"></span>
          }
          @case ('error') {
            <span class="pi-times-circle pi"></span>
          }
        }
      </span>
      <div class="p-toast-message-text">
        <p class="p-toast-detail">
          {{ message.detail }}
        </p>
        <div *ngIf="asToastData(message.data) as data">
          {{ data.subText }}
          <div *ngIf="data.route">
            <a [routerLink]="data.route.link"> {{ data.route.text | translate }} </a>
          </div>
        </div>
      </div>
      <azz-cross-button ariaLabel="Cancel" (click)="closeFn($event)" styleClass="p-toast-icon-close" />
    </div>
  </ng-template>
</p-toast>
