/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderCustomerResponseWebApiModel } from './order-customer-response-web.api-model';
import { DriverItemResponseWebApiModel } from './driver-item-response-web.api-model';
import { OrderStatusEnumWebApiModel } from './order-status-enum-web.api-model';
import { OrderAddressResponseWebApiModel } from './order-address-response-web.api-model';

/**
 * component with order data in orders search response
 */
export interface OrderItemResponseWebApiModel {
  /**
   * order internal ID
   */
  id: string;
  /**
   * order reference number
   */
  reference: string;
  /**
   * authorization number of the G7 order if present
   */
  partnerId?: string;
  status: OrderStatusEnumWebApiModel;
  driver?: DriverItemResponseWebApiModel;
  customer: OrderCustomerResponseWebApiModel;
  pickup: OrderAddressResponseWebApiModel;
  dropoff?: OrderAddressResponseWebApiModel;
  /**
   * trip price in €. See display details in Tab Courses spec
   */
  montant?: number;
}
export namespace OrderItemResponseWebApiModel {}
