import { AuthStore } from '@auth/store';
import { Action, ActionReducer, createReducer, MetaReducer } from '@ngrx/store';

export const ordersFeatureKey = 'orders';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

const initialState: State = {};

const ordersReducer = createReducer(initialState);

export const reducer = (state: State | undefined, action: Action) => ordersReducer(state, action);

export const clearState =
  (reducerCleared: ActionReducer<State>): ActionReducer<State> =>
  (state: State | undefined, action: Action) => {
    if (action.type === AuthStore.logoutSuccess.type) {
      return reducerCleared(undefined, action);
    }

    return reducerCleared(state, action);
  };

export const metaReducers: MetaReducer[] = [clearState];
