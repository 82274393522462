import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { FrenchPhoneConstraints } from '@core/constant/french-phone-constraints.constant';
import { FRENCH_PHONE_CODE } from '@dash/modules/phone-advisor/common/constants';

interface InternationalPhoneParams {
  phoneNumber: FormControl<string>;
  phoneCode: FormControl<{
    isoCode: string;
    code: string;
  }>;
}

const DIGITS_ONLY_WITH_PLUS_AT_START_REGEX = new RegExp(/^\+\d+$/);

/**
 * Validator for French numbers, should ignore non French number. Can be apply to FormControl<string> or FormGroup with country prefix
 *
 * @returns minLength error | maxLength error | digitsOnly error
 */
export const frenchTelephoneValidator = (abstractControl: AbstractControl): ValidationErrors | null => {
  let fullPhone: string;
  if (abstractControl instanceof FormGroup) {
    const {
      phoneNumber,
      phoneCode: { code },
    } = abstractControl.value;
    fullPhone = `${code}${phoneNumber}`;
  } else {
    fullPhone = abstractControl.value;
  }

  if (typeof fullPhone !== 'string') {
    console.warn('[frenchTelephoneValidator] wrong input type');
  }

  const validationResult = checkPhoneNumberForFrenchConstraints(fullPhone);

  if (abstractControl instanceof FormGroup) {
    abstractControl.controls.phoneNumber.setErrors(validationResult);
    abstractControl.markAllAsTouched();
  }
  return validationResult;
};

const checkPhoneNumberForFrenchConstraints = (phone: string): ValidationErrors => {
  const { minLength, maxLength, codeLength } = FrenchPhoneConstraints;
  const phoneExists = !!phone;

  let errors: ValidationErrors = null;
  if (!phoneExists || !phone.startsWith(FRENCH_PHONE_CODE)) {
    return null;
  }

  const hasNotAllowedCharacters = !DIGITS_ONLY_WITH_PLUS_AT_START_REGEX.test(phone);

  if (hasNotAllowedCharacters) {
    errors = { digitsOnly: true };
  }

  const lengthWithoutPrefix = phone.length - codeLength;
  const isTooSmall = phone.length <= 11;
  if (isTooSmall) {
    errors = { minLength: true, ...errors };
  }

  const isTooBig = lengthWithoutPrefix > maxLength;
  if (isTooBig) {
    errors = { maxLength: true, ...errors };
  }

  return errors;
};
