import { CommonModule } from '@angular/common';
import { Directive, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[azzDisableControl]',
})
export class DisableControlDirective implements OnChanges {
  @Input() azzDisableControl: boolean;
  constructor(private readonly ngControl: NgControl) {}
  ngOnChanges(changes: SimpleChanges) {
    const disableControl = 'azzDisableControl';
    if (changes[disableControl]) {
      const action = this.azzDisableControl ? 'disable' : 'enable';
      setTimeout(() => this.ngControl.control[action]({ emitEvent: false }));
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [DisableControlDirective],
  exports: [DisableControlDirective],
})
export class DisableControlModule {}
