<section class="dropdown" ngbDropdown autoClose="outside">
  <form #ngForm="ngForm">
    <label for="display-value" class="azz-transparent-input-label"
      >{{ azzLabel }}<span *ngIf="ngRequired">*</span></label
    >
    <input
      id="display-value"
      type="text"
      name="display_value"
      class="form-control azz-transparent-input azz-validate azz-expandable"
      [placeholder]="azzPlaceholder"
      [(ngModel)]="displayValue"
      ngbDropdownToggle
      readonly
      [required]="ngRequired"
      [disabled]="ngDisabled"
    />
  </form>
  <div class="dropdown__wrapper" ngbDropdownMenu>
    <ul class="dropdown__list">
      <li *ngFor="let option of optionsData?.content; let i = index" [hidden]="!selectedOptionsTemp[option.id]">
        <app-multi-dropdown-option
          [i]="i"
          [option]="option"
          [optionFormatFields]="optionFormatFields"
          [selectedOptions]="selectedOptionsTemp"
          (toggleOption$)="toggleOption($event)"
        >
        </app-multi-dropdown-option>
      </li>
    </ul>
    <div class="dropdown__search-block azz-has-addon">
      <div class="azz-search-addon"></div>
      <input
        type="text"
        name="search_query"
        [placeholder]="azzSearchPlaceholder | translate"
        class="form-control azz-transparent-input azz-search-field"
        [(ngModel)]="filterModel.name"
        (input)="watcher$.next(0)"
      />
    </div>
    <div class="dropdown__sort" (click)="changeFilterDirection()">
      {{ 'LAST_NAME' | translate }}
      <span
        class="arrow"
        [class.arrow-up]="filterModel.direction === 'asc'"
        [class.arrow-down]="filterModel.direction === 'desc'"
      >
      </span>
    </div>
    <ul class="dropdown__list" *ngIf="!loading">
      <li *ngIf="!filterModel.name">
        <input id="all" type="checkbox" name="all" [checked]="allSelected" (click)="toggleAllSelected()" />
        <label for="all" class="dropdown__option-label">{{ azzAllOptionsLabel }}</label>
      </li>
      <li *ngFor="let option of optionsData?.content; let i = index" [hidden]="selectedOptionsTemp[option.id]">
        <app-multi-dropdown-option
          [i]="i"
          [option]="option"
          [optionFormatFields]="optionFormatFields"
          [selectedOptions]="selectedOptionsTemp"
          (toggleOption$)="toggleOption($event)"
        >
        </app-multi-dropdown-option>
      </li>
    </ul>
    <azz-rotate-indicator7 [visible]="loading"></azz-rotate-indicator7>
    <azz-pagination
      *ngIf="isPaginationVisible()"
      (goToPrevPage)="prevPage()"
      (goToNextPage)="nextPage()"
      [prevDisabled]="isPrevDisabled()"
      [nextDisabled]="isNextDisabled()"
    >
    </azz-pagination>
  </div>
</section>
