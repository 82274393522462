import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as VehicleInfoReducer from '../reducers';

const vehicleInfo = createFeatureSelector<VehicleInfoReducer.State>(VehicleInfoReducer.vehicleInfoFeatureKey);
export const makes = createSelector(vehicleInfo, state => state?.makes);
export const models = createSelector(vehicleInfo, state => state?.models);
export const energies = createSelector(vehicleInfo, state => state?.energies);
export const colors = createSelector(vehicleInfo, state => state?.colors);
export const productionYears = createSelector(vehicleInfo, state => state?.productionYears);
export const serviceTypes = createSelector(vehicleInfo, state => state?.serviceTypes);
export const vehicleTypes = createSelector(vehicleInfo, state => state?.vehicleTypes);
