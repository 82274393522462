<section class="modal-notification">
  <p class="modal-notification__header" *ngIf="header">
    {{ processTextParameter(header) }}
  </p>
  <p
    [ngClass]="isButtonsCentered ? 'modal-notification__message-center' : 'modal-notification__message'"
    *ngIf="message"
    [innerHTML]="processTextParameter(message)"
  ></p>
  <div [ngClass]="isButtonsCentered ? 'modal-notification__btn-group-center' : 'modal-notification__btn-group'">
    <button
      *ngIf="hasCancelButton"
      type="button"
      [ngClass]="isButtonsCentered ? 'custom-btn--red custom-btn__wide' : 'custom-btn--grey'"
      class="custom-btn mr-3"
      (click)="close()"
    >
      {{ closeBtn | translate | titlecase }}
    </button>
    <button
      type="button"
      class="custom-btn custom-btn--green"
      [ngClass]="isButtonsCentered ? 'custom-btn__wide' : ''"
      (click)="submit()"
    >
      {{ submitBtn | translate }}
    </button>
  </div>
</section>
