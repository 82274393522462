import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { IDriverLicense, IDriverLicenseKind } from '../../models/driver-license.model';
import { ITransportLicense } from '../../models/transport-license.model';

@Injectable({ providedIn: 'root' })
export class LicensesService extends ApiService {
  private readonly driverLicenseUrl = '/user/api/v1/driverLicense/';
  private readonly transportLicenseUrl = '/user/api/v1/transportLicense/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getDriverLicenseKinds(): Observable<IDriverLicenseKind[]> {
    return this.get(`${this.driverLicenseUrl}kind`);
  }

  public getTransportLicense(vehicleId: number): Observable<ITransportLicense> {
    return this.get(`${this.transportLicenseUrl}last`, { vehicleId });
  }

  public getDriverLicense(driverId: number): Observable<IDriverLicense> {
    return this.get(`${this.driverLicenseUrl}last`, { driverId });
  }

  public findDriverLicenseKind(kindToFind, kindArray): IDriverLicenseKind | any {
    if (!kindToFind || !kindArray) {
      return null;
    }

    for (const item of kindArray) {
      if (item.id === kindToFind.id) {
        return item;
      }
    }
    return null;
  }

  public approveTransportLicense(id: number): Observable<any> {
    return this.post(`${this.transportLicenseUrl}${id}/approve`, {});
  }

  public cancelTransportLicense(id: number): Observable<any> {
    return this.post(`${this.transportLicenseUrl}${id}/cancel`, {});
  }
}
