import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VehicleInfoEffects } from './effects';
import * as VehicleInfoReducer from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(VehicleInfoReducer.vehicleInfoFeatureKey, VehicleInfoReducer.reducer, {
      metaReducers: VehicleInfoReducer.metaReducers,
    }),
    EffectsModule.forFeature([VehicleInfoEffects]),
  ],
})
export class VehicleInfoStoreModule {}
