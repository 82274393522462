<div class="azz-editable-price" (click)="toggleEditMode(true); $event.stopPropagation()">
  <div *ngIf="!isEditModeEnabled" [ngClass]="{ 'azz-warning': price === 0 }">
    {{ price !== -1 ? price + ' &euro;' : ('CORE_TAXIMETER' | translate) }}
  </div>
  <input
    *ngIf="isEditModeEnabled"
    id="price"
    name="price"
    type="text"
    azzBlurOnTabClick
    class="form-control"
    [(ngModel)]="price"
    (keydown)="keyDown($event)"
    (blur)="onBlur()"
  />
  <div class="form-control__error" *ngIf="maxPrice && price > maxPrice">
    <small>
      {{ 'CORE_MAX_TRIP_PRICE_ERROR_MESSAGE_HIGHLIGHT' | translate: { maxPrice: maxPrice } }}
    </small>
  </div>
</div>
