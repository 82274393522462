import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { AzzGeoMapComponent } from './azz-geo-map.component';

@NgModule({
  imports: [LeafletModule],
  declarations: [AzzGeoMapComponent],
  exports: [AzzGeoMapComponent],
})
export class AzzGeoMapModule {}
