import { UserRole } from '@core/constant/role.constant';

const auth = {
  login: '/auth',
  forgot: '/auth/forgot',
  newPassword: '/auth/new-password',
};

const registration = {
  billingUser: '/registration/billing-user',
  dispatcher: '/registration/dispatcher',
  driver: '/registration/driver',
  fleet: '/registration/fleet',
  phoneAdvisor: '/registration/phone-advisor',
  super: '/registration/super',
};

const pub = {
  agreement: '/public/agreement',
  legal: '/public/legal',
  discover: '/public/discover',
  contact: '/public/contact',
};

const dash = {
  [UserRole.billingUser]: '/billing-user',
  [UserRole.dispatcher]: '/dispatcher',
  [UserRole.driver]: '/driver',
  [UserRole.fleet]: '/fleet',
  [UserRole.phoneAdvisor]: '/phone-advisor',
  [UserRole.super]: '/super',
};

export const RouteUrls = {
  auth,
  registration,
  dash,
  pub,
};

export const getRouteUrl = (role: UserRole) => {
  return dash[role].slice(1);
};
