<div class="azz-icon-group user">
  <div class="azz-icon-cropper" [ngClass]="{ 'azz-clickable': navigateUrl }" (click)="showDriverDetails()">
    <img class="azz-icon" *ngIf="driver?.avatar" [src]="driver?.avatar + '=s80-c'" />
    <div class="azz-initials" *ngIf="!driver?.avatar">
      {{ getDriverInitials() }}
    </div>
  </div>
  <div class="azz-uploader-block">
    <a class="azz-full-name user__link" [ngClass]="{ 'azz-clickable': navigateUrl }" (click)="showDriverDetails()">
      {{ driver?.firstName }}
      {{ driver?.lastName }}
    </a>
  </div>
  <div class="clearfix"></div>
</div>
