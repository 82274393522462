import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../../services/common/api.service';

@Injectable()
export class NotificationService extends ApiService {
  public G7_NOTIFICATIONS_URL = 'user-activity/api/v1/notification/g7';

  constructor(http: HttpClient) {
    super(http);
  }

  public getNotifications(connectedUserId, notificationType, connectedOrderId, limit, pageToken): Observable<any> {
    const payload = { connectedUserId, notificationType, connectedOrderId, limit, pageToken };
    return this.get(this.G7_NOTIFICATIONS_URL, payload);
  }
}
