/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * object with data in response of orders count request
 */
export interface OrdersCountResponseWebApiModel {
  /**
   * all orders available to the current user
   */
  toutes: number;
  /**
   * the orders of type ORDER available to the current user
   */
  immediate: number;
  /**
   * the orders of types RESERVATION and IN_ADVANCE available to the current user
   */
  reservations: number;
  /**
   * the orders in progress available to the current user
   */
  encours: number;
}
