/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * component with data about zones in which specific coordinates placed
 */
export interface CoordinatesZoneResponseWebApiModel {
  /**
   * business zone id in which found coordinates are located. Can be null
   */
  businessZone: string | null;
  /**
   * dispatch zone id in which found coordinates are located. Can be null
   */
  dispatchZone: string | null;
  /**
   * is found business zone relates to current fleet or not
   */
  currentFleetZone: boolean;
  /**
   * order dispatch should be started before specified number of minutes. Nullable if zone not found
   */
  dispatchBeforeMinutes: number | null;
}
