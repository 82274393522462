import { HttpErrorResponse } from '@angular/common/http';
import { AuthStore } from '@auth/store';
import { Action, createReducer, on } from '@ngrx/store';

import * as CoffeeBreakActions from '../actions/coffee-break.actions';

export const coffeeBreakFeatureKey = 'coffee-break';

export interface State {
  isCoffeeBreak: boolean;
  isCtiCoffeeBreak: boolean;
  isCoffeeBreakPopupLoadingIndicator: boolean;
  stopCoffeeBreakError: HttpErrorResponse;
}

const initialState: State = {
  isCoffeeBreak: false,
  isCtiCoffeeBreak: false,
  isCoffeeBreakPopupLoadingIndicator: false,
  stopCoffeeBreakError: null,
};

const coffeeBreakReducer = createReducer(
  initialState,
  on(CoffeeBreakActions.coffeeBreakSuccess, state => ({ ...state, isCoffeeBreak: true })),
  on(CoffeeBreakActions.ctiCoffeeBreakSuccess, state => ({ ...state, isCtiCoffeeBreak: true })),
  on(
    CoffeeBreakActions.stopCoffeeBreak,
    CoffeeBreakActions.stopCtiCoffeeBreak,
    CoffeeBreakActions.coffeeBreakLogout,
    state => ({
      ...state,
      isCoffeeBreakPopupLoadingIndicator: true,
    })
  ),
  on(
    CoffeeBreakActions.stopCoffeeBreakSuccess,
    CoffeeBreakActions.stopCtiCoffeeBreakSuccess,
    AuthStore.firebaseSignInSuccess,
    () => initialState
  ),
  on(CoffeeBreakActions.stopCoffeeBreakError, (state, { error }) => ({
    ...state,
    isCoffeeBreakPopupLoadingIndicator: false,
    stopCoffeeBreakError: error,
  })),
  on(CoffeeBreakActions.resetStopCoffeeBreakError, state => ({
    ...state,
    stopCoffeeBreakError: null,
  })),
  on(AuthStore.loginError, state => ({ ...state, isCoffeeBreakPopupLoadingIndicator: false }))
);

export const reducer = (state: State | undefined, action: Action) => coffeeBreakReducer(state, action);
