import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { LoadWrapperService } from '@common/services/load-wrapper.service';
import { NamedOption } from '@common/types/named-option.type';
import { PhoneComponent } from '@controls/phone/phone.component';
import { CONFIRM_MODE, ConfirmModeTitle } from '@core/constant/confirm-mode.constant';
import { CustomerStatusValue } from '@core/constant/customer-status.constant';
import { DIRECTION } from '@core/constant/direction.enum';
import { PHONE_TYPE, PhoneTypeFieldTitle } from '@core/constant/phone-type.constant';
import { RouteUrls } from '@core/constant/route-urls';
import { ICustomer, ICustomerHistoryData } from '@core/models/customer.model';
import { IGetPaOrdersParams, IPhoneAdvisorOrderData } from '@core/models/phone-advisor-order';
import { CustomConfirmDialogComponent } from '@core/modules/custom-dialog/custom-confirm-dialog';
import { LocalStorageService, PaginationService, UtilService } from '@core/services/common';
import { PageHistory } from '@core/services/common/pagination.service';
import { PAOrderService } from '@core/services/orders/phone-advisor-order.service';
import { CustomerService } from '@core/services/users/customer.service';
import { PhoneAdvisorService } from '@core/services/users/phone-advisor.service';
import { AppState } from '@core/store/reducers';
import { phoneCallButtonSelector } from '@core/store/selectors/phone-call-button.selectors';
import { normalizeWhitespacesIn } from '@core/utils';
import { FormService } from '@core/utils/form-service';
import { sortNumber } from '@core/utils/util';
import { azzFrLettersAndNumsValidator } from '@core/utils/validators';
import {
  blockCustomerOptions,
  CustomerBlockReasonType,
  FRENCH_PHONE_CODE,
} from '@dash/modules/phone-advisor/common/constants';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from '@services/root.service';
import moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

import { phoneNumbersGroupValidator } from '../utils/validators';

type TitledOption<V, T = string> = {
  title: T;
  value: V;
};

@Component({
  selector: 'azz-customer-details',
  templateUrl: './azz-customer-details.component.html',
  styleUrls: ['./azz-customer-details.component.less'],
  providers: [NgOnDestroyService, LoadWrapperService],
})
export class AzzCustomerDetailsComponent implements OnInit {
  private readonly changeDetectionRef = inject(ChangeDetectorRef);
  protected readonly rootService = inject(RootService);

  @Input() isCreateMode: boolean;
  @Input() orderDetailsUrl: string;
  @Input() driverDetailsUrl: string;
  @Input() customersPage: string;
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @ViewChildren(PhoneComponent) public azzPhoneInput: PhoneComponent[];
  @ViewChild(CustomConfirmDialogComponent) confirmDialog: CustomConfirmDialogComponent;

  public form: UntypedFormGroup;
  public customer: ICustomer;
  public customerId: any;
  public customerStatus: any;
  public blockUnblockIndicator: boolean;
  public isPhoneAdvisor: boolean;
  public customerBlockReason;
  public customerBlockTime;
  public availableData: any;
  public numbersRegexp: RegExp;
  public data: any;
  public minPhoneLength: number;
  public maxPhoneLength: number;
  public phoneNumberWatcher = new Subject<{ item: AbstractControl; index: number }>();
  public blockCustomerOptions = blockCustomerOptions;

  public isOrdersLoading = false;
  public isHistoryLoading = false;
  public pageHistory: PageHistory;
  public customerOrdersData: IPhoneAdvisorOrderData;
  public customerOrdersWatcher$ = new BehaviorSubject<number | null>(null);
  public customerReservationOrdersWatcher$ = new BehaviorSubject<number | void>(null);
  public customerHistoryWatcher$ = new BehaviorSubject<number>(null);
  public customerHistory: ICustomerHistoryData;
  public customerPhoneCallLink =
    'https://api-mediation.flexiblecontactcenter.orange-business.com/clickToCall?phoneNumber=%2B';
  public isDisabled: boolean;
  public isPhoneCallButtonAvailable$: Observable<boolean>;
  public phoneCallButtonSelectorOn: boolean;

  private phoneNumbersGroup;
  private phoneConfigCountryCodesData: any[];
  private readonly REQUEST_DELAY_MS = 500;
  private readonly blockParams: { reason: CustomerBlockReasonType | null; status: CustomerStatusValue } = {
    reason: null,
    status: 'ACTIVE',
  };
  public readonly isCustomerLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly customerService: CustomerService,
    private readonly destroyed$: NgOnDestroyService,
    private readonly formService: FormService,
    private readonly localStorageService: LocalStorageService,
    private readonly paOrderService: PAOrderService,
    private readonly paginationService: PaginationService,
    private readonly phoneAdvisorService: PhoneAdvisorService,
    private readonly router: Router,
    private readonly store$: Store<AppState>,
    private readonly translate: TranslateService,
    private readonly utilService: UtilService
  ) {
    this.availableData = {
      prefixes: this.initPrefixes(),
      radioButtons: this.initRadioButtons(),
      phoneTypes: this.initPhoneTypes(),
      phoneCodes: [FRENCH_PHONE_CODE],
    };
    this.pageHistory = this.paginationService.createPageHistory();
    this.numbersRegexp = this.utilService.numbersRegexp;
    this.minPhoneLength = 9;
    this.maxPhoneLength = 9;
    this.initForm();
  }

  private get firstNameControl(): FormControl {
    return this.form.get('firstName') as FormControl;
  }

  private get lastNameControl(): FormControl {
    return this.form.get('lastName') as FormControl;
  }

  @Input() set ngDisabled(value: boolean) {
    this.isDisabled = value;
    if (value) {
      this.form.disable();
    }
  }

  public ngOnInit() {
    this.init();
    this.isPhoneAdvisorRole(this.orderDetailsUrl);
    this.connectAutoConfirmWithConfirmByControl();
  }

  public onClickUnblockCustomerButton() {
    this.blockParams.reason = 'PROD';
    this.blockParams.status = 'ACTIVE';
    this.showUnblockCustomerDialog();
  }

  // Unblock dialog
  public showUnblockCustomerDialog(): void {
    this.confirmDialog.showDialog('', this.translate.instant('CUSTOMER_UNBLOCK_TEXT'), {
      okValue: this.translate.instant('CUSTOMER_UNBLOCK_BUTTON'),
      hasCancelButton: true,
      isSubmitted: true,
    });
  }

  public onClickBlockCustomerButton() {
    this.blockParams.reason = 'PROD';
    this.blockParams.status = 'BLOCKED';
    this.checkCustomersPreviousOrders();
  }

  public resetBlockParams() {
    this.blockParams.reason = null;
    this.blockParams.status = this.customer.status as CustomerStatusValue;
  }

  //Block dialog
  public showBlockCustomerDialog(response: IPhoneAdvisorOrderData): void {
    let text = 'CUSTOMER_DOES_NOT_HAVE_ORDERS_IN_ADVANCE';
    if (response.content.length > 0) {
      text = 'CUSTOMER_HAS_ORDERS_IN_ADVANCE';
    }

    this.confirmDialog.showDialog('', this.translate.instant(text), {
      okValue: this.translate.instant('CUSTOMER_BLOCK_BUTTON'),
      hasCancelButton: true,
      customOkBtnClass: 'custom-btn custom-btn--red',
      isSubmitted: true,
    });
  }

  // *onShow dropdown list
  onSelectedButtonClick(reasonToBlock: CustomerBlockReasonType): void {
    this.blockParams.reason = reasonToBlock;
    this.blockParams.status = 'BLOCKED';
    this.checkCustomersPreviousOrders();
  }

  // check previopus orders first
  public checkCustomersPreviousOrders(): void {
    this.blockUnblockIndicator = true;
    this.customerReservationOrdersWatcher$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => this.checkCustomerForReservations$()),
        tap(() => (this.blockUnblockIndicator = false))
      )
      .subscribe((response: IPhoneAdvisorOrderData) => this.showBlockCustomerDialog(response));
  }

  public checkCustomerForReservations$(): Observable<IPhoneAdvisorOrderData> {
    return this.paOrderService.checkCustomerForReservations(this.generateCustomerParams()).pipe(
      takeUntil(this.destroyed$),
      finalize(() => this.setOrdersLoading(false)),
      catchError(() => {
        this.setOrdersLoading(false);
        return EMPTY;
      })
    );
  }

  public submitBlockCustomer(): void {
    this.blockUnblockIndicator = true;
    this.phoneAdvisorService
      .blockCustomer(this.generateCustomerDataWithoutChange())
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.blockUnblockIndicator = false)),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError(({ error }: HttpErrorResponse) => {
          this.confirmDialog.closeDialog();
          // const dialog = this.modalService.open(AzzNotificationDialogComponent).componentInstance as AzzNotificationDialogComponent;
          // dialog.message = error.message;
          this.blockUnblockIndicator = false;
          return EMPTY;
        })
      )
      .subscribe(data => {
        this.disableEnableForm(data);
        this.customer = data;
        this.loadCustomer();
        this.updateFormValue();
        this.confirmDialog.closeDialog();
        this.customerHistoryWatcher$.next(this.getCurrentHistoryPage());
      });
  }

  public isPhoneAdvisorRole(url: string): void {
    if (url === RouteUrls.dash.phoneAdvisor + '/orders') {
      this.isPhoneAdvisor = true;
    }
    if (url === RouteUrls.dash.fleet + '/orders') {
      this.isPhoneAdvisor = false;
    }
  }

  public disableEnableForm(data: any) {
    if (data?.blockTime) {
      this.form.disable();
      this.isDisabled = true;
    } else {
      this.form.enable();
      this.isDisabled = false;
    }
    this.checkIsDisabled(data);
    this.checkSmsEnabled();
  }

  checkIsDisabled(data) {
    const customerBlockTime = data.blockTime;
    const customerBlockReason = data.blockReason;
    if (!customerBlockTime) {
      this.customerBlockTime = '';
    } else if (customerBlockTime && customerBlockReason) {
      this.customerBlockTime = moment(customerBlockTime).format('DD.MM.YYYY') + ', ';
    } else if (customerBlockTime && !customerBlockReason) {
      this.customerBlockTime = moment(customerBlockTime).format('DD.MM.YYYY') + '. ';
    }
    this.customerBlockReason =
      customerBlockReason === 'PROD'
        ? this.translate.instant('FLEET_PROD_REASON')
        : customerBlockReason === 'INVOICE'
          ? this.translate.instant('FLEET_INVOICE_REASON')
          : '';
  }

  public checkPhone(): boolean {
    return this.phoneCallButtonSelectorOn && this.localStorageService.checkTelephnyEnabled();
  }

  public checkSmsEnabled() {
    const smsEnabled = this.localStorageService.checkSmsEnabled();
    const isCreateMode = this.isCreateMode;

    if (isCreateMode) {
      this.form.get('smsNotificationEnabled').setValue(smsEnabled);
    } else {
      this.form.get('smsNotificationEnabled').setValue(false);
    }

    if (!smsEnabled) {
      this.form.get('smsNotificationEnabled').disable();
    }
  }

  public getCustomerHistory(): void {
    this.customerHistoryWatcher$
      .pipe(
        debounceTime(this.REQUEST_DELAY_MS),
        takeUntil(this.destroyed$),
        switchMap((pageNumber: number) => this.getCustomerHistoryObservable$(pageNumber))
      )
      .subscribe((res: ICustomerHistoryData) => (this.customerHistory = res));
  }

  public getCurrentHistoryPage(): number {
    return this.customerHistory ? this.customerHistory.number : 0;
  }

  public prevHistoryPage(): void {
    this.customerHistoryWatcher$.next(this.getCurrentHistoryPage() - 1);
  }

  public nextHistoryPage(): void {
    this.customerHistoryWatcher$.next(this.getCurrentHistoryPage() + 1);
  }

  public isPrevHistoryBtnDisabled(): boolean {
    return !this.customerHistory || this.customerHistory.first;
  }

  public isNextHistoryBtnDisabled(): boolean {
    return !this.customerHistory || this.customerHistory.last;
  }

  public getCustomersOrders(): void {
    this.customerOrdersWatcher$
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => this.setOrdersLoading(true)),
        switchMap((page: number) => this.getCustomerOrdersObservable$(page)),
        tap(() => this.setOrdersLoading(false))
      )
      .subscribe((response: IPhoneAdvisorOrderData) => (this.customerOrdersData = response));
  }

  public getCustomerOrdersObservable$(page?: number): Observable<IPhoneAdvisorOrderData> {
    const params = this.generateOrdersParams(page);
    return this.paOrderService.getPAOrders(params).pipe(
      takeUntil(this.destroyed$),
      finalize(() => this.setOrdersLoading(false)),
      catchError(() => {
        this.customerOrdersData = null;
        this.setOrdersLoading(false);
        return EMPTY;
      })
    );
  }

  public prevOrdersPage(): void {
    this.customerOrdersWatcher$.next(this.getOrdersCurrentPage() - 1);
  }

  public nextOrdersPage(): void {
    this.customerOrdersWatcher$.next(this.getOrdersCurrentPage() + 1);
  }

  public isOrdersPrevDisabled(): boolean {
    return !this.customerOrdersData || this.customerOrdersData.first;
  }

  public isOrdersNextDisabled(): boolean {
    return !this.customerOrdersData || this.customerOrdersData.last;
  }

  public submit() {
    this.formService.markFieldsAsTouched(this.form);
    this.formService.markFieldsAsTouched(this.form.get('emails') as FormArray);
    for (const group of (this.form.get('phoneNumbers') as FormArray).controls) {
      this.formService.markFieldsAsTouched(group as FormGroup);
    }

    if (!this.isFormValid()) {
      this.formService.scrollToFirstInvalidControl([this.formElemRef]);
      return;
    }

    if (this.isEmailRequiredForConfirmation()) {
      this.rootService.alerts.warn('ENTER_EMAIL');
    } else if (this.isCreateMode) {
      this.createCustomer();
    } else {
      this.updateCustomer();
    }
  }

  public initPhoneControls(): void {
    if (!this.phoneNumbersGroup) {
      return;
    }

    this.phoneNumbersGroup.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      // eslint-disable-next-line no-underscore-dangle
      this.phoneConfigCountryCodesData = (this.azzPhoneInput as any)._results;
    });
  }

  public createCustomer() {
    this.isCustomerLoading$.next(true);
    this.phoneAdvisorService.createCustomer(this.generateCustomerData()).subscribe(() => {
      this.router.navigate([this.customersPage]);
      this.isCustomerLoading$.next(false);
    });
  }

  public updateCustomer() {
    this.isCustomerLoading$.next(true);
    return this.phoneAdvisorService.updateCustomerData(this.generateCustomerData()).subscribe((data: ICustomer) => {
      this.customer = data;
      this.sortCustomerPhones();
      this.sortCustomerEmails();
      this.updateFormValue();
      this.customerHistoryWatcher$.next(this.getCurrentHistoryPage());
      this.isCustomerLoading$.next(false);
    });
  }

  public generateEmailsArray() {
    const emailsArray = [];
    const emailControls = (this.form.get('emails') as FormArray).controls;
    for (const control of emailControls) {
      if (control.value) {
        emailsArray.push({
          email: control.value,
          orderNumber: emailControls.indexOf(control) + 1,
        });
      }
    }
    return emailsArray;
  }

  public initPhoneNumberWatcher() {
    this.phoneNumberWatcher
      .pipe(takeUntil(this.destroyed$), debounceTime(this.REQUEST_DELAY_MS))
      .subscribe((data: { item: AbstractControl; index: number }) => this.handlePhoneNumberChanging(data));
  }

  public getCustomerHistoryObservable$(pageNumber?: number): Observable<ICustomerHistoryData> {
    const params = {
      pageNumber,
      pageSize: 5,
      sorts: null,
      customerId: this.customerId,
    };
    this.isHistoryLoading = true;
    return this.customerService.getCustomerHistory(params).pipe(
      takeUntil(this.destroyed$),
      finalize(() => (this.isHistoryLoading = false))
    );
  }

  public deleteAdditionalPhone(index: any) {
    const phonesArray = this.form.get('phoneNumbers') as FormArray;
    phonesArray.removeAt(index);
  }

  public hasAnyPhoneNumberEqualityError(): boolean {
    return (this.form.get('phoneNumbers') as FormArray).controls.some((item: AbstractControl) =>
      item.hasError('phoneAlreadyExists')
    );
  }

  public allPhoneNumberCheckedOnEquality(): boolean {
    return (this.form.get('phoneNumbers') as FormArray).controls.every(
      (item: AbstractControl) => item.value.equalityChecked
    );
  }

  public addPhoneNumber() {
    const phonesArray = this.form.get('phoneNumbers') as FormArray;
    const formGroup = new FormGroup(
      {
        phoneCode: new FormControl(this.availableData.phoneCodes[0], [Validators.required]),
        phoneNumber: new FormControl(FRENCH_PHONE_CODE, [Validators.required, Validators.pattern(this.numbersRegexp)]),
        phoneType: new FormControl(null, [Validators.required]),
        equalityChecked: new FormControl(true, []),
      },
      {
        validators: phoneNumbersGroupValidator({
          minLength: this.minPhoneLength,
          maxLength: this.maxPhoneLength,
        }),
      }
    );
    phonesArray.push(formGroup);
  }

  public generatePhonesArray() {
    const phonesArray = [];
    const phonesControls = (this.form.get('phoneNumbers') as FormArray).controls;
    for (let i = 0; i < phonesControls.length; i++) {
      const formGroupValue = (phonesControls[i] as FormGroup).value;
      let phoneNumber = formGroupValue.phoneNumber.slice(formGroupValue.phoneCode.length);

      if (
        phoneNumber.length === 10 &&
        phoneNumber.slice(0, 1) === '0' &&
        formGroupValue.phoneCode === FRENCH_PHONE_CODE
      ) {
        phoneNumber = phoneNumber.slice(1, formGroupValue.phoneNumber.slice(formGroupValue.phoneCode.length));
      }

      const item = {
        phoneType: formGroupValue.phoneType,
        phoneNumber,
        phoneCode: formGroupValue.phoneCode,
        orderNumber: i + 1,
      };
      phonesArray.push(item);
    }
    return phonesArray;
  }

  public isMobileNumber(phoneNumber: string): boolean {
    return this.utilService.isMobileNumber(phoneNumber);
  }

  public isPhoneTypeAvailable(phoneNumber: string, phoneCode?: string): boolean {
    if (phoneCode !== FRENCH_PHONE_CODE) {
      return true;
    }

    if (phoneNumber && phoneNumber.length) {
      const phoneCodeLength = phoneCode?.length ?? 0;
      return (
        phoneNumber.length === this.maxPhoneLength + phoneCodeLength ||
        phoneNumber.length === this.minPhoneLength + phoneCodeLength
      );
    } else {
      return false;
    }
  }

  public generateCustomerData() {
    const idProp = 'id';
    const customerData = {
      name: this.form.get('firstName').value || null,
      lastName: this.form.get('lastName').value,
      emails: this.generateEmailsArray(),
      phones: this.generatePhonesArray(),
      civility: this.form.get('prefix').value,
      subscriberCode: this.form.get('code').value ? this.form.get('code').value : null,
      status: this.blockParams.status,
      blockReason: this.blockParams.reason,
      confirmationMode: this.form.get('confirmBy').value,
      smsNotificationEnabled: this.form.get('smsNotificationEnabled').value,
      emailNotificationEnabled: this.form.get('emailNotificationEnabled').value,
    };
    if (this.customerId) {
      customerData[idProp] = this.customerId;
    }

    return customerData;
  }

  public generateCustomerDataWithoutChange() {
    return {
      ...this.customer,
      blockReason: this.blockParams.reason,
      status: this.blockParams.status,
    };
  }

  public addNewEmail() {
    const control = new FormControl(null, []);
    (this.form.get('emails') as FormArray).push(control);
  }

  public deleteAdditionalEmail(index: any) {
    (this.form.get('emails') as FormArray).removeAt(index);
  }

  public isRadioButtonAvailable(): boolean {
    return this.form.get('autoConfirm').value;
  }

  public updateFormValue(): void {
    this.updateEmails();
    this.updateConfirmByResponse();
    this.updatePhoneNumbers();
    this.form.patchValue({
      firstName: this.customer.name,
      lastName: this.customer.lastName,
      prefix: this.customer.civility,
      status: this.customer.status,
      code: this.customer.subscriberCode,
      smsNotificationEnabled: this.customer.smsNotificationEnabled,
      emailNotificationEnabled: this.customer.emailNotificationEnabled,
    });
  }

  public clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  public loadCustomer(): void {
    this.phoneAdvisorService
      .getCustomerById(this.customerId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: ICustomer) => {
        this.disableEnableForm(response);
        this.checkCustomerStatus(response);
        this.checkIsDisabled(response);
        this.customer = response;
        this.sortCustomerPhones();
        this.sortCustomerEmails();
        this.updateFormValue();
      });
  }

  public checkCustomerStatus(customer: ICustomer) {
    if (customer?.status === 'ACTIVE') {
      return (this.customerStatus = {
        title: this.translate.instant('PA_CUSTOMERS_STATUS_ACTIVE'),
        value: 'ACTIVE',
      });
    }
    if (customer?.status === 'BLOCKED') {
      return (this.customerStatus = {
        title: this.translate.instant('PA_CUSTOMERS_STATUS_BLOCKED'),
        value: 'BLOCKED',
      });
    }
  }

  public goBack(): void {
    const prevRoute = this.localStorageService.getLocalStorageItem('PREV_PAGE');
    this.router.navigate([prevRoute]);
  }

  public setOrdersLoading(enabled: boolean): void {
    this.isOrdersLoading = enabled;
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  public getPhonesFormArray(): FormArray {
    return this.form.get('phoneNumbers') as FormArray;
  }

  public getEmailsFormArray() {
    return this.form.get('emails') as FormArray;
  }

  public getEmailValidators(): Validators[] {
    return [Validators.required, Validators.pattern(this.utilService.emailRegexp)];
  }

  private generateCustomerParams(page?: number): Partial<IGetPaOrdersParams> {
    return {
      customerId: this.customerId,
      from: 1,
      immediate: false,
      statuses: ['CREATE'],
      withDriverDetails: true,
      direction: DIRECTION.ASC,
      size: 5,
      page,
    };
  }

  public onBlurFormaFirstNameText(): void {
    this.firstNameControl.setValue(normalizeWhitespacesIn(this.firstNameControl.value));
  }

  public onBlurFormatLastNameText(): void {
    this.lastNameControl.setValue(normalizeWhitespacesIn(this.lastNameControl.value));
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      status: new UntypedFormControl('ACTIVE', []),
      prefix: new UntypedFormControl('', []),
      firstName: new UntypedFormControl('', [azzFrLettersAndNumsValidator]),
      lastName: new UntypedFormControl('', [Validators.required, azzFrLettersAndNumsValidator]),
      phoneNumbers: new UntypedFormArray([
        new UntypedFormGroup(
          {
            phoneCode: new UntypedFormControl('', []),
            phoneNumber: new UntypedFormControl(FRENCH_PHONE_CODE, [
              Validators.required,
              Validators.pattern(this.numbersRegexp),
            ]),
            phoneType: new UntypedFormControl(null, [Validators.required]),
            equalityChecked: new UntypedFormControl(true, []),
          },
          {
            validators: phoneNumbersGroupValidator({
              minLength: this.minPhoneLength,
              maxLength: this.maxPhoneLength,
            }),
          }
        ),
      ]),
      emails: new UntypedFormArray([new UntypedFormControl(null, [])]),
      code: new UntypedFormControl('', []),
      autoConfirm: new UntypedFormControl(false, []),
      confirmBy: new UntypedFormControl({ value: CONFIRM_MODE.NO_CONFIRMATION, disabled: true }, []),
      emailNotificationEnabled: new UntypedFormControl(false, []),
      smsNotificationEnabled: new UntypedFormControl(false, []),
    });

    this.phoneNumbersGroup = this.form.get('phoneNumbers');

    this.initPhoneControls();
  }

  private init(): void {
    if (!this.isCreateMode) {
      this.activatedRoute.params.subscribe((res: Params) => {
        this.customerId = res.customerId;
        this.loadCustomer();
        this.getCustomersOrders();
        this.getCustomerHistory();
      });
    }
    this.checkSmsEnabled();
    this.initPhoneNumberWatcher();
    this.isPhoneCallButtonAvailable$ = this.store$.pipe(select(phoneCallButtonSelector));
    this.isPhoneCallButtonAvailable$.subscribe(user => (this.phoneCallButtonSelectorOn = !!user));
  }

  private generateOrdersParams(page?: number): Partial<IGetPaOrdersParams> {
    return {
      customerId: this.customerId,
      from: 1,
      withDriverDetails: true,
      direction: DIRECTION.ASC,
      size: 5,
      page,
    };
  }

  private getOrdersCurrentPage(): number {
    return this.customerOrdersData ? this.customerOrdersData.number : 0;
  }

  private sortCustomerPhones(): void {
    this.customer?.phones?.sort(sortNumber('orderNumber'));
  }

  private sortCustomerEmails(): void {
    this.customer?.emails?.sort(sortNumber('orderNumber'));
  }

  private isEmailRequiredForConfirmation(): boolean {
    const confirmModeEmail = this.form.get('emailNotificationEnabled').value;
    const emailsControls = (this.form.get('emails') as FormArray).controls;
    return confirmModeEmail && emailsControls.every(control => !control.value);
  }

  private updatePhoneNumbers() {
    const phonesArray = this.form.get('phoneNumbers') as FormArray;
    this.clearFormArray(phonesArray);
    for (const item of this.customer.phones) {
      const formGroup = new FormGroup(
        {
          phoneCode: new FormControl(item.phoneCode, [Validators.required]),
          phoneNumber: new FormControl(`${item.phoneCode}${item.phoneNumber}`, [
            Validators.required,
            Validators.pattern(this.numbersRegexp),
          ]),
          phoneType: new FormControl(item.phoneType, [Validators.required]),
          equalityChecked: new FormControl(true, []),
        },
        {
          validators: phoneNumbersGroupValidator({
            minLength: this.minPhoneLength,
            maxLength: this.maxPhoneLength,
          }),
        }
      );
      if (this.isDisabled) {
        formGroup.disable();
      }
      phonesArray.push(formGroup);
    }
  }

  private setEqualityCheckedValue(control: AbstractControl, value: boolean): void {
    control.patchValue(value);
  }

  // User changes customer phone number
  private handlePhoneNumberChanging(data: { item: AbstractControl; index: number }): void {
    let countryCodeValue = this.phoneConfigCountryCodesData[data.index].phoneConfig?.selectedCountryData?.dialCode;
    const phoneNumberValue = data.item.get('phoneNumber').value;
    countryCodeValue = countryCodeValue && phoneNumberValue[0] === '+' ? `+${countryCodeValue}` : '';
    const phoneCodeControl = data.item.get('phoneCode');
    phoneCodeControl.patchValue(countryCodeValue);
    const phoneCodeValue = data.item.get('phoneCode').value;
    const phoneTypeControl = data.item.get('phoneType');

    if (!this.isPhoneTypeAvailable(phoneNumberValue, phoneCodeValue)) {
      phoneTypeControl.reset();
      this.checkAllPhoneNumbersExistenceExceptChanged(data);
      return;
    }

    if (this.isMobileNumber(phoneNumberValue)) {
      phoneTypeControl.setValue(PHONE_TYPE.MOBILE);
    } else {
      phoneTypeControl.setValue(PHONE_TYPE.LANDLINE);
    }

    this.checkExistenceOfChangedPhoneNumber(data);
    this.checkAllPhoneNumbersExistenceExceptChanged(data);
  }

  // Check existence of changed phone number only if it's valid (10 digits length)
  private checkExistenceOfChangedPhoneNumber(data: { item: AbstractControl; index: number }): void {
    this.setEqualityCheckedValue(data.item.get('equalityChecked'), false);
    const phoneNumberValue = data.item.get('phoneNumber').value;
    const phoneCodeValue = data.item.get('phoneCode').value;

    if (this.equalToLocal(data.item)) {
      return;
    }

    if (!this.isCreateMode && this.equalToOwnBackend(data.item)) {
      return;
    }

    if (phoneCodeValue !== FRENCH_PHONE_CODE) {
      this.setEqualityCheckedValue(data.item.get('equalityChecked'), true);
      return;
    }

    if (phoneCodeValue === FRENCH_PHONE_CODE && phoneNumberValue.includes(FRENCH_PHONE_CODE)) {
      this.customerService
        .checkPhoneNumberExist(phoneNumberValue, phoneCodeValue)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          () => {
            this.setEqualityCheckedValue(data.item.get('equalityChecked'), true);
            data.item.setErrors(null);
          },
          (errorResponse: HttpErrorResponse) => {
            this.handleCheckPhoneNumberError(errorResponse, data.item);
            return of(null);
          }
        );
    }
  }

  private handleCheckPhoneNumberError(errorResponse: HttpErrorResponse, control: AbstractControl): void {
    if (errorResponse.status === 400 && errorResponse.error.reason === 'customer.phone.number.exists') {
      control.setErrors({ phoneAlreadyExists: true });
    }
    this.changeDetectionRef.markForCheck();
  }

  // Equal to any other phone on the customer create/details page
  private equalToLocal(checkedItem: AbstractControl): boolean {
    let result = false;
    const allItems = (this.form.get('phoneNumbers') as FormArray).controls;
    for (const item of allItems) {
      if (checkedItem !== item) {
        if (checkedItem.get('phoneNumber').value === item.get('phoneNumber').value) {
          result = true;
          this.setEqualityCheckedValue(checkedItem.get('equalityChecked'), true);
          checkedItem.setErrors({ phoneAlreadyExists: true });
          break;
        }
      }
    }
    return result;
  }

  // Changed phone number equals to his own already existed on backend phone number
  private equalToOwnBackend(control: AbstractControl): boolean {
    let equality = false;
    const allPhones = this.customer.phones;
    for (const item of allPhones) {
      if (item.phoneNumber === control.value.phoneNumber) {
        equality = true;
        this.setEqualityCheckedValue(control.get('equalityChecked'), true);
        control.setErrors(null);
        break;
      }
    }
    return equality;
  }

  // Check valid phones numbers for equality with others except changed(omitted) phone
  private checkAllPhoneNumbersExistenceExceptChanged(omitted: { item: AbstractControl; index: number }) {
    const allItems = (this.form.get('phoneNumbers') as FormArray).controls;
    for (let index = 1; index < allItems.length; index++) {
      const item = allItems[index];
      if (omitted.item !== item) {
        const phoneNumberValue = item.value.phoneNumber;
        const phoneCodeValue = item.value.phoneCode;
        if (this.isPhoneTypeAvailable(phoneNumberValue, phoneCodeValue)) {
          this.checkPhoneNumberExistenceAfterChanging({ item, index }, { item: omitted.item, index: omitted.index });
        }
      }
    }
  }

  // data -> data about checked phone, omittedData -> data about recently changed (omitted in comparison) phone
  private checkPhoneNumberExistenceAfterChanging(
    data: { item: AbstractControl; index: number },
    omittedData: { item: AbstractControl; index: number }
  ): void {
    // this.setEqualityCheckedValue(data.item.get('equalityChecked'), false);
    const phoneNumberValue = data.item.get('phoneNumber').value;
    const phoneCodeValue = data.item.get('phoneCode').value;

    if (this.equalToLocalExceptOmitted(data, omittedData)) {
      return;
    }

    if (!this.isCreateMode) {
      if (this.equalToOwnBackend(data.item)) {
        return;
      }
    }

    if (phoneCodeValue !== FRENCH_PHONE_CODE) {
      this.setEqualityCheckedValue(data.item.get('equalityChecked'), true);
      return;
    }

    if (phoneCodeValue === FRENCH_PHONE_CODE && phoneNumberValue.includes(FRENCH_PHONE_CODE)) {
      this.customerService
        .checkPhoneNumberExist(phoneNumberValue, phoneCodeValue)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          () => {
            this.setEqualityCheckedValue(data.item.get('equalityChecked'), true);
            data.item.setErrors(null);
          },
          (errorResponse: HttpErrorResponse) => this.handleCheckPhoneNumberError(errorResponse, data.item)
        );
    }
  }

  // Compare from top to bottom. If there are some identical numbers, higher one would be valid, another - invalid
  // Get index of checked phone to compare it with phones with less index standing before checked phone
  private equalToLocalExceptOmitted(
    data: { item: AbstractControl; index: number },
    omittedData: { item: AbstractControl; index: number }
  ): boolean {
    let result = false;
    const checkedItem = data.item;
    const checkedItemIndex = data.index;
    const allItems = (this.form.get('phoneNumbers') as FormArray).controls;
    for (let index = 0; index <= checkedItemIndex; index++) {
      const item = allItems[index];
      if (item !== omittedData.item && item !== checkedItem) {
        if (checkedItem.get('phoneNumber').value === item.get('phoneNumber').value) {
          result = true;
          this.setEqualityCheckedValue(data.item.get('equalityChecked'), true);
          checkedItem.setErrors({ phoneAlreadyExists: true });
          break;
        }
      }
    }
    return result;
  }

  // Check all phone number after removing customer phone
  private checkAllPhoneNumbersExistenceAfterRemoving(): void {
    const allItems = (this.form.get('phoneNumbers') as FormArray).controls;
    for (let index = 1; index < allItems.length; index++) {
      const item = allItems[index];
      const phoneNumberValue = item.value.phoneNumber;
      const phoneCodeValue = item.value.phoneCode;
      if (this.isPhoneTypeAvailable(phoneNumberValue, phoneCodeValue)) {
        this.checkPhoneNumberExistenceAfterRemoving({ item, index });
      }
    }
  }

  private checkPhoneNumberExistenceAfterRemoving(data: { item: AbstractControl; index: number }): void {
    // this.setEqualityCheckedValue(data.item.get('equalityChecked'), false);
    const phoneNumberValue = data.item.get('phoneNumber').value;
    const phoneCodeValue = data.item.get('phoneCode').value;

    if (this.equalToLocalAfterRemoving(data)) {
      return;
    }

    if (!this.isCreateMode && this.equalToOwnBackend(data.item)) {
      return;
    }

    this.customerService
      .checkPhoneNumberExist(phoneNumberValue, phoneCodeValue)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.setEqualityCheckedValue(data.item.get('equalityChecked'), true);
          data.item.setErrors(null);
        },
        errorResponse => this.handleCheckPhoneNumberError(errorResponse, data.item)
      );
  }

  // Compare from top to bottom. If there are some identical numbers, higher one would be valid, another - invalid
  // What is equal to local? The naming is vague, try to rename it if your ticket related to this method
  private equalToLocalAfterRemoving(data: { item: AbstractControl; index: number }): boolean {
    let result = false;
    const checkedItem = data.item;
    const checkedItemIndex = data.index;
    const allItems = (this.form.get('phoneNumbers') as FormArray).controls;
    for (let index = 0; index < checkedItemIndex; index++) {
      const item = allItems[index];
      if (checkedItem.get('phoneNumber').value === item.get('phoneNumber').value) {
        result = true;
        this.setEqualityCheckedValue(data.item.get('equalityChecked'), true);
        checkedItem.setErrors({ phoneAlreadyExists: true });
        break;
      }
    }
    return result;
  }

  private updateConfirmByResponse() {
    if (this.customer.confirmationMode === CONFIRM_MODE.NO_CONFIRMATION) {
      return;
    }

    this.form.get('autoConfirm').setValue(true);
  }

  private updateEmails() {
    const emailsArray = this.form.get('emails') as FormArray;
    this.clearFormArray(emailsArray);
    if (!this.customer.emails?.length) {
      emailsArray.push(new FormControl({ value: null, disabled: this.isDisabled }));
      return;
    }
    for (const item of this.customer.emails) {
      emailsArray.push(new FormControl({ value: item.email, disabled: this.isDisabled }));
    }
  }

  private initPhoneTypes(): NamedOption<PhoneTypeFieldTitle>[] {
    const mobile: NamedOption<PhoneTypeFieldTitle> = {
      name: this.translate.instant('PA_CUSTOMERS_MOBILE_PHONE'),
      value: 'MOBILE',
    };

    const landline: NamedOption<PhoneTypeFieldTitle> = {
      name: this.translate.instant('PA_CUSTOMERS_LANDLINE_PHONE'),
      value: 'LANDLINE',
    };

    return [mobile, landline];
  }

  private initPrefixes(): NamedOption<'MR' | 'MRS'>[] {
    return [
      {
        name: this.translate.instant('PREFIX_SHORT_MR'),
        value: 'MR',
      },
      {
        name: this.translate.instant('PREFIX_SHORT_MRS'),
        value: 'MRS',
      },
    ];
  }

  private initRadioButtons(): TitledOption<ConfirmModeTitle>[] {
    const phone: TitledOption<ConfirmModeTitle> = {
      title: this.translate.instant('PA_CUSTOMERS_CONFIRM_PHONE'),
      value: 'PHONE',
    };
    const sms: TitledOption<ConfirmModeTitle> = {
      title: this.translate.instant('PA_CUSTOMERS_CONFIRM_SMS'),
      value: 'SMS',
    };
    const email: TitledOption<ConfirmModeTitle> = {
      title: this.translate.instant('PA_CUSTOMERS_CONFIRM_EMAIL'),
      value: 'EMAIL',
    };
    return [phone, sms, email];
  }

  private connectAutoConfirmWithConfirmByControl() {
    this.form.get('autoConfirm').valueChanges.subscribe((isAutoConfirm: boolean) => {
      if (isAutoConfirm) {
        this.form.get('confirmBy').enable();
        this.form
          .get('confirmBy')
          .setValue(this.customer?.confirmationMode ? this.customer.confirmationMode : CONFIRM_MODE.PHONE);
      } else {
        this.form.get('confirmBy').disable();
        this.form.get('confirmBy').setValue(CONFIRM_MODE.NO_CONFIRMATION);
      }
    });
  }
}
