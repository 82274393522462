/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { inject, Injectable } from '@angular/core';
import {
  DriverSessionStatusType,
  ORDER_STATUS,
  OrderStatusType,
  PACompetencyLevelName,
  RoleName,
} from '@core/constant';
import { IDriverFullSession } from '@core/models/driver-session.model';
import { IPhoneAdvisorUserInfo } from '@core/models/user-info.model';
import { UserService } from '@core/services/users';

@Injectable({
  providedIn: 'any',
})
export class CanUserFreeDriverDuringSessionService {
  private readonly userService = inject(UserService);

  private readonly conditionsToFreeDriver: {
    roles: RoleName[];
    driverSessionStatus: DriverSessionStatusType[];
    orderStatus: OrderStatusType[];
    competenceLevel: {
      PhoneAdvisor: PACompetencyLevelName[];
    };
  } = {
    roles: ['ROLE_FLEET_OWNER', 'ROLE_FLEET_ADMIN', 'ROLE_PHONE_ADVISOR', 'ROLE_DISPATCHER'],
    driverSessionStatus: ['BUSY', 'UNREACHABLE', 'OCCUPIED', 'FREE', 'CONNECTED', 'IN_QUEUE'],
    orderStatus: [
      ORDER_STATUS.FINISHED,
      ORDER_STATUS.CANCELLED,
      ORDER_STATUS.CANCELLED_AUTO,
      ORDER_STATUS.CANCELLED_BY_CUSTOMER,
      ORDER_STATUS.CANCELLED_BY_DRIVER,
      ORDER_STATUS.CANCELLED_BY_PHONE_ADVISOR,
      ORDER_STATUS.APPROACH,
      ORDER_STATUS.CONFIRMED,
      ORDER_STATUS.AT_DEPARTURE_ADDRESS,
      ORDER_STATUS.TOWARDS_DESTINATION,
    ],
    competenceLevel: {
      PhoneAdvisor: ['EXPERT'],
    },
  };

  byOrderStatus(status: OrderStatusType): boolean {
    return this.conditionsToFreeDriver.orderStatus.includes(status);
  }

  bySession(session: IDriverFullSession): boolean {
    const sessionHasOrder = session?.currentOrderId !== null;
    return sessionHasOrder && this.conditionsToFreeDriver.driverSessionStatus.includes(session.status);
  }

  byUserRole(role: RoleName): boolean {
    if (role === 'ROLE_PHONE_ADVISOR') {
      const PhoneAdvisorCompetencyLevel =
        this.userService.getCurrentUserInfo<IPhoneAdvisorUserInfo>()?.user?.competenceLevel;
      return this.conditionsToFreeDriver.competenceLevel.PhoneAdvisor.includes(PhoneAdvisorCompetencyLevel);
    }

    return this.conditionsToFreeDriver.roles.includes(role);
  }
}
