import dayjs from 'dayjs';

import { DispatchBeforeConstraintParams } from './dispatch-order.interfaces';

/** date limit @after which NONE of the order' fields can be modified  */
export const getAllFieldsEditableTimeLimit = ({
  dispatchBeforeMinutes: dispatchBefore,
  orderDateRaw,
}: DispatchBeforeConstraintParams): dayjs.Dayjs => {
  const orderDate = dayjs(orderDateRaw);
  return orderDate.subtract(dispatchBefore, 'minutes');
};
