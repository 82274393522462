<div class="modal-notification popup">
  <p>{{ 'MASS_RETURN_STATUS_ERROR' | translate }}:</p>
  <ul class="popup__list">
    <li *ngFor="let order of orders; let index = index">
      {{ index + 1 }}.{{ 'REFERENCE_SHORT' | translate }}: {{ order.tripNumber }} ({{
        'GO_ORDER_STATUS_' + order?.processingStatus | translate
      }})
    </li>
  </ul>
  <div class="modal-notification__btn-group">
    <button type="button" class="custom-btn custom-btn--gray" (click)="close()">
      {{ 'CORE_OK_VALUE' | translate }}
    </button>
  </div>
</div>
