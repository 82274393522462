import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AzzRotateIndicatorModule } from '@common/components/rotate-indicator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AzzButtonDropdownComponent } from './button-dropdown.component';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, AzzRotateIndicatorModule],
  declarations: [AzzButtonDropdownComponent],
  exports: [AzzButtonDropdownComponent],
})
export class AzzButtonDropdownModule {}
