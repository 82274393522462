import { Directive, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Directive()
export class DialogDirective {
  @Output() azzClose = new EventEmitter();
  @Output() azzConfirm = new EventEmitter();
  public header: string | null;
  public message: string | null;
  public closeValue: string;
  public dismissValue: string;
  public modalClass: string;
  public retrievedValue: any;
  public fleetMessage = '';
  public dialogContent: any;

  constructor(protected dialogService: NgbModal) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public showDialog(header: string | null, message: string | null, closeValue?: string, dismissValue?: string) {
    this.header = header;
    this.message = message;
    this.dialogService
      .open(this.dialogContent, {
        windowClass: this.modalClass,
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        result => {},
        () => {}
      );
  }

  public showDialogWithValueRetrieving(header: string, message: string, value?: any) {
    this.header = header;
    this.message = message;
    this.retrievedValue = value;
    this.dialogService
      .open(this.dialogContent, {
        windowClass: this.modalClass,
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        result => {},
        () => {}
      );
  }

  public onClose() {
    if (this.retrievedValue) {
      this.azzClose.emit({ value: this.retrievedValue });
    }
    this.dialogService.dismissAll();
  }

  public onSuccess() {
    if (this.retrievedValue && this.fleetMessage) {
      this.azzConfirm.emit({ value: this.retrievedValue, message: this.fleetMessage });
      this.retrievedValue = null;
      this.fleetMessage = '';
    } else if (this.retrievedValue) {
      this.azzConfirm.emit({ value: this.retrievedValue });
      this.retrievedValue = null;
    } else {
      this.azzConfirm.emit();
    }
    this.dialogService.dismissAll();
  }

  public setContent(content: any) {
    this.dialogContent = content;
  }

  public getFleetMessage(event: any): void {
    this.fleetMessage = event.target.value;
  }
}
