import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { G7Order } from '../../../../models/g7-order.model';

@Component({
  selector: 'azz-g7-multi-validation-error-popup',
  templateUrl: './multi-validation-error-popup.component.html',
  styleUrls: ['./multi-validation-error-popup.component.less'],
})
export class G7MultiValidationErrorPopupComponent {
  @Input() orders: G7Order[];

  constructor(private readonly modal: NgbActiveModal) {}

  public close(): void {
    this.modal.close();
  }
}
