/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderPassengerItemResponseWebApiModel } from './order-passenger-item-response-web.api-model';

/**
 * object containing passengers data in order detail response
 */
export interface OrderPassengersResponseWebApiModel {
  /**
   * true, if a passenger is anonymous
   */
  anonymous: boolean;
  /**
   * true, if a customer is different with passenger
   */
  differentWithClient: boolean;
  /**
   * list of passengers specified in order
   */
  passengers?: Array<OrderPassengerItemResponseWebApiModel>;
}
