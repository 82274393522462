<div class="azz-header">{{ 'INFORMATION_VEHICLE' | translate }}</div>
<div>
  <span class="azz-transparent-input-label">{{ 'MAKE' | translate }}: </span>
  {{ vehicle?.make?.name }} {{ vehicle?.model?.name }} {{ vehicle?.color?.name }}
</div>
<div>
  <span>
    <span class="azz-transparent-input-label">{{ 'FD_PLATE_LABEL' | translate }}:</span>
    {{ vehicle?.plate }}
  </span>
  <span *ngIf="driver?.parameters?.taxiNumber">
    / <span class="azz-transparent-input-label">{{ 'TAXI_NUMBER' | translate }}:</span>
    {{ driver?.parameters?.taxiNumber }}
  </span>
</div>
