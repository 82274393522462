/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * coordinates represented as latitude + longitude
 */
export interface PointResponseWebApiModel {
  /**
   * latitude
   */
  lat: number;
  /**
   * longitude
   */
  lon: number;
}
