import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DialogDirective } from '../../../../common/components/dialog/dialog.component';

@Component({
  selector: 'azz-delete-point-of-interest-dialog',
  templateUrl: './delete-point-of-interest-dialog.component.html',
  styleUrls: ['./delete-point-of-interest-dialog.component.less'],
})
export class DeletePointOfInterestDialogComponent extends DialogDirective implements OnInit {
  @ViewChild('content') public declare dialogContent: any;
  public loadingIndicator = false;
  public modalReference: any;

  constructor(dialogService: NgbModal) {
    super(dialogService);
  }

  ngOnInit() {
    this.setContent(this.dialogContent);
  }

  public override showDialogWithValueRetrieving(header: string, message: string, value?: any) {
    this.header = header;
    this.message = message;
    this.retrievedValue = value;
    this.modalReference = this.dialogService.open(this.dialogContent, {
      windowClass: this.modalClass,
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  public override onSuccess() {
    this.loadingIndicator = true;
    this.azzConfirm.emit({ value: this.retrievedValue });
  }

  public closeAfterSuccess(): void {
    this.loadingIndicator = false;
    this.retrievedValue = null;
    this.modalReference.close();
  }

  public closeAfterFailure(): void {
    this.loadingIndicator = false;
    this.retrievedValue = null;
    this.modalReference.close();
  }
}
