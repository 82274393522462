import { CommonModule } from '@angular/common';
import { Directive, ElementRef, NgModule, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, pluck, takeUntil } from 'rxjs/operators';

import { DriverService } from '../../core/services/users/driver.service';

@Directive({
  selector: '[azzPasswordSecurity]',
})
export class PasswordSecurityDirective implements OnInit, OnDestroy {
  public LEVEL_CLASS_RED = 'warning-red';
  public LEVEL_CLASS_ORANGE = 'warning-orange';
  public LEVEL_CLASS_YELLOW = 'warning-yellow';
  public LEVEL_CLASS_GREEN = 'warning-green';
  private readonly unsubscribe$ = new Subject<void>();
  private readonly DEBOUNCE_TIME = 500;

  constructor(
    private readonly el: ElementRef,
    private readonly driverService: DriverService
  ) {}

  ngOnInit() {
    fromEvent(this.el.nativeElement, 'input')
      .pipe(
        takeUntil(this.unsubscribe$),
        pluck('target', 'value'),
        debounceTime(this.DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe(newPassword => {
        this.watcherFun(this.el.nativeElement, newPassword);
      });
  }

  public watcherFun(input, newPassword): void {
    const parentFormGroup = input.parentNode;
    if (parentFormGroup) {
      parentFormGroup.classList.remove(this.LEVEL_CLASS_RED);
      parentFormGroup.classList.remove(this.LEVEL_CLASS_ORANGE);
      parentFormGroup.classList.remove(this.LEVEL_CLASS_YELLOW);
      parentFormGroup.classList.remove(this.LEVEL_CLASS_GREEN);

      if (newPassword) {
        const levelClass = this.getPasswordLevelClass(newPassword);
        this.validate(levelClass);

        if (levelClass) {
          parentFormGroup.classList.add(levelClass);
        }
      } else {
        // Temporary to delete ng-invalid class from input
        this.validate(true);
      }
    }
  }

  public validate(levelClass): void {
    this.driverService.passwordValidate.next(levelClass !== this.LEVEL_CLASS_RED);
    // this.driverService.passwordValidate.next(levelClass == this.LEVEL_CLASS_RED); May be this variant is correct???
    // ngModel.$setValidity('invalidPassword', levelClass !== this.LEVEL_CLASS_RED);
  }

  public getPasswordLevelClass(password: string): string | null {
    if (this.lessThanMinLength(password)) {
      return this.LEVEL_CLASS_RED;
    }
    if (this.onlyLowercaseOrNumbers(password)) {
      return this.LEVEL_CLASS_ORANGE;
    }
    if (this.onlyLowercaseAndNumbers(password)) {
      return this.LEVEL_CLASS_YELLOW;
    }
    if (this.allCaseAndNumbers(password)) {
      return this.LEVEL_CLASS_GREEN;
    }
    return null;
  }

  public lessThanMinLength(password: string): boolean {
    return password.length < 6;
  }

  public onlyLowercaseOrNumbers(password: string): boolean {
    const ONLY_LOWERCASE_PATTERN = /^[ a-z]{6,}$/;
    const ONLY_NUMBERS_PATTERN = /^[0-9]{6,}$/;

    return ONLY_LOWERCASE_PATTERN.test(password) || ONLY_NUMBERS_PATTERN.test(password);
  }

  public onlyLowercaseAndNumbers(password: string): boolean {
    const ONLY_LOWERCASE_AND_NUMBERS_PATTERN = /^[ a-z0-9]{6,}$/;

    return ONLY_LOWERCASE_AND_NUMBERS_PATTERN.test(password);
  }

  public allCaseAndNumbers(password: string): boolean {
    const ALL_CASE_AND_NUMBERS_PATTERN = /^[ a-zA-Z0-9]{6,}$/;

    return ALL_CASE_AND_NUMBERS_PATTERN.test(password);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [PasswordSecurityDirective],
  exports: [PasswordSecurityDirective],
})
export class PasswordSecurityModule {}
