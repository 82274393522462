import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogDirective } from '@common/components/dialog/dialog.component';
import { ORDER, OrderType } from '@core/constant';
import { ICustomer, INewCustomer } from '@core/models/customer.model';
import { ITag } from '@core/models/tag.model';
import { helpers } from '@core/modules/nouvelle-commande/components/date-time/date/helpers';
import {
  AssignmentDriverOrderStatus,
  DriverAssignmentInfo,
} from '@core/modules/nouvelle-commande/components/driver-assignment/driver-assignment-info';
import { DriverAssignmentService } from '@core/modules/nouvelle-commande/components/driver-assignment/driver-assignment.service';
import { HereApi, UIAddress } from '@core/modules/nouvelle-commande/interfaces';
import { OSRMService } from '@core/services/common';
import { SessionService } from '@core/services/sessions';
import { sortAttributeTags } from '@dash/modules/phone-advisor/common/tags-sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, tap } from 'rxjs/operators';

import { defineOrderType } from './order-type';
import { PreSaveConfirmDialogComponent } from './pre-save-modal/pre-save-modal.component';
import { OrderForm } from '../../types/order-form.type';

moment.locale('fr');

export type TDistanceShowType = 'show' | 'hide-nothing-exists' | 'hide-something-exists';

@Component({
  selector: 'azz-order-pre-save-dialog',
  templateUrl: './order-pre-save.dialog.component.html',
  styleUrls: ['./order-pre-save-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPreSaveDialogComponent extends DialogDirective implements OnInit, OnChanges {
  private readonly driverAssignmentService = inject(DriverAssignmentService);
  private readonly sessionService = inject(SessionService);
  private readonly osrmService = inject(OSRMService);
  public readonly immediateOrder = ORDER.ORDER;
  public readonly inAdvanceOrder = ORDER.IN_ADVANCE;

  @ViewChild(PreSaveConfirmDialogComponent) customModalNotification: PreSaveConfirmDialogComponent;
  @ViewChild('content') public declare dialogContent: ElementRef;
  @Input() form: FormGroup<OrderForm>;
  @Input() isLoading: boolean;
  @Input() azzDisabled: boolean;
  @Input() public distance: number;
  @Input() duration: number;
  @Input() addresses: { appointmentAddress?: any; destinationAddress?: any };
  @Input() customer: ICustomer;
  @Input() newCustomer: INewCustomer;
  @Input() estimatedPrice: number;
  @Input() isEstimatedPriceLoading: boolean;
  @Input() isEstimatedPriceShown: boolean;
  @Input() tags: ITag[];
  @Input() public declare closeValue: string;
  @Input() distanceETA: number;
  @Input() durationETA: number;
  @Input() isEtaLoading: boolean;
  @Input() public distanceShowType: TDistanceShowType;

  @Input() set orderTimeInTimeFormat(value: moment.Moment) {
    this.dispatchDelayInFrench = value;
    this.formatTime(value);
  }

  get orderTimeInTimeFormat(): moment.Moment {
    return this.dispatchDelayInFrench;
  }

  @Input() set departureAddress(address: UIAddress) {
    this.departureAddress$.next(address);
  }

  @Input() set orderTime(value: string) {
    this.orderTimeInFrench = value;
    this.formatTime(value);
  }

  get orderTime(): string {
    return this.orderTimeInFrench;
  }

  @Input()
  set orderDate(value: string) {
    this.orderDateInFrench = value;
    this.formatDate();
  }

  get orderDate(): string {
    return this.orderDateInFrench;
  }

  @Output() override azzClose = new EventEmitter();

  public formattedDate: string;
  public formattedTime: string;
  public formattedDispatchDelayTime: string;

  private orderDateInFrench: string;
  private orderTimeInFrench: string;
  private dispatchDelayInFrench: moment.Moment;

  public orderType: OrderType;
  public isSameAddress = false;
  public sortTags: string[] = ['PA', 'AN', 'HO', 'HT', 'TDE', 'AV', 'RA', 'RL', 'DEM', 'VB', 'LO', 'GC', 'PB', 'TP'];

  protected selectedDriver$: Observable<DriverAssignmentInfo>;
  protected driverPickupEstimation$: Observable<HereApi>;
  protected isLoading$ = new BehaviorSubject<boolean>(false);
  private readonly departureAddress$ = new ReplaySubject<UIAddress>(1);

  constructor(dialogService: NgbModal) {
    super(dialogService);
  }

  public formatDate(): void {
    this.formattedDate = 'Le ' + helpers.convertDateStrToMoment(this.orderDateInFrench).format('dddd D MMMM');
  }

  public formatTime(time: string | moment.Moment): void {
    if (typeof time === 'string') {
      const [hours, minutes] = time.split(':');
      this.formattedTime = 'à ' + hours + 'h' + minutes;
    } else if (time instanceof moment) {
      const hours = moment(time).hours();
      const minutes = time.minutes().toString().padStart(2, '0');
      this.formattedDispatchDelayTime = 'à ' + hours + 'h' + minutes;
    }
  }

  ngOnInit() {
    this.setContent(this.dialogContent);

    this.selectedDriver$ = combineLatest([
      this.driverAssignmentService.drivers$,
      this.form.controls.driver.valueChanges,
    ]).pipe(
      map(([drivers, driverId]) => {
        return drivers.find(driver => driver.id === driverId);
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.driverPickupEstimation$ = this.selectedDriver$.pipe(
      distinctUntilChanged(isEqual),
      filter(Boolean),
      filter(
        driver =>
          driver.statuses?.[0] === AssignmentDriverOrderStatus.FREE ||
          driver.statuses?.[0] === AssignmentDriverOrderStatus.CONNECTED
      ),
      mergeMap(driver => this.sessionService.getActiveDriverSessionById(driver.id)),
      mergeMap(session =>
        this.departureAddress$.pipe(
          filter(Boolean),
          distinctUntilChanged(isEqual),
          map(address => (address.poi ? address.poi.coordinates : address.coordinates)),
          filter(Boolean),
          tap(() => this.isLoading$.next(true)),
          mergeMap(coordinates => {
            const coordsString = new HttpParams()
              .set('pickupLat', session.location.latitude)
              .set('pickupLon', session.location.longitude)
              .set('dropoffLat', coordinates.latitude)
              .set('dropoffLon', coordinates.longitude);

            return this.osrmService.getFastestRoute(coordsString);
          }),
          tap(() => this.isLoading$.next(false))
        )
      )
    );
  }

  ngOnChanges(): void {
    this.orderType = defineOrderType({ time: this.orderTime, date: this.orderDate });
    this.tags = sortAttributeTags(this.tags, this.sortTags);

    if (this.addresses) {
      if (isEqual(this.addresses?.appointmentAddress, this.addresses?.destinationAddress)) {
        this.isSameAddress = true;
        return;
      }
      this.isSameAddress = false;
    }
  }

  public onCancel() {
    this.customModalNotification.showDialog('PRE_SAVE_MODAL_HEADER', 'PRE_SAVE_MODAL_BODY');
  }

  public resetFields() {
    this.addresses = null;
    this.azzClose.emit();
  }

  public isSameDates(date1: string | moment.Moment | Date, date2: string | moment.Moment | Date): boolean {
    const d1 = moment.isMoment(date1)
      ? date1.startOf('day').valueOf()
      : helpers.convertDateStrToMoment(date1 as string).valueOf();
    const d2 = moment.isMoment(date2)
      ? date2.startOf('day').valueOf()
      : helpers.convertDateStrToMoment(date2 as string).valueOf();
    return d1 === d2;
  }

  protected readonly statusEnum = AssignmentDriverOrderStatus;
}
