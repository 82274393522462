import { inject, Injectable } from '@angular/core';
import { IToastData } from '@common/models/IToastData';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private readonly messageService = inject(MessageService);
  private readonly translateService = inject(TranslateService);

  public readonly key = 'global-notification';

  public success(message: string, data?: IToastData): void {
    this.showAlert(message, 'success', data);
  }

  public warn(message: string, data?: IToastData): void {
    this.showAlert(message, 'warn', data);
  }

  public error(message: string, data?: IToastData): void {
    this.showAlert(message, 'error', data);
  }

  private showAlert(message: string, severity: 'success' | 'error' | 'warn', data?: IToastData): void {
    const detail = this.translateService.instant(message);

    this.messageService.add({
      key: this.key,
      severity,
      detail,
      life: 10000, // 10s
      data,
    });
  }
}
