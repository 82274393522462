<form class="card azz-multiple customer-typeahead" #formElemRef [formGroup]="form">
  <ng-template #resultTemplate let-result="result">
    <div *ngIf="result">
      <div *ngIf="result?.name; else lastNameOnly">
        <ngb-highlight [result]="result?.name + ', ' + result?.lastName" [term]="hightlightTerm"></ngb-highlight>
      </div>
      <ng-template #lastNameOnly>
        <ngb-highlight [result]="result?.lastName" [term]="hightlightTerm"></ngb-highlight>
      </ng-template>

      <div *ngIf="result?.subscriberCode">
        <span class="customer-typeahead__label">{{ 'SUBSCRIBER_CODE' | translate }}:</span>
        <ngb-highlight [result]="result?.subscriberCode" [term]="hightlightTerm"></ngb-highlight>
      </div>
      <div>
        <span class="customer-typeahead__label">{{ 'PA_CREATE_NEW_PHONE_NUMBER' | translate }}:</span>
        <ngb-highlight
          [result]="
            result?.phones && result?.phones[0] ? result?.phones[0].phoneCode + result?.phones[0].phoneNumber : ''
          "
          [term]="hightlightTerm"
        ></ngb-highlight>
      </div>
    </div>
    <div *ngIf="!result">{{ 'CLIENT_NOT_FOUND' | translate }}</div>
  </ng-template>
  <div class="d-flex align-items-center w-100">
    <input
      type="text"
      class="form-control azz-transparent-input azz-validate azz-search-field"
      name="searchField"
      formControlName="searchField"
      [disabled]="isDisabled"
      [tabindex]="NgTabindex"
      [id]="ngId"
      [ngbTypeahead]="search"
      [resultTemplate]="resultTemplate"
      [inputFormatter]="formatter"
      (selectItem)="onCustomerSelect($event)"
      (blur)="onBlur()"
      (keydown.enter)="onEnterClick()"
    />
    <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
  </div>
  <div class="form-control__error" *ngIf="customer?.status === 'BLOCKED'">
    <small
      style="font-size: 0.83em"
      [innerHTML]="
        'CUSTOMER_BLOCKED_EXPLANTION_SUMMARY'
          | translate: { blockTime: customerBlockTime, blockReason: customerBlockReason }
      "
    >
    </small>
  </div>
  <div class="form-control__error" *ngIf="searchFieldControl?.touched && searchFieldControl.errors">
    <small *ngIf="searchFieldControl.errors?.noSelectedCustomer">
      {{ 'PA_CHOOSE_CUSTOMER_ERROR' | translate }}
    </small>
  </div>
</form>
