export const ROLE = {
  UNAUTHORIZED: 'ROLE_UNAUTHORIZED',
  ADMIN: 'ROLE_ADMIN',
  DRIVER: 'ROLE_DRIVER',
  FLEET_OWNER: 'ROLE_FLEET_OWNER',
  FLEET_ADMIN: 'ROLE_FLEET_ADMIN',
  DRIVER_INACTIVE: 'ROLE_DRIVER_INACTIVE',
  DRIVER_INCOMPLETE: 'ROLE_DRIVER_INCOMPLETE',
  FLEET_OWNER_INACTIVE: 'ROLE_FLEET_OWNER_INACTIVE',
  FLEET_ADMIN_INACTIVE: 'ROLE_FLEET_ADMIN_INACTIVE',
  FLEET_ADMIN_DEACTIVATED: 'ROLE_FLEET_ADMIN_DEACTIVATED',
  FLEET_OWNER_INCOMPLETE: 'ROLE_FLEET_OWNER_INCOMPLETE',
  G7: 'ROLE_G7_BILLING_USER',
  G7_INCOMPLETE: 'ROLE_G7_INCOMPLETE',
  G7_INACTIVE: 'ROLE_G7_BILLING_USER_INACTIVE',
  PHONE_ADVISOR: 'ROLE_PHONE_ADVISOR',
  PHONE_ADVISOR_INACTIVE: 'ROLE_PHONE_ADVISOR_INACTIVE',
  PHONE_ADVISOR_DEACTIVATED: 'ROLE_PHONE_ADVISOR_DEACTIVATED',
  ADMIN_DEACTIVATED: 'ROLE_ADMIN_DEACTIVATED',
  DISPATCHER: 'ROLE_DISPATCHER',
  DISPATCHER_INACTIVE: 'ROLE_DISPATCHER_INACTIVE',
  DISPATCHER_INCOMPLETE: 'ROLE_DISPATCHER_INCOMPLETE',
  DISPATCHER_DEACTIVATED: 'ROLE_DISPATCHER_DEACTIVATED',
} as const;

export type RoleName = (typeof ROLE)[keyof typeof ROLE];

export const RoleList: RoleName[] = [
  'ROLE_ADMIN',
  'ROLE_ADMIN_DEACTIVATED',
  'ROLE_DISPATCHER',
  'ROLE_DISPATCHER_DEACTIVATED',
  'ROLE_DISPATCHER_INACTIVE',
  'ROLE_DISPATCHER_INCOMPLETE',
  'ROLE_DRIVER',
  'ROLE_DRIVER_INACTIVE',
  'ROLE_DRIVER_INCOMPLETE',
  'ROLE_FLEET_ADMIN',
  'ROLE_FLEET_ADMIN_DEACTIVATED',
  'ROLE_FLEET_ADMIN_INACTIVE',
  'ROLE_FLEET_OWNER',
  'ROLE_FLEET_OWNER_INACTIVE',
  'ROLE_FLEET_OWNER_INCOMPLETE',
  'ROLE_G7_BILLING_USER',
  'ROLE_G7_BILLING_USER_INACTIVE',
  'ROLE_G7_INCOMPLETE',
  'ROLE_PHONE_ADVISOR',
  'ROLE_PHONE_ADVISOR_DEACTIVATED',
  'ROLE_PHONE_ADVISOR_INACTIVE',
  'ROLE_UNAUTHORIZED',
];

export enum UserRole {
  billingUser = 'billingUser',
  dispatcher = 'dispatcher',
  driver = 'driver',
  fleet = 'fleet',
  phoneAdvisor = 'phoneAdvisor',
  super = 'super',
}

export const LOGIN_STATUS = {
  SIGNEDIN: 'SIGNEDIN',
  CRC_DISABLED: 'CRCDISABLED',
  PHONE_ADVISOR_DEACTIVATED: 'PHONE_ADVISOR_DEACTIVATED',
  WEB_RTC_NOT_SUPPORTED: 'WEB_RTC_NOT_SUPPORTED',
  NOT_ASSIGNED_AGENT: 'NOT_ASSIGNED_AGENT',
  ADMIN_DEACTIVATED: 'ADMIN_DEACTIVATED',
  ERROR: 'ERROR',
} as const;

export type LoginStatusName = (typeof LOGIN_STATUS)[keyof typeof LOGIN_STATUS];
