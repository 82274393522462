<form class="card autocomplete" [formGroup]="form" #formElemRef>
  <ng-template #rt let-r="result" let-t="term">
    <ngb-highlight *ngIf="r" [result]="r" [term]="t"></ngb-highlight>
    <div *ngIf="!r">{{ 'CITY_NOT_FOUND' | translate }}</div>
  </ng-template>
  <div class="d-flex align-items-center">
    <input
      emitOnEvent
      sourceEvent="blur"
      (triggerAction$)="enableLoadingIndicator(false)"
      [id]="ngId"
      type="text"
      formControlName="city"
      [ngbTypeahead]="search"
      (selectItem)="onCitySelect($event)"
      [resultTemplate]="rt"
      class="form-control azz-transparent-input azz-validate"
    />
    <azz-rotate-indicator7 [visible]="loadingIndicator"></azz-rotate-indicator7>
  </div>
  <div *ngIf="form.controls.city.touched && form.controls.city.errors" class="autocomplete__error">
    <small *ngIf="form.controls.city.errors?.noCityFound">{{ 'CAN_NOT_IDENTIFY_POI_CITY' | translate }}</small>
    <small *ngIf="form.controls.city.errors?.noSelectedCity && !form.controls.city.errors?.noCityFound">
      {{ 'CHOOSE_POI_CITY_FROM_AUTOCOMPLETE' | translate }}
    </small>
  </div>
</form>
