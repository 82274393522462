/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * customer civility enum
 */
export type CustomerCivilityEnumWebApiModel = 'MR' | 'MRS';

export const CustomerCivilityEnumWebApiModel = {
  Mr: 'MR' as CustomerCivilityEnumWebApiModel,
  Mrs: 'MRS' as CustomerCivilityEnumWebApiModel,
};
