<div class="azz-details-page azz-driver-details pragmatic details azz-info-blocks-row details">
  <div class="azz-info-block">
    <azz-loading-block [isLoading]="driverLoading$ | async">
      <ng-container *ngIf="driver">
        <div class="azz-nav-block">
          <img src="assets/images/return-arrow.png" class="azz-back-btn" (click)="goBack()" alt="go back" />
          <div class="azz-back-title">{{ 'GO_BACK' | translate }}</div>
        </div>

        <!--Avatar-->
        <div class="details__avatar">
          <app-driver-details-avatar [driver]="driver"></app-driver-details-avatar>
        </div>

        <!--Blocked driver-->
        <div class="details__warning" *ngIf="isDriverBlocked">
          <app-driver-details-blocked-driver
            [driver]="driver"
            [ngDisabled]="disabled"
            (openUnblockPopup$)="openUnblockDriverPopup()"
          >
          </app-driver-details-blocked-driver>
        </div>

        <!-- For driver with zero rating -->
        <div class="details__warning" *ngIf="driver?.rating === 0">
          <div>{{ 'FD_DRIVER_NOT_AUTHORIZED_MSG' | translate }}</div>
          <div>{{ 'FD_DRIVER_TO_AUTHORIZE_MSG' | translate }}</div>
        </div>

        <!-- For not validated user -->
        <div class="details__not-validated">
          <app-driver-details-not-validated
            *ngIf="!driver.approved && !disabled"
            [driver]="driver"
            [transportLicense]="transportLicense"
            [driverLicense]="driverLicense"
            [btnLoading]="validateBtnLoading$ | async"
            (openValidatePopup$)="openValidateDriverPopup()"
          >
          </app-driver-details-not-validated>
        </div>

        <!--New transport license for approved driver-->
        <div class="details__warning" *ngIf="newTransportLicense && driver?.approved">
          <app-driver-details-new-transport-license
            [newTransportLicense]="newTransportLicense"
            [confirmBtnLoading]="confirmLicenseBtnLoading$ | async"
            [rejectBtnLoading]="rejectLicenseBtnLoading$ | async"
            [ngDisabled]="disabled"
            (openApproveDriverLicensePopup$)="openConfirmTransportLicensePopup()"
            (openRejectDriverLicensePopup$)="openRejectTransportLicensePopup()"
          >
          </app-driver-details-new-transport-license>
        </div>

        <!-- When color is not set -->
        <div class="details__warning" *ngIf="colorManagementEnabled && colorManagement?.colorEnabled && !currentColor">
          <span>{{ 'FD_NO_COLOR_SET_ERROR' | translate }}</span>
        </div>

        <form [formGroup]="form" #formElemRef>
          <!--Parameters-->
          <section class="azz-control-group">
            <div class="azz-header">{{ 'FD_PARAMS_HEADER' | translate }}</div>

            <div class="row">
              <div class="col-md-4">
                <!--Taxi number-->
                <app-driver-details-taxi-number [form]="form" [driver]="driver"> </app-driver-details-taxi-number>
              </div>
              <!--Color Management-->
              <div class="col-md-4" *ngIf="colorManagementEnabled && colorManagement?.colorEnabled">
                <app-driver-details-color-management
                  [form]="form"
                  [driver]="driver"
                  [colorManagementData]="colorManagement"
                >
                </app-driver-details-color-management>
              </div>
            </div>
          </section>

          <!--Driver rating-->
          <div class="azz-control-group" *ngIf="driver?.rating !== null">
            <azz-loading-block [isLoading]="ordersStatsLoading$ | async">
              <app-rating
                [driver]="driver"
                [ordersStats]="ordersStats$ | async"
                [readonly]="disabled"
                (changeRate)="updateRating($event)"
              >
              </app-rating>
            </azz-loading-block>
          </div>

          <!-- Driver info -->
          <div class="azz-control-group">
            <div class="azz-header">{{ 'DRIVER' | translate }}</div>
            <app-driver-details-info
              [form]="form"
              [ngDisabled]="disabled"
              [countries]="countries$ | async"
              [minPhoneLength]="minPhoneLength"
              [maxPhoneLength]="maxPhoneLength"
              [driverData]="driver"
            >
            </app-driver-details-info>
          </div>
        </form>

        <!-- License info -->
        <div class="row azz-control-group" *ngIf="transportLicense && driverLicense">
          <div class="col-md-8">
            <app-driver-transport-licenses
              [driverLicenseKinds]="driverLicenseKinds$ | async"
              [driverLicenseData]="driverLicense"
              [transportLicenseData]="transportLicense"
              [editable]="!disabled"
            >
            </app-driver-transport-licenses>
          </div>
        </div>
        <!-- Vehicle info -->
        <div class="azz-control-group">
          <div class="azz-header">{{ 'VEHICLE' | translate }}</div>
          <div class="row">
            <div class="col-md-8">
              <app-vehicle-info
                [vehicle]="vehicle"
                [taxiNumberData]="driver?.parameters?.taxiNumber"
                [driverVehicleTypeTagsIds]="vehicleTypeTagsIds$ | async"
                [driverServiceTypeTagsIds]="serviceTypeTagsIds$ | async"
                [showTaxiNumber]="false"
                [ngDisabled]="disabled"
              >
              </app-vehicle-info>
            </div>
          </div>
          <div class="form-group" *ngIf="vehicle?.picture">
            <div class="azz-transparent-input-label">{{ 'PHOTO' | translate }}</div>
            <div class="details__img-block">
              <img class="azz-transparent-input-img-value" [alt]="'VEHICLE' | translate" [src]="vehicle?.picture" />
            </div>
          </div>
        </div>

        <div class="row justify-content-center" *ngIf="!disabled">
          <button
            type="button"
            class="custom-btn btn-rotate-flex btn-rotate-flex--w-auto mr-4"
            [class]="isDriverBlocked ? 'custom-btn--green' : 'custom-btn--red'"
            (click)="isDriverBlocked ? openUnblockDriverPopup() : openBlockDriverPopup()"
            [disabled]="
              isDriverBlocked ? (unblockBtnLoading$ | async) : (blockBtnLoading$ | async) || (saveBtnLoading$ | async)
            "
          >
            <azz-rotate-indicator7
              [visible]="isDriverBlocked ? (unblockBtnLoading$ | async) : (blockBtnLoading$ | async)"
            ></azz-rotate-indicator7>
            {{ (isDriverBlocked ? 'FD_UNBLOCK_DRIVER_BUTTON' : 'FD_BLOCK_DRIVER_BUTTON') | translate }}
          </button>
          <button
            type="button"
            class="custom-btn custom-btn--green btn-rotate-flex btn-rotate-flex--w-auto"
            [disabled]="(saveBtnLoading$ | async) || (unblockBtnLoading$ | async) || (blockBtnLoading$ | async)"
            (click)="saveDriver()"
          >
            <azz-rotate-indicator7 [visible]="saveBtnLoading$ | async"></azz-rotate-indicator7>
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </ng-container>
    </azz-loading-block>
  </div>
</div>
<div class="azz-info-blocks-row azz-last pragmatic">
  <div class="azz-info-block">
    <azz-loading-block [isLoading]="driverLoading$ | async">
      <ng-container *ngIf="driver">
        <div class="azz-header">{{ 'FD_DETAILS_TAGS_HEADER' | translate }}</div>
        <div class="instruct">{{ 'FD_DETAILS_TAGS_INSTRUCT' | translate }}</div>
        <app-driver-tags [driverId]="driver.id" [azzReadOnly]="disabled"></app-driver-tags>
      </ng-container>
    </azz-loading-block>
  </div>
</div>
