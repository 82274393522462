import { createAction, props } from '@ngrx/store';

import { ITag } from '../../../../models/tag.model';

export const init = createAction('[Driver Tags] Init');
export const destroy = createAction('[Driver Tags] Destroy');
export const getCombinedTags = createAction('[Driver Tags] Get Combined Tags', props<{ driverId: number }>());
export const getCombinedTagsSuccess = createAction(
  '[Driver Tags] Get Combined Tags Success',
  props<{ allTags: ITag[]; currentDriverTags: ITag[] }>()
);
export const getCombinedTagsError = createAction('[Driver Tags] Get Combined Tags Error');
export const updateDriverTags = createAction('[Driver Tags] Update Driver Tags', props<{ driverId: number }>());
export const updateDriverTagsSuccess = createAction('[Driver Tags] Update Driver Tags Success');
export const updateDriverTagsError = createAction('[Driver Tags] Update Driver Tags Error');
export const toggleTag = createAction('[Driver Tags] Toggle Tag', props<{ id: string }>());
