<span>{{ 'FD_NEW_LICENSE_UPLOADED_MSG' | translate }}</span>
<a (click)="openTransportLicense(newTransportLicense)" class="azz-color-dark-blue azz-link">
  {{ 'FD_NEW_TRANSPORT_LICENSE_LABEL' | translate | lowercase }}
</a>
<span>{{ 'FD_CHECK_LICENSE_MSG' | translate }}</span>
<div class="d-flex" *ngIf="!ngDisabled">
  <button
    type="button"
    class="custom-btn custom-btn--green mr-3 btn-rotate-flex btn-rotate-flex--w-auto"
    [disabled]="confirmBtnLoading"
    (click)="openApproveDriverLicensePopup()"
  >
    <azz-rotate-indicator7 [visible]="confirmBtnLoading"></azz-rotate-indicator7>
    {{ 'FD_VALID_BUTTON' | translate }}
  </button>
  <button
    type="button"
    class="custom-btn custom-btn--red btn-rotate-flex btn-rotate-flex--w-auto"
    [disabled]="rejectBtnLoading"
    (click)="openRejectDriverLicensePopup()"
  >
    <azz-rotate-indicator7 [visible]="rejectBtnLoading"></azz-rotate-indicator7>
    {{ 'FD_NOT_VALID_BUTTON' | translate }}
  </button>
</div>
