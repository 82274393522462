export const FLEET_STATUS = {
  BLOCKED: 'BLOCKED',
  ACTIVE: 'ACTIVE',
  REGISTERED: 'REGISTERED',
  VALIDATED: 'VALIDATED',
} as const;

export type FleetStatusType = (typeof FLEET_STATUS)[keyof typeof FLEET_STATUS];

export const FLEET_STATUS_LIST: FleetStatusType[] = ['BLOCKED', 'ACTIVE', 'REGISTERED', 'VALIDATED'];
