import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as ScheduleReducer from '../reducers/schedule.reducer';

export const schedule = createFeatureSelector<ScheduleReducer.State>(ScheduleReducer.scheduleFeatureKey);

export const scheduleFocusTimeSelector = createSelector(
  schedule,
  (state: ScheduleReducer.State) => state?.planning?.focusTime
);
