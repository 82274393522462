<ng-template #content let-modal>
  <div class="azz-popup">
    <h1 class="message-dialog-header">{{ header | translate }}</h1>

    <div class="azz-info-block azz-pre-line message-dialog-message">{{ message | translate }}</div>

    <div class="form-group col-md-12">
      <div [ngStyle]="{ 'margin-bottom': hideMessageField ? '10px' : '0px' }">
        Nº {{ order?.tripNumber }}<span *ngIf="order?.tripCategory">-{{ order?.tripCategory }}</span> du
        {{ order?.dateMillis | date: 'dd.MM.yyyy' : 'UTC' }}
      </div>
      <textarea
        [ngStyle]="{ display: hideMessageField ? 'none' : 'block' }"
        id="message-to-fleet"
        (input)="getFleetMessage($event)"
        name="message_to_fleet"
        class="form-control azz-transparent-input azz-textarea azz-validate"
        #messageToFleetRef
      ></textarea>
      <span *ngIf="hideMessageField">{{ messageToFleet }}</span>
    </div>
    <div class="clearfix"></div>
    <div class="azz-toolbar message-dialog-toolbar">
      <button type="button" class="btn btn-link message-dialog-default-btn" (click)="onClose()">
        {{ dismissValue || 'CANCEL' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-link message-dialog-primary-btn"
        [disabled]="!hideMessageField && !messageToFleetRef.value"
        (click)="onSuccess()"
      >
        {{ closeValue || 'CORE_OK_VALUE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
