<div class="base-advert-header advert">
  <div class="base-advert-container">
    <ng-container *ngIf="advert$ | async as advert">
      <a *ngIf="advert?.imageUrl" [href]="advert?.adUrl" target="_blank" class="advert__link">
        <img class="base-advert" [src]="advert?.imageUrl" />
      </a>
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>
