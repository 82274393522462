import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AzzPhoneInputModule, AzzRotateIndicatorModule } from '@common/components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AzzDriverCircleModule } from '../../azz-driver-cicrle/azz-driver-circle.module';
import { AzzKilometersPipe } from '../pipes/kilometers.pipe';
import { AzzMetersToKilometersPipe } from '../pipes/meters-to-kilomerers.pipe';
import { AzzSecondsToUIOrderTimePipeModule } from '../pipes/seconds-to-ui-order-time.pipe';
import { AssignDriverComponent } from './assign-driver/assign-driver.component';
import { ChangeRemoveDriverComponent } from './change-remove-driver/change-remove-driver.component';
import { FullTripDataComponent } from './custom-order-full-trip-data/custom-order-full-trip-data.component';
import { CustomerDataComponent } from './customer-data/customer-data.component';
import { DriverDataComponent } from './driver-data/driver-data.component';
import { EditOrderButtonsComponent } from './edit-order-buttons/edit-order-buttons.component';
import { FullApproachingDataComponent } from './full-approaching-data/full-approaching-data.component';
import { FullOrderDataComponent } from './full-order-data/full-order-data.component';
import { OpenExceptionComponent } from './open-exception/open-exception.component';
import { PassengerInfoComponent } from './passenger-info/passenger-info.component';
import { VehicleDataComponent } from './vehicle-data/vehicle-data.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AzzRotateIndicatorModule,
    AzzDriverCircleModule,
    ReactiveFormsModule,
    FormsModule,
    AzzPhoneInputModule,
    NgbTooltipModule,
    AzzSecondsToUIOrderTimePipeModule,
  ],
  declarations: [
    FullTripDataComponent,
    FullApproachingDataComponent,
    FullOrderDataComponent,
    CustomerDataComponent,
    DriverDataComponent,
    PassengerInfoComponent,
    VehicleDataComponent,
    EditOrderButtonsComponent,
    OpenExceptionComponent,
    AssignDriverComponent,
    ChangeRemoveDriverComponent,
    AzzKilometersPipe,
    AzzMetersToKilometersPipe,
  ],
  exports: [
    FullTripDataComponent,
    FullApproachingDataComponent,
    FullOrderDataComponent,
    CustomerDataComponent,
    DriverDataComponent,
    PassengerInfoComponent,
    VehicleDataComponent,
    EditOrderButtonsComponent,
    OpenExceptionComponent,
    AssignDriverComponent,
    ChangeRemoveDriverComponent,
    AzzKilometersPipe,
    AzzMetersToKilometersPipe,
  ],
})
export class CustomOrderSharedModule {}
