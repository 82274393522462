import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DriverOperationType } from '@core/constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-driver-details-confirm-dialog',
  templateUrl: './driver-details-confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverDetailsConfirmDialogComponent {
  @Input() operation: DriverOperationType;
  constructor(private readonly modal: NgbActiveModal) {}

  public close(): void {
    this.modal.close();
  }

  public submit(): void {
    this.modal.close('submit');
  }
}
