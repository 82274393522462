import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { DateUtilService } from './date-util.service';
import { UtilService } from './util.service';
import { NEWS_RECIPIENT_TYPE } from '../../constant/news-recipient.constant';
import { ICreateNewsPayload, INewsItem } from '../../models/news-model';

@Injectable({ providedIn: 'root' })
export class NewsService extends ApiService {
  private readonly FLEET_NEWS_URL = 'news/api/v1/news/';
  private readonly NEWS_URL = 'api/v1/news';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly dateUtils: DateUtilService,
    private readonly utilService: UtilService
  ) {
    super(httpClient);
  }

  public getNews(recipientType, status, title, sort, direction, limit, pageToken) {
    return this.get(`${this.NEWS_URL}`, {
      recipientType,
      status,
      title,
      sort,
      direction,
      limit,
      pageToken,
    });
  }

  public getNewsById(newsId) {
    return this.get(`${this.NEWS_URL}/${newsId}`);
  }

  public createNews(payload: ICreateNewsPayload): Observable<INewsItem> {
    return this.post(`${this.FLEET_NEWS_URL}fleet`, payload);
  }

  public createFleetNews(date, title, message, recipients, recipientType, sendNow) {
    return this.post(`${this.FLEET_NEWS_URL}fleet`, {
      date,
      title,
      message,
      recipients: recipientType !== NEWS_RECIPIENT_TYPE.ALL ? recipients : null,
      recipientType,
      sendNow,
    });
  }

  public updateFleetNews(newsId, fleetId, date, status, recipientType, recipients, title, message) {
    return this.put(`${this.FLEET_NEWS_URL}fleet/${newsId}`, {
      fleetId,
      date: this.getDate(date),
      sendNow: this.getSendNow(date),
      status,
      recipientType,
      recipients,
      title,
      message,
    });
  }

  public getDate(date): number {
    return this.dateUtils.isToday(date) ? new Date().getTime() : moment(date).startOf('day').valueOf();
  }

  public getSendNow(date) {
    return this.dateUtils.isToday(date);
  }

  public getRecipients(recipients: string, recipientType: string) {
    if (recipientType !== NEWS_RECIPIENT_TYPE.ALL) {
      return this.utilService.extractIds(recipients);
    }
    return null;
  }
}
