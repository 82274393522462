import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { REMOTE_STREAM_ACTION } from '../../constant/cti.constant';

@Injectable({ providedIn: 'root' })
export class CtiNotifyService {
  private readonly remoteStreamAction$ = new Subject<{
    action: REMOTE_STREAM_ACTION;
    stream?: MediaStream | null;
  }>();

  public getRemoteStreamAction(): Observable<{
    action: REMOTE_STREAM_ACTION;
    stream?: MediaStream | null;
  }> {
    return this.remoteStreamAction$.asObservable();
  }

  public sendRemoteStreamAction(data: { action: REMOTE_STREAM_ACTION; stream?: MediaStream | null }): void {
    this.remoteStreamAction$.next(data);
  }
}
