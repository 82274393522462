import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { ORDER_LOG, OrderLogType } from '@core/constant/order-log.constant';
import { PhoneAdvisorOrder } from '@core/models';
import { ITag } from '@core/models/tag.model';
import { LocalStorageService, UtilService, VehicleService } from '@core/services/common';
import { extractTagIds } from '@core/utils/extract-tag-ids.function';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from '@services/root.service';
import { icon, LatLng, Layer, MapOptions, marker, Marker, tileLayer } from 'leaflet';
import { EMPTY, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, debounceTime, finalize, map, share, switchMap, takeUntil, tap } from 'rxjs/operators';

import { COORDS } from '../../constant/coords.constant';
import { PAYMENT_TYPE_TAG, SERVICE_TYPE_TAG, VEHICLE_TYPE_TAG } from '../../constant/tag.constant';
import { ICustomer } from '../../models/customer.model';
import { IDriverFullSession, IOrderDistancePassedByDriver } from '../../models/driver-session.model';
import { IEstimation } from '../../models/estimation.model';
import { IOrderLogsData, IOrderLogsParams } from '../../models/order-logs.model';
import { IOrder } from '../../models/order.model';
import { IPosition } from '../../models/trip.model';
import { IDriverUserInfo } from '../../models/user-info.model';
import { IVehicle } from '../../models/vehicle.model';
import { OrderService } from '../../services/orders/order.service';
import { SessionService } from '../../services/sessions/session.service';
import { CustomerService } from '../../services/users/customer.service';
import { DriverService } from '../../services/users/driver.service';
import { AzzGeoMapComponent } from '../azz-geo-map/azz-geo-map.component';

@Component({
  selector: 'azz-custom-order',
  templateUrl: './custom-order.component.html',
})
export class CustomOrderComponent implements OnInit, OnDestroy {
  protected readonly rootService = inject(RootService);

  @Input() public mode: 'driver' | 'fleet' | 'super-admin';
  @Input() public urlToCustomerDetailsPage: string;
  @Input() public urlToDriverDetailsPage: string;
  @Input() public backUrl: string;
  @Input() public autoDispatch: boolean;
  @ViewChild(AzzGeoMapComponent) public geoMapComponent: AzzGeoMapComponent;

  public onEstimatedRealisedDestroy$ = new Subject<void>();
  public orderId: string;
  public order: PhoneAdvisorOrder;
  public customer$: Observable<ICustomer>;
  public driver$: Observable<IDriverUserInfo>;
  public vehicle$: Observable<IVehicle[]>;
  public estimatedRealizedTimer = 1000 * 30;
  public tripData: any;
  public approachingData: any;
  public realOrderPrice: number;
  public realOrderTime: number;
  public realTimeToDepartureAddress: number = null;
  public driverActiveLocation: IPosition;
  public driverCarMarker: Marker;
  public mapLayers: Layer[] = [];
  public mapOptions: MapOptions;
  public estimatedOrderPrice: number;
  public estimatedPriceLoading = false;
  public actionsLoadingIndicator = false;
  public orderHistoryData: IOrderLogsData;
  public appointmentPoint: LatLng;
  public destinationPoint: LatLng;
  public zoom = 10;
  public paymentTypeId: string;
  public serviceTypeId: string;
  public vehicleTypeId: string;
  public tagIdsExceptServiceVehiclePaymentType: string[] = [];
  public distanceToDepartureAddress: number;
  public distanceToArrivalAddress: number;
  public localStorageService: LocalStorageService;
  public historyParamsTypes = {
    [ORDER_LOG.COMMON]: true,
    [ORDER_LOG.DISPATCH]: false,
    [ORDER_LOG.ERROR]: false,
  };
  protected orderHistoryWatcher$ = new Subject<number | null>();
  private readonly REQUEST_DELAY_MS = 300;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected orderService: OrderService,
    protected customerService: CustomerService,
    protected driverService: DriverService,
    protected vehicleService: VehicleService,
    protected router: Router,
    protected sessionService: SessionService,
    protected translate: TranslateService,
    protected utilService: UtilService,
    protected store: Store,
    protected destroyed$: NgOnDestroyService
  ) {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: Params) => (this.orderId = res.orderId));
  }

  public ngOnInit(): void {
    switch (this.mode) {
      case 'driver':
        this.loadDataForDriver();
        break;
      case 'fleet':
      case 'super-admin':
        this.loadDataForUser();
        this.initOrderHistoryWatcher();
        this.orderHistoryWatcher$.next(null);
        break;
    }
  }

  public loadApproachingOrTripData(): void {
    switch (this.order.status) {
      case 'CREATE':
        if (!this.tripData) {
          this.initTripData();
        }
        this.loadEstimatedPrice();
        break;
      case 'CONFIRMED':
        this.getActiveDriverSessionById(this.order.driverId);
        this.initApproachingData();
        if (!this.tripData) {
          this.initTripData(true);
        }
        this.loadEstimatedPrice();
        break;
      case 'TOWARDS_DESTINATION':
        this.getActiveDriverSessionById(this.order.driverId);
        this.initTripData();
        if (!this.approachingData) {
          this.initApproachingData();
        }
        this.loadEstimatedPrice();
        break;
      case 'AT_DEPARTURE_ADDRESS':
        this.getActiveDriverSessionById(this.order.driverId);
        if (!this.tripData) {
          this.initTripData();
        }
        if (!this.approachingData) {
          this.initApproachingData();
        }
        this.resetApproachingOrTripRealizedValue('approachingData');
        this.loadEstimatedPrice();
        break;
      case 'DISPATCHING':
        if (!this.tripData) {
          this.initTripData();
        }
        this.loadEstimatedPrice();
        break;
      case 'FINISHED':
        if (!this.tripData) {
          this.initTripData();
        }
        this.resetApproachingOrTripRealizedValue('tripData');
        if (!this.approachingData) {
          this.initApproachingData();
        }
        this.realOrderPrice = this.order.price;
        if (!this.realOrderTime) {
          this.realOrderTime = this.order.timeTripTook / 1000;
        } // Convert milliseconds to seconds
        this.loadEstimatedPrice();
        this.setOrderDistancePassedByDriver();
        this.setRealTimeToDepartureAddress();
        break;
      default:
        if (!this.tripData) {
          this.initTripData();
        }
        this.loadEstimatedPrice();
        this.unsubscribeFromEstimatedRealizedInterval();
        this.resetApproachingOrTripRealizedValue('tripData');
        return;
    }
  }

  public initApproachingData(): void {
    this.sessionService
      .getApproachingEstimation(this.orderId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: IEstimation) => {
        this.approachingData = data;
      });
  }

  public initTripData(partialAssignment?: boolean): void {
    this.sessionService
      .getTripEstimation(this.orderId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: IEstimation) => {
        if (partialAssignment) {
          this.tripData = {
            ...data,
            realized: { kilometres: null, seconds: null },
          };
        } else {
          this.tripData = data;
        }
      });
  }

  public getActiveDriverSessionById(driverId: number): void {
    this.sessionService
      .getActiveDriverSessionById(driverId)
      .pipe(
        takeUntil(this.destroyed$),
        catchError(err => throwError(err))
      )
      .subscribe(
        (driverSession: IDriverFullSession) => this.handleActiveDriverSession(driverSession),
        (errorResponse: HttpErrorResponse) => this.handleActiveDriverSessionError(errorResponse)
      );
  }

  public getOrderHistory$(page?: number): Observable<IOrderLogsData> {
    const params = this.generateOrderHistoryParams(page);
    return this.orderService.getOrderHistory(params).pipe(
      takeUntil(this.destroyed$),
      finalize(() => (this.actionsLoadingIndicator = false)),
      catchError(() => {
        this.orderHistoryData = null;
        return EMPTY;
      })
    );
  }

  public filterOrderHistoryByType(data: { checked: boolean; type: OrderLogType }): void {
    this.historyParamsTypes[data.type] = data.checked;
    this.orderHistoryWatcher$.next(null);
  }

  public loadOrderObservable$(): Observable<PhoneAdvisorOrder> {
    return this.orderService.getOrderById(this.orderId).pipe(
      map(data => data as PhoneAdvisorOrder),
      takeUntil(this.destroyed$)
    );
  }

  public loadCustomer(): void {
    if (this.order.customer && this.order.customer.id) {
      this.customer$ = this.customerService.getCustomerById(this.order.customer.id);
    }
  }

  public loadDriver(): void {
    if (this.order.driverId) {
      this.driver$ = this.driverService.getDriverById(this.order.driverId).pipe(takeUntil(this.destroyed$), share());
    }
  }

  public loadVehicle(): void {
    if (this.order.driverId) {
      this.vehicle$ = this.vehicleService.getDriverVehicle(this.order.driverId).pipe(takeUntil(this.destroyed$));
    }
  }

  public setEstimatedRealizedInterval(): void {
    timer(0, this.estimatedRealizedTimer)
      .pipe(takeUntil(this.onEstimatedRealisedDestroy$))
      .subscribe(() => this.loadApproachingOrTripData());
  }

  public setRealizedIntervalForDriver(): void {
    timer(0, this.estimatedRealizedTimer)
      .pipe(takeUntil(this.onEstimatedRealisedDestroy$))
      .subscribe(() => this.loadApproachingTripDataForDriver());
  }

  public loadApproachingTripDataForDriver(): void {
    if (this.order.status !== 'FINISHED') {
      return;
    }

    if (!this.tripData) {
      this.resetTripRealDataForDriver();
    }

    if (!this.approachingData) {
      this.resetApproachingRealDataForDriver();
    }

    this.realOrderPrice = this.order.price;
    if (!this.realOrderTime) {
      this.realOrderTime = this.order.timeTripTook / 1000;
    } // Convert milliseconds to seconds

    this.setRealTimeToDepartureAddress();
    this.setOrderDistancePassedByDriver();
    this.unsubscribeFromEstimatedRealizedInterval();
  }

  public resetApproachingRealDataForDriver(): void {
    this.approachingData = {
      estimated: null,
      realized: null,
    };
  }

  public resetTripRealDataForDriver(): void {
    this.tripData = {
      estimated: null,
      realized: null,
    };
  }

  public loadEstimatedPrice(): void {
    if (this.estimatedOrderPrice) {
      return;
    }

    this.calculateEstimatedPrice();
  }

  public calculateEstimatedPrice(): void {
    const pickupCoords = this.order.appointmentAddress.position;
    const destinationCoords = this.order.destinationAddress.position;
    const { longitude: pickupLon, latitude: pickupLat } = pickupCoords;
    const { longitude: dropoffLon, latitude: dropoffLat } = destinationCoords;
    this.estimatedPriceLoading = true;
    this.sessionService
      .getEstimatedPrice({ pickupLat, pickupLon, dropoffLat, dropoffLon })
      .pipe(
        finalize(() => (this.estimatedPriceLoading = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((data: { price: number }) => (this.estimatedOrderPrice = data.price));
  }

  public resetApproachingOrTripRealizedValue(data: string): void {
    if (!this[data]) {
      return;
    }

    if (this[data].realized) {
      this[data] = { ...this[data], realized: { kilometres: null, seconds: null } };
    } else {
      this[data].realized = {
        kilometres: null,
        seconds: null,
      };
    }
  }

  public unsubscribeFromEstimatedRealizedInterval(): void {
    this.onEstimatedRealisedDestroy$.next(null);
    this.onEstimatedRealisedDestroy$.complete();
  }

  public initCoordinatesAndMapOptions(): void {
    this.destinationPoint = new LatLng(
      this.order?.destinationAddress?.position.latitude,
      this.order?.destinationAddress?.position.longitude
    );
    this.appointmentPoint = new LatLng(
      this.order?.appointmentAddress?.position.latitude,
      this.order?.appointmentAddress?.position.longitude
    );
    this.mapOptions = {
      layers: [tileLayer(COORDS.TILE, { maxZoom: 18, attribution: '...' })],
      zoom: this.zoom,
      scrollWheelZoom: false,
    };
  }

  public initMapLayers(): void {
    const departureAddressMarker = marker(this.appointmentPoint, {
      icon: icon({ iconUrl: 'assets/images/blue-marker.svg' }),
    })
      .bindTooltip(
        `${this.translate.instant('DEPARTURE_ADDRESS_LABEL')}:
        ${this.setAddressMarkerTooltip(this.order, 'appointmentAddress')}`
      )
      .openTooltip();

    const arrivalAddressMarker = marker(this.destinationPoint, {
      icon: icon({ iconUrl: 'assets/images/red-marker.svg' }),
    })
      .bindTooltip(
        `${this.translate.instant('ARRIVAL_ADDRESS_LABEL')}:
      ${this.setAddressMarkerTooltip(this.order, 'destinationAddress')}`
      )
      .openTooltip();

    departureAddressMarker.setZIndexOffset(2);
    arrivalAddressMarker.setZIndexOffset(2);

    this.mapLayers = [departureAddressMarker, arrivalAddressMarker];
  }

  public fitMapBounds(): void {
    setTimeout(() => this.geoMapComponent.fitBounds([this.appointmentPoint, this.destinationPoint]));
  }

  public setAddressMarkerTooltip(order: IOrder, address: string): string {
    return this.utilService
      .tagRemoveFalsyValueFunc`${order[address].number} ${order[address].street} ${order[address].city}`;
  }

  public goBack(): void {
    this.router.navigate([this.backUrl]);
  }

  public prevOrderHistoryPage(page: number): void {
    this.orderHistoryWatcher$.next(page);
  }

  public nextOrderHistoryPage(page: number): void {
    this.orderHistoryWatcher$.next(page);
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromEstimatedRealizedInterval();
  }

  private loadDataForDriver(): void {
    this.loadOrderObservable$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((order: PhoneAdvisorOrder) => {
        this.order = order;
        this.getServiceVehiclePaymentTypeId();

        this.setRealizedIntervalForDriver();
      });
  }

  private loadDataForUser(): void {
    this.loadOrderObservable$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (order: PhoneAdvisorOrder) => {
          this.order = order;
          this.getServiceVehiclePaymentTypeId();
          this.loadCustomer();
          this.loadDriver();
          this.loadVehicle();
          this.initCoordinatesAndMapOptions();
          this.initMapLayers();
          this.fitMapBounds();
          this.setEstimatedRealizedInterval();
        },
        (errorResponse: HttpErrorResponse) => this.handleFleetOrderError(errorResponse)
      );
  }

  private initOrderHistoryWatcher(): void {
    this.orderHistoryWatcher$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(this.REQUEST_DELAY_MS),
        tap(() => (this.actionsLoadingIndicator = true)),
        switchMap((page: number) => this.getOrderHistory$(page)),
        tap(() => (this.actionsLoadingIndicator = false))
      )
      .subscribe((res: IOrderLogsData) => (this.orderHistoryData = res));
  }

  private generateOrderHistoryParams(page?: number): Partial<IOrderLogsParams> {
    return { orderId: this.orderId, size: 15, page, type: this.generateHistoryTypeParam() };
  }

  private generateHistoryTypeParam(): OrderLogType[] {
    const typesArr = Object.keys(this.historyParamsTypes);
    // @ts-ignore
    return typesArr.filter((key: OrderLogType) => this.historyParamsTypes[key]) as OrderLogType[];
  }

  private handleFleetOrderError(errorResponse: HttpErrorResponse): void {
    this.order = null;
    switch (errorResponse.status) {
      case 403:
        if (errorResponse.error.reason === 'order.of.another.fleet') {
          this.rootService.alerts.error('NO_ACCESS_TO_PAGE');
          this.navigateIfFleetOrderErrorOccurs();
        }
        break;
      case 404:
        this.rootService.alerts.error('ORDER_DOESNOT_EXIST');
        this.navigateIfFleetOrderErrorOccurs();
        break;
      default:
        throw errorResponse;
    }
  }

  private navigateIfFleetOrderErrorOccurs(): void {
    this.router.navigate([this.backUrl]);
  }

  private getServiceVehiclePaymentTypeId(): void {
    const { serviceTypeId, vehicleTypeId, paymentTypeId, orderSelectedTags } = extractTagIds(this.order.tags as ITag[]);
    this.tagIdsExceptServiceVehiclePaymentType = orderSelectedTags.map(tag => tag.id);
    this.tagIdsExceptServiceVehiclePaymentType = this.tagIdsExceptServiceVehiclePaymentType.slice();
    this.serviceTypeId = serviceTypeId || SERVICE_TYPE_TAG.STND;
    this.vehicleTypeId = vehicleTypeId || VEHICLE_TYPE_TAG.SEDAN;
    this.paymentTypeId = paymentTypeId || PAYMENT_TYPE_TAG.CSH;
  }

  private getOrderDistancePassedByDriver$(): Observable<IOrderDistancePassedByDriver> {
    return this.sessionService.getOrderDistancePassedByDriver(this.orderId);
  }

  private setOrderDistancePassedByDriver(): void {
    if (this.distanceToDepartureAddress == null || !this.distanceToArrivalAddress == null) {
      this.getOrderDistancePassedByDriver$()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: IOrderDistancePassedByDriver) => {
          this.distanceToDepartureAddress = res.distanceToApp ? Number((res.distanceToApp / 1000).toFixed(2)) : null;
          this.distanceToArrivalAddress = res.distanceToDest ? Number((res.distanceToDest / 1000).toFixed(2)) : null;
        });
    }
  }

  private setRealTimeToDepartureAddress(): void {
    if (this.realTimeToDepartureAddress) {
      return;
    }

    this.realTimeToDepartureAddress = this.order.driverArrivalTook ? this.order.driverArrivalTook / 1000 : null;
  }

  private handleActiveDriverSession(driverSession: IDriverFullSession): void {
    this.driverActiveLocation = driverSession.location;
    const driverCoords = new LatLng(this.driverActiveLocation.latitude, this.driverActiveLocation.longitude);

    if (!this.driverCarMarker) {
      this.driverCarMarker = marker(driverCoords, {
        icon: icon({
          iconUrl: this.utilService.setCarColorByStatus(this.order.status, driverSession.status),
          iconAnchor: [-8, -14],
        }),
      });
      this.driverCarMarker.setZIndexOffset(1);
      this.mapLayers = [...this.mapLayers, this.driverCarMarker];
      return;
    }
    this.driverCarMarker.setLatLng(driverCoords);
  }

  private handleActiveDriverSessionError(errorResponse: HttpErrorResponse): void {
    const { reason, message } = errorResponse.error;
    if (errorResponse.status === 400 && reason === 'driver.not.logged.in') {
      console.log(reason);
      this.driver$.pipe(map(driver => ({ ...driver, status: 'ACTIVE_BUT_NOT_LOGGED_IN' })));
      return;
    }
    if (
      errorResponse.status === 401 &&
      (reason === 'active.session.not.found' ||
        reason === 'active.session.was.closed' ||
        reason === 'active.session.was.closed.by.by.driver')
    ) {
      this.unsubscribeFromEstimatedRealizedInterval();
      this.rootService.alerts.error(message);
    } else {
      throw errorResponse;
    }
  }
}
