/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * order type enum
 */
export type OrderTypeEnumWebApiModel = 'IMMEDIATE' | 'RESERVATION' | 'IN_ADVANCE';

export const OrderTypeEnumWebApiModel = {
  Immediate: 'IMMEDIATE' as OrderTypeEnumWebApiModel,
  Reservation: 'RESERVATION' as OrderTypeEnumWebApiModel,
  InAdvance: 'IN_ADVANCE' as OrderTypeEnumWebApiModel,
};
