import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IOrder } from '../../../models/order.model';

@Component({
  selector: 'azz-custom-order-driver-order-data',
  templateUrl: './driver-order-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverOrderDataComponent {
  @Input() order: IOrder;
  @Input() paymentTypeId: string;
  @Input() tagIdsExceptServiceVehiclePaymentType: string[];
}
