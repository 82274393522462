/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * object with distance, time and price estimations. Price estimation can be avoided if request params doesn\'t contain price requirements (for example driver ETA request)
 */
export interface RouteEstimationResponseWebApiModel {
  /**
   * estimated route time in seconds
   */
  inSeconds: number;
  /**
   * estimated route length in meters
   */
  inMeters: number;
  /**
   * estimated route price, in euro
   */
  price?: string;
}
