import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { OrderExceptionType } from '@core/constant';
import { isEqual } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import { IExceptionDataParams } from '../../../models/exception.model';

@Component({
  selector: 'app-exceptions-filters',
  templateUrl: './exceptions-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class ExceptionsFiltersComponent implements OnInit {
  @Output() updateFilterModel$ = new EventEmitter<Partial<IExceptionDataParams>>();
  public form: FormGroup;
  public types: OrderExceptionType[] = [
    'DRIVER_LATE',
    'DRIVER_PROBLEM',
    'CUSTOMER_ABSENT',
    'TAXI_NOT_FOUND',
    'TAXI_NOT_ASSIGNED',
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initForm();
  }

  @Input() set filterModel(value: Partial<IExceptionDataParams>) {
    if (!this.isValueEqual(value)) {
      this.form.patchValue(value, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updateFilterModel$.emit(this.form.value));
  }

  private initForm(): void {
    this.form = this.fb.group({ type: null });
  }

  private isValueEqual(value: Partial<IExceptionDataParams>): boolean {
    return isEqual(value, this.form.value);
  }
}
