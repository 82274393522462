import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ExceptionsComponent } from './exceptions.component';
import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';
import { ExceptionRowComponent } from './exception-row/exception-row.component';
import { ExceptionsFiltersComponent } from './filters/exceptions-filters.component';
import { ExceptionsStoreModule } from './store/exceptions-store.module';
import { ExceptionsTableComponent } from './table/exceptions-table.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBlockModule,
    TableNoMatchesModule,
    PaginationModule,
    ExceptionsStoreModule,
  ],
  declarations: [ExceptionsComponent, ExceptionsTableComponent, ExceptionsFiltersComponent, ExceptionRowComponent],
  exports: [ExceptionsComponent],
})
export class ExceptionsModule {}
