import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { ICustomer, ICustomersData, ICustomersDataParams } from '@core/models/customer.model';
import { UtilService } from '@core/services/common';
import { CustomerService } from '@core/services/users/customer.service';
import { UserService } from '@core/services/users/user.service';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { boundMethod } from 'autobind-decorator';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'azz-customer-typeahead',
  templateUrl: './customer-typeahead.component.html',
  styleUrls: ['./customer-typeahead.component.less'],
  providers: [NgOnDestroyService],
})
export class CustomerTypeaheadComponent implements OnInit {
  @Input() NgTabindex = 0;
  @Input() ngId: string;
  @Input() isDisabled = false;
  @Output() selectCustomer$ = new EventEmitter<ICustomer>();
  @Output() typing = new EventEmitter<void>();
  @Output() createCustomer$ = new EventEmitter<string>();
  @Output() searchValues$ = new EventEmitter<ICustomer[]>();
  @ViewChild('formElemRef') formElemRef: ElementRef;

  public customerBlockTime: string;
  public customerBlockReason: string;
  public REQUEST_DELAY_MS = 500;
  public form: FormGroup;
  public customer: any;
  public maxAmountOfCustomers = 5;
  public loadingIndicator: boolean;
  public numbersPattern = /^[0-9]+$/;
  private noCustomerFound: boolean;

  constructor(
    private readonly utilService: UtilService,
    private readonly destroyed$: NgOnDestroyService,
    private readonly customerService: CustomerService,
    private readonly userService: UserService,
    private readonly translate: TranslateService
  ) {}

  public get hightlightTerm(): string {
    const search = this.form.controls.searchField.value as string;

    return this.removeZeroFromStartOf(search);
  }

  public get searchFieldControl() {
    return this.form.get('searchField');
  }

  private get phoneNumberControl() {
    return this.form.get('phoneNumber');
  }

  @boundMethod
  public search(text$: Observable<string>): Observable<ICustomer[]> {
    return text$.pipe(
      debounceTime(this.REQUEST_DELAY_MS),
      distinctUntilChanged(),
      tap(() => {
        this.resetCustomer();
        this.typing.emit();
      }),
      tap(() => (this.loadingIndicator = true)),
      switchMap((searchValue: string) => this.getCustomers$(this.generateCustomersParams(searchValue))),
      tap(listOfCustomers => this.searchValues$.emit(listOfCustomers)),
      tap(() => (this.loadingIndicator = false)),
      takeUntil(this.destroyed$)
    );
  }

  @boundMethod
  public formatter(customer: ICustomer) {
    return this.utilService.tagRemoveFalsyValueFunc`${customer.name} ${customer.lastName}`;
  }

  @boundMethod
  private noSelectedCustomerValidator(): ValidationErrors | null {
    if (this.customer) {
      this.checkIsDisabled(this.customer);
    }

    const formValue = this.form?.get('searchField')?.value;
    return this.customer || formValue ? null : { noSelectedCustomer: true };
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    this.form = new FormGroup({
      searchField: new FormControl('', [this.noSelectedCustomerValidator]),
      phoneNumber: new FormControl(''),
    });
  }

  public focusOnSearchField(): void {
    const inputName = 'searchField';
    setTimeout(() => {
      console.log('SEARCH FIELD: ', this.form.value.searchField);
      if (this.form.value.searchField) {
        return;
      }
      this.formElemRef.nativeElement.elements[inputName].focus();
    });
  }

  public removeFocusOnSearchField(): void {
    setTimeout(() => {
      this.formElemRef.nativeElement.elements[1].click();
    });
  }

  public reset(): void {
    this.customer = null;
    this.noCustomerFound = false;
    this.form.reset();
    this.form.controls.searchField.updateValueAndValidity();
  }

  public onCustomerSelect(ngbEvent: NgbTypeaheadSelectItemEvent<ICustomer | null> | (ICustomer | null)): void {
    console.log('onCustomerSelect');
    if (this.isNgbTypeaheadSelectItemEvent(ngbEvent)) {
      this.customer = ngbEvent.item ? ngbEvent.item : null;
    } else {
      this.customer = ngbEvent;
    }
    if (this.customer !== null) {
      this.checkIsDisabled(this.customer);
    }

    if (this.customer) {
      this.selectCustomer$.emit(this.customer);
    } else {
      this.createCustomer$.emit(this.form.controls.searchField.value);
    }
  }

  public setAutocompleteFieldValueName(customer: any): void {
    this.form.patchValue({ searchField: customer });
  }

  public setAutocompleteFieldValue(customer: any): void {
    this.form.patchValue({ searchField: customer });
    this.customer = customer;
    this.form.controls.searchField.updateValueAndValidity();
  }

  public setDefaultPhoneNumber(phoneNumber: string): void {
    if (phoneNumber) {
      this.phoneNumberControl.patchValue(phoneNumber);
      this.formElemRef.nativeElement.elements.searchField.value = phoneNumber;
    }
  }

  public onEnterClick() {
    if (this.noCustomerFound) {
      this.createCustomer$.emit(this.form.controls.searchField.value);
    }
  }

  public onBlur() {
    this.loadingIndicator = false;
    this.noCustomerFound = false;
    this.form.get('searchField').updateValueAndValidity();
  }

  public checkIsDisabled(data) {
    const customerBlockTime = data.blockTime;
    const customerBlockReason = data.blockReason;
    this.customerBlockTime = customerBlockTime ? moment(customerBlockTime).format('DD.MM.YYYY') + ', ' : '';
    this.customerBlockReason =
      customerBlockReason === 'PROD'
        ? this.translate.instant('FLEET_PROD_REASON')
        : customerBlockReason === 'INVOICE'
          ? this.translate.instant('FLEET_INVOICE_REASON')
          : '';
  }

  private isNgbTypeaheadSelectItemEvent(event): event is NgbTypeaheadSelectItemEvent {
    return event.hasOwnProperty('item') && event.hasOwnProperty('preventDefault');
  }

  private getCustomers$(params: Partial<ICustomersDataParams>): Observable<ICustomer[]> {
    let searchValue = params?.anyParam;
    searchValue = this.removeZeroFromStartOf(searchValue);
    return searchValue
      ? this.customerService.getCustomersExactFirst(params).pipe(
          takeUntil(this.destroyed$),
          map((response: ICustomersData) => {
            if (!response?.content?.length) {
              this.noCustomerFound = true;
              return [null];
            }
            let listOfCustomers = response.content;
            searchValue = searchValue.replace('+', '');

            if (this.shouldBeTreatedAsPhone(searchValue)) {
              listOfCustomers = this.sortPhonesByPhoneMatch(listOfCustomers, searchValue);
            } else {
              listOfCustomers = this.sortPhonesByOrderNumber(listOfCustomers);
            }
            return listOfCustomers;
          }),
          catchError(() => {
            this.loadingIndicator = false;
            this.noCustomerFound = true;
            return [null]; // THE SAME AS NO RESULTS FOUND
          })
        )
      : of([]);
  }

  private shouldBeTreatedAsPhone(search: string): boolean {
    return !!search.match(this.numbersPattern);
  }

  private sortPhonesByOrderNumber(customers: any[]) {
    customers.forEach(customer => {
      customer.phones.sort((a, b) => a.orderNumber - b.orderNumber);
    });

    return customers;
  }

  /* SEARCHING CUSTOMER BY PHONE NUMBER */
  private sortPhonesByPhoneMatch(customers: any[], search: string) {
    customers.forEach(customer => {
      const matchedPhonesArr = [];
      const noMatchPhonesArr = [];
      customer.phones.forEach(item => {
        const isFound = (item.phoneCode + item.phoneNumber).search(new RegExp(search)) !== -1;

        if (isFound) {
          matchedPhonesArr.push(item);
        } else {
          noMatchPhonesArr.push(item);
        }
      });

      customer.phones = [...matchedPhonesArr, ...noMatchPhonesArr];
    });

    return customers;
  }

  /**
   * if phone number starts from 0, the 0 should be removed
   */
  private removeZeroFromStartOf(search: string) {
    if (typeof search !== 'string') {
      return;
    }
    return search?.startsWith('0') ? search.slice(1) : search;
  }

  private generateCustomersParams(searchValue?: string): Partial<ICustomersDataParams> {
    return {
      pageSize: this.maxAmountOfCustomers,
      anyParam: searchValue,
      fleetId: this.userService.getCurrentUserInfo()?.fleet?.id,
    };
  }

  private resetCustomer(): void {
    console.log('resetCustomer');
    if (this.customer) {
      this.customer = null;
      this.form.controls.searchField.updateValueAndValidity();
    }
    this.noCustomerFound = false;
  }
}
