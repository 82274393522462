import { UIAddress } from '../interfaces/address-dto.interface';

export const buildCreateOrderAddressDTO = (address: UIAddress): any => {
  if (!address) {
    throw new Error('Cannot build address, wrong parameter provided');
  }

  return address.type === 'poi'
    ? {
        poi: {
          ...address?.poi,
          label: address?.poi?.label ? address.poi.label : address.label,
        },
        position: {
          ...address?.poi?.coordinates,
        },
        city: address.city,
        geoAreaId: address.geoAreaId,
        type: 'poi',
      }
    : {
        state: address.city,
        postalCode: address.postalCode,
        city: address.city,
        street: address.street,
        // eslint-disable-next-line id-blacklist
        number: address.housenumber,
        position: {
          longitude: address?.coordinates?.longitude,
          latitude: address?.coordinates?.latitude,
        },
      };
};
