import {
  ORDER_TAG_TYPE_MAP,
  PAYMENT_TYPE_TAG,
  PaymentTypeTitle,
  SERVICE_TYPE_TAG,
  ServiceTypeTitle,
  TAG_TYPES,
  VEHICLE_TYPE_TAG,
  VehicleTypeTitle,
} from '@core/constant/tag.constant';
import { ITag } from '@core/models/tag.model';
import { includes } from 'lodash-es';

export const getServiceTypeById = (id: ServiceTypeTitle) => {
  const defaultServiceType = ORDER_TAG_TYPE_MAP.service.STND;
  const type = id ? ORDER_TAG_TYPE_MAP.service[id] : defaultServiceType;

  return type;
};
export const getVehicleTypeById = (id: VehicleTypeTitle) => {
  const defaultVehicleType = ORDER_TAG_TYPE_MAP.vehicle.SEDAN;
  const type = id ? ORDER_TAG_TYPE_MAP.vehicle[id] : defaultVehicleType;

  return type;
};
export const getPaymentTypeById = (id: PaymentTypeTitle) => {
  const defaultPaymentType = ORDER_TAG_TYPE_MAP.payment.CSH;
  const type = id ? ORDER_TAG_TYPE_MAP.payment[id] : defaultPaymentType;

  return type;
};

export const defaultTagTypeId = {
  service: SERVICE_TYPE_TAG.STND,
  vehicle: VEHICLE_TYPE_TAG.SEDAN,
  payment: PAYMENT_TYPE_TAG.CSH,
};

const isServiceTagId = (tagId: string): tagId is ServiceTypeTitle => includes(TAG_TYPES.service, tagId);
const isVehicleTagId = (tagId: string): tagId is VehicleTypeTitle => includes(TAG_TYPES.vehicle, tagId);
const isPaymentTagId = (tagId: string): tagId is PaymentTypeTitle => includes(TAG_TYPES.payment, tagId);

export const extractTagIds = (tags: ITag[]) => {
  const orderSelectedTags: ITag[] = [];
  let serviceTypeId: ServiceTypeTitle = null;
  let vehicleTypeId: VehicleTypeTitle = null;
  let paymentTypeId: PaymentTypeTitle = null;
  for (const tag of tags) {
    if (includes(TAG_TYPES.service, tag.id) && isServiceTagId(tag.id)) {
      serviceTypeId = tag.id;
    } else if (includes(TAG_TYPES.vehicle, tag.id) && isVehicleTagId(tag.id)) {
      vehicleTypeId = tag.id;
    } else if (includes(TAG_TYPES.payment, tag.id) && isPaymentTagId(tag.id)) {
      paymentTypeId = tag.id;
    } else {
      orderSelectedTags.push(tag);
    }
  }

  return {
    paymentTypeId,
    serviceTypeId,
    vehicleTypeId,
    orderSelectedTags,
  };
};
