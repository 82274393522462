import { DIRECTION, DRIVER_SESSION_STATUS } from '@core/constant';
import { TagTypeName } from '@core/constant/tag.constant';
import { IGetAllDriversParams } from '@core/models';

export function getSearchParams(
  fleetId: number,
  tags: TagTypeName[][],
  name: string = ''
): Partial<IGetAllDriversParams> {
  return {
    name,
    fleetId,
    pageSize: 100,
    sort: [`taxiNumber,${DIRECTION.ASC}`, `firstName,${DIRECTION.ASC}`],
    tags,
    sessionStatus: [
      DRIVER_SESSION_STATUS.FREE,
      DRIVER_SESSION_STATUS.CONNECTED,
      DRIVER_SESSION_STATUS.OCCUPIED,
      DRIVER_SESSION_STATUS.UNREACHABLE,
    ],
  };
}
