import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export abstract class CustomControlBase<TVal> implements ControlValueAccessor {
  public readonly value$ = new BehaviorSubject<TVal | null | undefined>(null);
  public readonly disabled$ = new BehaviorSubject<boolean>(false);

  public onChange = (value: TVal | null | undefined): void => {};

  public onTouched: () => void = () => {};

  protected setValue(value: TVal): void {
    this.value$.next(value);

    this.onChange(value);
  }

  public writeValue(value: TVal): void {
    setTimeout(() => {
      this.value$.next(value);
    });
  }

  public registerOnChange(fn: (val: TVal) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }
}
