import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AzzPhoneInputModule, AzzRotateIndicatorModule, LoadingBlockModule, RatingModule } from '@common/components';
import { DatePickerModule } from '@common/date-picker/date-picker.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { DriverDetailsAvatarComponent } from './avatar/driver-details-avatar.component';
import { DriverDetailsBlockedDriverComponent } from './blocked-driver/driver-details-blocked-driver.component';
import { DriverDetailsColorManagementComponent } from './color-management/driver-details-color-management.component';
import { DriverDetailsComponent } from './driver-details.component';
import { AddressAutocompleteModule } from '../address-autocomplete/address-autocomplete.module';
import { DriverDetailsConfirmDialogComponent } from './confirm-dialog/driver-details-confirm-dialog.component';
import { DriverDetailsInfoComponent } from './driver-info/driver-details-info.component';
import { DriverDetailsNewTransportLicenseComponent } from './new-transport-license/driver-details-new-transport-license.component';
import { DriverDetailsNotValidatedComponent } from './not-validated/driver-details-not-validated.component';
import { DriverDetailsStoreModule } from './store/driver-details-store.module';
import { DriverDetailsTaxiNumberComponent } from './taxi-number/driver-details-taxi-number.component';
import { DriverTagsModule } from '../driver-tags/driver-tags.module';
import { DriverTransportLicensesModule } from '../driver-transport-licenses/driver-transport-licenses.module';
import { VehicleInfoModule } from '../vehicle-info/vehicle-info.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    DatePickerModule,
    NgSelectModule,
    AddressAutocompleteModule,
    DriverTagsModule,
    RatingModule,
    DriverDetailsStoreModule,
    LoadingBlockModule,
    DriverTransportLicensesModule,
    VehicleInfoModule,
    AzzRotateIndicatorModule,
    AzzPhoneInputModule,
  ],
  declarations: [
    DriverDetailsComponent,
    DriverDetailsAvatarComponent,
    DriverDetailsInfoComponent,
    DriverDetailsBlockedDriverComponent,
    DriverDetailsNotValidatedComponent,
    DriverDetailsNewTransportLicenseComponent,
    DriverDetailsTaxiNumberComponent,
    DriverDetailsColorManagementComponent,
    DriverDetailsConfirmDialogComponent,
  ],
  exports: [DriverDetailsComponent],
})
export class DriverDetailsModule {}
