import { createAction, props } from '@ngrx/store';

import { ITag } from '../../../../models/tag.model';

export enum OrderLogActions {
  GET_TAGS = '[Order Logs] Get Tags',
  GET_TAGS_SUCCESS = '[Order Logs] Get Tags Success',
  GET_TAGS_FAIL = '[Order Logs] Get Tags Fail',
}

export const getTagsAction = createAction(OrderLogActions.GET_TAGS);
export const getTagsSuccessAction = createAction(OrderLogActions.GET_TAGS_SUCCESS, props<{ tags: ITag[] }>());
export const getTagsFailAction = createAction(OrderLogActions.GET_TAGS_FAIL);
