<ng-template #content let-modal>
  <div class="azz-popup pre-save">
    <h1 class="message-dialog-header">{{ header | translate }}</h1>

    <div class="azz-info-block azz-pre-line message-dialog-message summary-customer-info">
      <div class="row">
        <div class="col-6">
          <div class="pre-save__text">{{ 'ORDER_TYPE_' + orderType | translate }}</div>
        </div>
        <div class="col-6" *ngIf="!isImmediate()">
          {{ orderDate | date: 'dd/MM/yyyy' }} {{ orderTime | date: 'HH:mm' }}
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="pre-save__text">
            {{ 'PA_CREATE_PASSENGER' | translate }}:
            {{ isPassengerAnonymous ? ('ANONYMOUS_PASSENGER' | translate) : passengerName }}
          </div>
        </div>
        <div class="col-6">
          <div class="pre-save__text">
            <span> {{ 'FLEET_ORDERS_TELEPHONE_LABEL' | translate }}: </span>
            <span *ngIf="customerType === 'old'; else newCustomerBlock">
              {{ selectedCustomerPhoneNumber }}
            </span>
            <ng-template #newCustomerBlock>
              <span>
                <span *ngFor="let phone of newCustomer?.phones"> {{ phone.phoneCode }}{{ phone.phoneNumber }} </span>
              </span>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="addresses?.appointmentAddress">
        <div class="col-12">
          <div class="pre-save__text">
            {{ 'DEPARTURE_ADDRESS_SHORT' | translate }}:
            <span *ngIf="addresses.appointmentAddress.type === 'poi'">
              {{ addresses.appointmentAddress?.poi?.label }}
            </span>
            <span *ngIf="addresses.appointmentAddress.type !== 'poi'">
              {{ addresses.appointmentAddress?.number }}
              {{ addresses.appointmentAddress?.street }},
              {{ addresses.appointmentAddress?.city }}
            </span>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="addresses?.destinationAddress">
        <div class="col-12">
          <div class="pre-save__text">
            {{ 'ARRIVAL_ADDRESS_SHORT' | translate }}:
            <span *ngIf="addresses.destinationAddress.type === 'poi'">
              {{ addresses.destinationAddress?.poi?.label }}
            </span>
            <span *ngIf="addresses.destinationAddress.type !== 'poi'">
              {{ addresses.destinationAddress?.number }} {{ addresses.destinationAddress?.street }},
              {{ addresses.destinationAddress?.city }}
            </span>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="tags?.length">
        <div class="col-12">
          <div class="pre-save__text">
            {{ 'PA_CREATE_NEW_TAGS' | translate }}:
            <span *ngFor="let tag of tags; let i = index">
              {{ 'CRC_TAG_' + tag.id | translate }}<span *ngIf="i !== tags.length - 1">,</span>
            </span>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="paymentType?.value">
        <div class="col-12">
          <div class="pre-save__text">
            {{ 'PAYMENT_MODE' | translate }}: {{ 'PAYMENT_TYPE_' + paymentType.value | translate }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="pre-save__text pre-save__estimations-block">
            <span>{{ 'CREATE_ORDER_ESTIMATIONS' | translate }}: </span>
            <span class="pre-save__estimations">
              <span *ngIf="distance">{{ distance / 1000 | number: '1.0-1' }} km | </span>
              <span *ngIf="duration">{{ duration | azzSecondsToUIOrderTime }} |</span>
              <span *ngIf="isEstimatedPriceShown" class="pre-save__price-block">
                <azz-rotate-indicator7 [visible]="estimatedPriceLoading" class="pre-save__price-rotate">
                </azz-rotate-indicator7>
                <span *ngIf="estimatedPrice">{{ estimatedPrice }} <span>&euro;</span></span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="azz-toolbar message-dialog-toolbar">
        <button type="button" class="btn btn-link message-dialog-default-btn" (click)="onClose()">
          {{ 'CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-link message-dialog-primary-btn" (click)="onSuccess()">
          {{ 'CREATE' | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
