import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { Observable } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

import { IDriverSessionHistory } from '../../../models/session.model';
import { SessionService } from '../../../services/sessions/session.service';
import { UserService } from '../../../services/users/user.service';

@Component({
  selector: 'azz-session-chat-details',
  templateUrl: './session-chat-details.component.html',
  styleUrls: ['./session-chat-details.component.less'],
  providers: [NgOnDestroyService],
})
export class SessionChatDetailsComponent implements OnInit {
  public loadingIndicator: boolean;
  public history: IDriverSessionHistory;

  constructor(
    private readonly sessionService: SessionService,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  ngOnInit() {
    this.sessionService.setUrl(this.userService.getCurrentUserInfo()?.user?.roles[0]);
    this.route.params
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((res: Params) => this.getSessionHistory$(res.sessionId))
      )
      .subscribe((res: IDriverSessionHistory) => (this.history = res));
  }

  private getSessionHistory$(sessionId: string): Observable<IDriverSessionHistory> {
    this.loadingIndicator = true;
    return this.sessionService.getSessionHistory({ sessionId }).pipe(
      takeUntil(this.destroyed$),
      finalize(() => (this.loadingIndicator = false))
    );
  }
}
