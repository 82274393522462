import { API_ORIGINS, ApiOriginData } from './api-origins.constant';
import { ENV_HOSTS } from './environment-hosts.constant';

/** API has different origin per each environment */
export const getAPIOriginByHost = (): ApiOriginData => {
  const host = window.location.host;

  if (ENV_HOSTS.prod.includes(host)) {
    return API_ORIGINS.prod;
  } else if (ENV_HOSTS.pretest.includes(host)) {
    return API_ORIGINS.pretest;
  } else if (ENV_HOSTS.stage.includes(host)) {
    return API_ORIGINS.stage;
  } else if (ENV_HOSTS.preprod.includes(host)) {
    return API_ORIGINS.preprod;
  } else if (ENV_HOSTS.develop.includes(host)) {
    return API_ORIGINS.develop;
  } else if (ENV_HOSTS.docker.includes(host)) {
    return API_ORIGINS.docker;
  } else if (ENV_HOSTS.local.includes(host)) {
    return API_ORIGINS.preprod;
  }

  if (host.includes('localhost')) {
    return API_ORIGINS.preprod;
  }

  throw new Error('[getAPIOriginByHost] no host found for provided origin');
};
