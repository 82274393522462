<div class="card recent-orders-card">
  <div class="card-container">
    <h2 class="header-card">{{ 'PA_RECENT_ORDERS_HEADER' | translate }}</h2>

    <div *ngIf="customerPrevOrders === undefined || customerPrevOrders === null" class="no-recent-orders">
      <p>{{ 'NO_RECENT_ORDERS_CREATED' | translate }}</p>
    </div>

    <div *ngIf="customerPrevOrders?.length === 0" class="no-recent-orders">
      <p>{{ 'PA_CUSOTMER_HAS_NO_RECENT_ORDERS' | translate }}</p>
    </div>

    <div *ngIf="customerPrevOrders?.length >= 1" class="recent-cards-container">
      <div class="recent-order-card" *ngFor="let order of customerPrevOrders">
        <div class="flex">
          <div class="recent-order-label text">{{ 'DATE' | translate }}</div>
          <div class="recent-order-content text">
            {{ order.date | date: 'dd.MM.yyyy' }} {{ order.date | date: 'HH:mm' }}
          </div>
        </div>
        <div class="flex">
          <span class="recent-order-label text"> {{ 'PREV_ORDER_PICKUP_ADDRESS_SHORT' | translate }}: </span>
          <span class="recent-order-content text" *ngIf="order?.appointmentAddress?.poi?.label">
            {{ order?.appointmentAddress?.poi?.label }}
            <span *ngIf="order?.appointmentAddress?.poi?.deleted">
              (<span class="text-deleted">{{ 'DELETED' | translate }}</span
              >)
            </span>
          </span>

          <span class="recent-order-content text" *ngIf="!order?.appointmentAddress?.poi?.label">
            {{ order?.appointmentAddress?.number }}
            {{ order?.appointmentAddress?.street }},
            {{ order?.appointmentAddress?.city }}
          </span>
        </div>
        <div class="flex">
          <span class="recent-order-label text">{{ 'PREV_ORDER_DESTINATION_ADDRESS_SHORT' | translate }}: </span>
          <span class="recent-order-content text" *ngIf="order?.destinationAddress?.poi?.label">
            {{ order?.destinationAddress?.poi?.label }}
            <span *ngIf="order?.destinationAddress?.poi?.deleted">
              (<span class="text-deleted">{{ 'DELETED' | translate }}</span
              >)
            </span>
          </span>

          <span class="recent-order-content text" *ngIf="!order?.destinationAddress?.poi?.label">
            {{ order?.destinationAddress?.number }}
            {{ order?.destinationAddress?.street }},
            {{ order?.destinationAddress?.city }}
          </span>
        </div>
        <div class="flex">
          <div class="recent-order-label text">{{ 'TAGS' | translate }}:</div>
          <azz-prev-order-tags [allTags]="order?.tags"></azz-prev-order-tags>
        </div>
        <div class="flex">
          <div class="recent-order-label text">{{ 'PAYMENT_MODE' | translate }}:</div>
          <azz-prev-order-payment-type [allTags]="order?.tags"></azz-prev-order-payment-type>
        </div>

        <button class="recent-order-btn" (click)="onPreviousOrderClick(order)">
          {{ 'PA_CREATE_PREV_CUSTOMER_ORDER' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
