import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IObjectMap } from '../../models/common.model';
import { IUserNotificationReadPayload, IUserNotificationsData } from '../../models/user-notification.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class UserNotificationService extends ApiService {
  notificationUrl = 'user-activity/api/v1/notification/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getNotifications(params?: IObjectMap): Observable<IUserNotificationsData> {
    return this.get(this.notificationUrl, params ?? {});
  }

  public readNotification(payload: IUserNotificationReadPayload): Observable<any> {
    return this.post(`${this.notificationUrl}markAsRead`, payload);
  }
}
