import { ChangeDetectorRef, Component } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { UtilService } from '../../../../services/common/util.service';
import { DropdownComponent } from '../../../dropdown/dropdown.component';

@Component({
  selector: 'azz-city-dropdown',
  templateUrl: './city-dropdown.component.html',
  providers: [NgbDropdownConfig],
})
export class CityDropdownComponent extends DropdownComponent {
  constructor(changeDetectionRef: ChangeDetectorRef, translate: TranslateService, utilService: UtilService) {
    super(changeDetectionRef, translate, utilService);
  }

  protected override init() {
    this.loadInitialData();
    this.initWatchers();
  }

  protected override initValue() {
    if (this.inputCurrentValue) {
      this.data.displayValue = this.inputCurrentValue;
    } else if (!this.inputCurrentValue && this.defaultInputValue) {
      this.data.displayValue = this.translate.instant(this.defaultInputValue);
    } else {
      this.data.displayValue = '';
    }
  }

  protected override updateDisplayValue(): string {
    return this.selectedValue;
  }
}
