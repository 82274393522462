import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { IPhoneAdvisorOrderData } from '../../../models/phone-advisor-order';

@Component({
  selector: 'azz-customer-orders',
  templateUrl: './azz-customer-orders.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzzCustomerOrdersComponent {
  @Input() customerOrdersData: IPhoneAdvisorOrderData;
  @Input() ordersLoadingIndicator: boolean;
  @Input() orderDetailsUrl: string;
  @Input() driverDetailsUrl: string;

  constructor(private readonly router: Router) {}

  public navigateToOrderDetailsPage(evt: any, orderId: string) {
    if (evt.target.classList.contains('no-general-navigation')) {
      evt.preventDefault();
    } else {
      this.router.navigate([this.orderDetailsUrl, orderId]);
    }
  }

  public navigateToDriverDetailsPage(driverId: number) {
    if (driverId) {
      this.router.navigate([this.driverDetailsUrl, driverId]);
    }
  }
}
