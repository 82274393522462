<section class="avatar-driver">
  <div class="round-img-wrapper avatar-driver__image-wrapper">
    <img class="round-img" *ngIf="driver?.avatar" [src]="driver?.avatar + '=s80-c'" alt="driver avatar" />
    <div class="azz-initials" *ngIf="!driver?.avatar">{{ driverInitials }}</div>
  </div>
  <div class="avatar-driver__full-name">
    {{ driverFullName }}
  </div>
  <div class="avatar-driver__status" [ngClass]="'avatar-driver__status--' + driverStatusClassModifier">
    {{ 'CORE_DRIVER_STATUS_' + driver?.status | translate }}
  </div>
</section>
