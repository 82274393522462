import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf, NgOptimizedImage, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from '@auth/auth-routing.module';
import { AuthComponent } from '@auth/auth.component';
import { AdvertModule } from '@core/modules';
import { LogoutPopupModule } from '@core/modules/custom-dialog/logout-popup/logout-popup.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { AuthStore } from './store';
import { AuthEffects } from './store/effects';
import { FooterComponent } from '../shared/components/footer/footer.component';

@NgModule({
  declarations: [AuthComponent],
  imports: [
    StoreModule.forFeature(AuthStore.authFeatureKey, AuthStore.reducers),
    EffectsModule.forFeature(AuthEffects),
    AuthRoutingModule,
    AsyncPipe,
    NgClass,
    AdvertModule,
    TranslateModule,
    UpperCasePipe,
    DatePipe,
    LogoutPopupModule,
    NgIf,
    NgForOf,
    NgOptimizedImage,
    FooterComponent,
  ],
})
export class AuthModule {}
