import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ZonesAndTaxiStations } from '@core/models/fleet.model';
import { FeatureCollection } from 'geojson';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import {
  IDispatchZonesDataWithParams,
  IGetDispatchZonesDataParams,
  IUpdateZoneBody,
  IZoneParams,
} from '../../models/zone.model';

export interface IGeoArea {
  id: string;
  name: string;
  normalizedName: string;
  dispatchBeforeMinutes: number;
  description: string;
  coordinates: [
    {
      latitude: 0;
      longitude: 0;
    },
  ];
}

@Injectable({ providedIn: 'root' })
export class GeoAreaService extends ApiService {
  public URL = 'zone/api/v1/geoarea/';
  public GEOZONE_URL = 'zone/api/v1/geozone/';
  public TAXI_STATION_URL = 'zone/api/v1/geo/station/';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public findByParams(query, direction) {
    // ToDo remove, used in deprecated fleet first login component
    return this.get(`${this.URL}findByParams`, {
      query,
      direction,
    });
  }

  public findGeoArea(id: any, query: any) {
    // query - {include: 'coordinates'}
    return this.get(`${this.URL}${id}`, query);
  }

  public findByPoint({
    longitude,
    latitude,
    countryCode,
    fleetId,
  }: {
    longitude: number;
    latitude: number;
    countryCode?: 'FR' | string;
    fleetId?: number;
  }): Observable<IGeoArea> {
    const query = { latitude, longitude, fleetId, country: countryCode ?? 'FR' };
    return this.get(`${this.URL}findByPoint`, query);
  }

  public findByGeozone({
    longitude,
    latitude,

    geoAreaId,
  }: {
    longitude: number;
    latitude: number;
    countryCode?: 'FR' | string;
    geoAreaId: string;
  }): Observable<IGeoArea> {
    const query = { latitude, longitude, geoAreaId };
    return this.get(`${this.GEOZONE_URL}findByPoint`, query);
  }

  public createBusinessZone(countryCode: string, payload): Observable<any> {
    return this.post(`${this.URL}country/${countryCode}`, payload);
  }

  public createDispatchingZones(areaId: string, payload): Observable<any> {
    return this.post(`${this.URL}${areaId}/geoZone`, payload);
  }

  public createTaxiStation(dispatchAreaId: string, payload: FeatureCollection): Observable<any> {
    return this.post(`${this.GEOZONE_URL}${dispatchAreaId}/station`, payload);
  }

  public exportBusinessZone(areaId: string): Observable<FeatureCollection> {
    return this.get(`${this.URL}${areaId}/export`);
  }

  public exportDispatchZones(areaId: string): Observable<any> {
    return this.get(`${this.URL}${areaId}/zones/export`);
  }

  public exportAllZones(areaId: string): Observable<ZonesAndTaxiStations[]> {
    return this.get(`${this.URL}${areaId}/allZones/export`);
  }

  public exportTaxiStations(dispatchAreaId: string): Observable<FeatureCollection> {
    return this.get(`${this.GEOZONE_URL}${dispatchAreaId}/export`);
  }

  public hideBusinessZone(queryParams: any): Observable<any> {
    return this.put(`${this.URL}hide`, null, queryParams);
  }

  public hideDispatchZones(queryParams: any): Observable<any> {
    return this.put(`${this.GEOZONE_URL}hide`, null, queryParams);
  }

  public hideTaxiStation(id: string): Observable<any> {
    return this.put(`${this.TAXI_STATION_URL}hide`, null, { id });
  }

  public getBusinessZoneParams(areaId: string): Observable<IZoneParams> {
    return this.get(`${this.URL}${areaId}/parameters`);
  }

  public getDispatchZonesDataWithParams(params: IGetDispatchZonesDataParams): Observable<IDispatchZonesDataWithParams> {
    return this.get(`${this.GEOZONE_URL}parameters`, params);
  }

  public updateBusinessZoneParams(body: IUpdateZoneBody): Observable<IZoneParams> {
    return this.put(`${this.URL}parameters`, body);
  }

  public updateDispatchZonesParams(body: IUpdateZoneBody[]): Observable<any> {
    return this.put(`${this.GEOZONE_URL}parameters`, body);
  }
}
