import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceTagsOptions } from '@common/types/id-option.type';
import {
  ORDER_TAG_TYPE,
  SERVICE_TYPE_TAG,
  TagTypeName,
  VEHICLE_TYPE_TAG,
  VehicleTypeTitle,
} from '@core/constant/tag.constant';
import { ITag } from '@core/models/tag.model';
import { sortAttributeTags } from '@dash/modules/phone-advisor/common/tags-sort';
import { NgbAccordionDirective, NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import { includes, isArray } from 'lodash-es';
import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

const defaultTags = {
  vehicle: { id: 'SEDAN', value: null },
  service: { id: 'STND', value: null },
};

@Component({
  selector: 'azz-attribute-tags',
  templateUrl: './attribute-tags.component.html',
  styleUrls: ['./attribute-tags.component.less'],
})
export class AttributeTagsComponent implements OnInit, OnChanges {
  @ViewChild('accordion') accordion: NgbAccordionDirective;
  @ViewChildren(NgbAccordionItem) accordionItems: QueryList<NgbAccordionItem>;
  @Input() listOfTags: ITag[];
  @Input() isTagsDisabled: boolean;
  @Input() form: FormGroup;
  @Output() setChangeTag = new EventEmitter();

  public vehicleTypes = ORDER_TAG_TYPE.vehicle;
  public serviceTypes = ORDER_TAG_TYPE.service;

  public isNotDefaultMandatoryTags$: Observable<boolean>;

  public paymentList: ITag[] = [];
  public driversList: ITag[] = [];
  public vehiclesList: ITag[] = [];

  public readonly paymentListTags = ['TP'];
  public readonly driversListTags = ['PA', 'AN', 'HO', 'HT'];
  public readonly vehiclesListTags = ['TDE', 'AV', 'RA', 'RL', 'DEM', 'VB', 'LO', 'GC', 'PB'];

  public readonly sortTags = ['PA', 'AN', 'HO', 'HT', 'TDE', 'AV', 'RA', 'RL', 'DEM', 'VB', 'LO', 'GC', 'PB'];

  public selectedTags: ITag[] = [];

  public model: string = '';
  public isCollapsed = false;

  public ngOnInit(): void {
    this.isNotDefaultMandatoryTags$ = combineLatest([
      this.form.controls.vehicleType.valueChanges.pipe(startWith(defaultTags.vehicle)),
      this.form.controls.serviceType.valueChanges.pipe(startWith(defaultTags.service)),
    ]).pipe(
      debounceTime(100),
      map(
        ([vehicleType, serviceType]) =>
          defaultTags.vehicle.id !== vehicleType.id || serviceType.id !== defaultTags.service.id
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  public ngOnChanges(): void {
    if (!this.listOfTags || !isArray(this.listOfTags)) {
      return;
    }
    this.listOfTags.forEach(tag => {
      if (this.paymentListTags.includes(tag.id) && !this.paymentList.includes(tag)) {
        this.paymentList.splice(0, 1, tag);
      }
      if (this.driversListTags.includes(tag.id) && !this.driversList.includes(tag)) {
        if (tag.selected) {
          this.driversList = this.driversList.map(obj => this.listOfTags.find(o => o.id === obj.id) || obj);
        }

        if (this.driversList.length >= 4) {
          return;
        }
        this.driversList.push(tag);
      }
      if (this.vehiclesListTags.includes(tag.id) && !this.vehiclesList.includes(tag)) {
        if (tag.selected) {
          this.vehiclesList = this.vehiclesList.map(obj => this.listOfTags.find(o => o.id === obj.id) || obj);
        }
        if (this.vehiclesList.length >= 9) {
          return;
        }
        this.vehiclesList.push(tag);
      }
      this.driversList = sortAttributeTags(this.driversList, this.driversListTags);
      this.vehiclesList = sortAttributeTags(this.vehiclesList, this.vehiclesListTags);
    });
    this.updateSelectedTags();
  }

  public formatter = (x: { name: string }) => x.name;

  private getMatches(tags: { name: string; id: string }[], cleanedTerm: string) {
    const startsWithMatches = [];
    const containsMatches = [];

    for (const tag of tags) {
      const lowerName = tag.name.toLowerCase();
      if (lowerName.startsWith(cleanedTerm)) {
        startsWithMatches.push(tag);
      } else if (lowerName.includes(cleanedTerm)) {
        containsMatches.push(tag);
      }
    }

    startsWithMatches.sort((a, b) => a.name.localeCompare(b.name));
    containsMatches.sort((a, b) => a.name.localeCompare(b.name));

    return startsWithMatches.concat(containsMatches);
  }

  public search: OperatorFunction<string, readonly { name: string; id: string }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        if (term.length < 1) {
          return [];
        }
        const cleanedTerm = term.trimStart().toLowerCase();
        let searchResults = [];

        const vehicleTypesFormatted = this.vehicleTypes.map(type => ({
          name: type.name,
          id: type.id,
          selected: type.selected,
        }));
        const serviceTypesFormatted = this.serviceTypes.map(type => ({
          name: type.name,
          id: type.id,
          selected: type.selected,
        }));

        searchResults = searchResults.concat(this.getMatches(this.listOfTags, cleanedTerm));
        searchResults = searchResults.concat(this.getMatches(vehicleTypesFormatted, cleanedTerm));
        searchResults = searchResults.concat(this.getMatches(serviceTypesFormatted, cleanedTerm));
        searchResults = this.getMatches(searchResults, cleanedTerm);

        const vehicleTypeToHide = ['SEDAN', 'BR'];

        const vehicleType = this.form.controls.vehicleType.value.id;
        if (vehicleTypeToHide.includes(vehicleType)) {
          searchResults = searchResults.filter(obj => obj.id !== 'MV');
        }

        const serviceType = this.form.controls.serviceType.value.id;
        if (serviceType === 'MV') {
          searchResults = searchResults.filter(obj => !vehicleTypeToHide.includes(obj.id));
        }

        return searchResults.length > 0 ? searchResults : [null];
      })
    );

  public selectedItem($event): void {
    $event.preventDefault();
    this.model = '';
    const itemIndex = this.listOfTags.findIndex(driverTag => driverTag.name === $event.item.name);
    if (itemIndex !== -1) {
      const item = this.listOfTags[itemIndex];
      item.selected = !item.selected;
      this.updateSelectedTags();
    }

    const vehicleTag = this.vehicleTypes.find(vt => vt.name === $event.item.name);
    if (vehicleTag) {
      this.form.controls.vehicleType.setValue(vehicleTag);
    }

    const serviceTag = this.serviceTypes.find(st => st.name === $event.item.name);
    if (serviceTag) {
      this.form.controls.serviceType.setValue(serviceTag);
    }

    this.onTagSelected();
  }

  public uncheckTag(unselectedTag: ITag): void {
    const index = this.listOfTags.findIndex(driverTag => driverTag === unselectedTag);
    if (index !== -1) {
      this.listOfTags[index].selected = false;
    }
    this.onTagSelected();
  }

  public uncheckAllTags() {
    this.listOfTags.forEach((tag: ITag) => (tag.selected = false));
    this.driversList.forEach((tag: ITag) => (tag.selected = false));
    this.vehiclesList.forEach((tag: ITag) => (tag.selected = false));
    this.paymentList.forEach((tag: ITag) => (tag.selected = false));
    this.onTagSelected();
  }

  public updateSelectedTags() {
    this.selectedTags = this.listOfTags.filter(tag => tag.selected);
  }

  public resetMandatoryTags() {
    this.form.controls.vehicleType.setValue(defaultTags.vehicle);
    this.form.controls.serviceType.setValue(defaultTags.service);
    this.setChangeTag.emit();
  }

  public updateListOfTags() {
    const allTagLists = [this.driversList, this.vehiclesList, this.paymentList];
    allTagLists.forEach(list => {
      list.forEach((tag: ITag) => {
        if (tag.selected && !this.listOfTags.includes(tag)) {
          this.listOfTags.push(tag);
        }
      });
    });
  }

  public onTagSelected(): void {
    this.updateListOfTags();
    this.setChangeTag.emit();
    this.updateSelectedTags();
  }

  public checkMenuState() {
    this.isCollapsed = !this.isCollapsed;
    this.model = '';
    const panelIds = this.accordionItems.map(ngbPanel => ngbPanel.id);
    if (panelIds) {
      for (const panelId of panelIds) {
        this.accordion?.collapse(panelId);
      }
    }
  }

  public isServiceTypeTagDisabled(option: { id: string; value: string }): boolean {
    const vehicleType = this.form.controls.vehicleType.value;
    const { MV, SEDAN, BR } = { ...SERVICE_TYPE_TAG, ...VEHICLE_TYPE_TAG };

    return option.id === MV && (vehicleType.id === SEDAN || vehicleType.id === BR);
  }

  public isVehicleTypeTagDisabled(option: ServiceTagsOptions<VehicleTypeTitle, string>): boolean {
    const serviceType = this.form.controls.serviceType.value;
    return this.isTagTypeDisabled({ tagId: option.id as TagTypeName, typeId: serviceType.id });
  }

  public isTagTypeDisabled({ tagId, typeId }: { tagId: TagTypeName; typeId: string }): boolean {
    const { SEDAN, BR, MV } = { ...SERVICE_TYPE_TAG, ...VEHICLE_TYPE_TAG };

    return includes([SEDAN, BR], tagId) && typeId === MV;
  }

  public compareFn(c1: { id: string; value: string | null }, c2: { id: string; value: string | null }): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
