import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { IColor, IEnergy, IMake, IModel, IVehicle } from '../../models/vehicle.model';

@Injectable({ providedIn: 'root' })
export class VehicleService extends ApiService {
  public vehicleUrl = 'user/api/v1/vehicle/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getDriverVehicle(driverId: number): Observable<IVehicle[]> {
    return this.get(`${this.vehicleUrl}driver/${driverId}`);
  }

  public updateVehicle(vehicle) {
    return this.put(`${this.vehicleUrl}${vehicle.id}`, vehicle);
  }

  public getMakes(): Observable<IMake[]> {
    return this.get(`${this.vehicleUrl}make`);
  }

  public getModelByMake(makeId: string): Observable<IModel[]> {
    return this.get(`${this.vehicleUrl}model/make/${makeId}`);
  }

  public getEnergies(): Observable<IEnergy[]> {
    return this.get(`${this.vehicleUrl}energy`);
  }

  public getCapacity() {
    return this.get(`${this.vehicleUrl}capacity`);
  }

  public getCapacityPackage() {
    return this.get(`${this.vehicleUrl}capacityPackage`);
  }

  public getColors(): Observable<IColor[]> {
    return this.get(`${this.vehicleUrl}color`);
  }
}
