<input
  #input
  pInputText
  type="tel"
  prefix="+"
  placeholder="+"
  thousandSeparator="-"
  [id]="inputId"
  [attr.data-test-id]="dataTestId"
  [attr.aria-labelledby]="inputId + 'Error'"
  [mask]="phoneMask$ | async"
  [keepCharacterPositions]="true"
  [disabled]="disabled$ | async"
  [ngModel]="value$ | async"
  (ngModelChange)="setValue(input)"
  (focusin)="touch()"
  (blur)="setValue(input)"
  (paste)="onPaste($event)"
/>
