import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUrls } from '@core/constant/route-urls';

import { PhoneAdvisorOrder } from '../../../../models/phone-advisor-order';

@Component({
  selector: 'azz-customer-current-orders',
  templateUrl: './customer-current-orders.component.html',
  styleUrls: ['./customer-current-orders.component.less'],
})
export class CustomerCurrentOrdersComponent {
  @Input() customerCurrentOrdersData: PhoneAdvisorOrder[];
  @Output() loadNextPage = new EventEmitter();
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(private readonly router: Router) {}

  public redirectToOrderPage(order: PhoneAdvisorOrder): void {
    void this.router.navigate([]).then(_result => {
      window.open(`#${RouteUrls.dash.phoneAdvisor}/orders/${order.id}`, '_blank');
    });
  }

  public redirectToDriverPage(order: PhoneAdvisorOrder): void {
    void this.router.navigate([]).then(_result => {
      window.open(`#${RouteUrls.dash.phoneAdvisor}/drivers/${order.driverId}`, '_blank');
    });
  }

  onScroll() {
    this.loadNextPage.emit();
  }
}
