@if (!!listOfTags && listOfTags.length > 0) {
  <div class="azz-control-group">
    <div class="azz-header">
      {{ 'PA_CREATE_NEW_ORDER_COMPLEMENTS_AND_TAGS_HEADER' | translate }}
    </div>
    <div class="row">
      <div class="order-tag form-group col-md-4" *ngFor="let tag of getAllTags()">
        <input
          [disabled]="isTagsDisabled"
          [id]="'tag' + tag.id"
          type="checkbox"
          [(ngModel)]="tag.selected"
          [value]="tag"
          [ngModelOptions]="{ standalone: true }"
          [name]="'tag' + tag.id"
          (change)="onChangeTag()"
        />
        <label [for]="'tag' + tag.id">
          {{ 'CRC_TAG_' + tag.id | translate }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <button tabindex="-1" class="toggle-tags-shown" *ngIf="!isAllTagsAvailable" (click)="toggleTagsShown()">
          {{ 'SHOW_ALL' | translate }}
        </button>
        <button tabindex="-1" class="toggle-tags-shown" *ngIf="isAllTagsAvailable" (click)="toggleTagsShown()">
          {{ 'HIDE' | translate }}
        </button>
      </div>
    </div>
  </div>
}
