import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from '@services/root.service';
import { FeatureCollection } from 'geojson';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, filter, finalize, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ChangePoiErrorDialogComponent } from './change-poi-error-dialog/change-poi-error-dialog.component';
import { DeletePointOfInterestDialogComponent } from './delete-point-of-interest-dialog/delete-point-of-interest-dialog.component';
import { ImportPoiErrorDialogComponent } from './import-points-of-interest-dialog/import-poi-error-dialog/import-poi-error-dialog.component';
import { ImportPointsOfInterestDialogComponent } from './import-points-of-interest-dialog/import-points-of-interest-dialog.component';
import { MapPointsOfInterestComponent } from './map-points-of-interest/map-points-of-interest.component';
import { SearchPointOfInterestComponent } from './search-point-of-interest/search-point-of-interest.component';
import { TablePointsOfInterestComponent } from './table-points-of-interest/table-points-of-interest.component';
import { ROLE } from '../../constant/role.constant';
import { IFleetParametersForOtherUser } from '../../models/fleet.model';
import { IGetPoiDataParams, IPoi, IPoiData } from '../../models/poi.model';
import { GeoAreaService } from '../../services/common/geoArea.service';
import { PaginationService } from '../../services/common/pagination.service';
import { PoiService } from '../../services/common/poi.service';
import { FleetService } from '../../services/users/fleet.service';
import { UserService } from '../../services/users/user.service';

@Component({
  selector: 'azz-points-of-interest',
  templateUrl: './points-of-interest.component.html',
  styleUrls: ['./points-of-interest.component.less'],
  providers: [NgOnDestroyService],
})
export class PointsOfInterestComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly rootService = inject(RootService);

  @Input() createPoiUrl: string;
  @Input() editPoiUrl: string;
  @Input() hasRestrictionToShowG7Poi: boolean;
  @ViewChild(ImportPointsOfInterestDialogComponent)
  importPoiDialog: ImportPointsOfInterestDialogComponent;
  @ViewChild(DeletePointOfInterestDialogComponent)
  deletePoiDialog: DeletePointOfInterestDialogComponent;
  @ViewChild(SearchPointOfInterestComponent) searchPoiComponent: SearchPointOfInterestComponent;
  @ViewChild(MapPointsOfInterestComponent) mapPoiComponent: MapPointsOfInterestComponent;
  @ViewChild(TablePointsOfInterestComponent) tablePoiComponent: TablePointsOfInterestComponent;
  @ViewChild(ChangePoiErrorDialogComponent) changePoiErrorDialog: ChangePoiErrorDialogComponent;
  @ViewChild(ImportPoiErrorDialogComponent) importPoiErrorDialog: ImportPoiErrorDialogComponent;
  public watcher$ = new Subject<number | null>();
  public tableLoadingIndicator = false;
  public mapLoadingIndicator = false;
  public poiTableData: IPoiData;
  public poiMapData: any;
  public downloadCsvUrl = 'order/api/v1/poi/import/POIImportExample.csv';
  public filterData: any;
  public pageTableHistory: any;
  private readonly REQUEST_DELAY_MS = 300;
  private businessZoneId: string;

  constructor(
    private readonly translate: TranslateService,
    private readonly poiService: PoiService,
    private readonly router: Router,
    private readonly paginationService: PaginationService,
    private readonly cd: ChangeDetectorRef,
    private readonly zone: NgZone,
    private readonly userService: UserService,
    private readonly fleetService: FleetService,
    private readonly geoAreaService: GeoAreaService,
    private readonly store: Store,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  ngOnInit() {
    this.initData();
    this.initWatcher();
    this.watcher$.next(null);
    this.getPoiMapData();
    if (this.isFleetUser() || this.isPhoneAdvisor()) {
      this.loadFleetBusinessZoneGeoJSON();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.listenForPoiCreationData();
      this.listenForPoiUpdateData();
    }, 0);
  }

  public exportBusinessZone() {
    return this.geoAreaService.exportBusinessZone(this.businessZoneId).pipe(filter(val => !!val));
  }

  public initWatcher(): void {
    this.watcher$
      .pipe(
        debounceTime(this.REQUEST_DELAY_MS),
        tap(() => this.enableTableLoadingIndicator(true)),
        takeUntil(this.destroyed$),
        switchMap((page: number) => this.getPoiTableDataObservable$(page)),
        tap(() => this.enableTableLoadingIndicator(false))
      )
      .subscribe((res: IPoiData) => (this.poiTableData = res));
  }

  public initData(): void {
    this.filterData = {
      label: null,
      serviceLevel: null,
    };
    this.pageTableHistory = this.paginationService.createPageHistory();
  }

  public onPoiSuccessfullyImport(): void {
    this.rootService.alerts.success('SAVE_CHANGES_NOTIFICATION');
    this.watcher$.next(null);
    this.getPoiMapData();
  }

  public onPoiImportError(poiList: string[]): void {
    this.importPoiErrorDialog.showDialog(poiList);
  }

  public onPoiClick(poi: IPoi): void {
    if (this.poiMapData) {
      this.searchPoiComponent.setPoiLabel(poi.label);
      this.filterData.label = poi.label;
      this.filterData.serviceLevel = poi.serviceLevel;
      this.mapPoiComponent.showMarkerOnTableClick(poi.id);
      this.mapPoiComponent.setMarkerView([poi.position.latitude, poi.position.longitude]);
      this.watcher$.next(null);
      this.pageTableHistory.reset(); // Temporary version of resolving pagination bug
    }
  }

  public onDeletePoi(poi: IPoi): void {
    this.zone.run(() => {
      this.deletePoiDialog.showDialogWithValueRetrieving('', this.translate.instant('POI_PRE_DELETE_DIALOG_TEXT'), poi);
    });
  }

  public deletePoi(data: { value: IPoi }): void {
    this.poiService
      .deletePoi(data.value.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.deletePoiDialog.closeAfterSuccess();
          this.watcher$.next(null);
          this.mapPoiComponent.removeMarkerFromMap(data.value.id);
          this.rootService.alerts.success('SAVE_CHANGES_NOTIFICATION');
        },
        (err: HttpErrorResponse) => this.handleDeletePoiOrderExistsError(err, data.value)
      );
  }

  public navigateToEditPage(poiId: string): void {
    this.zone.run(() => this.router.navigate([this.editPoiUrl, poiId]));
  }

  public filterPoiByMarker(data: { label: string; serviceLevel: string }): void {
    this.filterData.label = data.label;
    this.filterData.serviceLevel = data.serviceLevel;
    this.watcher$.next(null);
    this.searchPoiComponent.setPoiLabel(this.filterData.label);
    this.tablePoiComponent.setFilteredByPoiRowClickValue(true);
  }

  public resetFilterPoiByMarker(): void {
    this.filterData.label = null;
    this.filterData.serviceLevel = null;
    this.watcher$.next(null);
    this.searchPoiComponent.setPoiLabel(this.filterData.label);
    this.tablePoiComponent.setFilteredByPoiRowClickValue(false);
  }

  public filterPoiByLabel(poiLabel: string): void {
    if (!poiLabel) {
      this.filterData.label = poiLabel;
      this.filterData.serviceLevel = null;
      this.mapPoiComponent.resetSelectedMarker();
      this.tablePoiComponent.setFilteredByPoiRowClickValue(false);
    } else {
      this.filterData.label = poiLabel;
    }
    this.watcher$.next(null);
  }

  public getPoiTableDataObservable$(page?: number): Observable<IPoiData> {
    this.customDetectChanges();
    return this.poiService.getPoiData(this.generateParams(5, page)).pipe(
      takeUntil(this.destroyed$),
      catchError(() => {
        this.poiTableData = null;
        this.enableTableLoadingIndicator(false);
        return EMPTY;
      }),
      finalize(() => this.customDetectChanges())
    );
  }

  public customDetectChanges(): void {
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  public getPoiMapDataObservable$(page?: number): Observable<IPoiData> {
    this.enableMapLoadingIndicator(true);
    return this.poiService.getPoiData(this.generateParams(100, page)).pipe(
      takeUntil(this.destroyed$),
      catchError(() => {
        this.poiMapData = null;
        return EMPTY;
      }),
      finalize(() => this.enableMapLoadingIndicator(false))
    );
  }

  public getPoiMapData(): void {
    this.getPoiMapDataObservable$().subscribe((res: IPoiData) => (this.poiMapData = res));
  }

  public openImportPoiDialog() {
    this.importPoiDialog.showDialog(this.translate.instant('POI_IMPORT_DIALOG_HEADER'), '');
  }

  public navigateToCreatePoiPage(): void {
    this.router.navigate([this.createPoiUrl]);
  }

  public enableTableLoadingIndicator(value: boolean): void {
    this.tableLoadingIndicator = value;
  }

  public enableMapLoadingIndicator(value: boolean): void {
    this.mapLoadingIndicator = value;
  }

  public prevTablePage(): void {
    this.watcher$.next(this.getCurrentTablePage() - 1);
  }

  public nextTablePage(): void {
    this.watcher$.next(this.getCurrentTablePage() + 1);
  }

  public isPrevTableBtnDisabled(): boolean {
    return !this.poiTableData || this.poiTableData.first;
  }

  public isNextTableBtnDisabled(): boolean {
    return !this.poiTableData || this.poiTableData.last;
  }

  ngOnDestroy() {
    this.poiService.setFleetBusinessZoneGeoJSON(null);
  }

  private loadFleetBusinessZoneGeoJSON() {
    this.loadFleetParamsByOtherUser()
      .pipe(
        takeUntil(this.destroyed$),
        mergeMap((res: IFleetParametersForOtherUser) => {
          this.businessZoneId = res.geoArea.id;
          return this.exportBusinessZone();
        })
      )
      .subscribe((res: FeatureCollection) => {
        this.setFleetBusinessZone(res);
      });
  }

  private loadFleetParamsByOtherUser(): Observable<IFleetParametersForOtherUser> {
    const fleetId = this.userService.getCurrentUserInfo().fleet.id;
    return this.fleetService.getFleetParametersForOtherUser(fleetId);
  }

  private isFleetUser(): boolean {
    const user = this.userService.getCurrentUserInfo().user;
    const userRoles = user ? user.roles : [];
    return userRoles.indexOf(ROLE.FLEET_OWNER) !== -1 || userRoles.indexOf(ROLE.FLEET_ADMIN) !== -1;
  }

  private isPhoneAdvisor(): boolean {
    const user = this.userService.getCurrentUserInfo().user;
    const userRoles = user ? user.roles : [];
    return userRoles.indexOf(ROLE.PHONE_ADVISOR) !== -1;
  }

  private setFleetBusinessZone(data: FeatureCollection): void {
    if (data.features && data.features.length) {
      this.poiService.setFleetBusinessZoneGeoJSON(data.features[0]);
    }
  }

  private listenForPoiCreationData(): void {
    this.poiService
      .getIsPoiCreatedValue()
      .pipe(
        takeUntil(this.destroyed$),
        filter(value => !!value)
      )
      .subscribe(() => {
        this.rootService.alerts.success('SAVE_CHANGES_NOTIFICATION');
        this.poiService.setIsPoiCreatedValue(false);
      });
  }

  private listenForPoiUpdateData(): void {
    this.poiService
      .getIsPoiUpdatedValue()
      .pipe(
        takeUntil(this.destroyed$),
        filter(value => !!value)
      )
      .subscribe(() => {
        this.rootService.alerts.success('SAVE_CHANGES_NOTIFICATION');
        this.poiService.setIsPoiUpdatedValue(false);
      });
  }

  private handleDeletePoiOrderExistsError(error: HttpErrorResponse, poi: IPoi): void {
    this.deletePoiDialog.closeAfterFailure();
    const {
      displayedInCrcService = false,
      displayedInDriverApp = false,
      displayedInPassengerApp = false,
    } = { ...error.error };
    const hasDisplayInValue = displayedInCrcService || displayedInDriverApp || displayedInPassengerApp;
    if (
      error.status === 409 &&
      (error.error.reason === 'poi.order.exists' || error.error.reason === 'displayIn.is.true')
    ) {
      this.changePoiErrorDialog.showDialog('POI_CANNOT_UPDATE_POI_ERROR_TITLE', {
        poi,
        fleets: error.error.fleets,
        hasDisplayInValue,
      });
    } else {
      throw error;
    }
  }

  private generateParams(size: number = 5, page?: number): Partial<IGetPoiDataParams> {
    return { label: this.filterData.label, serviceLevel: this.filterData.serviceLevel, size, page };
  }

  private getCurrentTablePage(): number {
    return this.poiTableData ? this.poiTableData.number : 0;
  }
}
