import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrderService } from '@core/services/orders';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

export enum EOrderType {
  All,
  Immediate,
  Reservation,
  Ongoing,
}

@Component({
  selector: 'azz-orders-tab',
  standalone: true,
  imports: [TranslateModule, AsyncPipe, NgIf, NgForOf, NgClass],
  templateUrl: './orders-tab.component.html',
  styleUrls: ['./orders-tab.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersTabComponent {
  private readonly orderService = inject(OrderService);

  @Output() public readonly typeChanged = new EventEmitter<EOrderType>();

  private readonly counts$ = this.orderService.getOrdersCountsWithTypes().pipe(
    map(({ order, ongoing, reservation }) => {
      return {
        all: order + reservation,
        ongoing,
        order,
        reservation,
      };
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  protected readonly selectedType$ = new BehaviorSubject<number>(EOrderType.Ongoing);

  protected readonly tabs$ = this.counts$.pipe(
    map(counts => {
      return [
        {
          type: EOrderType.All,
          locale: 'ORDERS_TAB_ALL',
          count: counts.all,
        },
        {
          type: EOrderType.Immediate,
          locale: 'ORDERS_TAB_IMMEDIATE',
          count: counts.order,
        },
        {
          type: EOrderType.Reservation,
          locale: 'ORDERS_TAB_RESERVATIONS',
          count: counts.reservation,
        },
        {
          type: EOrderType.Ongoing,
          locale: 'ORDERS_TAB_IN_PROGRESS',
          count: counts.ongoing,
        },
      ];
    })
  );

  constructor() {
    this.selectedType$.pipe(distinctUntilChanged(), takeUntilDestroyed()).subscribe(type => {
      this.typeChanged.emit(type);
    });
  }
}
