import { UIAddress } from '@core/modules/base-create-order/interfaces';
import { OrderFormField } from '@core/modules/base-create-order/types';
import { createAction, props } from '@ngrx/store';

import { AllowedFieldType } from '../reducers/order-form.reducer';

export enum OrderFormActions {
  SAVE_ALL = '[Order Form] save values before leaving the page',
  CLEAR_ALL_FIELDS = '[Order Form] clear all saved values',
  SET_ALLOWED_FIELDS = '[Order Form] set allowed fields for order form',
}

export const SAVE_ALL_FIELDS = createAction(
  OrderFormActions.SAVE_ALL,
  props<{
    saved: Partial<
      OrderFormField & {
        address: Partial<{
          arrival: UIAddress | null;
          departure: UIAddress | null;
        }>;
      }
    >;
  }>()
);
export const CLEAR_ALL_FIELDS = createAction(OrderFormActions.CLEAR_ALL_FIELDS);
export const SET_ALLOWED_FIELDS = createAction(
  OrderFormActions.SET_ALLOWED_FIELDS,
  props<{ allowedFields: AllowedFieldType[] }>()
);

export const orderFormActions = {
  SAVE_ALL_FIELDS,
  CLEAR_ALL_FIELDS,
  SET_ALLOWED_FIELDS,
};
