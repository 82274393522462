import { IAuthData, ILogoutData } from '@core/models/auth.model';
import { IShortError } from '@core/models/common.model';
import { ICurrentUserInfo } from '@core/models/user-info.model';
import { createAction, props } from '@ngrx/store';

export const login = createAction('[Auth] Login', props<{ authData: IAuthData; fleetId?: number }>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ fleetId?: number }>());
export const loginError = createAction('[Auth] Login Error', props<{ error: IShortError }>());

export const handleUserInfo = createAction(
  '[Auth] Handle User Info',
  props<{ userInfo: ICurrentUserInfo; fleetId?: number }>()
);
export const setUserInfo = createAction('[Auth] Set User Info', props<{ userInfo: ICurrentUserInfo }>());
export const userInfoError = createAction('[Auth] User Info Error');

export const firebaseSignIn = createAction('[Auth] Firebase SignIn');
export const firebaseSignInSuccess = createAction('[Auth] Firebase SignIn Success');
export const firebaseSignInError = createAction('[Auth] Firebase SignIn Error');

export const tryLogout = createAction(
  '[Auth] Try Logout',
  (payload: Partial<ILogoutData> = {} as ILogoutData) => payload
);

export const logout = createAction('[Auth] Logout', (payload: Partial<ILogoutData> = {} as ILogoutData) => payload);
export const logoutSuccess = createAction('[Auth] Logout Success', props<{ route: string }>());
export const logoutError = createAction('[Auth] Logout Error');

export const toggleLogoutPopup = createAction('[Auth] Toggle Logout Popup', props<{ isLogoutPopupShown: boolean }>());

export const togglePhoneAdvisorAccountDeactivated = createAction(
  '[Auth] Phone Advisor Deactivated',
  props<{ isPhoneAdvisorDeactivated: boolean }>()
);

export const toggleSessionExpired = createAction('[Auth] Session Expired', props<{ isSessionExpired: boolean }>());
