<input
  [id]="'top-' + i"
  type="checkbox"
  [name]="'top-' + i"
  [checked]="selectedOptions[option.id]"
  (change)="toggleOption(option)"
/>
<label [for]="'top-' + i" class="option__label">
  <span *ngFor="let field of optionFormatFields; let last = last">
    {{ option[field] }}<span *ngIf="!last"> </span>
  </span>
</label>
