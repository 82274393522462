import { IObjectMap } from '@core/models/common.model';
import { G7Order, IG7OrdersData, IG7OrdersParams } from '@core/models/g7-order.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isValidationAllowed } from '../../components/common/utils';
import * as G7OrdersReducer from '../reducers';

export const selectG7Orders = createFeatureSelector<G7OrdersReducer.State>(G7OrdersReducer.g7OrdersFeatureKey);

export const selectG7OrdersData = createSelector(selectG7Orders, (state: G7OrdersReducer.State) => state.ordersData);

export const selectG7OrdersDataAllValidatable = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.ordersDataAllValidatable
);

export const selectG7OrdersDataAllValidatableSelected = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.ordersDataAllValidatableSelected
);

export const selectG7OrdersAllowedValidation = createSelector(
  selectG7OrdersData,
  (state: IG7OrdersData) => state?.content?.filter(isValidationAllowed)
);

export const selectSelectedOrders = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.selectedOrders
);

export const multiValidationPagination = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiValidationPagination
);

export const multiReturnPagination = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiReturnPagination
);

export const multiValidationOrders = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiValidationOrders
);

export const multiReturnOrders = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiReturnOrders
);

export const multiValidationTableLoading = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiValidationTableLoading
);

export const multiReturnTableLoading = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiReturnTableLoading
);

export const multiValidationLoading = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiValidationLoading
);

export const multiReturnLoading = createSelector(
  selectG7Orders,
  (state: G7OrdersReducer.State) => state.multiReturnLoading
);

export const selectedOrdersIds = createSelector(selectSelectedOrders, (state: IObjectMap<boolean>) =>
  Object.keys(state)
    .filter((orderId: string) => state[orderId])
    .map((id: string) => Number(id))
);

export const isSelectedAllOrders = createSelector(
  selectSelectedOrders,
  selectG7OrdersAllowedValidation,
  (selectedOrders: IObjectMap<boolean>, allowedValidationOrders: G7Order[]) => {
    return (
      allowedValidationOrders?.length > 0 &&
      allowedValidationOrders?.every((order: G7Order) => selectedOrders[order.tripNumber])
    );
  }
);

export const selectedOrdersLength = createSelector(selectedOrdersIds, (state: number[]) => state.length);

export const atLeastOneSelected = createSelector(selectSelectedOrders, (state: IObjectMap<boolean>) =>
  Object.values(state).some(Boolean)
);

export const atLeastOneSelectedOnPage = createSelector(
  selectSelectedOrders,
  selectG7OrdersAllowedValidation,
  (selectedOrders: IObjectMap<boolean>, allowedValidationOrders: G7Order[]) =>
    (allowedValidationOrders?.length > 0 &&
      allowedValidationOrders?.some((order: G7Order) => selectedOrders[order.tripNumber])) ||
    false
);

export const allSelectedOnPage = createSelector(
  selectSelectedOrders,
  selectG7OrdersAllowedValidation,
  (selectedOrders: IObjectMap<boolean>, allowedValidationOrders: G7Order[]) =>
    (allowedValidationOrders?.length > 0 &&
      allowedValidationOrders?.every(order => selectedOrders[order.tripNumber])) ||
    false
);

export const selectG7OrdersLoading = createSelector(selectG7Orders, state => state.loading);

export const selectG7OrdersFilterModel = createSelector(selectG7Orders, state => state.filterModel);

export const selectG7OrdersPagination = createSelector(selectG7Orders, state => state.pagination);

export const selectG7OrdersSorting = createSelector(selectG7Orders, state => state.sorting);

export const selectG7OrdersParams = createSelector(
  selectG7OrdersFilterModel,
  selectG7OrdersPagination,
  selectG7OrdersSorting,
  (filterModel, pagination, sorting) =>
    ({
      ...filterModel,
      ...pagination,
      ...sorting,
    }) as Partial<IG7OrdersParams>
);

export const selectDispatchPagination = createSelector(selectG7Orders, state => state.dispatchPagination);

export const selectDispatchFullFilterModel = createSelector(
  selectG7OrdersFilterModel,
  selectDispatchPagination,
  (filterModel, dispatchPagination) => ({ ...filterModel, ...dispatchPagination })
);

export const selectDispatchOrdersBody = createSelector(selectG7OrdersFilterModel, state => state);

export const selectDispatchOrdersData = createSelector(selectG7Orders, state => state.dispatchOrdersData);

export const selectDispatchTableLoading = createSelector(selectG7Orders, state => state.dispatchTableLoading);

export const selectDispatchLoading = createSelector(selectG7Orders, state => state.dispatchLoading);

export const selectG7OrdersCsvParams = createSelector(
  selectG7OrdersFilterModel,
  selectG7OrdersSorting,
  (filterModel, sorting) => ({
    ...filterModel,
    ...sorting,
  })
);

export const selectG7OrdersDataAllValidatableSelectedDisabled = createSelector(
  selectG7Orders,
  isSelectedAllOrders,
  (state: G7OrdersReducer.State, isSelectedAll: boolean) =>
    state.ordersData?.content.length ? !isSelectedAll || state.disableDataAllValidatableSelected : false
);

export const selectG7OrdersDataSelectedOrders = createSelector(selectG7Orders, (state: G7OrdersReducer.State) => {
  if (!state.ordersData) {
    return [];
  }

  if (!state.ordersDataAllValidatableSelected) {
    return state.ordersData.content.filter(order => state.selectedOrders[order?.tripNumber]);
  }

  if (!state.ordersDataAllValidatable) {
    return [];
  }

  if (!state.filterModel.processingStatus) {
    return state.ordersDataAllValidatable.content;
  }

  const filterStatus = Array.isArray(state.filterModel.processingStatus)
    ? state.filterModel.processingStatus
    : [state.filterModel.processingStatus];

  if (!filterStatus.length) {
    return state.ordersDataAllValidatable.content;
  }

  return state.ordersDataAllValidatable.content.filter(item =>
    filterStatus.some(status => item.processingStatus === status)
  );
});

export const selectG7OrdersDataSelectedOrdersCount = createSelector(
  selectG7OrdersDataSelectedOrders,
  selectedOrders => {
    return selectedOrders.length;
  }
);
