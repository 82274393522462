import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ICustomer, ICustomerPhone } from '@core/models';
import { IEmail } from '@core/models/fleet.model';
import { UtilService } from '@core/services/common';
import { CustomerBlockReasonType } from '@dash/modules/phone-advisor/common/constants';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

@Component({
  selector: 'azz-customer-summary-block',
  templateUrl: './customer-summary-block.component.html',
  styleUrls: ['./customer-summary-block.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSummaryBlockComponent implements OnChanges {
  @Input() customerData: ICustomer;
  @Input() customersRoute: string;
  @Output() setCreateOrderState = new EventEmitter<null>();
  public customerBlockTime: string;
  public customerBlockReason: string;

  constructor(
    private readonly router: Router,
    private readonly utilService: UtilService,
    private readonly translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const hasBlockFields =
      this.customerData.hasOwnProperty('blockTime') && this.customerData.hasOwnProperty('blockReason');
    if (!!changes.customerData && hasBlockFields) {
      this.customerBlockTime = this.getBlockTime();
      this.customerBlockReason = this.getBlockReason();
    }
  }

  get customerFullName(): string {
    return this.utilService.tagRemoveFalsyValueFunc`${this.customerData.name} ${this.customerData.lastName}`;
  }

  getBlockReason(): string {
    switch (this.asBlocked().blockReason) {
      case 'PROD':
        return this.translate.instant('FLEET_PROD_REASON') as string;
      case 'INVOICE':
        return this.translate.instant('FLEET_INVOICE_REASON') as string;
      default:
        return '';
    }
  }

  getBlockTime(): string {
    const date = dayjs(this.asBlocked().blockTime);
    return this.asBlocked().blockTime ? date.format('DD.MM.YYYY') + ', <br/>' : '';
  }

  public editCustomer(): void {
    this.setCreateOrderState.emit(null);
    this.router.navigate([this.customersRoute, this.customerData.id]);
  }

  public getSortedPhonesByOrderNumber(): ICustomerPhone[] {
    return this.customerData.phones.sort((a, b) => a.orderNumber - b.orderNumber);
  }

  public getSortedEmailsByOrderNumber(): IEmail[] {
    return this.customerData.emails.sort((a, b) => a.orderNumber - b.orderNumber);
  }

  private asBlocked(): Blocked {
    return this.customerData as unknown as Blocked;
  }
}

interface Blocked {
  blockTime: string;
  blockReason: CustomerBlockReasonType;
}
