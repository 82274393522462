import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import { PhoneAdvisorOrder } from '../../../../models/phone-advisor-order';

@Component({
  selector: 'azz-customer-previous-order',
  templateUrl: './customer-previous-order.component.html',
  styleUrls: ['./customer-previous-order.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbCarouselConfig],
})
export class CustomerPreviousOrderComponent {
  @Input() customerPrevOrders: PhoneAdvisorOrder[];
  @Output() setPreviousOrderData$ = new EventEmitter<PhoneAdvisorOrder>();
  public showNavigationArrows = false;
  public showNavigationIndicators = true;

  constructor(config: NgbCarouselConfig) {
    config.interval = 0;
  }

  public onPreviousOrderClick(order: PhoneAdvisorOrder): void {
    this.setPreviousOrderData$.emit(order);
  }
}
