import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IOrderLogsData, IOrderLogsParams, IOrderValidResponse } from '../../models/order-logs.model';
import { IGetOrdersParams, IOrder, IOrderData, IOrderValidateParams, IShortOrder } from '../../models/order.model';
import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class OrderService extends ApiService {
  private readonly API_URL = 'order/api/v1/order/';

  constructor(http: HttpClient) {
    super(http);
  }

  public getOrders(params: Partial<IGetOrdersParams>): Observable<IOrderData> {
    return this.get(this.API_URL, params);
  }

  public getOrderById(orderId: string): Observable<IOrder> {
    return this.get(`${this.API_URL}${orderId}`);
  }

  public getPaOrderById(orderId: string): Observable<IOrder> {
    return this.get(`${this.API_URL}phone/advisor/${orderId}`);
  }

  public savePrice(id, price): any {
    return this.post(`${this.API_URL}${id}/price`, { price: Number(price) });
  }

  public getDriverFutureOrders(driverId, limit) {
    return this.get(`${this.API_URL}driver/${driverId}/future`, { limit });
  }

  public getDriverLastOrders(driverId: number): Observable<IOrder[]> {
    return this.get(`${this.API_URL}driver/${driverId}/last`);
  }

  public getOrderHistory(params: Partial<IOrderLogsParams>): Observable<IOrderLogsData> {
    return this.get(`${this.API_URL}modification/history`, params);
  }

  public exportTransmissionHistoryCsv(params?: any) {
    const queryParams = this.createQueryParams(params);
    return `${this.API_URL}csv${queryParams}`;
  }

  public validate(params: IOrderValidateParams): Observable<IOrderValidResponse[]> {
    return this.put(`${this.API_URL}validate`, params);
  }

  public getShortOrders(params: { id: string[] }): Observable<IShortOrder[]> {
    return this.get(`${this.API_URL}validate`, params);
  }

  public getOrdersCountsWithTypes(): Observable<{
    ongoing: number;
    order: number;
    reservation: number;
  }> {
    return this.get(`${this.API_URL}count/by/type`);
  }
}
