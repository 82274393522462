import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-registration',
  templateUrl: './pre-registration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreRegistrationComponent implements OnInit {
  @Input() createUiSref: any;
  @Input() facebookUrl: string;
  @Input() paramName: string;
  @Input() paramValue: string;
  @Input() paramNameForCreatePage: string;
  public queryParams: { [k: string]: any };

  ngOnInit() {
    const params = {};
    params[this.paramNameForCreatePage || this.paramName] = this.paramValue;
    this.queryParams = params;
  }
}
