<div class="modal-notification">
  <p [innerHTML]="'MASS_VALIDATION_QUESTION' | translate"></p>
  <p>{{ 'DISPATCH_DIALOG_SUMMARY_HEADER' | translate }}:</p>
  <azz-loading-block [isLoading]="tableLoading$ | async">
    <div class="azz-table-wrapper">
      <table class="table table-hover azz-table">
        <thead>
          <tr class="d-table-row">
            <th class="col-3">{{ 'CITY' | translate }}</th>
            <th class="col-3">{{ 'GO_ORDERS_G7_CODE_HEADER' | translate }}</th>
            <th class="col-3">{{ 'REFERENCE_LABEL' | translate }}</th>
            <th class="col-3">{{ 'TOTAL' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="orders?.length > 0">
          <!-- IE scrolling fix -->
          <tr class="azz-clickable d-table-row" *ngFor="let order of orders">
            <td>{{ order?.fleetCity }}</td>
            <td>{{ order?.fleetG7Id }}</td>
            <td>{{ order?.tripNumber }}</td>
            <td>
              {{ (order?.totalPrice <= 0 ? 0 : order?.totalPrice) + ' \u20AC' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <azz-table-no-matches [azzVisible]="!orders?.length"></azz-table-no-matches>
    <azz-pagination
      (goToPrevPage)="prevPage()"
      (goToNextPage)="nextPage()"
      [prevDisabled]="isPrevDisabled()"
      [nextDisabled]="isNextDisabled()"
    >
    </azz-pagination>
  </azz-loading-block>

  <div class="modal-notification__btn-group">
    <button type="button" class="custom-btn custom-btn--grey mr-3" [disabled]="loading$ | async" (click)="close()">
      {{ 'CANCEL' | translate | titlecase }}
    </button>
    <button
      type="button"
      class="custom-btn custom-btn--green btn-rotate-flex btn-rotate-flex--w-auto"
      [disabled]="loading$ | async"
      (click)="submit()"
    >
      <azz-rotate-indicator7 [visible]="loading$ | async"></azz-rotate-indicator7>
      {{ 'CORE_OK_VALUE' | translate }}
    </button>
  </div>
</div>
