import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUrls } from '@core/constant/route-urls';
import { IShortError } from '@core/models/common.model';
import { UserInfoService } from '@core/services/users/user-info.service';
import { createEffect, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as AuthActions from '../actions';

@Injectable()
export class ForgotPasswordEffects extends EffectsBase {
  private readonly userInfoService = inject(UserInfoService);
  private readonly router = inject(Router);

  public forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      switchMap(({ params }) =>
        this.userInfoService.forgotPassword(params).pipe(
          map(() => AuthActions.forgotPasswordSuccess()),
          catchError((err: HttpErrorResponse) =>
            this.catchErrorHandler(
              err,
              AuthActions.forgotPasswordError({
                error: {
                  status: err?.status,
                  reason: err?.error?.reason,
                } as IShortError,
              })
            )
          )
        )
      )
    )
  );

  public forgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.forgotPasswordSuccess),
        tap(() => {
          this.alertSuccess('RESET_PASSWORD_SUCCESS_TOAST_NOTIFICATION');
        }),
        tap(() => {
          void this.router.navigate([RouteUrls.auth.login]);
        })
      ),
    { dispatch: false }
  );
}
