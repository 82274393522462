import { Attribute, ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'azz-labeled-input',
  template: `
    <label [for]="id" class="azz-transparent-input-label"> {{ label }}</label>
    <input
      class="form-control azz-transparent-input azz-validate"
      type="text"
      [id]="id"
      [required]="isRequired"
      [formControl]="innerControl"
      (blur)="updateParentControl()"
    />
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabeledInputComponent),
      multi: true,
    },
    NgOnDestroyService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledInputComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() maxLength = 1000;
  @Input() isRequired = true;
  public readonly id: string | number;

  public readonly innerControl = new FormControl<string>('');

  onChange: (value: string) => void;
  onTouch: () => void;

  constructor(
    @Attribute('label') label: string,
    @Attribute('formControlName') controlName: string,
    private readonly destroyed$: NgOnDestroyService,
    @Attribute('maxLength') maxLength?: number
  ) {
    this.label = label;
    this.id = controlName;
    this.maxLength = maxLength;
  }
  ngOnInit(): void {
    this.innerControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (!this.onChange) {
        return;
      }

      this.updateParentControl();
    });
  }

  updateParentControl(): void {
    this.onChange(this.innerControl.value);
    this.onTouch();
  }

  writeValue(value: string): void {
    this.innerControl.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.innerControl.disable();
    } else {
      this.innerControl.enable();
    }
  }
}
