import { ActionReducerMap } from '@ngrx/store';

import * as AdvertReducer from './advert.reducer';
import * as ExceptionsCountReducer from './exceptions-count.reducer';
import * as CreateOrderFormSavedValuesReducer from './order-form.reducer';
import * as PendingRequestsReducer from './pending-requests.reducer';
import * as PrevRouteReducer from './prev-route.reducer';
import * as RouterReducer from './router.reducer';
import * as ScheduleReducer from './schedule.reducer';
import * as TimerReducer from './timer.reducer';
import * as UserMessagesReducer from './user-messages.reducer';
import * as UserNotificationsReducer from './user-notifications.reducer';

export interface AppState {
  [PendingRequestsReducer.pendingRequestsFeatureKey]: PendingRequestsReducer.State;
  [ExceptionsCountReducer.exceptionsCountFeatureKey]: ExceptionsCountReducer.State;
  [RouterReducer.routerFeatureKey]: RouterReducer.State;
  [AdvertReducer.advertFeatureKey]: AdvertReducer.State;
  [PrevRouteReducer.prevRouteFeatureKey]: PrevRouteReducer.State;
  [ScheduleReducer.scheduleFeatureKey]: ScheduleReducer.State;
  [UserNotificationsReducer.userNotificationsFeatureKey]: UserNotificationsReducer.State;
  [UserMessagesReducer.userMessagesFeatureKey]: UserMessagesReducer.State;
  [CreateOrderFormSavedValuesReducer.OrderFormFeatureKey]: CreateOrderFormSavedValuesReducer.State;
  [TimerReducer.timerFeatureKey]: TimerReducer.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [PendingRequestsReducer.pendingRequestsFeatureKey]: PendingRequestsReducer.reducer,
  [ExceptionsCountReducer.exceptionsCountFeatureKey]: ExceptionsCountReducer.reducer,
  [RouterReducer.routerFeatureKey]: RouterReducer.reducer,
  [AdvertReducer.advertFeatureKey]: AdvertReducer.reducer,
  [PrevRouteReducer.prevRouteFeatureKey]: PrevRouteReducer.reducer,
  [ScheduleReducer.scheduleFeatureKey]: ScheduleReducer.reducer,
  [UserNotificationsReducer.userNotificationsFeatureKey]: UserNotificationsReducer.reducer,
  [UserMessagesReducer.userMessagesFeatureKey]: UserMessagesReducer.reducer,
  [CreateOrderFormSavedValuesReducer.OrderFormFeatureKey]: CreateOrderFormSavedValuesReducer.reducer,
  [TimerReducer.timerFeatureKey]: TimerReducer.reducer,
};
