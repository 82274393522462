<div class="azz-table-wrapper">
  <table class="table table-hover azz-table">
    <thead>
      <tr class="d-table-row">
        <th class="col-2">{{ 'HOURS' | translate }}</th>
        <th class="col-2">{{ 'PA_EXCEPTION_TYPE_HEADER' | translate }}</th>
        <th class="col-3">{{ 'DRIVER' | translate }}</th>
        <th class="col-3">{{ 'CLIENT' | translate }}</th>
        <th class="col-2">{{ 'PA_EXCEPTION_WAITING_TIME_HEADER' | translate }}</th>
      </tr>
    </thead>
    <tbody *ngIf="exceptionsData?.content?.length > 0">
      <!-- IE scrolling fix -->
      <app-exception-row
        *ngFor="let exception of exceptionsData?.content; trackBy: trackByFunc"
        [exception]="exception"
        (navigateToDriver$)="navigateToDriver$.emit($event)"
        (navigateToCustomer$)="navigateToCustomer$.emit($event)"
        (click)="navigateToException$.emit(exception.orderId)"
      >
      </app-exception-row>
    </tbody>
  </table>
</div>
<azz-table-no-matches [azzVisible]="!exceptionsData?.content?.length"></azz-table-no-matches>
<azz-pagination
  (goToPrevPage)="prevPage()"
  (goToNextPage)="nextPage()"
  [prevDisabled]="isPrevDisabled()"
  [nextDisabled]="isNextDisabled()"
>
</azz-pagination>
