import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@core/services/common/util.service';
import { FormService } from '@core/utils/form-service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'azz-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.less'],
})
export class AttachFileComponent implements OnChanges {
  private readonly formService = inject(FormService);
  private readonly translate = inject(TranslateService);
  private readonly utilService = inject(UtilService);

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @Input() azzId: string;
  @Input() value: any | null;
  @Input() azzAccept: string;
  @Input() azzAcceptMimeType = 'image/png, image/jpeg';
  @Input() acceptedExtensionsArray = ['jpg', 'jpeg', 'png', 'JPG', 'PNG', 'JPEG'];
  @Input() isDisabled: string;
  @Input() ngRequired: any;
  @Input() outputFileName: boolean;
  @Input() hideAttacher: boolean;
  @Input() href: string;
  @Input() hideCloseButton: boolean;
  @Input() typeOrExtensionErrorStr = this.translate.instant('CORE_FILE_TYPE_EXTENSION_INCORRECT');
  @Input() fileUrl: string;
  @Input() fileName: string;
  @Output() updateFile = new EventEmitter();

  public azzUploadedData: boolean = false;
  public attachFileForm: FormGroup;
  public file: File | null;
  public sizeError: boolean;
  public typeOrExtensionError: boolean;

  private readonly ICON_MAP: any;
  private readonly MAX_FILE_SIZE = 10485760; // bytes
  private hasAtLeastOneError = false;

  constructor() {
    this.ICON_MAP = {
      'image/png': 'attach-icon-img.png',
      'image/jpeg': 'attach-icon-img.png',
      'application/pdf': 'attach-icon-pdf.png',
      'application/excel': 'attach-icon-xls.png',
      'application/vnd.ms-excel': 'attach-icon-xls.png',
      'application/x-excel': 'attach-icon-xls.png',
      'application/x-msexcel': 'attach-icon-xls.png',
      'text/csv': 'attach-icon-xls.png',
    };
    this.initForm();
  }

  @Input() set azzUploaded(value: boolean) {
    this.azzUploadedData = value;
    if (value) {
      this.attachFileForm.patchValue({ attachFile: 'link' });
    } else if (!this.value) {
      this.attachFileForm.patchValue({ attachFile: null });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ngRequired && changes.ngRequired.currentValue) {
      this.formService.setValidators(this.attachFileForm, 'attachFile', [Validators.required]);
    }
    if (changes.ngRequired && !changes.ngRequired.currentValue) {
      this.formService.removeValidators(this.attachFileForm, 'attachFile');
    }
  }

  public initForm() {
    this.attachFileForm = new FormGroup({
      attachFile: new FormControl('', []),
    });
  }

  public isFormValid(): boolean {
    return this.attachFileForm.valid;
  }

  public markAsTouched(): void {
    this.attachFileForm.markAllAsTouched();
  }

  public deleteAttachedFile() {
    this.updateFile.emit(null);
    this.resetForm();

    if (this.value) {
      this.value = null;
      this.file = null;
    }
    if (this.azzUploadedData) {
      this.azzUploadedData = false;
    }
  }

  public getAttachedFileIcon() {
    if (this.file) {
      const mimeType = this.file.type;

      if (mimeType) {
        return this.ICON_MAP[mimeType];
      }
    }
  }

  public onFileChange(event: Event) {
    this.resetErrors();
    const files = (event.target as HTMLInputElement).files;
    const reader = new FileReader();

    if (!files || !files.length) {
      return;
    }

    this.file = files[0];
    this.handleErrors();
    if (this.hasAtLeastOneError) {
      this.azzUploadedData = false;
      this.value = null;
      this.resetForm();
      this.updateFile.emit(null);
      return;
    }

    reader.readAsDataURL(this.file);
    this.attachFileForm.patchValue({ attachFile: 'link' });
    reader.onload = () => {
      this.azzUploadedData = true;
      this.value = {};
      this.value.name = this.file.name;
      console.log('on file change, this.value.name: ', this.value.name);

      if (this.outputFileName) {
        this.updateFile.emit({
          file: this.file,
          fileName: this.value.name,
        });
      } else {
        this.updateFile.emit(this.file);
      }
    };
    // Make it possible to trigger change event when adding the same file
    (event.target as HTMLInputElement).value = '';
  }

  public resetForm() {
    this.attachFileForm.reset();
  }

  private handleErrors(): void {
    if (!this.isFileMimeTypeAccepted(this.file)) {
      this.typeOrExtensionError = true;
      this.hasAtLeastOneError = true;
    }
    if (!this.isFileExtensionAccepted()) {
      this.typeOrExtensionError = true;
      this.hasAtLeastOneError = true;
    }
    if (this.file.size > this.MAX_FILE_SIZE) {
      this.sizeError = true;
      this.hasAtLeastOneError = true;
    }
  }

  private isFileExtensionAccepted(): boolean {
    const fileName = this.getFileExtension(this.file.name);
    return this.acceptedExtensionsArray.includes(fileName);
  }

  private getFileExtension(fileName: string) {
    return fileName.split('.').pop();
  }

  private resetErrors(): void {
    this.sizeError = false;
    this.typeOrExtensionError = false;
    this.hasAtLeastOneError = false;
  }

  private isFileMimeTypeAccepted(file: File): boolean {
    const acceptedImageTypes = this.utilService.getAcceptedImageTypesArrFromStr(this.azzAcceptMimeType);
    return file.type ? acceptedImageTypes.includes(file.type) : true;
  }

  download() {
    saveAs(this.file || this.fileUrl, this.value?.name || this.fileName);
  }
}
