<section class="row azz-info-blocks-row azz-last pragmatic">
  <div class="col-12">
    <div class="azz-info-block">
      <div class="azz-header">{{ ordersData?.totalElements || 0 }} {{ 'ORDERS' | translate | lowercase }}</div>
      <!-- Search START -->
      <form class="row">
        <div class="form-group col-md-2">
          <label for="orders-date-start" class="azz-transparent-input-label">{{ 'START_DATE' | translate }}</label>
          <input
            id="orders-date-start"
            type="text"
            name="orders_date_start"
            class="form-control azz-transparent-input azz-validate"
            autocomplete="off"
            (input)="onDataChanged()"
            [disabled]="!!filterData.trip"
            [(ngModel)]="filterData.dateStart"
            [owlDateTimeTrigger]="dt2Start"
            [owlDateTime]="dt2Start"
            [owlDateTimeFilter]="filterDateStart"
            [max]="maxDate"
          />
          <owl-date-time
            (afterPickerClosed)="onDataChanged()"
            [firstDayOfWeek]="1"
            [pickerType]="'calendar'"
            #dt2Start
          />
        </div>
        <div class="form-group col-md-2">
          <label for="orders-date-end" class="azz-transparent-input-label">{{ 'FS_END_DATE' | translate }}</label>
          <input
            id="orders-date-end"
            type="text"
            name="orders_date_end"
            class="form-control azz-transparent-input azz-validate"
            autocomplete="off"
            (input)="onDataChanged()"
            [disabled]="!!filterData.trip"
            [(ngModel)]="filterData.dateEnd"
            [owlDateTimeTrigger]="dt2End"
            [owlDateTime]="dt2End"
            [owlDateTimeFilter]="filterDateEnd"
            [max]="maxDate"
          />
          <owl-date-time (afterPickerClosed)="onDataChanged()" [pickerType]="'calendar'" [firstDayOfWeek]="1" #dt2End />
        </div>
        <div class="form-group col-md-3">
          <label for="orders-search" class="azz-transparent-input-label">{{
            'FO_SEARCH_TRIP_LABEL' | translate
          }}</label>
          <input
            id="orders-search"
            name="orders_search"
            type="text"
            (input)="onDataChanged()"
            class="form-control azz-transparent-input azz-search-field"
            [(ngModel)]="filterData.trip"
          />
        </div>
        <div class="form-group col-md-2">
          <label for="orders-status" class="azz-transparent-input-label">
            {{ 'STATUS' | translate }}
          </label>
          <select
            id="orders-status"
            name="orders_status"
            class="form-control azz-transparent-input azz-expandable"
            (ngModelChange)="onDataChanged()"
            [disabled]="!!filterData.trip || _orderType === 3"
            [(ngModel)]="filterData.status"
          >
            <option *ngFor="let status of statuses" [ngValue]="status">{{ status.title }}</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <azz-dropdown
            azzId="select-driver"
            [azzLabel]="'FO_SELECT_DRIVER_LABEL' | translate"
            azzOptionFormat="firstName lastName"
            [azzPlaceholder]="'FO_SELECT_DRIVER_LABEL' | translate"
            [azzDataRequest]="loadDriversOuterContext()"
            [inputCurrentValue]="filterData?.driver"
            [ngDisabled]="!!filterData.trip"
            (azzSelect)="onSelectDriver($event)"
            defaultInputValue="CORE_SHOW_ALL_OPT"
            [azzNullAsAll]="true"
            [azzAllOptionsLabel]="'CORE_SHOW_ALL_OPT' | translate"
            [isPartnerRemovingIsPossible]="true"
          >
          </azz-dropdown>
        </div>
        <div class="form-group col-md-3">
          <azz-dropdown
            azzId="select-customer"
            [azzLabel]="'FLEET_ORDERS_CLIENTS_LABEL' | translate"
            azzOptionFormat="name lastName"
            [azzPlaceholder]="'FLEET_ORDERS_CLIENTS_LABEL' | translate"
            [azzDataRequest]="loadClientsOuterContext()"
            [inputCurrentValue]="filterData?.customer"
            [ngDisabled]="!!filterData.trip"
            (azzSelect)="onSelectCustomer($event)"
            defaultInputValue="CORE_SHOW_ALL_OPT"
            [azzNullAsAll]="true"
            [azzAllOptionsLabel]="'CORE_SHOW_ALL_OPT' | translate"
            [isPartnerRemovingIsPossible]="true"
          >
          </azz-dropdown>
        </div>
        <div class="form-group col-md-3">
          <label for="phone-number-search" class="azz-transparent-input-label">
            {{ 'CUSTOMER_PHONE' | translate }}
          </label>
          <input
            id="phone-number-search"
            name="phone_number_search"
            type="text"
            (input)="onDataChanged()"
            [disabled]="!!filterData.trip"
            class="form-control azz-transparent-input azz-search-field"
            [(ngModel)]="filterData.customerPhone"
          />
        </div>
        <div class="form-group col-md-3">
          <label for="customer-reference-search" class="azz-transparent-input-label">
            {{ 'PA_CREATE_NEW_SUBSCRIBER_CODE' | translate }}
          </label>
          <input
            id="customer-reference-search"
            name="customer_reference_search"
            type="text"
            (input)="onDataChanged()"
            [disabled]="!!filterData.trip"
            class="form-control azz-transparent-input azz-search-field"
            [(ngModel)]="filterData.subscriberCode"
          />
        </div>
        <div class="form-group col-md-3">
          <label for="partner-id" class="azz-transparent-input-label">{{ 'AUTHORIZATION_NUMBER' | translate }}</label>
          <input
            id="partner-id"
            name="partner_id"
            type="text"
            (input)="onDataChanged()"
            [disabled]="!!filterData.trip"
            class="form-control azz-transparent-input azz-search-field"
            [(ngModel)]="filterData.partnerId"
          />
        </div>
      </form>

      <div class="form-group row col-12">
        <div>
          <input
            id="orders-free"
            type="checkbox"
            name="orders_free"
            (change)="onDataChanged()"
            [disabled]="!!filterData.trip"
            [(ngModel)]="filterData.zeroPrice"
          />
          <label for="orders-free">
            {{ 'FO_SHOW_ONLY_FREE_LABEL' | translate }}
          </label>
        </div>
      </div>
      <!-- Search END -->

      <azz-loading-block [isLoading]="loadingIndicator">
        <azz-common-orders-table
          [ordersData]="ordersData"
          [filterData]="filterData"
          (sortColumn$)="sortColumn($event)"
          (navigateToOrderDetails$)="navigateToOrderDetailsPage($event)"
          (navigateToDriverDetails$)="navigateToDriverDetailsPage($event)"
          (navigateToCustomerDetails$)="navigateToCustomerDetailsPage($event)"
        >
        </azz-common-orders-table>

        @if (!ordersData?.content?.length) {
          <p class="azz-table-no-matches">{{ 'COURSES_EMPTY_TABLE' | translate }}</p>
        }

        <azz-pagination
          (goToPrevPage)="prevPage()"
          (goToNextPage)="nextPage()"
          [prevDisabled]="isPrevDisabled()"
          [nextDisabled]="isNextDisabled()"
        >
        </azz-pagination>
      </azz-loading-block>
    </div>
  </div>
</section>
