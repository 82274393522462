import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { DialogModule } from '../../../common/components/dialog/dialog.module';
import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';
import { AzzRotateIndicatorModule } from '../../../common/components/rotate-indicator/rotate-indicator.module';
import { EmitOnEventModule } from '../../../common/directives/emit-on-event';
import { AttachFileModule } from '../attach-file/attach-file.module';
import { AzzCustomAutocompleteModule } from '../azz-custom-autocomplete/azz-custom-autocomplete.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';
import { ChangePoiErrorDialogComponent } from './change-poi-error-dialog/change-poi-error-dialog.component';
import { CreatePointOfInterestComponent } from './create-point-of-interest/create-point-of-interest.component';
import { MapCreatePointOfInterestComponent } from './create-point-of-interest/map-create-point-of-interest';
import { PoiAddressAutocompleteComponent } from './create-point-of-interest/poi-address-autocomplete/poi-address-autocomplete.component';
import { PoiCityAutocompleteComponent } from './create-point-of-interest/poi-city-autocomplete/poi-city-autocomplete.component';
import { DeletePointOfInterestDialogComponent } from './delete-point-of-interest-dialog/delete-point-of-interest-dialog.component';
import { ImportPoiErrorDialogComponent } from './import-points-of-interest-dialog/import-poi-error-dialog/import-poi-error-dialog.component';
import { ImportPointsOfInterestDialogComponent } from './import-points-of-interest-dialog/import-points-of-interest-dialog.component';
import { MapPointsOfInterestComponent } from './map-points-of-interest/map-points-of-interest.component';
import { PointsOfInterestComponent } from './points-of-interest.component';
import { SearchPointOfInterestComponent } from './search-point-of-interest/search-point-of-interest.component';
import { TablePointsOfInterestComponent } from './table-points-of-interest/table-points-of-interest.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LeafletModule,
    LeafletDrawModule,
    DialogModule,
    AzzRotateIndicatorModule,
    ReactiveFormsModule,
    FormsModule,
    AzzCustomAutocompleteModule,
    NgbTypeaheadModule,
    LoadingBlockModule,
    TableNoMatchesModule,
    PaginationModule,
    AttachFileModule,
    EmitOnEventModule,
    NgbModule,
  ],
  declarations: [
    PointsOfInterestComponent,
    TablePointsOfInterestComponent,
    MapPointsOfInterestComponent,
    ImportPointsOfInterestDialogComponent,
    CreatePointOfInterestComponent,
    MapCreatePointOfInterestComponent,
    DeletePointOfInterestDialogComponent,
    SearchPointOfInterestComponent,
    ChangePoiErrorDialogComponent,
    PoiCityAutocompleteComponent,
    PoiAddressAutocompleteComponent,
    ImportPoiErrorDialogComponent,
  ],
  exports: [PointsOfInterestComponent, CreatePointOfInterestComponent],
})
export class PointsOfInterestModule {}
