import { Injectable } from '@angular/core';
import { NgOnDestroyService } from '@common/services';
import {
  IOrderDispatchLogAction,
  IOrderDispatchLogDriver,
  IOrderDispatchLogDriverData,
  OrderDispatchLogReasons,
} from '@core/models/order-logs.model';
import { ITag } from '@core/models/tag.model';
import { TranslateService } from '@ngx-translate/core';
import { boundMethod } from 'autobind-decorator';

export interface IMessageData {
  tags?: ITag[];
}

@Injectable()
export class OrderDispatchLogService extends NgOnDestroyService {
  private tags: ITag[] = [];

  constructor(private readonly translate: TranslateService) {
    super();
  }

  @boundMethod
  private generateDriver(driver: IOrderDispatchLogDriver, i: number): string {
    const reason = this.generateDriverWithReason(driver);

    return `<li>${i + 1}. ${driver.name}${this.formatTaxiNumber(driver.taxiNumber)}${
      reason ? `, ${reason}` : reason
    }</li>`;
  }

  @boundMethod
  private generateDriverWithZone(driver: IOrderDispatchLogDriver, i: number): string {
    const reason = this.generateDriverWithReason(driver);
    const zone = this.generateDriverWithZoneAndPosition(driver);
    return `<li>${i + 1}. ${driver.name}${this.formatTaxiNumber(driver.taxiNumber)}${zone}${
      reason ? `, ${reason}` : reason
    }</li>`;
  }

  @boundMethod
  private generateDriverWithEta(driver: IOrderDispatchLogDriver, i: number): string {
    const reason = this.generateDriverWithReason(driver);
    const zone = this.generateDriverWithZoneAndPosition(driver);
    const eta = driver.eta
      ? `<span>, (${this.getMinutes(driver.eta)} / ${this.getSeconds(driver.eta)}) ${zone}</span>`
      : '';

    return `<li>${i + 1}. ${driver.name}${this.formatTaxiNumber(driver.taxiNumber)}${eta}${
      reason ? `, ${reason}` : reason
    }</li>`;
  }

  @boundMethod
  private generateDriverWithDuration(driver: IOrderDispatchLogDriver, i: number): string {
    const reason = this.generateDriverWithReason(driver);
    const zone = this.generateDriverWithZoneAndPosition(driver);
    const duration = driver.duration
      ? `<span>${zone}, (${this.getMinutes(driver.duration)} / ${this.getSeconds(driver.duration)}) ${
          reason ? `, ${reason}` : reason
        }</span>`
      : '';

    return `<li>${i + 1}. ${driver.name}${this.formatTaxiNumber(driver.taxiNumber)}${duration}</li>`;
  }

  @boundMethod
  private getMinutes(value: number): string {
    return `${Math.floor(value / 60)} ${this.translate.instant('MINUTES_SHORT_lABEL')}`;
  }

  @boundMethod
  private getSeconds(value: number): string {
    return `${value} ${this.translate.instant('SECONDS_SHORT_lABEL')}`;
  }

  public generateMsg(action: IOrderDispatchLogAction, messageData?: IMessageData): string {
    let result;
    this.tags = messageData.tags;
    switch (action.modificationType) {
      case 'DRIVERS_CONNECTED_LIST':
        result = this.generateDriversConnectedListMsg(action);
        break;
      case 'DRIVERS_VALID_LIST_DISPATCH_ZONE':
        result = this.generateDriversValidListDispatchZoneMsg(action);
        break;
      case 'DRIVERS_VALID_LIST_BUSINESS_ZONE':
        result = this.generateDriversValidListBusinessZoneMsg(action);
        break;
      case 'DRIVERS_REFUSED_LIST':
        result = this.generateDriverRefusedListMsg(action);
        break;
      case 'NO_DRIVERS_IN_ZONE_LEFT':
        result = this.generateNoDriversInZoneLeftMsg(action);
        break;
      case 'DRIVERS_IN_ZONE_LIST':
        result = this.generateDriversInZoneListMsg(action);
        break;
      case 'DRIVERS_NOT_EXCLUDED_ETA':
        result = this.generateDriversNotExcludedEtaMsg(action);
        break;
      case 'DRIVERS_NOT_EXCLUDED_ETA_PLUS_PARAM':
        result = this.generateDriverNotExcludedEtaPlusParamsMsg(action);
        break;
      case 'DRIVERS_WITH_DURATION_LIST':
        result = this.generateDriverWithDurationListMsg(action);
        break;
      case 'DRIVER_CONNECTED_LIST_SAVED_DISPATCH_ZONE':
        result = this.generateDriversConnectedListMsg(action);
        break;
      case 'DRIVERS_VALID_CONNECTED_LIST_SAVED_DISPATCH_ZONE':
        result = this.generateDriversValidListDispatchZoneMsg(action);
        break;
      case 'NO_PROPOSAL_ATTEMPTS_LEFT':
        result = '-';
        break;
      default:
        result = action?.message;
        break;
    }
    return result;
  }

  //  DRIVERS_CONNECTED_LIST
  private generateDriversConnectedListMsg(action: IOrderDispatchLogAction): string {
    const message = 'DRIVERS_CONNECTED_LIST_LOG';
    const size = action?.driverData?.length ?? 0;
    const geoZoneName = action?.geoZoneName ?? '';
    const parameters = { size, geoZoneName };
    const header = `<div>${this.translate.instant(message, parameters)}</div>`;
    return size
      ? header + this.generateDriverList(action.driverData, this.generateDriver)
      : this.generateEmptyDriversDataMsg({ message, parameters });
  }

  //  DRIVERS_VALID_LIST_DISPATCH_ZONE
  private generateDriversValidListDispatchZoneMsg(action: IOrderDispatchLogAction): string {
    const size = action?.driverData?.length ?? 0;
    const excludedSize = action?.driverDataExcluded?.length ?? 0;
    const header = `<div>${this.translate.instant('DRIVERS_VALID_LIST_DISPATCH_ZONE_TRUE_LOG', { size })}</div>`;
    const excludedHeader = `<div>${this.translate.instant('DRIVERS_VALID_LIST_DISPATCH_ZONE_FALSE_LOG', {
      size: excludedSize,
    })}</div>`;
    const drivers = size ? header + this.generateDriverList(action.driverData, this.generateDriver) : '';
    const excludedDrivers = excludedSize
      ? excludedHeader + this.generateDriverList(action.driverDataExcluded, this.generateDriver)
      : '';
    return drivers + excludedDrivers;
  }

  //  DRIVERS_VALID_LIST_BUSINESS_ZONE
  private generateDriversValidListBusinessZoneMsg(action: IOrderDispatchLogAction): string {
    const size = action?.driverData?.length ?? 0;
    const excludedSize = action?.driverDataExcluded?.length ?? 0;
    const header = `<div>${this.translate.instant('DRIVERS_VALID_LIST_BUSINESS_ZONE_TRUE_LOG', { size })}</div>`;
    const excludedHeader = `<div>${this.translate.instant('DRIVERS_VALID_LIST_BUSINESS_ZONE_FALSE_LOG', {
      size: excludedSize,
    })}</div>`;
    const drivers = size ? header + this.generateDriverList(action.driverData, this.generateDriverWithZone) : '';
    const excludedDrivers = excludedSize
      ? excludedHeader + this.generateDriverList(action.driverDataExcluded, this.generateDriverWithZone)
      : '';

    return drivers + excludedDrivers;
  }

  //  DRIVERS_REFUSED_LIST
  private generateDriverRefusedListMsg(action: IOrderDispatchLogAction): string {
    const message = 'DRIVERS_REFUSED_LIST_LOG';
    const size = action?.driverData?.length ?? 0;
    const parameters = { size };
    const header = `<div>${this.translate.instant(message, parameters)}</div>`;

    return size
      ? header + this.generateDriverList(action.driverData, this.generateDriver)
      : this.generateEmptyDriversDataMsg({ message, parameters });
  }

  //  NO_DRIVERS_IN_ZONE_LEFT
  private generateNoDriversInZoneLeftMsg(action: IOrderDispatchLogAction): string {
    return `<div>${this.translate.instant('NO_DRIVERS_IN_ZONE_LEFT_LOG', {
      geoZoneName: action.geoZoneName,
    })}</div>`;
  }

  //  DRIVERS_IN_ZONE_LIST
  private generateDriversInZoneListMsg(action: IOrderDispatchLogAction): string {
    const message = 'DRIVERS_IN_ZONE_LIST_LOG';
    const size = action?.driverData?.length ?? 0;
    const parameters = { size, geoAreaName: action?.geoAreaName };
    const header = `<div>${this.translate.instant(message, parameters)}</div>`;
    return size
      ? `${header} ${this.generateDriverList(action.driverData, this.generateDriver)}`
      : this.generateEmptyDriversDataMsg({ message, parameters });
  }

  //  DRIVERS_NOT_EXCLUDED_ETA
  private generateDriversNotExcludedEtaMsg(action: IOrderDispatchLogAction): string {
    const size = action?.driverData?.length ?? 0;
    const excludedSize = action?.driverDataExcluded?.length ?? 0;
    const tta = `<span> (${this.getMinutes(action.tta)} / ${this.getSeconds(action.tta)})</span>`;
    const header = `<div>${this.translate.instant('DRIVERS_NOT_EXCLUDED_ETA_LESS_LOG', { size })}${tta}</div>`;
    const excludedHeader = `<div>${this.translate.instant('DRIVERS_NOT_EXCLUDED_ETA_MORE_LOG', {
      size: excludedSize,
    })}</div>`;
    const drivers = size ? header + this.generateDriverList(action.driverData, this.generateDriverWithEta) : '';
    const excludedDrivers = excludedSize
      ? excludedHeader + this.generateDriverList(action.driverDataExcluded, this.generateDriverWithEta)
      : '';

    return drivers + excludedDrivers;
  }

  //  DRIVERS_NOT_EXCLUDED_ETA_PLUS_PARAM
  private generateDriverNotExcludedEtaPlusParamsMsg(action: IOrderDispatchLogAction): string {
    // ToDo Clarify excluded
    const size = action?.driverData?.length ?? 0;
    const excludedSize = action?.driverDataExcluded?.length ?? 0;
    const tta = ` <span>(${this.getMinutes(action.timeAddedToEtaOutsideZone + action.shortestEta)} / ${this.getSeconds(
      action.timeAddedToEtaOutsideZone + action.shortestEta
    )})</span>`;
    const header = `<div>${this.translate.instant('DRIVERS_NOT_EXCLUDED_ETA_PLUS_PARAM_TRUE_LOG', {
      size,
    })}</div>${tta}`;
    const excludedHeader = `<div>${this.translate.instant('DRIVERS_NOT_EXCLUDED_ETA_PLUS_PARAM_FALSE_LOG', {
      size: excludedSize,
    })}</div>${tta}`;
    const drivers = size ? `${header} ${this.generateDriverList(action.driverData, this.generateDriverWithEta)}` : '';
    const excludedDrivers = excludedSize
      ? excludedHeader + this.generateDriverList(action.driverDataExcluded, this.generateDriverWithEta)
      : '';

    return drivers + excludedDrivers;
  }

  //  DRIVERS_WITH_DURATION_LIST
  private generateDriverWithDurationListMsg(action: IOrderDispatchLogAction): string {
    const message = 'DRIVERS_WITH_DURATION_LIST_LOG';
    const size = action?.driverData?.length ?? 0;
    const header = `<div>${this.translate.instant(message)}</div>`;

    return size
      ? header + this.generateDriverList(action.driverData, this.generateDriverWithDuration)
      : this.generateEmptyDriversDataMsg({ message });
  }

  private generateEmptyDriversDataMsg({
    message,
    parameters,
  }: {
    message: string;
    parameters?: { [key: string]: any };
  }): string {
    if (message && parameters) {
      return `<div>${this.translate.instant(message, parameters)}</div>`;
    }

    if (message) {
      return `<div>${this.translate.instant(message)}</div>`;
    }

    return '';
  }

  private generateDriverList(
    data: IOrderDispatchLogDriverData,
    cb: (d: IOrderDispatchLogDriver, i: number) => string
  ): string {
    const items = data.map(cb).join('');
    return `<ul class="reset-list pl-2">${items}</ul>`;
  }

  private generateDriverWithZoneAndPosition(driver: IOrderDispatchLogDriver): string {
    const { zoneName, position, size } = driver?.queuePosition || {};

    return zoneName ? `<span>, ${zoneName} (${position}/${size})</span>` : '';
  }

  private generateDriverWithReason(driver: IOrderDispatchLogDriver): string {
    const { reasonForExclusion, tagList, maxDistance } = driver;

    switch (reasonForExclusion) {
      case OrderDispatchLogReasons.TAGS:
        const tagNames = this.tags.length
          ? tagList
              .map((tagId: string) => this.tags.find((tag: ITag): boolean => tag.id === tagId))
              .map(({ name }: ITag): string => name)
          : [''];

        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_TAGS', {
          tags: tagNames.join(', '),
        })}`;
      case OrderDispatchLogReasons.ORDER:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_ORDER')}`;
      case OrderDispatchLogReasons.STATUS:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_STATUS')}`;
      case OrderDispatchLogReasons.UNREACHABLE:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_UNREACHABLE')}`;
      case OrderDispatchLogReasons.BUSY:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_BUSY')}`;
      case OrderDispatchLogReasons.REFUSED:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_REFUSED')}`;
      case OrderDispatchLogReasons.REFUSED_BY_DRIVER_TIMEOUT:
        return `${this.translate.instant('REASON')}: ${this.translate.instant(
          'REASON_WITH_REFUSED_BY_DRIVER_TIMEOUT'
        )}`;
      case OrderDispatchLogReasons.REFUSED_BY_TIMEOUT:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_REFUSED_BY_TIMEOUT')}`;
      case OrderDispatchLogReasons.RATING:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_RATING')}`;
      case OrderDispatchLogReasons.DISTANCE:
        return `${this.translate.instant('REASON')}: ${this.translate.instant('REASON_WITH_DISTANCE', {
          distance: maxDistance / 1000,
        })}`;
      default:
        return '';
    }
  }

  private formatTaxiNumber(taxiNumber: string | null): string {
    return taxiNumber === null ? '' : ` (${taxiNumber})`;
  }
}
