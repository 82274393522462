import { HttpErrorResponse } from '@angular/common/http';
import { IUserNotificationsData } from '@core/models/user-notification.model';
import { createAction, props } from '@ngrx/store';

export enum UserMessagesActions {
  INIT = '[User Messages] Init Messages',
  GET_MESSAGES = '[User Messages] Get Messages',
  GET_MESSAGES_SUCCESS = '[User Messages] Get Messages Success',
  GET_MESSAGES_FAILED = '[User Messages] Get Messages Failed',
  READ_MESSAGE = '[User Messages] Read Message',
  READ_MESSAGE_SUCCESS = '[User Messages] Read Message Success',
  READ_MESSAGE_FAILED = '[User Messages] Read Message Failed',
  DESTROY_MESSAGES = '[User Messages] Destroy',
}

export const initUserMessages = createAction(UserMessagesActions.INIT);
export const getUserMessages = createAction(UserMessagesActions.GET_MESSAGES);
export const getUserMessagesSuccess = createAction(
  UserMessagesActions.GET_MESSAGES_SUCCESS,
  props<{ messagesData: IUserNotificationsData }>()
);
export const getUserMessagesFailed = createAction(
  UserMessagesActions.GET_MESSAGES_FAILED,
  props<{ error: HttpErrorResponse }>()
);
export const readUserMessage = createAction(UserMessagesActions.READ_MESSAGE, props<{ messageId: number }>());
export const readUserMessageSuccess = createAction(UserMessagesActions.READ_MESSAGE_SUCCESS, props<any>());
export const readUserMessageFailed = createAction(
  UserMessagesActions.READ_MESSAGE_FAILED,
  props<{ error: HttpErrorResponse }>()
);
export const destroyUserMessages = createAction(UserMessagesActions.DESTROY_MESSAGES);
