import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStore } from '@auth/store';
import { environment } from '@azz/env';
import { ROLE } from '@core/constant';
import {
  NO_INCREMENT_PEND_REQ_COUNT_URL,
  SPEC_FORBIDDEN_URL,
  SPEC_UNAUTHORIZED_URL,
} from '@core/constant/routes.constant';
import { CoffeeBreakService } from '@core/services/common';
import { URLBuilderService } from '@core/services/common/url-builder.service';
import { UserService } from '@core/services/users';
import { AppStore } from '@core/store';
import { PhoneAdvisorStore } from '@dash/modules/phone-advisor/store';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { RootService } from '@services/root.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

const excludeReasons = [
  'wrong.recaptcha',
  'cancellation.not.allowed.in.this.status',
  'driver.is.unreachable',
  'driver.is.unavailable',
];

// const BACKEND_PREFIX = '/backend';
@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  private readonly rootService = inject(RootService);
  private readonly router = inject(Router);

  private sessionExpired = false;
  private readonly sessionExpiredTimeout = 500;

  constructor(
    private readonly store: Store,
    private readonly userService: UserService,
    private readonly coffeeBreakService: CoffeeBreakService,
    private readonly urlBuilder: URLBuilderService
  ) {}

  public intercept(interceptedRequest: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<never>> {
    const requestWithBackendPrefix = interceptedRequest.clone({
      url: this.urlBuilder.wrapByApiPrefix(interceptedRequest.url),
    });

    const request = requestWithBackendPrefix ? requestWithBackendPrefix : interceptedRequest;
    return next.handle(request).pipe(
      tap(res => {
        if (
          res.type === HttpEventType.Sent &&
          !request.url.includes(NO_INCREMENT_PEND_REQ_COUNT_URL.LOGOUT) &&
          !request.url.includes(NO_INCREMENT_PEND_REQ_COUNT_URL.LOGOUT_AGENT)
        ) {
          this.store.dispatch(AppStore.incrementPendingRequestsCount());
        }
      }),
      // retryWhen(genericRetryStrategy(() => this.store.dispatch(AppStore.decrementPendingRequestsCount())),
      catchError((errorResponse: HttpErrorResponse) => {
        if (environment.production || environment.type === 'production') {
          Sentry.captureException(errorResponse);
        }

        return this.handleHTTPErrorsByStatus(errorResponse);
      }),
      finalize(() => this.store.dispatch(AppStore.decrementPendingRequestsCount()))
    );
  }

  private handleHTTPErrorsByStatus(error: HttpErrorResponse): Observable<any> {
    switch (error.status) {
      case 400:
        this.handleBadRequest(error);
        return throwError(() => error);
      case 401:
        if (!this.isSpecificUnauthorizedRequest(error) && !this.sessionExpired) {
          this.store.dispatch(AuthStore.logout());
          this.sessionExpired = true;
          setTimeout(
            () => ((this.sessionExpired = false), this.rootService.alerts.error('SESSION_EXPIRED')),
            this.sessionExpiredTimeout
          );
        }
        return throwError(() => error);
      case 403: // TODO: temporary solution, change after exceptions table will be refactored to NgRx
        if (this.isSpecificForbiddenError(error)) {
          this.store.dispatch(PhoneAdvisorStore.deactivatedPhoneAdvisor({ deactivated: true }));
        }
        return throwError(() => error);
      case 404:
        return throwError(() => error);
      case 409:
        this.handleConflictRequest(error);
        break;
      case 500:
        if (error.url.includes('exclusion/count')) {
          return;
        }
        if (error.url.endsWith('phoneAdvisorOrder')) {
          // very bad, we need to refactor it ASAP. We need consistent and customisable error handling policy across application
          this.addInternalServerErrorNotification(error.error.message ? error.error.message : '');
        } else {
          this.addInternalServerErrorNotification();
        }
        break;
      default:
        return throwError(() => error);
    }
  }

  private isSpecificUnauthorizedRequest(errorResponse: HttpErrorResponse): boolean {
    return (
      errorResponse.url.indexOf(SPEC_UNAUTHORIZED_URL.ACTIVE_DRIVER_SESSION) !== -1 ||
      errorResponse.url.indexOf(SPEC_UNAUTHORIZED_URL.LOGIN) !== -1
    );
  }

  private isSpecificForbiddenError(errorResponse: HttpErrorResponse): boolean {
    return (
      this.isPhoneAdvisorDeactivated() ||
      (this.isPhoneAdvisor() &&
        !this.coffeeBreakService.getCoffeeBreakState() &&
        !this.isLockExceptionRequest(errorResponse.url) &&
        !this.isKillDriverSessionRequest(errorResponse.url))
    );
  }

  private isLockExceptionRequest(url: string): boolean {
    return SPEC_FORBIDDEN_URL.LOCK_EXCEPTION.every(i => url.includes(i));
  }

  private isKillDriverSessionRequest(url: string): boolean {
    return url.includes(SPEC_FORBIDDEN_URL.KILL_DRIVER_SESSION);
  }

  private isPhoneAdvisor(): boolean {
    const user = this.userService.getCurrentUserInfo()?.user;
    const userRoles = user?.roles || [];
    return userRoles.indexOf(ROLE.PHONE_ADVISOR) !== -1;
  }

  private isPhoneAdvisorDeactivated(): boolean {
    const user = this.userService.getCurrentUserInfo()?.user;
    const userRoles = user?.roles || [];
    return userRoles.indexOf(ROLE.PHONE_ADVISOR_DEACTIVATED) !== -1;
  }

  private handleBadRequest(errorResponse: HttpErrorResponse): void {
    const err = errorResponse.error.validationErrors ? errorResponse.error.validationErrors[0] : errorResponse.error;

    if (excludeReasons.includes(err.reason)) {
      return;
    }

    if (err.reason === 'super.admin.expiration.link.expired') {
      void this.router.navigate(['/welcome']);
    }

    this.rootService.alerts.error(this.getServerMessage(err));
  }

  private getServerMessage(error: HttpErrorResponse | { message: string }): string {
    return error.message;
  }

  private addInternalServerErrorNotification(message?: string): void {
    this.rootService.alerts.error(message || 'CORE_UNKNOWN_ERROR_MESSAGE');
  }

  private handleConflictRequest(errorResponse: HttpErrorResponse): void {
    const message = this.getServerMessage(errorResponse.error);
    this.rootService.alerts.error(message);
  }
}
