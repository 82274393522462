import { inject, Injectable } from '@angular/core';
import { IColor, IEnergy, IMake, IModel } from '@core/models/vehicle.model';
import { VehicleService } from '@core/services/common';
import { createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { Observable } from 'rxjs';
import { exhaustMap, map, switchMap, takeUntil } from 'rxjs/operators';

import * as VehicleInfoActions from '../actions';

@Injectable()
export class VehicleInfoEffects extends EffectsBase {
  private readonly vehicleService = inject(VehicleService);

  public getMakes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleInfoActions.getMakes),
      switchMap(() =>
        this.vehicleService.getMakes().pipe(
          map((makes: IMake[]) => VehicleInfoActions.getMakesSuccess({ makes })),
          this.catchError(VehicleInfoActions.getMakesError())
        )
      )
    )
  );

  public getModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleInfoActions.getModels),
      switchMap(({ makeId }) =>
        this.vehicleService.getModelByMake(makeId).pipe(
          map((models: IModel[]) => VehicleInfoActions.getModelsSuccess({ models })),
          this.catchError(VehicleInfoActions.getModelsError())
        )
      )
    )
  );

  public getEnergies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleInfoActions.getEnergies),
      switchMap(() =>
        this.vehicleService.getEnergies().pipe(
          map((energies: IEnergy[]) => VehicleInfoActions.getEnergiesSuccess({ energies })),
          this.catchError(VehicleInfoActions.getEnergiesError())
        )
      )
    )
  );

  public getColors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleInfoActions.getColors),
      switchMap(() =>
        this.vehicleService.getColors().pipe(
          map((colors: IColor[]) => VehicleInfoActions.getColorsSuccess({ colors })),
          this.catchError(VehicleInfoActions.getColorsError())
        )
      )
    )
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(VehicleInfoActions.init),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(VehicleInfoActions.destroy)))))
    );
  }
}
