export const POI_SERVICE_LEVEL = {
  ALL: 'ALL',
  STANDARD: 'STANDARD',
  BUSINESS: 'BUSINESS',
  LUX: 'LUX',
  BUSINESS_AND_LUX: 'BUSINESS_AND_LUX',
} as const;

export type POIServiceLevelName = (typeof POI_SERVICE_LEVEL)[keyof typeof POI_SERVICE_LEVEL];

export enum POI_ICON_URL {
  ALL = 'assets/images/black-marker.svg',
  STANDARD = 'assets/images/green-marker.svg',
  BUSINESS = 'assets/images/blue-marker.svg',
  LUX = 'assets/images/yellow-marker.svg',
  BUSINESS_AND_LUX = 'assets/images/red-marker.svg',
}

export const POI_TYPE = {
  GARE: 'GARE',
  AEROPORT: 'AEROPORT',
  HOTEL: 'HOTEL',
  EXPO: 'EXPO',
  SOCIETE: 'SOCIETE',
  HOSPITAL: 'HOSPITAL',
  STATION: 'STATION',
  ADDITIONAL_ADDRESS: 'ADDITIONAL_ADDRESS',
  OTHER: 'OTHER',
} as const;

export type POITypeName = (typeof POI_TYPE)[keyof typeof POI_TYPE];

export const POI_SERVICE_LEVEL_ORDER_SERVICE_TYPE = {
  CA: `${POI_SERVICE_LEVEL.ALL},${POI_SERVICE_LEVEL.BUSINESS},${POI_SERVICE_LEVEL.BUSINESS_AND_LUX}`,
  MV: `${POI_SERVICE_LEVEL.ALL},${POI_SERVICE_LEVEL.BUSINESS},${POI_SERVICE_LEVEL.BUSINESS_AND_LUX}`,
  SP: `${POI_SERVICE_LEVEL.ALL},${POI_SERVICE_LEVEL.STANDARD}`,
  STND: `${POI_SERVICE_LEVEL.ALL},${POI_SERVICE_LEVEL.STANDARD}`,
  VP: `${POI_SERVICE_LEVEL.ALL},${POI_SERVICE_LEVEL.LUX},${POI_SERVICE_LEVEL.BUSINESS_AND_LUX}`,
};

export const ORDER_SERVICE_TYPE_POI_SERVICE_LEVEL_ARRAY = {
  STND: [POI_SERVICE_LEVEL.ALL, POI_SERVICE_LEVEL.STANDARD],
  SP: [POI_SERVICE_LEVEL.ALL, POI_SERVICE_LEVEL.STANDARD],
  MV: [POI_SERVICE_LEVEL.ALL, POI_SERVICE_LEVEL.BUSINESS, POI_SERVICE_LEVEL.BUSINESS_AND_LUX],
  CA: [POI_SERVICE_LEVEL.ALL, POI_SERVICE_LEVEL.BUSINESS, POI_SERVICE_LEVEL.BUSINESS_AND_LUX],
  VP: [POI_SERVICE_LEVEL.ALL, POI_SERVICE_LEVEL.LUX, POI_SERVICE_LEVEL.BUSINESS_AND_LUX],
};
