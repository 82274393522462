<!-- temporary solution, old markup -->
<div class="azz-info-blocks-row nav-tab">
  <div class="azz-info-block">
    <ul class="nav-tab__list">
      <li *ngFor="let tab of tabs$ | async">
        <button
          class="nav-tab__item"
          type="button"
          [ngClass]="{ 'nav-tab__item--active': (selectedType$ | async) === tab.type }"
          (click)="selectedType$.next(tab.type)"
        >
          {{ tab.locale | translate }}
          <span>({{ tab.count }})</span>
        </button>
      </li>
    </ul>
  </div>
</div>
