<section class="azz-details-page pragmatic order azz-info-blocks-row azz-last row" *ngIf="order">
  <div class="col-12">
    <div class="azz-info-block">
      <div class="azz-nav-block">
        <img src="assets/images/return-arrow.png" class="azz-back-btn" (click)="goBack()" alt="back" />
        <div class="azz-back-title">{{ 'GO_BACK' | translate }}</div>
      </div>
      <ng-container *ngIf="mode === 'driver'">
        <!--PASSENGER-->
        <div class="azz-control-group">
          <azz-custom-order-passenger-info [order]="order"></azz-custom-order-passenger-info>
        </div>
        <!--ORDER INFO-->
        <div class="azz-control-group">
          <azz-custom-order-driver-order-data
            [order]="order"
            [paymentTypeId]="paymentTypeId"
            [tagIdsExceptServiceVehiclePaymentType]="tagIdsExceptServiceVehiclePaymentType"
          >
          </azz-custom-order-driver-order-data>
        </div>
        <!--ESTIMATED PRICE & TIME ON ORDER PAGE-->
        <div class="azz-control-group" *ngIf="approachingData || tripData">
          <div class="row">
            <div class="col-md-6" *ngIf="approachingData">
              <azz-custom-order-driver-approaching-data
                [realTimeToDepartureAddress]="realTimeToDepartureAddress"
                [distanceToDepartureAddress]="distanceToDepartureAddress"
              >
              </azz-custom-order-driver-approaching-data>
            </div>
            <div class="col-md-6" *ngIf="tripData">
              <azz-custom-order-driver-trip-data
                [realOrderTime]="realOrderTime"
                [tripData]="tripData"
                [distanceToArrivalAddress]="distanceToArrivalAddress"
                [realOrderPrice]="realOrderPrice"
              >
              </azz-custom-order-driver-trip-data>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mode === 'fleet' || mode === 'super-admin'">
        <!--CUSTOMER & DRIVER-->
        <div class="azz-control-group">
          <div class="row">
            <!--CUSTOMER-->
            <div class="col-md-6" *ngIf="customer$ | async as customer">
              <azz-custom-order-customer-data
                [phoneCallButtonAvailable]="false"
                [customer]="customer"
                [urlToCustomerDetailsPage]="urlToCustomerDetailsPage"
                [order]="order"
              >
              </azz-custom-order-customer-data>
            </div>
            <!--DRIVER-->
            <div class="col-md-6" *ngIf="driver$ | async as driver">
              <azz-custom-order-driver-data
                [phoneCallButtonAvailable]="false"
                [driver]="driver"
                [urlToDriverDetailsPage]="urlToDriverDetailsPage"
              >
              </azz-custom-order-driver-data>
            </div>
          </div>
        </div>
        <!--PASSENGER & VEHICLE-->
        <div class="row">
          <!--PASSENGER-->
          <div class="col-md-6 azz-control-group">
            <azz-custom-order-passenger-info [order]="order"></azz-custom-order-passenger-info>
          </div>
          <!--VEHICLE-->
          <ng-container *ngIf="driver$ | async as driver">
            <ng-container *ngIf="vehicle$ | async as vehicle">
              <div class="col-md-6 azz-control-group">
                <azz-custom-order-vehicle-data *ngIf="vehicle.length" [driver]="driver" [vehicle]="vehicle[0]">
                </azz-custom-order-vehicle-data>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <!--ORDER INFO-->
        <div class="azz-control-group">
          <azz-custom-order-full-order-data
            [order]="order"
            [paymentTypeId]="paymentTypeId"
            [serviceTypeId]="serviceTypeId"
            [vehicleTypeId]="vehicleTypeId"
            [tagIdsExceptServiceVehiclePaymentType]="tagIdsExceptServiceVehiclePaymentType"
          >
          </azz-custom-order-full-order-data>
        </div>
        <!--ESTIMATED PRICE & TIME ON ORDER PAGE-->
        <div class="azz-control-group" *ngIf="approachingData || tripData">
          <div class="row">
            <!-- Approaching Data -->
            <div class="col-md-6" *ngIf="approachingData">
              <azz-custom-order-full-approaching-data [approachingData]="approachingData" />
            </div>
            <!-- Trip Data -->
            <div class="col-md-6" *ngIf="tripData">
              <azz-custom-order-full-trip-data
                [tripData]="tripData"
                [realOrderTime]="realOrderTime"
                [distanceToArrivalAddress]="distanceToArrivalAddress"
                [estimatedOrderPrice]="estimatedOrderPrice"
                [estimatedPriceLoading]="estimatedPriceLoading"
                [realOrderPrice]="realOrderPrice"
              >
              </azz-custom-order-full-trip-data>
            </div>
          </div>
        </div>
        <!--HISTORY ON ORDER PAGE-->
        <div class="azz-control-group">
          <app-order-logs-table
            [orderHistoryData]="orderHistoryData"
            [isAutoDispatch]="autoDispatch"
            [isLoading]="actionsLoadingIndicator"
            (filterByType)="filterOrderHistoryByType($event)"
            (prevPage)="prevOrderHistoryPage($event)"
            (nextPage)="nextOrderHistoryPage($event)"
          >
          </app-order-logs-table>
        </div>
        <!--MAP ON ORDER PAGE-->
        <div class="azz-control-group">
          <azz-geo-map [geoLayers]="mapLayers" [geoOptions]="mapOptions"></azz-geo-map>
        </div>
      </ng-container>
    </div>
  </div>
</section>
