/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * driver session status type enum
 */
export type DriverSessionStatusEnumWebApiModel = 'CONNECTED' | 'FREE' | 'OCCUPIED' | 'UNREACHABLE';

export const DriverSessionStatusEnumWebApiModel = {
  Connected: 'CONNECTED' as DriverSessionStatusEnumWebApiModel,
  Free: 'FREE' as DriverSessionStatusEnumWebApiModel,
  Occupied: 'OCCUPIED' as DriverSessionStatusEnumWebApiModel,
  Unreachable: 'UNREACHABLE' as DriverSessionStatusEnumWebApiModel,
};
