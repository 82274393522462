import { OrderStatusType } from '@core/constant/order-status.constant';
import { PhoneAdvisorOrder } from '@core/models';
import dayjs from 'dayjs';
import { isNull } from 'lodash-es';

import { AutomaticDispatchOrderClass } from './automatic-dispatch-order.class';
import { TimeContraintParams } from './dispatch-order.interfaces';
import { getAllFieldsEditableTimeLimit } from './get-editable-order-date-limit.function';
import { ManualDispatchOrderClass } from './manual-dispatch-order.class';

type OrderDispatchType = 'automatic' | 'manual';

export abstract class DispatchOrderWrapperClass {
  protected readonly rulesByOrderStatus: {
    canCancelOrEdit: OrderStatusType[];
    canOpenException: OrderStatusType[];
    isCancelOrEditForbidden: OrderStatusType[];
    isOrderCancelled: OrderStatusType[];
  } = {
    canCancelOrEdit: ['AT_DEPARTURE_ADDRESS', 'CONFIRMED', 'CREATE', 'TOWARDS_DESTINATION'],
    canOpenException: ['AT_DEPARTURE_ADDRESS', 'CONFIRMED', 'TOWARDS_DESTINATION', 'IN_QUEUE'],
    isCancelOrEditForbidden: ['DISPATCHING'],
    isOrderCancelled: ['CANCELLED', 'CANCELLED_BY_CUSTOMER', 'CANCELLED_BY_DRIVER', 'CANCELLED_BY_PHONE_ADVISOR'],
  };

  protected timeConstraints: TimeContraintParams;
  abstract readonly type: OrderDispatchType;

  static timeConstraintParamsGuard(constraints: TimeContraintParams | unknown): constraints is TimeContraintParams {
    return (
      constraints.hasOwnProperty('dispatchBeforeMinutes') &&
      constraints.hasOwnProperty('updateTimeLimitMinutes') &&
      constraints.hasOwnProperty('orderDateRaw')
    );
  }

  public canCancelOrEdit(order: PhoneAdvisorOrder): boolean {
    if (!this.timeConstraints) {
      console.warn('[DispatchOrderClass.canCancelOrEdit] time constraints not provided');
    }
    const { status, exceptional, driverId } = order;
    const notCancelled = !this.isOrderCancelled(status);
    const notForbiddenStatus = !this.isCancelOrEditForbidden(status);
    const notExceptional = !exceptional;
    const noAssignedDriver = !driverId; // yes, if driver is assigned, edit is not allowed, no error here.
    const isAllowedTimeToEdit = this.isAllowedTimeToEdit();
    return notCancelled && notForbiddenStatus && isAllowedTimeToEdit && notExceptional && noAssignedDriver;
  }

  public setTimeConstraints(constraints: Partial<TimeContraintParams>): void {
    if (isNull(this.timeConstraints) || !constraints) {
      console.warn('[DispatchOrder.setTimeConstraints] no constraints provided');
      return;
    }

    if (DispatchOrderWrapperClass.timeConstraintParamsGuard(constraints)) {
      this.timeConstraints = constraints;
      return;
    }

    const { dispatchBeforeMinutes, updateTimeLimitMinutes, orderDateRaw } = constraints;
    if (dispatchBeforeMinutes) {
      this.timeConstraints = { ...this.timeConstraints, dispatchBeforeMinutes };
    }

    if (updateTimeLimitMinutes) {
      this.timeConstraints = { ...this.timeConstraints, updateTimeLimitMinutes };
    }

    if (orderDateRaw) {
      this.timeConstraints = { ...this.timeConstraints, orderDateRaw };
    }
  }

  public isAutomaticDispatchOrderGuard(
    order: AutomaticDispatchOrderClass | ManualDispatchOrderClass
  ): order is AutomaticDispatchOrderClass {
    return order.type === 'automatic';
  }

  public isManualDispatchOrderGuard(
    order: AutomaticDispatchOrderClass | ManualDispatchOrderClass
  ): order is ManualDispatchOrderClass {
    return order.type === 'manual';
  }

  public isOrderCancelled(status: OrderStatusType): boolean {
    return this.rulesByOrderStatus.isOrderCancelled.includes(status);
  }

  public isCancelOrEditForbidden(status: OrderStatusType): boolean {
    return this.rulesByOrderStatus.isCancelOrEditForbidden.includes(status);
  }

  public canOpenExceptionByStatus(status: OrderStatusType): boolean {
    return this.rulesByOrderStatus.canOpenException.includes(status);
  }

  // public canEditOnlyAddressesByStatus(status: OrderStatusType): boolean {
  //   return this.rulesByOrderStatus.canEditOnlyAddresses.includes(status);
  // }

  public canCancelOrEditByStatus(status: OrderStatusType): boolean {
    return this.rulesByOrderStatus.canCancelOrEdit.includes(status);
  }

  public isAllowedTimeToEdit(): boolean {
    const now = dayjs();
    // const activeOrderDateLimit = getPartialEditTimeLimit(this.timeConstraints);
    const editableOrderDateLimit = getAllFieldsEditableTimeLimit(this.timeConstraints);

    return now.isBefore(editableOrderDateLimit);
  }

  /** not Advanced and not Immediate */
  public isOrderReserved(activeOrderTime: dayjs.Dayjs): boolean {
    const now = dayjs();
    return now.isBefore(activeOrderTime);
  }

  protected hasTimeConstaints(): boolean {
    const hasTimeConstaints = !!this.timeConstraints && !isNull(this.timeConstraints);
    if (!hasTimeConstaints) {
      console.warn(
        "[AbstractDispatchOrderClass.isAllowedToEditAllFields] No time constraints were provided, make sure it's intentional"
      );
    }
    return hasTimeConstaints;
  }

  abstract isEditAllowed(status: OrderStatusType): boolean;
}
