import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'azz-table-no-matches',
  templateUrl: './table-no-matches.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableNoMatchesComponent {
  @Input() azzVisible: boolean;
}
