<div class="azz-header">{{ 'ACTIONS' | translate }}</div>
<div *ngIf="isAutoDispatch" class="row">
  <div class="form-group col-lg-auto">
    <input
      id="exception-dispatch-history"
      type="checkbox"
      [value]="dispatchHistoryType"
      #dispatchHistory
      (change)="filterOrderHistoryByType({ checked: dispatchHistory.checked, type: dispatchHistoryType })"
      name="exception_dispatch_history"
    />
    <label for="exception-dispatch-history">{{ 'SHOW_DISPATCH_HISTORY' | translate }}</label>
  </div>
  <div class="form-group col-lg-auto">
    <input
      id="exception-error-history"
      type="checkbox"
      [value]="errorHistoryType"
      #errorHistory
      (change)="filterOrderHistoryByType({ checked: errorHistory.checked, type: errorHistoryType })"
      name="exception_error_history"
    />
    <label for="exception-error-history">{{ 'SHOW_ERROR_HISTORY' | translate }}</label>
  </div>
</div>
<azz-loading-block [isLoading]="isLoading">
  <div class="azz-table-wrapper">
    <table class="table table-hover azz-table">
      <thead>
        <tr class="d-table-row">
          <th class="col-2">{{ 'DATE' | translate }}</th>
          <th class="col-5">{{ 'ACTION' | translate }}</th>
          <th class="col-3">{{ 'WHO' | translate }}</th>
          <th class="col-2">{{ 'TAXI_NUMBER' | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="orderHistoryData?.content?.length > 0">
        <!-- IE scrolling fix -->
        <tr
          [class.azz-color-red]="item.type === 'ERROR'"
          class="azz-clickable"
          *ngFor="let item of orderHistoryData?.content"
        >
          <td>{{ item?.modificationDate | date: 'dd.MM.yyyy HH:mm:ss' }}</td>
          <td>
            <app-order-log-info [action]="item?.action"></app-order-log-info>
          </td>
          <td>{{ item?.who }}</td>
          <td>{{ item?.taxiNumber === null ? '' : item?.taxiNumber }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <azz-table-no-matches [azzVisible]="!orderHistoryData?.content?.length"></azz-table-no-matches>
  <azz-pagination
    (goToPrevPage)="prevOrderHistoryPage()"
    (goToNextPage)="nextOrderHistoryPage()"
    [prevDisabled]="isPrevOrderHistoryDisabled()"
    [nextDisabled]="isNextOrderHistoryDisabled()"
  >
  </azz-pagination>
</azz-loading-block>
