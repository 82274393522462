/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROLE } from '@core/constant/role.constant';
import {
  IActiveDriverSessionParams,
  IDriverFullSession,
  IDriverFullSessionData,
  IOrderDistancePassedByDriver,
  ISessionsCountByStatus,
  ISessionsCountByStatusParams,
} from '@core/models/driver-session.model';
import { IEstimatedPriceParams } from '@core/models/estimated-price.model';
import { IEstimation } from '@core/models/estimation.model';
import {
  IDriverSessionData,
  IDriverSessionHistory,
  IGetDriverSessionHistoryParams,
  IGetDriverSessionsDataParams,
} from '@core/models/session.model';
import { IDriverOrdersStats } from '@core/models/stats.model';
import { round } from '@core/utils/util';
import moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../common/api.service';

@Injectable({ providedIn: 'root' })
export class SessionService extends ApiService {
  public resource: any;
  public sessions$ = new Subject();
  public selectedSession$ = new Subject<IDriverFullSession>();
  public deleteSession$ = new Subject();
  public lastTimeUpdatedCoords$ = new Subject();

  private SESSION_SEARCH_URL: string;
  private SESSION_URL: string;
  private readonly API_URL = 'driver-session/api/v1/';
  private readonly FLEET_ADMIN_DRIVER_SESSION = 'driver-session/api/v1/';
  private readonly FLEET_SESSION_SEARCH_URL = 'driver-session/api/v1/';
  private readonly FLEET_SESSION_URL = 'api/v1/driversession/search/';
  private readonly PHONE_ADVISOR_SESSION_SEARCH_URL = 'driver-session/api/v1/';
  private readonly PHONE_ADVISOR_SESSION_URL = 'api/v1/driversession/search/';

  constructor(private readonly httpClient: HttpClient) {
    super(httpClient);
  }

  public getOrderDistancePassedByDriver(orderId: string): Observable<IOrderDistancePassedByDriver> {
    return this.get(`${this.API_URL}driverorder/${orderId}/passedDistance`);
  }

  public changeDriverSessionFromOccupiedToFree(driverId: number): Observable<any> {
    return this.put(`${this.FLEET_ADMIN_DRIVER_SESSION}driversession/${driverId}/free`, {});
  }

  public getEstimatedPrice(params: IEstimatedPriceParams): Observable<{ price: number }> {
    return this.get(`${this.FLEET_SESSION_SEARCH_URL}estimation/price`, params);
  }

  public getApproachingEstimation(orderId: string): Observable<IEstimation> {
    return this.get(`${this.PHONE_ADVISOR_SESSION_SEARCH_URL}estimation/${orderId}/approaching`).pipe(
      map(this.roundingEstimation)
    );
  }

  public getTripEstimation(orderId: string): Observable<IEstimation> {
    return this.get(`${this.PHONE_ADVISOR_SESSION_SEARCH_URL}estimation/${orderId}/trip`).pipe(
      map(this.roundingEstimation)
    );
  }

  public getActiveDriverSessionById(driverId: number): Observable<IDriverFullSession> {
    return this.get(`${this.FLEET_SESSION_SEARCH_URL}driversession/active/${driverId}`);
  }

  public search(driverId, startDate, endDate, limit, pageToken, sort, direction, finished) {
    return this.get(`${this.SESSION_URL}`, {
      driverId,
      startDate: this._toTimestamp(startDate),
      finishDate: this._toTimestamp(endDate),
      limit,
      pageToken,
      sort,
      direction,
      finished,
    });
  }

  public getDriverSessions(params: Partial<IGetDriverSessionsDataParams>): Observable<IDriverSessionData> {
    return this.get(`${this.SESSION_URL}`, params);
  }

  public searchActive(params: Partial<IActiveDriverSessionParams>): Observable<IDriverFullSessionData> {
    return this.get(`${this.SESSION_SEARCH_URL}driversession/searchActive`, params);
  }

  public getSessionHistory(params: IGetDriverSessionHistoryParams): Observable<IDriverSessionHistory> {
    return this.get(`${this.SESSION_SEARCH_URL}sessionhistory`, params);
  }

  public deleteSession(sessionId) {
    console.log(sessionId);
    return this.delete(`${this.SESSION_SEARCH_URL}driversession/${sessionId}`).pipe(
      catchError(error => {
        console.log('Error handler in Session Service');
        return throwError(error);
      })
    );
  }

  public getSessionCountByStatus(params: ISessionsCountByStatusParams): Observable<ISessionsCountByStatus[]> {
    return this.get(`${this.FLEET_SESSION_SEARCH_URL}driversession/count/by/status`, params);
  }

  public _toTimestamp(date) {
    if (!date) {
      return null;
    }
    const offset = moment(date).utcOffset();
    return moment(date).utc().add(offset, 'm').valueOf();
  }

  public setUrl(role: string) {
    role = role ? role : ROLE.FLEET_OWNER;
    const urlMapping = {
      ROLE_FLEET_OWNER: {
        SESSION_SEARCH_URL: this.FLEET_SESSION_SEARCH_URL,
        SESSION_URL: this.FLEET_SESSION_URL,
      },
      ROLE_FLEET_ADMIN: {
        SESSION_SEARCH_URL: this.FLEET_SESSION_SEARCH_URL,
        SESSION_URL: this.FLEET_SESSION_URL,
      },
      ROLE_PHONE_ADVISOR: {
        SESSION_SEARCH_URL: this.PHONE_ADVISOR_SESSION_SEARCH_URL,
        SESSION_URL: this.PHONE_ADVISOR_SESSION_URL,
      },
      ROLE_DISPATCHER: {
        SESSION_SEARCH_URL: this.FLEET_SESSION_SEARCH_URL,
        SESSION_URL: this.FLEET_SESSION_URL,
      },
    };

    this.SESSION_SEARCH_URL = urlMapping[role].SESSION_SEARCH_URL;
    this.SESSION_URL = urlMapping[role].SESSION_URL;
  }

  public getDriverOrdersStats(driverId: number): Observable<IDriverOrdersStats> {
    return this.get(`${this.API_URL}trip-info/${driverId}`);
  }

  private roundingEstimation(estimationData: IEstimation): IEstimation {
    const { estimated, realized } = estimationData;

    const roundedResults = {
      ...estimationData,
    };

    if (estimated) {
      roundedResults.estimated = {
        ...estimated,
        kilometres: round(estimated.kilometres, 1),
      };
    }

    if (realized) {
      roundedResults.realized = {
        ...realized,
        kilometres: round(realized.kilometres, 1),
      };
    }

    return roundedResults;
  }
}
