/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * object with error data returned when any error occurs
 */
export interface ErrorResponseWebApiModel {
  /**
   * error code
   */
  reason: string;
  /**
   * error message (fr)
   */
  message: string;
  /**
   * error message (eng)
   */
  debugMessage?: string;
}
