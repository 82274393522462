<form class="card azz-multiple customer-typeahead" #formElemRef [formGroup]="form">
  <ng-template #resultTemplate let-result="result">
    <div *ngIf="result">
      <div *ngIf="result?.name; else lastNameOnly">
        <ngb-highlight
          [ngClass]="result.status === 'BLOCKED' ? 'status-blocked' : ''"
          class="name"
          [result]="result?.name + ', ' + result?.lastName"
          [term]="hightlightTerm"
        ></ngb-highlight>
      </div>
      <ng-template #lastNameOnly>
        <ngb-highlight
          [ngClass]="result.status === 'BLOCKED' ? 'status-blocked' : ''"
          class="name"
          [result]="result?.lastName"
          [term]="hightlightTerm"
        ></ngb-highlight>
      </ng-template>

      <div *ngIf="result?.subscriberCode">
        <span class="customer-typeahead__label ref">{{ 'COMMANDE_SUBSCRIBER_CODE' | translate }}: </span>
        <ngb-highlight class="ref" [result]="result?.subscriberCode" [term]="hightlightTerm"></ngb-highlight>
      </div>
      <div>
        <ngb-highlight
          class="customer-phone ref"
          [result]="
            result?.phones && result?.phones[0] ? result?.phones[0].phoneCode + result?.phones[0].phoneNumber : ''
          "
          [term]="hightlightTerm"
        ></ngb-highlight>
      </div>
    </div>

    <div *ngIf="!result" class="hover-dropdown">
      <p class="error-text">
        {{ 'COMMANDE_CLIENT_NOT_FOUND' | translate }}
      </p>
      <p class="link">
        {{ 'COMMANDE_CLIENT_CREATE_NEW_CUSTOMER' | translate }}
      </p>
    </div>
  </ng-template>

  <div [ngClass]="customer ? 'd-flex gap-1' : ''">
    <div class="d-flex align-items-center w-100">
      <input
        class="transparent-input azz-validate azz-search-field"
        [ngClass]="customer ? 'shorten-input' : ''"
        [tabindex]="NgTabindex"
        [id]="ngId"
        type="text"
        name="searchField"
        formControlName="searchField"
        (keydown.enter)="onEnterClick()"
        (blur)="onBlur()"
        [editable]="true"
        [ngbTypeahead]="search"
        (selectItem)="onCustomerSelect($event)"
        [resultTemplate]="resultTemplate"
        [inputFormatter]="formatter"
      />
      <azz-rotate-indicator7 [visible]="loadingIndicator" newDesign size="extra-small" class="loading-indicator" />
    </div>

    <button
      type="button"
      *ngIf="customer"
      class="edit-customer"
      (click)="redirectToClientEditPage()"
      [ngbTooltip]="tooltipContent"
      placement="top"
      tooltipClass="status-tooltip error-color"
    >
      <span class="visibility-hidden">{{ 'EDIT' | translate }}</span>
    </button>
    <ng-template #tooltipContent>
      <small class="hover-text" [innerHTML]="'EDIT' | translate"> </small>
    </ng-template>
  </div>

  <div class="transparent-input-error" *ngIf="customer?.status === 'BLOCKED'">
    <small
      [innerHTML]="
        'CUSTOMER_BLOCKED_EXPLANTION_SUMMARY'
          | translate: { blockTime: customerBlockTime, blockReason: customerBlockReason }
      "
    >
    </small>
  </div>
  <div
    class="transparent-input-error transparent-input-label-wide"
    *ngIf="searchFieldControl?.touched && searchFieldControl.errors"
  >
    <small *ngIf="searchFieldControl.errors?.noSelectedCustomer">
      {{ 'PA_CHOOSE_CUSTOMER_ERROR' | translate }}
    </small>
  </div>
</form>
