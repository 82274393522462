import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'azz-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.less'],
})
export class LogoutPopupComponent implements AfterViewInit {
  @ViewChild('content') dialogContent: any;
  public modalReference: NgbModalRef;

  constructor(private readonly dialogService: NgbModal) {}

  ngAfterViewInit() {
    this.setContent(this.dialogContent);
  }

  public showDialog(): void {
    this.modalReference = this.dialogService.open(this.dialogContent, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  public closeDialog(): void {
    if (this.hasOpenModals()) {
      this.modalReference.close();
      this.modalReference = null;
    }
  }

  public hasOpenModals(): boolean {
    return !!this.modalReference;
  }

  protected setContent(content: any) {
    this.dialogContent = content;
  }
}
