import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { map, pairwise } from 'rxjs/operators';

import { AppStore } from '../index';

@Injectable()
export class PrevRouteEffects {
  private readonly actions$ = inject(Actions);
  private readonly localStorageService = inject(LocalStorageService);

  previousRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      pairwise(),
      map(([prev, _]) => {
        const prevRouteState = prev?.payload?.routerState as any;
        this.localStorageService.setLocalStorageItem('PREV_PAGE', prevRouteState?.url);
        return AppStore.setPrevRoute({ prevRoute: prevRouteState });
      })
    )
  );
}
