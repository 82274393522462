import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { POI_SERVICE_LEVEL, POI_TYPE } from '@core/constant/poi.constant';
import { ROLE } from '@core/constant/role.constant';
import { IFleetParametersForOtherUser } from '@core/models/fleet.model';
import { IPoi } from '@core/models/poi.model';
import { AddressesService, GeoAreaService, PoiService, UtilService } from '@core/services/common';
import { FleetService } from '@core/services/users/fleet.service';
import { UserService } from '@core/services/users/user.service';
import { FormService } from '@core/utils/form-service';
import { Store } from '@ngrx/store';
import { RootService } from '@services/root.service';
import { boundMethod } from 'autobind-decorator';
import { FeatureCollection } from 'geojson';
import { LatLng } from 'leaflet';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, mergeMap, takeUntil } from 'rxjs/operators';

import { MapCreatePointOfInterestComponent } from './map-create-point-of-interest';
import { PoiAddressAutocompleteComponent } from './poi-address-autocomplete';
import { PoiCityAutocompleteComponent } from './poi-city-autocomplete';
import { ChangePoiErrorDialogComponent } from '../change-poi-error-dialog';

@Component({
  selector: 'azz-create-point-of-interest',
  templateUrl: './create-point-of-interest.component.html',
  styleUrls: ['./create-point-of-interest.component.less'],
  providers: [NgOnDestroyService],
})
export class CreatePointOfInterestComponent implements OnInit, OnDestroy {
  protected readonly rootService = inject(RootService);

  @Input() isEditMode: boolean;
  @Input() backUrl: string;
  @ViewChild(MapCreatePointOfInterestComponent)
  mapCreateComponent: MapCreatePointOfInterestComponent;
  @ViewChild(PoiAddressAutocompleteComponent) autoComplete: PoiAddressAutocompleteComponent;
  @ViewChild('formElemRef') formElemRef: ElementRef;
  @ViewChild(ChangePoiErrorDialogComponent) changePoiErrorDialog: ChangePoiErrorDialogComponent;
  @ViewChild(PoiCityAutocompleteComponent) poiCityAutocompleteRef: PoiCityAutocompleteComponent;
  public form: FormGroup;
  public poiTypes: { id: string; name: string }[];
  public poiServiceLevels = this.initPoiServiceLevels();
  public address: any = {};
  public addressAutocompleteId = 'gps-position';
  public poiCityId = 'poi-city';
  public loadingIndicator = false;
  public markerLatLngArr: number[];
  public markerDragCoordsStr: string;
  public markerDragCoords: LatLng;
  public poiDataLoadingIndicator = false;
  public minDurationEstimateTime = 0;
  public maxDurationEstimateTime = 45;
  private poiId: string;
  private poiData: IPoi;
  private businessZoneId: string;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly formService: FormService,
    private readonly poiService: PoiService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilService: UtilService,
    private readonly fleetService: FleetService,
    private readonly geoAreaService: GeoAreaService,
    private readonly store: Store,
    private readonly addressesService: AddressesService,
    private readonly destroyed$: NgOnDestroyService
  ) {
    this.initForm();
    this.initPoiTypes();
    if (this.isFleetUser() || this.isPhoneAdvisor()) {
      this.loadFleetBusinessZoneGeoJSON();
    }
  }

  public get displayInBillingPortalControl(): FormControl {
    return this.form.get('displayInBillingPortal') as FormControl;
  }

  @boundMethod
  public addressAutocompleteValidator(): ValidationErrors | null {
    return this.markerDragCoordsStr ? null : { markerNotAdded: true };
  }

  ngOnInit() {
    if (this.isEditMode) {
      this.activatedRoute.params
        .pipe(
          takeUntil(this.destroyed$),
          mergeMap((res: Params) => {
            this.poiId = res.poiId;
            return this.getPoiObservableById$();
          })
        )
        .subscribe(
          (res: IPoi) => {
            this.poiData = res;
            this.updateForm();
            this.updateCity();
            this.updatePoiMarkerData();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 403 && err.error && err.error.reason === 'poi.belongs.to.another.fleet') {
              this.rootService.alerts.error('POI_OF_ANOTHER_FLEET');
              void this.router.navigate([this.backUrl]);
            } else {
              throw err;
            }
          }
        );
    }
    this.listenCrossFieldInteractionsLogic();
  }

  public onSetAddress() {
    if (!this.address || !this.address.value) {
      return;
    }

    this.markerLatLngArr = this.address.value.geometry.coordinates.reverse();
    const [lat, lon] = this.markerLatLngArr;

    this.markerDragCoordsStr = `${lat}, ${lon}`;
    this.markerDragCoords = new LatLng(lat, lon);
    this.mapCreateComponent.setMarkerView({ data: new LatLng(lat, lon), resetZoom: false });
    this.setCityFromAddress();
  }

  public exportBusinessZone() {
    return this.geoAreaService.exportBusinessZone(this.businessZoneId).pipe(filter(val => !!val));
  }

  public updatePoiMarkerData(): void {
    this.markerLatLngArr = [this.poiData.position.latitude, this.poiData.position.longitude];
    this.markerDragCoordsStr = `${this.poiData.position.latitude}, ${this.poiData.position.longitude}`;
    this.markerDragCoords = new LatLng(this.poiData.position.latitude, this.poiData.position.longitude);
    this.mapCreateComponent.setMarkerView({
      data: new LatLng(this.poiData.position.latitude, this.poiData.position.longitude),
    });
  }

  public getPoiObservableById$(): Observable<IPoi> {
    this.poiDataLoadingIndicator = true;
    return this.poiService.getPoiById(this.poiId).pipe(
      takeUntil(this.destroyed$),
      finalize(() => (this.poiDataLoadingIndicator = false))
    );
  }

  public submit(): void {
    this.markFieldsAsTouched();
    if (this.isFormValid() && this.markerDragCoords) {
      if (this.isEditMode) {
        this.updatePoi();
      } else {
        this.createPoi();
      }
    } else {
      this.formService.scrollToFirstInvalidControl([
        this.formElemRef,
        this.autoComplete.formElemRef,
        this.poiCityAutocompleteRef.formElemRef,
      ]);
    }
  }

  public generatePayload(): IPoi {
    let addressStrValue: string;
    if (this.address.value) {
      const properties = this.address.value.properties;
      addressStrValue = this.utilService.tagRemoveFalsyValueFunc`${properties.name}, ${properties.city}`;
    } else {
      addressStrValue = this.autoComplete.getAutocompleteFieldValue();
    }
    return {
      ...this.form.getRawValue(),
      position: {
        latitude: this.markerDragCoords.lat,
        longitude: this.markerDragCoords.lng,
      },
      address: addressStrValue,
      city: this.poiCityAutocompleteRef.city,
    };
  }

  public initForm(): void {
    this.form = this.fb.group({
      label: [null, [Validators.required, Validators.maxLength(50)]],
      type: [POI_TYPE.ADDITIONAL_ADDRESS, [Validators.required]],
      serviceLevel: [POI_SERVICE_LEVEL.ALL, [Validators.required]],
      descriptionLink: [null, [Validators.maxLength(500)]],
      description: [null, [Validators.maxLength(500)]],
      displayInDriverApp: [false],
      displayInCrcService: [true],
      displayInPassengerApp: [false],
      displayInBillingPortal: [true],
      addFieldFlightId: [false],
      addFieldTrainId: [false],
      addFieldRoomId: [false],
      additionalDurationEstimateTime: [
        this.minDurationEstimateTime,
        [Validators.min(this.minDurationEstimateTime), Validators.max(this.maxDurationEstimateTime)],
      ],
      welcomeSignWithName: [false],
      descriptionForPassengerApp: [null, [Validators.maxLength(500)]],
      descriptionLinkSmsOrEmail: [null, [Validators.maxLength(500)]],
    });
  }

  public initPoiTypes(): void {
    this.poiService
      .getPoiTypes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: { id: string; name: string }[]) => (this.poiTypes = res));
  }

  public initPoiServiceLevels(): { title: string; value: string }[] {
    return [
      { title: POI_SERVICE_LEVEL.ALL, value: POI_SERVICE_LEVEL.ALL },
      { title: POI_SERVICE_LEVEL.STANDARD, value: POI_SERVICE_LEVEL.STANDARD },
      { title: POI_SERVICE_LEVEL.BUSINESS, value: POI_SERVICE_LEVEL.BUSINESS },
      { title: POI_SERVICE_LEVEL.LUX, value: POI_SERVICE_LEVEL.LUX },
      { title: POI_SERVICE_LEVEL.BUSINESS_AND_LUX, value: POI_SERVICE_LEVEL.BUSINESS_AND_LUX },
    ];
  }

  public onMarkerDrag(data: LatLng): void {
    this.markerDragCoordsStr = `${data.lat}, ${data.lng}`;
    this.markerDragCoords = data;
    this.autoComplete.reset();
    this.getCityByPoint(data.lat, data.lng);
    this.cd.detectChanges();
  }

  public onMarkerCreate(data: LatLng): void {
    this.markerDragCoordsStr = `${data.lat}, ${data.lng}`;
    this.markerDragCoords = data;
    this.cd.detectChanges();
    this.getCityByPoint(data.lat, data.lng);
  }

  public onMarkerDelete(): void {
    this.markerDragCoordsStr = null;
    this.markerDragCoords = null;
    this.autoComplete.reset();
    this.poiCityAutocompleteRef.reset();
    this.cd.detectChanges();
  }

  public goBack(): void {
    this.router.navigate([this.backUrl]);
  }

  ngOnDestroy() {
    this.poiService.setFleetBusinessZoneGeoJSON(null);
  }

  private setCityFromAddress(): void {
    if (this.address.value.properties && this.address.value.properties.city) {
      this.poiCityAutocompleteRef.setCityFromOutside(this.address.value.properties.city);
    } else {
      this.poiCityAutocompleteRef.setNoCityFound();
    }
  }

  private listenCrossFieldInteractionsLogic(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged((previous, current) => {
          const serviceLevelWasntChanged = previous.serviceLevel === current.serviceLevel;
          return serviceLevelWasntChanged;
        }),
        map(values => values.serviceLevel as string)
      )
      .subscribe(serviceLevel => {
        if (serviceLevel !== 'ALL') {
          this.displayInBillingPortalControl.setValue(false);
          this.displayInBillingPortalControl.disable();
        } else {
          this.displayInBillingPortalControl.enable();
        }
      });
  }

  private updateCity(): void {
    if (this.poiData.city) {
      this.poiCityAutocompleteRef.setCityFromOutside(this.poiData.city);
    }
  }

  private isFleetUser(): boolean {
    const user = this.userService.getCurrentUserInfo().user;
    const userRoles = user ? user.roles : [];
    return userRoles.indexOf(ROLE.FLEET_OWNER) !== -1 || userRoles.indexOf(ROLE.FLEET_ADMIN) !== -1;
  }

  private isPhoneAdvisor(): boolean {
    const user = this.userService.getCurrentUserInfo().user;
    const userRoles = user ? user.roles : [];
    return userRoles.indexOf(ROLE.PHONE_ADVISOR) !== -1;
  }

  private loadFleetParamsByAnyUser(): Observable<IFleetParametersForOtherUser> {
    const fleetId = this.userService.getCurrentUserInfo().fleet.id;
    return this.fleetService.getFleetParametersForOtherUser(fleetId);
  }

  private loadFleetBusinessZoneGeoJSON() {
    this.loadFleetParamsByAnyUser()
      .pipe(
        takeUntil(this.destroyed$),
        mergeMap((res: IFleetParametersForOtherUser) => {
          this.businessZoneId = res.geoArea.id;
          return this.exportBusinessZone();
        })
      )
      .subscribe((res: FeatureCollection) => {
        this.setFleetBusinessZone(res);
      });
  }

  private setFleetBusinessZone(data: FeatureCollection): void {
    if (data.features && data.features.length) {
      this.poiService.setFleetBusinessZoneGeoJSON(data.features[0]);
    }
  }

  private updateForm(): void {
    this.form.patchValue({
      label: this.poiData.label,
      type: this.poiData.type,
      serviceLevel: this.poiData.serviceLevel,
      descriptionLink: this.poiData.descriptionLink,
      description: this.poiData.description,
      displayInDriverApp: this.poiData.displayInDriverApp,
      displayInCrcService: this.poiData.displayInCrcService,
      displayInPassengerApp: this.poiData.displayInPassengerApp,
      displayInBillingPortal: this.poiData.displayInBillingPortal,
      addFieldFlightId: this.poiData.addFieldFlightId,
      addFieldTrainId: this.poiData.addFieldTrainId,
      addFieldRoomId: this.poiData.addFieldRoomId,
      additionalDurationEstimateTime: this.poiData.additionalDurationEstimateTime,
      welcomeSignWithName: this.poiData.welcomeSignWithName,
      descriptionForPassengerApp: this.poiData.descriptionForPassengerApp,
      descriptionLinkSmsOrEmail: this.poiData.descriptionLinkSmsOrEmail,
    });
    this.autoComplete.setAutocompleteFieldValue(this.poiData.address);
  }

  private markFieldsAsTouched(): void {
    this.formService.markFieldsAsTouched(this.form);
    this.formService.markFieldsAsTouched(this.autoComplete.form);
    this.formService.markFieldsAsTouched(this.poiCityAutocompleteRef.form);
  }

  private createPoi() {
    const poiData = this.generatePayload();
    this.enableLoadingIndicator(true);
    this.poiService
      .createPoi(poiData)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.enableLoadingIndicator(false))
      )
      .subscribe(() => {
        this.poiService.setIsPoiCreatedValue(true);
        this.router.navigate([this.backUrl]);
      });
  }

  private updatePoi(): void {
    const poiData = this.generatePayload();
    this.enableLoadingIndicator(true);
    this.poiService
      .updatePoi(this.poiId, poiData)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.enableLoadingIndicator(false))
      )
      .subscribe(
        () => {
          this.poiService.setIsPoiUpdatedValue(true);
          this.router.navigate([this.backUrl]);
        },
        (err: HttpErrorResponse) => this.handleUpdatePoiOrderExistsError(err)
      );
  }

  private handleUpdatePoiOrderExistsError(error: HttpErrorResponse): void {
    if (
      error.status === 409 &&
      (error.error.reason === 'poi.order.exists' || error.error.reason === 'displayIn.is.true')
    ) {
      this.changePoiErrorDialog.showDialog('POI_CANNOT_UPDATE_POI_ERROR_TITLE', {
        poi: this.poiData,
        fleets: error.error.fleets,
      });
    } else {
      throw error;
    }
  }

  private isFormValid(): boolean {
    return this.form.valid && this.poiCityAutocompleteRef.isFormValid();
  }

  private getCityByPoint(lat: number, lon: number): void {
    this.addressesService
      .getCityByPoint({ lat, lon })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: { id: any; name: string }) => this.poiCityAutocompleteRef.setCityFromOutside(res.name),
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.poiCityAutocompleteRef.setNoCityFound();
          } else {
            throw err;
          }
        }
      );
  }

  private enableLoadingIndicator(value: boolean): void {
    this.loadingIndicator = value;
  }
}
