import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgOnDestroyService } from '@common/services';
import { ToastsComponent } from '@core/components/layout/toasts/toasts.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'azz-layout',
  standalone: true,
  imports: [RouterModule, NgbModule, MessagesModule, ToastModule, ToastsComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  // TODO: enable this when app will work ok (now we have change detection issues in dashboard(
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class LayoutComponent {}
