import { createAction, props } from '@ngrx/store';

import { IPaginationState } from '../../../../models/common.model';
import { IExceptionData, IExceptionDataParams } from '../../../../models/exception.model';

export const init = createAction('[Exceptions] Init');
export const destroy = createAction('[Exceptions] Destroy');
export const getExceptions = createAction('[Exceptions] Get Exceptions');
export const getExceptionsSuccess = createAction(
  '[Exceptions] Get Exceptions Success',
  props<{ exceptionsData: IExceptionData }>()
);
export const getExceptionsError = createAction('[Exceptions] Get Exceptions Error');
export const updateFilterModel = createAction(
  '[Exceptions] Update Filter Model',
  props<{ filterModel: Partial<IExceptionDataParams> }>()
);
export const updatePagination = createAction(
  '[Exceptions] Update Pagination',
  props<{ pagination: Partial<IPaginationState> }>()
);
export const lockException = createAction('[Exceptions] Lock Exception', props<{ id: string; route: string }>());
export const lockExceptionSuccess = createAction(
  '[Exceptions] Lock Exception Success',
  props<{ id: string; route: string }>()
);
export const lockExceptionError = createAction('[Exceptions] Lock Exception Error');
