import { RouteUrls } from '@core/constant/route-urls';

const {
  dash: { dispatcher },
} = RouteUrls;
export const DISPATCHER_ROUTE = {
  CLIENTS: `${dispatcher}/customers`,
  CREATE_ORDER: `${dispatcher}/create-order`,
  DRIVERS: `${dispatcher}/drivers`,
  EDIT_ORDER: `${dispatcher}/edit-order`,
  EXCEPTIONS: `${dispatcher}/exceptions`,
  ORDERS: `${dispatcher}/orders`,
  PLANNING: `${dispatcher}/planning`,
  PROFILE: `${dispatcher}/profile`,
  SESSIONS: `${dispatcher}/sessions`,
  PRE_REG: `${dispatcher}/registration/pre-registration`,
  CREATE_REG: `${dispatcher}/registration/create`,
  EDIT_REG: `${dispatcher}/registration/edit`,
  CONFIRM_REG: `${dispatcher}/registration/confirm/registration`,
  CONFIRM_EMAIL: `${dispatcher}/registration/create/confirm/done`,
};
