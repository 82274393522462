import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { G7Order } from '@core/models/g7-order.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DialogDirective } from '../dialog.component';

@Component({
  selector: 'azz-g7-deny-validation-dialog',
  templateUrl: './g7-deny-validation-dialog.component.html',
})
export class G7DenyValidationDialogComponent extends DialogDirective implements OnInit {
  @Input() order: G7Order;
  @Input() hideMessageField: boolean;
  @Input() isDetailsPage: boolean;
  @Input() messageToFleet: string;
  @ViewChild('content') public declare dialogContent: any;
  @ViewChild('textAreaElemRef') textAreaElemRef: ElementRef;

  constructor(dialogService: NgbModal) {
    super(dialogService);
  }

  ngOnInit() {
    this.setContent(this.dialogContent);
  }
}
