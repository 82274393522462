import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CustomOrderComponent } from './custom-order.component';
import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';
import { AzzGeoMapModule } from '../azz-geo-map/azz-geo-map.module';
import { CustomDialogModule } from '../custom-dialog/custom-dialog.module';
import { OrderLogsModule } from '../order-logs/order-logs.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';
import { DriverApproachingDataComponent } from './driver-approaching-data/driver-approaching-data.component';
import { DriverOrderDataComponent } from './driver-order-data/driver-order-data.component';
import { DriverTripDataComponent } from './driver-trip-data/driver-trip-data.component';
import { AzzSecondsToUIOrderTimePipeModule } from './pipes/seconds-to-ui-order-time.pipe';
import { CustomOrderSharedModule } from './shared/custom-order-shared.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CustomOrderSharedModule,
    LoadingBlockModule,
    PaginationModule,
    TableNoMatchesModule,
    AzzGeoMapModule,
    CustomDialogModule,
    OrderLogsModule,
    AzzSecondsToUIOrderTimePipeModule,
  ],
  declarations: [
    CustomOrderComponent,
    DriverApproachingDataComponent,
    DriverTripDataComponent,
    DriverOrderDataComponent,
  ],
  exports: [CustomOrderComponent, DriverOrderDataComponent, DriverApproachingDataComponent, DriverTripDataComponent],
})
export class CustomOrderModule {}
