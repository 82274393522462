export const DRIVER_SESSION_STATUS = {
  BUSY: 'BUSY',
  CONNECTED: 'CONNECTED',
  FREE: 'FREE',
  OCCUPIED: 'OCCUPIED',
  UNREACHABLE: 'UNREACHABLE',
  IN_QUEUE: 'IN_QUEUE',
} as const;

export const DRIVER_SESSION_STATUS_ORDER = {
  FREE: 'FREE',
  OCCUPIED: 'OCCUPIED',
  UNREACHABLE: 'UNREACHABLE',
} as const;

export type DriverSessionStatusType = keyof typeof DRIVER_SESSION_STATUS;
export type DriverSessionStatusOrderType = keyof typeof DRIVER_SESSION_STATUS_ORDER;
