import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadingBlockModule } from '@common/components';
import { DatePickerModule } from '@common/date-picker/date-picker.module';
import { AzzArrowDirectiveModule } from '@common/directives';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { CommonOrdersComponent } from './orders.component';
import * as OrdersReducer from './store/reducers';
import { CommonOrdersTableComponent } from './table/orders-table.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';

@NgModule({
  imports: [
    CommonModule,
    DatePickerModule,
    DropdownModule,
    FormsModule,
    AzzArrowDirectiveModule,
    LoadingBlockModule,
    PaginationModule,
    StoreModule.forFeature(OrdersReducer.ordersFeatureKey, OrdersReducer.reducer, {
      metaReducers: OrdersReducer.metaReducers,
    }),
    TableNoMatchesModule,
    TranslateModule,
  ],
  declarations: [CommonOrdersComponent, CommonOrdersTableComponent],
  exports: [CommonOrdersComponent, CommonOrdersTableComponent],
})
export class CommonOrdersModule {}
