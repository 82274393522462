<div class="azz-table-wrapper">
  <table class="table table-hover azz-table">
    <thead>
      <tr class="d-table-row">
        <th>{{ 'PA_DETAILS_TRIP' | translate }}</th>
        <th>{{ 'PA_DETAILS_REALIZED' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="azz-clickable">
        <td>{{ 'CORE_TIME' | translate }}</td>

        <td>
          {{ (realOrderTime ? realOrderTime : tripData?.realized?.seconds) | azzSecondsToUIOrderTime }}
        </td>
      </tr>
      <tr class="azz-clickable">
        <td>{{ 'PA_CREATE_DISTANCE' | translate }}</td>
        <td>{{ distanceToArrivalAddress | azzKilometers }}</td>
      </tr>
      <tr class="azz-clickable">
        <td>{{ 'TOTAL_AMOUNT' | translate }}</td>
        <td>{{ realOrderPrice ? realOrderPrice + ' \u20AC' : '-' }}</td>
      </tr>
    </tbody>
  </table>
</div>
