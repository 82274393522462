/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RouteUrls } from '@core/constant/route-urls';
import { Observable } from 'rxjs';

import { UserService } from '../services/users/user.service';

const { login } = RouteUrls.auth;

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    protected userService: UserService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.userService.getUserDefaultRoute() !== login) {
      // ToDo replace by ROUTE_MAP value instead of string
      return true;
    }

    this.router.navigate(['/welcome']);
  }
}
