/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerBlockReasonEnumWebApiModel } from './customer-block-reason-enum-web.api-model';

/**
 * object with parameters for customer block
 */
export interface CustomerBlockRequestWebApiModel {
  reason?: CustomerBlockReasonEnumWebApiModel;
}
export namespace CustomerBlockRequestWebApiModel {}
