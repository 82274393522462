import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStore } from '@auth/store';
import { RouteUrls } from '@core/constant/route-urls';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { UserInfoService } from './user-info.service';
import { DISPATCHER_ROUTE } from '../../constant/dispatcher-constant';
import { RoleName } from '../../constant/role.constant';
import { IBaseUserInfo, ICurrentUserInfo, IGeneralUserInfo } from '../../models/user-info.model';
import { FirebaseService } from '../common/firebase.service';
import { LocalStorageService } from '../common/local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  public azzUsersAvatarChanged = new BehaviorSubject<any>(null);
  private readonly USERS_DEFAULT_ROUTES: Map<RoleName | '', string>;

  constructor(
    private readonly firebaseService: FirebaseService,
    private readonly userInfoService: UserInfoService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly cookieService: CookieService,
    private readonly store: Store
  ) {
    this.USERS_DEFAULT_ROUTES = this.getUsersDefaultRoutes();
  }

  public setCurrentUserInfo(userInfo: ICurrentUserInfo): void {
    this.localStorageService.setLocalStorageItem('CURRENT_USER_INFO', userInfo);
  }

  public getCurrentUserInfo<T extends IGeneralUserInfo = IBaseUserInfo>(): ICurrentUserInfo<T> {
    return this.localStorageService.getLocalStorageItem('CURRENT_USER_INFO');
  }

  public setCurrentUser(user, asObservable?: boolean) {
    // ToDo Remove after migrating fleet registration to NgRx
    this.localStorageService.setLocalStorageItem('CURRENT_USER_INFO', user);

    // Login into firebase
    if (user != null) {
      if (asObservable) {
        return this.firebaseService.signIn().pipe(
          catchError(() => {
            this.reset.bind(this);

            return of(null);
          })
        );
      } else {
        this.firebaseService.signIn().subscribe(
          () => {},
          () => this.reset.bind(this)
        );
      }
    }

    return user;
  }

  public setCurrentUserWithoutFirebaseLogin(userInfo: ICurrentUserInfo): void {
    this.localStorageService.setLocalStorageItem('CURRENT_USER_INFO', userInfo);
    this.store.dispatch(AuthStore.setUserInfo({ userInfo }));
  }

  public reset(noRedirect?: boolean) {
    localStorage.clear();
    this.firebaseService.signOut();
    this.cookieService.deleteAll();
    if (!noRedirect) {
      this.router.navigate(['login']);
    }
  }

  public reloadCurrentUserWithoutRedirection(): Observable<any> {
    return this.userInfoService.getInfo().pipe(tap((response: ICurrentUserInfo) => this.setCurrentUser(response)));
  }

  public getUserDefaultRoute(): string {
    const userInfo = this.getCurrentUserInfo();
    const role = userInfo ? userInfo.user.roles[0] : 'ROLE_UNAUTHORIZED';

    return this.USERS_DEFAULT_ROUTES.get(role);
  }

  private getUsersDefaultRoutes(): Map<RoleName | '', string> {
    const { auth, registration, dash } = RouteUrls;
    return new Map([
      ['ROLE_UNAUTHORIZED', auth.login],
      ['ROLE_ADMIN', dash.super],
      ['ROLE_DRIVER', dash.driver],
      ['ROLE_FLEET_OWNER', dash.fleet],
      ['ROLE_FLEET_ADMIN', dash.fleet],
      ['ROLE_DRIVER_INACTIVE', `${registration.driver}/edit`],
      //['ROLE_DRIVER_INCOMPLETE', 'driverFirstLogin'],
      ['ROLE_FLEET_OWNER_INACTIVE', `${registration.fleet}/edit`],
      ['ROLE_FLEET_ADMIN_INACTIVE', `${registration.fleet}/admin/edit`],
      ['ROLE_FLEET_OWNER_INCOMPLETE', `${registration.fleet}/firstLogin`],
      ['ROLE_G7_BILLING_USER', dash.billingUser],
      ['ROLE_G7_BILLING_USER_INACTIVE', `${registration.billingUser}/edit`],
      ['ROLE_G7_INCOMPLETE', `${registration.billingUser}/edit`],
      ['ROLE_PHONE_ADVISOR', dash.phoneAdvisor],
      ['ROLE_PHONE_ADVISOR_INACTIVE', `${registration.phoneAdvisor}/edit`],
      ['ROLE_DISPATCHER', dash.dispatcher],
      ['ROLE_DISPATCHER_INACTIVE', DISPATCHER_ROUTE.EDIT_REG],
      ['ROLE_DISPATCHER_INCOMPLETE', DISPATCHER_ROUTE.EDIT_REG],
    ]);
  }
}
