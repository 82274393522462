import { Injectable } from '@angular/core';
import {
  AutomaticDispatchOrderClass,
  DispatchOrderWrapperClass,
  getAllFieldsEditableTimeLimit,
  getPartialEditTimeLimit,
  ManualDispatchOrderClass,
} from '@common/models/dispatch-order';
import { TimeContraintParams } from '@common/models/dispatch-order/dispatch-order.interfaces';
import { CustomerStatusValue } from '@core/constant/customer-status.constant';
import { OrderStatusType } from '@core/constant/order-status.constant';
import { PhoneAdvisorOrder } from '@core/models';
import dayjs from 'dayjs';
import { isNull } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

/**
 * encapsulates ui-rules for edit/cancel order buttons
 */
@Injectable()
export class UiOrderRulesService {
  public readonly activeOrderDateLimit$: Observable<dayjs.Dayjs | null>;
  public readonly editableOrderDateLimit$: Observable<dayjs.Dayjs | null>;
  public readonly order$: Observable<PhoneAdvisorOrder>;
  public readonly canCancelOrEditOrder$: Observable<boolean>;
  public readonly isOrderReserved$: Observable<boolean>;
  public readonly isExceptionalOrder$: Observable<boolean>;
  public readonly isManualDispatch$: Observable<boolean>;
  public readonly hasAssignedDriver$: Observable<boolean>;
  public readonly canOpenException$: Observable<boolean>;
  public readonly isCancelOrEditForbidden$: Observable<boolean>;

  public readonly orderTypes = {
    automatic: new AutomaticDispatchOrderClass(),
    manual: new ManualDispatchOrderClass(),
  } as const;

  private readonly orderSubject = new BehaviorSubject<PhoneAdvisorOrder | null>(null);
  private readonly canCancelOrEditOrderSubject = new BehaviorSubject(true);
  private readonly isManualDispatchSubject = new BehaviorSubject(false);
  // private readonly wrappedOrder$: Observable<DispatchOrderWrapperClass>;

  private constraints: TimeContraintParams | null = null;

  private readonly timerToCheckMinDate$: Observable<number | null>;

  constructor() {
    this.isManualDispatch$ = this.isManualDispatchSubject.asObservable();

    this.canCancelOrEditOrder$ = this.canCancelOrEditOrderSubject.asObservable();
    this.order$ = this.orderSubject.asObservable();

    this.activeOrderDateLimit$ = this.order$.pipe(
      filter(_ => !isNull(this.constraints)),
      tap(order => {
        this.constraints.orderDateRaw = order.date;
      }),
      map(() => getPartialEditTimeLimit(this.constraints))
    );
    this.editableOrderDateLimit$ = this.order$.pipe(
      filter(_ => !isNull(this.constraints)),
      tap(order => (this.constraints.orderDateRaw = order.date)),
      map(() => getAllFieldsEditableTimeLimit(this.constraints))
    );
    this.isOrderReserved$ = this.activeOrderDateLimit$.pipe(
      map(minDate => this.dispatchOrder.isOrderReserved(minDate))
    );
    this.isExceptionalOrder$ = this.order$.pipe(map(order => order?.exceptional));
    this.isCancelOrEditForbidden$ = this.order$.pipe(
      map(order => this.dispatchOrder.isCancelOrEditForbidden(order.status))
    );
    this.canOpenException$ = combineLatest([this.hasAssignedDriver$, this.isExceptionalOrder$]).pipe(
      map(([hasAssignedDriver, isExceptionalOrder]) => {
        const canOpenExceptionByStatus = this.dispatchOrder.canOpenExceptionByStatus(this.orderSubject.value.status);
        return canOpenExceptionByStatus && hasAssignedDriver && !isExceptionalOrder;
      })
    );
    this.timerToCheckMinDate$ = this.activeOrderDateLimit$.pipe(
      withLatestFrom(this.isOrderReserved$),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, isOrderReserved]) =>
        timer(0, 20_000).pipe(
          tap(() => {
            this.canCancelOrEditOrderSubject.next(isOrderReserved);
          }),
          takeUntil(this.canCancelOrEditOrder$.pipe(map(can => !can)))
        )
      )
    );
  }

  public get dispatchOrder(): ManualDispatchOrderClass | AutomaticDispatchOrderClass {
    const isAutomaticDispatch = !this.isManualDispatchSubject.value;
    const order = isAutomaticDispatch ? this.orderTypes.automatic : this.orderTypes.manual;
    order.setTimeConstraints(this.constraints);
    return order;
  }

  public canEditOrder() {
    const order = this.orderSubject.value;
    return this.dispatchOrder.canCancelOrEdit(order);
  }

  public setIsManualDispatchFlag(isManual: boolean): void {
    this.isManualDispatchSubject.next(isManual);
  }

  public canOpenExceptionByStatus(status: OrderStatusType) {
    return this.dispatchOrder.canOpenExceptionByStatus(status);
  }

  public setTimeConstaints(constraints: Partial<TimeContraintParams>) {
    if (isNull(constraints) || !constraints) {
      console.warn('[UiOrderRules.setTimeConstraints] constraints is undefined');
      return;
    }

    if (DispatchOrderWrapperClass.timeConstraintParamsGuard(constraints)) {
      this.constraints = constraints;
      return;
    }

    if (constraints?.dispatchBeforeMinutes) {
      const { dispatchBeforeMinutes } = constraints;
      this.constraints = { ...this.constraints, dispatchBeforeMinutes };
    }

    if (constraints?.updateTimeLimitMinutes) {
      const { updateTimeLimitMinutes } = constraints;
      this.constraints = { ...this.constraints, updateTimeLimitMinutes };
    }

    if (constraints?.orderDateRaw) {
      const { orderDateRaw } = constraints;
      this.constraints = { ...this.constraints, orderDateRaw };
    }
  }

  public setOrder(order: PhoneAdvisorOrder): void {
    this.orderSubject.next(order);
  }

  // public canEditOnlyAddressByTime(params?: TimeContraintParams) {
  //   const now = dayjs();
  //   const minDate = this.getMinDateToModifyOnlyAddresses(params ? params : this.constraints);
  //   const maxDate = this.getMaxDateToModifyOnlyAddresses(params ? params : this.constraints);

  //   return now.isAfter(minDate) && now.isBefore(maxDate);
  // }

  // public doesTimeAllowToChangeAllOrderFields(params: TimeContraintParams): boolean {
  //   const now = dayjs();
  //   const minDateToModifyOnlyAddress = this.getMinDateToModifyOnlyAddresses(params);

  //   return now.isBefore(minDateToModifyOnlyAddress);
  // }

  // public isEditAllowedByTime(params: DispatchBeforeConstraintParams) {
  //   const now = dayjs();
  //   const maxDate = this.getMaxDateToModifyOnlyAddresses(params);

  //   return now.isBefore(maxDate);
  // }

  public isCustomerBlocked(status: CustomerStatusValue): boolean {
    return status === 'BLOCKED';
  }

  // public canEditOrder(isAutoDispatch: boolean): boolean {
  //   debugger;
  //   const order = this.orderSubject.value;
  //   if (!order) {
  //     console.warn('[UiOrderRulesService.canEditOrder] No Order Was Found');
  //     return false;
  //   }

  //   const isNotCancelled = !this.isOrderCancelled(order.status);
  //   const isNotExceptional = !this.isOrderExceptional();
  //   // const hasNoDriver = !this.hasAssignedDriver();
  //   const isAllowedByTime = this.isEditAllowedByTime({
  //     dispatchBeforeMinutes: this.constraints.dispatchBeforeMinutes,
  //     orderDateRaw: order.date
  //   });
  //   const isAllowedByStatus = this.canCancelOrModifyByStatus(order.status);

  //   const canEditOrder = isNotCancelled && isNotExceptional && isAllowedByTime && isAllowedByStatus;
  //   this.canCancelOrEditOrderSubject.next(canEditOrder);

  //   return canEditOrder;
  // }

  private getMinimalDateToModifyOrder(dateRaw: string): dayjs.Dayjs {
    const orderDate = dayjs(dateRaw);
    const minutesToSubtract = this.constraints.dispatchBeforeMinutes + this.constraints.updateTimeLimitMinutes;
    const minimalDate = orderDate.subtract(minutesToSubtract, 'minutes');

    this.timerToCheckMinDate$.subscribe();
    return minimalDate;
  }

  // private getMinDateToModifyOnlyAddresses({
  //   dispatchBeforeMinutes: dispatchBefore,
  //   updateTimeLimitMinutes: updateTimeLimit,
  //   orderDateRaw
  // }: TimeContraintParams): dayjs.Dayjs {
  //   const orderDate = dayjs(orderDateRaw);

  //   return orderDate.subtract(dispatchBefore + updateTimeLimit, 'minutes');
  // }

  // private getMaxDateToModifyOnlyAddresses({
  //   dispatchBeforeMinutes: dispatchBefore,
  //   orderDateRaw
  // }: DispatchBeforeConstraintParams): dayjs.Dayjs {
  //   const orderDate = dayjs(orderDateRaw);
  //   return orderDate.subtract(dispatchBefore, 'minutes');
  // }
}
