/**
 * Pragmatik-RN7 WEB API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * field enum that could be used to specify sort field in drivers search request
 */
export type DriverSearchFieldEnumWebApiModel = 'NAME';

export const DriverSearchFieldEnumWebApiModel = {
  Name: 'NAME' as DriverSearchFieldEnumWebApiModel,
};
