import { OrderStatusType } from '@core/constant/order-status.constant';
import dayjs from 'dayjs';

import { DispatchOrderWrapperClass } from './abstract-dispatch-order.class';
import { getPartialEditTimeLimit } from './get-active-order-date-limit.function';
import { getAllFieldsEditableTimeLimit } from './get-editable-order-date-limit.function';

export class AutomaticDispatchOrderClass extends DispatchOrderWrapperClass {
  public readonly type = 'automatic' as const;
  constructor() {
    super();
    this.rulesByOrderStatus.canOpenException.push('CONFIRMED');
  }

  isEditAllowed(status: OrderStatusType): boolean {
    return this.isAllowedToEditAllFields(status) || this.isOnlyAddressEditAllowed(status);
  }

  isAllowedToEditAllFields(status: OrderStatusType): boolean {
    if (!this.hasTimeConstaints()) {
      console.warn('NO TIME CONSTRAINTS PROVIDED, IS IT INTENTIONAL?');
      return false;
    }

    const partialEditDateLimit = getPartialEditTimeLimit(this.timeConstraints);

    const now = dayjs();
    const isOrderEditable = {
      byTime: now.isBefore(partialEditDateLimit),
      byStatus: this.rulesByOrderStatus.canCancelOrEdit.includes(status),
    };

    return !this.isOnlyAddressEditAllowed(status) && isOrderEditable.byStatus && isOrderEditable.byTime;
  }

  isOnlyAddressEditAllowed(status: OrderStatusType) {
    const now = dayjs();
    const partialEditDateLimit = getPartialEditTimeLimit(this.timeConstraints);
    const fullEditOrderDateLimit = getAllFieldsEditableTimeLimit(this.timeConstraints);

    const isOnlyAddressesAllowed = {
      byTime: now.isBefore(fullEditOrderDateLimit) && now.isAfter(partialEditDateLimit),
      byStatus: this.rulesByOrderStatus.canCancelOrEdit.includes(status),
    };

    return isOnlyAddressesAllowed.byTime && isOnlyAddressesAllowed.byStatus;
  }

  isAllowedToEditOnlyAddressesOnlyByException(status: OrderStatusType): boolean {
    throw new Error('Method [AutoDispatchOrderClass.isAllowedToEditOnlyAddressesOnlyByException] not implemented.');
  }
}
