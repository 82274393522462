import { Action, createReducer, on } from '@ngrx/store';

import * as PendingRequestsActions from '../actions/pending-requests.actions';

export const pendingRequestsFeatureKey = 'pending-requests';

export interface State {
  count: number;
}

export const initialState: State = {
  count: 0,
};

const pendingRequestsReducer = createReducer(
  initialState,
  on(PendingRequestsActions.incrementPendingRequestsCount, state => ({ count: state.count + 1 })),
  on(PendingRequestsActions.decrementPendingRequestsCount, state =>
    state.count ? { count: state.count - 1 } : initialState
  )
);

export const reducer = (state: State | undefined, action: Action) => pendingRequestsReducer(state, action);
