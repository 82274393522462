import { AuthStore } from '@auth/store';
import { ROLE, RoleName } from '@core/constant';
import { RouteUrls } from '@core/constant/route-urls';
import { USER_TYPE } from '@core/constant/user-type.enum';
import { ICurrentUserInfo, IGeneralUserInfo } from '@core/models/user-info.model';
import { createSelector } from '@ngrx/store';

import * as RouterSelectors from './router.selectors';

const TOOLBAR_HIDDEN_URL = {
  [RouteUrls.auth.login]: true,
  '/welcome': true,
};

const MESSAGES_ICON_ROLES = {
  [ROLE.FLEET_OWNER]: true,
  [ROLE.FLEET_ADMIN]: true,
  [ROLE.G7]: true,
};

const NOTIFY_ICON_ROLES = {
  ...MESSAGES_ICON_ROLES,
  [ROLE.DRIVER]: true,
};

export const isUserToolbarVisible = createSelector(
  RouterSelectors.selectRouterUrl,
  AuthStore.userInfoSelector,
  (url: string, userInfo: ICurrentUserInfo) => !TOOLBAR_HIDDEN_URL[url] && !!userInfo
);

export const isProfileIconVisible = createSelector(AuthStore.userSelector, (state: IGeneralUserInfo) =>
  state ? state?.type !== USER_TYPE.ADMIN : null
);

export const isNotifyIconVisible = createSelector(AuthStore.userSelector, (state: IGeneralUserInfo) =>
  (state?.roles ?? []).some((role: RoleName) => NOTIFY_ICON_ROLES[role])
);

export const isMessageIconVisible = createSelector(AuthStore.userSelector, (state: IGeneralUserInfo) =>
  (state?.roles ?? []).some((role: RoleName) => MESSAGES_ICON_ROLES[role])
);
