import { createAction, props } from '@ngrx/store';

import { IPaginationState, ISortingState } from '../../../../models/common.model';
import { ICustomersData, ICustomersDataParams } from '../../../../models/customer.model';

export const init = createAction('[Customers] Init');
export const destroy = createAction('[Customers] Destroy');
export const getCustomers = createAction('[Customers] Get Customers');
export const getCustomersSuccess = createAction(
  '[Customers] Get Customers Success',
  props<{ customersData: ICustomersData }>()
);
export const getCustomersError = createAction('[Customers] Get Customers Error');
export const updateFilterModel = createAction(
  '[Customers] Update Filter Model',
  props<{ filterModel: Partial<ICustomersDataParams> }>()
);
export const updatePagination = createAction(
  '[Customers] Update Pagination',
  props<{ pagination: Partial<IPaginationState> }>()
);
export const updateSorting = createAction('[Customers] Update Sorting', props<{ sorting: Partial<ISortingState> }>());
