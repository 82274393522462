import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { G7_ORDER_PROCESSING_STATUS } from './g7-order.constant';

@Injectable({ providedIn: 'root' })
export class G7OrderStatusFactory {
  public data: any;

  public allStatuses = [
    { name: G7_ORDER_PROCESSING_STATUS.WAITING_FLEET },
    { name: G7_ORDER_PROCESSING_STATUS.WAITING_G7 },
    { name: G7_ORDER_PROCESSING_STATUS.VALIDATE_G7 },
    { name: G7_ORDER_PROCESSING_STATUS.TRANSMIT_ERROR },
    { name: G7_ORDER_PROCESSING_STATUS.CHANGED },
    { name: G7_ORDER_PROCESSING_STATUS.TRANSMITTED },
  ];

  constructor(private readonly translate: TranslateService) {
    this.data = [
      {
        title: this.translate.instant('CORE_SHOW_ALL_OPT'),
        value: null,
      },
    ];
  }

  public all() {
    return this.filter(() => true);
  }

  public getAll() {
    return this.allStatuses.map(status => ({
      title: this.translate.instant('GO_ORDER_STATUS_' + status.name),
      value: status.name,
    }));
  }

  public getAllStatuses(): string[] {
    return [
      G7_ORDER_PROCESSING_STATUS.WAITING_FLEET,
      G7_ORDER_PROCESSING_STATUS.WAITING_G7,
      G7_ORDER_PROCESSING_STATUS.VALIDATE_G7,
      G7_ORDER_PROCESSING_STATUS.TRANSMIT_ERROR,
      G7_ORDER_PROCESSING_STATUS.CHANGED,
      G7_ORDER_PROCESSING_STATUS.TRANSMITTED,
      G7_ORDER_PROCESSING_STATUS.WAITING_DATA,
    ];
  }

  private filter(f) {
    return this.data.concat(
      this.allStatuses.filter(f).map(status => ({
        title: this.translate.instant('GO_ORDER_STATUS_' + status.name),
        value: status.name,
      }))
    );
  }
}
