import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

const getCurrentDateStr = (): string => {
  return moment().format('L');
};
const convertDateStrToMoment = (date: string): moment.Moment => {
  return moment(date, dateFormat);
};
const convertDateTimeStrToMoment = (date: string, time: string): moment.Moment => {
  const resultMoment = convertDateStrToMoment(date);

  const [hh, mm] = time.split(':');
  resultMoment.set('hour', +hh);
  resultMoment.set('minute', +mm);

  return resultMoment;
};

export const helpers = {
  getCurrentDateStr,
  convertDateStrToMoment,
  convertDateTimeStrToMoment,
  autocompleteDateMoment: (date: string): moment.Moment => {
    const [dd, mm, yyyy] = date.split('/');
    if (Number.isInteger(+dd) && Number.isNaN(+yyyy)) {
      const resultDate = moment();

      if (Number.isInteger(+mm)) {
        if (+mm > 12) {
          return moment.invalid();
        }

        resultDate.set('month', +mm - 1);
      }

      if (resultDate.daysInMonth() < +dd) {
        return moment.invalid();
      }

      resultDate.set('date', +dd);

      return resultDate;
    }

    return convertDateStrToMoment(date);
  },
};
