import { IAdvert } from '@core/models/advert.model';
import { Action, createReducer, on } from '@ngrx/store';

import * as AdvertActions from '../actions/advert.actions';

export const advertFeatureKey = 'advert';

export type State = IAdvert;

export const initialState: State | null = null;

export const advertReducer = createReducer(
  initialState,
  // @ts-ignore
  on(AdvertActions.getAdvertSuccess, (state, { advert }) => advert),
  on(AdvertActions.getAdvertError, () => initialState)
);

export const reducer = (state: State | undefined, action: Action) => advertReducer(state, action);
