<div class="header-group">
  <h1>{{ 'THANK_YOU' | translate }}!</h1>
</div>
<div class="header-group">
  {{ 'ACCOUNT_CREATED' | translate: { user: user | translate | lowercase } }}
</div>
<h2 class="bottom-space">{{ 'NEXT_STEPS' | translate }}</h2>

<div class="row">
  <div class="col-md-12">
    <div class="confirmation-icon icon-email"></div>
    <div>
      <div>{{ 'CHECK_EMAIL' | translate }}</div>
      <div>{{ 'EMAIL_HAS_BEEN_SENT' | translate }}</div>
      <div>{{ 'EMAIL_CONFIRM_REQUIRED' | translate }}</div>
    </div>
  </div>
</div>
