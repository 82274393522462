import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmRegistrationComponent {
  @Input() user: string;
}
