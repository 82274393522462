import { inject, Injectable } from '@angular/core';
import { AuthStore } from '@auth/store';
import { DRIVER_SERVICE_TYPE_TAG_ID_MAP, DRIVER_VEHICLE_TYPE_TAG_ID_MAP } from '@core/constant/tag.constant';
import { ITag } from '@core/models';
import { IColorManagementFull } from '@core/models/color-management.model';
import { IDriverLicense, IDriverLicenseKind } from '@core/models/driver-license.model';
import { IUpdateDriverByFleetPayload } from '@core/models/driver.model';
import { ICountry } from '@core/models/fleet.model';
import { IDriverOrdersStats } from '@core/models/stats.model';
import { ITransportLicense } from '@core/models/transport-license.model';
import { IDriverUserInfo } from '@core/models/user-info.model';
import {
  ColorManagementService,
  CountryService,
  CustomPopupService,
  LicensesService,
  TagService,
  VehicleService,
} from '@core/services/common';
import { SessionService } from '@core/services/sessions';
import { DriverService } from '@core/services/users';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EffectsBase } from '@store/effects/base.effects';
import { from, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DriverDetailsConfirmDialogComponent } from '../../confirm-dialog/driver-details-confirm-dialog.component';
import * as DriverDetailsActions from '../actions';
import { DriverDetailsStore } from '../index';

@Injectable()
export class DriverDetailsEffects extends EffectsBase {
  private readonly sessionService = inject(SessionService);
  private readonly countryService = inject(CountryService);
  private readonly licensesService = inject(LicensesService);
  private readonly vehicleService = inject(VehicleService);
  private readonly driverService = inject(DriverService);
  private readonly colorManagementService = inject(ColorManagementService);
  private readonly tagService = inject(TagService);
  private readonly customPopupService = inject(CustomPopupService);

  public getDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getDriver),
      withLatestFrom(this.selectFromStore(AuthStore.fleetSelector)),
      switchMap(([{ driverId, colorManagement }, { colorManagementEnabled }]) =>
        this.driverService.getDriverById(driverId).pipe(
          switchMap((driver: IDriverUserInfo) => {
            const obs$: Action[] = [DriverDetailsActions.getDriverSuccess({ driver })];
            if (colorManagement && colorManagementEnabled) {
              obs$.push(DriverDetailsActions.getColorManagement());
            }
            return obs$;
          }),
          this.catchError(DriverDetailsActions.getDriverError())
        )
      )
    )
  );

  public getColorManagement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getColorManagement),
      withLatestFrom(this.selectFromStore(AuthStore.fleetSelector)),
      switchMap(([_, fleet]) =>
        this.colorManagementService.getColorManagement(fleet.id, { includeNumOfDrivers: false }).pipe(
          map((colorManagement: IColorManagementFull) =>
            DriverDetailsActions.getColorManagementSuccess({ colorManagement })
          ),
          this.catchError(DriverDetailsActions.getColorManagementError())
        )
      )
    )
  );

  public getOrdersStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getOrdersStats),
      switchMap(({ driverId }) =>
        this.sessionService.getDriverOrdersStats(driverId).pipe(
          map((ordersStats: IDriverOrdersStats) => DriverDetailsActions.getOrdersStatsSuccess({ ordersStats })),
          this.catchError(DriverDetailsActions.getOrdersStatsError())
        )
      )
    )
  );

  public getCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getCountries),
      switchMap(() =>
        this.countryService.getCountries().pipe(
          map((countries: ICountry[]) => DriverDetailsActions.getCountriesSuccess({ countries })),
          this.catchError(DriverDetailsActions.getCountriesError())
        )
      )
    )
  );

  public getDriverLicenseKinds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getDriverLicenseKinds),
      switchMap(() =>
        this.licensesService.getDriverLicenseKinds().pipe(
          map((driverLicenseKinds: IDriverLicenseKind[]) =>
            DriverDetailsActions.getDriverLicenseKindsSuccess({ driverLicenseKinds })
          ),
          this.catchError(DriverDetailsActions.getDriverLicenseKindsError())
        )
      )
    )
  );

  public getDriverLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getDriverLicense),
      switchMap(({ driverId }) =>
        this.licensesService.getDriverLicense(driverId).pipe(
          map((driverLicense: IDriverLicense) => DriverDetailsActions.getDriverLicenseSuccess({ driverLicense })),
          this.catchError(DriverDetailsActions.getDriverLicenseError())
        )
      )
    )
  );

  public getVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getVehicle),
      switchMap(({ driverId }) =>
        this.vehicleService.getDriverVehicle(driverId).pipe(
          switchMap(([vehicle]) => [
            DriverDetailsActions.getVehicleSuccess({ vehicle }),
            DriverDetailsActions.getTransportLicense({ vehicleId: vehicle.id }),
          ]),
          this.catchError(DriverDetailsActions.getVehicleError())
        )
      )
    )
  );

  public getTransportLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getTransportLicense),
      switchMap(({ vehicleId }) =>
        this.licensesService.getTransportLicense(vehicleId).pipe(
          map((transportLicense: ITransportLicense) =>
            DriverDetailsActions.getTransportLicenseSuccess({ transportLicense })
          ),
          this.catchError(DriverDetailsActions.getTransportLicenseError())
        )
      )
    )
  );

  public getDriverTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getDriverTags),
      switchMap(({ driverId }) =>
        this.tagService.getDriverTags(driverId).pipe(
          map((tags: ITag[]) => DriverDetailsActions.getDriverTagsSuccess({ tags })),
          this.catchError(DriverDetailsActions.getDriverTagsError())
        )
      )
    )
  );

  public updateDriverRating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.updateDriverRating),
      switchMap(({ driverId, rating }) =>
        this.driverService.updateDriverRating(driverId, rating).pipe(
          map((driver: IDriverUserInfo) => DriverDetailsActions.updateDriverRatingSuccess({ driver })),
          this.catchError(DriverDetailsActions.updateDriverRatingError())
        )
      )
    )
  );

  public openConfirmPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.openConfirmPopup),
      withLatestFrom(
        this.selectFromStore(DriverDetailsStore.driver),
        this.selectFromStore(DriverDetailsStore.driverLicense),
        this.selectFromStore(DriverDetailsStore.transportLicense)
      ),
      switchMap(([{ operation }, driver, driverLicense, transportLicense]) => {
        const ref = this.customPopupService.openPopup(DriverDetailsConfirmDialogComponent, {
          operation,
        });
        let action;
        const [driverId, driverLicenseId, transportLicenseId] = [driver.id, driverLicense?.id, transportLicense?.id];
        switch (operation) {
          case 'BLOCK':
            action = DriverDetailsActions.blockDriver({ driverId });
            break;
          case 'UNBLOCK':
            action = DriverDetailsActions.unblockDriver({ driverId });
            break;
          case 'VALIDATE':
            action = DriverDetailsActions.validateDriver({
              driverId,
              payload: { driverLicenseId, transportLicenseId },
            });
            break;
          case 'CONFIRM_LICENSE':
            action = DriverDetailsActions.confirmTransportLicense({ transportLicenseId });
            break;
          case 'REJECT_LICENSE':
            action = DriverDetailsActions.rejectTransportLicense({ transportLicenseId });
            break;
        }
        return from(ref.result).pipe(
          map((message: string) => (message ? action : DriverDetailsActions.closeConfirmPopup())),
          catchError(() => of(DriverDetailsActions.closeConfirmPopup()))
        );
      })
    )
  );

  public blockDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.blockDriver),
      mergeMap(({ driverId }) =>
        this.driverService.blockDriver(driverId).pipe(
          map((driver: IDriverUserInfo) => DriverDetailsActions.blockDriverSuccess({ driver })),
          this.catchError(DriverDetailsActions.blockDriverError())
        )
      )
    )
  );

  public unblockDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.unblockDriver),
      mergeMap(({ driverId }) =>
        this.driverService.unblockDriver(driverId).pipe(
          map((driver: IDriverUserInfo) => DriverDetailsActions.unblockDriverSuccess({ driver })),
          this.catchError(DriverDetailsActions.unblockDriverError())
        )
      )
    )
  );

  public validateDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.validateDriver),
      mergeMap(({ driverId, payload }) =>
        this.driverService.approveDriver(driverId, payload).pipe(
          map(() => DriverDetailsActions.validateDriverSuccess()),
          this.catchError(DriverDetailsActions.validateDriverError())
        )
      )
    )
  );

  public validateDriverSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.validateDriverSuccess),
      withLatestFrom(this.selectFromStore(DriverDetailsStore.driver), this.selectFromStore(DriverDetailsStore.vehicle)),
      switchMap(([_, driver, vehicle]) => [
        DriverDetailsActions.getValidatedDriver({ driverId: driver.id }),
        DriverDetailsActions.getDriverLicense({ driverId: driver.id }),
        DriverDetailsActions.getTransportLicense({ vehicleId: vehicle.id }),
      ])
    )
  );

  public getValidatedDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.getValidatedDriver),
      switchMap(({ driverId }) =>
        this.driverService.getDriverById(driverId).pipe(
          map((driver: IDriverUserInfo) => DriverDetailsActions.getValidatedDriverSuccess({ driver })),
          this.catchError(DriverDetailsActions.getValidatedDriverError())
        )
      )
    )
  );

  public confirmTransportLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.confirmTransportLicense),
      mergeMap(({ transportLicenseId }) =>
        this.licensesService.approveTransportLicense(transportLicenseId).pipe(
          map(() => DriverDetailsActions.confirmTransportLicenseSuccess()),
          this.catchError(DriverDetailsActions.confirmTransportLicenseError())
        )
      )
    )
  );

  public rejectTransportLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.rejectTransportLicense),
      mergeMap(({ transportLicenseId }) =>
        this.licensesService.cancelTransportLicense(transportLicenseId).pipe(
          map(() => DriverDetailsActions.rejectTransportLicenseSuccess()),
          this.catchError(DriverDetailsActions.rejectTransportLicenseError())
        )
      )
    )
  );

  public saveDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.saveDriver),
      mergeMap(({ payload, serviceVehicleTypeTagsIds }) =>
        this.driverService.updateDriverByFleet(payload).pipe(
          switchMap((response: IUpdateDriverByFleetPayload) => [
            DriverDetailsActions.saveDriverSuccess({ response }),
            DriverDetailsActions.updateDriverTags({ serviceVehicleTypeTagsIds }),
          ]),
          this.catchError(DriverDetailsActions.saveDriverError())
        )
      )
    )
  );

  public updateDriverTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverDetailsActions.updateDriverTags),
      withLatestFrom(this.selectFromStore(DriverDetailsStore.driver), this.selectFromStore(DriverDetailsStore.tags)),
      switchMap(([{ serviceVehicleTypeTagsIds }, driver, storeTags]) =>
        this.tagService.getDriverTags(driver.id).pipe(
          catchError(() => of(storeTags)),
          mergeMap((res: ITag[]) => {
            const tagsIdsExceptServiceVehicleType = res
              .map(t => t.id)
              .filter(id => !DRIVER_SERVICE_TYPE_TAG_ID_MAP[id] && !DRIVER_VEHICLE_TYPE_TAG_ID_MAP[id]);
            return this.tagService
              .saveDriverTags(driver.id, [...serviceVehicleTypeTagsIds, ...tagsIdsExceptServiceVehicleType])
              .pipe(
                map((tags: ITag[]) => {
                  this.alertSuccess('FD_SAVED_SUCCESSFULLY_MESSAGE');

                  return DriverDetailsActions.updateDriverTagsSuccess({ tags });
                }),
                this.catchError(DriverDetailsActions.updateDriverTagsError())
              );
          })
        )
      )
    )
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(DriverDetailsActions.init),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(DriverDetailsActions.destroy)))))
    );
  }
}
