export const ORDERS_STATS_PERIOD = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
} as const;

export type OrdersStatsPeriodType = (typeof ORDERS_STATS_PERIOD)[keyof typeof ORDERS_STATS_PERIOD];

export enum CUSTOM_RANGE {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  CURRENT_WEEK = 'CURRENT_WEEK',
  PREVIOUS_WEEK = 'PREVIOUS_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
}
