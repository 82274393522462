<div class="row" [formGroup]="form">
  <div class="col-lg-6 form-group">
    <label for="service-type" class="azz-transparent-input-label">
      {{ 'PA_CREATE_NEW_ORDER_SERVICES_HEADER' | translate }}*
    </label>
    <select
      id="service-type"
      name="service_type"
      formControlName="serviceType"
      [compareWith]="compareFn"
      class="form-control azz-transparent-input azz-validate azz-expandable"
    >
      <option
        *ngFor="let item of serviceTypes; trackBy: trackByFn"
        [ngValue]="item"
        [disabled]="isServiceTypeDisabled(item)"
      >
        {{ 'SERVICE_TYPE_' + item.id | translate }}
      </option>
    </select>
    <div *ngIf="form.controls?.serviceType?.errors && form.controls?.serviceType?.touched" class="form-control__error">
      <small *ngIf="form.controls?.serviceType?.errors?.required">{{
        'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
      }}</small>
    </div>
  </div>
  <div class="col-lg-6 form-group">
    <label for="vehicle-type" class="azz-transparent-input-label">
      {{ 'PA_CREATE_NEW_ORDER_VEHICLE_TYPE_HEADER' | translate }}*
    </label>
    <select
      id="vehicle-type"
      name="vehicle_type"
      class="form-control azz-transparent-input azz-validate azz-expandable"
      formControlName="vehicleType"
      [compareWith]="compareFn"
    >
      <option
        *ngFor="let item of vehicleTypes; trackBy: trackByFn"
        [ngValue]="item"
        [disabled]="isVehicleTypeDisabled(item)"
      >
        {{ 'VEHICLE_TYPE_' + item.id | translate }}
      </option>
    </select>
    <div *ngIf="form.controls?.vehicleType?.errors && form.controls?.vehicleType?.touched" class="form-control__error">
      <small *ngIf="form.controls?.vehicleType?.errors?.required">{{
        'CORE_REQUIRED_FIELD_VALIDATION_ERROR' | translate
      }}</small>
    </div>
  </div>
</div>
