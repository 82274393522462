import { RouteUrls } from '@core/constant/route-urls';

export enum NO_INCREMENT_PEND_REQ_COUNT_URL {
  LOGOUT = 'api/v1/auth/logout',
  LOGOUT_AGENT = 'user/api/v1/phoneAdvisor/logoutAgent',
}

export const SPEC_UNAUTHORIZED_URL = {
  ACTIVE_DRIVER_SESSION: 'driversession/active',
  LOGIN: RouteUrls.auth.login,
};

export const SPEC_FORBIDDEN_URL = {
  LOCK_EXCEPTION: ['order/api/v1/exclusion/', 'lock'],
  KILL_DRIVER_SESSION: 'driver-session/api/v1/driversession',
};
