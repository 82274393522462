import { Action, createReducer, on } from '@ngrx/store';

import * as ExceptionsCountActions from '../actions/exceptions-count.actions';

export const exceptionsCountFeatureKey = 'exceptions-count';

export type State = number;

const initialState: State = 0;

const exceptionsCountReducer = createReducer(
  initialState,
  on(ExceptionsCountActions.getExceptionsCountSuccess, (state, { count }) => count),
  on(ExceptionsCountActions.getExceptionsCountError, ExceptionsCountActions.resetExceptionsCount, () => initialState)
);

export const reducer = (state: State | undefined, action: Action) => exceptionsCountReducer(state, action);
