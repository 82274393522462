import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IPoi, IPoiData } from '../../../models/poi.model';

@Component({
  selector: 'azz-table-points-of-interest',
  templateUrl: './table-points-of-interest.component.html',
  styleUrls: ['./table-points-of-interest.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePointsOfInterestComponent {
  @Input() poiData: IPoiData;
  @Input() hasRestrictionToShowG7Poi: boolean;
  @Output() deletePoi = new EventEmitter<IPoi>();
  @Output() updatePoi = new EventEmitter<string>();
  @Output() clickPoi = new EventEmitter<IPoi>();
  private isFilteredByPoiRowClick = false;

  public onDeletePoi(poi: IPoi): void {
    this.deletePoi.emit(poi);
  }

  public onUpdatePoi(poiId: string): void {
    this.updatePoi.emit(poiId);
  }

  public onPoiClick(evt: any, poi: IPoi): void {
    if (evt.target.classList.contains('azz-circle-btn')) {
      evt.preventDefault();
    } else {
      if (!this.isFilteredByPoiRowClick) {
        this.clickPoi.emit(poi);
        this.isFilteredByPoiRowClick = true;
      }
    }
  }

  public setFilteredByPoiRowClickValue(value: boolean): void {
    this.isFilteredByPoiRowClick = value;
  }
}
