import { Component, Input } from '@angular/core';

@Component({
  selector: 'azz-icon-logo-G7',
  standalone: true,
  template: `
    <svg [attr.width]="iconSize" viewBox="0 0 41 20" fill="none" role="img" aria-labelledby="logoG7Title">
      <title id="logoG7Title">Logo G7</title>
      <path
        d="M24.0718 19.7657C28.2774 14.3216 31.3948 9.70923 34.9415 3.76041C32.287 3.53476 27.4987 3.64648 22.2649 4.0325L22.8816 0.494064C30.5962 -0.422419 38.44 0.148409 41 0.516141C37.3312 7.92708 32.7616 14.3763 28.7251 19.7657H24.0718Z"
        fill="#E0052D"
      />
      <path
        d="M16.638 16.2301C15.5845 16.3918 14.2537 16.4996 13.0333 16.4996C6.87727 16.4996 4.10402 14.022 4.10402 10.0369C4.10402 6.32064 6.87728 3.73562 13.8099 3.73562C15.5845 3.73562 17.1929 3.84337 19.3002 4.00474L18.6901 0.50427C16.9155 0.181347 15.2514 0.0192871 13.6433 0.0192871C4.32624 0.0192871 0 4.22062 0 10.0369C0 16.4996 4.49219 20.0001 12.8665 20.0001C15.5845 20.0001 17.969 19.8383 20.3543 19.3539V10.7367H16.638V16.2301Z"
        fill="#E0052D"
      />
    </svg>
  `,
})
export class IconLogoG7Component {
  @Input() public iconSize = '41';
}
