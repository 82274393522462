/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, inject, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmDialogComponent } from '@common/components/dialog/confirm-dialog';
import { G7DenyValidationDialogComponent } from '@common/components/dialog/g7-deny-validation-dialog';
import { NgOnDestroyService } from '@common/services';
import { G7_ORDER_PROCESSING_STATUS, G7_ORDER_STATUS } from '@core/constant/g7-order.constant';
import { RoleName } from '@core/constant/role.constant';
import { IObjectMap } from '@core/models/common.model';
import { G7Order } from '@core/models/g7-order.model';
import { ITip, ITipGrid } from '@core/models/tip-grid.model';
import { ICurrentUserInfo } from '@core/models/user-info.model';
import { FirebaseService, LocalStorageService } from '@core/services/common';
import { G7OrdersService } from '@core/services/orders/g7-orders.service';
import { G7Service } from '@core/services/users/g7.service';
import { UserService } from '@core/services/users/user.service';
import { FormService } from '@core/utils/form-service';
import { sortNumber } from '@core/utils/util';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootService } from '@services/root.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, mergeMap, takeUntil } from 'rxjs/operators';

import { SearchAddressPoiAutocompleteComponent } from '../azz-custom-autocomplete/search-address-poi-autocomplete';
import { G7NotificationsComponent } from '../g7-notifications';
import { isFleetMessageAllowed, isG7MessageAllowed } from '../g7-orders/components/common/utils';

export const FLEET_DISABLED_FIELDS_MAP = {
  [G7_ORDER_PROCESSING_STATUS.WAITING_FLEET]: false,
  [G7_ORDER_PROCESSING_STATUS.WAITING_G7]: true,
  [G7_ORDER_PROCESSING_STATUS.VALIDATE_G7]: true,
  [G7_ORDER_PROCESSING_STATUS.CHANGED]: true,
  [G7_ORDER_PROCESSING_STATUS.TRANSMITTED]: true,
  [G7_ORDER_PROCESSING_STATUS.TRANSMIT_ERROR]: true,
};

export const G7_DISABLED_FIELDS_MAP = {
  [G7_ORDER_PROCESSING_STATUS.WAITING_FLEET]: true,
  [G7_ORDER_PROCESSING_STATUS.WAITING_G7]: false,
  [G7_ORDER_PROCESSING_STATUS.VALIDATE_G7]: false,
  [G7_ORDER_PROCESSING_STATUS.CHANGED]: false,
  [G7_ORDER_PROCESSING_STATUS.TRANSMITTED]: true,
  [G7_ORDER_PROCESSING_STATUS.TRANSMIT_ERROR]: true,
};

@Component({
  selector: 'azz-trip-details',
  templateUrl: './azz-trip-details.component.html',
  styleUrls: ['./azz-trip-details.component.less'],
  providers: [NgOnDestroyService],
})
export class AzzTripDetailsComponent implements OnInit {
  protected readonly rootService = inject(RootService);

  @Input() backState: string;
  @Input() mode: 'fleet' | 'g7';
  @Input() navigateUrlForCicleComponent: any;

  @ViewChild('formElemRef') formElemRef: ElementRef;
  @ViewChild(ConfirmDialogComponent) confirmDialog: ConfirmDialogComponent;
  @ViewChild(G7DenyValidationDialogComponent) declineDialog: G7DenyValidationDialogComponent;
  @ViewChild(G7NotificationsComponent) g7NotifyComponent: G7NotificationsComponent;
  @ViewChildren(SearchAddressPoiAutocompleteComponent)
  autoComplete: QueryList<SearchAddressPoiAutocompleteComponent>;

  public order: G7Order;
  public orderId: number;
  public messageToFleet = '';

  public isDeclineSpinner: boolean;
  public isConfirmSpinner: boolean;
  public isSaveSpinner: boolean;
  public loadingIndicator: boolean;

  public uploadedFiles: any[] = [];
  public deletedFiles: any[] = [];
  public initialAttachments: any[] = [];
  public imagesToBeUploaded: any[];
  public gridTips: ITip[];
  public defaultTotalPrice = 9.99;
  public G7_ORDER_PROCESSING_STATUS = G7_ORDER_PROCESSING_STATUS;
  public categories: ITipGrid[] = [];
  public categoryValue: number;
  public isAllowedToChangeCategory: boolean;

  public wrongAddress: boolean;
  public pickupAddress: any = {};
  public destinationAddress: any = {};

  public orderChanged = false;
  public fileUpdated = false;
  public initialPickupAddress: any = {};
  public initialDestinationAddress: any = {};
  public userRoles: RoleName[];

  private readonly addressChangedSubject: Subject<null> = new Subject<null>();

  private initialOrder: G7Order;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly g7Service: G7Service,
    private readonly firebaseService: FirebaseService,
    private readonly g7OrdersService: G7OrdersService,
    private readonly translate: TranslateService,
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
    private readonly formService: FormService,
    private readonly destroyed$: NgOnDestroyService
  ) {}

  public ngOnInit(): void {
    this.userRoles = this.userService.getCurrentUserInfo()?.user?.roles;
    this.activatedRoute.params
      .pipe(
        takeUntil(this.destroyed$),
        mergeMap((params: Params) => {
          this.orderId = Number(params.orderId);
          this.loadingIndicator = true;
          return forkJoin([this.loadOrder$(), this.loadTips$(), this.loadAttachments$()]).pipe(
            finalize(() => (this.loadingIndicator = false)),
            takeUntil(this.destroyed$)
          );
        })
      )
      .subscribe(([order, tips, attachments]: [G7Order, ITip[], any]) => {
        this.handleOrder(order);
        this.handleTips(tips);
        this.handleAttachments(attachments);
      });

    this.listenOrderChange();
  }

  public saveAsBillingUser(): void {
    if (this.isAddressNotSelected()) {
      return;
    }
    this.isSaveSpinner = true;
    this.setNoShowPriceBeforeSend();
    this.g7OrdersService
      .saveG7orders(this.orderId, this.generateSavePayloadForBillingUser(), 'billing')
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.isSaveSpinner = false))
      )
      .subscribe(() => {
        this.g7NotifyComponent.watcher$.next(null);
        this.resetErrors();
        this.router.navigate([`${this.backState}`]);
      });
  }

  public saveAsFleet(): void {
    if (!this.isCategoryChosen()) {
      this.rootService.alerts.error('CHOOSE_TIP_CATEGORY_ERROR');
      return;
    }

    if (this.isAddressNotSelected()) {
      return;
    }

    const userData: ICurrentUserInfo = this.userService.getCurrentUserInfo();
    this.isSaveSpinner = true;
    this.divideImagesTypes();
    const images$ = [of('')];
    if (this.imagesToBeUploaded.length) {
      this.imagesToBeUploaded.map(image => {
        images$.push(
          /// users/{userId}/g7Orders/{orderId}/attachments/{fileName}
          this.firebaseService
            .uploadFile(
              image.binary,
              `/users/${userData.user.id}/g7Orders/${this.orderId}/attachments/`,
              undefined,
              image.filenameToUpload
            )
            .pipe(map(snapshot => snapshot as any))
        );
      });
    }

    forkJoin(images$)
      .pipe(
        mergeMap((snapshots: any[]) => {
          if (snapshots.length > 1) {
            const uploadedImages = snapshots.slice(1);

            this.imagesToBeUploaded.forEach((image, index) => {
              image.filename = uploadedImages[index].metadata.name;
            });
          }
          return this.g7OrdersService.saveG7orders(this.orderId, this.generateSavePayloadForFleetUser(), '');
        }),
        finalize(() => (this.isSaveSpinner = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.g7NotifyComponent.watcher$.next(null);
        this.resetErrors();
        this.clearMessageToFleet();
        void this.router.navigate([`${this.backState}`]);
      });
  }

  public saveWithValidation(): void {
    const selectedCategory = this.getSelectedCategoryByName(this.order.tripCategory);
    const selectedTip = this.gridTips.find((grid: ITip) => grid.id === this.order.tipId);

    const dontHaveCategory = selectedCategory === undefined;
    const dontHaveTip = selectedTip === undefined;

    const showWarning = dontHaveCategory || dontHaveTip;
    if (showWarning) {
      this.rootService.alerts.error('CHOOSE_TIP_CATEGORY_ERROR');
    } else {
      this.saveForWaitingFleet(true);
    }
  }

  public saveWithoutValidation(): void {
    this.saveForWaitingFleet(false);
  }

  public saveSameOrderForWaitingFleet(): void {
    const params: any = this.generateSaveInitialPayloadForFleetUser();
    params.valid = false;

    this.g7OrdersService
      .saveFleetOrderWithValidation(this.initialOrder.tripNumber, params)
      .pipe(
        finalize(() => (this.isSaveSpinner = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.g7NotifyComponent.watcher$.next(null);
        this.resetErrors();
        this.clearMessageToFleet();
        this.router.navigate([`${this.backState}`]);
      });
  }

  public isCategoryChosen(): boolean {
    return !!this.order.tripCategory;
  }

  public isAddressBlocked() {
    const data = this.order;
    if (this.isBlockedStatus()) {
      return true;
    }

    if (data) {
      if (this.mode === 'g7') {
        return data.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_FLEET;
      } else {
        return data.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_G7 || this.isValidG7Status();
      }
    }
    return false;
  }

  public initAddressObject(address: any) {
    if (!address.value) {
      return null;
    }

    return address.value.properties.type === 'poi'
      ? {
          poi: {
            id: address.value.properties.id,
            label: address.value.properties.label,
            serviceLevel: address.value.properties.serviceLevel,
          },
        }
      : {
          state: address.value.properties.city,
          postalCode: address.value.properties.postcode,
          city: address.value.properties.city,
          street: address.value.properties.street ? address.value.properties.street : address.value.properties.name,
          // eslint-disable-next-line id-blacklist
          number: address.value.properties.housenumber || address.value.properties.number || null,
        };
  }

  public setOrderAddress(order: any, address: string, isInitial: boolean = false) {
    let properties;
    if (order[address]) {
      if (order[address].poi && order[address].poi.label) {
        properties = { ...order[address].poi, type: 'poi' };
      } else {
        properties = {
          number: order[address].number ? order[address].number.trim() : null,
          adverbeMultiplicatif: order[address].adverbeMultiplicatif,
          typeVoie: order[address].typeVoie,
          street: order[address].street,
          postalCode: order[address].postalCode,
          city: order[address].city,
        };
        properties = this.areAddressPropertiesValid(properties) ? properties : null;
      }
    }
    const addressValue = properties ? { properties } : null;
    if (!isInitial) {
      if (address === 'appointmentAddress') {
        this.pickupAddress.value = addressValue;
      } else {
        this.destinationAddress.value = addressValue;
      }
    } else {
      if (address === 'appointmentAddress') {
        this.initialPickupAddress.value = addressValue;
      } else {
        this.initialDestinationAddress.value = addressValue;
      }
    }
  }

  public setPickupAddressAutoCompleteValue() {
    if (!this.pickupAddress?.value) {
      return;
    }

    this.autoComplete.first.setAutocompleteFieldValue(this.pickupAddress.value);
  }

  public setDestinationAddressAutocompleteValue(): void {
    const autoCompleteValue = this.destinationAddress.value
      ? this.destinationAddress.value
      : this.translate.instant('GO_ORDER_UNKNOWN_ADDRESS');
    this.autoComplete.last.setAutocompleteFieldValue(autoCompleteValue);
  }

  public generateSavePayloadForBillingUser() {
    return {
      appointmentAddress: this.initAddressObject(this.pickupAddress),
      destinationAddress: this.initAddressObject(this.destinationAddress),
      price: this.order.price,
      category: this.order.tripCategory,
      toll: this.order.toll,
      totalPrice: this.order.totalPrice,
      clientAbsent: this.order.clientAbsent,
    };
  }

  public generateSavePayloadForFleetUser() {
    if (this.isBlocked()) {
      return {
        message: this.messageToFleet,
        ...this.generateImagesPayload(),
      };
    }

    return {
      appointmentAddress: this.initAddressObject(this.pickupAddress),
      destinationAddress: this.initAddressObject(this.destinationAddress),
      price: this.order.price,
      // proofFileName: this.order.proofFileName,
      category: this.order.tripCategory,
      toll: this.order.toll,
      totalPrice: this.order.totalPrice,
      clientAbsent: this.order.clientAbsent,
      message: this.messageToFleet,
      ...this.generateImagesPayload(),
    };
  }

  public goBack(): void {
    const prevRoute = this.localStorageService.getLocalStorageItem('PREV_PAGE');
    void this.router.navigateByUrl(prevRoute);
  }

  public onValidate(): void {
    if (this.isAddressNotSelected()) {
      return;
    }
    this.confirmDialog.showDialog('', this.translate.instant('GO_ORDER_ACCEPT_VALIDATION_DIALOG'), null, null);
  }

  public onDecline(): void {
    this.declineDialog.showDialog('', this.translate.instant('GO_ORDER_DENY_VALIDATION_DIALOG'), null, null);
  }

  public validate(data: any, spinnerName: string): void {
    // @ts-ignore
    this[spinnerName] = true;
    this.g7Service
      .validateG7(this.orderId, data)
      .pipe(
        takeUntil(this.destroyed$),
        // @ts-ignore
        finalize(() => (this[spinnerName] = false))
      )
      .subscribe((order: G7Order) => {
        this.order = order;
        this.handleOrder(order);
        this.g7NotifyComponent.watcher$.next(null);
        this.clearMessageToFleet();
      });
  }

  public saveErrorHandler(err: any) {
    if (err.error.validationErrors.find(error => error.path.includes('destinationAddress'))) {
      this.wrongAddress = true;
      setTimeout(() => {
        this.formService.scrollToFirstInvalidControl([this.formElemRef]);
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setDocumentImage(inputFileData: any, fileData: any, _: number): void {
    this.fileUpdated = true;

    if (inputFileData === null) {
      this.uploadedFiles.pop();
    }

    this.addressChanged();
    if (!inputFileData) {
      fileData.deleted = true;
      fileData.binary = null;
      fileData.filename = null;
      fileData.filenameToUpload = null;
      this.addNewDocument();
      //this.deletedFiles.pop();
    } else {
      fileData.binary = inputFileData.file;
      fileData.filenameToUpload = inputFileData.fileName;
      this.addNewDocument();
    }
  }

  public addNewDocument() {
    if (!this.isImageExists() || this.isMoreThenTenFiles()) {
      return;
    }
    this.uploadedFiles.push({
      id: null,
      orderG7Id: this.orderId,
      filename: null,
    });
    // if (this.isImageExists() && !this.isMoreThenTenFiles()) {
    // }
  }

  public divideImagesTypes(): void {
    // in progress
    const updatedAttachments = this.uploadedFiles
      .filter(image => image.id && !image.deleted)
      .map(image => {
        image._updated = true;
        return image;
      });
    const createdAttachments = this.uploadedFiles
      .filter(image => !image.id && !image.deleted && image.binary)
      .map(image => {
        image._created = true;
        return image;
      });
    const deletedAttachments = this.uploadedFiles
      .filter(image => image.deleted)
      .map(image => {
        image._deleted = true;
        return image;
      });
    this.imagesToBeUploaded = [...updatedAttachments, ...createdAttachments, ...deletedAttachments].filter(
      image => image.binary
    );
  }

  public generateImagesPayload() {
    const updatedAttachments = this.uploadedFiles.filter(image => image._updated && image.binary).map(this.imageFormat);
    const createdAttachments = this.uploadedFiles
      .filter(image => image._created && !image._deleted)
      .map(this.imageFormat);
    const deletedAttachments = this.uploadedFiles.filter(image => image._deleted && image.id).map(image => image.id);

    return {
      updatedAttachments,
      createdAttachments,
      deletedAttachments,
    };
  }

  public imageFormat = image => {
    const userData = this.userService.getCurrentUserInfo();

    return {
      id: image.id,
      orderG7Id: image.orderG7Id,
      filename: `users/${userData.user.id}/g7Orders/${this.orderId}/attachments/${image.filename}`,
    };
  };

  public up(value) {
    if (!value) {
      return;
    }

    return value.slice(value.indexOf('_') + 1);
  }

  public isImageExists() {
    return this.uploadedFiles.filter(image => !image.deleted).every(image => image.filename || image.binary);
  }

  public isMoreThenTenFiles() {
    return this.uploadedFiles.filter(image => !image.deleted).length >= 10;
  }

  public isValidationButtonsVisible() {
    if (!this.order) {
      return;
    }

    return (
      this.mode === 'g7' &&
      this.order.processingStatus !== 'TRANSMIT_ERROR' &&
      this.order.processingStatus !== 'TRANSMITTED' &&
      this.order.processingStatus !== 'VALIDATE_G7' &&
      this.order.status !== 'CANCEL'
    );
  }

  public getSelectedCategoryByName(name: string) {
    return this.categories.find(category => category.category === name);
  }

  public onCategoryChange(value: number) {
    if (this.order.clientAbsent) {
      value = 0;
    }

    this.categoryValue = this.roundValue((Number(this.order.price) * Number(value)) / 100);
    this.calculateTotalPrice();

    this.addressChanged();
  }

  public onSetShow() {
    this.addressChanged();

    if (!this.order.clientAbsent) {
      this.order.price = 0;
      this.order.totalPrice = 0;
      this.initialOrder.price = 0;
      this.initialOrder.totalPrice = 0;
      this.order.toll = 0;
      const categoryValue = this.getSelectedCategoryByName(this.order.tripCategory);
      this.onCategoryChange(categoryValue ? categoryValue.value : 0);
    } else {
      this.order.price = this.defaultTotalPrice;
      this.order.totalPrice = this.defaultTotalPrice;
      this.initialOrder.price = this.defaultTotalPrice;
      this.initialOrder.totalPrice = this.defaultTotalPrice;
      this.order.toll = 0;
    }
  }

  public roundValue(inputNumber: number) {
    const isDecimal = inputNumber % 1 !== 0;

    if (!isDecimal) {
      return inputNumber;
    }

    const decimalPart = inputNumber.toString().split('.')[1];
    if (decimalPart.length > 2) {
      inputNumber = Number(Math.trunc(inputNumber) + '.' + decimalPart.slice(0, 2));
    }

    return inputNumber;
  }

  public onChangePrice(price: any): void {
    this.order.price = Number(price);
    const categoryValue = this.getSelectedCategoryByName(this.order.tripCategory);
    this.onCategoryChange(categoryValue?.value || 0);
  }

  public onChangeToll(toll: any): void {
    this.order.toll = Number(toll);
    this.addressChanged();
    this.calculateTotalPrice();
  }

  public calculateTotalPrice(): void {
    this.order.totalPrice = Number(this.order.toll) + Number(this.order.price) + Number(this.categoryValue);
    this.order.totalPrice = this.roundValue(this.order.totalPrice);
  }

  public setNoShowPriceBeforeSend() {
    if (this.order.clientAbsent) {
      // this.order.price = this.defaultTotalPrice;
      // this.order.totalPrice = this.defaultTotalPrice;
    }
  }

  public setEmptyStringIfAddressIsNull(): void {
    const address = this.order?.destinationAddress;
    if (address && typeof address !== 'string') {
      address.city = address.city || '';
      // eslint-disable-next-line id-blacklist
      address.number = address.number || '';
    }
  }

  public isProhibitedStatusForModify(): boolean {
    if (this.order?.firstPayment && this.order?.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_G7) {
      return G7_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    }
    if (this.mode === 'fleet') {
      return FLEET_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    } else if (this.mode === 'g7') {
      return G7_DISABLED_FIELDS_MAP[this.order?.processingStatus];
    }
    return false;
  }

  public isBlocked() {
    const data = this.order;
    if (this.isBlockedStatus()) {
      return true;
    }

    if (data?.status === 'PENDING' && data?.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_G7) {
      return false;
    }

    if (data) {
      if (this.mode === 'g7') {
        return data.processingStatus === 'WAITING_FLEET';
      } else {
        return data.processingStatus === 'WAITING_G7' || this.isValidG7Status();
      }
    }
    return false;
  }

  public isBlockedStatus() {
    const data = this.order;
    return (
      data &&
      (data.status === 'BILLING' ||
        data.status === 'CANCEL' ||
        data.processingStatus === 'TRANSMITTED' ||
        data.processingStatus === 'TRANSMIT_ERROR')
    );
  }

  public isValidG7Status() {
    const data = this.order;
    if (data) {
      return data.processingStatus === 'VALIDATE_G7' || data.processingStatus === 'CHANGED';
    }

    return false;
  }

  public isDeclineButtonsVisible() {
    if (!this.order) {
      return;
    }

    return (
      this.mode === 'g7' &&
      this.order.processingStatus !== 'TRANSMIT_ERROR' &&
      this.order.processingStatus !== 'TRANSMITTED' &&
      this.order.status !== 'CANCEL'
    );
  }

  public clearMessageToFleet(): void {
    this.messageToFleet = '';
  }

  public resetErrors() {
    this.wrongAddress = false;
  }

  public isFleetMessageAllowed(order: G7Order): boolean {
    return isFleetMessageAllowed(order);
  }

  public isG7MessageAllowed(order: G7Order): boolean {
    return isG7MessageAllowed(order);
  }

  public g7OffsetClassForButtonWrapper(): IObjectMap<boolean> {
    return {
      'col-md-offset-3':
        this.mode === 'fleet' ||
        this.order?.processingStatus === G7_ORDER_PROCESSING_STATUS.TRANSMIT_ERROR ||
        this.order?.processingStatus === G7_ORDER_PROCESSING_STATUS.TRANSMITTED ||
        this.order?.processingStatus === G7_ORDER_PROCESSING_STATUS.VALIDATE_G7 ||
        this.order?.status === 'CANCEL',
    };
  }

  public addressChanged(): void {
    this.addressChangedSubject.next(null);
  }

  public listenOrderChange(): void {
    this.addressChangedSubject
      .pipe(
        map(() => {
          const changedData = this.generateSavePayloadForFleetUser();
          const initialData = this.generateSaveInitialPayloadForFleetUser();
          return (
            JSON.stringify(changedData) !== JSON.stringify(initialData) ||
            this.uploadedFiles.length !== this.initialAttachments.length ||
            this.fileUpdated
          );
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(orderChanged => {
        this.orderChanged = orderChanged;
      });
  }

  private loadOrder$(): Observable<G7Order> {
    return this.g7OrdersService.getOrder(this.orderId).pipe(catchError(() => of(null)));
  }

  private loadAttachments$(): Observable<any> {
    return this.g7OrdersService.getOrdersAttachments(this.orderId).pipe(catchError(() => of(null)));
  }

  private loadTips$(): Observable<ITip[]> {
    return this.g7OrdersService.getG7Tips().pipe(catchError(() => of(null)));
  }

  private handleOrder(order: G7Order): void {
    this.order = order;
    this.initialOrder = Object.assign({}, order);
    if (!order) {
      return;
    }

    if (this.userRoles[0] === 'ROLE_G7_BILLING_USER') {
      if (
        (order.status === G7_ORDER_STATUS.PENDING ||
          G7_ORDER_STATUS.TO_PAY ||
          order.status === G7_ORDER_STATUS.IN_ERROR) &&
        (order.processingStatus === G7_ORDER_PROCESSING_STATUS.WAITING_G7 ||
          order.processingStatus === G7_ORDER_PROCESSING_STATUS.CHANGED)
      ) {
        this.isAllowedToChangeCategory = true;
      } else {
        this.isAllowedToChangeCategory = false;
      }
    }

    this.setOrderAddress(this.order, 'appointmentAddress');
    this.setOrderAddress(this.order, 'destinationAddress');
    this.setOrderAddress(this.initialOrder, 'appointmentAddress', true);
    this.setOrderAddress(this.initialOrder, 'destinationAddress', true);
    this.setPickupAddressAutoCompleteValue();
    this.setDestinationAddressAutocompleteValue();
  }

  private handleTips(tips: ITip[]): void {
    if (!tips) {
      return;
    }

    this.gridTips = tips;
    this.getCategoriesByTipId();
  }

  private handleAttachments(attachments: any): void {
    this.uploadedFiles = attachments || [];
    this.deletedFiles = attachments || [];
    this.initialAttachments = attachments || [];
    this.addNewDocument();
  }

  private getCategoriesByTipId() {
    if (!this.order) {
      return;
    }

    const tipGrid = this.gridTips.find((grid: ITip) => grid.id === this.order.tipId);
    this.categories = tipGrid?.g7TipGrids || [];
    this.categories.sort(sortNumber('id'));
    const categoryValue = this.getSelectedCategoryByName(this.order.tripCategory);
    this.onCategoryChange(categoryValue ? categoryValue.value : 0);
  }

  private isAddressValid(address: string) {
    return !!this[address].value;
  }

  private saveForWaitingFleet(valid: boolean): void {
    const userData: ICurrentUserInfo = this.userService.getCurrentUserInfo();
    this.isSaveSpinner = true;
    this.divideImagesTypes();
    const images$ = [of('')];
    if (this.imagesToBeUploaded.length) {
      this.imagesToBeUploaded.map(image =>
        images$.push(
          /// users/{userId}/g7Orders/{orderId}/attachments/{fileName}
          this.firebaseService
            .uploadFile(
              image.binary,
              `/users/${userData.user.id}/g7Orders/${this.orderId}/attachments/`,
              undefined,
              image.filenameToUpload
            )
            .pipe(map(snapshot => snapshot as any))
        )
      );
    }

    forkJoin(images$)
      .pipe(
        mergeMap((snapshots: any[]) => {
          if (snapshots.length > 1) {
            const uploadedImages = snapshots.slice(1);

            this.imagesToBeUploaded.forEach((image, index) => {
              image.filename = uploadedImages[index].metadata.name;
            });
          }

          const params: any = this.generateSavePayloadForFleetUser();
          params.message = '';
          params.valid = valid;

          return this.g7OrdersService.saveFleetOrderWithValidation(this.order.tripNumber, params);
        }),
        finalize(() => (this.isSaveSpinner = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.g7NotifyComponent.watcher$.next(null);
        this.resetErrors();
        this.clearMessageToFleet();
        this.router.navigate([`${this.backState}`]);
      });
  }

  private isAddressNotSelected(): boolean {
    let notSelected = false;
    if (!this.isAddressValid('pickupAddress')) {
      notSelected = true;
      const message = this.translate.instant('ADDRESS_INVALID', {
        addressFieldName: this.translate.instant('DEPARTURE_ADDRESS_LABEL'),
      });
      this.rootService.alerts.error(message);
    }
    if (!this.isAddressValid('destinationAddress')) {
      notSelected = true;
      const message = this.translate.instant('ADDRESS_INVALID', {
        addressFieldName: this.translate.instant('ARRIVAL_ADDRESS_LABEL'),
      });
      this.rootService.alerts.error(message);
    }
    return notSelected;
  }

  private areAddressPropertiesValid(props: any): boolean {
    return Object.keys(props).some(prop => !!props[prop]);
  }

  private generateSaveInitialPayloadForFleetUser() {
    if (this.isBlocked()) {
      return {
        message: this.messageToFleet,
        ...this.generateImagesPayload(),
      };
    }

    return {
      appointmentAddress: this.initAddressObject(this.initialPickupAddress),
      destinationAddress: this.initAddressObject(this.initialDestinationAddress),
      price: this.initialOrder.price,
      // proofFileName: this.order.proofFileName,
      category: this.initialOrder.tripCategory,
      toll: this.initialOrder.toll,
      totalPrice: this.initialOrder.totalPrice,
      clientAbsent: this.initialOrder.clientAbsent,
      message: this.messageToFleet,
      ...this.generateImagesPayload(),
    };
  }
}
