import { Attribute, ChangeDetectionStrategy, Component, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { throttled } from '@common/decorators';

@Component({
  selector: 'azz-checkbox',
  template: `
    <input [formControl]="innerControl" type="checkbox" [disabled]="isDisabled" />
    <label [class.not-allowed]="innerControl.disabled">{{ label | translate }}</label>
  `,
  styleUrls: ['./checkbox.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string;

  public readonly innerControl = new FormControl(null);
  public isDisabled = false;

  constructor(@Attribute('label') label: string) {
    this.label = label;
  }

  @throttled(1000)
  @HostListener('click')
  onCheckboxClick(): void {
    if (this.innerControl.disabled) {
      return;
    }

    const isChecked = !this.innerControl.value;
    this.innerControl.setValue(isChecked);
    this.onChange(isChecked);
    this.onTouch();
  }

  writeValue(isToggled: boolean | null): void {
    this.innerControl.setValue(isToggled);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.innerControl.disable();
    } else {
      this.innerControl.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (_: boolean | null) => {};
  private onTouch = () => {};
}
