import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { OrderLogsTableComponent } from './order-logs-table.component';
import { LoadingBlockModule } from '../../../common/components/loading-block/loading-block.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableNoMatchesModule } from '../table-no-matches/table-no-matches.module';
import { OrderLogInfoComponent } from './order-log-info/order-log-info.component';
import { OrderDiffMsgLogService } from './services/order-diff-msg-log.service';
import { OrderDispatchLogService } from './services/order-dispatch-log.service';
import { OrderRedispatchLogService } from './services/order-redispatch-log.service';
import { OrderLogsEffects } from './store/effects';
import * as OrderLogsReducer from './store/reducer';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    StoreModule.forFeature(OrderLogsReducer.orderLogFeatureKey, OrderLogsReducer.reducer),
    EffectsModule.forFeature([OrderLogsEffects]),
    TableNoMatchesModule,
    PaginationModule,
    LoadingBlockModule,
  ],
  declarations: [OrderLogsTableComponent, OrderLogInfoComponent],
  exports: [OrderLogsTableComponent],
  providers: [OrderDiffMsgLogService, OrderDispatchLogService, OrderRedispatchLogService],
})
export class OrderLogsModule {}
