import { inject, Injectable } from '@angular/core';
import { ICustomersData } from '@core/models/customer.model';
import { CustomerService } from '@core/services/users';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { EffectsBase } from '@store/effects/base.effects';
import { Observable } from 'rxjs';
import { debounceTime, exhaustMap, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import * as CustomersActions from '../actions';
import { CustomersStore } from '../index';

@Injectable()
export class CustomersEffects extends EffectsBase {
  private readonly customerService = inject(CustomerService);

  public getCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomersActions.getCustomers),
      withLatestFrom(this.selectFromStore(CustomersStore.params)),
      switchMap(([_, params]) =>
        this.customerService.getCustomers(params).pipe(
          map((customersData: ICustomersData) => CustomersActions.getCustomersSuccess({ customersData })),
          this.catchError(CustomersActions.getCustomersError())
        )
      )
    )
  );

  public updateFilterModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomersActions.updateFilterModel),
      debounceTime(300),
      map(() => CustomersActions.updatePagination({ pagination: { page: 0 } }))
    )
  );

  public updateSorting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomersActions.updateSorting),
      map(() => CustomersActions.getCustomers())
    )
  );

  public updatePagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomersActions.updatePagination),
      map(() => CustomersActions.getCustomers())
    )
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(CustomersActions.init),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(CustomersActions.destroy)))))
    );
  }
}
