<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-md-6 azz-header customers-info-header">
        {{ 'PA_CUSTOMERS_ORDERS' | translate }}
      </div>
    </div>
    <azz-loading-block [isLoading]="ordersLoadingIndicator">
      <div class="azz-table-wrapper">
        <table class="table table-hover azz-table">
          <thead>
            <tr class="d-table-row">
              <th class="col-2">{{ 'PA_DATE_COL_HEADER' | translate }}</th>
              <th class="col-2">{{ 'PA_ORDER_COL_HEADER' | translate }}</th>
              <th class="col-2">{{ 'PA_STATUS_COL_HEADER' | translate }}</th>
              <th class="col-2">{{ 'PA_ADDRESS_COL_HEADER' | translate }}</th>
              <th class="col-2">{{ 'PA_PRICE_COL_HEADER' | translate }}</th>
              <th class="col-2">{{ 'PA_DRIVER_COL_HEADER' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="customerOrdersData?.content?.length > 0">
            <!-- IE scrolling fix -->
            <tr
              class="azz-clickable"
              *ngFor="let order of customerOrdersData?.content"
              (click)="navigateToOrderDetailsPage($event, order.id)"
            >
              <td>
                <span *ngIf="order?.date">
                  {{ order.date | date: 'dd.MM.yyyy' }},<br />
                  {{ order.date | date: 'HH:mm' }}
                </span>
              </td>
              <td>{{ order.id }}</td>
              <td>{{ 'CORE_ORDER_STATUS_' + order.status | translate }}</td>
              <td>
                <span *ngIf="order?.appointmentAddress?.poi?.label">
                  {{ order?.appointmentAddress?.poi?.label }}
                </span>
                <span *ngIf="!order?.appointmentAddress?.poi?.label">
                  {{ order?.appointmentAddress?.number }}
                  {{ order?.appointmentAddress?.street }},
                  {{ order?.appointmentAddress?.city }}
                </span>
                -> <br />
                <span *ngIf="order?.destinationAddress?.poi?.label">
                  {{ order?.destinationAddress?.poi?.label }}
                </span>
                <span *ngIf="!order?.destinationAddress?.poi?.label">
                  {{ order?.destinationAddress?.number }}
                  {{ order?.destinationAddress?.street }},
                  {{ order?.destinationAddress?.city }}
                </span>
              </td>
              <td [ngClass]="{ 'azz-color-red': order.price === 0 }">
                {{ order.price !== -1 ? order?.price + ' \u20AC' : ('FO_TAXIMETER_VALUE' | translate) }}
              </td>
              <td>
                <span class="no-general-navigation" (click)="navigateToDriverDetailsPage(order.driver?.id)">
                  {{ order?.driver?.firstName }} {{ order?.driver?.lastName }}<br />
                  {{ order?.driver?.phoneCode }}{{ order?.driver?.phoneNumber }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <azz-table-no-matches [azzVisible]="!customerOrdersData?.content?.length"></azz-table-no-matches>
      <ng-content></ng-content>
    </azz-loading-block>
  </div>
</div>
