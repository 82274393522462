import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ISessionsCountByStatus } from '../../../models/driver-session.model';

@Component({
  selector: 'azz-sessions-count',
  templateUrl: './sessions-count.component.html',
  styleUrls: ['./sessions-count.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionsCountComponent {
  @Input() sessionsCountArr: ISessionsCountByStatus[];
  @Input() totalSessionsCount: number;
}
