import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgOnDestroyService } from '@common/services';
import { EtalabFeatureCollection } from '@core/models/geo-json.model';
import { AddressesService, IFindAddressParams } from '@core/services/common/addresses.service';
import { FormService } from '@core/utils/form-service';
import { Feature } from 'geojson';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'azz-custom-autocomplete',
  templateUrl: './azz-custom-autocomplete.component.html',
  styleUrls: ['./azz-custom-autocomplete.component.less'],
})
export class CustomAutocompleteComponent implements OnChanges {
  @Input() ngDisabled: boolean;
  @Input() ngId: string;
  @Input() address: any;
  @Input() validators = [Validators.required];
  @Input() customFocusTriggerCounter: number;
  @Input() isAddressNotSelectedErrorShown = true;
  @Output() setAddress = new EventEmitter<boolean>();
  @Output() typing = new EventEmitter();
  @ViewChild('formElemRef') formElemRef: ElementRef;
  public REQUEST_DELAY_MS = 500;
  public form: FormGroup;
  public watcher$ = new Subject();
  public loadingIndicator: boolean;

  constructor(
    protected addressesService: AddressesService,
    protected formService: FormService,
    protected destroyed$: NgOnDestroyService
  ) {
    this.initForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ngDisabled) {
      if (changes.ngDisabled?.currentValue) {
        this.form.get('searchField').disable();
      } else {
        this.form.get('searchField').enable();
      }
    }
    if (changes.validators && changes.validators.currentValue && changes.validators.currentValue.length === 0) {
      this.formService.removeValidators(this.form, 'searchField');
    }
  }

  public search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.REQUEST_DELAY_MS),
      distinctUntilChanged(),
      tap(() => this.enableLoadingIndicator(true)),
      switchMap((searchValue: string) => {
        const params = {
          limit: 10,
          q: searchValue,
        };
        this.typing.emit();
        this.resetAddressValue();
        return this.findAddress(params);
      }),
      tap(() => this.enableLoadingIndicator(false)),
      takeUntil(this.destroyed$)
    );

  public findAddress(params: IFindAddressParams): Observable<Feature[]> {
    return params.q
      ? this.addressesService.findAddress(params).pipe(
          filter((response: EtalabFeatureCollection) => !!response && !!response.features),
          map((response: EtalabFeatureCollection) => (response.features.length ? response.features : [null])),
          catchError(() => EMPTY),
          takeUntil(this.destroyed$)
        )
      : of([]);
  }

  public formatter = (address: string) =>
    // @ts-ignore
    address.properties ? `${address.properties.name}, ${address.properties.city}` : address;

  public enableLoadingIndicator(enabled: boolean): void {
    this.loadingIndicator = enabled;
  }

  public resetAddressValue(): void {
    if (this.address.value) {
      this.address.value = null;
      this.setAddress.emit();
    }
  }

  public onAddressSelect(event: { item: Feature; preventDefault: () => void }): void {
    if (!event.item) {
      event.preventDefault();
      this.formService.markFieldsAsTouched(this.form);
      return;
    }
    this.address.value = event.item;
    this.setAddress.emit(true);
  }

  public setAutocompleteFieldValue(searchField: string): void {
    this.form.patchValue({ searchField });
  }

  public getAutocompleteFieldValue() {
    return this.form.value.searchField;
  }

  protected initForm(): void {
    this.form = new FormGroup({
      searchField: new FormControl({ value: '', disabled: this.ngDisabled }, this.validators),
    });
  }
}
